import React from 'react'

const Button = ({ label, onClick }) => {
    return (
        <button onClick={onClick} className='w-full py-2.5 px-4 text-white bg-primary rounded-md'>
            {label}
        </button>
    )
}

export default Button
