import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { getCityCoordinates } from "./getCityCoordinates";
import { RxCross2 } from "react-icons/rx";
import { FaMapMarkerAlt, FaUserFriends } from "react-icons/fa";
import { FaStar, FaUtensils } from "react-icons/fa6";
import { debounce } from "lodash";

const Map = ({ setIsShowMap, city, places, setMapBounds, setFilterVenues }) => {
  const [coordinates, setCoordinates] = useState({ Lat: 0, Lng: 0 });
  const [showDetail, setIsshowDetail] = useState(false);
  const [venueDetail, setVenueDetail] = useState({});
  const [infoPosition, setInfoPosition] = useState(null);
  const mapRef = useRef(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg",
  });

  useEffect(() => {
    const cityCoordinates = getCityCoordinates(city);
    if (cityCoordinates) {
      setCoordinates(cityCoordinates);
    }
  }, [city]);
  const handleClose = () => {
    setIsShowMap(false);
    setFilterVenues([]);
  };

  const handleBoundsChanged = useCallback(
    debounce(() => {
      const bounds = mapRef.current?.getBounds();
      if (bounds) {
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        setMapBounds({
          north: northEast.lat(),
          east: northEast.lng(),
          south: southWest.lat(),
          west: southWest.lng(),
        });
      }
    }, 300),
    []
  );

  const handleClick = (place) => {
    setIsshowDetail(true);
    setVenueDetail(place);
    setInfoPosition({
      lat: place.overview.locationDetails.lat,
      lng: place.overview.locationDetails.lng,
    });
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-[98vh] w-full relative">
      <GoogleMap
        onLoad={(map) => (mapRef.current = map)}
        onIdle={handleBoundsChanged}
        center={coordinates}
        zoom={10}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        {places.length > 0 &&
          places.map((place, i) => (
            <Marker
              key={i}
              onClick={() => handleClick(place)}
              position={{
                lat: place.overview.locationDetails.lat,
                lng: place.overview.locationDetails.lng,
              }}
            />
          ))}

        {showDetail && infoPosition && (
          <InfoWindow
            position={infoPosition}
            onCloseClick={() => setIsshowDetail(false)}
          >
            <div className="w-60 bg-white rounded-lg shadow-lg p-2">
              <div className="relative">
                <img
                  src={venueDetail.photoVideo?.images?.[0]}
                  alt="Venue"
                  className="w-full h-32 rounded-t-lg object-cover"
                />
              </div>

              {/* Venue Details */}
              <div className="p-2">
                <h1 className="text-lg font-semibold">
                  {venueDetail.overview?.basicDetails?.hotelName}
                </h1>
                <p className="text-gray-600 flex items-center">
                  <FaMapMarkerAlt size={14} className="mr-1" />
                  {venueDetail.overview?.locationDetails?.city},{" "}
                  {venueDetail.overview?.locationDetails?.area}
                </p>

                {/* Icons for Rating, People Count, and Category */}
                <div className="flex justify-between items-center my-2 text-gray-600">
                  <div className="flex items-center">
                    <FaUserFriends size={14} className="mr-1" />
                    {venueDetail.overview?.additionalDetails
                      ?.venueSetting?.[0] || "N/A"}
                  </div>
                  <div className="flex items-center">
                    <FaStar size={14} className="mr-1 text-yellow-500" />
                    {venueDetail.overview.basicDetails.hotelRating || "N/A"}
                  </div>
                  <div className="flex items-center">
                    <FaUtensils size={14} className="mr-1" />
                    {venueDetail.overview?.basicDetails?.hotelName}
                  </div>
                </div>

                {/* Price Information */}
                {venueDetail.pricing?.basePrice && (
                  <>
                    <div className="text-red-600 font-semibold text-lg">
                      AED {venueDetail.pricing?.basePrice || "N/A"}
                    </div>
                    <p className="text-gray-600 text-sm">Min. spend</p>
                  </>
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <button
        onClick={handleClose}
        className="absolute top-3 right-[5vw] bg-white h-10 w-10 rounded-full flex justify-center items-center"
      >
        <RxCross2 size={24} />
      </button>
    </div>
  );
};

export default Map;
