import React, { useEffect, useState } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../components/NavbarCustomer";
import { useSelector } from "react-redux";

function CompareVenuesAnsStay({ filters, setFilters }) {
  const [isMobileView, setIsMobileView] = useState(false);
  const data = useSelector((state) => state.auth.compareVenuesAndStay);
  const closeLink =
    useSelector((state) => state.auth.compareVSLink) || "/my-favorites";
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView ? (
        <>
          <div className="">
            <div className="bg-white">
              <Navbar filters={filters} setFilters={setFilters} />
              <div className="flex justify-between items-center px-4 py-4 ">
                <h1 className="text-[20px] font-medium">Comparing Venues</h1>
                <RxCross2
                  className="cursor-pointer"
                  size={26}
                  onClick={() => navigate(closeLink)}
                />
              </div>
              <div className="px-4 py-4 space-y-4 overflow-x-auto custom-scrollbar1">
                <div className="flex justify-center items-center gap-6 w-full">
                  {data?.map((venue, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-center items-center "
                    >
                      <img
                        src={venue.photoVideo?.images[0]}
                        alt={venue.name}
                        className="w-32 h-22 object-cover rounded-lg"
                      />
                      <p className="text-[16px] font-medium mt-2 text-center">
                        {venue?.overview?.basicDetails?.hotelName ||
                          "Undefined"}
                        ,{" "}
                        {venue?.overview?.basicDetails?.hotelChain ||
                          "Undefined"}
                      </p>
                    </div>
                  ))}
                </div>

                <div className="flex flex-col items-center pb-4">
                  <table className="mt-4 w-full text-center text-sm">
                    <tbody>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        City
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.overview?.locationDetails?.area || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Area
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.overview?.locationDetails?.city || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Hotel Rating
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.overview?.basicDetails?.hotelRating || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Hotel Type
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.overview?.basicDetails?.selectedTags
                              ?.types[0] || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Hotel Built Year
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.overview?.additionalDetails?.hotelBuiltYear?.slice(
                              0,
                              10
                            ) || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Hotel Renovated Year
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.overview?.additionalDetails?.lastRenovatedYear?.slice(
                              0,
                              10
                            ) || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        No. of Rooms
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.accommodation?.accommodations || "0"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        No. of Venues
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.accommodation?.accommodation || "0"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Base Price
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.pricing?.basePrice || "0"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Sustainable
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue.overview?.additionalDetails.isSustainable
                              ? "Sustainable"
                              : "No Sustainable"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Mention Session
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue.pricing.mentionSeasons
                              ? "Mention Session"
                              : "No Mention Session"}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="">
          <div className="bg-white ">
            <Navbar />
            <div className="text-[#717171] flex items-center gap-2 p-6 px-10">
              <Link to="/">Home</Link>
              <RiArrowRightSLine />
              <p className="text-[#717171] cursor-pointer">Search Results</p>
              <RiArrowRightSLine />
              <p className="text-dark">Compare</p>
            </div>
            <div className="flex w-full justify-between items-center px-10">
              <h1 className="text-[32px] font-semibold text-[#222222]">
                Compare
              </h1>
              <div>
                <RxCross2
                  className="cursor-pointer"
                  size={26}
                  onClick={() => navigate(closeLink)}
                />
              </div>
            </div>
            <div className="px-6 py-4 overflow-x-auto custom-scrollbar1">
              <div className="min-w-full inline-block  pb-1">
                <table className="w-full whitespace-nowrap text-center">
                  <thead>
                    <tr>
                      <th className="py-2 font-bold text-[24px] text-left pl-4">
                        Comparing Venues & Stay
                      </th>
                      {data?.map((venue, index) => (
                        <td key={index} className="px-4 py-2">
                          <div className="flex items-center text-left p-2 border border-[#EBEBEB] rounded-[8px] justify-start gap-1 shadow-sm max-w-[300px]">
                            <img
                              src={venue.photoVideo.images[0]}
                              alt={venue.name}
                              className="w-20 h-16 object-cover rounded-lg mr-2"
                            />
                            <p className="text-[14px] font-medium text-[#222222] hidden md:block truncate">
                              {venue?.overview?.basicDetails?.hotelName ||
                                "Undefined"}
                            </p>
                          </div>
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px] min-w-[300px]">
                        City
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left min-w-[300px]"
                        >
                          {venue.overview.locationDetails.area || "Undefined"}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Area
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue.overview.locationDetails.city || "Undefined"}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Hotel Rating
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue.overview.basicDetails.hotelRating ||
                            "Undefined"}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Hotel Type
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.overview?.basicDetails?.selectedTags
                            ?.types[0] || "Undefined"}
                        </td>
                      ))}
                    </tr>

                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Hotel Built Year
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue?.overview?.additionalDetails?.hotelBuiltYear?.slice(
                            0,
                            10
                          ) || ""}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Hotel Renovated Year
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.overview?.additionalDetails?.lastRenovatedYear?.slice(
                            0,
                            10
                          ) || ""}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        No. of Rooms
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue?.accommodation?.accommodations || "0"}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        No. of Venues
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.overview?.additionalDetails?.lastRenovatedYear?.slice(
                            0,
                            10
                          ) || ""}
                        </td>
                      ))}
                    </tr>

                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Base Price
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue?.pricing?.basePrice || "0"}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Sustainable
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue.overview?.additionalDetails.isSustainable
                            ? "Sustainable"
                            : "No Sustainable"}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Mention Session
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue.pricing.mentionSeasons
                            ? "Mention Session"
                            : "No Mention Session"}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompareVenuesAnsStay;
