/* eslint-disable */

import React, { useState } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navbar from "../../../components/NavbarCustomer";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { IoShareSocial } from "react-icons/io5";
import VenueFavoriteDetail from "../../../components/myFav/VenueFavoriteDetail";
import VenueStayFavoriteDetail from "../../../components/myFav/VenueStayFavoriteDetail";
import EditNameModal from "../../../components/myFav/EditNameModal";
import DeleteFolderModal from "../../../components/myFav/DeleteFolderModal";
import SocialMediaModal from "../../../components/myFav/SocialMediaModal";
import { Boxes } from "../../../svgs";
import {
  setCompareVenues,
  setCompareVenuesAndStay,
  setCompareVLink,
  setCompareVSLink,
} from "../../../store/features/authSlice";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/shared/Modal";
import BuilkEnquiryModal from "../../../components/myFav/BulkEnquiryModal";
import { base_URL } from "../../../config/config";
import { useQuery } from "@tanstack/react-query";

function FavoriteDetail({ filters, setFilters }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [deleteFolder, setDeleteFolder] = useState(false);
  const [socialModal, setSocialModal] = useState(false);
  const [isCompareMode, setIsCompareMode] = useState(false);
  const [isBulkEnquiry, setIsBulkEnquiry] = useState(false);
  const [selectedEnquiries, setSelectedEnquiries] = useState([]);
  const [enquiryType, setEnquiryType] = useState("both");
  const handleToggleCompare = () => {
    setIsCompareMode(!isCompareMode);
    setIsBulkEnquiry(false);
  };
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [compareType, setCompareType] = useState("both");
  const [isBulkEnquiryModal, setIsBulkEnquiryModal] = useState(false);
  const [venuesData, setVenuesData] = useState([]);
  const [venueStaysData, setVenuesStayData] = useState([]);
  const user = JSON.parse(localStorage.getItem("authUser"));
  const { customerId, folderName } = useParams();

  const removeFolder = (name) => {
    const newVenues = user.favoritesFolder.filter(
      (folder) => folder.name !== name
    );
    const newUser = { ...user, favoritesFolder: newVenues };
    localStorage.setItem("authUser", JSON.stringify(newUser));
  };

  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-favorites-folder/${customerId}/${folderName}`
      );
      if (!response.ok) {
        // console.log("Failed to fetch Venues");
        return;
      }
      // console.log(response);
      const data = await response.json();

      // console.log(data);
      return data.folder;
    } catch (err) {
      console.log(err);
    }
  };

  const { data, refetch } = useQuery({
    queryKey: ["favoriteFolder", customerId, folderName],
    queryFn: getFavoriteFolder,
    enabled: !!customerId && !!folderName, // Ensure the query only runs when these values are available
  });

  const handleVenueSelect = (venue) => {
    setSelectedVenues((prevSelected) => {
      const alreadySelected = prevSelected.some(
        (item) => item._id === venue._id
      );
      if (alreadySelected) {
        return prevSelected.filter((item) => item._id !== venue._id);
      } else if (prevSelected.length < 3) {
        return [...prevSelected, venue];
      } else {
        return prevSelected;
      }
    });
  };
  const handleEnquirySelect = (enquiry) => {
    setSelectedEnquiries((prevSelected) => {
      const alreadySelected = prevSelected.some(
        (item) => item._id === enquiry._id
      );
      if (alreadySelected) {
        return prevSelected.filter((item) => item._id !== enquiry._id);
      } else {
        return [...prevSelected, enquiry];
      }
    });
  };

  const handleCompare = () => {
    if (compareType === "venues") {
      console.log(window.location.pathname);
      dispatch(setCompareVenues(selectedVenues));
      dispatch(setCompareVLink(window.location.pathname));
      navigate("/compare-venues");
    } else {
      console.log(window.location.pathname);
      dispatch(setCompareVenuesAndStay(selectedVenues));
      dispatch(setCompareVSLink(window.location.pathname));
      navigate("/compare-venues-stay");
    }
  };
  const handleBulkEnquiry = () => {
    setIsBulkEnquiry(false);
    setIsBulkEnquiryModal(true);
  };
  const handleRemove = (id) => {
    setSelectedVenues((prevSelected) =>
      prevSelected.filter((venue) => venue._id !== id)
    );
  };
  const removeVenue = (id) => {
    setVenuesData((prevData) => prevData.filter((data) => data._id !== id));
  };
  const removeVenueStay = (id) => {
    setVenuesStayData((prevData) => prevData.filter((data) => data._id !== id));
  };
  const handleEnquiryRemove = (id) => {
    setSelectedEnquiries((prevSelected) =>
      prevSelected.filter((enquiry) => enquiry._id !== id)
    );
  };

  return (
    <>
      {isBulkEnquiryModal && (
        <Modal
          isOpen={isBulkEnquiryModal}
          onClose={() => setIsBulkEnquiryModal(false)}
        >
          <BuilkEnquiryModal
            onClose={() => setIsBulkEnquiryModal(false)}
            selectedEnquiries={selectedEnquiries}
            enquiryType={enquiryType === "venues" ? "venue" : "hotel"}
          />
        </Modal>
      )}
      {socialModal && (
        <SocialMediaModal
          onClose={() => setSocialModal(false)}
          isOpen={socialModal}
          shareLink={window.location.href}
        />
      )}
      {deleteFolder && (
        <DeleteFolderModal
          preName={folderName}
          onClose={() => setDeleteFolder(false)}
          isOpen={deleteFolder}
          removeFolder={removeFolder}
        />
      )}
      {edit && (
        <EditNameModal
          preName={folderName}
          onClose={() => setEdit(false)}
          isOpen={edit}
          refetch={refetch}
        />
      )}
      <Navbar filters={filters} setFilters={setFilters} />
      <div
        className={`bg-white sm:px-8 px-4 py-5 ${
          isCompareMode ? "mb-20" : "mb-0"
        }`}
      >
        <div className="text-[#717171] flex items-center my-4 gap-2">
          <Link to="/">Home</Link>
          <RiArrowRightSLine />
          <Link to="/my-favorites">My Favorites</Link>
          <RiArrowRightSLine />
          <p className="text-dark">{folderName}</p>
        </div>
        <div className="flex md:flex-row flex-col md:justify-between justify-start md:items-center items-start gap-y-3 md:gap-y-0 ">
          <div className="text-[24px] font-medium flex gap-3 items-center ">
            {folderName}{" "}
            <FaRegEdit
              size={22}
              className="cursor-pointer"
              onClick={() => setEdit(true)}
            />
          </div>
          <div className="flex md:justify-end justify-between gap-2">
            <button
              className="flex items-center gap-2 md:px-4 px-2 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              onClick={handleToggleCompare}
            >
              <p>Compare</p>
              <Boxes className="text-black md:block hidden" />
            </button>
            <button
              className="flex items-center gap-2 md:px-4 px-2 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              onClick={() => {
                setIsBulkEnquiry(!isBulkEnquiry);
                setIsCompareMode(false);
                setEnquiryType("both");
                setSelectedEnquiries([]);
              }}
            >
              <IoDocumentTextOutline className="w-4 h-4 md:block hidden" />
              Bulk enquiry
            </button>

            <button
              onClick={() => setDeleteFolder(true)}
              className="flex items-center gap-2 md:px-4 px-2 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              <MdDeleteOutline className="w-4 h-4 cursor-pointer md:block hidden" />
              Delete
            </button>

            <button
              onClick={() => setSocialModal(true)}
              className="flex items-center gap-2 md:px-4 px-2 py-2 text-sm font-medium text-white rounded-md  bg-gray-800"
            >
              <IoShareSocial className="w-4 h-4 text-white md:block hidden" />
              Share now
            </button>
          </div>
        </div>
        <div>
          <div className="text-[16px] text-start my-6">
            {data?.venues?.length > 0 && "Venues"}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {data &&
              data.venues.length > 0 &&
              data?.venues?.map((venue, index) => (
                <VenueFavoriteDetail
                  key={index}
                  data={venue}
                  refetch={refetch}
                  name={folderName}
                  removeVenue={removeVenue}
                  compareType={compareType}
                  setCompareType={setCompareType}
                  handleCompare={handleCompare}
                  handleRemove={handleRemove}
                  setIsCompareMode={setIsCompareMode}
                  selectedVenues={selectedVenues}
                  isCompareMode={isCompareMode}
                  clearSelectedVenues={() => setSelectedVenues([])}
                  onSelect={handleVenueSelect}
                  isBulkEnquiry={isBulkEnquiry}
                  setIsBulkEnquiry={setIsBulkEnquiry}
                  enquiryType={enquiryType}
                  setEnquiryType={setEnquiryType}
                  selectedEnquiries={selectedEnquiries}
                  handleEnquirySelect={handleEnquirySelect}
                  handleBulkEnquiry={handleBulkEnquiry}
                  handleEnquiryRemove={handleEnquiryRemove}
                  onEnquirySelect={handleEnquirySelect}
                />
              ))}
          </div>
        </div>
        <div>
          <div className="text-[16px] text-start my-6">
            {data?.venueStays?.length > 0 && "Venues & Stay"}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {data &&
              data.venueStays.length > 0 &&
              data.venueStays?.map((venue, index) => (
                <VenueStayFavoriteDetail
                  key={index}
                  data={venue}
                  refetch={refetch}
                  name={folderName}
                  compareType={compareType}
                  removeVenueStay={removeVenueStay}
                  setCompareType={setCompareType}
                  handleCompare={handleCompare}
                  handleRemove={handleRemove}
                  clearSelectedVenues={() => setSelectedVenues([])}
                  setIsCompareMode={setIsCompareMode}
                  selectedVenues={selectedVenues}
                  isCompareMode={isCompareMode}
                  onSelect={handleVenueSelect}
                  isBulkEnquiry={isBulkEnquiry}
                  setIsBulkEnquiry={setIsBulkEnquiry}
                  selectedEnquiries={selectedEnquiries}
                  handleBulkEnquiry={handleBulkEnquiry}
                  handleEnquiryRemove={handleEnquiryRemove}
                  enquiryType={enquiryType}
                  setEnquiryType={setEnquiryType}
                  onEnquirySelect={handleEnquirySelect}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default FavoriteDetail;
