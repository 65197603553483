import React from "react";

function EnquiruStatuszero() {
  return (
    <div className="w-full bg-[#FFFFFF]  rounded-xl md:w-[35%] h-[333px]">
      <div className="ml-[20px] mt-[20px]">
        <div className="text-[14px] md:text-[20px] font-bold">
          Enquiry status
        </div>
      </div>

      <div className=" h-full w-full flex flex-col gap-y-3 items-center mt-[64px]">
        <img src="/statuszero.svg" alt="" />
        <p className="text-[14px] md:text-[16px] text-[#222222] text-center font-normal">
          At this moment, there are no status <br /> regarding any venue.
        </p>
      </div>
    </div>
  );
}

export default EnquiruStatuszero;
