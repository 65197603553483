/* eslint-disable */

import { useQuery, useMutation } from "@tanstack/react-query";
import Navbar from "../../../../components/NavbarCustomer";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { useState, useEffect, useRef } from "react";
import { FaNotesMedical } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { IoIosAttach } from "react-icons/io";
import { BsSend } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { base_URL } from "../../../../config/config";
import toast from "react-hot-toast";
import socketIOClient from "socket.io-client";
import axios from "axios";
import AWS from "aws-sdk";
import DeclineModal from "./components/DeclineModal";
import {
  FaUsers,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaUtensils,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: REGION,
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const sendMessage = async (newMessage, enquiryData, refetch) => {
  const response = await axios.post(`${base_URL}/api/message/send`, {
    content: newMessage.content,
    enquiryId: enquiryData?._id,
    customerId: enquiryData?.customerId,
    vendorId: enquiryData?.vendorId,
    managerIds: [],
    fileName: newMessage?.fileName,
    fileType: newMessage?.fileType,
    isFile: newMessage?.isFile,
    senderId: enquiryData?.customerId,
    sender: "customer",
  });
  refetch();

  return response.data.message;
};

const Messages = ({ filters, setFilters }) => {
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("authUser"));
  const navigate = useNavigate();
  const [newMessage, setNewMessage] = useState("");
  const [enquiryData, setEnquiryData] = useState(
    JSON.parse(localStorage.getItem("selectedVenue"))
  );
  const fileInputRef = useRef(null);
  const socket = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [declineMessage, setDeclineMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [vendorResponseTime, setVendorResponseTime] = useState(0);

  const getResponseTime = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/vendor-response-time/${enquiryData?.vendorId}`
      );
      const data = await response.json();
      // console.log(data);
      setVendorResponseTime(data?.averageResponseTime);
    } catch (error) {
      console.error("Error fetching vendor response time:", error);
    }
  };

  useEffect(() => {
    getResponseTime();
  }, [enquiryData]);

  const getEnquiryData = async () => {
    // console.log(id, user._id);
    try {
      const response = await fetch(`${base_URL}/api/enquiry/getEnquiryById`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          enquiryId: id,
          customerId: user?._id,
        }),
      });
      // console.log(response);
      const data = await response.json();
      setEnquiryData(data?.enquiry);
      // console.log(data?.enquiry);
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
    }
  };

  useEffect(() => {
    getEnquiryData();
  }, []);

  const {
    data: messages,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["messages", enquiryData?._id],
    queryFn: () =>
      axios
        .get(`${base_URL}/api/message/get/${enquiryData?._id}`)
        .then((res) => res.data),
    enabled: !!enquiryData?._id,
  });

  useEffect(() => {
    if (!socket.current) {
      socket.current = socketIOClient(base_URL, { withCredentials: true });
      socket.current.on("connect", () =>
        console.log("Connected to socket server")
      );
    }

    if (enquiryData?._id) {
      socket.current.emit("joinEnquiryRoom", enquiryData._id);

      socket.current.on("newMessage", () => {
        refetch();
      });
    }

    return () => {
      socket.current.off("newMessage");
      socket.current.disconnect();
    };
  }, [enquiryData, refetch]);

  useEffect(() => {
    const messagesContainer = document.querySelector(".messages-container");
    if (messagesContainer && messages?.messages?.[0]?.message?.length > 0) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
    // console.log(messages?.messages?.[0]?.message);
    if (!messages) {
      // console.log("messages length", messages);
    }
    // console.log(messages);
  }, [messages]);

  const mutation = useMutation({
    mutationFn: (newMessage) => sendMessage(newMessage, enquiryData, refetch),
    onSuccess: (data) => {
      // toast.success("Message sent Successfuly");
      socket.current.emit("newMessage", data);
      // console.log("sending", data);
      setNewMessage("");
      refetch();
    },
    onError: (error) => {
      // console.log("sending", error);
    },
  });

  const handleAttachClick = () => {
    // if (
    //   enquiryData?.status === "Customer Declined" ||
    //   enquiryData?.status === "Venue Declined" ||
    //   enquiryData.status === "Lost"
    // )
    //   return;
    fileInputRef.current.click();
  };

  const sendMessageHandler = () => {
    // if (
    //   enquiryData?.status === "Customer Declined" ||
    //   enquiryData?.status === "Venue Declined" ||
    //   enquiryData.status === "Lost" ||
    //   enquiryData?.status === "Successful"
    // ) {
    //   toast.error("Enquiry Closed");
    //   return;
    // }
    if (!newMessage.trim()) {
      return;
    }
    const messageData = {
      content: newMessage,
      isFile: false,
      fileType: "",
      fileName: "",
    };
    // console.log(messageData);
    mutation.mutate(messageData);
  };

  const closeEnquiry = async (declineReason, declineJustification) => {
    // console.log({
    //   enquiryId: enquiryData?._id,
    //   status: "Customer Declined",
    //   statusMessage: "Enquiry Declined by Customer",
    //   declineReason,
    //   declineJustification,
    // });
    try {
      const response = await fetch(`${base_URL}/api/enquiry/updateEnquiry`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          enquiryId: enquiryData?._id,
          status: "Customer Declined",
          statusMessage: "Enquiry Declined by Customer",
          declineReason,
          declineJustification,
        }),
      });

      const data = await response.json();
      getEnquiryData();
      setDeclineMessage("Enquiry Declined by Customer");
      setIsModalVisible(false);
      toast.success("Enquiry Declined Successfuly");
      return data;
    } catch (err) {
      toast.error("Error declining enquiry");
    }
  };

  const handleFileUpload = async (event) => {
    if (
      enquiryData.status === "Customer Declined" ||
      enquiryData.status === "Successful" ||
      enquiryData.status === "Venue Declined" ||
      enquiryData.status === "Lost"
    ) {
      toast.error("Enquiry Closed");
      return;
    }
    const file = event.target.files[0];
    if (!file) return;

    try {
      setIsUploading(true);
      setUploadProgress(0);

      const fileName = `${Date.now()}-${file?.name}`;

      // S3 upload parameters with progress tracking
      const params = {
        Key: `chats/${fileName}`,
        Body: file,
        ContentType: file.type,
        Bucket: S3_BUCKET,
      };

      // Upload to S3 with progress tracking
      const uploadPromise = myBucket.upload(params);

      // Track upload progress
      uploadPromise.on("httpUploadProgress", (progress) => {
        const percentCompleted = Math.round(
          (progress.loaded / progress.total) * 100
        );
        setUploadProgress(percentCompleted);
      });

      // Wait for upload to complete
      const uploadResponse = await uploadPromise.promise();
      // console.log("Upload response:", uploadResponse);

      let content = uploadResponse?.Location;
      const newMessage = {
        content: content,
        isFile: true,
        fileName: fileName,
        fileType: file?.type,
      };
      // console.log(content, fileName, fileType);
      mutation.mutate(newMessage, {
        onSettled: () => {
          // Reset upload state
          setIsUploading(false);
          setUploadProgress(0);
        },
      });

      // Emit file message via socket
      if (socket.current) {
        socket.current.emit("newMessage", {
          enquiryId: enquiryData?._id,
          message: content,
        });
      }
    } catch (error) {
      console.error("File upload error:", error);
      setIsUploading(false);
      setUploadProgress(0);
      toast.error("Failed to upload file. Please try again.");
    }
  };

  const handleModalClose = () => setIsModalVisible(false);

  const handleUpdateClick = async () => {
    try {
      await axios.post(`${base_URL}/api/enquiry/updateClick`, {
        enquiryId: enquiryData?._id,
      });
    } catch (err) {
      console.error(err);
    }
  };
  // handle navigation to preview page
  const handleViewPreviewDetails = async (enquiryData) => {
    const cf = "hpv";
    const basePath =
      enquiryData?.enquiryType === "hotel" ? "hotel-preview" : "preview";
    navigate(`/${basePath}/${cf}/${enquiryData?.venueId}`);
  };
  return (
    <div className="bg-[#f9f9fc] min-h-[100vh]">
      <Navbar filters={filters} setFilters={setFilters} />
      <div className="h-full mx-auto md:w-[85%] w-[95%] mt-7">
        <div className="flex justify-start items-center gap-1">
          <Link to="/enquiries" className=" text-[#C2C2C2] text-[14px] ">
            My Enquiries
          </Link>
          <FaAngleRight className="text-[#C2C2C2] text-[16px]" />
          <Link to="#" className="text-black text-[14px]">
            Messages
          </Link>
        </div>
        <div className="w-full md:flex justify-between items-center md:py-10 py-4 md:h-[125vh] h-auto">
          <div className="md:w-[65%] w-full rounded-[8px] bg-white shadow-md h-full">
            <div className="w-full md:pt-0 pt-1">
              <div className="w-[93%] my-7 mx-auto md:flex justify-between items-start">
                <div className="flex justify-start items-center gap-3">
                  <div className="h-[40px] w-[40px] rounded-full flex justify-center items-center bg-[#eaeff1]">
                    <FaUser className="text-[#b4c3c8] text-[20px]" />
                  </div>
                  <div>
                    <h1 className="text-[20px] font-[500] text-[#000000]">
                      {enquiryData?.enquiryType === "hotel"
                        ? `${enquiryData?.hotelName}`
                        : `${enquiryData?.venueName}, ${enquiryData?.hotelName}`}
                    </h1>
                    <p className="text-[14px] text-[#000000]">
                      Response time : {vendorResponseTime} mins
                    </p>
                  </div>
                </div>
                <div className="md:w-[15%] w-full md:mt-0 mt-2">
                  {!(
                    enquiryData?.status === "Customer Declined" ||
                    enquiryData?.status === "Venue Declined" ||
                    enquiryData?.status === "Lost" ||
                    enquiryData?.status === "Successful"
                  ) ? (
                    <div>
                      <div
                        className=" bg-[#FE4747] cursor-pointer py-1 text-sm text-white rounded-[8px] flex justify-center items-center"
                        onClick={() => setIsModalVisible(true)}
                      >
                        Close Enquiry
                      </div>
                    </div>
                  ) : (
                    " "
                  )}
                  {enquiryData?.status === "Customer Declined" ||
                  enquiryData?.status === "Venue Declined" ||
                  enquiryData?.status === "Lost" ||
                  enquiryData?.status === "Successful" ? (
                    <p className="text-[13px] px-1 rounded-md text-center bg-[#FE4747] w-full py-1 text-white font-[500]">
                      {enquiryData?.status === "Customer Declined"
                        ? "Declined by you"
                        : enquiryData?.status === "Lost"
                        ? "Event date passed"
                        : enquiryData?.status}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="md:h-[70%] h-[80vh] overflow-y-auto w-full border-y border-y-[#ebebeb] flex justify-center items-end relative">
              <div className="messages-container w-full h-full overflow-y-auto custom-scrollbar py-2 px-4">
                <div className="p-4 md:w-[40%] w-[90%] mx-auto">
                  {/* Enquiry Box */}
                  <div className="flex flex-col mx-auto w-full  rounded-xl bg-white border border-gray-300 p-4 shadow-lg">
                    {/* Sender Info */}
                    <div className="flex items-center mb-2">
                      <div className="font-medium text-[16px] md:text-[14px] text-gray-800">
                        <p>You have sent an enquiry</p>
                      </div>
                    </div>
                    {/* Enquiry Details with Icons */}
                    <div className="text-sm text-gray-600 text-[10px] md:text-[12px]">
                      <div className="flex items-center">
                        <FaUsers className="mr-2  text-gray-600" />
                        <p>Party for {enquiryData?.guests} guests</p>
                      </div>
                      <div className="flex items-center">
                        <FaMapMarkerAlt className="mr-2  text-gray-600" />
                        <p>
                          {enquiryData?.hotelName} {enquiryData?.venueName}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <FaCalendarAlt className="mr-2  text-gray-600" />
                        <p>
                          {enquiryData?.startDate} - {enquiryData?.endDate}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <FaUtensils className="mr-2  text-gray-600" />
                        <p>Event Type ({enquiryData?.eventType})</p>
                      </div>
                      <div className="flex items-center">
                        <FaNotesMedical className="mr-2  text-gray-600" />
                        <p>Note: ({enquiryData?.additionalNotes})</p>
                      </div>
                    </div>

                    {/* Date */}
                    <div className="  text-gray-500 mt-2">
                      <p>{enquiryData?.createAt}</p>
                    </div>
                  </div>
                </div>
                {isLoading ? (
                  <p>Loading messages...</p>
                ) : isError ? (
                  <p>Error loading messages</p>
                ) : (
                  messages?.messages?.[0]?.message?.map((message) => {
                    return (
                      <>
                        <div
                          key={message?.time}
                          className="flex justify-start gap-2 items-start"
                        >
                          <div
                            className={`min-w-full flex flex-col mt-3 justify-${
                              message?.sender !== "customer" ? "start" : "end"
                            } items-${
                              message?.sender === "customer" ? "end" : "start"
                            }`}
                          >
                            <div className="flex justify-start items-start gap-2 md:max-w-[80%] mx-w-[90%]">
                              {message?.sender === "vendor" && (
                                <div className="min-h-[40px] min-w-[40px] rounded-full flex justify-center items-center bg-[#eaeff1]">
                                  {messages?.vendorProfilePic ? (
                                    <>
                                      <img
                                        src={messages?.vendorProfilePic}
                                        alt=""
                                        className="size-10 rounded-full"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <FaUser className="text-[#b4c3c8] text-[20px]" />
                                    </>
                                  )}
                                </div>
                              )}
                              <div
                                className={`flex flex-col -mt-[10px] ${
                                  message?.sender === "customer"
                                    ? "items-end"
                                    : "items-start justify-end"
                                }`}
                              >
                                <p>
                                  {message?.sender === "customer"
                                    ? enquiryData?.customerName
                                    : enquiryData?.vendorName}
                                </p>
                                <div
                                  className={`${
                                    message?.sender === "customer"
                                      ? "border border-[#DDDDDD] rounded-l-[12px]  text-[#222222] "
                                      : "bg-[#dfdfff] text-[#222222] rounded-r-[12px]"
                                  }   rounded-t-[12px] mt-1 mb-1 ${
                                    message?.isFile &&
                                    message?.fileType?.includes("image")
                                      ? ""
                                      : "p-3"
                                  }`}
                                >
                                  {message?.isFile ? (
                                    <div className="flex items-center gap-2">
                                      {message?.fileType?.includes("image") ? (
                                        <img
                                          src={message?.content}
                                          alt={message?.fileName}
                                          className="max-w-[200px] max-h-[200px] rounded-md object-cover"
                                        />
                                      ) : (
                                        <a
                                          href={message?.content}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-[#FE4747] underline"
                                        >
                                          {message?.fileName}
                                        </a>
                                      )}
                                    </div>
                                  ) : (
                                    <p className="text-[14px]">
                                      {message?.content}
                                    </p>
                                  )}
                                </div>
                                <p
                                  className={`text-[12px]  text-${
                                    message?.sender === "customer"
                                      ? "right"
                                      : "left"
                                  } mb-2 text-[#717171]`}
                                >
                                  {message?.time &&
                                    (() => {
                                      const date = new Date(message.time);
                                      const formattedDate = [
                                        String(date.getDate()).padStart(2, "0"),
                                        String(date.getMonth() + 1).padStart(
                                          2,
                                          "0"
                                        ),
                                        date.getFullYear(),
                                      ].join("-");
                                      const formattedTime = date
                                        .toTimeString()
                                        .slice(0, 5); // hh:mm
                                      return `${formattedTime} ${formattedDate}`;
                                    })()}
                                </p>
                              </div>
                              {message?.sender === "customer" && (
                                <div className="min-h-[40px] min-w-[40px] rounded-full flex justify-center items-center bg-[#eaeff1]">
                                  {messages?.customerProfilePic ? (
                                    <>
                                      <img
                                        src={messages?.customerProfilePic}
                                        alt=""
                                        className="size-10 rounded-full"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <FaUser className="text-[#b4c3c8] text-[20px]" />
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
                {(enquiryData?.status === "Customer Declined" ||
                  enquiryData?.status === "Venue Declined") && (
                  <div className="p-4 md:max-w-[80%] w-[90%] mx-auto">
                    {/* Enquiry Box */}
                    <div className="mx-auto  flex flex-col w-fit bg-white rounded-xl border border-gray-300 p-4 shadow-lg">
                      {/* Sender Info */}
                      <div className="flex items-center mb-2">
                        <div className="font-medium text-[16px] md:text-[14px] text-gray-800">
                          <p>
                            {enquiryData?.status === "Customer Declined"
                              ? "Declined by you"
                              : enquiryData?.status === "Venue Declined"
                              ? `Enquiry Declined by ${enquiryData?.vendorName}`
                              : ""}
                          </p>
                          {enquiryData?.status !== "Lost" && (
                            <p>
                              <strong>Reason: </strong>
                              {enquiryData?.declineReason}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {(enquiryData?.status === "Lost" ||
                enquiryData?.status === "Customer Declined" ||
                enquiryData?.status === "Venue Declined") &&
                !enquiryData?.enquiryUnsuccessfulClicked && (
                  <div className="flex-1 p-4 overflow-y-auto absolute top-0 z-[20] left-[50%] w-[90%] transform -translate-x-1/2">
                    {/* Enquiry Box */}
                    <div className="sm:mx-auto  flex flex-col  rounded-xl bg-white border border-gray-300 p-4 shadow-lg">
                      {/* Sender Info */}
                      <p className="md:text-[20px] text-[16px] font-[500] text-[#000000]">
                        Enquiry Unsuccessfull
                      </p>
                      <div className="flex items-center mb-2">
                        <div className="font-medium text-[16px] md:text-[14px] text-gray-800">
                          <p>
                            We're sorry, this particular enquiry didn't work
                            out. You can message the host and continue the
                            conversation or look for other venues.
                          </p>
                        </div>
                      </div>
                      <button
                        className="text-white p-2 rounded-md w-[200px] bg-[#01a287]"
                        onClick={() => {
                          navigate("/listing/1");
                          handleUpdateClick();
                        }}
                      >
                        Find Similar Venues
                      </button>
                    </div>
                  </div>
                )}
            </div>
            <div className="flex justify-center items-center w-[95%] mx-auto h-[15%]">
              <div className="flex justify-between items-center w-full">
                <div className="flex justify-start items-center gap-3 md:w-[90%] w-[85%] md:px-4 px-2 rounded-[8px] h-[48px] border border-[#DDDDDD]">
                  {isUploading && (
                    <div className="absolute bottom-full left-0 right-0 mb-1">
                      <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <div className="text-center text-xs text-gray-500 mt-1">
                        {uploadProgress}% Uploaded
                      </div>
                    </div>
                  )}
                  <IoIosAttach
                    onClick={handleAttachClick}
                    className="text-[24px] font-semibold text-[#000000] cursor-pointer"
                  />
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                  <input
                    type="text"
                    placeholder="Type something..."
                    className="w-[90%] h-[40px] border-none text-black placeholder-[#B0B0B0]  outline-none"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                </div>
                <div
                  className="w-[48px] h-[48px] rounded-[6px] bg-[#FF3939] flex justify-center items-center"
                  onClick={sendMessageHandler}
                >
                  <BsSend className="text-white text-[20px] mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[30%] w-full md:mt-0 mt-7 rounded-[8px] bg-white shadow-md h-full p-6">
            <div className="w-full h-[276px]">
              <img
                src={enquiryData?.venueImage}
                alt=""
                className="w-full h-full object-cover rounded-[8px]"
              />
            </div>

            <div className="w-full mt-6">
              <h2
                className="text-[20px] font-[500] text-[#222222] cursor-pointer"
                onClick={() => handleViewPreviewDetails(enquiryData)}
              >
                {enquiryData?.enquiryType === "hotel"
                  ? `${enquiryData?.hotelName}`
                  : `${enquiryData?.venueName}, ${enquiryData?.hotelName}`}
              </h2>

              <h3 className="text-[16px] text-[##212B36] font-[500] my-4">
                Enquiry Details
              </h3>

              <div className="flex flex-col justify-start items-center gap-2 w-full">
                <div className="flex justify-between items-center w-full">
                  <p className="text-[14px] text-[#717171]">Event Start Date</p>
                  <p className="text-[14px] text-[#000000]">
                    {new Date(enquiryData?.startDate)
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-")}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-[14px] text-[#717171]">Event End Date</p>
                  <p className="text-[14px] text-[#000000]">
                    {new Date(enquiryData?.endDate)
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-")}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-[14px] text-[#717171]">Start Time</p>
                  <p className="text-[14px] text-[#000000]">
                    {enquiryData?.startTime}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-[14px] text-[#717171]">End Time</p>
                  <p className="text-[14px] text-[#000000]">
                    {enquiryData?.endTime}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-[14px] text-[#717171]">Flexible</p>
                  <p className="text-[14px] text-[#000000]">
                    {enquiryData?.flexibility ? "Yes" : "No"}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-[14px] text-[#717171]">Event Budget</p>
                  <p className="text-[14px] text-[#000000]">
                    AED {enquiryData?.budget}
                  </p>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-[14px] text-[#717171]">Number of Guest</p>
                  <p className="text-[14px] text-[#000000]">
                    {enquiryData?.guests}
                  </p>
                </div>
                {enquiryData?.enquiryType === "hotel" && (
                  <>
                    <div className="flex justify-between items-center w-full">
                      <p className="text-[14px] text-[#717171]">
                        Number of Rooms
                      </p>
                      <p className="text-[14px] text-[#000000]">
                        {enquiryData?.rooms}
                      </p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p className="text-[14px] text-[#717171]">
                        Number of Venues Required
                      </p>
                      <p className="text-[14px] text-[#000000]">
                        {enquiryData?.numberOfVenues}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeclineModal
        isVisible={isModalVisible}
        enquiryId={enquiryData?._id}
        closeEnquiry={closeEnquiry}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default Messages;
