import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { base_URL } from "../../config/config";
const API_BASE_URL = `${base_URL}/api/vendor`;

// API function to fetch user by ID (Current vendor)
const fetchUserByIdAPI = async (vendorId) => {
  const response = await axios.get(`${API_BASE_URL}/get-vendor/${vendorId}`);
  return response.data;
};

// Hook to fetch user data (Current vendor)
export const useFetchUserByIdQuery = (vendorId) => {
  return useQuery({
    queryKey: ["user", vendorId],
    queryFn: () => fetchUserByIdAPI(vendorId),
    enabled: !!vendorId,
    retry: 3,
    staleTime: 5 * 60 * 1000,
  });
};
// API function to update organization details  (Organization Details)
const updateOrganizationDetailsAPI = async ({
  vendorId,
  organization,
  jobTitle,
}) => {
  const response = await axios.patch(
    `${base_URL}/api/dashboard/updateorganization`,
    {
      organization,
      jobTitle,
      vendorId,
    }
  );
  return response.data;
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOrganizationDetailsAPI,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};
