import React, { useState } from "react";

export default function StatusDropdown({ enquiry, updateStatus, user }) {
  const [isOpen, setIsOpen] = useState(false);

  const statusOptions = ["Active", "Successful", "Lost", "New"];

  const handleStatusChange = (newStatus) => {
    updateStatus(enquiry?._id, newStatus, `${newStatus} by ${user?.email}`);
    // Ensure the parent component updates the status immediately
    if (enquiry) {
      enquiry.status = newStatus;
    }
    setIsOpen(false); // Close the dropdown after selection
  };
  // Function to determine styles based on status
  const getStatusStyles = (status) => {
    switch (status) {
      case "Active":
        return "bg-[#EAF9F7] text-[#039F8D]";
      case "Successful":
        return "bg-[#DFDFFF] text-[#4A49D9]";
      case "Lost":
        return "bg-[#EBEBEB] text-[#717171]";
      case "New":
        return "bg-[#E4EFFF] text-[#1252B1]";
      case "Venue Declined":
        return "bg-[#FF3939]/20 text-[#FF3939]";
      default:
        return "bg-blue-50 text-blue-600";
    }
  };
  return (
    <div className="relative">
      <div
        className={`px-3 flex items-center justify-center gap-x-2 py-2 rounded-lg text-[14px] cursor-pointer ${getStatusStyles(
          enquiry?.status
        )}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {/* Showing active statuses */}
        {enquiry?.status === "Venue Declined"
          ? "venue declined"
          : enquiry?.status === "Not Accepted"
          ? "New"
          : enquiry?.status === "Customer Declined"
          ? "client declined"
          : enquiry?.status === "Active" || enquiry?.status === "New"
          ? "ongoing"
          : enquiry?.status}
        <span className="flex items-center justify-center">
          <img src="/downarrow.svg" alt="Arrow" />
        </span>
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute right-12 -top-16 bg-white shadow-lg rounded-lg z-10">
          {statusOptions?.map((option) => (
            <div
              key={option}
              onClick={() => handleStatusChange(option)}
              className={` px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 ${
                option === enquiry?.status
                  ? "bg-gray-200 text-blue-600"
                  : "text-gray-800"
              }`}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
