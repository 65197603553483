import React, { useState } from "react";
import BrochureModal from "../BrochureModal/BrochureModal";

const AboutThisHotel = ({ data }) => {
  // managing brochures view state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentType, setDocumentType] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const view = data?.additionalDetails?.venueView?.join(", ");
  const style = [
    ...data?.additionalDetails?.venueStyle.traditionalStyle,
    ...data?.additionalDetails?.venueStyle.culturalRegionalStyle,
    ...data?.additionalDetails?.venueStyle.eclecticStyle,
    ...data?.additionalDetails?.venueStyle.modernContemporaryStyle,
  ].join(", ");
  const handleViewBrochure = (type) => {
    setIsModalOpen(true);
    setDocumentType(type);
  };

  return (
    <div className="py-4 lg:w-3/4 w-full mx-auto">
      {/* Render the BrochureModal/floorplan modal/menu modal */}
      <BrochureModal
        brochures={
          documentType === "brochure"
            ? data?.UploadDocuments?.brochure
            : documentType === "sampleMenus"
            ? data?.UploadDocuments?.sampleMenus
            : documentType === "floorPlans"
            ? data?.UploadDocuments?.floorPlans
            : [] // Default to an empty array if no match
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          documentType === "brochure"
            ? "Brochure"
            : documentType === "floorPlans"
            ? "Floor Plan"
            : documentType === "sampleMenus"
            ? "Sample Menu"
            : "Document"
        }
      />
      <div className="bg-white rounded-lg ">
        <h2 className="text-2xl font-bold mb-4">About This Venue</h2>
        <div className="flex justify-between ">
          {/* Conditionally render the "View Brochure" button */}
          {data?.UploadDocuments?.brochure?.length > 0 && (
            <button
              onClick={() => handleViewBrochure("brochure")}
              className="text-primary font-medium mb-4"
            >
              View Brochure
            </button>
          )}
        </div>
        {/* <p
          className={`text-[#222222] text-xl font-normal mb-4 ${
            showMore ? "" : "line-clamp-3"
          }`}
        >
          {data?.basicDetails?.venueDescription}
        </p> */}
        <pre
         className={`whitespace-pre-line font-outfit font-[400] text-[#222222] mb-4 ${
          showMore ? "" : "line-clamp-3"
        }`}
        >
          {data?.basicDetails?.venueDescription}
        </pre>
        <button
          onClick={() => setShowMore(!showMore)}
          className="text-primary font-medium"
        >
          {showMore ? "Show Less" : "Show More"}
        </button>

        <div className="grid grid-cols-2 gap-4 p-8 w-full rounded-xl bg-[#F7F7F7] mt-6">
          <div className="col-span-1 border-r border-gray-400 pr-4">
            <h3 className="text-xl font-medium mb-2">Style</h3>
            <span className="text-[#222222] text-sm">{style}</span>
          </div>
          <div className="col-span-1 pl-4">
            <h3 className="text-xl font-medium mb-2">View</h3>
            <span className="text-[#222222] text-sm">{view}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutThisHotel;
