import React from "react";

function Responsezero() {
  return (
    <div className="w-full bg-[#FFFFFF]  rounded-xl md:w-[30%] ">
      <div className="ml-[20px] mt-[20px]">
        <div className="text-[14px] md:text-[20px] font-bold">
          Response Rate
        </div>
      </div>

      <div className=" h-full w-full flex flex-col gap-y-3 items-center mt-[64px]">
        <img src="/responsezero.svg" alt="" />
        <p className="text-[14px] md:text-[16px] text-[#222222] text-center font-normal">
          At this moment, no response rate is <br /> calculated
        </p>
      </div>
    </div>
  );
}

export default Responsezero;
