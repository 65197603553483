import React from "react";

function ByCOuntryzerostate() {
  return (
    <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px] h-[333px] md:h-[426px]">
      <div className="mb-[20px] md:mb-[100px] flex flex-col gap-1">
        <p className="text-[14px] md:text-[20px] font-bold">
          Most enquires received by countries
        </p>
      </div>

      <div className=" h-full w-full flex flex-col gap-y-3 items-center mt-[64px] md:mt-[66px]">
        <img src="/enquiryzero.svg" alt="" />
        <p className="text-[14px] md:text-[16px] text-[#222222] text-center font-normal">
          At this moment, you have not yet received any enquiries regarding any
          venue.
        </p>
      </div>
    </div>
  );
}

export default ByCOuntryzerostate;
