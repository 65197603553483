import { useNavigate } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import img2 from "../../assets2/images/enquiry-sent.png";

const EnquirySuccessfulModel = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const redirectToListing = () => {
    navigate("/listing/1");
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white w-[624px] h-[486px] rounded-[8px] relative flex flex-col justify-center items-center">
        <RxCross2
          onClick={() => setIsOpen(false)}
          className="absolute top-4 right-4 text-[#000000] cursor-pointer"
        />
        <img
          src={img2}
          alt="icon"
          className="w-[212px] h-[212px] object-contain"
        />
        <h2 className="text-black text-[20px] font-medium mt-4">
          Your enquiry sent successfully!
        </h2>
        <p className="text-[16px] font-[400] text-black mt-3">
          Meanwhile, you can check out other spaces at Findmyvenue
        </p>
        <button
          onClick={() => redirectToListing()}
          className="bg-[#FE4747] text-white w-[183px] h-[48px] rounded-[8px] flex justify-center items-center mt-4"
        >
          Continue Exploring
        </button>
        <a href="/" class="text-[16px] mt-1 font-medium underline text-black">
          Go back to home page
        </a>
      </div>
    </div>
  );
};

export default EnquirySuccessfulModel;
