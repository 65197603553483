import axios from "axios";
import { base_URL } from "../config/config";

const API_BASE_URL = `${base_URL}/api`;

export const login = async (loginData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/vendor/auth/login`,
      loginData
    );
    return response.data;
  } catch (error) {
    // console.error('Error registering venue:', error);

    // Extract the error message from the response
    if (error.response && error.response.data) {
      // Return the error message from the response
      throw new Error(
        error.response.data.message || "An error occurred during Login."
      );
    } else {
      // Handle cases where there is no response (network error, etc.)
      throw new Error(
        "An error occurred during Login. Please try again later."
      );
    }
  }
};
