const EnquiryIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M7.49998 14.7915H12.5C12.6773 14.7915 12.8258 14.7317 12.9455 14.6121C13.0651 14.4924 13.125 14.3439 13.125 14.1666C13.125 13.9892 13.0651 13.8407 12.9455 13.7211C12.8258 13.6014 12.6773 13.5416 12.5 13.5416H7.49998C7.32263 13.5416 7.17413 13.6014 7.05448 13.7211C6.93483 13.8407 6.875 13.9892 6.875 14.1666C6.875 14.3439 6.93483 14.4924 7.05448 14.6121C7.17413 14.7317 7.32263 14.7915 7.49998 14.7915ZM7.49998 11.4582H12.5C12.6773 11.4582 12.8258 11.3984 12.9455 11.2787C13.0651 11.1591 13.125 11.0106 13.125 10.8332C13.125 10.6559 13.0651 10.5074 12.9455 10.3877C12.8258 10.2681 12.6773 10.2083 12.5 10.2083H7.49998C7.32263 10.2083 7.17413 10.2681 7.05448 10.3877C6.93483 10.5074 6.875 10.6559 6.875 10.8332C6.875 11.0106 6.93483 11.1591 7.05448 11.2787C7.17413 11.3984 7.32263 11.4582 7.49998 11.4582ZM5.25642 17.9165C4.83547 17.9165 4.47917 17.7707 4.1875 17.479C3.89583 17.1874 3.75 16.8311 3.75 16.4101V3.58967C3.75 3.16873 3.89583 2.81242 4.1875 2.52075C4.47917 2.22909 4.83547 2.08325 5.25642 2.08325H11.2516C11.4525 2.08325 11.6456 2.12225 11.8309 2.20025C12.0163 2.27824 12.1773 2.38561 12.3141 2.52236L15.8109 6.01911C15.9476 6.15587 16.055 6.31693 16.133 6.50229C16.211 6.68766 16.25 6.88076 16.25 7.08161V16.4101C16.25 16.8311 16.1041 17.1874 15.8125 17.479C15.5208 17.7707 15.1645 17.9165 14.7435 17.9165H5.25642ZM11.25 6.33V3.33323H5.25642C5.19231 3.33323 5.13353 3.35994 5.0801 3.41336C5.02669 3.46679 4.99998 3.52556 4.99998 3.58967V16.4101C4.99998 16.4742 5.02669 16.533 5.0801 16.5864C5.13353 16.6399 5.19231 16.6666 5.25642 16.6666H14.7435C14.8077 16.6666 14.8664 16.6399 14.9199 16.5864C14.9733 16.533 15 16.4742 15 16.4101V7.08321H12.0032C11.7885 7.08321 11.6093 7.01136 11.4656 6.86765C11.3219 6.72395 11.25 6.54474 11.25 6.33Z"
      />
    </svg>
  );
};

export default EnquiryIcon;
