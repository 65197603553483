import React, { useEffect, useState } from "react";
import CustomListingSelector from "../../shared/CustomListingSelector";
import CustomCheckBox from "../../shared/CustomCheckbox";
import PriceRangeGraph from "../../shared/PriceRangeGraph";
import { Filter } from "../../../svgs";
import { LuSearch } from "react-icons/lu";
import {
  cities,
  eventTypes,
  venueType,
  venueHire,
  Catering,
  venueSettings,
  Amenities,
  Menu,
  statesAndCities,
} from "../../../data";
import { RxCross1 } from "react-icons/rx";
import EventListingSelector from "../../shared/EventListingSelector";
import CustomInput from "../../shared/CustomInput";
import { IoMdArrowDropdown } from "react-icons/io";

function countValidKeyValuePairs(obj) {
  let count = 0;

  // Iterate through the keys of the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check the conditions based on the value type
      if (Array.isArray(value)) {
        // Check if the array length is at least 1
        if (value.length >= 1) {
          count++;
        }
      } else if (typeof value === "string") {
        // Check if the string has at least 1 character
        if (value.length > 0) {
          count++;
        }
      } else if (typeof value === "number") {
        // Check if the number is greater than zero
        if (value > 0) {
          count++;
        }
      } else if (typeof value === "object" && value !== null) {
        // Check if the object is not null or undefined
        if (Object.keys(value).length > 0) {
          // Checks if the object has any properties
          count++;
        }
      }
    }
  }

  return count;
}

const popupKeyPair = {
  eventTypes: false,
  venueTypes: false,
  venueHire: false,
  catering: false,
  venueSettings: false,
  amenities: false,
  menus: false,
  area: false,
  guests: false,
  offersAvailable: false,
  minSpendPerPerson: false,
  minSpendPerEvent: false,
  numberOfRooms: false,
};
const VenueFilter = ({ filters, setFilters }) => {
  // console.log(filters);
  const [showFilter, setShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [city, setCity] = useState(filters.city);
  const [selectedEventTypes, setSelectedEventTypes] = useState([
    filters.eventTypes,
  ]);
  const [selectedVenueTypes, setSelectedVenueTypes] = useState([
    filters.venueTypes,
  ]);
  const [selectedVenueHire, setSelectedVenueHire] = useState("");
  const [numberOfStandingGuests, setNumberOfStandingGuests] = useState(
    filters.standingGuests
  );
  const [numberOfSittingGuests, setNumberOfSittingGuests] = useState(
    filters.standingGuests
  );
  const [guestType, setGuestType] = useState("standing"); // Tracks which button is pressed (standing or sitting)
  const [inputValue, setInputValue] = useState(filters.standingGuests); // General input value
  const [offersAvailable, setOffersAvailable] = useState(false);
  const [selectedCatering, setSelectedCatering] = useState([]);
  const [selectedVenueSetting, setSelectedVenueSetting] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [numberOfRooms, setNumberOfRooms] = useState(filters.numberOfRooms);
  const [openPopup, setOpenPopup] = useState(popupKeyPair);

  const [minSpendPerPerson, setMinSpendPerPerson] = useState({
    min: 0,
    max: 6000,
  });

  const [minSpendPerEvent, setMinSpendPerEvent] = useState({
    min: 0,
    max: 6000,
  });

  const handlePricePerEventChange = (minValue, maxValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      minSpendPerEvent: { max: maxValue, min: minValue },
    }));
  };
  const handleOpenPopup = (name) => {
    setOpenPopup((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  const handlePricePerPersonChange = (minValue, maxValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      minSpendPerPerson: { max: maxValue, min: minValue },
      setMinSpendPerPerson: {
        min: {
          min: minValue * filters.standingGuests,
          max: maxValue * filters.standingGuests,
        },
      },
    }));
  };

  useEffect(() => {
    countValidKeyValuePairs(filters);
  }, [filters]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: e.target.value,
    }));
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      city: e.target.value,
    }));
  };

  const handleEventTypesChange = (selectedOptions) => {
    setSelectedEventTypes(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      eventTypes: selectedOptions,
    }));
  };

  const handleVenueTypesChange = (selectedOptions) => {
    setSelectedVenueTypes(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      venueTypes: selectedOptions,
    }));
  };

  const handleVenueHireChange = (selectedOptions) => {
    setSelectedVenueHire(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      venueHire: selectedOptions,
    }));
  };

  const handleGuestNumberChange = () => {
    if (guestType === "standing") {
      setNumberOfStandingGuests(inputValue);
      setFilters((prevFilters) => ({
        ...prevFilters,
        standingGuests: inputValue,
      }));
    } else {
      setNumberOfSittingGuests(inputValue);
      setFilters((prevFilters) => ({
        ...prevFilters,
        sittingGuests: inputValue,
      }));
    }
  };

  const handleCateringChange = (selectedOptions) => {
    setSelectedCatering(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      catering: selectedOptions,
    }));
  };

  const handleVenueSettingChange = (selectedOptions) => {
    setSelectedVenueSetting(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      venueSettings: selectedOptions,
    }));
  };

  const handleAmenitiesChange = (selectedOptions) => {
    setSelectedAmenities(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      amenities: selectedOptions,
    }));
  };

  const handleRoomChange = (e) => {
    setNumberOfRooms(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      numberOfRooms: e.target.value,
    }));
  };

  const handleMenusChange = (selectedOptions) => {
    setSelectedMenus(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      menus: selectedOptions,
    }));
  };

  const handleAreaChange = (selectedOptions) => {
    setSelectedArea(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      area: selectedOptions,
    }));
  };

  const handleOffersChange = () => {
    setOffersAvailable(!offersAvailable);
    setFilters((prevFilters) => ({
      ...prevFilters,
      offersAvailable: !offersAvailable,
    }));
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = (type) => {
    setGuestType(type);
    if (type === "standing") {
      setInputValue(numberOfStandingGuests);
    } else {
      setInputValue(numberOfSittingGuests);
    }
  };

  const handleShowFilters = () => {
    setShowFilter(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseFilters = () => {
    setShowFilter(false);
    document.body.style.overflow = "auto";
  };

  const resetAll = () => {
    setCity("");
    setSelectedEventTypes([]);
    setSelectedVenueTypes([]);
    setSelectedVenueHire("");
    setNumberOfStandingGuests("");
    setNumberOfSittingGuests("");
    setGuestType(null);
    setInputValue("");
    setNumberOfRooms("");
    setOffersAvailable(false);
    setSelectedCatering([]);
    setSelectedVenueSetting([]);
    setSelectedAmenities([]);
    setSelectedMenus([]);
    setSelectedArea([]);
    setSearchValue("");
    setFilters({
      city: "",
      eventTypes: [],
      venueTypes: [],
      venueHire: "",
      standingGuests: "",
      sittingGuests: "",
      catering: [],
      venueSettings: [],
      amenities: [],
      menus: [],
      area: [],
      offersAvailable: false,
    });
  };

  return (
    <div className=" w-full   flex flex-col  border-grayNeutral ">
      <div className="md:flex hidden items-center justify-between w-full py-4">
        <h1 className="text-dark lg:text-2xl sm:text-xl font-semibold text-lg">
          Filter by {countValidKeyValuePairs(filters)}
        </h1>
        <p
          className="text-[#FE4747] underline font-medium cursor-pointer hover:text-dark"
          onClick={resetAll}
        >
          Reset All
        </p>
      </div>

      {
        <div className="flex items-end justify-between sm:gap-5 md:mt-0 mt-4 gap-3">
          <div className="flex-1">
            <p className="mb-2">Search</p>
            <div className="border border-grayNeutral flex items-center gap-2 justify-between px-3 py-2 rounded-full">
              <input
                type="text"
                placeholder="Search"
                className="bg-transparent outline-none w-[90%]"
                name=""
                id=""
                value={filters.search || searchValue}
                onChange={handleSearchChange}
              />
              <LuSearch className="flex-shrink-0" />
            </div>
          </div>
          <div
            onClick={handleShowFilters}
            className="border md:hidden block h-full mb-1 rounded-md p-3"
          >
            <Filter />
          </div>
        </div>
      }

      <div className="mt-4 md:block hidden">
        <p className="text-dark mb-2">Location (Cities)</p>
        <div className="relative cursor-pointer">
          <select
            value={city}
            onChange={handleCityChange}
            className="w-full text-grayNeutral py-2 px-3 cursor-pointer appearance-none border outline-none border-bordered rounded-md"
          >
            <option value="" disabled>
              {filters.city || "Select City"}
            </option>
            {cities.map((city) => (
              <option key={city.value} value={city.value}>
                {city.label}
              </option>
            ))}
          </select>
          <IoMdArrowDropdown className="absolute text-dark text-xl right-2 top-1/2 -translate-y-1/2" />
        </div>
      </div>

      <div className="md:block hidden">
        <EventListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("eventTypes")}>
                Event Type{" "}
              </span>
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedEventTypes([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    eventTypes: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          handleOpenPopup={() => handleOpenPopup("eventTypes")}
          openPopup={openPopup.eventTypes}
          events={eventTypes}
          selectedOptions={filters.eventTypes || selectedEventTypes} // Pass selected options
          setSelectedOptions={handleEventTypesChange} // Pass setter function
        />
        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("venueTypes")}>
                Venue Type{" "}
              </span>
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedVenueTypes([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    venueTypes: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          handleOpenPopup={() => handleOpenPopup("venueTypes")}
          openPopup={openPopup.venueTypes}
          selectOptions={venueType}
          selectedOptions={filters.venueTypes || selectedVenueTypes} // Pass selected options
          setSelectedOptions={handleVenueTypesChange} // Pass setter function
        />
        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("guests")}>
                No. of Guests{" "}
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setNumberOfSittingGuests("");
                  setNumberOfStandingGuests("");
                  setInputValue("");
                  setGuestType(null);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    standingGuests: "",
                    sittingGuests: "",
                    guestType: null,
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          content={
            <div>
              <input
                type="number"
                className="w-full py-2 px-3 outline-none border border-grayNeutral bg-transparent rounded-lg"
                placeholder={`Enter number of ${guestType || "guests"}`}
                value={inputValue}
                onChange={handleInputChange}
              />

              <div className="flex items-center gap-3 w-full mt-3">
                <button
                  className={`border ${
                    guestType === "standing" ? "bg-black text-white" : ""
                  } border-dark w-full py-2 px-3 rounded-md`}
                  onClick={() => handleButtonClick("standing")}
                >
                  Standing
                </button>
                <button
                  className={`border ${
                    guestType === "sitting" ? "bg-black text-white" : ""
                  } border-dark w-full py-2 px-3 rounded-md`}
                  onClick={() => handleButtonClick("sitting")}
                >
                  Sitting
                </button>
              </div>

              {guestType && (
                <button
                  className="mt-3 bg-[#FE4747] text-white py-2 px-4 rounded-md border w-full"
                  onClick={handleGuestNumberChange}
                >
                  Apply
                </button>
              )}

              {/* Display the saved values */}
              {numberOfStandingGuests && guestType === "sitting" && (
                <div className="mt-4">
                  <p className="font-medium">
                    Number of Standing Guests: {numberOfStandingGuests}
                  </p>
                </div>
              )}
              {numberOfSittingGuests && guestType === "standing" && (
                <div className="mt-4">
                  <p className="font-medium">
                    Number of Sitting Guests: {numberOfSittingGuests}
                  </p>
                </div>
              )}
            </div>
          }
          handleOpenPopup={() => handleOpenPopup("guests")}
          openPopup={openPopup.guests}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("minSpendPerPerson")}>
                Total Budget Per Person (AED)
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setMinSpendPerPerson({ min: null, max: null });
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    minSpendPerPerson: null,
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          content={
            <PriceRangeGraph
              minSpendPerPerson={minSpendPerPerson}
              setMinSpendPerPerson={setMinSpendPerPerson}
              updateFilters={handlePricePerPersonChange}
            />
          }
          handleOpenPopup={() => handleOpenPopup("minSpendPerPerson")}
          openPopup={openPopup.minSpendPerPerson}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("minSpendPerEvent")}>
                Total Budget Per Event (AED){" "}
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setMinSpendPerEvent({ min: 0, max: 6000 });
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    minSpendPerEvent: null,
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          content={
            <PriceRangeGraph
              minSpendPerPerson={minSpendPerEvent}
              setMinSpendPerPerson={setMinSpendPerEvent}
              updateFilters={handlePricePerEventChange}
            />
          }
          handleOpenPopup={() => handleOpenPopup("minSpendPerEvent")}
          openPopup={openPopup.minSpendPerEvent}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("offersAvailable")}>
                Offers
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setOffersAvailable(false);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    offersAvailable: false,
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          content={
            <CustomCheckBox
              label={"Offers Available"}
              isChecked={offersAvailable}
              onChange={handleOffersChange}
            />
          }
          handleOpenPopup={() => handleOpenPopup("offersAvailable")}
          openPopup={openPopup.offersAvailable}
        />
        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("area")}>Area</span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedArea([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    area: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={
            city
              ? statesAndCities[city]
              : [{ name: "No city selected yet!", value: "" }]
          }
          selectedOptions={selectedArea} // Pass selected options
          setSelectedOptions={handleAreaChange}
          handleOpenPopup={() => handleOpenPopup("area")}
          openPopup={openPopup.area}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("venueHire")}>
                Venue Hire
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedVenueHire("");
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    venueHire: "",
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={venueHire}
          selectedOptions={selectedVenueHire} // Pass selected options
          setSelectedOptions={handleVenueHireChange}
          handleOpenPopup={() => handleOpenPopup("venueHire")}
          openPopup={openPopup.venueHire}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("catering")}>
                Catering & Drinks
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedCatering([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    catering: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={Catering}
          selectedOptions={selectedCatering} // Pass selected options
          setSelectedOptions={handleCateringChange}
          handleOpenPopup={() => handleOpenPopup("catering")}
          openPopup={openPopup.catering}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("venueSettings")}>
                Venue Settings
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedVenueSetting([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    venueSettings: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={venueSettings}
          selectedOptions={selectedVenueSetting}
          setSelectedOptions={handleVenueSettingChange}
          handleOpenPopup={() => handleOpenPopup("venueSettings")}
          openPopup={openPopup.venueSettings}
        />
        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("numberOfRooms")}>
                No. of Rooms
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setNumberOfRooms("");
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    numberOfRooms: "",
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          content={
            <CustomInput
              placeholder={"Enter your input"}
              type="number"
              value={numberOfRooms}
              onChange={handleRoomChange}
              name="numberOfRooms"
            />
          }
          handleOpenPopup={() => handleOpenPopup("numberOfRooms")}
          openPopup={openPopup.numberOfRooms}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("amenities")}>
                Amenities
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedAmenities([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    amenities: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={Amenities}
          selectedOptions={selectedAmenities} // Pass selected options
          setSelectedOptions={handleAmenitiesChange}
          handleOpenPopup={() => handleOpenPopup("amenities")}
          openPopup={openPopup.amenities}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("menus")}>Menu</span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedMenus([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    menus: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={Menu}
          selectedOptions={selectedMenus} // Pass selected options
          setSelectedOptions={handleMenusChange}
          handleOpenPopup={() => handleOpenPopup("menus")}
          openPopup={openPopup.menus}
        />
      </div>

      {/* filters in mobile view */}
      {showFilter && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="bg-white h-full w-full px-4 pt-6 overflow-y-auto">
            <div className="relative">
              <div className="flex items-center justify-between w-full py-4">
                <h1 className="text-dark lg:text-2xl sm:text-xl font-semibold text-lg">
                  Filter by {Object.keys(filters).length || "0"}
                </h1>
                <div className="" onClick={handleCloseFilters}>
                  <RxCross1 className="text-xl" />
                </div>
              </div>

              <div className="flex-1">
                <p className="mb-2">Search</p>
                <div className="border border-grayNeutral flex items-center gap-2 justify-between px-3 py-2 rounded-full">
                  <input
                    type="text"
                    placeholder="Search"
                    value={filters.search || searchValue}
                    onChange={handleSearchChange}
                    className="bg-transparent outline-none w-[90%]"
                    name=""
                    id=""
                  />
                  <LuSearch className="flex-shrink-0" />
                </div>
              </div>

              <div className="mt-4">
                <p className="text-dark mb-2">Location (Cities)</p>
                <div className="relative cursor-pointer">
                  <select
                    value={city}
                    onChange={handleCityChange}
                    className="w-full text-grayNeutral py-2 px-3 cursor-pointer appearance-none border outline-none border-bordered rounded-md"
                  >
                    <option value="" disabled>
                      {filters.city || "Select City"}
                    </option>
                    {cities.map((city) => (
                      <option key={city.value} value={city.value}>
                        {city.label}
                      </option>
                    ))}
                  </select>
                  <IoMdArrowDropdown className="absolute text-dark text-xl right-2 top-1/2 -translate-y-1/2" />
                </div>
              </div>

              <div className="pb-4">
                <EventListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("eventTypes")}>
                        Event Type
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedEventTypes([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            eventTypes: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  events={eventTypes}
                  selectedOptions={filters.eventTypes || selectedEventTypes} // Pass selected options
                  setSelectedOptions={handleEventTypesChange}
                  handleOpenPopup={() => handleOpenPopup("eventTypes")}
                  openPopup={openPopup.eventTypes}
                />
                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("venueTypes")}>
                        Venue Type
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedVenueTypes([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            venueTypes: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={venueType}
                  selectedOptions={filters.venueTypes || selectedVenueTypes} // Pass selected options
                  setSelectedOptions={handleVenueTypesChange}
                  handleOpenPopup={() => handleOpenPopup("venueTypes")}
                  openPopup={openPopup.venueTypes}
                />
                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("guests")}>
                        No. of Guests
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setNumberOfSittingGuests("");
                          setNumberOfStandingGuests("");
                          setInputValue("");
                          setGuestType(null);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            standingGuests: "",
                            sittingGuests: "",
                            guestType: null,
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  content={
                    <div>
                      <input
                        type="number"
                        className="w-full py-2 px-3 outline-none border border-grayNeutral bg-transparent rounded-lg"
                        placeholder={`Enter number of ${guestType || "guests"}`}
                        value={inputValue}
                        onChange={handleInputChange}
                      />

                      <div className="flex items-center gap-3 w-full mt-3">
                        <button
                          className={`border ${
                            guestType === "standing"
                              ? "bg-black text-white"
                              : ""
                          } border-dark w-full py-2 px-3 rounded-md`}
                          onClick={() => handleButtonClick("standing")}
                        >
                          Standing
                        </button>
                        <button
                          className={`border ${
                            guestType === "sitting" ? "bg-black text-white" : ""
                          } border-dark w-full py-2 px-3 rounded-md`}
                          onClick={() => handleButtonClick("sitting")}
                        >
                          Sitting
                        </button>
                      </div>

                      {guestType && (
                        <button
                          className="mt-3 bg-[#FE4747] text-white py-2 px-4 rounded-md border w-full"
                          onClick={handleGuestNumberChange}
                        >
                          Apply
                        </button>
                      )}

                      {/* Display the saved values */}
                      {numberOfStandingGuests && guestType === "sitting" && (
                        <div className="mt-4">
                          <p className="font-medium">
                            Number of Standing Guests: {numberOfStandingGuests}
                          </p>
                        </div>
                      )}
                      {numberOfSittingGuests && guestType === "standing" && (
                        <div className="mt-4">
                          <p className="font-medium">
                            Number of Sitting Guests: {numberOfSittingGuests}
                          </p>
                        </div>
                      )}
                    </div>
                  }
                  handleOpenPopup={() => handleOpenPopup("guests")}
                  openPopup={openPopup.guests}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span
                        onClick={() => handleOpenPopup("minSpendPerPerson")}
                      >
                        Total Budget Per Person (AED)
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setMinSpendPerPerson({ min: 0, max: 6000 });
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            minSpendPerPerson: { max: 6000, min: 0 },
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  content={
                    <PriceRangeGraph
                      minSpendPerPerson={minSpendPerPerson}
                      setMinSpendPerPerson={setMinSpendPerPerson}
                      updateFilters={handlePricePerPersonChange}
                    />
                  }
                  handleOpenPopup={() => handleOpenPopup("minSpendPerPerson")}
                  openPopup={openPopup.minSpendPerPerson}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("minSpendPerEvent")}>
                        Total Budget Per Event (AED){" "}
                      </span>
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setMinSpendPerEvent({ min: 0, max: 6000 });
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            minSpendPerEvent: { max: 6000, min: 0 },
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  content={
                    <PriceRangeGraph
                      minSpendPerPerson={minSpendPerEvent}
                      setMinSpendPerPerson={setMinSpendPerEvent}
                      updateFilters={handlePricePerEventChange}
                    />
                  }
                  handleOpenPopup={() => handleOpenPopup("minSpendPerEvent")}
                  openPopup={openPopup.minSpendPerEvent}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("offersAvailable")}>
                        Offers
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setOffersAvailable(false);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            offersAvailable: false,
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  content={
                    <CustomCheckBox
                      label={"Offers Available"}
                      isChecked={offersAvailable}
                      onChange={handleOffersChange}
                    />
                  }
                  handleOpenPopup={() => handleOpenPopup("offersAvailable")}
                  openPopup={openPopup.offersAvailable}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("area")}>Area </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedArea([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            area: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={
                    city
                      ? statesAndCities[city]
                      : [{ name: "No city selected yet!", value: "" }]
                  }
                  selectedOptions={selectedArea} // Pass selected options
                  setSelectedOptions={handleAreaChange}
                  handleOpenPopup={() => handleOpenPopup("area")}
                  openPopup={openPopup.area}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("venueHire")}>
                        Venue Hire{" "}
                      </span>
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedVenueHire("");
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            venueHire: "",
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={venueHire}
                  selectedOptions={selectedVenueHire} // Pass selected options
                  setSelectedOptions={handleVenueHireChange}
                  handleOpenPopup={() => handleOpenPopup("venueHire")}
                  openPopup={openPopup.venueHire}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("catering")}>
                        Catering & Drinks{" "}
                      </span>
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedCatering([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            catering: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={Catering}
                  selectedOptions={selectedCatering} // Pass selected options
                  setSelectedOptions={handleCateringChange}
                  handleOpenPopup={() => handleOpenPopup("catering")}
                  openPopup={openPopup.catering}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("venueSettings")}>
                        Venue Settings{" "}
                      </span>
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedVenueSetting([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            setSelectedVenueTypes: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={venueSettings}
                  selectedOptions={selectedVenueSetting} // Pass selected options
                  setSelectedOptions={handleVenueSettingChange}
                  handleOpenPopup={() => handleOpenPopup("venueSettings")}
                  openPopup={openPopup.venueSettings}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("numberOfRooms")}>
                        No. of Rooms
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setNumberOfRooms("");
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            numberOfRooms: "",
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  content={
                    <CustomInput
                      placeholder={"Enter your input"}
                      type="number"
                      value={numberOfRooms}
                      onChange={handleRoomChange}
                      name="numberOfRooms"
                    />
                  }
                  handleOpenPopup={() => handleOpenPopup("numberOfRooms")}
                  openPopup={openPopup.numberOfRooms}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("amenities")}>
                        Amenities{" "}
                      </span>
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedAmenities([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            amenities: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={Amenities}
                  selectedOptions={selectedAmenities} // Pass selected options
                  setSelectedOptions={handleAmenitiesChange}
                  handleOpenPopup={() => handleOpenPopup("amenities")}
                  openPopup={openPopup.amenities}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("menus")}>
                        Menu{" "}
                      </span>
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedMenus([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            menus: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={Menu}
                  selectedOptions={selectedMenus} // Pass selected options
                  setSelectedOptions={handleMenusChange}
                  handleOpenPopup={() => handleOpenPopup("menus")}
                  openPopup={openPopup.menus}
                />
              </div>

              <div className="flex sticky bottom-0 shadow-lg items-center gap-4 bg-white py-1.5 text-sm w-full">
                <button
                  className="bg-white py-3 flex justify-center items-center underline w-full"
                  onClick={() => {
                    resetAll();
                    handleCloseFilters();
                  }}
                >
                  Reset all
                </button>
                <button
                  className="bg-primary py-3 rounded-md flex justify-center text-white items-center  w-full"
                  onClick={() => {
                    handleCloseFilters();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenueFilter;
