export const categories = [
  {
    name: "Business",
    subcategories: [
      {
        name: "Conference/Seminars",
        options: [
          "Annual General meeting",
          "Auctions",
          "Board Meetings",
          "Breakout",
          "Conference",
          "Conventions",
          "Executive Summits",
          "Exhibitions",
          "Meetings",
          "Networking",
          "Presentation",
          "Press Conference",
          "Product Launch",
          "Seminars",
          "Symposium",
          "Trade Shows",
          // "Training",
          "Workshops",
        ],
      },
      {
        name: "Corporate Events",
        options: [
          "Activity Day",
          "Award Ceremony",
          "Away day",
          "Brainstorm",
          "Charity event",
          "Corporate Gala dinner",
          "Corporate Reception",
          "Corporate Retreat",
          "FAM Trip",
          "Fundraiser",
          "Incentive trip",
          "Office Party",
          "Outdoor Activity",
          "Pop Up event",
          "PR events",
          "Promotional Events",
          "Staff Party",
          "Summer Party",
          "Team Building",
          "Training Program",
        ],
      },
    ],
  },
  {
    name: "Wedding & Dining",
    subcategories: [
      {
        name: "Weddings",
        options: [
          "Arabic Wedding",
          "Beach Wedding",
          "Christian Wedding",
          "Church Wedding",
          "Civil Partnership",
          "Civil Wedding",
          "Garden Wedding",
          "Hindu Wedding",
          "Indian Wedding",
          "Marquee Wedding",
          "Outdoor Wedding",
          "Unique Wedding",
          "Wedding Ceremony",
          "Wedding Reception",
        ],
      },
      {
        name: "Dining",
        options: [
          "BBQ",
          "Anniversary Party",
          "Cocktail Masterclass",
          "Cocktail Reception",
          "Condolences",
          "Drinks Reception",
          "Engagement",
          "Farewell Party",
          "Funeral reception",
          "Graduation Party",
          "New Years Eve Party",
          "Party",
          "Private Party",
          "Retirement Party",
          "Reunions",
          "Stag Party",
          "Wine Tasting",
        ],
      },
      {
        name: "Milestone Birthdays",
        options: [
          "16th Birthday Party",
          "18th Birthday Party",
          "21st Birthday Party",
          "25th Birthday Party",
          "30th Birthday Party",
          "40th Birthday Party",
          "50th Birthday Party",
          "Birthday Party",
        ],
      },
      {
        name: "Tea Party/Showers",
        options: [
          "Afternoon Tea",
          "Baby Christening",
          "Baby Shower",
          "Bachelorette Party/Bachelor",
          "Bridal Shower",
          "High Tea",
        ],
      },
      {
        name: "Teen/Children",
        options: ["Children Birthday Party", "Kids Party", "Teen Party"],
      },
      {
        name: "Special Big Events",
        options: [
          "Gender Reveals",
          "Proposals",
          "Christmas dinner",
          "Christmas Party",
          "Diwali",
          "Eid",
          "Halloween Party",
          "Holiday Party",
          "Iftar",
          "Ladies night",
          "Ramadan",
          "Suhoor",
          "Xmas Party",
        ],
      },
    ],
  },
  {
    name: "Art & Cultural",
    subcategories: [
      {
        name: "Filming, Photography & Media",
        options: ["Filming/Video Recording", "Photoshoot", "Podcast Recording"],
      },
      {
        name: "Cultural",
        options: ["Book Launch", "Film Festival", "Music Festival"],
      },
      {
        name: "Art Spaces",
        options: ["Art Exhibitions", "Pop Up Event"],
      },
      {
        name: "Performances & Exhibitions",
        options: [
          "Concert",
          "Dance Performance",
          "Fashion Show",
          "GIG",
          "Live Performance",
          "Performing Arts",
          "Screening",
          "Stand-up Comedy",
          "Theatre (Drama/Plays)",
        ],
      },
    ],
  },
];
