import React, { useState } from "react";
import { Modal } from "./SettingsDashboard";
import PassModal from "./PassModal";

function LoginDetails({ setLoginModal, loginDetails, user }) {
  const [passModal, setPassModal] = useState(false);

  const handleChangePassword = () => {
    setPassModal(true);
  };

  return (
    <>
      <Modal isOpen={passModal} onClose={() => setPassModal(false)}>
        <PassModal user={user} setPassModal={setPassModal} />
      </Modal>
      <div className="mb-8">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <h2 className="text-[20px] text-[#212B36] font-medium">
              Login Details
            </h2>
          </div>
          <button
            onClick={() => setLoginModal(true)}
            className="active:opacity-55 w-[53px] h-[25px] text-[14px] text-[#222222] border border-[#27C2B0] rounded-md hover:bg-gray-50"
          >
            Edit
          </button>
        </div>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <p className="text-[14px] text-[#717171] font-normal">
              Mobile Number
            </p>
            <p className="text-[#222222] text-[16px]">+{user?.phone}</p>
          </div>

          <div className="flex justify-between items-center">
            <p className="text-[14px] text-[#717171] font-normal">Password</p>
            <p className="text-[#222222] text-[16px]">
              {loginDetails.password}
            </p>
          </div>
        </div>
        <div
          className="flex justify-end items-end"
          onClick={handleChangePassword}
        >
          <button className="text-[#FE4747] text-[16px] active:opacity-50 font-medium">
            Change Password?
          </button>
        </div>
      </div>
    </>
  );
}

export default LoginDetails;
