import ResponsiveAppBar from "../../../components/ResponsiveAppBar";

const Policy = () => {
  return (
    <>
      <ResponsiveAppBar />
      <div className="flex justify-center ">
        <div className="min-h-screen  sm:p-8 p-4 md:w-[70%] sm:w-[80%] w-full text-justify">
          <h1 className="text-3xl font-bold text-center mb-8 text-custom">
            Cancellation Policy
          </h1>
          <div className="mt-8 text-custom">
            <p className="mb-4">
              All capitalized terms used in this Vendor Cancellation and Refund
              Policy shall have the meanings ascribed to them in the Vendor
              Terms of Use, unless expressly defined otherwise herein.
            </p>

            <p className="mb-4 font-bold">
              {" "}
              <span className="mr-4">1.</span>Cancellation fees
            </p>

            <p className="mb-4">
              <span className="mr-4">1.1.</span> The Company understands that
              circumstances may change for Vendors, and thus offers a "cancel
              anytime" option. Vendors have the freedom to cancel their Vendor
              Subscriptions at any time through the Platform, even prior to
              expiry of the Venue Subscription Period. Upon cancellation, the
              Vendor shall continue to have access to the Services and Platform
              provided by the Company, until the expiry of the Venue
              Subscription Period.
            </p>
            <p className="mb-4">
              <span className="mr-4">1.2.</span> Upon prior cancellation, access
              to the Vendor Subscription shall automatically terminate at the
              end of the Vendor Subscription Period. In such a case no refunds
              shall be issued for any remaining Vendor Subscription Period
              post-cancellation.
            </p>

            <p className="mb-4">
              <span className="mr-4">1.3.</span> All Vendor Subscriptions shall
              automatically renew at the end of the Vendor Subscription Period,
              unless cancelled or notified in writing by the Vendor at least 1
              (One) month prior to the expiry of the Vendor Subscription Period.
              In case of cancellation, the Vendor may renew the Vendor
              Subscription at its sole discretion by paying the requisite Vendor
              Subscription Amount. In both cases, the renewed Vendor
              Subscription shall be governed by the Vendor Terms, or agreement
              currently in force, as may be applicable. The Vendor shall be
              responsible to review such Vendor Terms and/or agreement to
              understand any updates or changes that may impact their Vendor
              Subscription.
            </p>

            <p className="mb-4 font-bold">
              <span className="mr-4">2.</span>REFUND POLICY
            </p>

            <p className="mb-4 ">
              <span className="mr-4">2.1.</span>It is expressly clarified that
              any and all payments including the Vendor Subscription Amounts
              paid by the Vendor to the Company are strictly non-refundable. The
              Company shall not in any case provide a refund for partial use or
              prior cancellation of any Venue Subscription.
            </p>

            <p className="mb-4 ">
              <span className="mr-4">2.2.</span>By proceeding with the purchase
              of the Vendor Subscription, the Vendor acknowledges and agrees to
              this Vendor Cancellation and Refund Policy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policy;
