import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { base_URL } from "../../config/config";

function AvgBudgetGraph({ startDate, endDate, venueName }) {
  // for a team member invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const { data, isLoading, error } = useQuery({
    queryKey: [
      "enquiriesavgbudgetAnalysisGraph",
      startDate,
      endDate,
      venueName,
    ],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/enquiries-avgbudget-analysis/graph`,
        {
          params: {
            vendorId: vendorId,
            startDate: startDate
              ? startDate?.toLocaleDateString("en-CA")
              : null, // Converts to YYYY-MM-DD
            endDate: endDate ? endDate?.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return response?.data;
    },
  });
  //   in graph array needs to be passed
  const transformedData = [
    // Formula: totalBudget / totalEnquiries
    { name: "Total", value: data?.averageBug },
    // Formula: successfulBudget / successfulEnquiries
    { name: "Successful", value: data?.successfulEnquiriesAvgBudget },
    // Formula: acceptedBudget / acceptedEnquiries
    { name: "Accepted", value: data?.acceptedEnquiriesAvgBudget },
  ];
  if (isLoading)
    return (
      <div className="h-[20vh] mt-[32px] rounded-lg animate-pulse bg-black/20"></div>
    );
  if (error) return <div>Error loading event type graph data</div>;

  return (
    <div className="flex flex-col bg-white p-4 sm:p-6 rounded-xl mt-8">
      <div className="mb-4 sm:mb-6">
        <p className="text-sm sm:text-base font-medium">Average Budget</p>
        <p className="text-[12px] text-[#00000005]/60 ">
          average budget from enquiry received
        </p>
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart data={transformedData} margin={{ left: 50 }}>
          <XAxis dataKey="name" tick={{ fontSize: 12 }} tickLine={false} />
          <YAxis
            type="number"
            domain={[0, "dataMax"]}
            allowDecimals={false}
            tick={{ fontSize: 12 }}
            tickLine={false}
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <Tooltip
            content={({ payload }) => {
              if (!payload || payload?.length === 0) return null;
              const value = payload[0]?.value;
              return (
                <div className="tooltip text-sm text-gray-600 bg-white p-2 rounded shadow">
                  <p>{`Ag. Budget: ${value}`}</p>
                </div>
              );
            }}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke="none"
            fill="rgba(73, 144, 251, 0.06)"
            // fillOpacity={0.3}
          />
          <Bar
            dataKey="value"
            fill="#4990FB"
            barSize={window.innerWidth < 640 ? 20 : 80}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default AvgBudgetGraph;
