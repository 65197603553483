import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
} from "recharts";
import { base_URL } from "../../config/config";

function EnquiryStatusGraphReport({ startDate, endDate, venueName }) {
  // for a team member invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const { data, isLoading } = useQuery({
    queryKey: ["enquiryStatusAnalysisGraph", startDate, endDate, venueName],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/enquiries-status-analysis/graph`,
        {
          params: {
            vendorId: vendorId,
            startDate: startDate
              ? startDate?.toLocaleDateString("en-CA")
              : null, // Converts to YYYY-MM-DD
            endDate: endDate ? endDate?.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return response.data?.statuses;
    },
  });

  if (isLoading)
    return (
      <div className="h-[20vh] mt-[32px] rounded-lg animate-pulse bg-black/20"></div>
    );

  // Reshape the data to be an array of objects for the graph
  const graphData = data
    ? Object.keys(data).map((key) => ({
        name: key, // Status name (e.g., "accepted", "clientDecline")
        value: data[key], // Corresponding value
      }))
    : [];
  const truncate = (str, maxLength) =>
    str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  return (
    <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px]">
      <div className="mb-8">
        <p className="text-[14px] font-medium">Enquiry Status Report</p>
        <p className="text-[12px] text-[#00000005]/60 ">
          Enquiry received by your venue
        </p>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={graphData}
          margin={{ top: 5, right: 0, bottom: 5, left: -34 }}
        >
          <XAxis
            dataKey="name"
            tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
            tickFormatter={(name) =>
              window.innerWidth < 640 ? truncate(name, 5) : name
            }
            tickLine={false}
          />
          <YAxis
            type="number"
            domain={[0, "dataMax"]}
            allowDecimals={false}
            tick={{ fontSize: 12 }}
            tickLine={false}
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <Tooltip
            content={({ payload }) => {
              if (!payload || payload.length === 0) return null;
              const { name, value } = payload[0]?.payload; // Access the first value only
              return (
                <div className="tooltip text-[#4990FB] bg-white rounded-lg p-3 shadow-md">
                  <p className="inline md:hidden">{`${name}`}</p>
                  <p>{`Enquiries : ${value}`}</p>
                </div>
              );
            }}
          />

          {/* <Legend /> */}
          <Area
            type="monotone"
            dataKey="value"
            stroke="none"
            fill="rgba(73, 144, 251, 0.06)"
            // fillOpacity={0.3}
          />
          <Bar
            dataKey="value"
            fill="#4990FB"
            barSize={window.innerWidth < 640 ? 20 : 80}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default EnquiryStatusGraphReport;
