import React from "react";

function Favzero() {
  return (
    <div className="p-4 bg-[#FFFFFF] rounded-2xl">
      <div className="space-y-0 pb-2">
        <div className="text-[14px] md:text-[20px] font-bold">
          Saved as favourite
        </div>
      </div>
      <div className=" h-full w-full flex flex-col gap-y-3 items-center mt-[30px]">
        <img src="/enquiryzero.svg" alt="" />
        <p className="text-[14px] md:text-[16px] text-[#222222] text-center font-normal">
          At this moment, no venue&apos;s are saved as <br /> favourite.
        </p>
      </div>
    </div>
  );
}

export default Favzero;
