import moment from "moment";
import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const OffersAndPackages = ({ data = [] }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [offersData, setOffersData] = useState([]);

  const saveOffers = () => {
    setOffersData(data);
  };

  useEffect(() => {
    // console.log("Offers and Packages", data);
    saveOffers();
  }, []);
  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const filteredOffers = data?.filter((offer) => {
    const currentDate = getCurrentDate();
    if (offer?.endDate) {
      const endDate = offer.endDate;
      return endDate >= currentDate;
    }
    return true;
  });

  return (
    <div className="bg-white rounded-lg mb-2  w-full mx-auto pt-10 pb-6 ">
      {filteredOffers &&
        filteredOffers?.map((offer, index) => (
          <>
            <h2 className="text-2xl font-semibold mb-4 text-[#222222]">
              Offers & Packages
            </h2>
            <div key={index} className="mb-4">
              <div
                className={`cursor-pointer bg-gray-100 p-4 ${
                  expandedIndex === index ? "rounded-t-lg" : "rounded-lg"
                } flex justify-between items-center`}
                onClick={() => toggleExpand(index)}
              >
                <h3 className="text-[20px] text-[#222222] font-medium">
                  {offer.title || "No Title"}
                </h3>
                <span>
                  {expandedIndex === index ? (
                    <MdKeyboardArrowUp className="text-3xl" />
                  ) : (
                    <MdKeyboardArrowDown className="text-3xl" />
                  )}
                </span>
              </div>
              {expandedIndex === index && (
                <div
                  className={`p-4 border-b ${
                    expandedIndex === index ? "rounded-b-lg" : ""
                  }  bg-gray-100`}
                >
                  <div className="flex space-x-2 lg:w-1/2 w-full py-2 items-start justify-between">
                    {offer.startDate && (
                      <div className="mb-2 flex flex-col text-[14px] text-black">
                        <p className="text-[#222222] font-medium text-[16px]">
                          From
                        </p>{" "}
                        {moment(offer.startDate).format("DD MMM YYYY")}
                      </div>
                    )}
                    {offer.endDate && (
                      <div className="mb-2  flex flex-col">
                        <p className="text-[#222222] font-medium text-[16px]">
                          To
                        </p>
                        {moment(offer.endDate).format("DD MMM YYYY")}
                      </div>
                    )}
                  </div>
                  {offer.description && (
                    <>
                      <p className="text-[#222222] font-medium text-[16px]">
                        Offer Description
                      </p>
                      <p className="text-[14px] text-black">
                        {offer.description}
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        ))}
    </div>
  );
};

export default OffersAndPackages;
