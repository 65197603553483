import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import * as api from "../api/teamMember.request";

export const useTeamMembers = (vendorId, searchQuery) => {
  return useQuery({
    queryKey: ["teamMembers"],
    queryFn: () => api.fetchTeamMembers(vendorId, searchQuery),
    enabled: !!vendorId,
    refetchOnWindowFocus: false,
  });
};

export const useTeamMemberSearch = (searchQuery) => {
  return useQuery({
    queryKey: ["teamMembers", searchQuery],
    queryFn: () => api.useTeamMemberSearch(searchQuery),
    refetchOnWindowFocus: false,
    enabled: !!searchQuery,
  });
};

export const useTeamMember = (id) => {
  return useQuery({
    queryKey: ["teamMember", id],
    queryFn: () => api.fetchTeamMember(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};
// get assigned venues and restaurants
export const useTeamMembervenuesandrestaurants = (id) => {
  return useQuery({
    queryKey: ["teamMember", id],
    queryFn: () => api.fetchTeamMemberrestandvenues(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
};

export const useCreateTeamMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => await api.createTeamMember(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers"]);
    },
  });
};

export const useUpdateTeamMember = (data) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => await api.updateTeamMember(data?.id, data?.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers"]);
    },
  });
};

export const useDeleteTeamMember = (userId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => await api.deleteTeamMember(userId),
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers"]);
    },
  });
};
