import React, { useContext } from "react";
import { HotelContext } from "../../context/HotelContext";
import ToggleSwitch from "../shared/common/custom-toggle";
import SelectableTags from "../shared/common/selectableTags";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const PricingForm = () => {
  const { formData, setFormData } = useContext(HotelContext);

  // Provide default values if formData or formData.pricing is undefined
  const basePrice = formData?.pricing?.basePrice || "";
  const mentionSeasons = formData?.pricing?.mentionSeasons || false;
  const highDemandMonths = formData?.pricing?.highDemandMonths || [];
  const midDemandMonths = formData?.pricing?.midDemandMonths || [];
  const lowDemandMonths = formData?.pricing?.lowDemandMonths || [];

  // const handleBasePriceChange = (e) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     pricing: {
  //       ...prevFormData.pricing,
  //       basePrice: e.target.value,
  //     },
  //   }));
  // };
  const handleBasePriceChange = (e) => {
    const value = e.target.value;

    // Allow only numeric values
    if (/^\d*$/.test(value) || value === "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        pricing: {
          ...prevFormData.pricing,
          basePrice: value,
          errors: { ...prevFormData.pricing.errors, basePrice: "" }, // Clear error on valid input
        },
      }));
    } else {
      // Set error for invalid input
      setFormData((prevFormData) => ({
        ...prevFormData,
        pricing: {
          ...prevFormData.pricing,
          errors: {
            ...prevFormData.pricing.errors,
            basePrice: "Please enter a valid number.",
          },
        },
      }));
    }
  };

  const handleMentionSeasonsToggle = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      pricing: {
        ...prevFormData.pricing,
        mentionSeasons: !prevFormData.pricing?.mentionSeasons,
      },
    }));
  };

  const handleMonthToggle = (month, demandType) => {
    const updateMonths = (monthsList) =>
      monthsList.includes(month)
        ? monthsList.filter((m) => m !== month)
        : [...monthsList, month];

    setFormData((prevFormData) => {
      const updatedPricing = { ...prevFormData.pricing };
      switch (demandType) {
        case "high":
          updatedPricing.highDemandMonths = updateMonths(highDemandMonths);
          break;
        case "mid":
          updatedPricing.midDemandMonths = updateMonths(midDemandMonths);
          break;
        case "low":
          updatedPricing.lowDemandMonths = updateMonths(lowDemandMonths);
          break;
        default:
          break;
      }
      return {
        ...prevFormData,
        pricing: updatedPricing,
      };
    });
  };

  const errors = formData.pricing.errors || {};

  const selectedMonths = [
    ...highDemandMonths,
    ...midDemandMonths,
    ...lowDemandMonths,
  ];

  return (
    <div className="bg-white rounded-lg">
      <h2 className="text-2xl font-semibold text-neutralBlack mb-4 relative w-fit">
        Pricing
        <span className="text-red-500 text-[15px] absolute top-0 -right-3">
          *
        </span>
      </h2>

      <div className="mb-4">
        <label className="block text-secondary font-medium text-sm mb-2">
          Starting base price per room per night
        </label>
        <div className="flex items-center lg:w-1/2 w-full border-2 rounded-lg border-lightGray">
          <select className="p-2 rounded-md border-0">
            <option>AED</option>
          </select>
          <input
            type="text"
            placeholder="Enter base pricing"
            value={basePrice}
            onChange={(e) => {
              // Allow only numeric values
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleBasePriceChange(e); // Update the state only if the input is a valid number
              }
            }}
            className="ml-2 p-2 border-0 focus:outline-none rounded-md w-full"
          />
        </div>
        {errors.basePrice && (
          <p className="text-red-500 text-base pt-1">{errors.basePrice}</p>
        )}

        <p className="text-sm text-mediumGray mt-1">
          This pricing will be displayed on the search results
        </p>
      </div>

      <div className="mb-4">
        <label className="flex items-center">
          <span className="text-darkGray text-base font-medium mr-2">
            Would you like to mention the High & Low Season availability?
          </span>
          <ToggleSwitch
            isOn={mentionSeasons}
            handleToggle={handleMentionSeasonsToggle}
          />
        </label>
      </div>

      {mentionSeasons && (
        <>
          <DemandSection
            title="High Demand"
            para="Select months with peak season"
            color="red"
            selectedMonths={highDemandMonths}
            toggleMonth={(month) => handleMonthToggle(month, "high")}
            disabledMonths={selectedMonths.filter(
              (month) => !highDemandMonths.includes(month)
            )}
          />
          <DemandSection
            title="Mid Demand"
            para="Select months with mid season demand"
            color="yellow"
            selectedMonths={midDemandMonths}
            toggleMonth={(month) => handleMonthToggle(month, "mid")}
            disabledMonths={selectedMonths.filter(
              (month) => !midDemandMonths.includes(month)
            )}
          />
          <DemandSection
            title="Low Demand"
            para="Select months with less tourist demand"
            color="green"
            selectedMonths={lowDemandMonths}
            toggleMonth={(month) => handleMonthToggle(month, "low")}
            disabledMonths={selectedMonths.filter(
              (month) => !lowDemandMonths.includes(month)
            )}
          />
        </>
      )}

      {errors.monthsTotal && (
        <p className="text-red-500 text-base pt-1">{errors.monthsTotal}</p>
      )}
      {errors.overlap && (
        <p className="text-red-500 text-base pt-1">{errors.overlap}</p>
      )}
    </div>
  );
};

const DemandSection = ({
  title,
  para,
  color,
  selectedMonths,
  toggleMonth,
  disabledMonths,
}) => (
  <div className="mb-4">
    <div className="flex gap-3 items-center mb-2">
      <div className="flex flex-col">
        <div className="flex items-center space-x-2">
          <div
            className={`h-3 w-3 rounded-full bg-${color}-500 ${
              color === "yellow" && "bg-yellow-500"
            }`}
          ></div>
          <h3 className={`text-xl font-medium text-darkGray`}>{title}</h3>
        </div>
        <p className="text-sm text-secondary ml-4">{para}</p>
      </div>
    </div>
    <div className="flex flex-wrap gap-2 pt-5">
      <SelectableTags
        tags={months}
        selectedTags={selectedMonths}
        onTagClick={(month) => toggleMonth(month)}
        disabledTags={disabledMonths}
      />
    </div>
  </div>
);

export default PricingForm;
