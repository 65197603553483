import axios from "axios";
import React, { useState, useEffect } from "react";
import { base_URL } from "../../config/config";
import { RxCross2 } from "react-icons/rx";
import CustomInput from "../shared/CustomInput";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function EditNameModal({ preName, onClose, isOpen }) {
  const [name, setName] = useState(preName);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [wordsLength, setWordsLength] = useState(0);

  useEffect(() => {
    setWordsLength(name.length);
  }, [wordsLength, name]);

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(`${base_URL}/api/auth/rename-folder`, {
        customerId: user._id,
        preName,
        newName: name,
      });

      toast.success("successfully updated name");
      navigate("/my-favorites");
      onClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg py-4 px-6 w-[464px]  space-y-3 h-72 flex flex-col justify-center items-center  ">
        <div className="flex justify-center items-center relative w-full ">
          <p className="text-center text-[24px] mb-4">Edit Name</p>
          <RxCross2
            size={22}
            className="absolute top-1 right-0 cursor-pointer text-dark"
            onClick={onClose}
          />
        </div>

        <div className=" flex flex-col justify-center items-center gap-3 w-full">
          <div className="w-full">
            <CustomInput
              label={"Name"}
              type={"text"}
              placeholder={"Enter name"}
              value={name}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 30 || newValue.length < name.length) {
                  setName(newValue);
                }
              }}
            />
            <p className="text-[14px] mt-2 text-gray">
              {wordsLength}/30 Characters
            </p>
          </div>

          <button
            onClick={handleUpdate}
            className="bg-primary mt-5 text-white rounded-lg w-[160px] py-2 text-sm"
          >
            {isLoading ? "Renaming..." : "Done"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditNameModal;
