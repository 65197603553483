import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { getCityCoordinates } from "./getCityCoordinates";
import { RxCross2 } from "react-icons/rx";
import { FaMapMarkerAlt, FaUserFriends } from "react-icons/fa";
import { FaStar, FaUtensils } from "react-icons/fa6";
import { debounce, set } from "lodash";

const Map = ({ setIsShowMap, city, places, setMapBounds }) => {
  const [coordinates, setCoordinates] = useState({ Lat: 0, Lng: 0 });
  const [showDetail, setIsshowDetail] = useState(false);
  const [venueDetail, setVenueDetail] = useState({});
  const [infoPosition, setInfoPosition] = useState(null);
  const [minSpendPerPerson, setMinSpendPerPerson] = useState(null);
  const [minSpendPricing, setMinSpendPricing] = useState(null);
  const [venueRental, setVenueRental] = useState(null);
  const mapRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg",
  });

  useEffect(() => {
    const cityCoordinates = getCityCoordinates(city);
    if (cityCoordinates) {
      setCoordinates(cityCoordinates);
    }
  }, [city]);

  const handleBoundsChanged = useCallback(
    debounce(() => {
      const bounds = mapRef.current?.getBounds();
      if (bounds) {
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        setMapBounds({
          north: northEast.lat(),
          east: northEast.lng(),
          south: southWest.lat(),
          west: southWest.lng(),
        });
      }
    }, 300),
    []
  );

  const handleClick = (place) => {
    calculateMinSpendFromSelectedOptions(place);
    setIsshowDetail(true);
    setVenueDetail(place);
    setInfoPosition({ lat: place.location.lat, lng: place.location.lng });
  };
  const calculateMinSpendFromSelectedOptions = (e) => {
    // Declare variables at the top to ensure they are accessible in all blocks
    let minSpendPricing = null;
    let venueRental = null;
    let minSpendPerPerson = null;

    if (e?.pricing?.selectedOptions?.length) {
      e.pricing.selectedOptions.forEach((option) => {
        const minSpendValue = Number(option.details.minSpend);
        let hasMinimumSpendPricing = false;
        switch (option.type) {
          case "Minimum Spend Pricing":
            hasMinimumSpendPricing = true; // Flag that "Minimum Spend Pricing" exists
            minSpendPricing =
              minSpendPricing === null
                ? minSpendValue
                : Math.min(minSpendPricing, minSpendValue);
            break;

          case "Venue Rental":
            // Only use Venue Rental if Minimum Spend Pricing isn't available
            venueRental =
              venueRental === null
                ? minSpendValue
                : Math.min(venueRental, minSpendValue);
            break;

          case "Min spend per person":
            minSpendPerPerson =
              minSpendPerPerson === null
                ? minSpendValue
                : Math.min(minSpendPerPerson, minSpendValue);
            break;

          default:
            break;
        }
      });

      // If Minimum Spend Pricing is not available, use Venue Rental
      setMinSpendPerPerson(minSpendPerPerson);
      setMinSpendPricing(minSpendPricing);
      setVenueRental(venueRental);
      return {
        minSpendPerPerson,
        minSpendPricing,
        venueRental,
      };
    } else {
      // If no selected options are present, return variables initialized above
      setMinSpendPerPerson(minSpendPerPerson);
      setMinSpendPricing(minSpendPricing);
      setVenueRental(venueRental);
      return {
        minSpendPerPerson,
        minSpendPricing,
        venueRental,
      };
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-[98vh] w-full relative">
      <GoogleMap
        onLoad={(map) => (mapRef.current = map)}
        onIdle={handleBoundsChanged}
        center={coordinates}
        zoom={10}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        {places.length > 0 &&
          places.map((place, i) => (
            <Marker
              key={i}
              onClick={() => handleClick(place)}
              position={{ lat: place.location.lat, lng: place.location.lng }}
            />
          ))}

        {showDetail && infoPosition && (
          <InfoWindow
            position={infoPosition}
            onCloseClick={() => setIsshowDetail(false)}
          >
            <div className="w-60 bg-white rounded-lg shadow-lg p-2">
              <div className="relative">
                <img
                  src={venueDetail.imagesAndVideos.images[0]}
                  alt="Venue"
                  className="w-full h-32 rounded-t-lg object-cover"
                />
              </div>

              {/* Venue Details */}
              <div className="p-2">
                <h1 className="text-lg font-semibold">{venueDetail.name}</h1>
                <p className="text-gray-600 flex items-center">
                  <FaMapMarkerAlt size={14} className="mr-1" />
                  {venueDetail.location.city}, {venueDetail.location.area}
                </p>

                {/* Icons for Rating, People Count, and Category */}
                <div className="flex justify-between items-center my-2 text-gray-600">
                  <div className="flex items-center">
                    <FaUserFriends size={14} className="mr-1" />
                    {venueDetail.capacity?.sitting || "N/A"}
                  </div>
                  {/* <div className="flex items-center">
                    <FaStar size={14} className="mr-1 text-yellow-500" />
                    {venueDetail.rating || "N/A"}
                  </div> */}
                  <div className="flex items-center">
                    <FaUtensils size={14} className="mr-1" />
                    {venueDetail.category || "Restaurant"}
                  </div>
                </div>

                {/* Price Information */}
                <div className="flex  justify-between items-start w-full">
                  {minSpendPricing &&
                  venueRental &&
                  !Number.isNaN(minSpendPricing) &&
                  !Number.isNaN(venueRental) ? (
                    <>
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {minSpendPricing}
                        </p>
                        {/* Show one "Min. spend" text */}
                        <p className="text-sm">Min. spend</p>
                      </div>
                      {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
                    </>
                  ) : (
                    <>
                      {/* Case 2: Both minSpendPerPerson and venueRental available */}
                      {minSpendPerPerson &&
                      venueRental &&
                      !Number.isNaN(minSpendPerPerson) &&
                      !Number.isNaN(venueRental) ? (
                        <>
                          <div className="">
                            <p className="text-primary font-medium">
                              AED {venueRental}
                            </p>
                            <p className="text-sm">Min. spend</p>
                          </div>
                          <div className="">
                            <p className="text-primary font-medium">
                              AED {minSpendPerPerson}
                            </p>
                            <p className="text-sm">Per guest</p>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Render each one individually if other cases don't match */}
                          {minSpendPerPerson &&
                            !Number.isNaN(minSpendPerPerson) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {minSpendPerPerson}
                                </p>
                                <p className="text-sm">Per guest</p>
                              </div>
                            )}
                          {minSpendPricing &&
                            !Number.isNaN(minSpendPricing) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {minSpendPricing}
                                </p>
                                <p className="text-sm">Min. spend</p>
                              </div>
                            )}
                          {venueRental && !Number.isNaN(venueRental) && (
                            <div className="">
                              <p className="text-primary font-medium">
                                AED {venueRental}
                              </p>
                              <p className="text-sm">Min. spend</p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>

      <button
        onClick={() => setIsShowMap(false)}
        className="absolute top-3 right-[5vw] bg-white h-10 w-10 rounded-full flex justify-center items-center"
      >
        <RxCross2 size={24} />
      </button>
    </div>
  );
};

export default Map;
