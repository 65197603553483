/* eslint-disable no-unused-vars */
import React from "react";
import DashboardLayout from "../../layout/dashboard-layout";
import Profile from "../../assets/icons/user.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineCalendarToday } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { PiBag } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";
import { AiOutlinePhone } from "react-icons/ai";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteAssignedVenue } from "../../api/teamMember.request";
import { getOnboarding } from "../../api/signup.request";
import { useTeamMember } from "../../queries/team-members";

export default function TeamDetails() {
  // showing venues of super admin by invited id of role is admin from await getOnboarding(invitedBy),
  // showing only his venues if role is limited from  const { data, isPending } = useTeamMember(id);
  const invitedBy = localStorage.getItem("invitedBy");
  const checkAdmin = localStorage.getItem("checkAdmin");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const { id } = useParams();
  const { data, isPending } = useTeamMember(id);

  const {
    data: boardingData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => await getOnboarding(vendorId),
    refetchOnWindowFocus: false,
  });

  // Combine restaurantForms and venueForms for admins
  const combinedVenues = [
    ...(boardingData?.restaurantForms || []),
    ...(boardingData?.venueForms || []),
    ...(boardingData?.hotels || []),
  ];

  const queryClient = useQueryClient();
  const deleteMutation = useMutation({
    mutationFn: async (venueId) => {
      return await deleteAssignedVenue(id, venueId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMember", id]);
    },
  });
  // Function to determine the text based on status and itemStatus
  const getStatusText = (status, itemStatus) => {
    switch (true) {
      case status?.toLowerCase() === "pending" &&
        itemStatus?.toLowerCase() === "pending":
        return "Draft";
      case status === "completed" &&
        ["pending", "Pending", "ReSubmitting"].includes(itemStatus):
        return "Under Review";
      case status === "completed" && itemStatus === "Edit Pending":
        return "Edits Under Review";
      case status === "completed" && itemStatus === "Approved":
        return "Live";
      case status === "completed" && itemStatus === "Rejected":
        return "Rejected";
      case status === "completed" && itemStatus === "unpublish":
        return "Unpublished";
      default:
        return "Draft";
    }
  };
  const truncateText = (text, charLimit) => {
    if (!text) return "";
    if (text.length <= charLimit) return text;
    return text.slice(0, charLimit) + "...";
  };
  return (
    <DashboardLayout>
      {isPending || isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex w-full py-4 justify-center">
          <div className="w-[90%] flex flex-col gap-5">
            <div className="flex items-center gap-2">
              {" "}
              <Link to="/dashboard" className="text-[#FE4747]">
                Dashboard
              </Link>{" "}
              {">"}
              <Link to="/team" className="text-[#FE4747]">
                My Team
              </Link>{" "}
              {">"} <p>User Details</p>
            </div>
            <h2 className="text-2xl font-semibold">My Team</h2>
            {/* for admins */}
            {data?.user?.assignedVenues?.length === 0 &&
            data?.user?.isAdmin === true ? (
              <div className="flex flex-col-reverse gap-4 md:space-y-0 md:grid md:grid-cols-3 md:gap-5">
                {/* Card */}
                <div className="w-full bg-white rounded-[6px] ">
                  <div className="flex flex-col items-center gap-2 justify-center py-5">
                    {data?.user?.profilePic ? (
                      <>
                        <img
                          src={data?.user?.profilePic}
                          alt="Profile"
                          className="h-[80px] w-[80px] object-cover rounded-full" // Added object-cover and rounded-full for better appearance
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={Profile}
                          alt="Profile"
                          className="h-[80px] w-[80px] object-cover rounded-full" // Added object-cover and rounded-full for better appearance
                        />
                      </>
                    )}

                    {/* username */}
                    <p className="text-base font-semibold text-center  ">
                      {truncateText(data?.user?.name, 20)}
                    </p>
                  </div>

                  <hr />
                  {/* Card details */}
                  <div className="flex flex-col gap-5 px-5 py-5">
                    <div className="flex gap-3 items-center">
                      <MdOutlineCalendarToday color="#717171" />
                      <div>
                        <p className="text-[667085] text-sm">User since</p>
                        <p className="font-semibold">
                          {dayjs(data?.user?.createdAt).format("DD MMM YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <PiBag color="#717171" />

                      <div>
                        <p className="text-[667085] text-sm">Role</p>
                        <p className="font-semibold">
                          {truncateText(data?.user?.role, 20)}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <MdOutlineEmail color="#717171" />
                      <div>
                        <p className="text-[667085] text-sm">E-mail</p>
                        <p className="font-semibold">
                          {" "}
                          {truncateText(data?.user?.email, 20)}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <AiOutlinePhone color="#717171" />
                      <div>
                        <p className="text-[667085] text-sm">Phone Number</p>
                        <p className="font-semibold">
                          {" "}
                          {data?.user?.phone
                            ? data?.user?.phone?.startsWith("+")
                              ? data?.user?.phone?.replace(/^\+{2}/, "+") // Remove extra '+' if present
                              : `+${data?.user?.phone}` // Add '+' if missing
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Table */}
                <div className="w-full col-span-2">
                  <div
                    className="grid grid-cols-5 h-[57px] bg-[#e4e4e4] items-center px-5"
                    style={{
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                    }}
                  >
                    <p className="font-medium col-span-2">Venues</p>
                    <p className="font-medium">List date</p>
                    <p className="font-medium">Status</p>
                    <div>
                      {" "}
                      <p className="font-medium text-end"></p>
                    </div>
                  </div>
                  {combinedVenues?.map((e, i) => {
                    return (
                      <div className="grid grid-cols-5 items-center py-3 bg-white px-5">
                        <div className="flex gap-2 col-span-2 items-center">
                          {e?.imagesAndVideos?.images &&
                          e?.imagesAndVideos?.images?.length > 0 ? (
                            <img
                              src={
                                e?.imagesAndVideos?.images &&
                                e?.imagesAndVideos?.images?.length > 0
                                  ? e?.imagesAndVideos?.images[0]
                                  : ""
                              }
                              alt="image-main"
                              className="h-[56px] w-[83px] rounded-[7px]"
                            />
                          ) : null}

                          <div className="space-y-1">
                            <p className="text-[#222222] font-medium text-sm">
                              {e?.basicDetails?.venueName ||
                                e?.basicDetails?.hotelName ||
                                e?.overview?.basicDetails?.hotelName}
                            </p>
                            <p className="text-[#717171] text-xs">
                              {e?.location?.country}
                            </p>
                          </div>
                        </div>

                        <p className="text-[#222222] text-sm">
                          {dayjs(e?.createdAt).format("DD MMM YYYY")}
                        </p>
                        <div>
                          {" "}
                          <p className="bg-[#E4EFFF] text-sm px-2 py-1 w-fit rounded-[6px] text-[#1252B1] ">
                            {getStatusText(e?.status, e?.itemStatus)}
                          </p>
                        </div>
                        <div className="flex justify-end">
                          {/* <RiDeleteBin6Line
                        color="#222222"
                        role="button"
                        onClick={() => deleteMutation.mutate(e?._id)}
                      /> */}
                          {checkAdmin === "true" && (
                            <RiDeleteBin6Line
                              color="#222222"
                              onClick={() => deleteMutation.mutate(e?._id)}
                              role="button"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex flex-col-reverse gap-4 md:space-y-0 md:grid md:grid-cols-3 md:gap-5">
                {/* Card */}
                <div className="w-full bg-white rounded-[6px]">
                  <div className="flex flex-col items-center gap-2 justify-center py-5">
                    <img
                      src={Profile}
                      alt="img"
                      className="h-[80px] w-[80px]"
                    />
                    <p className="text-base font-semibold">
                      {data?.user?.name}
                    </p>
                  </div>
                  <hr />
                  {/* Card details */}
                  <div className="flex flex-col gap-5 px-5 py-5">
                    <div className="flex gap-3 items-center">
                      <MdOutlineCalendarToday color="#717171" />
                      <div>
                        <p className="text-[667085] text-sm">User since</p>
                        <p className="font-semibold">
                          {dayjs(data?.user?.createdAt).format("DD MMM YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <PiBag color="#717171" />

                      <div>
                        <p className="text-[667085] text-sm">Role</p>
                        <p className="font-semibold">{data?.user?.role}</p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <MdOutlineEmail color="#717171" />
                      <div>
                        <p className="text-[667085] text-sm">E-mail</p>
                        <p className="font-semibold">{data?.user?.email}</p>
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <AiOutlinePhone color="#717171" />
                      <div>
                        <p className="text-[667085] text-sm">Phone Number</p>
                        <p className="font-semibold">
                          {data?.user?.phone
                            ? data?.user?.phone?.startsWith("+")
                              ? data?.user?.phone?.replace(/^\+{2}/, "+") // Remove extra '+' if present
                              : `+${data?.user?.phone}` // Add '+' if missing
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Table for limited users*/}
                <div className="w-full col-span-2">
                  <div
                    className="grid grid-cols-5 h-[57px] bg-[#e4e4e4] items-center px-5"
                    style={{
                      borderTopLeftRadius: "6px",
                      borderTopRightRadius: "6px",
                    }}
                  >
                    <p className="font-medium col-span-2">Venues</p>
                    <p className="font-medium">List date</p>
                    <p className="font-medium">Status</p>
                    <div>
                      {" "}
                      <p className="font-medium text-end"></p>
                    </div>
                  </div>
                  {[
                    ...data?.user?.assignedVenues,
                    ...data?.user?.RestaurantForm,
                    ...data?.user?.Hotel,
                  ].map((e, i) => {
                    return (
                      <div className="grid grid-cols-5 items-center py-3 bg-white px-5">
                        <div className="flex gap-2 col-span-2 items-center">
                          {e?.imagesAndVideos?.images &&
                          e?.imagesAndVideos?.images?.length > 0 ? (
                            <img
                              src={
                                e?.imagesAndVideos?.images &&
                                e?.imagesAndVideos?.images?.length > 0
                                  ? e?.imagesAndVideos?.images[0]
                                  : ""
                              }
                              alt="image-main"
                              className="h-[56px] w-[83px] rounded-[7px]"
                            />
                          ) : e?.photoVideo?.images &&
                            e?.photoVideo?.images?.length > 0 ? (
                            <img
                              src={
                                e?.photoVideo?.images &&
                                e?.photoVideo?.images?.length > 0
                                  ? e?.photoVideo?.images[0]
                                  : ""
                              }
                              alt="image-main"
                              className="h-[56px] w-[83px] rounded-[7px]"
                            />
                          ) : null}

                          <div className="space-y-1">
                            <p className="text-[#222222] font-medium text-sm">
                              {e?.basicDetails?.venueName ||
                                e?.basicDetails?.hotelName ||
                                e?.overview?.basicDetails?.hotelName}
                            </p>
                            <p className="text-[#717171] text-xs">
                              {e?.location?.country}
                            </p>
                          </div>
                        </div>

                        <p className="text-[#222222] text-sm">
                          {dayjs(e?.createdAt).format("DD MMM YYYY")}
                        </p>
                        <div>
                          {" "}
                          <p className="bg-[#E4EFFF] text-sm px-2 py-1 w-fit rounded-[6px] text-[#1252B1] ">
                            {getStatusText(e?.status, e?.itemStatus)}
                          </p>
                        </div>
                        <div className="flex justify-end">
                          {checkAdmin === "true" && (
                            <RiDeleteBin6Line
                              color="#222222"
                              onClick={() => deleteMutation.mutate(e?._id)}
                              role="button"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}
