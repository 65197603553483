import axios from "axios";
import { base_URL } from "../config/config";

const API_BASE_URL = `${base_URL}/api`;

export const registerVenue = async (venueData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/vendor/venue/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(venueData),
    });


    const responseData = await response.json(); // Read the response once

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }


    return responseData; // Return the parsed response
  } catch (error) {
    console.error("Error registering venue:", error);
    throw error;
  }
};

export const fetchVendorVenues = async (vendorId) => {
  const response = await axios.get(`${API_BASE_URL}/vendor/${vendorId}/venues`);
  return response.data;
};
