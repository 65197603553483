import React, { useEffect, useState } from "react";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import CustomInput from "./shared/CustomInput";
import Button from "./shared/Button";
import { Apple, Facebook, Google } from "../svgs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { base_URL } from "../config/config";
import { closeModals, toggleResetWorkMail } from "../store/features/modalSlice";
import { useDispatch } from "react-redux";
import { setUser } from "../store/features/authSlice";
import { useNavigate } from "react-router-dom";
const LoginModal = ({ onSignUpClick, onSocialClick }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    email: "",
    password: "",
    success: "",
  });
  const dispatch = useDispatch();

  const validation = () => {
    if (!email) {
      setError((prev) => ({
        ...prev,
        email: "Please enter your email address.",
      }));
    }
    if (!password) {
      setError((prev) => ({
        ...prev,
        password: "Please enter your password.",
      }));
    }
  };
  const handleLogin = async () => {
    validation();
    if (error.email !== "" || error.password !== "") {
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${base_URL}/api/auth/login`, {
        email,
        password,
      });

      // Assuming the token comes in response.data.token
      const { token, user } = response.data;
      // console.log(user);

      // Handle successful login (store token, redirect, etc.)
      if (user.isVerified) {
        toast.success("Logged in successfully!");
        dispatch(closeModals());

        dispatch(setUser(user)); // Save user to Redux
        localStorage.setItem("authToken", token); // Store token in localStorage
        localStorage.setItem("authUser", JSON.stringify(user)); // Store user in localStorage      setIsLoading(false);
        navigate("/");
      } else {
        setIsLoading(false);
        toast.error("Please verify your email to login.");
      }

      // Redirect or do additional steps after login if needed
    } catch (error) {
      setIsLoading(false);
      setError((prev) => ({
        ...prev,
        success: "Login failed. Please check your credentials and try again.",
      })); // Clear previous errors
    }
  };

  const handleForgotPassword = async () => {
    dispatch(toggleResetWorkMail());
  };

  //  Facebook login handler
  const handleFacebookResponse = async (response) => {
    try {
      if (response.accessToken) {
        const result = await axios.get(
          `${base_URL}/api/auth/facebook?access_token=${response.accessToken}`
        );
        const { data, token } = result.data;

        dispatch(setUser(data));
        dispatch(closeModals());

        localStorage.setItem("authToken", token);
        localStorage.setItem("authUser", JSON.stringify(data));

        toast.success("Logged in successfully!");
        navigate("/");
      }
    } catch (error) {
      console.error("Facebook login error:", error);
      toast.error(
        error.response?.data?.message || "Failed to login with Facebook"
      );
    }
  };
  useEffect(() => {
    // Initialize Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v17.0",
      });
    };

    // Load Facebook SDK
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <div>
      <div
        onClick={() => {
          navigate("/login");
          dispatch(closeModals());
        }}
        className="text-dark cursor-pointer mb-0 underline mx-auto w-full text-center"
      >
        Login as Vendor
      </div>
      <p className="text-center">
        New to Find My Venue?{" "}
        <span
          onClick={onSignUpClick}
          className="underline cursor-pointer font-medium"
        >
          Sign up for free
        </span>
      </p>

      <div className="flex flex-col gap-4">
        <CustomInput
          label={"Email address"}
          type={"text"}
          placeholder={"Enter your email address"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={error.email}
        />
        <CustomInput
          label={"Password"}
          type={"password"}
          placeholder={"Enter your password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={error.password}
        />

        <Link onClick={handleForgotPassword} className="text-dark underline">
          Forget Password?
        </Link>

        <p className="text-[#FE4747] text-sm">{error.success}</p>
        <Button
          onClick={handleLogin}
          label={isLoading ? "Logging in..." : "Log in"}
          disabled={isLoading}
        />
        <div className="flex gap-2 justify-center w-full">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            callback={handleFacebookResponse}
            render={(renderProps) => (
              <div
                className="border justify-center flex border-dark rounded-md w-full px-4 py-2"
                onClick={renderProps.onClick}
              >
                <Facebook />
              </div>
            )}
          />

          {/* Apple Button */}
          <button className="border justify-center flex border-dark rounded-md w-full px-4 py-2">
            <Apple />
          </button>

          {/* Google Button */}
          <button
            onClick={onSocialClick}
            className="border justify-center flex border-dark rounded-md w-full px-4 py-2"
          >
            <Google />
          </button>
        </div>

        <div className="flex gap-2 justify-center w-full">
          {/* {[<Facebook />, <Apple />, <Google />].map((e, i) => (
            <button
              key={i}
              onClick={onSocialClick}
              className="border justify-center flex border-dark rounded-md w-full px-4 py-2"
            >
              {e}
            </button>
          ))} */}
          {/* <GoogleLogin
            onSuccess={(response) => console.log(response)}
            onError={(error) => console.log(error)}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="border justify-center flex border-dark rounded-md w-full px-4 py-2"
              >
                <Google />
              </button>
            )}
          /> */}
          {/* <AppleLogin
            clientId="com.your.app" // Your App ID
            redirectURI="https://yourdomain.com/auth/apple/callback"
            responseType={"code"} // Request authorization code
            responseMode={"query"} // Get response in URL query
            usePopup={true} // Optional: Opens in popup instead of redirect
            onSuccess={(response) =>
              console.log("Apple Login Response:", response)
            }
            onFailure={(error) => console.error("Apple Login Error:", error)}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="border justify-center flex border-dark rounded-md w-full px-4 py-2"
              >
                <Apple />
              </button>
            )}
          /> */}
          {/* <FacebookLogin
            appId="YOUR_APP_ID"
            autoLoad={false}
            callback={(response) => console.log(response)}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="border justify-center flex border-dark rounded-md w-full px-4 py-2"
              >
                <Facebook />
              </button>
            )}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
