import React, { useState } from "react";
import axios from "axios";
import CustomInput from "../shared/CustomInput";
import Button from "../shared/Button";
import { useDispatch } from "react-redux";
import { toggleResetMail } from "../../store/features/modalSlice";
import { setResendEmail } from "../../store/features/authSlice";
import { base_URL } from "../../config/config";

const ResetWorkMail = () => {
  const [email, setEmail] = useState(""); // Update to email state
  const [isLoading, setIsLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const dispatch = useDispatch();
  const handleForgotPassword = async () => {
    if (!email) {
      setInputError("Please enter your work email.");
      return;
    }

    setIsLoading(true);
    dispatch(setResendEmail(email)); // Sync resendEmail with the email field
    try {
      await axios.post(`${base_URL}/api/auth/forgot-password`, {
        email,
      });

      dispatch(toggleResetMail());
      setIsLoading(false);
    } catch (error) {
      setInputError(error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p className="w-[330px] text-center mx-auto mb-[10px] text-[#717171] font-semibold">
        Enter your email linked to your account and we’ll send you a link to
        reset your password.
      </p>
      <div className="flex flex-col gap-4">
        <CustomInput
          label={"Email"}
          type={"email"}
          placeholder={"Enter your email"}
          value={email} // Bound to the email state
          onChange={(e) => setEmail(e.target.value)}
          error={inputError}
        />

        <Button
          onClick={handleForgotPassword} // Link to forgot password logic
          label={isLoading ? "Sending..." : "Send Email"}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default ResetWorkMail;
