import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const TableComponent = ({ headers, rows, showHeader }) => {
  console.log(rows, "rows");
  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table
        sx={{ minWidth: { xs: "100%", md: 650 } }}
        aria-label="simple table"
      >
        {showHeader && (
          <TableHead>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                marginTop: "-4px !important",
              }}
            >
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align={header.align}
                  sx={{ color: "#5e5e5e", fontWeight: "500", fontSize: "1rem" }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headers.map((header, cellIndex) => (
                <TableCell
                  key={cellIndex}
                  align={header.align}
                  sx={{
                    color: "#5e5e5e",
                    borderBottom:
                      rowIndex === rows.length - 1 ? "none" : "inherit",
                    width: header.width,
                    py: 1,
                  }}
                >
                  {row[header.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
