import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { base_URL } from "../../../config/config";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useParams, useNavigate } from "react-router-dom";
import { setUser } from "../../../store/features/authSlice";
import Button from "../../../components/shared/Button";
import NavbarCustomer from "../../../components/NavbarCustomer";

const CustomInput = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  label,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full ">
      <p className="text-dark mb-1 font-normal">{label}</p>
      <div className="w-full relative">
        <input
          type={showPassword && type === "password" ? "text" : type}
          name={name}
          value={value}
          onChange={onChange}
          className="w-full py-2 px-3 border border-bordered outline-none focus:border-[#FE4747] rounded-md"
          placeholder={placeholder}
          id=""
        />

        {type === "password" && (
          <div
            className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-gray"
            onClick={handleTogglePassword}
          >
            {showPassword ? <IoMdEyeOff size={20} /> : <IoMdEye size={20} />}
          </div>
        )}
      </div>
      {error && name === "basePrice" ? (
        <p className="text-[#9ca3af] text-sm mt-1">{error}</p>
      ) : (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
};

const validation = (password, confirmPassword) => {
  let error = {
    password: "",
    cPassword: "",
    success: "",
  };

  if (!password) {
    error.password = "Please enter your password.";
  }
  if (!confirmPassword) {
    error.cPassword = "Please re-enter your password.";
  }
  if (password !== confirmPassword) {
    error.cPassword = "Passwords do not match.";
  }

  return error;
};

const Reset = ({ filters, setFilters }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useParams().token;

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({
    password: "",
    cPassword: "",
    success: "",
  });

  const handleReset = async () => {
    const error = validation(password, confirmPassword);
    if (error.password || error.cPassword) {
      setError(error);
      return;
    }

    setLoading(true);

    try {
      const res = await axios.put(`${base_URL}/api/auth/update-password`, {
        newPassword: password,
        token,
      });
      if (res.data.success) {
        toast.success(res.data.message);
        setLoading(false);
        if (res.data.user.isVerified) {
          dispatch(setUser(res.data.user));
          localStorage.setItem("authToken", res.data.token);
          localStorage.setItem("authUser", JSON.stringify(res.data.user));
        }
        navigate("/");
      }
    } catch (error) {
      setError({ success: "Failed to update password. Please try again." });
      setLoading(false);
    }
  };

  return (
    <div>
      <NavbarCustomer filters={filters} setFilters={setFilters} />
      <div className="md:w-[430px] w-[95%] mx-auto mt-20 shadow-lg md:p-6 p-4 rounded-lg">
        <div className="md:text-2xl text-xl text-black cursor-pointer mb-4 mx-auto w-full text-center">
          Reset Password
        </div>

        <div className="flex flex-col gap-4">
          <CustomInput
            label={"Password"}
            type={"password"}
            placeholder={"Enter your password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error.password}
          />
          <CustomInput
            label={"Confirm Password"}
            type={"password"}
            placeholder={"Re-enter your password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={error.cPassword}
          />
          <p className="text-[#FE4747] text-sm">{error.success}</p>
          <Button
            label={loading ? "Updating..." : "Update Password"}
            disabled={loading}
            onClick={handleReset}
          />
        </div>
      </div>
    </div>
  );
};

export default Reset;
