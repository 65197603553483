import React from "react";

function PlanBenefits() {
  return (
    <div className="mt-4 md:mt-0 flex flex-col w-full md:w-1/2 pl-5 bg-[#FFF8F5] rounded-2xl shadow-md p-2">
      <div className="flex flex-col mt-[40px] md:mt-[72px] mb-[60px] ml-[2px] md:ml-[32px]">
        <h2 className="text-xl  mb-4 text-[16px] md:text-[25px] font-bold lg:text-[40px]">
          Plan <span className="font-extralight">Benefits</span>
        </h2>
        <ul className="list-none space-y-2 mt-[16px] md:mt-[24px]">
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939]  mr-2">✔</span>
            Unlimited venue listing with base price
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Free six months trial on annual membership
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Cancel anytime before trial ends
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Enhanced profile on Findmyvenue.com
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Direct website enquiries
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            No booking fees on the platform
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Promotions on the platform
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Direct access to venue seekers
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Strategic reporting & analytics
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            1x Social media post on Findmyvenue platform
          </li>
          <li className="flex items-center text-[12px] md:text-[14px]">
            <span className="text-[#FF3939] mr-2">✔</span>
            Digital guided onboarding
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PlanBenefits;
