import { FaStar, FaRegStar } from "react-icons/fa";

export default function Rating({ rating }) {
  const ratingNumber = parseInt(rating);
  const stars = Array.from({ length: 5 }, (_, index) => {
    const isFilled = index < ratingNumber;
    return isFilled ? (
      <FaStar key={index} className="text-[#F2C200] text-[18px]" />
    ) : (
      <FaRegStar key={index} className="text-[#F2C200] text-[18px]" />
    );
  });

  return <div className="flex items-center gap-2">{stars}</div>;
}
