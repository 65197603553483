import React, { useEffect, useState } from "react";

function VerifyPhoneModal({ user, setVerifyPhoneModal, loginDetails }) {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timeLeft, setTimeLeft] = useState(45);

  // Handle OTP input changes
  const handleOtpChange = (index, value) => {
    if (!/^\d?$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move focus to the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`)?.focus();
    }
  };

  // Timer functionality
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const handleResend = () => {
    setTimeLeft(45);
  };

  const handleSubmit = () => {
    const otpCode = otp.join("");
    console.log("Submitted OTP:", otpCode);
  };

  return (
    <div className="fixed inset-0 font-outfit flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg md:w-[600px]">
        {/* Close Button */}
        <div className="flex justify-end">
          <img
            onClick={() => setVerifyPhoneModal(false)}
            src="/cross.svg"
            alt="close"
            className="cursor-pointer"
          />
        </div>
        <div className="text-center mt-2">
          <h2 className="text-[#1D1F2C] text-[32px] font-bold mt-2">
            Verify Phone Number
          </h2>

          <p className="mt-[2px] text-[#5E5E5E] font-normal text-[16px]">
            We've sent a code to {loginDetails?.mobileNumber}. Enter the code
            you receive below to <br /> verify.
          </p>
        </div>
        {/* OTP Input */}
        <div className="flex justify-center gap-2 mt-[32px]">
          {otp?.map((digit, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              maxLength="1"
              value={digit}
              placeholder="-"
              onChange={(e) => handleOtpChange(index, e.target.value)}
              className="w-[56px] placeholder:text-[#222222] h-[64px] text-center border border-[#B0B0B0] rounded-md text-xl"
            />
          ))}
        </div>

        {/* Timer */}
        <p className="mt-[16px] text-[14px] text-[#222222] flex items-center justify-center">
          {timeLeft > 0
            ? `The OTP should arrive within ${timeLeft}s`
            : "Didn't receive a OTP?"}
          {timeLeft === 0 && (
            <span
              onClick={handleResend}
              className="text-[#FE4747] ml-2 font-medium underline underline-offset-2 cursor-pointer active:opacity-55"
            >
              Resend OTP
            </span>
          )}
        </p>

        <div className="flex justify-center mt-[30px]">
          <button
            onClick={handleSubmit}
            type="submit"
            className="md:w-[400px] active:opacity-55 bg-[#FF3939] mt-[40px] text-white w-full py-2 rounded-xl"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
}

export default VerifyPhoneModal;
