/* eslint-disable */

import Navbar from "../../../components/NavbarCustomer";
import VenuePreview from "../../../components/venuePreview/VenuePreview";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { base_URL } from "../../../config/config";
import { DeviceType } from "../../../hooks/deviceType";
import axios from "axios";
import { useEffect } from "react";

const Preview = ({ filters, setFilters }) => {
  const deviceType = DeviceType();
  const user = localStorage.getItem("authUser");
  const { id, cf } = useParams();

  const { data, isError, error } = useQuery({
    queryKey: ["previewData", id], // Key for caching
    queryFn: async () => {
      if (!id) throw new Error("ID is required");
      const response = await axios.post(`${base_URL}/api/hotels/get-preview`, {
        id,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch preview data: ${response.statusText}`);
      }

      return response.data; // Axios automatically parses JSON
    },
    enabled: !!id, // Enable query only when `id` is available
  });

  const favoriteVenues =
    user?.favoritesFolder?.map((folder) => folder.venues).flat() || [];

  const isFavorite =
    data && favoriteVenues.some((favorite) => favorite?.id === data?._id);

  const updatePageVisits = async (userId, vendorId, venueName) => {
    const pageVisitData = {
      venueId: id,
      venueName: venueName,
      date: new Date(),
      deviceCategory: deviceType,
      customerId: userId,
      pageVisits: 1,
      vendorId: vendorId,
      isFav: isFavorite,
    };
    // console.log(pageVisitData);
    try {
      const response = await fetch(
        `${base_URL}/api/impressions/update-impressions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pageVisitData),
        }
      );
      // console.log(response);
      const data = await response.json();
      // console.log(data);
      return data;
    } catch (err) {
      // console.log(err);
      return err;
    }
  };

  useEffect(() => {
    if (data && cf === "hpv") {
      // console.log("Data inside Preview Component", data);
      const venueName = `${data?.venue?.basicDetails?.venueName}, ${data?.venue?.basicDetails?.hotelName}`;
      const vendorId = data?.venue?.vendorId;
      if (!user) {
        updatePageVisits("guest", vendorId, venueName);
      } else if (user?._id && vendorId && venueName) {
        updatePageVisits(user?._id, vendorId, venueName);
      }
    }
  }, [data]);

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <Navbar filters={filters} setFilters={setFilters} />
      {data && <VenuePreview data={data.venue} />}
    </div>
  );
};

export default Preview;
