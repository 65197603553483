import React from "react";

function Eventtypezero() {
  return (
    <div className="w-full bg-[#FFFFFF]  rounded-xl font-outfit md:w-[65%] pb-3 h-[333px]">
      <div className="flex flex-row items-center justify-between ml-5 md:ml-[20px] mt-[20px]">
        <div className="flex flex-col gap-y-2">
          <div className="text-[14px] md:text-[20px] font-bold">
            Total Enquires by Event Type{" "}
          </div>
        </div>
      </div>
      <div className=" h-full w-full flex flex-col gap-y-3 items-center mt-[64px]">
        <img src="/eventzero.svg" alt="" />
        <p className="text-[14px] md:text-[16px] text-[#222222] font-normal text-center">
          At this moment, you have not yet received any enquiries regarding any
          venue.
        </p>
      </div>
    </div>
  );
}

export default Eventtypezero;
