import React, { createContext, useState, useContext } from "react";

const PreviewContext = createContext();

export const PreviewProvider = ({ children }) => {
  const [previewData, setPreviewData] = useState(null); // Fix typo here

  const handleSetPreviewData = (data) => {
    setPreviewData(data);
    // console.log("Data inside preview context", data);
  };

  return (
    <PreviewContext.Provider
      value={{ previewData, setPreviewData: handleSetPreviewData }} // Fix typo here
    >
      {children}
    </PreviewContext.Provider>
  );
};

export const usePreview = () => useContext(PreviewContext);
