import React from "react";

function Tooltipdashbaord({
  tooltipRef,
  setIsOpen,
  isLoading,
  error,
  daysLeft,
  lastPaymentDate,
  nextPaymentDate,
  isOnTrial,
}) {
  return (
    <div
      ref={tooltipRef}
      className="font-outfit w-[220px] lg:w-[268px] z-[1000] absolute"
    >
      {/* Triangle Pointer */}
      <div className="absolute  mt-1 left-9 w-4 h-4 bg-[#222222] transform rotate-45 " />
      <div className="absolute mt-3 p-3 lg:p-6  bg-[#222222] rounded-2xl shadow-lg  lg:w-full z-50">
        {/* Close Button */}
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-6 right-4 hidden lg:inline text-gray-400 hover:text-white"
        >
          <img src="/crosstool.svg" alt="" />
        </button>

        {/* Content */}
        <div className="space-y-4">
          {isLoading ? (
            <div className="flex items-center justify-center animate-pulse ">
              <p className="text-white text-sm">Loading...</p>
            </div>
          ) : (
            <>
              {isOnTrial ? (
                // Trial Status Display
                <div className="flex gap-8">
                  <div>
                    <p className="text-white text-[10px] lg:text-[14px]">
                      Free trial - {Math.max(0, daysLeft)} days left
                    </p>
                  </div>
                </div>
              ) : lastPaymentDate === "N/A" && nextPaymentDate === "N/A" ? (
                // No Subscription Status
                <div className="flex gap-8">
                  <div>
                    <p className="text-white text-[10px] lg:text-[14px]">
                      No active subscription
                    </p>
                  </div>
                </div>
              ) : (
                // Active Subscription Status
                <div className="flex gap-8">
                  <div>
                    <p className="text-[#B0B0B0] text-[10px]">Last Payment</p>
                    <p className="text-white text-[10px] lg:text-[14px]">
                      {lastPaymentDate}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#B0B0B0] text-[10px]">Renewal Date</p>
                    <p className="text-white text-[10px] lg:text-[14px]">
                      {nextPaymentDate}
                    </p>
                  </div>
                </div>
              )}

              {/* Show More Details Link - Only show if there's valid subscription data */}
              {(isOnTrial ||
                (lastPaymentDate !== "N/A" && nextPaymentDate !== "N/A")) && (
                <div
                  onClick={() => {
                    /* Add your show more details handler here */
                  }}
                  className="active:opacity-55 flex items-center text-[12px] lg:text-[14px] gap-2 text-[#FE4747] hover:text-red-300 cursor-pointer"
                >
                  <span>Show more details</span>
                  <img src="/right-short.svg" alt="Arrow" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tooltipdashbaord;
