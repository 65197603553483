import React from "react";
import Button from "./shared/Button";
import { Google } from "../svgs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";

import { useDispatch } from "react-redux";
import { setUser } from "../store/features/authSlice";
import { closeModals } from "../store/features/modalSlice";
import { toast } from "react-toastify";
import { base_URL } from "../config/config";

const SocialModal = ({ onLoginClick, onAdditionalClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGoogleResponse = async (response) => {
    try {
      if (response.code) {
        const result = await axios.get(
          `${base_URL}/api/auth/google?code=${response.code}`
        );
        console.log(result, "result");
        console.log(response, "response");
        const { data, token } = result.data;

        // Update Redux store and local storage
        dispatch(setUser(data));
        dispatch(closeModals());

        localStorage.setItem("authToken", token);
        localStorage.setItem("authUser", JSON.stringify(data));

        toast.success("Logged in successfully!");
        navigate("/");
      }
    } catch (error) {
      console.error("Google login error:", error);
      toast.error(
        error.response?.data?.message || "Failed to login with Google"
      );
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleResponse,
    onError: (error) => {
      console.error("Google OAuth Error:", error);
      toast.error("Failed to login with Google");
    },
    flow: "auth-code",
  });
  return (
    <div>
      <p className="text-start -mt-2">
        <span
          onClick={onLoginClick}
          className="underline cursor-pointer font-medium"
        >
          Sign in
        </span>{" "}
        or{" "}
        <span
          onClick={onLoginClick}
          className="underline cursor-pointer font-medium"
        >
          join
        </span>{" "}
        another way Sign up for free
      </p>

      <div className="flex flex-col gap-6 mt-5">
        <div className="flex items-center gap-3 py-5 border-0 border-t border-b border-bordered">
          <Google />
          <p className="underline font-semibold">Sign in to join with Google</p>
        </div>

        <Button onClick={login} label={"Continue"} />

        <p className="text-[#717171]">
          To create your account, Google will share your name, email address,
          and profile picture with Design Community. Learn more about{" "}
          <Link className="text-dark font-medium">Terms of services</Link> and{" "}
          <Link className="text-dark font-medium">Privacy policy.</Link>
        </p>
      </div>
    </div>
  );
};

export default SocialModal;
