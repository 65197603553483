import axios from "axios";
import React, { useState } from "react";
// import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// import Verifyuser from "../pages/user/Verifyuser";
// import UAE from "../assets2/uae.png";
import {
  resetFormData,
  setAdditionalData,
  setSubmitting,
} from "../store/features/authSlice";
import { toggleCheckInbox } from "../store/features/modalSlice";
// import { setUser } from "../store/features/authSlice";
import Button from "./shared/Button";
import CustomInput from "./shared/CustomInput";
import { useNavigate } from "react-router-dom";
import { base_URL } from "../config/config";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

const AdditionalDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [country, setCountry] = useState("AE"); // Default country
  const [countryCode, setCountryCode] = useState("+971");
  const { formData, additionalData, isSubmitting } = useSelector(
    (state) => state.auth
  );
  const handleCountryChange = (countryCode) => {
    if (countryCode) {
      setCountry(countryCode);

      try {
        // Directly get the calling code for the selected country
        const dialCode = getCountryCallingCode(countryCode);
        setCountryCode(`+${dialCode}`);

        // If no number is entered, set a placeholder with the new country code
        if (!additionalData.mobileNumber) {
          setAdditionalData({ name: "mobileNumber", value: `+${dialCode}` });
        }
      } catch (error) {
        console.error("Error getting country calling code:", error);
        setCountryCode("+");
      }
    }
  };

  const [error, setError] = React.useState({
    select: "",
    companyName: "",
    mobileNumber: "",
  });

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    dispatch(setAdditionalData({ name: "select", value: selectedValue }));

    // If the selected option is not "corporate", reset the companyName
    if (selectedValue !== "corporate") {
      dispatch(setAdditionalData({ name: "companyName", value: "" }));
    }
  };

  const handleCompanyNameChange = (e) => {
    dispatch(setAdditionalData({ name: "companyName", value: e.target.value }));
  };

  const handleMobileNumberChange = (value) => {
    dispatch(setAdditionalData({ name: "mobileNumber", value: value }));
  };

  const handleSubmit = async () => {
    dispatch(setSubmitting(true));

    // Combine formData and additionalData
    const allData = {
      ...formData,
      ...additionalData,
    };

    // Validation checks
    if (!additionalData.select) {
      // toast.error("Please select an option.");
      setError((prev) => ({
        ...prev,
        select: "Please select an option.",
      }));
      dispatch(setSubmitting(false));
      return;
    }

    if (additionalData.select === "corporate" && !additionalData.companyName) {
      // toast.error("Company name is required for corporate selection.");
      setError((prev) => ({
        ...prev,
        companyName: "Company name is required for corporate selection.",
      }));
      dispatch(setSubmitting(false));
      return;
    }

    if (!additionalData.mobileNumber) {
      // toast.error("Mobile number is required.");
      setError((prev) => ({
        ...prev,
        mobileNumber: "Mobile number is required.",
      }));
      dispatch(setSubmitting(false));
      return;
    }

    try {
      const parsedNumber = parsePhoneNumberFromString(
        additionalData.mobileNumber,
        additionalData.country || "AE"
      );
      if (!parsedNumber || !parsedNumber.isValid()) {
        setError((prev) => ({
          ...prev,
          mobileNumber: "Invalid mobile number for the selected country.",
        }));
        dispatch(setSubmitting(false));
        return;
      }
      const updatedAllData = {
        ...allData,
        mobileNumber: parsedNumber.number,
        isVerified: false,
      };
      const response = await axios.post(
        `${base_URL}/api/auth/register`,
        updatedAllData
      );
      const { accessToken, createdUser } = response.data;
      dispatch(toggleCheckInbox());

      dispatch(resetFormData());

      // dispatch(setUser(createdUser));
      localStorage.setItem("authToken", accessToken);
      // localStorage.setItem("authUser", JSON.stringify(createdUser));
    } catch (err) {
      toast.error(
        err.response?.data?.message || "Something went wrong. Please try again."
      );
    } finally {
      dispatch(setSubmitting(false));
    }
  };

  return (
    <div>
      <p className="text-center">One last step before sending your enquiry</p>

      <div className="flex flex-col gap-4 mt-4">
        <div>
          <p className="text-dark font-semibold">I am...</p>
          <div className="relative cursor-pointer pr-1">
            <select
              value={additionalData.select || ""}
              onChange={handleSelectChange}
              className="w-full text-[#000000] py-2 pl-1 pr-4 cursor-pointer border outline-none border-bordered rounded-md "
            >
              <option value="">Select an option</option>
              <option value="individual">Individual</option>
              <option value="eventPlanner">Event Planner</option>
              <option value="corporate">Corporate</option>
            </select>
            {/* <IoMdArrowDropdown className="absolute text-dark text-xl right-2 top-1/2 -translate-y-1/2" /> */}
          </div>
          <span className="text-red-400 text-sm">{error.select}</span>
        </div>

        {additionalData.select === "corporate" && (
          <div className="flex flex-col gap-4">
            <CustomInput
              label={"Company Name"}
              type={"text"}
              error={error.companyName}
              placeholder={"Enter company name"}
              value={additionalData.companyName || ""}
              onChange={handleCompanyNameChange}
            />
          </div>
        )}

        <div className="flex flex-col gap-4 ">
          {/* <label className="text-dark font-semibold" htmlFor="mobileNumber">
            Mobile Number
          </label>
          <div className="flex justify-starts items-center gap-2  border outline-none border-bordered rounded-md">
            <div className="border-r w-12 h-8 border-bordered">
              <img
                src={UAE}
                alt="UAE"
                className="w-full h-full bg-cover px-[1px]"
              />
            </div>
            <input
              type="text"
              id="mobileNumber"
              value={additionalData.mobileNumber || "+971"}
              onChange={handleMobileNumberChange}
              placeholder="Enter mobile number"
              className="w-full text-dark border-none outline-none py-2 pl-1 bg-transparent"
            />
          </div> */}
          <div className="relative">
            <div className="absolute left-[60px] top-1/2 transform   z-10 pointer-events-none">
              {countryCode}
            </div>
            <label
              htmlFor=""
              className="relative text-dark font-semibold font-outfit"
            >
              Mobile Number
            </label>
            <PhoneInput
              id="mobile-input"
              placeholder="123 456 7890"
              value={additionalData.mobileNumber || ""}
              defaultCountry="AE"
              withCountryCallingCode={true}
              autoComplete="on"
              onCountryChange={handleCountryChange}
              onChange={handleMobileNumberChange}
              style={{
                border: "1px solid #d1d5db",
                boxShadow: "none",
                borderRadius: "10px",
                outline: "none",
                width: "100%",
                padding: "0.5rem",
                // marginTop: "0.3rem",
              }}
              className="no-focus-border  "
            />
          </div>
          <span className="text-red-400 text-sm">{error.mobileNumber}</span>
        </div>
        <Button
          onClick={handleSubmit}
          label={isSubmitting ? "Submitting..." : "Submit"}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
};

export default AdditionalDetails;
