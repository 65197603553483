import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import logo from "../assets2/images/whiteLogo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoMdHeartEmpty,
} from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import Box from "@mui/material/Box";
import profileIcon from "../assets/images/userres.svg";
import profileIcon2 from "../assets/images/downres.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import NavPopup from "./shared/NavPopup";
import Modal from "./shared/Modal";
import SignUpModal from "././SignUpModal";
import LoginModal from "././LoginModal";
import SocialModal from "././SocialModal";
import AdditionalDetails from "././AdditionalDetails";
import CheckInboxModal from "././CheckInboxModal";
import LogoutModal from "././LogoutModal";
import {
  toggleExplore,
  toggleMobileExplore,
  setHoveredOption,
  setActiveSubOption,
  toggleNav,
  togglePopup,
  toggleSignUp,
  toggleLogin,
  toggleSocial,
  toggleAdditional,
  toggleCheckInbox,
  closeModals,
  toggleReset,
  toggleLogoutModal,
} from "../store/features/modalSlice";
import ResetPassword from "./forget-password/ResetPassword";
import ResetMailSend from "./forget-password/ResetMailSent";
import ResetWorkMail from "./forget-password/ResetWorkMail";
import PasswordUpdated from "./forget-password/PasswordUpdated";
import { resetFormData } from "../store/features/authSlice";
import Cookies from "js-cookie";
import { is } from "date-fns/locale";

const NavbarCustomer = ({ setFilters }) => {
  const dispatch = useDispatch();
  const [subOption, setSubOption] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const vendorId = localStorage.getItem("vendorId");
  const isLoggedIn = !!localStorage.getItem("accessToken");
  const [encryptedMail, setEncryptedMail] = useState("");
  const {
    openExplore,
    openMobileExplore,
    hoveredOption,
    activeSubOption,
    openNav,
    openPopup,
    selectedSubOptions,
    selectedName,
    showSignUp,
    showLogin,
    showReset,
    showSocial,
    resetWorkMail,
    showAdditional,
    showCheckInbox,
    resetMailSend,
    updatePassword,
    showLogoutModal,
  } = useSelector((state) => state.modal);

  const { user } = useSelector((state) => state.auth);
  const dropdownRef = useRef(null);

  const startOptions = [
    {
      name: "Celebrations",
      filterKey: "eventTypes",
      subKey: [
        "Weddings",
        "Weddings",
        "Dining",
        "Dining",
        "Dining",
        "Dining",
        "Milestone Birthdays",
        "Tea Party/Showers",
        "Tea Party/Showers",
        "Teen/Children",
        "Special Big Events",
      ],
      subOptions: [
        "Wedding Ceremony",
        "Wedding Reception",
        "Anniversary Party",
        "Engagement Reception",
        "Graduation Party",
        "New Year's Eve Party",
        "Birthday Party",
        "Baby Shower",
        "Bridal Shower",
        "Teen Party",
        "Gender Reveals",
        "Christmas Party",
        "Cultural Celeberations",
      ],
    },
    {
      name: "Art & Culture",
      filterKey: "eventTypes",
      subKey: [
        "Filming, Photography & Media",
        "Art Spaces",
        "Art Spaces",
        "Performances & Exhibitions",
        "Performances & Exhibitions",
        "Performances & Exhibitions",
      ],
      subOptions: [
        "Photoshoot",
        "Art Exhibitions",
        "Pop Up Event",
        "Concert",
        "Fashion Show",
        "Live Performance",
      ],
    },
    {
      name: "Corporate",
      filterKey: "eventTypes",
      subKey: [
        "Conference/Seminars",
        "Conference/Seminars",
        "Conference/Seminars",
        "Conference/Seminars",
        "Conference/Seminars",
        "Corporate Events",
        "Conference/Seminars",
        "Corporate Events",
        "Corporate Events",
        "Corporate Events",
      ],
      subOptions: [
        "Conference",
        "Exhibitions",
        "Meetings",
        "Networking",
        "Product Launch",
        "Team Building",
        "Training",
        "Office Party",
        "Corporate Gala Dinner",
        "Corporate Retreat",
      ],
    },
    {
      name: "City",
      filterKey: "city",
      subOptions: [
        "Dubai",
        "Abu Dhabi",
        "Ras Al Khaimah",
        "Sharjah",
        "Fujairah",
        "Ajman",
        "Umm Al Quwain",
      ],
    },
    {
      name: "Venue Type",
      filterKey: "venueTypes",
      subOptions: [
        "Beach",
        "Deserts",
        "Boats and Yachts",
        "Venue with Onsite Activities",
        "Sports Venue",
        "Exhibition Hall",
        "Meeting room",
        "Kids play area",
        "Restaurant",
        "Ballroom",
        "Blank Canvas",
        "Auditorium",
        "Warehouse",
        "Indoor",
        "Outdoor",
        "Indoor & Outdoor",
      ],
    },
    {
      name: "Hotel Stay + Venue",
      filterKey: "hotelStayAndVenue",
      subOptions: [
        "Airport Hotel",
        "All-Inclusive Resort",
        "Beach Hotel",
        "Boutique Hotel",
        "Budget Hotel",
        "Business Hotel",
        "Casino Hotel",
        "City Hotel",
        "Desert Hotel",
        "Eco-friendly Hotel",
        "Floating Hotel",
        "Glamping Hotel",
        "Golf Resorts",
        "Heritage Hotel",
        "Historic Hotel",
        "Island Hotel",
        "Luxury Hotel",
        "Resort Hotel",
        "Safari Hotel",
        "Wellness Hotel",
      ],
    },
  ];
  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  const handleLogout = () => {
    Cookies.remove("token", { path: "/" });
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    // Add event listener to close Explore when clicking outside
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        openExplore // Only close if open
      ) {
        dispatch(toggleExplore());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, openExplore]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get("email");

    // If email exists in the query, dispatch toggleReset to open the reset password modal
    if (emailParam) {
      const decryptMail = window.atob(emailParam);
      setEncryptedMail(decryptMail);
      dispatch(toggleReset());
    }
  }, [location, dispatch]);

  const handleSelectOption = (category, option) => {
    if (category === "hotelStayAndVenue") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        hotelType: [option],
      }));
    } else if (category === "eventTypes") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        eventTypes: option,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [category]: [option],
      }));
    }
    dispatch(setHoveredOption(null));
    dispatch(setActiveSubOption(null));
    dispatch(toggleExplore());
    if (category === "hotelStayAndVenue") {
      navigate("/listing/2");
    } else {
      navigate(`/listing/1`);
    }
  };

  const isHomePage = location.pathname === "/";

  // useEffect(() => {
  //   console.log(filters);
  // }, [filters]);
  return (
    <div className="bg-primary flex justify-center w-full py-3 z-50">
      <div className="w-[90%] flex justify-between items-center">
        <div>
          {!isHomePage ? (
            <Link to={"/"}>
              <img alt="Logo" src={logo} />
            </Link>
          ) : (
            <img alt="Logo" src={logo} />
          )}
        </div>

        <div
          className={`lg:flex hidden items-center relative ${
            isLoggedIn ? "gap-3" : "gap-8"
          }`}
          ref={dropdownRef}
        >
          <div
            className="sm:flex hidden items-center gap-2 cursor-pointer"
            onClick={() => dispatch(toggleExplore())}
          >
            <h1 className="text-white">Explore</h1>
            {openExplore ? (
              <IoMdArrowDropup className="text-white text-lg" />
            ) : (
              <IoMdArrowDropdown className="text-white text-lg" />
            )}
            {openExplore && (
              <div className="bg-white rounded-lg z-50 absolute top-9 left-0 shadow-xl p-2">
                {startOptions.map((e, i) => (
                  <div
                    key={i}
                    className={`py-2 px-2 w-36 flex-1 text-sm flex items-center justify-between gap-2 rounded-md hover:bg-[#FFF5F5] ${
                      hoveredOption === i ? "bg-[#FFF5F5]" : ""
                    }`}
                    onMouseEnter={() => {
                      dispatch(setHoveredOption(i));
                      dispatch(
                        setActiveSubOption(activeSubOption === i ? null : i)
                      );
                    }}
                    onMouseLeave={() => {
                      dispatch(setHoveredOption(null));
                    }}
                    // onClick={(event) => {
                    //   event.stopPropagation();
                    //   dispatch(
                    //     setActiveSubOption(activeSubOption === i ? null : i)
                    //   );
                    // }}
                  >
                    {e.name}
                    <MdOutlineKeyboardArrowRight className="text-lg flex-shrink-0" />
                    {(activeSubOption === i || hoveredOption === i) &&
                      e.subOptions && (
                        <div
                          className={`bg-white custom-scrollbar rounded-md absolute overflow-auto max-h-screen right-full grid ${
                            i === 1
                              ? "grid-cols-2 xl:w-[32rem] w-[28rem]"
                              : i === 0
                              ? "grid-cols-2 xl:w-[22rem] w-[20rem]"
                              : i === 4
                              ? "grid-cols-2 xl:w-[32rem] w-[28rem]"
                              : "grid-cols-2 xl:w-[22rem] w-[20rem]"
                          } w-full mr-1 top-0 p-2 shadow-lg`}
                        >
                          {e.subOptions.map((subOption, subIndex) => (
                            <div
                              key={subIndex}
                              className="px-4 py-3 rounded-md text-sm hover:bg-[#FFF0F0]"
                              onClick={
                                () => {
                                  if (e.filterKey === "eventTypes") {
                                    const subKeyValue =
                                      startOptions[i].subKey[subIndex];
                                    setSubOption(
                                      startOptions[i].subKey[subIndex]
                                    );
                                    const eventType = {
                                      [subKeyValue]: [subOption],
                                    };

                                    handleSelectOption(e.filterKey, eventType);
                                  } else {
                                    handleSelectOption(e.filterKey, subOption);
                                  }
                                  dispatch(toggleExplore());
                                } // dynamically set the category using filterKey
                              }
                            >
                              {subOption}
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            )}
          </div>

          {user && (
            <Link to="/my-favorites" className="text-white hidden sm:flex  ">
              <div className="flex justify-center items-center gap-1">
                <IoMdHeartEmpty size={24} /> <span>My Favorites</span>
              </div>
            </Link>
          )}

          {user && (
            <Link to="/enquiries" className="text-white hidden sm:flex  ">
              <div className="flex justify-center items-center gap-1">
                <IoDocumentTextOutline size={24} /> <span>My Enquiries</span>
              </div>
            </Link>
          )}

          {!user && !vendorId ? (
            <>
              <button
                onClick={() => dispatch(toggleLogin())}
                className="text-white"
              >
                Login
              </button>
              <button
                onClick={() => dispatch(toggleSignUp())}
                className="bg-white py-1 px-[14px] rounded-[4px] text-[#222222] lg:text-[14px] lg:font-[500] h-[40px]"
              >
                Signup
              </button>
            </>
          ) : (
            <div>
              {!vendorId && (
                <div className="flex items-center gap-x-2">
                  {user?.profilePic ? (
                    <>
                      <div className="relative w-8 h-8 overflow-hidden bg-[#cccccc] rounded-full ">
                        <img src={user?.profilePic} alt="" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="relative w-8 h-8 overflow-hidden bg-[#cccccc] rounded-full ">
                        <svg
                          className="absolute w-10 h-10 text-[#ffff] -left-1"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </>
                  )}

                  <IoMdArrowDropdown
                    className="text-white text-lg cursor-pointer"
                    onClick={() => dispatch(toggleLogoutModal())}
                  />
                </div>
              )}
            </div>
          )}

          {isLoggedIn && (
            <Box
              sx={{ display: { md: "flex" }, alignItems: "center", zIndex: 10 }}
            >
              <IconButton onClick={handleOpenProfileMenu} sx={{ zIndex: 10 }}>
                <Box
                  component="img"
                  src={profileIcon}
                  alt="profile"
                  sx={{ height: 45, width: 45 }}
                />
                <Box
                  component="img"
                  src={profileIcon2}
                  alt="profile"
                  className="ml-3"
                />
              </IconButton>
              <Menu
                anchorEl={anchorElProfile}
                open={Boolean(anchorElProfile)}
                onClose={handleCloseProfileMenu}
                sx={{ zIndex: 11 }}
              >
                <MenuItem onClick={handleLogout}>Log Out</MenuItem>
              </Menu>
            </Box>
          )}
        </div>

        <button
          className="lg:hidden block px-2"
          onClick={() => dispatch(toggleNav())}
        >
          {openNav ? (
            <RxCross2 className="text-white text-3xl" />
          ) : (
            <FiMenu className="text-white text-3xl" />
          )}
        </button>

        <div
          className={`lg:hidden fixed top-20 right-0 z-50 w-full h-full bg-white transition-transform ${
            openNav ? "-translate-x-0" : "-translate-x-full"
          }`}
        >
          {!user ? (
            <div className="w-full px-8 pt-20 text-primary flex flex-col gap-8">
              <div
                onClick={() => dispatch(toggleMobileExplore())}
                className="z-40 text-[16px] font-bold flex justify-between items-center w-full"
              >
                <p>Explore</p>
                {openMobileExplore ? (
                  <IoMdArrowDropup className="text-2xl" />
                ) : (
                  <IoMdArrowDropdown className="text-2xl" />
                )}
              </div>
              {openMobileExplore && (
                <div className="flex text-xl flex-col gap-8 pl-6">
                  {startOptions.map((e, i) => (
                    <div
                      key={i}
                      className="flex justify-between text-[16px] text-[#FE4747] font-medium items-center cursor-pointer"
                      onClick={() =>
                        dispatch(
                          togglePopup({
                            isOpen: true,
                            subOptions: e.subOptions,
                            name: e.name,
                          })
                        )
                      }
                    >
                      {e.name}
                      <MdOutlineKeyboardArrowRight className="text-2xl flex-shrink-0" />
                    </div>
                  ))}
                </div>
              )}
              {user && (
                <Link
                  to="/my-favorites"
                  className="text-[16px] text-[#FE4747] font-medium"
                >
                  <div className="flex justify-start items-center gap-1">
                    <IoMdHeartEmpty size={24} /> <span>My Favorites</span>
                  </div>
                </Link>
              )}
              {isLoggedIn && (
                <button
                  onClick={() => {
                    Cookies.remove("token", { path: "/" });
                    localStorage.clear();
                    navigate("/");
                  }}
                  className="text-[16px] text-[#FE4747] w-full text-left font-bold"
                >
                  Logout
                </button>
              )}
              {!user && !isLoggedIn && (
                <>
                  <button
                    onClick={() => {
                      dispatch(toggleLogin());
                      dispatch(resetFormData());
                    }}
                    className="font-semibold w-fit text-2xl"
                  >
                    Login
                  </button>
                  <button
                    onClick={() => {
                      dispatch(toggleSignUp());
                      dispatch(resetFormData());
                    }}
                    className="font-semibold w-fit text-2xl"
                  >
                    Signup
                  </button>
                </>
              )}
            </div>
          ) : (
            <div className="w-full px-8 pt-20 text-primary flex flex-col gap-8">
              <div
                onClick={() => dispatch(toggleMobileExplore())}
                className="z-40 text-2xl font-semibold flex justify-between items-center w-full"
              >
                <p>Explore</p>
                {openMobileExplore ? (
                  <IoMdArrowDropup className="text-2xl" />
                ) : (
                  <IoMdArrowDropdown className="text-2xl" />
                )}
              </div>
              {openMobileExplore && (
                <div className="flex text-xl flex-col gap-8 pl-6">
                  {startOptions.map((e, i) => (
                    <div
                      key={i}
                      className="flex justify-between text-[16px] text-[#FE4747] font-medium items-center cursor-pointer"
                      onClick={() => {
                        dispatch(
                          togglePopup({
                            isOpen: true,
                            subOptions: e.subOptions,
                            name: e.name,
                          })
                        );
                      }}
                    >
                      {e.name}
                      <MdOutlineKeyboardArrowRight className="text-2xl flex-shrink-0" />
                    </div>
                  ))}
                </div>
              )}
              {user && (
                <Link
                  to="/my-favorites"
                  className="text-[16px] text-[#FE4747] font-medium"
                >
                  <div className="flex justify-start items-center gap-1">
                    <IoMdHeartEmpty size={24} /> <span>My Favorites</span>
                  </div>
                </Link>
              )}
              {user && (
                <Link
                  to="/enquiries"
                  className="text-[16px] text-[#FE4747] font-medium"
                >
                  <div className="flex justify-start items-center gap-1">
                    <IoDocumentTextOutline size={24} />{" "}
                    <span>My Enquiries</span>
                  </div>
                </Link>
              )}
              <div className="flex items-center gap-x-2 relative z-[9999]">
                <div className="relative w-8 h-8 overflow-hidden bg-[#cccccc] rounded-full ">
                  <svg
                    className="absolute w-10 h-10 text-[#ffff] -left-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <IoMdArrowDropdown
                  className="text-white text-lg cursor-pointer"
                  onClick={() => dispatch(toggleLogoutModal())}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <NavPopup
        openPopup={openPopup}
        onClose={() => dispatch(togglePopup({ isOpen: false }))}
        subOptions={selectedSubOptions}
        selectedName={selectedName}
      />

      <LogoutModal isOpen={showLogoutModal} />

      <Modal
        isOpen={showSignUp}
        onClose={() => {
          dispatch(closeModals());
          dispatch(resetFormData());
        }}
        label="Sign up"
      >
        <SignUpModal
          setShowSignUp={() => dispatch(toggleSignUp())}
          onLoginClick={() => {
            dispatch(toggleLogin());
            dispatch(resetFormData());
          }}
          onSubmit={() => dispatch(toggleCheckInbox())}
          onSocialClick={() => dispatch(toggleSocial())}
        />
      </Modal>

      <Modal
        isOpen={showLogin}
        onClose={() => {
          dispatch(closeModals());
          dispatch(resetFormData());
        }}
        label="Log in"
      >
        <LoginModal
          onSignUpClick={() => dispatch(toggleSignUp())}
          onSubmit={() => dispatch(closeModals())}
          onSocialClick={() => dispatch(toggleSocial())}
        />
      </Modal>

      <Modal
        isOpen={showReset}
        onClose={() => dispatch(closeModals())}
        label="Reset Password"
      >
        <ResetPassword
          onSubmit={() => dispatch(closeModals())}
          mail={encryptedMail}
        />
      </Modal>
      <Modal
        isOpen={updatePassword}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password"
      >
        <PasswordUpdated
          onSubmit={() => dispatch(closeModals())}
          mail={encryptedMail}
        />
      </Modal>

      <Modal
        isOpen={resetMailSend}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password Email Sent"
      >
        <ResetMailSend onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={resetWorkMail}
        onClose={() => dispatch(closeModals())}
        label="Reset Password"
      >
        <ResetWorkMail onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={resetMailSend}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password Email Sent"
      >
        <ResetMailSend onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={showSocial}
        onClose={() => dispatch(closeModals())}
        label=""
      >
        <SocialModal
          onLoginClick={() => dispatch(toggleLogin())}
          onAdditionalClick={() => dispatch(toggleAdditional())}
        />
      </Modal>

      <Modal
        isOpen={showAdditional}
        onClose={() => dispatch(closeModals())}
        label="Additional Details"
      >
        <AdditionalDetails onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={showCheckInbox}
        onClose={() => dispatch(closeModals())}
        label=""
      >
        <CheckInboxModal />
      </Modal>
    </div>
  );
};

export default NavbarCustomer;
