import moment from "moment";
import React from "react";

const CancellationDoneModal = ({ isOpen, onClose, user }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-[90%] md:w-[650px] p-6 rounded-xl shadow-lg relative">
        <div className="flex justify-end">
          <img
            src="/cancelsub.svg"
            alt=""
            onClick={onClose}
            className="cursor-pointer active:opacity-55"
          />
        </div>
        <h2 className="text-[22px] md:text-[32px] text-center text-[#1D1F2C] font-bold">
          Subscription Cancelled
        </h2>
        <div className="mx-auto flex items-center justify-center">
          <p className="mt-[32px]  text-[16px]  text-[#222222]">
            Your subscription has been cancelled.
            {user?.paymentStatus == "Cancelled" &&
            user?.trialStatus == "finished" ? (
              <>
                You have access to your account until <br />
                {moment(user?.trialEnd).format("MMMM D, YYYY")}
              </>
            ) : user?.paymentStatus == "Cancelled" &&
              user?.trialStatus == "remaining" ? (
              <>
                {" "}
                You have access to your account until <br />
                {moment(user?.trialEnd).format("MMMM D, YYYY")}
              </>
            ) : (
              <></>
            )}
          </p>
        </div>
        <div className="mx-auto w-full  flex items-center justify-center">
          <button
            onClick={onClose}
            className="bg-[#FE4747]  active:opacity-55  text-white p-2 rounded-lg w-full md:w-[400px] mt-[40px]"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancellationDoneModal;
