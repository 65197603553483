import React, { useEffect, useState } from "react";
import { base_URL } from "../../config/config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import {
  activeStage,
  stage,
  activeMeating,
  meating,
  table,
  activeTable,
  playground,
  activePlayground,
} from "../../svgs";
import img1 from "../../assets/pics/hotel1.png";
import img2 from "../../assets/pics/hotel2.png";
import img3 from "../../assets/pics/hotel3.png";
import img4 from "../../assets/pics/hotel4.png";
import DetailComponent from "./DetailComponent";
import activeBallroom from "../../assets/pics/Ballroomactive.png";
import activeBeach from "../../assets/pics/sun-umbrellaactive.png";
import activeBoats from "../../assets/pics/shipactive.png";
import activeConferenceCentre from "../../assets/pics/conference1active.png";
import activeSportsClub from "../../assets/pics/marathonactive.png";

import activeExhibitionHall from "../../assets/pics/exhibition1active.png";
import activeWarehouse from "../../assets/pics/warehouseactive.png";
import activeArtStudio from "../../assets/pics/drawingsactive.png";
import activeDesert from "../../assets/pics/desertactive.png";

import Ballroom from "../../assets/pics/Ballroom.png";
import Beach from "../../assets/pics/sun-umbrella.png";
import Boats from "../../assets/pics/ship.png";
import ConferenceCentre from "../../assets/pics/conference1.png";
import SportsClub from "../../assets/pics/marathon.png";

import ExhibitionHall from "../../assets/pics/exhibition1.png";
import Warehouse from "../../assets/pics/warehouse.png";
import ArtStudio from "../../assets/pics/drawings.png";
import Desert from "../../assets/pics/desert.png";
import VenueDetailComp from "./VenueDetailComp";

const provideData = [
  {
    images: [{ img1: img1 }, { img1: img1 }, { img1: img1 }],
    name: "Indigo Spaces (coming soon)",
    loc: "Dubai, Downtown",
    sitting: "1000",
    stand: "2000",
    activity: "Photshoot . Indoor & Outdoor",
    aed1: "AED 105",
    aed2: "AED 105",
    guest: "per guest",
    spend: "Min. spend",
    price: "Starting price from per room per night",
  },
  {
    images: [{ img1: img2 }, { img1: img2 }, { img1: img2 }],
    name: "The Palm (coming soon)",
    loc: "Dubai, Downtown",
    sitting: "1000",
    stand: "2000",
    activity: "Photshoot . Indoor & Outdoor",
    aed1: "AED 105",
    aed2: "AED 105",
    guest: "per guest",
    spend: "Min. spend",
    price: "Starting price from per room per night",
  },
  {
    images: [{ img1: img3 }, { img1: img3 }, { img1: img3 }],
    name: "Luxe (coming soon)",
    loc: "Dubai, Downtown",
    sitting: "1000",
    stand: "2000",
    activity: "Photshoot . Indoor & Outdoor",
    aed1: "AED 105",
    aed2: "AED 105",
    guest: "per guest",
    spend: "Min. spend",
    price: "Starting price from per room per night",
  },
  {
    images: [{ img1: img4 }, { img1: img4 }, { img1: img4 }],
    name: "Turquoise (coming soon)",
    loc: "Dubai, Downtown",
    sitting: "1000",
    stand: "2000",
    activity: "Photshoot . Indoor & Outdoor",
    aed1: "AED 105",
    aed2: "AED 105",
    guest: "per guest",
    spend: "Min. spend",
    price: "Starting price from per room per night",
  },
];

const HotelCarousel = ({ setFilters }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [venuesData, setVenuesData] = useState([]);
  const [data1, setData1] = useState([]);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute sm:-right-10  sm:top-1/2 mt-2 transform sm:-translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#FE4747] text-xl" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute sm:-left-10  sm:top-1/2 mt-2 transform sm:-translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#FE4747] text-xl" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    afterChange: (current) => setActiveIndex(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      icon: Ballroom,
      activeIcon: activeBallroom,
      name: "Ballroom",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: meating, // Existing Icon
      activeIcon: activeMeating,
      name: "Meeting Room",

      component: <DetailComponent data={provideData} />,
    },
    {
      icon: Beach, // New Icon from Basecamp
      activeIcon: activeBeach, // New active icon
      name: "Beach",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: Boats, // New Icon from Basecamp
      activeIcon: activeBoats, // New active icon
      name: "Boats & Yachts",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: playground, // Existing Icon
      activeIcon: activePlayground,
      name: "Kids Play Area",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: table, // Existing Icon
      activeIcon: activeTable,
      name: "Restaurant",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: ConferenceCentre, // New Icon from Basecamp
      activeIcon: activeConferenceCentre, // New active icon
      name: "Conference Centre",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: stage, // Existing Icon
      activeIcon: activeStage,
      name: "Auditorium",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: SportsClub, // New Icon from Basecamp
      activeIcon: activeSportsClub, // New active icon
      name: "Sports Club",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: ExhibitionHall,
      activeIcon: activeExhibitionHall,
      name: "Exhibition Hall",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: Warehouse,
      activeIcon: activeWarehouse,
      name: "Warehouse",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: ArtStudio,
      activeIcon: activeArtStudio,
      name: "Art Studio",
      component: <DetailComponent data={provideData} />,
    },
    {
      icon: Desert,
      activeIcon: activeDesert,
      name: "Desert",
      component: <DetailComponent data={provideData} />,
    },
  ];

  const getVenues = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/landing/venueType/${data[activeIndex].name}`
      );
      const venues = await response.json();
      setVenuesData(venues.venues);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getVenues();
  }, [activeIndex]);

  useEffect(() => {
    if (venuesData) {
      let allVenues = [];
      venuesData.map((e, i) => {
        const { minSpendPerPerson, minSpendPricing, venueRental } =
          calculateMinSpendFromSelectedOptions(e);
        const newVenue = {
          id: e._id,
          images: e.imagesAndVideos.images,
          name: `${e.basicDetails.venueName}, ${e.basicDetails.hotelName}`,
          loc: `${e.location.area}, ${e.location.city}`,
          sitted: e.capacity.sitting,
          stand: e.capacity.standing,
          aed: 230,
          venu: 2,
          minSpendPerPerson,
          minSpendPricing,
          venueRental,
          setting:
            e?.additionalDetails?.venueSetting?.length === 1
              ? e?.additionalDetails?.venueSetting?.[0]
              : "Indoor + Outdoor",
          activity: e.venueType.venueRepresent,
        };
        allVenues.push(newVenue);
      });
      setData1(allVenues);
      if (allVenues.length > 0) {
        localStorage.setItem("lastSearchData", JSON.stringify(allVenues));
      }
    }
  }, [venuesData]);

  const calculateMinSpendFromSelectedOptions = (e) => {
    // Declare variables at the top to ensure they are accessible in all blocks
    let minSpendPricing = null;
    let venueRental = null;
    let minSpendPerPerson = null;

    if (e?.pricing?.selectedOptions?.length) {
      e.pricing.selectedOptions.forEach((option) => {
        const minSpendValue = Number(option.details.minSpend);
        let hasMinimumSpendPricing = false;
        switch (option.type) {
          case "Minimum Spend Pricing":
            hasMinimumSpendPricing = true; // Flag that "Minimum Spend Pricing" exists
            minSpendPricing =
              minSpendPricing === null
                ? minSpendValue
                : Math.min(minSpendPricing, minSpendValue);
            break;

          case "Venue Rental":
            // Only use Venue Rental if Minimum Spend Pricing isn't available
            venueRental =
              venueRental === null
                ? minSpendValue
                : Math.min(venueRental, minSpendValue);
            break;

          case "Min spend per person":
            minSpendPerPerson =
              minSpendPerPerson === null
                ? minSpendValue
                : Math.min(minSpendPerPerson, minSpendValue);
            break;

          default:
            break;
        }
      });

      return {
        minSpendPerPerson,
        minSpendPricing,
        venueRental,
      };
    } else {
      // If no selected options are present, return variables initialized above
      return {
        minSpendPerPerson,
        minSpendPricing,
        venueRental,
      };
    }
  };

  return (
    <div className="relative w-full">
      <Slider {...settings}>
        {data.map((e, i) => (
          <div key={i} className="px-2 w-full">
            <div
              className={`bg-[#F7F7F7] cursor-pointer sm:rounded-lg rounded-full  ${
                i === activeIndex
                  ? "sm:border-b-4 border-none sm:rounded-b-lg rounded-b-full border-[#FE4747]"
                  : "border-b-none"
              }  flex flex-col items-center justify-center py-2 px-4`}
              onClick={() => {
                setActiveIndex(i);
              }}
            >
              <span className="sm:block hidden">
                {/* {i === activeIndex ? e.activeIcon : e.icon} */}

                {i === activeIndex ? (
                  typeof e.activeIcon === "string" ? (
                    <img
                      className="w-10"
                      src={e.activeIcon}
                      alt="Active Icon"
                    />
                  ) : (
                    <span>{e.activeIcon}</span>
                  )
                ) : typeof e.icon === "string" ? (
                  <img className="w-10" src={e.icon} alt="Icon" />
                ) : (
                  <span>{e.icon}</span>
                )}
              </span>
              <h1
                className={`${
                  i === activeIndex ? " text-[#fe4747]" : "text-darkGray"
                } sm:text-base text-xs whitespace-nowrap sm:px-0 px-3 font-medium`}
              >
                {e.name}
              </h1>
            </div>
          </div>
        ))}
      </Slider>
      <div className="mt-4 flex">
        {/* Render the component corresponding to the active tab */}
        <VenueDetailComp data={data1} />
      </div>

      <div className="flex justify-center w-full my-10">
        <button
          className="bg-[#FE4747] text-white rounded-lg w-[120px] py-2"
          onClick={() => {
            setFilters({
              venueTypes: [data[activeIndex].name],
            });
            window.location.href = "/listing/1";
          }}
        >
          Explore More
        </button>
      </div>
    </div>
  );
};

export default HotelCarousel;
