import React, { useEffect, useState, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { toast } from "react-toastify";
import { base_URL } from "../../config/config";
import { useNavigate } from "react-router-dom";
import SearchableDropdown from "./SearchableDropdown";

const time = [
  { id: 1, time: "06:00", value: "06:00 AM" },
  { id: 2, time: "06:30", value: "06:30 AM" },
  { id: 3, time: "07:00", value: "07:00 AM" },
  { id: 4, time: "07:30", value: "07:30 AM" },
  { id: 5, time: "08:00", value: "08:00 AM" },
  { id: 6, time: "08:30", value: "08:30 AM" },
  { id: 7, time: "09:00", value: "09:00 AM" },
  { id: 8, time: "09:30", value: "09:30 AM" },
  { id: 9, time: "10:00", value: "10:00 AM" },
  { id: 10, time: "10:30", value: "10:30 AM" },
  { id: 11, time: "11:00", value: "11:00 AM" },
  { id: 12, time: "11:30", value: "11:30 AM" },
  { id: 13, time: "12:00", value: "12:00 PM" },
  { id: 14, time: "12:30", value: "12:30 PM" },
  { id: 15, time: "13:00", value: "01:00 PM" },
  { id: 16, time: "13:30", value: "01:30 PM" },
  { id: 17, time: "14:00", value: "02:00 PM" },
  { id: 18, time: "14:30", value: "02:30 PM" },
  { id: 19, time: "15:00", value: "03:00 PM" },
  { id: 20, time: "15:30", value: "03:30 PM" },
  { id: 21, time: "16:00", value: "04:00 PM" },
  { id: 22, time: "16:30", value: "04:30 PM" },
  { id: 23, time: "17:00", value: "05:00 PM" },
  { id: 24, time: "17:30", value: "05:30 PM" },
  { id: 25, time: "18:00", value: "06:00 PM" },
];

const venueTypes = [
  { label: "Annual General meeting", value: "Annual General meeting" },
  { label: "Auctions", value: "Auctions" },
  { label: "Board Meetings", value: "Board Meetings" },
  { label: "Breakout", value: "Breakout" },
  { label: "Conference", value: "Conference" },
  { label: "Conventions", value: "Conventions" },
  { label: "Executive Summits", value: "Executive Summits" },
  { label: "Exhibitions", value: "Exhibitions" },
  { label: "Meetings", value: "Meetings" },
  { label: "Networking", value: "Networking" },
  { label: "Presentation", value: "Presentation" },
  { label: "Press Conference", value: "Press Conference" },
  { label: "Product Launch", value: "Product Launch" },
  { label: "Seminars", value: "Seminars" },
  { label: "Symposium", value: "Symposium" },
  { label: "Trade Shows", value: "Trade Shows" },
  { label: "Training", value: "Training" },
  { label: "Workshops", value: "Workshops" },
  { label: "Activity Day", value: "Activity Day" },
  { label: "Award Ceremony", value: "Award Ceremony" },
  { label: "Away day", value: "Away day" },
  { label: "Brainstorm", value: "Brainstorm" },
  { label: "Charity event", value: "Charity event" },
  { label: "Corporate Gala dinner", value: "Corporate Gala dinner" },
  { label: "Corporate Reception", value: "Corporate Reception" },
  { label: "Corporate Retreat", value: "Corporate Retreat" },
  { label: "FAM Trip", value: "FAM Trip" },
  { label: "Fundraiser", value: "Fundraiser" },
  { label: "Incentive trip", value: "Incentive trip" },
  { label: "Office Party", value: "Office Party" },
  { label: "Outdoor Activity", value: "Outdoor Activity" },
  { label: "Pop Up event", value: "Pop Up event" },
  { label: "PR events", value: "PR events" },
  { label: "Promotional Events", value: "Promotional Events" },
  { label: "Staff Party", value: "Staff Party" },
  { label: "Summer Party", value: "Summer Party" },
  { label: "Team Building", value: "Team Building" },
  { label: "Training Program", value: "Training Program" },
  { label: "Arabic Wedding", value: "Arabic Wedding" },
  { label: "Beach Wedding", value: "Beach Wedding" },
  { label: "Christian Wedding", value: "Christian Wedding" },
  { label: "Church Wedding", value: "Church Wedding" },
  { label: "Civil Partnership", value: "Civil Partnership" },
  { label: "Civil Wedding", value: "Civil Wedding" },
  { label: "Garden Wedding", value: "Garden Wedding" },
  { label: "Hindu Wedding", value: "Hindu Wedding" },
  { label: "Indian Wedding", value: "Indian Wedding" },
  { label: "Marquee Wedding", value: "Marquee Wedding" },
  { label: "Outdoor Wedding", value: "Outdoor Wedding" },
  { label: "Unique Wedding", value: "Unique Wedding" },
  { label: "Wedding Ceremony", value: "Wedding Ceremony" },
  { label: "Wedding Reception", value: "Wedding Reception" },
  { label: "BBQ", value: "BBQ" },
  { label: "Anniversary Party", value: "Anniversary Party" },
  { label: "Cocktail Masterclass", value: "Cocktail Masterclass" },
  { label: "Cocktail Reception", value: "Cocktail Reception" },
  { label: "Communion", value: "Communion" },
  { label: "Condolences", value: "Condolences" },
  { label: "Drinks Reception", value: "Drinks Reception" },
  { label: "Engagement Reception", value: "Engagement Reception" },
  { label: "Farewell Party", value: "Farewell Party" },
  { label: "Funeral reception", value: "Funeral reception" },
  { label: "Graduation Party", value: "Graduation Party" },
  { label: "New Year's Eve Party", value: "New Year's Eve Party" },
  { label: "Party", value: "Party" },
  { label: "Private Party", value: "Private Party" },
  { label: "Retirement Party", value: "Retirement Party" },
  { label: "Reunions", value: "Reunions" },
  { label: "Stag Party", value: "Stag Party" },
  { label: "Wine Tasting", value: "Wine Tasting" },
  { label: "16th Birthday Party", value: "16th Birthday Party" },
  { label: "18th Birthday Party", value: "18th Birthday Party" },
  { label: "21st Birthday Party", value: "21st Birthday Party" },
  { label: "25th Birthday Party", value: "25th Birthday Party" },
  { label: "30th Birthday Party", value: "30th Birthday Party" },
  { label: "40th Birthday Party", value: "40th Birthday Party" },
  { label: "50th Birthday Party", value: "50th Birthday Party" },
  { label: "Birthday Party", value: "Birthday Party" },
  { label: "Afternoon Tea", value: "Afternoon Tea" },
  { label: "Baby Christening", value: "Baby Christening" },
  { label: "Baby Shower", value: "Baby Shower" },
  {
    label: "Bachelorette Party/Bachelor",
    value: "Bachelorette Party/Bachelor",
  },
  { label: "Bridal Shower", value: "Bridal Shower" },
  { label: "High Tea", value: "High Tea" },
  { label: "Children Birthday Party", value: "Children Birthday Party" },
  { label: "Kids Party", value: "Kids Party" },
  { label: "Teen Party", value: "Teen Party" },
  { label: "Gender Reveals", value: "Gender Reveals" },
  { label: "Proposals", value: "Proposals" },
  { label: "Brunches", value: "Brunches" },
  { label: "Christmas dinner", value: "Christmas dinner" },
  { label: "Christmas Party", value: "Christmas Party" },
  { label: "Diwali", value: "Diwali" },
  { label: "Eid", value: "Eid" },
  { label: "Halloween Party", value: "Halloween Party" },
  { label: "Holiday Party", value: "Holiday Party" },
  { label: "Iftar", value: "Iftar" },
  { label: "Ladies night", value: "Ladies night" },
  { label: "Ramadan", value: "Ramadan" },
  { label: "Suhoor", value: "Suhoor" },
  { label: "Xmas Party", value: "Xmas Party" },
  { label: "Filming/Video Recording", value: "Filming/Video Recording" },
  { label: "Photoshoot", value: "Photoshoot" },
  { label: "Podcast Recording", value: "Podcast Recording" },
  { label: "Book Launch", value: "Book Launch" },
  { label: "Film Festival", value: "Film Festival" },
  { label: "Music Festival", value: "Music Festival" },
  { label: "Art Exhibitions", value: "Art Exhibitions" },
  { label: "Pop Up Event", value: "Pop Up Event" },
  { label: "Concert", value: "Concert" },
  { label: "Dance Performance", value: "Dance Performance" },
  { label: "Fashion Show", value: "Fashion Show" },
  { label: "GIG", value: "GIG" },
  { label: "Live Performance", value: "Live Performance" },
  { label: "Performing Arts", value: "Performing Arts" },
  { label: "Screening", value: "Screening" },
  { label: "Stand-up Comedy", value: "Stand-up Comedy" },
  { label: "Theatre (Drama/Plays)", value: "Theatre (Drama/Plays)" },
];
const hotelTypes = [
  {
    label: "Company Retreats",
    value: "Company Retreats",
  },
  {
    label: "Corporate Off-sites",
    value: "Corporate Off-sites",
  },
  {
    label: "Executive Meetings",
    value: "Executive Meetings",
  },
  {
    label: "Family Reunions",
    value: "Family Reunions",
  },
  {
    label: "Destination Weddings",
    value: "Destination Weddings",
  },
  {
    label: "Group Gateways",
    value: "Group Gateways",
  },
  {
    label: "Wellness Retreats",
    value: "Wellness Retreats",
  },
  {
    label: "Educational Camps or Workshops",
    value: "Educational Camps",
  },
  {
    label: "Special Events or Celebrations",
    value: "Special Events",
  },
  {
    label: "Training Programs",
    value: "Training Programs",
  },
];

const timeTabs = [
  { id: 1, name: "Event From" },
  { id: 2, name: "Event To" },
];

const validation = (data, type, setError) => {
  let newErrors = {};
  if (data.budget === "") {
    newErrors.budget = "Please enter budget";
  }
  if (data.customerId === "") {
    toast.error("Please login to send enquiry");
    return false;
  }
  if (data.startDate === "") {
    newErrors.startDate = "Please select start date";
  }
  if (data.endDate === "") {
    newErrors.endDate = "Please select end date";
  }
  if (type === "venue" && data.startTime === "" && data.endTime === "") {
    newErrors.startTime = "Please select start time";
  } else {
    if (type === "venue" && data.startTime === "") {
      newErrors.startTime = "Please select start time";
    }
    if (type === "venue" && data.endTime === "") {
      newErrors.endTime = "Please select end time";
    }
  }

  if (data.guests === "") {
    newErrors.guests = "Please enter number of guests";
  }
  if (data.eventType === "") {
    newErrors.eventType = "Please select event type";
  }
  if (type === "hotel" && data.numberOfVenues === "") {
    newErrors.numberOfVenues = "Please enter number of venues";
  }
  if (
    newErrors.budget ||
    newErrors.startDate ||
    newErrors.endDate ||
    newErrors.startTime ||
    newErrors.endTime ||
    newErrors.guests ||
    newErrors.eventType ||
    newErrors.numberOfVenues
  ) {
    setError(newErrors);
    return false;
  }
  return true;
};

export default function SpecifyEvent({
  onClose,
  selectedEnquiries,
  enquiryType,
}) {
  const selectedEnquiriesLength = selectedEnquiries.length;
  let eventTypes = enquiryType === "venue" ? venueTypes : hotelTypes;
  eventTypes.sort((a, b) => a.label.localeCompare(b.label));
  const autoFillRef = useRef(null);
  const eventTypeRef = useRef(null);
  const navigate = useNavigate();
  const user = localStorage.getItem("authUser");
  const [startTime, setStartTime] = useState(""); // Default start time
  const [endTime, setEndTime] = useState("");
  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [showTime, setShowTime] = useState(false);
  const [activeTimeTab, setActiveTimeTab] = useState(1);
  const [showAutoFill, setShowAutoFill] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState("");
  const [flexibility, setFlexibility] = useState(false);
  const [rooms, setRooms] = useState("");
  const [budget, setBudget] = useState("");
  const [accomodation, setAccomodation] = useState(false);
  const [numberOfVenues, setNumberOfVenues] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [guests, setGuests] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customerId, setCustomerId] = useState(
    user ? JSON.parse(user)._id : ""
  );
  const [showEvents, setShowEvents] = useState(false);
  const [selectedVenueDetails, setSelectedVenueDetails] = useState([]);
  const [previousEnquiry, setPreviousEnquiry] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    rooms: "",
    budget: "",
    accommodation: "",
    numberOfVenues: "",
    guests: "",
    eventType: "",
    termsAndConditions: "",
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autoFillRef.current &&
        !autoFillRef.current.contains(event.target) &&
        showAutoFill
      ) {
        setShowAutoFill(false);
      }
      if (
        eventTypeRef.current &&
        !eventTypeRef.current.contains(event.target) &&
        showEvents
      ) {
        setShowEvents(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAutoFill, showEvents]);

  useEffect(() => {
    if (user) {
      const userData = JSON.parse(user);
      setCustomerId(userData._id);

      if (enquiryType === "venue" || enquiryType === "hotel") {
        // Get a copy of current selectedVenueDetails to prevent direct mutation
        const updatedVenueDetails = [...selectedVenueDetails];

        // Iterate over selectedEnquiries and add only unique venueIds
        selectedEnquiries
          .slice(0, selectedEnquiriesLength) // Limit to selectedEnquiriesLength
          .forEach((enquiry) => {
            const venueId = enquiry?._id;

            // Check if venueId already exists
            if (
              !updatedVenueDetails.some((detail) => detail.venueId === venueId)
            ) {
              updatedVenueDetails.push({
                venueName:
                  enquiryType === "venue"
                    ? `${enquiry?.basicDetails?.venueName}`
                    : enquiry?.overview?.basicDetails?.hotelName,
                hotelName:
                  enquiryType === "venue"
                    ? enquiry?.basicDetails?.hotelName
                    : enquiry?.overview?.basicDetails?.hotelName,
                venueImage:
                  enquiryType === "venue"
                    ? enquiry?.imagesAndVideos?.images[0]
                    : enquiry?.photoVideo?.images[0],
                vendorId: enquiry?.vendorId,
                venueId: venueId,
              });
            }
          });
        setSelectedVenueDetails(updatedVenueDetails); // Update the state with unique venueIds
      }
    }
  }, []);

  const handleStartDateChange = (e) => {
    // console.log("Start Date", e.target.value.replaceAll("-", ""));

    if (
      e.target.value.replaceAll("-", "") <
      new Date().toISOString().slice(0, 10).replaceAll("-", "")
    ) {
      // console.log(new Date().toISOString().slice(0, 10).replaceAll("-", ""));
      toast.error("Please select a valid date");
      return;
    }
    setStartDate(e.target.value);
  };
  const handleBudgetChange = (e) => {
    setBudget(e.target.value);
  };
  const handleNumberOfVenuesChange = (e) => {
    setNumberOfVenues(e.target.value);
  };

  const handleEndDateChange = (e) => {
    if (
      e.target.value.replaceAll("-", "") <
      new Date().toISOString().slice(0, 10).replaceAll("-", "")
    ) {
      toast.error("Please select a valid date");
      return;
    }
    if (startDate > e.target.value) {
      toast.error("End date should be greater than start date");
      return;
    }
    setEndDate(e.target.value);
    setEndDate(e.target.value);
  };

  const handleSelectEventType = (event) => {
    setShowEvents(false);
    setSelectedEventType(event.label);
  };

  const handleRoomsChange = (e) => {
    setRooms(e.target.value);
  };

  const handleGuestsChange = (e) => {
    setGuests(e.target.value);
  };

  const additionalNotesChange = (e) => {
    setAdditionalNotes(e.target.value);
  };

  const handleTermsAndConditions = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  const handleShowAutoFill = () => {
    if (showAutoFill) {
      setShowAutoFill(false);
    } else {
      setShowAutoFill(true);
    }
  };

  const handleShowTime = () => {
    if (showTime) {
      setShowTime(false);
    } else {
      setShowTime(true);
    }
  };

  const handleTimeChange = (value) => {
    // Helper function to convert AM/PM time to a 24-hour format in minutes
    const convertTo24HourFormat = (time) => {
      const [hours, minutes] = time.split(":");
      const isPM = time.includes("PM");
      let hourIn24 = parseInt(hours);

      if (isPM && hourIn24 !== 12) {
        hourIn24 += 12;
      }

      if (!isPM && hourIn24 === 12) {
        hourIn24 = 0;
      }

      return hourIn24 * 60 + parseInt(minutes);
    };

    const time24 = convertTo24HourFormat(value);
    if (activeTimeTab === 1) {
      setStartTimeValue(time24);
      setStartTime(value);
      setActiveTimeTab(2);
    } else {
      if (time24 <= startTimeValue) {
        alert("End time should be greater than start time");
        return;
      }
      setEndTimeValue(time24);
      setEndTime(value);
      setShowTime(false);
    }
  };

  const reset = () => {
    setStartTime("");
    setEndTime("");
    setStartDate("");
    setEndDate("");
    setBudget("");
    setNumberOfVenues("");
    setAccomodation(false);
    setFlexibility(false);
    setRooms("");
    setGuests("");
    setSelectedEventType("");
    setAdditionalNotes("");
    setTermsAndConditions(false);
  };

  const handleSubmitEnquiry = async () => {
    // console.log("Selected Venue Details", selectedVenueDetails);
    if (!termsAndConditions) {
      toast.error("Please agree terms & conditions");
      return;
    }
    setLoading(true);
    setIsSubmitting(true);
    setError({});
    if (
      !validation(
        {
          customerId,
          startDate,
          endDate,
          startTime,
          endTime,
          budget,
          accomodation,
          numberOfVenues,
          flexibility,
          rooms,
          guests,
          selectedEventType,
          additionalNotes,
          termsAndConditions,
        },
        enquiryType,
        setError
      )
    ) {
      console.log("Error in validation", error);
      reset();
      toast.error("Please fill all the fields");
      setIsSubmitting(false);
      setLoading(false);
      return;
    }
    if (!termsAndConditions) {
      toast.error("Please accept terms and conditions");
      setIsSubmitting(false);
      setLoading(false);
      return;
    }

    const enquiryData = selectedVenueDetails.map((venue) => ({
      customerId: customerId,
      vendorId: venue.vendorId,
      venueId: venue.venueId,
      venueName: venue.venueName,
      hotelName: venue.hotelName,
      venueImage: venue.venueImage,
      enquiryType,
      startDate,
      endDate,
      startTime,
      endTime,
      flexibility,
      rooms,
      budget,
      accomodation,
      numberOfVenues,
      guests,
      eventType: selectedEventType,
      additionalNotes,
      termsAndConditions,
      status: "Not Accepted",
      statusMessage: "Pending",
    }));

    try {
      const responses = await Promise.all(
        enquiryData.map((data) =>
          fetch(`${base_URL}/api/enquiry/createEnquiry`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
        )
      );
      // console.log("Responses", responses);

      const allSuccessful = responses.every((res) => res.ok);
      if (allSuccessful) {
        getEnquiries();
        toast.success("Enquiry submitted successfully");
        onClose();
        navigate("/my-favorites");
        setLoading(false);
        const emailPromises = responses.map(async (res, index) => {
          if (res.ok) {
            const responseJson = await res.json();
            const { subject, html, customerEmail } = responseJson;
            await sendEnquiryEmail(customerEmail, subject, html); // Call the email function
          }
        });

        await Promise.all(emailPromises);
      } else {
        toast.error("Error in submitting some enquiries");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in sending enquiries", error.message);
      reset();
      setLoading(false);
      toast.error("Error in sending enquiries");
    }
    setIsSubmitting(false);
    setLoading(false);
  };
  const sendEnquiryEmail = async (email, subject, html) => {
    try {
      const response = await fetch(`${base_URL}/api/enquiry/sendEnquiryEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, subject, html }),
      });
      console.log("Email response", response);
    } catch (error) {
      console.log("Error in sending email", error.message);
    }
  };

  const getEnquiries = async () => {
    try {
      const response = await fetch(`${base_URL}/api/enquiry/getEnquiry/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId: customerId }),
      });
      if (response.ok) {
        const data = await response.json();
        let enquiriesData = data.enquiries;
        enquiriesData.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setPreviousEnquiry(enquiriesData);
      }
    } catch (error) {
      console.log("Error in fetching enquiries", error.message);
    }
  };
  useEffect(() => {
    getEnquiries();
    // console.log("Previous Enquiry", previousEnquiry);
  }, []);

  const handleShowEvents = () => {
    setShowEvents(!showEvents);
  };

  const handleAutoFill = (enquiry) => {
    setStartDate(enquiry.startDate);
    setEndDate(enquiry.endDate);
    setStartTime(enquiry.startTime);
    setEndTime(enquiry.endTime);
    setRooms(enquiry.rooms);
    setNumberOfVenues(enquiry.numberOfVenues);
    setAccomodation(enquiry.accomodation);
    setGuests(enquiry.guests);
    setBudget(enquiry.budget);
    setSelectedEventType(enquiry.eventType);
    setAdditionalNotes(enquiry.additionalNotes);
    setTermsAndConditions(enquiry.termsAndConditions);
    setShowAutoFill(false);
  };

  return (
    <div className="border border-[#DDDDDD]  w-full rounded-md px-4 py-5 mb-4 z-[9999]">
      <div className="flex justify-between items-center relative">
        <h1 className="text-[20px] font-[500] ">Specify your Event</h1>
        <div>
          <p
            className="text-[#FE4747] underline relative text-[16px] font-[500] cursor-pointer"
            onClick={handleShowAutoFill}
          >
            Autofill
          </p>
        </div>
        {showAutoFill && (
          <div
            className="absolute top-full right-0 z-50 overflow-y-auto min-h-[100px] custom-scrollbar"
            ref={autoFillRef}
          >
            <ul
              className="bg-white shadow-xl text-[#717171] shadow-[#D5D5D5] w-[200px]  rounded-md px-2  py-5"
              onClick={() => setShowAutoFill(false)}
            >
              {previousEnquiry?.length > 0
                ? previousEnquiry?.map((enquiry) => (
                    <li
                      key={enquiry._id}
                      className="cursor-pointer hover:bg-[#fffefe] py-2 px-2"
                      onClick={() => handleAutoFill(enquiry)}
                    >
                      {enquiryType === "hotel"
                        ? enquiry.hotelName
                        : enquiry.venueName}
                      ({enquiry.eventType})
                    </li>
                  ))
                : "No previous enquiry"}
            </ul>
          </div>
        )}
      </div>

      <div className="flex justify-between items-start gap-4 mt-4">
        <div>
          <label className="md:text-[16px] text-[14px] text-[#222222] ">
            Event Start Date
          </label>
          <input
            type="date"
            onChange={handleStartDateChange}
            value={startDate}
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          />
          <p className="text-[#FE4747] text-sm">{error.startDate}</p>
        </div>
        <div>
          <label className="md:text-[16px] text-[14px] text-[#222222] ">
            Event End Date
          </label>
          <input
            type="date"
            onChange={handleEndDateChange}
            value={endDate}
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          />
          <p className="text-[#FE4747] text-sm">{error.endDate}</p>
        </div>
      </div>
      {enquiryType === "venue" && (
        <div className="flex justify-between items-center gap-4 mt-1">
          <div className="w-full">
            <div className="mt-2 text-sm relative">
              <label className="md:text-[16px] text-[14px] text-[#222222] ">
                Event Time
              </label>
              <div
                className="border border-[#DDDDDD] rounded-md py-2 px-3 flex justify-between items-center mt-1 "
                onClick={() => handleShowTime()}
              >
                <p className="md:text-[16px] text-[14px]">
                  {startTime} - {endTime}
                </p>

                <div className="flex justify-between items-center cursor-pointer">
                  {!showTime ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
              </div>
              <p className="text-[#FE4747] text-sm">{error.startTime}</p>
              {showTime && (
                <div className="absolute top-[65px] w-full   left-0 z-[99999]">
                  <div className="bg-white border border-[#DDDDDD] rounded-md w-full cursor-pointer px-1">
                    <div className="flex justify-center gap-5 items-center">
                      {timeTabs.map((tab) => (
                        <div
                          key={tab.id}
                          className={`p-2 cursor-pointer flex justify-center items-center w-[45%] ${
                            activeTimeTab === tab.id
                              ? "text-black border-b-2 border-[#27c2b0] font-semibold"
                              : "text-[#717171] border-b border-[#DDDDDD]"
                          }`}
                          onClick={() => setActiveTimeTab(tab.id)}
                        >
                          {tab.name}
                        </div>
                      ))}
                    </div>
                    <ul className="w-full m-auto overflow-auto  h-[130px]">
                      {time.map((t) => (
                        <li
                          key={t.id}
                          onClick={() => handleTimeChange(t.value)}
                          className="p-2 cursor-pointer hover:bg-[#fff5f5] hover:rounded-md py-2 px-1 mt-1"
                        >
                          {t.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="mt-2 flex justify-start items-center gap-2">
        <input
          type="checkbox"
          className="text-xl"
          onChange={() => setFlexibility(!flexibility)}
          checked={flexibility}
        />
        <p className="md:text-[16px] text-[14px] text-[#222222]">
          I am flexible with{" "}
          {enquiryType === "venue" ? "date and time" : "dates"}
        </p>
      </div>
      <div className="flex justify-center items-start gap-4 mt-3">
        <div className="w-full">
          <label className="md:text-[16px] text-[14px] text-[#222222] ">
            Number of Guests
          </label>
          <input
            type="number"
            onChange={handleGuestsChange}
            value={guests}
            placeholder="Enter number of guests"
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          />
          <p className="text-[#FE4747] text-sm">{error.guests}</p>
        </div>
        <div className="w-full">
          <label className="md:text-[16px] text-[14px] text-[#222222]">
            {enquiryType === "venue"
              ? "Total Budget"
              : "Budget Price per night"}
          </label>

          <div className="flex justify-start items-center gap-1 py-[10px] px-2 border border-[#DDDDDD] rounded-md mt-1">
            <p className="text-black text-sm">AED</p>
            <input
              type="number"
              onChange={handleBudgetChange}
              value={budget}
              placeholder="Enter Amount"
              className="w-full border-l border-[#DDDDDD] outline-none text-sm pl-1"
            />
          </div>
          <p className="text-[#FE4747] text-sm">{error.budget}</p>
        </div>
      </div>
      <SearchableDropdown
        options={eventTypes}
        value={selectedEventType}
        onChange={setSelectedEventType}
        error={error.eventType}
        selectedEventType={selectedEventType}
      />

      {enquiryType === "hotel" && (
        <>
          <div className="flex justify-center items-start gap-4 mt-3">
            <div className="w-full">
              <label className="md:text-[16px] text-[14px] text-[#222222] ">
                Number of Venues
              </label>
              <input
                type="number"
                onChange={handleNumberOfVenuesChange}
                value={numberOfVenues}
                placeholder="Enter Number of venues"
                className="w-full border border-[#DDDDDD] outline-none rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
              />
              <p className="text-[#FE4747] text-sm">{error.numberOfVenues}</p>
            </div>
          </div>
          <div className="mt-2 flex justify-start items-center gap-2">
            <input
              type="checkbox"
              className="text-xl"
              onChange={() => setAccomodation(!accomodation)}
              checked={accomodation}
            />
            <p className="md:text-[16px] text-[14px] text-[#222222]">
              I am also looking for accomodation
            </p>
          </div>
          {accomodation && (
            <div className="flex justify-center items-start gap-4 mt-3">
              <div className="w-full">
                <label className="md:text-[16px] text-[14px] text-[#222222] ">
                  Number of Rooms
                </label>
                <input
                  type="number"
                  onChange={handleRoomsChange}
                  value={rooms}
                  placeholder="Enter Number of rooms"
                  className="w-full border border-[#DDDDDD] outline-none rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
                />
                {/* <p className="text-[#FE4747] text-sm">{error.rooms}</p> */}
              </div>
            </div>
          )}
        </>
      )}

      <div className="mt-3">
        <label className="md:text-[16px] text-[14px] text-[#222222] ">
          Additional Notes
        </label>
        <textarea
          className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          placeholder="Enter your input"
          onChange={additionalNotesChange}
          value={additionalNotes}
          rows="4"
        ></textarea>
      </div>
      <div className="mt-2 flex justify-start items-center gap-2">
        <input
          type="checkbox"
          className="text-xl text-[#DDDDDD]"
          onChange={handleTermsAndConditions}
          checked={termsAndConditions}
        />
        <p className="md:text-[16px] text-[14px] text-black">
          I agree with all the{" "}
          <a className="underline cursor-pointer" href="/terms&conditions">
            terms & conditions
          </a>
        </p>
      </div>
      <p className="text-[#FE4747] text-sm">{error.termsAndConditions}</p>
      <div className="mt-2 flex justify-start items-center gap-2">
        <button
          className="bg-[#FE4747] text-white px-4 py-2 text-[16px] w-full rounded-md"
          onClick={handleSubmitEnquiry}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Send Enquiry"}
        </button>
      </div>
    </div>
  );
}
