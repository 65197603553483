import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openExplore: false,
  openMobileExplore: false,
  hoveredOption: null,
  activeSubOption: null,
  openNav: false,
  openPopup: false,
  selectedSubOptions: [],
  selectedName: "",
  showSignUp: false,
  showReset: false,
  showLogin: false,
  showSocial: false,
  resetMailSend: false,
  resetWorkMail: false,
  showAdditional: false,
  updatePassword: false,
  showCheckInbox: false,
  showLogoutModal: false,
};

const modalSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    toggleExplore: (state) => {
      state.openExplore = !state.openExplore;
      if (!state.openExplore) {
        state.activeSubOption = null;
      }
    },
    toggleMobileExplore: (state) => {
      state.openMobileExplore = !state.openMobileExplore;
    },
    setHoveredOption: (state, action) => {
      state.hoveredOption = action.payload;
    },
    setActiveSubOption: (state, action) => {
      state.activeSubOption = action.payload;
    },
    toggleNav: (state) => {
      state.openNav = !state.openNav;
    },
    togglePopup: (state, action) => {
      state.openPopup = action.payload.isOpen;
      state.selectedSubOptions = action.payload.subOptions || [];
      state.selectedName = action.payload.name || "";
    },
    toggleSignUp: (state) => {
      state.showSignUp = true;
      state.showLogin = false;
      state.showSocial = false;
      state.showAdditional = false;
      state.showCheckInbox = false;
    },
    toggleResetWorkMail: (state) => {
      state.showSignUp = false;
      state.showLogin = false;
      state.resetWorkMail = true;
      state.showSocial = false;
      state.showAdditional = false;
      state.resetMailSend = false;
      state.showCheckInbox = false;
    },
    toggleUpdatePassword: (state) => {
      state.showSignUp = false;
      state.showLogin = false;
      state.resetWorkMail = false;
      state.updatePassword = true;
      state.showSocial = false;
      state.showAdditional = false;
      state.resetMailSend = false;
      state.showCheckInbox = false;
    },
    toggleLogin: (state) => {
      state.showSignUp = false;
      state.showLogin = true;
      state.showSocial = false;
      state.showAdditional = false;
      state.showCheckInbox = false;
    },
    toggleReset: (state) => {
      state.showSignUp = false;
      state.showReset = true;
      state.resetWorkMail = false;
      state.resetMailSend = false;
      state.showLogin = false;
      state.showSocial = false;
      state.showAdditional = false;
      state.showCheckInbox = false;
    },
    toggleResetMail: (state) => {
      state.resetWorkMail = false;
      state.showSignUp = false;
      state.showReset = false;
      state.resetMailSend = true;
      state.showLogin = false;
      state.showSocial = false;
      state.showAdditional = false;
      state.showCheckInbox = false;
    },
    toggleSocial: (state) => {
      state.showSignUp = false;
      state.showLogin = false;
      state.showSocial = true;
      state.showAdditional = false;
      state.showCheckInbox = false;
    },
    toggleAdditional: (state) => {
      state.showSignUp = false;
      state.showLogin = false;
      state.showSocial = false;
      state.showAdditional = true;
      state.showCheckInbox = false;
    },
    toggleCheckInbox: (state) => {
      state.showSignUp = false;
      state.showLogin = false;
      state.showSocial = false;
      state.showAdditional = false;
      state.showCheckInbox = true;
    },
    toggleLogoutModal: (state) => {
      state.showLogoutModal = !state.showLogoutModal;
    },
    closeModals: (state) => {
      state.showSignUp = false;
      state.showLogin = false;
      state.showSocial = false;
      state.showAdditional = false;
      state.showCheckInbox = false;
      state.resetWorkMail = false;
      state.showReset = false;
      state.resetMailSend = false;
      state.updatePassword = false;
    },
  },
});

export const {
  toggleExplore,
  toggleResetWorkMail,
  toggleMobileExplore,
  setHoveredOption,
  setActiveSubOption,
  toggleNav,
  toggleResetMail,
  togglePopup,
  toggleSignUp,
  toggleLogin,
  toggleSocial,
  toggleAdditional,
  toggleCheckInbox,
  toggleUpdatePassword,
  toggleReset,
  closeModals,
  toggleLogoutModal,
} = modalSlice.actions;

export default modalSlice.reducer;
