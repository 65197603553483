import React, { useEffect, useState } from "react";
import { BsSend } from "react-icons/bs";
import { IoIosAttach } from "react-icons/io";
import Vendorchat from "../VendorChat/VendorChat";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import MobileEnquiryDetails from "../MobileEnquiryDetails/MobileEnquiryDetails";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { base_URL } from "../../../config/config";

const EnquiryDetails = ({
  selectedEnquiryDetails,
  setSelectedEnquiryDetails,
  vednordetails,
  user,
}) => {
  const vendorId = localStorage.getItem("vendorId");
  const queryClient = useQueryClient();
  // Add a new query subscription
  const { data: activeEnquiry } = useQuery({ queryKey: ["activeEnquiry"] });
  // Status Update
  const updateStatusMutation = useMutation({
    mutationFn: async ({ id, newStatus, statusMessage, vendorEmail }) => {
      const response = await axios.patch(
        `${base_URL}/api/vendor-enquiries/${id}/status`,
        { status: newStatus, statusMessage, vendorEmail }
      );
      console.log(response, "kkk");
      return response.data;
    },

    onMutate: async ({ enquiryId, newStatus }) => {
      // Cancel any outgoing refetches (Full System)
      await queryClient.cancelQueries({
        queryKey: ["vendorEnquiries", vendorId],
      });

      // Snapshot the previous value
      const previousEnquiries = queryClient.getQueryData([
        "vendorEnquiries",
        vendorId,
      ]);

      // Optimistically update to new status
      queryClient.setQueryData(["vendorEnquiries", vendorId], (old) =>
        old?.map((enquiry) =>
          enquiry?._id === enquiryId
            ? { ...enquiry, status: newStatus }
            : enquiry
        )
      );
      // Return a context object with the snapshotted value
      return { previousEnquiries };
    },
    // If mutation fails, roll back to previous state
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ["vendorEnquiries", vendorId],
        context.previousEnquiries
      );
    },
    // Always refetch after error or success
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["vendorEnquiries", vendorId],
      });
    },
  });

  // Function to update status
  const updateStatus = (id, newStatus, statusMessage) => {
    updateStatusMutation.mutate({
      id,
      newStatus,
      statusMessage,
      vendorEmail: user?.email,
    });
  };
  // Watch for changes in activeEnquiry

  useEffect(() => {
    if (activeEnquiry) {
      setSelectedEnquiryDetails((prevDetails) => ({
        ...prevDetails,
        status: activeEnquiry.status,
      }));

      updateStatusMutation.mutate({
        id: activeEnquiry.id,
        newStatus: activeEnquiry.status,
        statusMessage: activeEnquiry.message,
        vendorEmail: user?.email,
      });
    }
  }, [activeEnquiry]);
  return (
    <div className="w-full h-full ">
      <div className="md:max-w-6xl mx-auto ">
        <div className="  ">
          {/* Main Content */}
          <div className="flex flex-col lg:flex-row gap-y-14 lg:gap-x-6">
            {/* Left Side - Enquiry Details for web */}
            <div className="hidden lg:inline w-[30%] rounded-xl p-6 bg-white">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-[20px] font-medium text-[#212B36]">
                  Enquiry Details
                </h3>
                <StatusDropdown
                  enquiry={selectedEnquiryDetails}
                  updateStatus={updateStatus}
                  user={user}
                />
              </div>

              {/* Details Grid */}
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="text-[#717171] text-[14px] font-normal">
                    Event Start Date
                  </span>
                  <span className="font-medium text-[14px] text-[#222222]">
                    {selectedEnquiryDetails?.startDate}
                  </span>
                </div>
                <div className="flex justify-between ">
                  <span className="text-[#717171] text-[14px] font-normal">
                    Event End Date
                  </span>
                  <span className="font-medium text-[14px] text-[#222222]">
                    {selectedEnquiryDetails?.endDate}
                  </span>
                </div>
                <div className="flex justify-between ">
                  <span className="text-[#717171] text-[14px] font-normal">
                    Flexible
                  </span>
                  <span>
                    {selectedEnquiryDetails?.flexibility === true
                      ? "Yes"
                      : "No"}
                  </span>
                </div>
                <div className="flex justify-between ">
                  <span className="text-[#717171] text-[14px] font-normal">
                    Event Type
                  </span>
                  <span>{selectedEnquiryDetails?.eventType}</span>
                </div>
                {selectedEnquiryDetails?.budget && (
                  <div className="flex justify-between ">
                    <span className="text-[#717171] text-[14px] font-normal">
                      Event Budget
                    </span>
                    <span>AED {selectedEnquiryDetails?.budget || "--"}</span>
                  </div>
                )}

                {selectedEnquiryDetails?.guests && (
                  <div className="flex justify-between ">
                    <span className="text-[#717171] text-[14px] font-normal">
                      Number of Guest
                    </span>
                    <span>{selectedEnquiryDetails?.guests}</span>
                  </div>
                )}

                {selectedEnquiryDetails?.rooms && (
                  <div className="flex justify-between ">
                    <span className="text-[#717171] text-[14px] font-normal">
                      Number of Rooms
                    </span>
                    <span>{selectedEnquiryDetails?.rooms}</span>
                  </div>
                )}
                {selectedEnquiryDetails?.numberOfVenues && (
                  <div className="flex justify-between ">
                    <span className="text-[#717171] text-[14px] font-normal">
                      Number of Venue Required
                    </span>
                    <span>{selectedEnquiryDetails?.numberOfVenues}</span>
                  </div>
                )}

                {/* Customer Info Section */}
                <div className="mt-8">
                  <h4 className="text-[#212B36] text-[20px] font-medium mb-4">
                    Customer Info
                  </h4>
                  <div className="space-y-3">
                    <div className="flex items-center gap-2">
                      <img src="/person.svg" alt="" />
                      <span className="text-[16px] font-normal">
                        {selectedEnquiryDetails?.customerId?.fullName}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src="/mail.svg" alt="" />
                      <span className="text-[16px] font-normal">
                        {selectedEnquiryDetails?.customerId?.email}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src="/call.svg" alt="" />
                      <span className="text-[16px] font-normal">
                        {selectedEnquiryDetails?.customerId?.mobileNumber}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src="/business_center.svg" alt="" />
                      <span className="text-[16px] font-normal">
                        {selectedEnquiryDetails?.customerId?.select}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Manager Info */}
                <div className="mt-12">
                  <h4 className="text-[#212B36] text-[20px] font-medium mb-4">
                    Manager Info
                  </h4>
                  <div className="space-y-3">
                    <div className="flex items-center gap-2">
                      <img src="/person.svg" alt="" />
                      <span className="text-[16px] font-normal">
                        {vednordetails?.email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Side - Chat .*/}
            <Vendorchat
              vednordetails={vednordetails}
              enquiryData={selectedEnquiryDetails}
              updateStatus={updateStatus}
              user={user}
            />
            {/* for mobile */}
            <MobileEnquiryDetails
              vednordetails={vednordetails}
              enquiryData={selectedEnquiryDetails}
              updateStatus={updateStatus}
              user={user}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquiryDetails;
