import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import CustomCheckBox from "./CustomCheckbox";

const EventListingSelector = ({
  events, // Pass the nested event types object
  label,
  selectedOptions,
  setSelectedOptions,
  handleOpenPopup,
  openPopup,
}) => {
  const [openSubCategories, setOpenSubCategories] = useState({}); // Store collapsible state for each event type

  // Handle opening/closing each sub-category
  const handleSubCategoryToggle = (category) => {
    setOpenSubCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Handle checkbox changes (add/remove)
  const handleOptionChange = (e, category) => {
    const { name, checked } = e.target;
    const updatedOptions = { ...selectedOptions };

    if (checked) {
      // Add the selected option under the correct category
      if (!updatedOptions[category]) {
        updatedOptions[category] = [];
      }
      updatedOptions[category] = [...updatedOptions[category], name];
    } else {
      // Remove the unselected option from the category
      updatedOptions[category] = updatedOptions[category].filter(
        (option) => option !== name
      );
      if (updatedOptions[category].length === 0) {
        delete updatedOptions[category]; // Remove the category if no options are selected
      }
    }
    setSelectedOptions(updatedOptions);
  };

  return (
    <div className="w-full pt-6 mt-4 border-t border-grayNeutral">
      <div className="w-full flex cursor-pointer items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <div className="text-[#222222] text-[16px] font-[500]">{label}</div>
        </div>
        <IoIosArrowDown
          onClick={handleOpenPopup}
          className={`flex-shrink-0 text-xl rotate-0 ${
            openPopup && "rotate-180"
          } transition-all duration-150`}
        />
      </div>

      {openPopup && (
        <div className="border-2 border-[#EBEBEB] custom-scrollbar rounded-lg p-2 w-full overflow-auto h-max max-h-56 flex flex-col mt-2 gap-2 outline-none">
          {/* Map over the event categories */}
          {Object.keys(events).map((category, index) => (
            <div key={index}>
              {/* Parent Category Toggle */}
              <div
                onClick={() => handleSubCategoryToggle(category)}
                className="flex items-center justify-between cursor-pointer mb-2"
              >
                <div className="font-[400] text-[16px] text-[#222222]">
                  {category}
                </div>
                <IoIosArrowDown
                  className={`flex-shrink-0 text-lg ${
                    openSubCategories[category] ? "rotate-180" : ""
                  } transition-all duration-150`}
                />
              </div>

              {/* Child Options - Only visible if the category is expanded */}
              {openSubCategories[category] && (
                <div className="pl-4">
                  {events[category].map((event, i) => (
                    <div key={i} className="flex items-center gap-3 mb-2">
                      <CustomCheckBox
                        label={event.name}
                        isChecked={selectedOptions[category]?.includes(
                          event.name
                        )}
                        onChange={(e) => handleOptionChange(e, category)}
                        name={event.name}
                        value={event.value}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventListingSelector;
