import React, { useEffect, useState, useRef } from "react";
import Input from "../shared/common/customInput";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createTeamMember } from "../../api/teamMember.request";
import AssignVenues from "./assign-venues";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import toast from "react-hot-toast";
import { base_URL } from "../../config/config";
import { fetchLoggedUser, fetchUserById } from "../../api/loggedUser";
import { Radio, styled } from "@mui/material";
import { getOnboarding } from "../../api/signup.request";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
// Custom radio button unchecked icon
const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 24, // Custom size for your Radio
  height: 24,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    background: "rgba(206,217,224,.5)",
  },
}));

// Custom radio button checked icon
const CustomCheckedIcon = styled(CustomIcon)({
  backgroundColor: "#0B8476", // Custom green color for checked state
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#0B8476",
  },
});

// Custom Radio component with styled icons
function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomCheckedIcon />}
      icon={<CustomIcon />}
      {...props}
    />
  );
}

const AddUserModal = ({
  showAddModal,
  setSnackbar,
  setUserData,
  setShowEditModal,
}) => {
  const modalRef = useRef();
  const [superAdmin, setSuperAdmin] = useState(null);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(true);
  const [assignVenue, setAssignVenue] = useState(false);
  const [assignedVenues, setAssignedVenues] = useState([]);
  const [assignedRestaurant, setAssignedRestaurant] = useState([]);
  const [assignedHotel, setAssignedHotel] = useState(null);
  const [emailError, setEmailError] = useState(""); // Add state for email error
  const [roleAccess, setRoleAccess] = useState("admin");
  const [mobileno, setMobileno] = useState("");
  const [country, setCountry] = useState("AE"); // Default country
  const [countryCode, setCountryCode] = useState("+971"); // Default UAE country code

  // for subadmin use invitedby id as vendor id
  // for superadmin user vendorId directly
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: async (data) => {
      const result = await createTeamMember(data);
      if (!result.success) {
        throw new Error(result.message); // This will trigger the onError callback
      }
      setUserData(result.user);
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers"]);
      onClose();
      setSnackbar({
        open: true,
        message: "User added successfully!",
        severity: "success",
      });
    },
    onError: (error) => {
      // Error handling for the toast notification
      setSnackbar({
        open: true,
        message: error.message || "User not added!",
        severity: "error",
      });
    },
  });

  const onClose = () => {
    showAddModal(false);
  };
  const handlePhoneChange = (value) => {
    setMobileno(value || "");
  };

  const handleCountryChange = (countryCode) => {
    if (countryCode) {
      setCountry(countryCode);

      try {
        // Directly get the calling code for the selected country
        const dialCode = getCountryCallingCode(countryCode);
        setCountryCode(`+${dialCode}`);

        // If no number is entered, set a placeholder with the new country code
        if (!mobileno) {
          setMobileno(`+${dialCode}`);
        }
      } catch (error) {
        console.error("Error getting country calling code:", error);
        setCountryCode("+");
      }
    }
  };
  // getting restaurants of vendor
  const {
    data: boardingData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => await getOnboarding(vendorId),
    refetchOnWindowFocus: false,
  });

  const isValidEmail = (email) => {
    const freeEmailDomains = [
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "gmail.com",
    ];

    // Split the email into local part and domain
    const emailParts = email.split("@");
    if (emailParts.length !== 2) return false;

    const domain = emailParts[1];

    // Check that the domain isn't from a free email provider and that it follows basic email structure rules
    return (
      !freeEmailDomains.includes(domain.toLowerCase()) &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    );
  };
  // Function to get venue names from IDs
  const getAssignedVenueNames = (venueIds, boardingData) => {
    const venueNames = [];
    (boardingData?.venueForms || []).forEach((venue) => {
      if (venueIds.includes(venue._id)) {
        venueNames.push(venue?.basicDetails?.venueName);
      }
    });
    return venueNames;
  };

  // Function to get restaurant names from IDs
  const getAssignedRestaurantNames = (restaurantIds, boardingData) => {
    const restaurantNames = [];
    (boardingData?.restaurantForms || []).forEach((restaurant) => {
      if (restaurantIds.includes(restaurant._id)) {
        restaurantNames.push(restaurant?.basicDetails?.venueName);
      }
    });
    return restaurantNames;
  };

  // Function to get hotel names from IDs
  const getAssignedHotelNames = (hotelIds, boardingData) => {
    const hotelNames = [];
    (boardingData?.hotels || []).forEach((hotel) => {
      if (hotelIds.includes(hotel._id)) {
        hotelNames.push(hotel?.overview?.basicDetails?.hotelName);
      }
    });
    return hotelNames;
  };
  // creating user
  const handleSubmit = async () => {
    const assignedVenueNames = getAssignedVenueNames(
      assignedVenues,
      boardingData
    );
    const assignedRestaurantNames = getAssignedRestaurantNames(
      assignedRestaurant,
      boardingData
    );
    const assignedHotelNames = getAssignedHotelNames(
      assignedHotel ? [assignedHotel] : [],
      boardingData
    );
    // console.log("Assigned Venue Names:", assignedVenueNames);
    // console.log("Assigned Restaurant Names:", assignedRestaurantNames);
    // console.log("Assigned Hotel Names:", assignedHotelNames);
    // Merge all names into a single array
    const allAssignedNames = [
      ...assignedVenueNames,
      ...assignedRestaurantNames,
      ...assignedHotelNames,
    ];
    setEmailError(""); // Clear error if email is valid
    if (!fullname) {
      toast.error("Please provide your Full name");
      return;
    }
    if (!mobileno || mobileno?.length <= 7) {
      toast.error("Please enter valid phone number");
      return;
    }
    try {
      await createMutation.mutateAsync({
        name: fullname,
        email: email.toLowerCase(),
        role,
        phone: mobileno,
        isAdmin,
        assignedVenues,
        RestaurantForm: assignedRestaurant,
        Hotel: assignedHotel ? [assignedHotel] : [],
        vendor: vendorId,
        superAdmin,
      });

      // Trigger the middleware that decides which email to send
      await fetch(`${base_URL}/auth/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          vendorId,
          assignedVenues,
          allAssignedNames,
        }),
      });
    } catch (error) {
      // Handle any unexpected errors here
      console.error("An unexpected error occurred:", error);
    }
  };

  const onClick = () => {
    if (!email) {
      toast.error("Please enter email address");
      return;
    }

    if (!isValidEmail(email)) {
      toast.error("Invalid email address. Please enter a valid work email.");
      return;
    }

    if (isAdmin) {
      // If admin, directly submit
      handleSubmit();
    } else {
      // If not admin, check if venues need to be assigned
      if (assignVenue) {
        handleSubmit();
      } else {
        setAssignVenue(true);
      }
    }
  };
  // invited by is super admin basically
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let response;
        if (invitedBy) {
          // Fetch the super admin based on invitedBy ID
          response = await fetchUserById(vendorId);
        } else {
          // Fetch the logged-in vendor as the super admin
          response = await fetchLoggedUser();
        }
        setSuperAdmin(response.vendor || response.user);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  // invited by is super admin basically
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let response;
        if (invitedBy) {
          // Fetch the super admin based on invitedBy ID
          response = await fetchUserById(vendorId);
        } else {
          // Fetch the logged-in vendor as the super admin
          response = await fetchLoggedUser();
        }
        setSuperAdmin(response.vendor || response.user);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed z-[999] inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        className="bg-white md:p-6 p-5 rounded-lg shadow-lg md:w-1/2 w-[95%] flex flex-wrap justify-between"
        ref={modalRef}
      >
        <div className="w-full flex justify-end">
          <p className="cursor-pointer" onClick={() => onClose()}>
            X
          </p>
        </div>
        <h2 className="text-2xl w-full font-semibold mb-4 text-center">
          Add User
        </h2>
        {assignVenue ? (
          <AssignVenues
            assignedVenues={assignedVenues}
            setAssignedVenues={setAssignedVenues}
            assignedRestaurants={assignedRestaurant}
            setAssignedRestaurants={setAssignedRestaurant}
            assignedHotel={assignedHotel}
            setAssignedHotel={setAssignedHotel}
          />
        ) : (
          <>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Full name"
                  type="text"
                  placeholder="Enter full name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Work Email"
                  type="text"
                  placeholder="user@workapp.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                  <p className="text-red-500 text-sm">{emailError}</p>
                )}
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <div className="absolute left-[60px] top-1/2 transform   z-10 pointer-events-none">
                  {countryCode}
                </div>
                <label htmlFor="" className="mb-3 text-[#5E5E5E] text-[14px] ">
                  Mobile No.
                </label>
                <PhoneInput
                  id="mobile-input"
                  placeholder="Enter your contact no."
                  value={mobileno}
                  defaultCountry="AE"
                  withCountryCallingCode={true}
                  autoComplete="on"
                  onCountryChange={handleCountryChange}
                  onChange={handlePhoneChange}
                  style={{
                    border: "1px solid #d1d5db",
                    boxShadow: "none",
                    borderRadius: "10px",
                    outline: "none",
                    width: "100%",
                    padding: "0.5rem",
                    // marginTop: "0.3rem",
                  }}
                  className="no-focus-border  "
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <div className="mb-3">
                  <label className="block text-start text-secondary text-sm font-outfitt mb-2">
                    Role in the company
                  </label>
                  <input
                    type="text"
                    placeholder="Enter role"
                    value={role}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Only allow alphabets (uppercase and lowercase)
                      if (/^[A-Za-z\s]*$/.test(inputValue)) {
                        setRole(inputValue);
                      }
                    }}
                    className={
                      "appearance-none border rounded-lg w-full py-3 px-3 text-sm text-secondary leading-tight focus:outline-none focus:shadow-outline"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex gap-2 items-center">
                <CustomRadio
                  id="admin"
                  name="role"
                  value="admin"
                  checked={isAdmin} // Admin radio should be checked when isAdmin is true
                  onChange={() => setIsAdmin(true)}
                />
                <label htmlFor="admin">Grant Full Admin Access</label>
                <IoIosInformationCircleOutline
                  color="#B0B0B0"
                  style={{ fontSize: "24px" }}
                  data-tooltip-id="admin-tooltip"
                  data-tooltip-content="Grant user administrator rights."
                />
                <ReactTooltip
                  id="admin-tooltip"
                  place="right"
                  style={{
                    maxWidth: "250px",
                    whiteSpace: "normal",
                    textAlign: "left",
                  }}
                />
              </div>
              <div className="flex gap-2 items-center">
                <CustomRadio
                  id="nonadmin"
                  name="role"
                  value="nonadmin"
                  checked={!isAdmin} // Venue radio should be checked when isAdmin is false
                  onChange={() => setIsAdmin(false)}
                />

                <label htmlFor="venue">Grant Access to Venue & Enquiries</label>
                <IoIosInformationCircleOutline
                  color="#B0B0B0"
                  style={{ fontSize: "24px" }}
                  data-tooltip-id="venue-tooltip"
                  data-tooltip-content="Grant access to specific venues and enquiries."
                />
                <ReactTooltip
                  id="venue-tooltip"
                  place="right"
                  style={{
                    maxWidth: "250px",
                    whiteSpace: "normal",
                    textAlign: "left",
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-center w-full gap-3 md:mt-3 mt-4">
          <button
            className="bg-primary w-[50%] text-white px-4 py-2 rounded-md"
            onClick={onClick}
          >
            {assignVenue
              ? "Submit"
              : createMutation.isLoading
              ? "Submitting..."
              : "Continue"}
          </button>
          <p
            className="text-primary font-semibold cursor-pointer"
            onClick={() => onClose()}
          >
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
