/* eslint-disable */
import React, { useState, useEffect } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import map from "../../../assets2/images/map.png";
import NavbarCustomer from "../../../components/NavbarCustomer";
import VenueFilter from "../../../components/listing/filters/VenueFilter";
import VenueTab from "../../../components/listing/VenueTab";
import VenuStayTab from "../../../components/listing/VenuStayTab";
import VenueStayFilter from "../../../components/listing/filters/VenuStayFilter";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { base_URL } from "../../../config/config";
import { useParams } from "react-router-dom";

const Listingvenuesonly = ({ filters, setFilters, showNav = true }) => {
  const { tab } = useParams();
  const location = useLocation();
  const [totalHotels, setTotalHotels] = useState(0);
  const [activeTab, setActiveTab] = useState(tab || "1");
  const [isShowMap, setIsShowMap] = useState(false);
  const [count, setCount] = useState({
    venue: "0",
    venueStay: "0",
  });
  const [searchParams, setSearchParams] = useState({
    eventVenue: "",
    numberOfGuests: "",
    city: "",
  });

  const getHotelCounts = async () => {
    try {
      const response = await fetch(`${base_URL}/api/hotels/get`);
      const data = await response.json();
      const counts = data.total;
      return counts;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    getHotelCounts().then((counts) => {
      setTotalHotels(counts);
    });
  }, []);

  useEffect(() => {
    setCount((prev) => ({
      ...prev,
      venueStay: totalHotels,
    }));
  }, [totalHotels]);

  useEffect(() => {
    if (location.state) {
      setSearchParams(location.state);
      // console.log("Received Search Params:", location.state); // Log the passed state
    }
  }, [location.state]);

  const tabs = [
    {
      name: "Venue",
      id: "1",
      component: (
        <VenueTab
          filters={filters}
          setCount={setCount}
          count={count}
          isShowMap={isShowMap}
          setIsShowMap={setIsShowMap}
          city={searchParams.city}
        />
      ),
    },
  ];

  return (
    <>
      {showNav && <NavbarCustomer filters={filters} setFilters={setFilters} />}

      <div className="bg-white sm:px-8 px-4 py-5">
        {showNav && (
          <div className="text-[#717171] flex items-center my-4 gap-2">
            <Link to="/">Home</Link>
            <RiArrowRightSLine />
            <p className="text-dark">Search Results</p>
          </div>
        )}

        <div className="flex items-start md:flex-row flex-col  ">
          <div className="lg:w-[25%] md:w-[35%] w-full md:border-r md:border-b-0 border-b flex flex-col  border-[#717171] md:pr-6 md:pb-0 pb-6 border-b-[px] md:border-[px]">
            {/* map */}
            {!isShowMap && (
              <div className="flex justify-center items-center relative w-full">
                <img src={map} className="w-full h-full rounded-lg" alt="" />
                <button
                  onClick={() => setIsShowMap(true)}
                  className="bg-primary absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white sm:px-6 px-3 py-3 rounded-lg"
                >
                  Show on Map
                </button>
              </div>
            )}

            {activeTab === "1" ? (
              <VenueFilter filters={filters} setFilters={setFilters} />
            ) : (
              <VenueStayFilter filters={filters} setFilters={setFilters} />
            )}
          </div>

          {/* carosuels and cards */}
          <div className="flex-1 md:border-l md:border-t-0 border-t-[px] border-[#717171] md:pl-6 md:pt-0 pt-6 md:border-[px] w-full">
            <div className="flex gap-7 xl:w-[70%] w-full">
              {tabs?.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  disabled={tab.id === "3"}
                  className={`w-fit px-6  py-2  ${
                    activeTab === tab.id
                      ? "border-b-[3px] border-b-[#27C2B0] text-black font-semibold "
                      : "border-b border-b-[#717171] text-[#717171]"
                  }`}
                >
                  {tab.name}{" "}
                  {tab.name === "Venue" && count.venue ? (
                    <span className="text-[#FE4747]">({count.venue})</span>
                  ) : tab.name === "Venue & Stay" && count.venueStay ? (
                    <span className="text-[#FE4747]">({count.venueStay})</span>
                  ) : null}
                </button>
              ))}
            </div>
            <div className="mt-4  w-full h-full bg-white">
              {tabs?.find((tab) => tab.id === activeTab)?.component}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listingvenuesonly;
