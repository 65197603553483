import React from "react";

const RatingStars = ({ rating }) => {
  const maxStars = 5; // Total number of stars
  const filledStars = Math.round(rating); // Round to the nearest whole number

  return (
    <div className="flex">
      {[...Array(maxStars)].map((_, index) => (
        <svg
          key={index}
          className={`w-5 h-5 ${
            index < filledStars ? "text-yellow-500" : "text-gray-300"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M12 .587l3.668 7.425 8.2 1.188-5.923 5.74 1.4 8.164L12 18.895l-7.345 3.883 1.4-8.164L.832 9.2l8.2-1.188L12 .587z" />
        </svg>
      ))}
    </div>
  );
};

export default RatingStars;
