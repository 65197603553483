import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Input from "../../shared/common/customInput";
import IconGrid from "./icon-picker";

const AddFeatureModal = ({
  isOpen,
  onRequestClose,
  onSave,
  featureToEdit,
  onUpdate,
}) => {
  const [featureName, setFeatureName] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [iconError, setIconError] = useState(false); // New state for error handling
  const [nameError, setNameError] = useState(false); // New state for error handling

  useEffect(() => {
    if (featureToEdit) {
      setFeatureName(featureToEdit.name);
      setSelectedIcon(featureToEdit.icon);
      setIconError(false); // Reset error state when editing
      setNameError(false); // Reset error state when editing
    } else {
      setFeatureName("");
      setSelectedIcon("");
      setIconError(false); // Reset error state when adding
      setNameError(false); // Reset error state when adding
    }
  }, [featureToEdit]);

  const handleSave = () => {
    if (!selectedIcon) {
      setIconError(true); // Show error if icon is not selected
      return;
    } else {
      setIconError(false);
    }

    if (featureName.trim() === "") {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }

    if (featureToEdit) {
      onUpdate({
        name: featureName,
        icon: selectedIcon,
        index: featureToEdit?.index,
      });
      onRequestClose();
    } else {
      if (featureName && selectedIcon) {
        onSave({ name: featureName, icon: selectedIcon });
        onRequestClose();
      } else {
        setIconError(true); // Set error if the icon is not selected
      }
    }
  };

  return (
    <div className="bg-white p-2 rounded-lg w-11/12 max-w-lg relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">
          {featureToEdit ? "Edit Feature" : "Add Feature"}
        </h2>
        <FaTimes className="cursor-pointer" onClick={onRequestClose} />
      </div>
      <div className="flex gap-5 justify-between items-start">
        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">Icon</label>
          <IconGrid
            onIconSelect={setSelectedIcon}
            defaultIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
          />
          {iconError && (
            <p className="text-red-500 text-sm mt-2">Please select an icon.</p>
          )}{" "}
          {/* Error message */}
        </div>
        <div className="mb-4 w-3/4">
          <Input
            label="Name of the feature"
            type="text"
            placeholder="Enter feature name"
            value={featureName}
            onChange={(e) => {
              setFeatureName(e.target.value);
              setNameError(false);
            }}
          />
          {nameError && (
            <p className="text-red-500 text-sm mt-2">
              Enter characters in this field.
            </p>
          )}{" "}
          {/* Error message */}
        </div>
      </div>
      <button
        className="bg-primary w-full text-white px-4 py-2 rounded-md"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default AddFeatureModal;
