import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Average time spent",
    value: 15,
  },
];

function AvgTimeSpendGraph({ data1, startDate, endDate }) {
  const [totalImpressions, setTotalImpressions] = useState(0);
  const data = [
    {
      name: "Average time spent",
      value: totalImpressions,
    },
  ];
  const totalVenuesData = (data) => {
    const totals = data.reduce(
      (acc, venue) => {
        acc.impressions +=
          startDate || endDate
            ? Math.round(venue.avgVisitTimeByDate)
            : Math.round(venue.avgVisitTime);
        return acc;
      },
      { impressions: 0 }
    );

    setTotalImpressions(totals.impressions);
  };

  useEffect(() => {
    totalVenuesData(data1 || []);
  }, [data1]);

  useEffect(() => {
    // console.log("Data1", data);
    // console.log(totalImpressions);
  }, [totalImpressions]);
  return (
    <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px]">
      <div className="mb-8">
        <p className="text-[14px] font-medium">Average time spent</p>
        <p className="text-[12px] text-[#00000005]/60 ">
          average time spent on page by customer
        </p>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data}>
          <XAxis dataKey="name" tickLine={false} />
          <YAxis type="number" domain={[0, "dataMax"]} tickLine={false} />
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <Tooltip />
          {/* <Legend /> */}
          <Area
            type="monotone"
            dataKey="value"
            stroke="none"
            fill="rgba(73, 144, 251, 0.06)"
            // fillOpacity={0.3}
          />
          <Bar
            dataKey="value"
            fill="#4990FB"
            barSize={window.innerWidth < 640 ? 20 : 80}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default AvgTimeSpendGraph;
