import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import modalReducer from "./features/modalSlice";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    auth: authReducer,
    // other reducers can be added here
  },
});

export default store;
