/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import CustomCheckbox from "../../shared/common/checkBox";
import { HotelContext } from "../../../context/HotelContext";
import axios from "axios";
import MapContainer from "./googleMap"; // Import the MapContainer component
import p1 from "../../../assets/parking.svg";
import p2 from "../../../assets/Valet.svg";
import ToggleSwitch from "../../shared/common/custom-toggle";
import UAEStateCitySelector from "./state-city";
import extractMapCoordinates from "../../../utils/extractMapCoordinates";
import { base_URL } from "../../../config/config";

const defaultLocation = {
  lat: 25.276987,
  lng: 55.296249,
};

const LocationForm = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [countries, setCountries] = useState([]);
  const [mapLocation, setMapLocation] = useState(defaultLocation);
  const [mapLinkError, setMapLinkError] = useState("");

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryNames = data
          .map((country) => ({
            value: country.name.common,
            label: country.name.common,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountries(countryNames);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  let locationDetails = formData?.overview?.locationDetails || {
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    poBox: "",
    country: "United Arab Emirates",
    mapLink: "",
    lat: null,
    lng: null,
    parkingAvailable: false,
    valetParking: false,
    accessibilityFeatures: {
      airportShuttle: false,
      metro: false,
      taxi: false,
      bus: false,
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      overview: {
        ...prevFormData.overview,
        locationDetails: {
          ...prevFormData.overview.locationDetails,
          [name]: value,
        },
      },
    }));
  };

  const handleInputChangeforpobox = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    setFormData((prevFormData) => {
      const updatedLocationDetails = {
        ...prevFormData.overview.locationDetails,
        [name]: numericValue,
      };

      // Remove error for the specific field being edited
      if (prevFormData.overview?.locationDetails?.errors) {
        delete updatedLocationDetails.errors?.[name];
      }

      return {
        ...prevFormData,
        overview: {
          ...prevFormData.overview,
          locationDetails: updatedLocationDetails,
        },
      };
    });
  };

  const handleFeatureChange = (feature) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      overview: {
        ...prevFormData.overview,
        locationDetails: {
          ...prevFormData.overview.locationDetails,
          accessibilityFeatures: {
            ...prevFormData.overview.locationDetails.accessibilityFeatures,
            [feature]:
              !prevFormData.overview.locationDetails.accessibilityFeatures[
                feature
              ],
          },
        },
      },
    }));
  };

  const handleCheckboxChange = (field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      overview: {
        ...prevFormData.overview,
        locationDetails: {
          ...prevFormData.overview.locationDetails,
          [field]: !prevFormData.overview.locationDetails[field],
        },
      },
    }));
  };

  const updateMapLocation = async () => {
    const { addressLine1, city, area, mapLink, lat, lng } = locationDetails;

    if (lat && lng) {
      setMapLocation({ lat, lng });
      return;
    }

    if (addressLine1 && city && area && (!mapLink || mapLink === "")) {
      const address = `${addressLine1}, ${city}, ${area}`;
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg`
        );
        const results = response.data.results[0];

        if (results) {
          const { lat, lng } = results.geometry.location;
          setMapLocation({ lat, lng });
        } else {
          setMapLocation(defaultLocation);
        }
      } catch (error) {
        console.error("Error fetching location details:", error);
        setMapLocation(defaultLocation);
      }
    } else if (mapLink) {
      // const urlPattern = /@(.*?),(.*?),(.*?z)/;
      // const match = mapLink.match(urlPattern);
      if (mapLink) {
        const coords = extractMapCoordinates(mapLink);
        if (coords) {
          setMapLocation(coords);
          setFormData((prevFormData) => ({
            ...prevFormData,
            overview: {
              ...prevFormData.overview,
              locationDetails: {
                ...prevFormData.overview.locationDetails,
                lat: coords?.lat,
                lng: coords?.lng,
              },
            },
          }));
        } else {
          console.log("Invalid map link");
        }
        
      } else {
        setMapLinkError("Invalid Google Maps link");
        setMapLocation(defaultLocation);
      }
    } else {
      setMapLocation(defaultLocation);
    }
  };

  useEffect(() => {
    updateMapLocation();
  }, []);

  const fetchCoordinatesFromShortLink = async (shortLink) => {
    try {
      // Step 1: Check if the input is a short link (like "https://maps.app.goo.gl/...")
      if (shortLink.includes("https://maps.app.goo.gl/")) {
        // Step 2: Expand the short URL using your API
        const response = await axios.post(`${base_URL}/api/expand-link`, {
          shortLink,
        });
        const expandedUrl = response?.data?.expandedUrl;
        if (expandedUrl) {
          // Step 3: Extract the coordinates from the expanded URL
          return extractMapCoordinates(expandedUrl);
        } else {
          console.error("Failed to expand the URL.");
          return null;
        }
      } else {
        // If the link is already expanded, directly extract coordinates
        return extractMapCoordinates(shortLink);
      }
    } catch (err) {
      return null;
    }
  };

  const handleMapLinkChange = async (e) => {
    const mapLink = e.target.value;

    // Step 2: Allow the field to be cleared
    if (!mapLink) {
      setMapLinkError("Please enter the Map Link to continue"); // Reset any errors
      setFormData((prevData) => ({
        ...prevData,
        overview: {
          ...formData?.overview,
          locationDetails: {
            ...locationDetails,
            lat: "",
            lng: "",
            mapLink: "",
          },
        },
      }));
      return;
    }

    // Step 1: Check if it's a valid Google Maps link (both full and short versions)
    const googleMapsFullLinkPattern =
      /^https?:\/\/(www\.)?google\.(com|[a-z]{2,3})\/maps\/.+$/;
    const googleMapsShortLinkPattern = /^https?:\/\/maps\.app\.goo\.gl\/.+$/;

    if (
      !googleMapsFullLinkPattern.test(mapLink) &&
      !googleMapsShortLinkPattern.test(mapLink)
    ) {
      setMapLinkError("Please enter a valid Google Maps link.");
      return; // Stop further execution if not a valid Google Maps link
    }

    const coords = await fetchCoordinatesFromShortLink(mapLink);
    if (coords) {
      setMapLocation(coords);
      setMapLinkError(""); // Reset error message
    } else {
      setMapLinkError("Invalid map link");
      return;
    }

    const updatedLocation = {
      ...locationDetails,
      lat: coords?.lat,
      lng: coords?.lng,
      mapLink,
    };

    setFormData((prevData) => ({
      ...prevData,
      overview: {
        ...formData?.overview,
        locationDetails: updatedLocation,
      },
    }));
  };

  const handleLocationChange = (lat, lng) => {
    setMapLocation({ lat, lng });
    setFormData((prevFormData) => ({
      ...prevFormData,
      overview: {
        ...prevFormData.overview,
        locationDetails: {
          ...prevFormData.overview.locationDetails,
          lat,
          lng,
        },
      },
    }));
  };

  const handleStateChange = (selectedState) => {
    setFormData((prevFormData) => {
      const updatedLocationDetails = {
        ...prevFormData.overview.locationDetails,
        area: selectedState,
        city: "", // Reset city when state changes
      };

      // Remove error for the 'area' field
      if (prevFormData.overview?.locationDetails?.errors) {
        delete updatedLocationDetails.errors?.area;
      }

      return {
        ...prevFormData,
        overview: {
          ...prevFormData.overview,
          locationDetails: updatedLocationDetails,
        },
      };
    });
  };

  const handleCityChange = (selectedCity) => {
    setFormData((prevFormData) => {
      const updatedLocationDetails = {
        ...prevFormData.overview.locationDetails,
        city: selectedCity,
      };

      // Remove error for the 'city' field
      if (prevFormData.overview?.locationDetails?.errors) {
        delete updatedLocationDetails.errors?.city;
      }

      return {
        ...prevFormData,
        overview: {
          ...prevFormData.overview,
          locationDetails: updatedLocationDetails,
        },
      };
    });
  };

  return (
    <div className="p-6 bg-white rounded-lg">
      <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
        Location
      </h2>
      <form className="w-full flex flex-wrap gap-4 justify-between mx-auto">
        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary text-sm mb-2">
            Address Line 1{" "}
            <span className="text-red-500 ml-1 text-[15px]">*</span>
          </label>
          <input
            type="text"
            name="addressLine1"
            value={locationDetails.addressLine1 || ""}
            onChange={handleInputChange}
            placeholder="Street name"
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
          {formData.overview?.locationDetails?.errors?.addressLine1 && (
            <p className="text-red-500 text-base pt-1">
              {formData.overview.locationDetails.errors.addressLine1}
            </p>
          )}
        </div>

        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary text-sm mb-2">
            Address Line 2
          </label>
          <input
            type="text"
            name="addressLine2"
            value={locationDetails.addressLine2 || ""}
            onChange={handleInputChange}
            placeholder="Apartment number, suite number"
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
        <UAEStateCitySelector
          selectedState={locationDetails.area}
          selectedCity={locationDetails.city}
          onStateChange={handleStateChange}
          onCityChange={handleCityChange}
          errors={formData.overview?.locationDetails?.errors}
        />

        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary text-sm mb-2">PO Box</label>
          <input
            type="text"
            name="poBox"
            value={locationDetails.poBox || ""}
            onChange={handleInputChangeforpobox}
            // onChange={(e) => {
            //   // Ensure only numeric input is accepted
            //   const value = e.target.value.replace(/\D/g, "");
            //   handleInputChange({ target: { name: e.target.name, value } });
            // }}
            onKeyDown={(e) => {
              // Prevent scientific notation and arrow keys
              if (["e", "E", "ArrowUp", "ArrowDown"].includes(e.key)) {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.preventDefault()}
            step="0"
            placeholder="Enter PO Box"
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent no-spinner"
          />
          {/* {formData.overview?.locationDetails?.errors?.poBox && (
            <p className="text-red-500 text-base pt-1">
              {formData.overview.locationDetails.errors.poBox}
            </p>
          )} */}
        </div>

        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary text-sm mb-2">Country</label>
          <select
            name="country"
            value={locationDetails.country || "United Arab Emirates"}
            onChange={handleInputChange}
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          >
            {/* {countries.map((country, index) => (
                            <option  key={index} value={country.value}>{country.label}</option>
                        ))} */}
            <option value="United Arab Emirates">United Arab Emirates</option>
          </select>
        </div>

        <div className="w-full mb-4">
          <MapContainer
            isHotel={true}
            isVenue={false}
            lat={mapLocation.lat}
            lng={mapLocation.lng}
            mapLocation={mapLocation}
            setMapLocation={setMapLocation}
            onLocationChange={handleLocationChange}
          />
        </div>

        <div className="w-full mb-4">
          <label className="block text-secondary text-sm mb-2">
            Map Link <span className="text-red-500 ml-1 text-[15px]">*</span>
          </label>
          <input
            type="text"
            name="mapLink"
            value={formData?.overview?.locationDetails?.mapLink || ""}
            onChange={handleMapLinkChange}
            placeholder="Enter Google Map Link"
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
          {/* {(mapLinkError ||
            formData?.overview?.locationDetails?.errors?.mapLink) && (
            <p className="text-red-500 text-base pt-1">
              {mapLinkError || 
                formData?.overview?.locationDetails?.errors?.mapLink}
            </p>
          )} */}
          <p className="text-mediumGray text-sm leading-[21.84px]">
            Please navigate to google, copy the link for your location & paste
            it.
          </p>
          {formData.overview?.locationDetails?.errors?.mapLink ? (
            <p className="text-red-500 text-base pt-1">
              {formData.overview?.locationDetails?.errors.mapLink}
            </p>
          ) : null}
        </div>

        <div className="w-full  ">
          <h3 className="text-sm text-secondary py-4">
            Accessibility Features
          </h3>
          <div className="w-[70%] mb-4 flex gap-5 items-center justify-between">
            <CustomCheckbox
              label="Airport Shuttle"
              checked={
                locationDetails.accessibilityFeatures?.airportShuttle || false
              }
              onChange={() => handleFeatureChange("airportShuttle")}
            />
            <CustomCheckbox
              label="Metro"
              checked={locationDetails.accessibilityFeatures?.metro || false}
              onChange={() => handleFeatureChange("metro")}
            />
            <CustomCheckbox
              label="Taxi"
              checked={locationDetails.accessibilityFeatures?.taxi || false}
              onChange={() => handleFeatureChange("taxi")}
            />
            <CustomCheckbox
              label="Bus"
              checked={locationDetails.accessibilityFeatures?.bus || false}
              onChange={() => handleFeatureChange("bus")}
            />
          </div>
        </div>

        <div className="w-full mb-4 space-y-5">
          <h3 className="text-secondary text-sm mb-2">Parking</h3>
          <div className="flex py-1 items-center justify-between lg:w-1/2 w-full mb-4">
            <div className="flex items-center gap-4 ">
              <img src={p1} alt="" className="" />
              <span className="text-base text-mediumGray">
                Parking Available
              </span>
            </div>
            <div className="flex items-center gap-2 relative">
              <ToggleSwitch
                isOn={locationDetails.parkingAvailable || false}
                handleToggle={() => handleCheckboxChange("parkingAvailable")}
              />
            </div>
          </div>

          <div className="flex py-1 items-center justify-between lg:w-1/2 w-full mb-4">
            <div className="flex items-center gap-4 ">
              <img src={p2} alt="" className="" />
              <span className="text-base text-mediumGray">Valet Parking </span>
            </div>
            <div className="flex items-center gap-2 relative">
              <ToggleSwitch
                isOn={locationDetails.valetParking || false}
                handleToggle={() => handleCheckboxChange("valetParking")}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LocationForm;
