import React, { useState } from "react";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import moment from "moment";

const MobileEnquiryDashboard = ({
  filteredEnquiries,
  updateStatus,
  tabs,
  setActiveTab,
  setCurrentPage,
  activeTab,
  pageSize,
  handlePageSizeChange,
  isLoading,
  setActiveEnquiryDetails,
  setSelectedEnquiryDetails,
  user,
}) => {
  const formatRelativeTime = (timestamp) => {
    return timestamp ? moment(timestamp).fromNow() : "--";
  };

  const isWithin15Minutes = (timestamp) => {
    return timestamp
      ? moment().diff(moment(timestamp), "minutes") <= 15
      : false;
  };
  return (
    <div className="inline md:hidden">
      {/* Tabs */}
      <div className="overflow-x-auto   mb-6">
        <div className="flex gap-4 border-b">
          {tabs?.map((tab) => (
            <button
              key={tab?.label}
              onClick={() => {
                setActiveTab(tab?.label);
                setCurrentPage(1); // Reset to first page when changing tabs
              }}
              className={`px-4 py-2 text-sm whitespace-nowrap ${
                activeTab === tab.label
                  ? "text-[#FE4747] border-b-2 border-[#FE4747]"
                  : "text-[#222222]"
              }`}
            >
              {tab?.count !== null && tab?.count !== undefined
                ? `${tab?.label} (${tab?.count})`
                : tab?.label}
            </button>
          ))}
        </div>
      </div>

      {/* Filters */}
      <div className="flex gap-x-2 mb-6">
        <select className="w-full px-3 py-2 border rounded-lg bg-white text-sm">
          <option value="">Enquiry for</option>
          <option value="rooms">Rooms</option>
          <option value="auditorium">Auditorium</option>
        </select>
        <input
          type="date"
          className="w-full px-3 py-2 border rounded-lg bg-white text-sm"
        />
      </div>

      {/* Enquiry Table */}
      {isLoading && (
        <div className="bg-white  rounded-xl  inline md:hidden">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="shimmer shimmer-row"></div>
          ))}
        </div>
      )}
      <div className="">
        {filteredEnquiries?.map((enquiry) => (
          <div
            key={enquiry.id}
            className="bg-white shadow-sm border border-[#EBEBEB]"
          >
            <div className="p-3 flex items-center justify-between">
              <div className="space-y-1">
                <h3 className="font-medium text-[14px]">
                  {" "}
                  {enquiry?.customerId?.fullName}
                </h3>
                <p className="text-[12px] text-[#222222] font-normal">
                  {enquiry?.eventType}
                </p>
              </div>
              <div className="flex items-center gap-x-5">
                <div className="flex flex-col items-end gap-y-1">
                  <StatusDropdown
                    enquiry={enquiry}
                    updateStatus={updateStatus}
                    user={user}
                  />
                  <span className="text-[12px] font-normal">
                    <div className="flex items-center gap-2">
                      <div
                        className={`bg-[#D03660] rounded-full w-2 ${
                          isWithin15Minutes(enquiry?.lastMessage?.time)
                            ? "visible mr-2 h-2"
                            : "invisible "
                        }`}
                      ></div>
                      {formatRelativeTime(enquiry?.lastMessage?.time)}
                    </div>
                  </span>
                </div>
                <div
                  onClick={() => {
                    setActiveEnquiryDetails(true);
                    setSelectedEnquiryDetails(enquiry);
                  }}
                >
                  <img
                    src="/rigthredarrow.svg"
                    alt=""
                    className="cursor-pointer active:opacity-50"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}

      <div className="flex items-center justify-end mt-4 gap-x-2">
        <span className="text-[14px] text-[#222222]">Display:</span>
        <select
          value={pageSize}
          onChange={handlePageSizeChange}
          className="px-2 py-1 border rounded-lg bg-white"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  );
};

export default MobileEnquiryDashboard;
