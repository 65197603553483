/* eslint-disable */

"use client";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import chair from "../../assets2/images/Chair.svg";
import standing from "../../assets2/icons/Standing.png";
import { MdDone } from "react-icons/md";
import houses from "../../assets2/images/houses.svg";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import NewFavoriteFolderModal from "../myFav/NewFavoriteFolderModal";
import AddedFavoriteModal from "../myFav/AddedFavoriteModal";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/features/authSlice";
import { getUser } from "../../lib/apis";
import { toast } from "react-toastify";
import { getEnquiries } from "../../lib/apis";
import Coupon from "../../assets2/icons/Coupon.svg";
import { base_URL } from "../../config/config";
import { DeviceType } from "../../hooks/deviceType";
import { useQuery } from "@tanstack/react-query";

const DetailComponent = ({ data, isCompareMode, isSelected, onSelect }) => {
  const cardRef = useRef();
  const user = useSelector((state) => state.auth.user);
  const deviceType = DeviceType();
  const favoriteVenues =
    user?.favoritesFolder?.map((folder) => folder.venues).flat() || [];
  const dispatch = useDispatch();
  const [newFolder, setNewFolder] = useState(false);
  const [favItem, setFavItem] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddedOpen, setIsModalAddedOpen] = useState(false);
  const [isSubmittedEnquiry, setIsSubmittedEnquiry] = useState(false);
  const hasOffers =
    data?.offersAndPackages?.length > 0 &&
    data?.offersAndPackages?.some((offer) => {
      const currentDate = new Date();
      const endDate = new Date(offer.endDate);
      return endDate >= currentDate;
    });

  const [impressions, setImpressions] = useState(new Set());
  const [minSpendPricing, setMinSpendPricing] = useState(null);
  const [minSpendPerPerson, setMinSpendPerPerson] = useState(null);
  const [venueRental, setVenueRental] = useState(null);
  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-folders/${user?._id}`
      );
      if (!response.ok) {
        console.log("Failed to fetch Venues");
        return;
      }
      const data = await response.json();
      console.log(data);
      return data.folders;
    } catch (err) {
      console.log(err);
    }
  };
  const { folders, refetch } = useQuery({
    queryKey: ["favoriteFolder", user?._id],
    queryFn: getFavoriteFolder,
    enabled: !!user?._id, // Ensure the query only runs when these values are available
  });
  const calculateMinSpendFromSelectedOptions = () => {
    if (data?.pricing) {
      let selectedOptions = [];
      if (data?.pricing?.["Minimum Spend Pricing"]) {
        selectedOptions = [
          ...selectedOptions,
          {
            type: "Minimum Spend Pricing",
            details: data?.pricing["Minimum Spend Pricing"][0],
          },
        ];
      }
      if (data?.pricing?.["Venue Rental"]) {
        selectedOptions = [
          ...selectedOptions,
          { type: "Venue Rental", details: data?.pricing["Venue Rental"][0] },
        ];
      }
      if (data?.pricing?.["Min spend per person"]) {
        selectedOptions = [
          ...selectedOptions,
          {
            type: "Min spend per person",
            details: data?.pricing["Min spend per person"][0],
          },
        ];
      }
      let minSpendPricing = null;
      let venueRental = null;
      let minSpendPerPerson = null;
      let hasMinimumSpendPricing = false;

      selectedOptions.forEach((option) => {
        const minSpendValue = Number(option?.details?.minSpend || undefined);

        if (minSpendValue !== undefined && !Number.isNaN(minSpendValue)) {
          switch (option.type) {
            case "Minimum Spend Pricing":
              hasMinimumSpendPricing = true; // Flag that "Minimum Spend Pricing" exists
              minSpendPricing =
                minSpendPricing === null
                  ? minSpendValue
                  : Math.min(minSpendPricing, minSpendValue);
              break;

            case "Venue Rental":
              // Only use Venue Rental if Minimum Spend Pricing isn't available
              venueRental =
                venueRental === null
                  ? minSpendValue
                  : Math.min(venueRental, minSpendValue);
              break;

            case "Min spend per person":
              minSpendPerPerson =
                minSpendPerPerson === null
                  ? minSpendValue
                  : Math.min(minSpendPerPerson, minSpendValue);
              break;

            default:
              break;
          }
        }
      });

      // Set the calculated minimum spend values to the state
      setMinSpendPricing(minSpendPricing);
      setMinSpendPerPerson(minSpendPerPerson);
      setVenueRental(venueRental);
    } else {
      // If no selected options are present, reset all values to null
      setMinSpendPricing(null);
      setMinSpendPerPerson(null);
      setVenueRental(null);
    }
  };

  // Call this function in useEffect to update the values whenever formData changes
  useEffect(() => {
    calculateMinSpendFromSelectedOptions();
  }, [data]);

  const handleImpression = (id) => {
    setImpressions((prevImpressions) => {
      if (!prevImpressions.has(id)) {
        const newSet = new Set(prevImpressions);
        newSet.add(id);
        // console.log(`Venue ${id} impressed`);
        updateImpressions(data?._id);
        return newSet;
      }
      return prevImpressions;
    });
  };

  const updateImpressions = async (venueId) => {
    const impressionData = {
      venueId: venueId,
      vendorId: data?.vendorId,
      date: new Date(),
      deviceCategory: deviceType,
      impressions: 1,
      venueName: `${data?.basicDetails?.venueName}, ${data?.basicDetails?.hotelName}`,
      customerId: user?._id,
    };
    try {
      const response = await fetch(
        `${base_URL}/api/impressions/update-impressions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(impressionData),
        }
      );
      // console.log(response);
      const data = await response.json();
      // console.log(data);
      return data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // console.log(handleImpression);
        if (handleImpression && entry.isIntersecting) {
          handleImpression(data?._id); // Trigger impression when visible
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the card is visible
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [data?._id, handleImpression]);

  const setEnquiryData = async () => {
    const enquiriesData = await getEnquiries();
    const venueEnquiries = enquiriesData?.venueEnquiries;
    const enquiryId = venueEnquiries?.map((enquiry) => enquiry.venueId);
    if (enquiryId?.includes(data._id)) {
      setIsSubmittedEnquiry(true);
    }
  };

  useEffect(() => {
    if (user) setEnquiryData();
  }, []);
  if (isMobileView) {
  }

  const navigate = useNavigate();
  const isFavorite = favoriteVenues.some(
    (favorite) => favorite?.id === data?._id
  );

  if (isMobileView) {
    // console.log("");
  }
  const handleClickFavorite = (itemId) => {
    if (!user) {
      return toast.error("Please login first");
    }

    if (isFavorite) return;

    setFavItem(itemId);
    user?.favoritesFolder?.length > 0
      ? setIsModalAddedOpen(true)
      : setIsModalOpen(true);
  };

  const handleViewPreviewDetails = async () => {
    const cf = "hpv";
    navigate(`/preview/${cf}/${data?._id}`);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUserInfo = async () => {
    const userInfo = await getUser(user?._id);
    if (userInfo) dispatch(setUser(userInfo));
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      data?.imagesAndVideos?.images?.length > 0
        ? Math.min(data.imagesAndVideos?.images?.length, 1)
        : 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
      {newFolder && (
        <NewFavoriteFolderModal
          isOpen={newFolder}
          onClose={() => setNewFolder(false)}
          itemId={favItem}
          type={"venue"}
          refetch={refetch}
        />
      )}
      {user?.favoritesFolder?.length > 0 ? (
        <AddedFavoriteModal
          setNewFolder={setNewFolder}
          isOpen={isModalAddedOpen}
          refetch={refetch}
          itemId={favItem}
          data={folders}
          type={"venue"}
          onClose={() => setIsModalAddedOpen(false)}
        />
      ) : (
        <NewFavoriteFolderModal
          isOpen={isModalOpen}
          itemId={favItem}
          refetch={refetch}
          type={"venue"}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <div
        className="bg-white rounded-lg min-h-[464px] shadow-lg relative"
        ref={cardRef}
      >
        {isCompareMode && (
          <div className="flex justify-center items-center gap-2 absolute bottom-3 left-3 z-10">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onSelect(data)}
              className=" h-5 w-6"
            />
            <span className="text-[16px] text-dark">Compare</span>
          </div>
        )}
        {data?.imagesAndVideos?.images?.length === 1 ? (
          <div className="w-full relative">
            <div className="w-[100%] h-[250px]">
              <img
                src={data.imagesAndVideos.images[0]}
                alt=""
                className="rounded-lg w-full h-full bg-cover"
              />
            </div>
            <div
              className="bg-white absolute top-1 right-5 p-1.5 rounded-full cursor-pointer"
              onClick={() => handleClickFavorite(data?._id, 0)}
            >
              {isFavorite ? (
                <IoMdHeart className="text-primary" />
              ) : (
                <IoMdHeartEmpty className="text-primary" />
              )}
            </div>
            {isSubmittedEnquiry && (
              <div className="bg-[#EAF9F7] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#039f8d] absolute top-3 left-3 rounded-md cursor-pointer">
                <span>
                  <MdDone className="text-md text-[#039f8d]" />
                </span>
                Enquiry Sent
              </div>
            )}
            {hasOffers && (
              <div className="bg-[#fff6e6] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#4B3A19] absolute bottom-3 right-3 rounded-md cursor-pointer">
                <span className="mr-1">
                  <img src={Coupon} alt="" />
                </span>
                Offer Available
              </div>
            )}
          </div>
        ) : (
          <Slider {...imageSettings}>
            {data?.imagesAndVideos?.images?.map((e, imageIndex) => (
              <div key={imageIndex} className="w-full relative">
                <div className="w-[100%] h-[250px]">
                  <img
                    src={e}
                    alt=""
                    className="rounded-lg w-full h-full bg-cover"
                  />
                </div>
                <div
                  className="bg-white absolute top-3 right-4 p-1.5 rounded-full cursor-pointer"
                  onClick={() => handleClickFavorite(data._id, imageIndex)}
                >
                  {isFavorite ? (
                    <IoMdHeart className="text-primary" />
                  ) : (
                    <IoMdHeartEmpty className="text-primary" />
                  )}
                </div>
                {isSubmittedEnquiry && (
                  <div className="bg-[#EAF9F7] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#039f8d] absolute top-3 left-3 rounded-md cursor-pointer">
                    <span>
                      <MdDone className="text-md text-[#039f8d]" />
                    </span>
                    Enquiry Sent
                  </div>
                )}
                {hasOffers && (
                  <div className="bg-[#fff6e6] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#4B3A19] absolute bottom-3 right-3 rounded-md cursor-pointer">
                    <span className="mr-1">
                      <img src={Coupon} alt="" />
                    </span>
                    Offer Available
                  </div>
                )}
              </div>
            ))}
          </Slider>
        )}

        <div className={`flex flex-col items-start gap-2 p-3 `}>
          <h1
            className="text-darkGray text-lg font-medium cursor-pointer hover:text-[#FE4747]"
            onClick={() => {
              handleViewPreviewDetails(
                data?._id,
                `${data?.basicDetails?.venueName}, ${data?.basicDetails?.hotelName}`,
                user?._id
              );
            }}
          >
            {data?.basicDetails?.venueName || "Undefined"},{" "}
            {data?.basicDetails?.hotelName || "Undefined"}
          </h1>
          <p className="text-[#717171]">
            {data?.location?.city || "Undefined"},{" "}
            {data?.location?.area || "Undefined"}
          </p>
          <div className="flex items-center w-full justify-start gap-4">
            <div className="flex items-center gap-1">
              <img src={chair} alt="" />
              <p className="text-[#717171]">
                {data?.capacity?.sitting || "Undefined"}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <img src={standing} alt="" className="w-[20px] h-[20px]" />
              <p className="text-[#717171]">
                {data?.capacity?.standing || "Undefined"}
              </p>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <img src={houses} alt="" />
              <p className=" font-medium text-sm text-[#717171]">
                {data?.venueType?.venueRepresent} .{" "}
                {data?.additionalDetails?.venueSetting?.length === 1
                  ? data?.additionalDetails?.venueSetting?.[0]
                  : "Indoor + Outdoor"}
              </p>
            </div>
          </div>
          <div
            className={`flex items-start w-full justify-between ${
              isCompareMode && "pb-7"
            } `}
          >
            {minSpendPricing &&
            venueRental &&
            !Number.isNaN(minSpendPricing) &&
            !Number.isNaN(venueRental) ? (
              <>
                <div className="">
                  <p className="text-primary font-medium">
                    AED {minSpendPricing}
                  </p>
                  {/* Show one "Min. spend" text */}
                  <p className="text-sm">Min. spend</p>
                </div>
                {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
              </>
            ) : (
              <>
                {/* Case 2: Both minSpendPerPerson and venueRental available */}
                {minSpendPerPerson &&
                venueRental &&
                !Number.isNaN(minSpendPerPerson) &&
                !Number.isNaN(venueRental) ? (
                  <>
                    <div className="">
                      <p className="text-primary font-medium">
                        AED {venueRental}
                      </p>
                      <p className="text-sm">Min. spend</p>
                    </div>
                    <div className="">
                      <p className="text-primary font-medium">
                        AED {minSpendPerPerson}
                      </p>
                      <p className="text-sm">Per guest</p>
                    </div>
                  </>
                ) : (
                  <>
                    {/* Render each one individually if other cases don't match */}
                    {minSpendPerPerson && !Number.isNaN(minSpendPerPerson) && (
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {minSpendPerPerson}
                        </p>
                        <p className="text-sm">Per guest</p>
                      </div>
                    )}
                    {minSpendPricing && !Number.isNaN(minSpendPricing) && (
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {minSpendPricing}
                        </p>
                        <p className="text-sm">Min. spend</p>
                      </div>
                    )}
                    {venueRental && !Number.isNaN(venueRental) && (
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {venueRental}
                        </p>
                        <p className="text-sm">Min. spend</p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailComponent;
