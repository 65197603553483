import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormData,
  setResendEmail,
  setSubmitting,
  toggleCheckBox,
} from "../store/features/authSlice";
import { closeModals, toggleAdditional } from "../store/features/modalSlice";
import { Apple, Facebook, Google } from "../svgs";
import Button from "./shared/Button";
import CustomCheckBox from "./shared/CustomCheckbox";
import CustomInput from "./shared/CustomInput";
import { useNavigate } from "react-router-dom";
import { resetFormData } from "../store/features/authSlice";

const SignUpModal = ({ onLoginClick, onSocialClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formData, isSubmitting } = useSelector((state) => state.auth);
  const [error, setError] = React.useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    dispatch(setFormData({ name, value }));

    // Sync email with resendEmail field
    if (name === "email") {
      dispatch(setResendEmail(value)); // Sync resendEmail with the email field
    }
  };

  const handleCheckBox = () => {
    dispatch(toggleCheckBox());
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    if (password.length < minLength) {
      setError((prev) => ({
        ...prev,
        password: "Password must be at least 8 characters long.",
      }));
      return false;
    }
    if (!hasNumber.test(password)) {
      setError((prev) => ({
        ...prev,
        password: "Password must contain at least one number.",
      }));
      return false;
    }
    if (!hasSpecialChar.test(password)) {
      setError((prev) => ({
        ...prev,
        password: "Password must contain at least one special character.",
      }));
      return false;
    }
    return true;
  };

  const validateForm = () => {
    const { fullName, email, password, confirmPassword } = formData;

    // Clear previous errors
    setError({ fullName: "", email: "", password: "", confirmPassword: "" });

    if (!fullName) {
      setError((prev) => ({
        ...prev,
        fullName: "Full name is required.",
      }));
    }

    if (!email) {
      setError((prev) => ({
        ...prev,
        email: "Email address is required.",
      }));
    }
    if (email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(email)) {
        setError((prev) => ({
          ...prev,
          email: "Please enter a valid email address.",
        }));
        return false;
      }
    }

    if (!password || password.length < 8) {
      setError((prev) => ({
        ...prev,
        password: "Password must be atleast 8 characters long.",
      }));
    }

    if (!confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Please confirm your password.",
      }));
      return false;
    }

    if (password !== confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match.",
      }));
      return false;
    }

    if (!validatePassword(password)) {
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    // console.log("click");

    dispatch(setSubmitting(true));

    if (!validateForm()) {
      dispatch(setSubmitting(false));
      return;
    }

    dispatch(toggleAdditional());
    dispatch(setSubmitting(false));
  };

  return (
    <div>
      <div
        onClick={() => {
          navigate("/register");
          dispatch(closeModals());
          dispatch(resetFormData());
        }}
        className="text-dark cursor-pointer mb-0 underline mx-auto w-full text-center"
      >
        Register as Vendor
      </div>
      <p className="text-center text-gray">
        Already have an account?{" "}
        <span
          onClick={onLoginClick}
          className="underline cursor-pointer font-medium text-black"
        >
          Log in
        </span>
      </p>

      <div className="flex flex-col gap-4">
        <CustomInput
          label={"Full name"}
          name={"fullName"}
          type={"text"}
          placeholder={"Enter your full name"}
          value={formData.fullName}
          onChange={handleChange}
          error={error.fullName}
        />
        <CustomInput
          label={"Email address"}
          name={"email"}
          type={"text"}
          placeholder={"Enter your email address"}
          value={formData.email}
          onChange={handleChange}
          error={error.email}
        />
        <CustomInput
          label={"Password"}
          name={"password"}
          type={"password"}
          placeholder={"Enter your password"}
          value={formData.password}
          onChange={handleChange}
          error={error.password}
        />
        <CustomInput
          label={"Confirm Password"}
          name={"confirmPassword"}
          type={"password"}
          placeholder={"Enter your password again"}
          value={formData.confirmPassword}
          onChange={handleChange}
          error={error.confirmPassword}
        />
        <div className="mt-2">
          <CustomCheckBox
            label={
              <span>
                <span className="font-[500]">Newsletter subscription</span>
                <br />
                Receive announcements, recommendations, and updates about
                Findmyvenue
              </span>
            }
            isChecked={formData.newsletterSubscription}
            onChange={handleCheckBox}
          />
        </div>

        <Button
          onClick={onSubmit}
          label={isSubmitting ? "Signing up..." : "Sign up"}
          disabled={isSubmitting}
        />

        <div className="flex gap-2 justify-center w-full">
          {[<Facebook />, <Apple />, <Google />].map((e, i) => (
            <button
              key={i}
              onClick={onSocialClick}
              className="border justify-center flex border-dark rounded-md w-full px-4 py-2"
            >
              {e}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
