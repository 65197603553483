/*eslint react-hooks/exhaustive-deps: "off"*/
/*eslint-disable*/

import React, { useEffect, useState, useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { toast } from "react-toastify";
import { base_URL } from "../../config/config";
import { useCountryLocation } from "../../hooks/userCountry";
import { DeviceType } from "../../hooks/deviceType";
import EnquirySuccessful from "./EnquirySuccessfulModel";
const time = [
  { id: 1, time: "06:00", value: "06:00 AM" },
  { id: 2, time: "06:30", value: "06:30 AM" },
  { id: 3, time: "07:00", value: "07:00 AM" },
  { id: 4, time: "07:30", value: "07:30 AM" },
  { id: 5, time: "08:00", value: "08:00 AM" },
  { id: 6, time: "08:30", value: "08:30 AM" },
  { id: 7, time: "09:00", value: "09:00 AM" },
  { id: 8, time: "09:30", value: "09:30 AM" },
  { id: 9, time: "10:00", value: "10:00 AM" },
  { id: 10, time: "10:30", value: "10:30 AM" },
  { id: 11, time: "11:00", value: "11:00 AM" },
  { id: 12, time: "11:30", value: "11:30 AM" },
  { id: 13, time: "12:00", value: "12:00 PM" },
  { id: 14, time: "12:30", value: "12:30 PM" },
  { id: 15, time: "13:00", value: "01:00 PM" },
  { id: 16, time: "13:30", value: "01:30 PM" },
  { id: 17, time: "14:00", value: "02:00 PM" },
  { id: 18, time: "14:30", value: "02:30 PM" },
  { id: 19, time: "15:00", value: "03:00 PM" },
  { id: 20, time: "15:30", value: "03:30 PM" },
  { id: 21, time: "16:00", value: "04:00 PM" },
  { id: 22, time: "16:30", value: "04:30 PM" },
  { id: 23, time: "17:00", value: "05:00 PM" },
  { id: 24, time: "17:30", value: "05:30 PM" },
  { id: 25, time: "18:00", value: "06:00 PM" },
  { id: 26, time: "18:30", value: "06:30 PM" },
  { id: 27, time: "19:00", value: "07:00 PM" },
  { id: 28, time: "19:30", value: "07:30 PM" },
  { id: 29, time: "20:00", value: "08:00 PM" },
  { id: 30, time: "20:30", value: "08:30 PM" },
  { id: 31, time: "21:00", value: "09:00 PM" },
  { id: 32, time: "21:30", value: "09:30 PM" },
  { id: 33, time: "22:00", value: "10:00 PM" },
  { id: 34, time: "22:30", value: "10:30 PM" },
  { id: 35, time: "23:00", value: "11:00 PM" },
  { id: 36, time: "23:30", value: "11:30 PM" },
  { id: 37, time: "00:00", value: "12:00 AM" },
  { id: 38, time: "00:30", value: "12:30 AM" },
  { id: 39, time: "01:00", value: "01:00 AM" },
  { id: 40, time: "01:30", value: "01:30 AM" },
  { id: 41, time: "02:00", value: "02:00 AM" },
  { id: 42, time: "02:30", value: "02:30 AM" },
  { id: 43, time: "03:00", value: "03:00 AM" },
  { id: 44, time: "03:30", value: "03:30 AM" },
  { id: 45, time: "04:00", value: "04:00 AM" },
  { id: 46, time: "04:30", value: "04:30 AM" },
  { id: 47, time: "05:00", value: "05:00 AM" },
  { id: 48, time: "05:30", value: "05:30 AM" },
];

const venueTypes = [
  { label: "Annual General meeting", value: "Annual General meeting" },
  { label: "Auctions", value: "Auctions" },
  { label: "Board Meetings", value: "Board Meetings" },
  { label: "Breakout", value: "Breakout" },
  { label: "Conference", value: "Conference" },
  { label: "Conventions", value: "Conventions" },
  { label: "Executive Summits", value: "Executive Summits" },
  { label: "Exhibitions", value: "Exhibitions" },
  { label: "Meetings", value: "Meetings" },
  { label: "Networking", value: "Networking" },
  { label: "Presentation", value: "Presentation" },
  { label: "Press Conference", value: "Press Conference" },
  { label: "Product Launch", value: "Product Launch" },
  { label: "Seminars", value: "Seminars" },
  { label: "Symposium", value: "Symposium" },
  { label: "Trade Shows", value: "Trade Shows" },
  { label: "Training", value: "Training" },
  { label: "Workshops", value: "Workshops" },
  { label: "Activity Day", value: "Activity Day" },
  { label: "Award Ceremony", value: "Award Ceremony" },
  { label: "Away day", value: "Away day" },
  { label: "Brainstorm", value: "Brainstorm" },
  { label: "Charity event", value: "Charity event" },
  { label: "Corporate Gala dinner", value: "Corporate Gala dinner" },
  { label: "Corporate Reception", value: "Corporate Reception" },
  { label: "Corporate Retreat", value: "Corporate Retreat" },
  { label: "FAM Trip", value: "FAM Trip" },
  { label: "Fundraiser", value: "Fundraiser" },
  { label: "Incentive trip", value: "Incentive trip" },
  { label: "Office Party", value: "Office Party" },
  { label: "Outdoor Activity", value: "Outdoor Activity" },
  { label: "Pop Up event", value: "Pop Up event" },
  { label: "PR events", value: "PR events" },
  { label: "Promotional Events", value: "Promotional Events" },
  { label: "Staff Party", value: "Staff Party" },
  { label: "Summer Party", value: "Summer Party" },
  { label: "Team Building", value: "Team Building" },
  { label: "Training Program", value: "Training Program" },
  { label: "Arabic Wedding", value: "Arabic Wedding" },
  { label: "Beach Wedding", value: "Beach Wedding" },
  { label: "Christian Wedding", value: "Christian Wedding" },
  { label: "Church Wedding", value: "Church Wedding" },
  { label: "Civil Partnership", value: "Civil Partnership" },
  { label: "Civil Wedding", value: "Civil Wedding" },
  { label: "Garden Wedding", value: "Garden Wedding" },
  { label: "Hindu Wedding", value: "Hindu Wedding" },
  { label: "Indian Wedding", value: "Indian Wedding" },
  { label: "Marquee Wedding", value: "Marquee Wedding" },
  { label: "Outdoor Wedding", value: "Outdoor Wedding" },
  { label: "Unique Wedding", value: "Unique Wedding" },
  { label: "Wedding Ceremony", value: "Wedding Ceremony" },
  { label: "Wedding Reception", value: "Wedding Reception" },
  { label: "BBQ", value: "BBQ" },
  { label: "Anniversary Party", value: "Anniversary Party" },
  { label: "Cocktail Masterclass", value: "Cocktail Masterclass" },
  { label: "Cocktail Reception", value: "Cocktail Reception" },
  { label: "Communion", value: "Communion" },
  { label: "Condolences", value: "Condolences" },
  { label: "Drinks Reception", value: "Drinks Reception" },
  { label: "Engagement Reception", value: "Engagement Reception" },
  { label: "Farewell Party", value: "Farewell Party" },
  { label: "Funeral reception", value: "Funeral reception" },
  { label: "Graduation Party", value: "Graduation Party" },
  { label: "New Year's Eve Party", value: "New Year's Eve Party" },
  { label: "Party", value: "Party" },
  { label: "Private Party", value: "Private Party" },
  { label: "Retirement Party", value: "Retirement Party" },
  { label: "Reunions", value: "Reunions" },
  { label: "Stag Party", value: "Stag Party" },
  { label: "Wine Tasting", value: "Wine Tasting" },
  { label: "16th Birthday Party", value: "16th Birthday Party" },
  { label: "18th Birthday Party", value: "18th Birthday Party" },
  { label: "21st Birthday Party", value: "21st Birthday Party" },
  { label: "25th Birthday Party", value: "25th Birthday Party" },
  { label: "30th Birthday Party", value: "30th Birthday Party" },
  { label: "40th Birthday Party", value: "40th Birthday Party" },
  { label: "50th Birthday Party", value: "50th Birthday Party" },
  { label: "Birthday Party", value: "Birthday Party" },
  { label: "Afternoon Tea", value: "Afternoon Tea" },
  { label: "Baby Christening", value: "Baby Christening" },
  { label: "Baby Shower", value: "Baby Shower" },
  {
    label: "Bachelorette Party/Bachelor",
    value: "Bachelorette Party/Bachelor",
  },
  { label: "Bridal Shower", value: "Bridal Shower" },
  { label: "High Tea", value: "High Tea" },
  { label: "Children Birthday Party", value: "Children Birthday Party" },
  { label: "Kids Party", value: "Kids Party" },
  { label: "Teen Party", value: "Teen Party" },
  { label: "Gender Reveals", value: "Gender Reveals" },
  { label: "Proposals", value: "Proposals" },
  { label: "Brunches", value: "Brunches" },
  { label: "Christmas dinner", value: "Christmas dinner" },
  { label: "Christmas Party", value: "Christmas Party" },
  { label: "Diwali", value: "Diwali" },
  { label: "Eid", value: "Eid" },
  { label: "Halloween Party", value: "Halloween Party" },
  { label: "Holiday Party", value: "Holiday Party" },
  { label: "Iftar", value: "Iftar" },
  { label: "Ladies night", value: "Ladies night" },
  { label: "Ramadan", value: "Ramadan" },
  { label: "Suhoor", value: "Suhoor" },
  { label: "Xmas Party", value: "Xmas Party" },
  { label: "Filming/Video Recording", value: "Filming/Video Recording" },
  { label: "Photoshoot", value: "Photoshoot" },
  { label: "Podcast Recording", value: "Podcast Recording" },
  { label: "Book Launch", value: "Book Launch" },
  { label: "Film Festival", value: "Film Festival" },
  { label: "Music Festival", value: "Music Festival" },
  { label: "Art Exhibitions", value: "Art Exhibitions" },
  { label: "Pop Up Event", value: "Pop Up Event" },
  { label: "Concert", value: "Concert" },
  { label: "Dance Performance", value: "Dance Performance" },
  { label: "Fashion Show", value: "Fashion Show" },
  { label: "GIG", value: "GIG" },
  { label: "Live Performance", value: "Live Performance" },
  { label: "Performing Arts", value: "Performing Arts" },
  { label: "Screening", value: "Screening" },
  { label: "Stand-up Comedy", value: "Stand-up Comedy" },
  { label: "Theatre (Drama/Plays)", value: "Theatre (Drama/Plays)" },
];

const hotelTypes = [
  {
    label: "Company Retreats",
    value: "Company Retreats",
  },
  {
    label: "Corporate Off-sites",
    value: "Corporate Off-sites",
  },
  {
    label: "Executive Meetings",
    value: "Executive Meetings",
  },
  {
    label: "Family Reunions",
    value: "Family Reunions",
  },
  {
    label: "Destination Weddings",
    value: "Destination Weddings",
  },
  {
    label: "Group Gateways",
    value: "Group Gateways",
  },
  {
    label: "Wellness Retreats",
    value: "Wellness Retreats",
  },
  {
    label: "Educational Camps or Workshops",
    value: "Educational Camps",
  },
  {
    label: "Special Events or Celebrations",
    value: "Special Events",
  },
  {
    label: "Training Programs",
    value: "Training Programs",
  },
];

const timeTabs = [
  { id: 1, name: "Event From" },
  { id: 2, name: "Event To" },
];

const validation = (data, type, setError) => {
  if (data.budget === "") {
    setError((prev) => ({ ...prev, budget: "Please enter budget" }));
    return false;
  }
  if (data.customerId === "") {
    toast.error("Please login to send enquiry");
    return false;
  }
  if (data.startDate === "") {
    setError((prev) => ({ ...prev, startDate: "Please select start date" }));
    return false;
  }
  if (data.endDate === "") {
    setError((prev) => ({ ...prev, endDate: "Please select end date" }));
    return false;
  }
  if (type === "venue" && data.startTime === "" && data.endTime === "") {
    setError((prev) => ({
      ...prev,
      startTime: "Please select start and end time",
    }));
    return false;
  } else {
    if (type === "venue" && data.startTime === "") {
      setError((prev) => ({ ...prev, startTime: "Please select start time" }));
      return false;
    }
    if (type === "venue" && data.endTime === "") {
      setError((prev) => ({ ...prev, endTime: "Please select end time" }));
      return false;
    }
  }

  if (data.guests === "") {
    setError((prev) => ({ ...prev, guests: "Please enter number of guests" }));
    return false;
  }
  if (data.eventType === "") {
    setError((prev) => ({ ...prev, eventType: "Please select event type" }));
    return false;
  }
  if (type === "hotel" && data.numberOfVenues === "") {
    setError((prev) => ({
      ...prev,
      numberOfVenues: "Please enter number of venues",
    }));
    return false;
  }

  return true; // Only returns true if no errors are found
};

export default function SpecifyEvent({ type, data }) {
  let eventTypes = type === "venue" ? venueTypes : hotelTypes;
  eventTypes.sort((a, b) => a.label.localeCompare(b.label));
  const autoFillRef = useRef(null);
  const eventTypeRef = useRef(null);
  const { country } = useCountryLocation();
  const [showEvents, setShowEvents] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deviceType = DeviceType();
  const [isOpen, setIsOpen] = useState(false);
  const user = localStorage.getItem("authUser");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [showTime, setShowTime] = useState(false);
  const [activeTimeTab, setActiveTimeTab] = useState(1);
  const [showAutoFill, setShowAutoFill] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState("");
  const [flexibility, setFlexibility] = useState(false);
  const [rooms, setRooms] = useState("");
  const [accomodation, setAccomodation] = useState(false);
  const [numberOfVenues, setNumberOfVenues] = useState("");
  const [guests, setGuests] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customerId, setCustomerId] = useState(
    user ? JSON.parse(user)._id : ""
  );
  const [venueName, setVenueName] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [previousEnquiry, setPreviousEnquiry] = useState([]);
  const [budget, setBudget] = useState("");
  const [error, setError] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    rooms: "",
    budget: "",
    accommodation: "",
    numberOfVenues: "",
    guests: "",
    eventType: "",
    termsAndConditions: "",
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autoFillRef.current &&
        !autoFillRef.current.contains(event.target) &&
        showAutoFill
      ) {
        setShowAutoFill(false);
      }
      if (
        eventTypeRef.current &&
        !eventTypeRef.current.contains(event.target) &&
        showEvents
      ) {
        setShowEvents(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAutoFill, showEvents]);

  const handleShowEvents = () => {
    setShowEvents(!showEvents);
  };

  useEffect(() => {
    if (user) {
      const userData = JSON.parse(user);
      setCustomerId(userData._id);
      if (type === "venue") {
        setVenueName(`${data?.basicDetails?.venueName}`);
        setHotelName(data?.basicDetails?.hotelName);
        setVenueImage(data?.imagesAndVideos?.images[0]);
      }
      if (type === "hotel") {
        setVenueName(data?.overview?.basicDetails?.hotelChain);
        setHotelName(data?.overview?.basicDetails?.hotelName);
        setVenueImage(data?.photoVideo?.images[0]);
      }
    }
  }, [user, data, type]);
  const handleStartDateChange = (e) => {
    // console.log("Start Date", e.target.value.replaceAll("-", ""));

    if (
      e.target.value.replaceAll("-", "") <
      new Date().toISOString().slice(0, 10).replaceAll("-", "")
    ) {
      // console.log(new Date().toISOString().slice(0, 10).replaceAll("-", ""));
      toast.error("Please select a valid date");
      return;
    }
    setStartDate(e.target.value);
  };
  const handleBudgetChange = (e) => {
    setBudget(e.target.value);
  };
  const handleNumberOfVenuesChange = (e) => {
    setNumberOfVenues(e.target.value);
  };

  const handleEndDateChange = (e) => {
    if (
      e.target.value.replaceAll("-", "") <
      new Date().toISOString().slice(0, 10).replaceAll("-", "")
    ) {
      toast.error("Please select a valid date");
      return;
    }
    if (startDate > e.target.value) {
      toast.error("End date should be greater than start date");
      return;
    }
    setEndDate(e.target.value);
  };

  const handleSelectEventType = (event) => {
    setSelectedEventType(event.label);
    handleShowEvents();
  };

  const handleRoomsChange = (e) => {
    setRooms(e.target.value);
  };

  const handleGuestsChange = (e) => {
    const capacity = Math.max(
      data?.capacity?.sitting,
      data?.capacity?.standing
    );
    if (type !== "hotel" && e.target.value > capacity) {
      toast.error(`Max capacity is ${capacity}`);
      return;
    }
    setGuests(e.target.value);
  };

  const additionalNotesChange = (e) => {
    setAdditionalNotes(e.target.value);
  };

  const handleTermsAndConditions = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  const handleShowAutoFill = () => {
    if (showAutoFill) {
      setShowAutoFill(false);
    } else {
      setShowAutoFill(true);
    }
  };

  const handleShowTime = () => {
    if (showTime) {
      setShowTime(false);
    } else {
      setShowTime(true);
    }
  };

  const handleTimeChange = (value) => {
    // Helper function to convert AM/PM time to a 24-hour format in minutes
    const convertTo24HourFormat = (time) => {
      const [hours, minutes] = time.split(":");
      const isPM = time.includes("PM");
      let hourIn24 = parseInt(hours);

      if (isPM && hourIn24 !== 12) {
        hourIn24 += 12;
      }

      if (!isPM && hourIn24 === 12) {
        hourIn24 = 0;
      }

      return hourIn24 * 60 + parseInt(minutes);
    };

    const time24 = convertTo24HourFormat(value);
    if (activeTimeTab === 1) {
      setStartTimeValue(time24);
      setStartTime(value);
      setActiveTimeTab(2);
    } else {
      if (time24 <= startTimeValue) {
        alert("End time should be greater than start time");
        return;
      }
      setEndTimeValue(time24);
      setEndTime(value);
      setShowTime(false);
    }
  };

  const reset = () => {
    setStartTime("");
    setEndTime("");
    setStartDate("");
    setEndDate("");
    setBudget("");
    setNumberOfVenues("");
    setAccomodation(false);
    setFlexibility(false);
    setRooms("");
    setGuests("");
    setSelectedEventType("");
    setAdditionalNotes("");
    setTermsAndConditions(false);
  };
  const handleSubmitEnuiry = async () => {
    setIsLoading(true);
    setError({});
    const enquiryData = {
      customerId: customerId,
      vendorId: data.vendorId,
      venueId: data._id,
      venueName: venueName,
      hotelName: hotelName,
      countryLocation: country,
      deviceCategory: deviceType,
      venueImage: venueImage,
      enquiryType: type,
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      budget: budget,
      numberOfVenues: numberOfVenues,
      accomodation: accomodation,
      flexibility: flexibility,
      rooms: rooms,
      guests: guests,
      eventType: selectedEventType,
      additionalNotes: additionalNotes,
      termsAndConditions: termsAndConditions,
      status: "Not Accepted",
      statusMessage: "Pending",
    };

    if (!validation(enquiryData, type, setError)) {
      // console.log("Error in validation", error);
      toast.error("Please fill all the fields");
      setIsLoading(false);
      return;
    }
    if (!termsAndConditions) {
      toast.error("Please agree to terms and conditions");
      setIsLoading(false);
      return;
    }

    try {
      // Send enquiry to the server
      const response = await fetch(`${base_URL}/api/enquiry/createEnquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(enquiryData),
      });
      console.log("Enquiry response", response);
      if (response.ok) {
        const { customerEmail, subject, html } = await response.json();
        // console.log("Enquiry response", data);
        getEnquiries(customerId);
        setIsOpen(true);
        setIsLoading(false);
        sendEnquiryEmail(customerEmail, subject, html);
      }
      setIsLoading(false);
    } catch (error) {
      // console.log("Error in sending enquiry", error.message);
      toast.error("Error in sending enquiry", error.message);
      setIsLoading(false);
    }
    reset();
    setIsLoading(false);
  };

  const sendEnquiryEmail = async (email, subject, html) => {
    try {
      const response = await fetch(`${base_URL}/api/enquiry/sendEnquiryEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, subject, html }),
      });
      console.log("Email response", response);
    } catch (error) {
      console.log("Error in sending email", error.message);
    }
  };

  const getEnquiries = async () => {
    // console.log("Customer Id", customerId);
    try {
      const response = await fetch(`${base_URL}/api/enquiry/getEnquiry/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId: customerId }),
      });
      if (response.ok && data) {
        const data = await response.json();
        let enquiriesData = data.enquiries;
        enquiriesData.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        if (type === "hotel") {
          enquiriesData = data.enquiries.filter(
            (enquiry) => enquiry.enquiryType === "hotel"
          );
        } else {
          enquiriesData = data.enquiries.filter(
            (enquiry) => enquiry.enquiryType === "venue"
          );
        }
        // console.log("Enquiries", enquiriesData);
        setPreviousEnquiry(enquiriesData);
      }
    } catch (error) {
      console.log("Error in fetching enquiries", error.message);
    }
  };
  useEffect(() => {
    getEnquiries();
  }, []);

  const handleAutoFill = (enquiry) => {
    setStartDate(enquiry.startDate);
    setEndDate(enquiry.endDate);
    setStartTime(enquiry.startTime);
    setEndTime(enquiry.endTime);
    setRooms(enquiry.rooms);
    setNumberOfVenues(enquiry.numberOfVenues);
    setAccomodation(enquiry.accomodation);
    setGuests(enquiry.guests);
    setBudget(enquiry.budget);
    setSelectedEventType(enquiry.eventType);
    setAdditionalNotes(enquiry.additionalNotes);
    setTermsAndConditions(enquiry.termsAndConditions);
    setShowAutoFill(false);
  };

  return (
    <div className="border border-[#DDDDDD]  w-full rounded-md px-4 py-5 mb-4">
      <EnquirySuccessful isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex justify-between items-center relative">
        <h1 className="text-[20px] font-[500] ">Specify your Event</h1>
        <div>
          <p
            className="text-[#FE4747] underline relative text-[16px] font-[500] cursor-pointer"
            onClick={handleShowAutoFill}
          >
            Autofill
          </p>
        </div>
        {showAutoFill && (
          <div
            className="absolute top-full right-0 z-50 overflow-y-auto min-h-[100px] custom-scrollbar"
            ref={autoFillRef}
          >
            <ul
              className="bg-white shadow-xl text-[#717171] shadow-[#D5D5D5] w-[200px]  rounded-md px-2  py-5"
              onClick={() => setShowAutoFill(false)}
            >
              {previousEnquiry?.length > 0
                ? previousEnquiry?.map((enquiry) => (
                    <li
                      key={enquiry._id}
                      className="cursor-pointer hover:bg-[#fffefe] py-2 px-2"
                      onClick={() => handleAutoFill(enquiry)}
                    >
                      {type === "hotel" ? enquiry.hotelName : enquiry.venueName}
                      ({enquiry.eventType})
                    </li>
                  ))
                : "No previous enquiry"}
            </ul>
          </div>
        )}
      </div>

      <div className="flex justify-between items-start gap-4 mt-4">
        <div>
          <label className="md:text-[16px] text-[14px] text-[#222222] ">
            Event Start Date
          </label>
          <input
            type="date"
            onChange={handleStartDateChange}
            value={startDate}
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          />
          <p className="text-[#FE4747] text-sm">{error.startDate}</p>
        </div>
        <div>
          <label className="md:text-[16px] text-[14px] text-[#222222] ">
            Event End Date
          </label>
          <input
            type="date"
            onChange={handleEndDateChange}
            value={endDate}
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          />
          <p className="text-[#FE4747] text-sm">{error.endDate}</p>
        </div>
      </div>
      {type === "venue" && (
        <div className="flex justify-between items-center gap-4 mt-1">
          <div className="w-full">
            <div className="mt-2 text-sm relative">
              <label className="md:text-[16px] text-[14px] text-[#222222] ">
                Event Time
              </label>
              <div
                className="border border-[#DDDDDD] rounded-md py-2 px-3 flex justify-between items-center mt-1 "
                onClick={() => handleShowTime()}
              >
                <p className="md:text-[16px] text-[14px]">
                  {startTime} - {endTime}
                </p>

                <div className="flex justify-between items-center cursor-pointer">
                  {!showTime ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
              </div>
              <p className="text-[#FE4747] text-sm">{error.startTime}</p>
              {showTime && (
                <div className="absolute top-[65px] w-full   left-0 z-[99999]">
                  <div className="bg-white border border-[#DDDDDD] rounded-md w-full cursor-pointer px-1">
                    <div className="flex justify-center gap-5 items-center">
                      {timeTabs.map((tab) => (
                        <div
                          key={tab.id}
                          className={`p-2 cursor-pointer flex justify-center items-center w-[45%] ${
                            activeTimeTab === tab.id
                              ? "text-black border-b-2 border-[#27c2b0] font-semibold"
                              : "text-[#717171] border-b border-[#DDDDDD]"
                          }`}
                          onClick={() => setActiveTimeTab(tab.id)}
                        >
                          {tab.name}
                        </div>
                      ))}
                    </div>
                    <ul className="w-full m-auto overflow-auto  h-[130px]">
                      {time.map((t) => (
                        <li
                          key={t.id}
                          onClick={() => handleTimeChange(t.value)}
                          className="p-2 cursor-pointer hover:bg-[#fff5f5] hover:rounded-md py-2 px-1 mt-1"
                        >
                          {t.value}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="mt-2 flex justify-start items-center gap-2">
        <input
          type="checkbox"
          className="text-xl"
          onChange={() => setFlexibility(!flexibility)}
          checked={flexibility}
        />
        <p className="md:text-[16px] text-[14px] text-[#222222]">
          I am flexible with {type === "venue" ? "date and time" : "dates"}
        </p>
      </div>
      <div className="flex justify-center items-start gap-4 mt-3">
        <div className="w-full">
          <label className="md:text-[16px] text-[14px] text-[#222222] ">
            Number of Guests
          </label>
          <input
            type="number"
            onChange={handleGuestsChange}
            value={guests}
            placeholder={` ${
              data?.capacity?.sitting && data?.capacity?.standing
                ? `Max ${Math.max(
                    data?.capacity?.sitting,
                    data?.capacity?.standing
                  )}`
                : "Enter number of guests"
            }`}
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          />
          <p className="text-[#FE4747] text-sm">{error.guests}</p>
        </div>
        <div className="w-full">
          <label className="md:text-[16px] text-[14px] text-[#222222]">
            {type === "venue" ? "Total Budget" : "Budget Price per night"}
          </label>

          <div className="flex justify-start items-center gap-1 py-[10px] px-2 border border-[#DDDDDD] rounded-md mt-1">
            <p className="text-black text-sm">AED</p>
            <input
              type="number"
              onChange={handleBudgetChange}
              value={budget}
              placeholder="Enter Amount"
              className="w-full border-l border-[#DDDDDD] outline-none text-sm pl-1"
            />
          </div>
          <p className="text-[#FE4747] text-sm">{error.budget}</p>
        </div>
      </div>
      <div className="mt-3 relative" ref={eventTypeRef}>
        <label className="md:text-[16px] text-[14px] text-[#222222]">
          Event Type
        </label>
        <div className="relative w-full mt-1">
          <div
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-[10px] md:text-[16px] text-[14px] flex justify-between items-center"
            onClick={handleShowEvents}
          >
            <label
              htmlFor="eventType"
              className="block text-[16px]  text-gray-400"
            >
              {selectedEventType || "Event Type"}
            </label>
            <span className=" flex items-center pointer-events-none">
              <ArrowDropDownIcon className="text-gray-400" />
            </span>
          </div>
          {showEvents && (
            <div className="absolute top-10 w-full   left-0 z-[99999]">
              <div className="bg-white border border-[#DDDDDD] rounded-md w-full cursor-pointer px-1">
                <ul className="w-full m-auto overflow-auto custom-scrollbar  h-[300px]">
                  {eventTypes.map((event) => (
                    <li
                      key={event.value}
                      onClick={() => handleSelectEventType(event)}
                      value={event.value}
                      className="p-2 cursor-pointer hover:bg-[#fff5f5] hover:rounded-md py-2 px-1 mt-1"
                    >
                      {event.label}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <p className="text-[#FE4747] text-sm">{error.eventType}</p>
        </div>
      </div>
      {type === "hotel" && (
        <>
          <div className="flex justify-center items-start gap-4 mt-3">
            <div className="w-full">
              <label className="md:text-[16px] text-[14px] text-[#222222] ">
                Number of Venues
              </label>
              <input
                type="number"
                onChange={handleNumberOfVenuesChange}
                value={numberOfVenues}
                placeholder="Enter Number of venues"
                className="w-full border border-[#DDDDDD] outline-none rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
              />
              {/* <p className="text-[#FE4747] text-sm">{error.numberOfVenues}</p> */}
            </div>
          </div>
          <div className="mt-2 flex justify-start items-center gap-2">
            <input
              type="checkbox"
              className="text-xl"
              onChange={() => setAccomodation(!accomodation)}
              checked={accomodation}
            />
            <p className="md:text-[16px] text-[14px] text-[#222222]">
              I am also looking for accomodation
            </p>
          </div>
          {accomodation && (
            <div className="flex justify-center items-start gap-4 mt-3">
              <div className="w-full">
                <label className="md:text-[16px] text-[14px] text-[#222222] ">
                  Number of Rooms
                </label>
                <input
                  type="number"
                  onChange={handleRoomsChange}
                  value={rooms}
                  placeholder="Enter Number of rooms"
                  className="w-full border border-[#DDDDDD] outline-none rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
                />
                {/* <p className="text-[#FE4747] text-sm">{error.rooms}</p> */}
              </div>
            </div>
          )}
        </>
      )}

      <div className="mt-3">
        <label className="md:text-[16px] text-[14px] text-[#222222] ">
          Additional Notes
        </label>
        <textarea
          className="w-full border border-[#DDDDDD] rounded-md px-2 py-2 md:text-[16px] text-[14px] mt-1"
          placeholder="Enter your input"
          onChange={additionalNotesChange}
          value={additionalNotes}
          rows="4"
        ></textarea>
      </div>
      <div className="mt-2 flex justify-start items-center gap-2">
        <input
          type="checkbox"
          className="text-xl text-[#DDDDDD]"
          onChange={handleTermsAndConditions}
          checked={termsAndConditions}
        />
        <p className="md:text-[16px] text-[14px] text-black">
          I agree with all the{" "}
          <a className="underline cursor-pointer" href="/terms&conditions">
            terms & conditions
          </a>
        </p>
      </div>
      <p className="text-[#FE4747] text-sm">{error.termsAndConditions}</p>
      <div className="mt-2 flex justify-start items-center gap-2">
        <button
          className="bg-[#FE4747] text-white px-4 py-2 text-[16px] w-full rounded-md"
          disabled={isLoading}
          onClick={handleSubmitEnuiry}
        >
          {isLoading ? "Sending Enquiry" : "Send Enquiry"}
        </button>
      </div>
    </div>
  );
}
