import moment from "moment";
import React, { useContext, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const OffersAndPackages = ({ data = [] }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const filteredOffers = data?.filter((offer) => {
    // Use moment for consistent date handling
    const currentDate = moment().startOf("day");
    const endDate = offer?.endDate
      ? moment(offer.endDate).startOf("day")
      : null;

    // If there's no end date, include the offer
    if (!endDate) return true;

    // Only include offers where end date is today or in the future
    return endDate.isSameOrAfter(currentDate);
  });

  return (
    <div className="bg-white rounded-lg mb-2 lg:w-3/4 w-full mx-auto pt-10 pb-6 ">
      {filteredOffers &&
        filteredOffers.map((offer, index) => (
          <>
            <h2 className="text-2xl font-bold mb-4">Offers & Packages </h2>
            <div key={index} className="mb-4">
              <div
                className={`cursor-pointer bg-gray-100 p-4 ${
                  expandedIndex === index ? "rounded-t-lg" : "rounded-lg"
                } flex justify-between items-center`}
                onClick={() => toggleExpand(index)}
              >
                <h3 className="text-xl font-semibold">
                  {offer.title || "No Title"}
                </h3>
                <span>
                  {expandedIndex === index ? (
                    <MdKeyboardArrowUp className="text-3xl" />
                  ) : (
                    <MdKeyboardArrowDown className="text-3xl" />
                  )}
                </span>
              </div>
              {expandedIndex === index && (
                <div
                  className={`p-4 border-b ${
                    expandedIndex === index ? "rounded-b-lg" : ""
                  }  bg-gray-100`}
                >
                  <div className="flex space-x-2 lg:w-1/2 w-full py-2 items-start justify-between">
                    {offer.startDate && (
                      <div className="mb-2 flex flex-col">
                        <strong>From:</strong>{" "}
                        {moment(offer.startDate).format("DD MMM YYYY")}
                      </div>
                    )}
                    {offer.endDate && (
                      <div className="mb-2  flex flex-col">
                        <strong>To:</strong>{" "}
                        {moment(offer.endDate).format("DD MMM YYYY")}
                      </div>
                    )}
                  </div>
                  {offer.description && (
                    <>
                      <strong>Offer Description</strong>
                      <p className="mt-1">{offer.description}</p>
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        ))}
    </div>
  );
};

export default OffersAndPackages;
