"use client";
import React from 'react';

const VenuModel = ({ children, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-[#000000B2]" onClick={onClose}></div>
            <div className="w-full h-full flex justify-center items-center relative">
                {children}
            </div>
        </div> 
    );
};

export default VenuModel;
