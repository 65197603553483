// src/components/Input.js
import React from "react";

const Input = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  error,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <div className="mb-3">
      <label className="block text-start text-secondary text-sm font-outfitt mb-2">
        {label}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        className={`appearance-none border ${
          error ? "border-red-500" : "border-lightGray"
        } rounded-lg w-full py-3 px-3 text-sm text-secondary leading-tight focus:outline-none focus:shadow-outline`}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
    </div>
  );
};

export default Input;
