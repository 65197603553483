import a1 from "../../../assets/icons/a1.svg";
import a2 from "../../../assets/icons/a2.svg";
import a3 from "../../../assets/icons/a3.svg";
import a4 from "../../../assets/icons/a4.svg";
export const eventTypes = [
  {
    id: 1,
    title: "Private",
    description:
      "Private hire is a space within venue that is exclusively reserved for your event",
    icon: a1,

    // (

    //     <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M1.00909 14C1.26607 6.94172 6.94172 1.26607 14 1.00909V14H1.00909Z" stroke="#C2C2C2" />
    //         <path d="M14 28.9909C6.94172 28.7339 1.26607 23.0583 1.00909 16H14V28.9909Z" stroke="#C2C2C2" />
    //         <path d="M16 1.00909C23.0583 1.26607 28.7339 6.94172 28.9909 14H16V1.00909Z" stroke="#C2C2C2" />
    //         <path d="M16.5 16.5H28.4621C27.9766 22.8824 22.8824 27.9766 16.5 28.4621V16.5Z" stroke="#222222" strokeWidth="2" />
    //     </svg>

    // ),
  },
  {
    id: 2,
    title: "Whole venue",
    description: "The entire venue is exclusively available for your event.",
    icon: a2,
    // (
    //     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M2.53789 14.5C3.02341 8.11759 8.11759 3.02341 14.5 2.53789V14.5H2.53789Z" stroke="#222222" strokeWidth="2" />
    //         <path d="M14.5 29.4621C8.11759 28.9766 3.02341 23.8824 2.53789 17.5H14.5V29.4621Z" stroke="#222222" strokeWidth="2" />
    //         <path d="M17.5 2.53789C23.8824 3.02341 28.9766 8.11759 29.4621 14.5H17.5V2.53789Z" stroke="#222222" strokeWidth="2" />
    //         <path d="M17.5 17.5H29.4621C28.9766 23.8824 23.8824 28.9766 17.5 29.4621V17.5Z" stroke="#222222" strokeWidth="2" />
    //     </svg>

    // ),
  },
  {
    id: 3,
    title: "Semi-private",
    description:
      "Semi-private hire involves booking a section of a venue that is partially secluded or separated, allowing for some privacy while other events or guests may also be present.",
    icon: a3,
    // (
    //     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M2.00909 15C2.26607 7.94172 7.94172 2.26607 15 2.00909V15H2.00909Z" stroke="#C2C2C2" />
    //         <path d="M15 29.9909C7.94172 29.7339 2.26607 24.0583 2.00909 17H15V29.9909Z" stroke="#C2C2C2" />
    //         <path d="M17 2.00909C24.0583 2.26607 29.7339 7.94172 29.9909 15H17V2.00909Z" stroke="#C2C2C2" />
    //         <path d="M16.5 18.0833C16.9281 18.0833 17.2934 17.8143 17.436 17.436C17.8143 17.2934 18.0833 16.9281 18.0833 16.5H21.9167C21.9167 17.0523 22.3644 17.5 22.9167 17.5H24.0833C24.6356 17.5 25.0833 17.0523 25.0833 16.5H28.9167C28.9167 16.9509 29.2151 17.3321 29.6251 17.4568C29.7866 17.7376 30.0809 17.9337 30.4251 17.9568C30.3353 18.8256 30.166 19.6708 29.9247 20.4851C29.4167 20.314 28.8607 20.575 28.6703 21.0806C28.5701 21.3467 28.4614 21.6087 28.3445 21.8662C28.1213 22.3582 28.3295 22.9361 28.8095 23.1747C28.397 23.9339 27.9163 24.6506 27.3756 25.3167C26.9718 24.964 26.3579 24.9922 25.9884 25.3866C25.7944 25.5936 25.5936 25.7944 25.3866 25.9884C24.9922 26.3579 24.964 26.9718 25.3167 27.3756C24.6506 27.9163 23.9339 28.397 23.1747 28.8095C22.9361 28.3295 22.3582 28.1213 21.8662 28.3445C21.6087 28.4614 21.3467 28.5701 21.0806 28.6703C20.575 28.8607 20.314 29.4167 20.4851 29.9247C19.6708 30.166 18.8256 30.3353 17.9568 30.4251C17.9337 30.0809 17.7376 29.7866 17.4568 29.6252C17.3321 29.2151 16.9509 28.9167 16.5 28.9167V25.0833C17.0523 25.0833 17.5 24.6356 17.5 24.0833V22.9167C17.5 22.3644 17.0523 21.9167 16.5 21.9167V18.0833Z" stroke="#222222" strokeWidth="2" stroke-linecap="round" stroke-dasharray="1 5" />
    //     </svg>

    // ),
  },
  {
    id: 4,
    title: "Shared space",
    description: "A non-exclusive, common area with open access for everyone.",
    icon: a4,
    // (
    //     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M15.5 13.9167C15.0719 13.9167 14.7066 14.1857 14.564 14.564C14.1857 14.7066 13.9167 15.0719 13.9167 15.5L10.0833 15.5C10.0833 14.9477 9.63562 14.5 9.08333 14.5L7.91667 14.5C7.36438 14.5 6.91667 14.9477 6.91667 15.5L3.08333 15.5C3.08333 15.0491 2.78494 14.6679 2.37485 14.5432C2.21338 14.2624 1.91911 14.0663 1.57488 14.0432C1.66471 13.1744 1.83398 12.3292 2.07532 11.5149C2.58325 11.686 3.13929 11.425 3.32968 10.9194C3.42989 10.6533 3.53859 10.3913 3.65545 10.1338C3.87873 9.64183 3.67049 9.0639 3.1905 8.82533C3.60304 8.06614 4.08375 7.34938 4.62444 6.68326C5.02824 7.03599 5.64208 7.00779 6.01157 6.61345C6.20561 6.40635 6.40635 6.20561 6.61345 6.01157C7.00779 5.64207 7.03599 5.02824 6.68326 4.62443C7.34939 4.08375 8.06614 3.60304 8.82534 3.1905C9.06391 3.67049 9.64183 3.87873 10.1338 3.65545C10.3913 3.53859 10.6533 3.42989 10.9194 3.32967C11.425 3.13929 11.686 2.58325 11.5149 2.07532C12.3292 1.83398 13.1744 1.66471 14.0432 1.57488C14.0663 1.91911 14.2624 2.21337 14.5432 2.37485C14.6679 2.78493 15.0491 3.08333 15.5 3.08333L15.5 6.91667C14.9477 6.91667 14.5 7.36438 14.5 7.91667L14.5 9.08333C14.5 9.63562 14.9477 10.0833 15.5 10.0833L15.5 13.9167Z" stroke="#222222" strokeWidth="2" stroke-linecap="round" stroke-dasharray="1 5" />
    //         <path d="M13.9167 16.5C13.9167 16.9281 14.1857 17.2934 14.564 17.436C14.7066 17.8143 15.0719 18.0833 15.5 18.0833L15.5 21.9167C14.9477 21.9167 14.5 22.3644 14.5 22.9167L14.5 24.0833C14.5 24.6356 14.9477 25.0833 15.5 25.0833L15.5 28.9167C15.0491 28.9167 14.6679 29.2151 14.5432 29.6251C14.2624 29.7866 14.0663 30.0809 14.0432 30.4251C13.1744 30.3353 12.3292 30.166 11.5149 29.9247C11.686 29.4167 11.425 28.8607 10.9194 28.6703C10.6533 28.5701 10.3913 28.4614 10.1338 28.3445C9.64183 28.1213 9.0639 28.3295 8.82533 28.8095C8.06614 28.397 7.34938 27.9163 6.68326 27.3756C7.03599 26.9718 7.00779 26.3579 6.61345 25.9884C6.40635 25.7944 6.20561 25.5936 6.01157 25.3866C5.64207 24.9922 5.02824 24.964 4.62443 25.3167C4.08375 24.6506 3.60304 23.9339 3.1905 23.1747C3.67049 22.9361 3.87873 22.3582 3.65545 21.8662C3.53859 21.6087 3.42989 21.3467 3.32967 21.0806C3.13929 20.575 2.58325 20.314 2.07532 20.4851C1.83398 19.6708 1.66471 18.8256 1.57488 17.9568C1.91911 17.9337 2.21337 17.7376 2.37485 17.4568C2.78493 17.3321 3.08333 16.9509 3.08333 16.5L6.91667 16.5C6.91667 17.0523 7.36438 17.5 7.91667 17.5L9.08333 17.5C9.63562 17.5 10.0833 17.0523 10.0833 16.5L13.9167 16.5Z" stroke="#222222" strokeWidth="2" stroke-linecap="round" stroke-dasharray="1 5" />
    //         <path d="M18.0833 15.5C18.0833 15.0719 17.8143 14.7066 17.436 14.564C17.2934 14.1857 16.9281 13.9167 16.5 13.9167L16.5 10.0833C17.0523 10.0833 17.5 9.63562 17.5 9.08333L17.5 7.91667C17.5 7.36438 17.0523 6.91667 16.5 6.91667L16.5 3.08333C16.9509 3.08333 17.3321 2.78494 17.4568 2.37485C17.7376 2.21338 17.9337 1.91911 17.9568 1.57488C18.8256 1.66471 19.6708 1.83398 20.4851 2.07532C20.314 2.58325 20.575 3.13929 21.0806 3.32967C21.3467 3.42989 21.6087 3.53859 21.8662 3.65545C22.3582 3.87873 22.9361 3.67049 23.1747 3.1905C23.9339 3.60304 24.6506 4.08375 25.3167 4.62443C24.964 5.02824 24.9922 5.64207 25.3866 6.01157C25.5936 6.20561 25.7944 6.40635 25.9884 6.61345C26.3579 7.00779 26.9718 7.03599 27.3756 6.68326C27.9163 7.34938 28.397 8.06614 28.8095 8.82533C28.3295 9.0639 28.1213 9.64183 28.3445 10.1338C28.4614 10.3913 28.5701 10.6533 28.6703 10.9194C28.8607 11.425 29.4167 11.686 29.9247 11.5149C30.166 12.3292 30.3353 13.1744 30.4251 14.0432C30.0809 14.0663 29.7866 14.2624 29.6252 14.5432C29.2151 14.6679 28.9167 15.0491 28.9167 15.5L25.0833 15.5C25.0833 14.9477 24.6356 14.5 24.0833 14.5L22.9167 14.5C22.3644 14.5 21.9167 14.9477 21.9167 15.5L18.0833 15.5Z" stroke="#222222" strokeWidth="2" stroke-linecap="round" stroke-dasharray="1 5" />
    //         <path d="M16.5 18.0833C16.9281 18.0833 17.2934 17.8143 17.436 17.436C17.8143 17.2934 18.0833 16.9281 18.0833 16.5H21.9167C21.9167 17.0523 22.3644 17.5 22.9167 17.5H24.0833C24.6356 17.5 25.0833 17.0523 25.0833 16.5H28.9167C28.9167 16.9509 29.2151 17.3321 29.6251 17.4568C29.7866 17.7376 30.0809 17.9337 30.4251 17.9568C30.3353 18.8256 30.166 19.6708 29.9247 20.4851C29.4167 20.314 28.8607 20.575 28.6703 21.0806C28.5701 21.3467 28.4614 21.6087 28.3445 21.8662C28.1213 22.3582 28.3295 22.9361 28.8095 23.1747C28.397 23.9339 27.9163 24.6506 27.3756 25.3167C26.9718 24.964 26.3579 24.9922 25.9884 25.3866C25.7944 25.5936 25.5936 25.7944 25.3866 25.9884C24.9922 26.3579 24.964 26.9718 25.3167 27.3756C24.6506 27.9163 23.9339 28.397 23.1747 28.8095C22.9361 28.3295 22.3582 28.1213 21.8662 28.3445C21.6087 28.4614 21.3467 28.5701 21.0806 28.6703C20.575 28.8607 20.314 29.4167 20.4851 29.9247C19.6708 30.166 18.8256 30.3353 17.9568 30.4251C17.9337 30.0809 17.7376 29.7866 17.4568 29.6252C17.3321 29.2151 16.9509 28.9167 16.5 28.9167V25.0833C17.0523 25.0833 17.5 24.6356 17.5 24.0833V22.9167C17.5 22.3644 17.0523 21.9167 16.5 21.9167V18.0833Z" stroke="#222222" strokeWidth="2" stroke-linecap="round" stroke-dasharray="1 5" />
    //     </svg>

    // ),
  },
];
