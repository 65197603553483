import React from "react";
import facebook from "../../assets2/img/facebook.png";
import watsapp from "../../assets2/img/watsapp.png";
import twitter from "../../assets2/img/twitter.svg";
import gmail from "../../assets2/img/gmail.png";
import { RxCross2 } from "react-icons/rx";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";

function SocialMediaModal({ isOpen, onClose, shareLink }) {
  const copyLink = () => {
    navigator.clipboard.writeText(shareLink);
    toast.success("Link copied to clipboard");
  };
  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={() => onClose()}
    >
      <div className="bg-white rounded-lg py-4 px-6 w-[380px]  space-y-3 h-72 flex flex-col justify-center items-center  ">
        <div className="flex justify-center items-center relative w-full ">
          <p className="text-center text-[24px] mb-4">Share your list</p>
          <RxCross2
            size={22}
            className="absolute top-1 right-0 cursor-pointer text-dark"
            onClick={onClose}
          />
        </div>

        <div className="grid grid-cols-2 gap-5">
          <div
            className="flex justify-center items-center gap-3 px-3 py-2 border border-gray rounded-md cursor-pointer"
            onClick={() => copyLink()}
          >
            <MdContentCopy size={20} />
            <span className="text-[14px]">Copy Link</span>
          </div>
          <div
            className="flex justify-center items-center gap-3 px-3 py-2 border border-gray rounded-md cursor-pointer"
            onClick={() => {
              window.open(
                `mailto:findmyvenue@gmail.com?subject=Check out this Venue &body=${shareLink}`
              );
            }}
          >
            <img src={gmail} alt="" className="h-6 w-6" />
            <span className="text-[14px]">Gmail</span>
          </div>
          <div
            className="flex justify-center items-center gap-3 px-3 py-2 border border-gray rounded-md cursor-pointer"
            onClick={() => {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`
              );
            }}
          >
            <img src={facebook} alt="" className="h-6 w-6" />
            <span className="text-[14px]">Facebook</span>
          </div>
          <div
            className="flex justify-center items-center gap-3 px-3 py-2 border border-gray rounded-md cursor-pointer"
            onClick={() => {
              window.open(`https://api.whatsapp.com/send?text=${shareLink}`);
            }}
          >
            <img src={watsapp} alt="" className="h-6 w-6" />
            <span className="text-[14px]">Whatsapp</span>
          </div>
          <div
            className="flex justify-center items-center gap-3 px-3 py-2 border border-gray rounded-md cursor-pointer"
            onClick={() => {
              window.open(`https://twitter.com/intent/tweet?url=${shareLink}`);
            }}
          >
            <img src={twitter} alt="" className="h-6 w-6" />
            <span className="text-[14px]">Twitter</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialMediaModal;
