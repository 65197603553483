import React, { useState } from "react";

const AboutThisHotel = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  let view;
  let style = [];
  if (data) {
    view = data?.additionalDetails?.venueView?.join(", ");
    style = [
      ...data?.additionalDetails?.venueStyle.traditionalStyle,
      ...data?.additionalDetails?.venueStyle.culturalRegionalStyle,
      ...data?.additionalDetails?.venueStyle.eclecticStyle,
      ...data?.additionalDetails?.venueStyle.modernContemporaryStyle,
    ].join(", ");
  }

  const handleViewBrochure = () => {
    if (data?.UploadDocuments?.brochure?.length > 0) {
      window.open(data.UploadDocuments.brochure[0], "_blank");
    }
  };

  return (
    <div className="py-5 mx-auto w-full rounded-lg">
      <div className="bg-white rounded-lg ">
        <h2 className="md:text-2xl text-[20px] text-[#222222] font-semibold md:mb-4 mb-2">
          About This Venue
        </h2>
        {/* Conditionally render the "View Brochure" button */}
        {data?.UploadDocuments?.brochure?.length > 0 && (
          <button
            onClick={handleViewBrochure}
            className="text-primary font-medium mb-4"
          >
            View Brochure
          </button>
        )}
        {/* <p
          className={`text-[#222222] md:text-[20px] text-[14px] font-normal md:mb-4 mb-1 ${
            showMore ? "" : "line-clamp-3"
          }`}
        >
          {data?.basicDetails?.venueDescription}
        </p> */}
        <pre
          className={`whitespace-pre-line text-[#222222] !md:text-[20px] !text-[14px] font-outfit !font-normal md:mb-4 mb-1 ${
            showMore ? "" : "line-clamp-3"
          }`}
        >
          {data?.basicDetails?.venueDescription}
        </pre>
        <button
          onClick={() => setShowMore(!showMore)}
          className="text-primary md:text-md text-[14px] font-medium"
        >
          {showMore ? "Show Less" : "Show More"}
        </button>

        <div className="md:grid grid-cols-2 gap-4 md:p-8 p-4 w-full rounded-xl bg-[#F7F7F7] mt-6">
          <div className="col-span-1 md:border-r border-gray-400 pr-4 md:mb-0 mb-2">
            <h3 className="md:text-[16px] text-[#222222] text-[14px] font-medium md:mb-2 mb-[2px]">
              Style
            </h3>
            <span className="text-[#222222] text-sm">{style}</span>
          </div>
          <div className="col-span-1 md:pl-4">
            <h3 className="md:text-[16px] text-[14px] text-[#222222] font-medium md:mb-2 mb-[2px]">
              View
            </h3>
            <span className=" text-[#222222] text-sm">{view}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutThisHotel;
