import React, { useContext, useEffect, useState } from "react";
import ToggleSwitch from "../../shared/common/custom-toggle";
import AddFeatureModal from "./add-feature-form";
import { HotelContext } from "../../../context/HotelContext";
import Modal from "../../shared/custom-modal/custom-modal";
import * as FaIcons from "react-icons/fa";

const defaultBusinessFeatures = [
  {
    name: "Business Centre",
    icon: <img src="/business-features/business.svg" alt="business" />,
    isDefault: true,
  },
  {
    name: "Video Conference",
    icon: (
      <img
        src="/business-features/video-conference.svg"
        alt="video conference"
      />
    ),
    isDefault: true,
  },
  {
    name: "VIP Services",
    icon: <img src="/business-features/vip.svg" alt="VIP services" />,
    isDefault: true,
  },
  {
    name: "AV Equipment",
    icon: (
      <img src="/business-features/av-equipements.svg" alt="AV equipment" />
    ),
    isDefault: true,
  },
  {
    name: "Onsite AV Staff",
    icon: <img src="/business-features/support.svg" alt="Onsite AV staff" />,
    isDefault: true,
  },
  {
    name: "High Speed Internet (100 Mbps)",
    icon: <img src="/business-features/internet.svg" alt="internet" />,
    isDefault: true,
  },
];

const BusinessFeatures = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [features, setFeatures] = useState(defaultBusinessFeatures);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureToEdit, setFeatureToEdit] = useState(null);

  const businessFeatures = formData?.amenities?.businessFeatures || {};
  const businessFeaturesIcon = formData?.amenities?.businessFeatures || {};

  useEffect(() => {
    const updatedFeatures = Object.keys(businessFeatures)?.map((feature) => ({
      name: feature,
      icon: businessFeaturesIcon[feature] || "FaAccusoft", // Fallback icon
    }));

    setFeatures((prevFeatures) => {
      const existingFeaturesMap = new Map(
        prevFeatures.map((item) => [item.name, item])
      );
      const combinedFeatures = [...prevFeatures];

      updatedFeatures.forEach((newFeature) => {
        if (!existingFeaturesMap.has(newFeature.name)) {
          combinedFeatures.push(newFeature);
        }
      });

      return combinedFeatures;
    });
  }, [businessFeatures, businessFeaturesIcon]);

  const handleToggle = (featureName) => {
    const updatedFeatures = {
      ...businessFeatures,
      [featureName]: !businessFeatures[featureName],
    };

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        businessFeatures: updatedFeatures,
      },
    }));
  };

  const handleAddFeature = (newFeature) => {
    if (featureToEdit) {
      const updatedFeatures = features.map((feature) =>
        feature.name == featureToEdit.name ? newFeature : feature
      );
      setFeatures(updatedFeatures);
      setFeatureToEdit(null);
    } else {
      setFeatures((prevFeatures) => [...prevFeatures, newFeature]);
    }

    setFormData((prevData) => {
      // Ensure previous data and amenities are defined
      const amenities = prevData?.amenities || {};
      const businessFeatures = amenities?.businessFeatures || {};
      const businessFeaturesIcons = amenities?.businessFeaturesIcons || {};

      return {
        ...prevData,
        amenities: {
          ...amenities,
          businessFeaturesIcons: {
            ...businessFeaturesIcons,
            [newFeature.name]: newFeature.name,
          },
          // storing icon name with the typed name
          businessFeatures: {
            ...businessFeatures,
            [newFeature.name]: newFeature.icon,
          },
        },
      };
    });
  };

  const handleEditFeature = (feature) => {
    setFeatureToEdit(feature);
    setIsModalOpen(true);
  };

  const handleDeleteFeature = (featureName) => {
    const updatedFeatures = features.filter(
      (feature) => feature.name !== featureName
    );
    setFeatures(updatedFeatures);

    const updatedBusinessFeatures = { ...businessFeatures };
    delete updatedBusinessFeatures[featureName];

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        businessFeatures: updatedBusinessFeatures,
      },
    }));
  };
  const handleUpdateFeature = (updatedFeature) => {
    // Create shallow copies for updates
    const updatedRoomFeatures = { ...businessFeatures };

    // Preserve the existing icon if only the name is updated
    if (featureToEdit?.name !== updatedFeature?.name) {
      const existingIcon = updatedRoomFeatures[featureToEdit?.name];

      // Remove the old name
      delete updatedRoomFeatures[featureToEdit?.name];

      // Add the new name with the preserved icon
      updatedRoomFeatures[updatedFeature?.name] =
        existingIcon || updatedFeature?.icon;
    }

    // Check if the icon has changed
    if (featureToEdit?.icon !== updatedFeature?.icon) {
      updatedRoomFeatures[updatedFeature?.name] = updatedFeature?.icon;
    }

    // Update the features list
    const updatedFeatures = features?.map((feature) =>
      feature?.name === featureToEdit?.name ? updatedFeature : feature
    );

    setFeatures(updatedFeatures);

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData?.amenities,
        businessFeatures: updatedRoomFeatures,
      },
    }));

    // Clear the feature being edited
    setFeatureToEdit(null);
  };
  const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent size={32} /> : null;
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="flex justify-between items-center w-full pb-5">
          <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
            Business Features{" "}
            <span className="text-red-500 ml-1 text-[15px]">*</span>
          </h2>
          <button
            onClick={() => {
              setFeatureToEdit(null);
              setIsModalOpen(true);
            }}
            className="text-primary font-medium text-base underline"
          >
            Add Custom Feature
          </button>
        </div>
        {features &&
          features.map((feature, index) => {
            const isDefaultFeature = defaultBusinessFeatures.find(
              (defaultFeature) => defaultFeature?.name === feature?.name
            );
            return (
              <div
                key={index}
                className="flex py-1 items-center justify-between w-full lg:w-[60%] mb-4"
              >
                <div className="flex items-center w-[80%] justify-between">
                  <div className="flex items-center space-x-4">
                    {isDefaultFeature ? (
                      feature.icon
                    ) : (
                      <span className="text-2xl mr-2">
                        {renderIcon(feature.icon)}
                      </span>
                    )}
                    <span
                      className="text-base text-darkGray"
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        maxWidth: "20ch",
                      }}
                    >
                      {feature.name}
                    </span>
                  </div>
                  <ToggleSwitch
                    isOn={businessFeatures[feature.name] || false}
                    handleToggle={() => handleToggle(feature.name)}
                  />
                </div>
                {!isDefaultFeature && (
                  <div className="flex items-center gap-1">
                    <button
                      className="text-sm text-[#1252B1] hover:text-red-600"
                      onClick={() => handleEditFeature({ ...feature, index })}
                    >
                      <img src="/edit.svg" alt="edit" />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-600"
                      onClick={() => handleDeleteFeature(feature.name)}
                    >
                      <img src="/trash.svg" alt="trash" />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        {formData?.amenities?.errors && (
          <p className="text-red-500 text-base pt-1">
            {formData?.amenities?.errors.businessFeatures}
          </p>
        )}
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <AddFeatureModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onSave={handleAddFeature}
          featureToEdit={featureToEdit}
          onUpdate={handleUpdateFeature}
        />
      </Modal>
    </div>
  );
};

export default BusinessFeatures;
