/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layout/dashboard-layout";
import { useUser } from "../../context/user";
import FunnelReport from "../../components/Reports/FunnelReport";
import CountryReport from "../../components/Reports/CountryReport";
import EventTypeReport from "../../components/Reports/EventTypeReport";
import EnquiryStatusReport from "../../components/Reports/EnquiryStatusReport";
import TotalEnquiryReport from "../../components/Reports/TotalEnquiryReport";
import Avgbudgetreport from "../../components/Reports/Avgbudgetreport";
import AvgResponsereport from "../../components/Reports/AvgResponseReports";
import SaveAsFavReport from "../../components/Reports/SaveAsFavReport";
import AvgTimeSPendReport from "../../components/Reports/AvgTimeSpendReport";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { base_URL } from "../../config/config";
import { fetchUserById } from "../../api/loggedUser";

export default function Reports() {
  const {
    setDashboardUser,
    funnelReport,
    setFunnelReport,
    countryReport,
    setCountryReport,
    eventTypeReport,
    setEventTypeReport,
    eventStatusReport,
    setEventStatusReport,
    totalEnquiryReport,
    setTotalEnquiryReport,
    averageBudgetReport,
    setAverageBudgetReport,
    averageResponseReport,
    setAverageResponseReport,
    saveAsFav,
    setSaveAsFav,
    avgTimeSpendReport,
    setAvgTimeSpendReport,
    activeReports,
    setActiveReports,
  } = useUser();
  // for a team member invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const [user, setUser] = useState(null);
  const [dashboardPath, setDashboardPath] = useState("/dashboard");
  // Fetch all Venue name of a vendor
  const { data: venueNames } = useQuery({
    queryKey: ["venueNames", vendorId],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/enquiries/venues-for-reports`,
        {
          params: { vendorId },
        }
      );
      return response?.data || [];
    },
    enabled: !!vendorId,
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        let response;
        response = await fetchUserById(vendorId);
        setUser(response?.vendor);
        // Check if user has any listings
        const hasVenueListing = response?.vendor?.venueForms?.length > 0;
        const hasRestaurantListing =
          response?.vendor?.RestaurantForm?.length > 0;
        const hasHotelListing = response?.vendor?.Hotel?.length > 0;

        // Set path to vendor dashboard if admin AND has required listings
        if (
          response?.vendor?.isAdmin &&
          (hasVenueListing || hasRestaurantListing) &&
          hasHotelListing
        ) {
          setDashboardPath("/vendor-dashboard");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);
  return (
    <DashboardLayout>
      <div className="flex w-full py-4 justify-center">
        <div className="w-[90%] flex flex-col gap-5">
          {/* header Reports show when no reports tab is active */}
          {!funnelReport &&
            !countryReport &&
            !eventTypeReport &&
            !eventStatusReport &&
            !totalEnquiryReport &&
            !averageBudgetReport &&
            !averageResponseReport &&
            !saveAsFav &&
            !avgTimeSpendReport && (
              <div className="flex w-full justify-between items-center">
                <div className="flex flex-col gap-3">
                  <div className="flex items-center gap-2">
                    <Link
                      to={dashboardPath}
                      className="text-[#FE4747] text-[10px] md:text-[16px]"
                    >
                      Dashboard
                    </Link>{" "}
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.813111 11.8536C0.617849 11.6583 0.617849 11.3417 0.813111 11.1464L5.79289 6.16667L0.813111 1.18689C0.617849 0.991625 0.617849 0.675041 0.813111 0.479779C1.00837 0.284517 1.32496 0.284517 1.52022 0.479779L6.85355 5.81311C7.04881 6.00838 7.04881 6.32496 6.85355 6.52022L1.52022 11.8536C1.32496 12.0488 1.00837 12.0488 0.813111 11.8536Z"
                        fill="#222222"
                      />
                    </svg>
                    <p
                      onClick={() => setActiveReports(true)}
                      className={`cursor-pointer ${
                        activeReports
                          ? "text-[#FE4747] text-[10px] md:text-[16px]"
                          : "text-black text-[10px] md:text-[16px]"
                      }`}
                    >
                      Report List
                    </p>
                  </div>
                  <h2 className="text-xl font-medium md:text-2xl md:font-semibold">
                    Reports Overview
                  </h2>
                </div>
              </div>
            )}

          {/* Reports Body show when no reports tab is active */}
          {!funnelReport &&
            !countryReport &&
            !eventTypeReport &&
            !eventStatusReport &&
            !totalEnquiryReport &&
            !averageBudgetReport &&
            !averageResponseReport &&
            !saveAsFav &&
            !avgTimeSpendReport && (
              <div className="flex flex-col font-outfit ">
                <div className="bg-[#F4F4F4] border-t-xl  shadow-xl p-4 ">
                  <h3 className="text-[#222222] text-[16px] font-medium">
                    Report Name
                  </h3>
                </div>
                <div
                  onClick={() => setFunnelReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Performance Funnel Report
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setTotalEnquiryReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Total Enquiry
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setEventStatusReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Enquiry Status
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setCountryReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Most Enquiries Received by Countries
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setEventTypeReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Total Enquiries by Event Type
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setAverageResponseReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Response Rate
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setAverageBudgetReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Average Budget
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setSaveAsFav(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Saved as Favourite
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div
                  onClick={() => setAvgTimeSpendReport(true)}
                  className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer"
                >
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Average Time Spent
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                {/* <div className="flex justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer">
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Overall rating
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div>
                <div className="flex rounded-b-xl justify-between bg-white border-b border-b-[#EBEBEB]   shadow-md p-4 cursor-pointer">
                  <h3 className="text-[#222222] font-normal text-[12px] md:text-[14px]">
                    Reviews
                  </h3>
                  <img
                    src="/rightarrow.svg"
                    alt=""
                    className="active:opacity-55"
                  />
                </div> */}
              </div>
            )}

          {/* Funnel Report Active (Customer Side)*/}
          {funnelReport && (
            <FunnelReport
              funnelReport={funnelReport}
              setFunnelReport={setFunnelReport}
              venueNames={venueNames}
            />
          )}
          {/* country report Active */}
          {countryReport && (
            <CountryReport
              countryReport={countryReport}
              setCountryReport={setCountryReport}
            />
          )}
          {/* event type report active */}
          {eventTypeReport && (
            <EventTypeReport
              eventTypeReport={eventTypeReport}
              setEventTypeReport={setEventTypeReport}
              venueNames={venueNames}
            />
          )}
          {/* Enquiry Status Report active */}
          {eventStatusReport && (
            <EnquiryStatusReport
              eventStatusReport={eventStatusReport}
              setEventStatusReport={setEventStatusReport}
              venueNames={venueNames}
            />
          )}
          {/* Total Enquiry Report */}
          {totalEnquiryReport && (
            <TotalEnquiryReport
              totalEnquiryReport={totalEnquiryReport}
              setTotalEnquiryReport={setTotalEnquiryReport}
              venueNames={venueNames}
            />
          )}

          {/* Avg Budget Report */}
          {averageBudgetReport && (
            <Avgbudgetreport
              averageBudgetReport={averageBudgetReport}
              setAverageBudgetReport={setAverageBudgetReport}
              venueNames={venueNames}
            />
          )}

          {/* Average Respone Report */}
          {averageResponseReport && (
            <AvgResponsereport
              averageResponseReport={averageResponseReport}
              setAverageResponseReport={setAverageResponseReport}
              venueNames={venueNames}
            />
          )}

          {/* Saved as favourite (Customer Side)*/}
          {saveAsFav && (
            <SaveAsFavReport
              saveAsFav={saveAsFav}
              setSaveAsFav={setSaveAsFav}
              venueNames={venueNames}
            />
          )}

          {/* Avg Time Spend Report (Customer Side) */}
          {avgTimeSpendReport && (
            <AvgTimeSPendReport
              avgTimeSpendReport={avgTimeSpendReport}
              setAvgTimeSpendReport={setAvgTimeSpendReport}
              venueNames={venueNames}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
