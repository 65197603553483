import a1 from "../../assets/icons/a1.svg";
import a2 from "../../assets/icons/a2.svg";
import a3 from "../../assets/icons/a3.svg";
import a4 from "../../assets/icons/a4.svg";
export const eventTypes = [
  {
    id: 1,
    title: "Private",
    description:
      "Private hire is a space within venue that is exclusively reserved for your event",
    icon: a1,
  },
  {
    id: 2,
    title: "Whole venue",
    description: "The entire venue is exclusively available for your event.",
    icon: a2,
  },
  {
    id: 3,
    title: "Semi-private",
    description:
      "Semi-private hire involves booking a section of a venue that is partially secluded or separated, allowing for some privacy while other events or guests may also be present.",
    icon: a3,
  },
  {
    id: 4,
    title: "Shared space",
    description: "A non-exclusive, common area with open access for everyone.",
    icon: a4,
  },
];
