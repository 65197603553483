import React from "react";

function Timespentzero() {
  return (
    <div className="p-4 bg-[#FFFFFF] rounded-2xl">
      <div className="space-y-0 pb-2">
        <div className="text-[14px] md:text-[20px] font-bold">
          Average time spent
        </div>
      </div>
      <div className=" h-full w-full flex flex-col gap-y-3 items-center mt-[30px]">
        <img src="/averagezero.svg" alt="" />
        <p className="text-[14px] md:text-[16px] text-[#222222] text-center font-normal">
          At this moment, no average time on any <br /> venue
        </p>
      </div>
    </div>
  );
}

export default Timespentzero;
