import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { base_URL } from "../../config/config";

function EventTypeTableReport({ startDate, endDate, venueName }) {
  // for a team member invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, error } = useQuery({
    queryKey: [
      "eventTypeAnalysisTable",
      startDate,
      endDate,
      currentPage,
      pageSize,
      venueName,
    ],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/event-type-analysis/table`,
        {
          params: {
            vendorId: vendorId,
            startDate: startDate
              ? startDate?.toLocaleDateString("en-CA")
              : null, // Converts to YYYY-MM-DD
            endDate: endDate ? endDate?.toLocaleDateString("en-CA") : null,
            page: currentPage,
            limit: pageSize,
            venueName,
          },
        }
      );
      return response.data;
    },
  });

  // Pagination logic
  const paginatedData = data?.data;
  const totalPages = data?.pagination?.totalPages || 0;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(Number(newPageSize));
    setCurrentPage(1); // Reset to first page when changing page size
  };
  if (isLoading)
    return (
      <div className="h-[40vh] mt-4 rounded-lg animate-pulse bg-black/20"></div>
    );
  if (error) return <div>Error loading event type data</div>;
  return (
    <div className="rounded-xl inline">
      <div className="overflow-x-auto rounded-xl bg-white mt-4">
        <table className="w-full text-center">
          <thead className="bg-[#F4F4F4]/70">
            <tr>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Event Type
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Number of Enquiries
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {paginatedData?.map((item, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {item?.name}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {item?.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex items-center justify-end gap-x-3 px-6 py-3 border-t">
          <div className="flex items-center gap-2">
            <span className="text-sm text-[#222222]">Display:</span>
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(e.target.value)}
              className="px-2 py-1 border rounded-lg"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventTypeTableReport;
