import React, { useEffect, useState } from "react";

import { IoIosCheckmarkCircle } from "react-icons/io";
import shadeRight from "../../assets/pics/shadeRight.svg";
import shadeLeft from "../../assets/pics/shadeLeft.svg";
import SelectAndSearch from "../../components/shared/SelectAndSearch";
import HotelVenuModal from "../../components/HotelVenuModal";
import { Link } from "react-router-dom";

export const eventTypes = [
  {
    label: "Annual General meeting",
    value: "Annual General meeting",
    eventKey: "Conference/Seminars",
  },
  { label: "Auctions", value: "Auctions", eventKey: "Conference/Seminars" },
  {
    label: "Board Meetings",
    value: "Board Meetings",
    eventKey: "Conference/Seminars",
  },
  { label: "Breakout", value: "Breakout", eventKey: "Conference/Seminars" },
  { label: "Conference", value: "Conference", eventKey: "Conference/Seminars" },
  {
    label: "Conventions",
    value: "Conventions",
    eventKey: "Conference/Seminars",
  },
  {
    label: "Executive Summits",
    value: "Executive Summits",
    eventKey: "Conference/Seminars",
  },
  {
    label: "Exhibitions",
    value: "Exhibitions",
    eventKey: "Conference/Seminars",
  },
  { label: "Meetings", value: "Meetings", eventKey: "Conference/Seminars" },
  { label: "Networking", value: "Networking", eventKey: "Conference/Seminars" },
  {
    label: "Presentation",
    value: "Presentation",
    eventKey: "Conference/Seminars",
  },
  {
    label: "Press Conference",
    value: "Press Conference",
    eventKey: "Conference/Seminars",
  },
  {
    label: "Product Launch",
    value: "Product Launch",
    eventKey: "Conference/Seminars",
  },
  { label: "Seminars", value: "Seminars", eventKey: "Conference/Seminars" },
  { label: "Symposium", value: "Symposium", eventKey: "Conference/Seminars" },
  {
    label: "Trade Shows",
    value: "Trade Shows",
    eventKey: "Conference/Seminars",
  },
  { label: "Training", value: "Training", eventKey: "Conference/Seminars" },
  { label: "Workshops", value: "Workshops", eventKey: "Conference/Seminars" },
  {
    label: "Activity Day",
    value: "Activity Day",
    eventKey: "Corporate Events",
  },
  {
    label: "Award Ceremony",
    value: "Award Ceremony",
    eventKey: "Corporate Events",
  },
  { label: "Away day", value: "Away day", eventKey: "Corporate Events" },
  { label: "Brainstorm", value: "Brainstorm", eventKey: "Corporate Events" },
  {
    label: "Charity event",
    value: "Charity event",
    eventKey: "Corporate Events",
  },
  {
    label: "Corporate Gala dinner",
    value: "Corporate Gala dinner",
    eventKey: "Corporate Events",
  },
  {
    label: "Corporate Reception",
    value: "Corporate Reception",
    eventKey: "Corporate Events",
  },
  {
    label: "Corporate Retreat",
    value: "Corporate Retreat",
    eventKey: "Corporate Events",
  },
  { label: "FAM Trip", value: "FAM Trip", eventKey: "Corporate Events" },
  { label: "Fundraiser", value: "Fundraiser", eventKey: "Corporate Events" },
  {
    label: "Incentive trip",
    value: "Incentive trip",
    eventKey: "Corporate Events",
  },
  {
    label: "Office Party",
    value: "Office Party",
    eventKey: "Corporate Events",
  },
  {
    label: "Outdoor Activity",
    value: "Outdoor Activity",
    eventKey: "Corporate Events",
  },
  {
    label: "Pop Up event",
    value: "Pop Up event",
    eventKey: "Corporate Events",
  },
  { label: "PR events", value: "PR events", eventKey: "Corporate Events" },
  {
    label: "Promotional Events",
    value: "Promotional Events",
    eventKey: "Corporate Events",
  },
  { label: "Staff Party", value: "Staff Party", eventKey: "Corporate Events" },
  {
    label: "Summer Party",
    value: "Summer Party",
    eventKey: "Corporate Events",
  },
  {
    label: "Team Building",
    value: "Team Building",
    eventKey: "Corporate Events",
  },
  {
    label: "Training Program",
    value: "Training Program",
    eventKey: "Corporate Events",
  },
  { label: "Arabic Wedding", value: "Arabic Wedding", eventKey: "Weddings" },
  { label: "Beach Wedding", value: "Beach Wedding", eventKey: "Weddings" },
  {
    label: "Christian Wedding",
    value: "Christian Wedding",
    eventKey: "Weddings",
  },
  { label: "Church Wedding", value: "Church Wedding", eventKey: "Weddings" },
  {
    label: "Civil Partnership",
    value: "Civil Partnership",
    eventKey: "Weddings",
  },
  { label: "Civil Wedding", value: "Civil Wedding", eventKey: "Weddings" },
  { label: "Garden Wedding", value: "Garden Wedding", eventKey: "Weddings" },
  { label: "Hindu Wedding", value: "Hindu Wedding", eventKey: "Weddings" },
  { label: "Indian Wedding", value: "Indian Wedding", eventKey: "Weddings" },
  { label: "Marquee Wedding", value: "Marquee Wedding", eventKey: "Weddings" },
  { label: "Outdoor Wedding", value: "Outdoor Wedding", eventKey: "Weddings" },
  { label: "Unique Wedding", value: "Unique Wedding", eventKey: "Weddings" },
  {
    label: "Wedding Ceremony",
    value: "Wedding Ceremony",
    eventKey: "Weddings",
  },
  {
    label: "Wedding Reception",
    value: "Wedding Reception",
    eventKey: "Weddings",
  },
  { label: "BBQ", value: "BBQ", eventKey: "Dining" },
  {
    label: "Anniversary Party",
    value: "Anniversary Party",
    eventKey: "Dining",
  },
  {
    label: "Cocktail Masterclass",
    value: "Cocktail Masterclass",
    eventKey: "Dining",
  },
  {
    label: "Cocktail Reception",
    value: "Cocktail Reception",
    eventKey: "Dining",
  },
  { label: "Communion", value: "Communion", eventKey: "Dining" },
  { label: "Condolences", value: "Condolences", eventKey: "Dining" },
  { label: "Drinks Reception", value: "Drinks Reception", eventKey: "Dining" },
  {
    label: "Engagement Reception",
    value: "Engagement Reception",
    eventKey: "Dining",
  },
  { label: "Farewell Party", value: "Farewell Party", eventKey: "Dining" },
  {
    label: "Funeral reception",
    value: "Funeral reception",
    eventKey: "Dining",
  },
  { label: "Graduation Party", value: "Graduation Party", eventKey: "Dining" },
  {
    label: "New Year's Eve Party",
    value: "New Year's Eve Party",
    eventKey: "Dining",
  },
  { label: "Party", value: "Party", eventKey: "Dining" },
  { label: "Private Party", value: "Private Party", eventKey: "Dining" },
  { label: "Retirement Party", value: "Retirement Party", eventKey: "Dining" },
  { label: "Reunions", value: "Reunions", eventKey: "Dining" },
  { label: "Stag Party", value: "Stag Party", eventKey: "Dining" },
  { label: "Wine Tasting", value: "Wine Tasting", eventKey: "Dining" },
  {
    label: "16th Birthday Party",
    value: "16th Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "18th Birthday Party",
    value: "18th Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "21st Birthday Party",
    value: "21st Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "25th Birthday Party",
    value: "25th Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "30th Birthday Party",
    value: "30th Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "40th Birthday Party",
    value: "40th Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "50th Birthday Party",
    value: "50th Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "Birthday Party",
    value: "Birthday Party",
    eventKey: "Milestone Birthdays",
  },
  {
    label: "Afternoon Tea",
    value: "Afternoon Tea",
    eventKey: "Tea Party/Showers",
  },
  {
    label: "Baby Christening",
    value: "Baby Christening",
    eventKey: "Tea Party/Showers",
  },
  { label: "Baby Shower", value: "Baby Shower", eventKey: "Tea Party/Showers" },
  {
    label: "Bachelorette Party/Bachelor",
    value: "Bachelorette Party/Bachelor",
    eventKey: "Tea Party/Showers",
  },
  {
    label: "Bridal Shower",
    value: "Bridal Shower",
    eventKey: "Tea Party/Showers",
  },
  { label: "High Tea", value: "High Tea", eventKey: "Tea Party/Showers" },
  {
    label: "Children Birthday Party",
    value: "Children Birthday Party",
    eventKey: "Teen/Children",
  },
  { label: "Kids Party", value: "Kids Party", eventKey: "Teen/Children" },
  { label: "Teen Party", value: "Teen Party", eventKey: "Teen/Children" },
  {
    label: "Gender Reveals",
    value: "Gender Reveals",
    eventKey: "Special Big Events",
  },
  { label: "Proposals", value: "Proposals", eventKey: "Special Big Events" },
  { label: "Brunches", value: "Brunches", eventKey: "Cultural Celeberations" },
  {
    label: "Christmas dinner",
    value: "Christmas dinner",
    eventKey: "Cultural Celeberations",
  },
  {
    label: "Christmas Party",
    value: "Christmas Party",
    eventKey: "Cultural Celeberations",
  },
  { label: "Diwali", value: "Diwali", eventKey: "Cultural Celeberations" },
  { label: "Eid", value: "Eid", eventKey: "Cultural Celeberations" },
  {
    label: "Halloween Party",
    value: "Halloween Party",
    eventKey: "Cultural Celeberations",
  },
  {
    label: "Holiday Party",
    value: "Holiday Party",
    eventKey: "Cultural Celeberations",
  },
  { label: "Iftar", value: "Iftar", eventKey: "Cultural Celeberations" },
  {
    label: "Ladies night",
    value: "Ladies night",
    eventKey: "Cultural Celeberations",
  },
  { label: "Ramadan", value: "Ramadan", eventKey: "Cultural Celeberations" },
  { label: "Suhoor", value: "Suhoor", eventKey: "Cultural Celeberations" },
  {
    label: "Xmas Party",
    value: "Xmas Party",
    eventKey: "Cultural Celeberations",
  },
  {
    label: "Filming/Video Recording",
    value: "Filming/Video Recording",
    eventKey: "Filming, Photography & Media",
  },
  {
    label: "Photoshoot",
    value: "Photoshoot",
    eventKey: "Filming, Photography & Media",
  },
  {
    label: "Podcast Recording",
    value: "Podcast Recording",
    eventKey: "Filming, Photography & Media",
  },
  { label: "Book Launch", value: "Book Launch", eventKey: "Cultural" },
  { label: "Film Festival", value: "Film Festival", eventKey: "Cultural" },
  { label: "Music Festival", value: "Music Festival", eventKey: "Cultural" },
  {
    label: "Art Exhibitions",
    value: "Art Exhibitions",
    eventKey: "Art Spaces",
  },
  { label: "Pop Up Event", value: "Pop Up Event", eventKey: "Art Spaces" },
  {
    label: "Concert",
    value: "Concert",
    eventKey: "Performances & Exhibitions",
  },
  {
    label: "Dance Performance",
    value: "Dance Performance",
    eventKey: "Performances & Exhibitions",
  },
  {
    label: "Fashion Show",
    value: "Fashion Show",
    eventKey: "Performances & Exhibitions",
  },
  { label: "GIG", value: "GIG", eventKey: "Performances & Exhibitions" },
  {
    label: "Live Performance",
    value: "Live Performance",
    eventKey: "Performances & Exhibitions",
  },
  {
    label: "Performing Arts",
    value: "Performing Arts",
    eventKey: "Performances & Exhibitions",
  },
  {
    label: "Screening",
    value: "Screening",
    eventKey: "Performances & Exhibitions",
  },
  {
    label: "Stand-up Comedy",
    value: "Stand-up Comedy",
    eventKey: "Performances & Exhibitions",
  },
  {
    label: "Theatre (Drama/Plays)",
    value: "Theatre (Drama/Plays)",
    eventKey: "Performances & Exhibitions",
  },
  { label: "Adventure venue", value: "Adventure venue", eventKey: "venues" },
  { label: "Amphitheatre", value: "Amphitheatre", eventKey: "venues" },
  { label: "Aquarium", value: "Aquarium", eventKey: "venues" },
  { label: "Art Gallery", value: "Art Gallery", eventKey: "venues" },
  { label: "Art studio", value: "Art studio", eventKey: "venues" },
  { label: "Auditorium", value: "Auditorium", eventKey: "venues" },
  { label: "Ballroom", value: "Ballroom", eventKey: "venues" },
  { label: "Bar", value: "Bar", eventKey: "venues" },
  { label: "Beach", value: "Beach", eventKey: "venues" },
  { label: "Beach club", value: "Beach club", eventKey: "venues" },
  { label: "Blank canvas", value: "Blank canvas", eventKey: "venues" },
  { label: "Boats & Yachts", value: "Boats & Yachts", eventKey: "venues" },
  { label: "Cafes", value: "Cafes", eventKey: "venues" },
  { label: "Church", value: "Church", eventKey: "venues" },
  { label: "Cinema", value: "Cinema", eventKey: "venues" },
  { label: "Co-working space", value: "Co-working space", eventKey: "venues" },
  { label: "Community center", value: "Community center", eventKey: "venues" },
  { label: "Concert hall", value: "Concert hall", eventKey: "venues" },
  {
    label: "Conference center",
    value: "Conference center",
    eventKey: "venues",
  },
  { label: "Courtyard", value: "Courtyard", eventKey: "venues" },
  { label: "Dance Studio", value: "Dance Studio", eventKey: "venues" },
  { label: "Desert", value: "Desert", eventKey: "venues" },
  {
    label: "Entertainment venue",
    value: "Entertainment venue",
    eventKey: "venues",
  },
  { label: "Escape Rooms", value: "Escape Rooms", eventKey: "venues" },
  { label: "EXPO", value: "EXPO", eventKey: "venues" },
  { label: "Farm", value: "Farm", eventKey: "venues" },
  {
    label: "Fine dining restaurant",
    value: "Fine dining restaurant",
    eventKey: "venues",
  },
  { label: "Garden", value: "Garden", eventKey: "venues" },
  { label: "Golf courses", value: "Golf courses", eventKey: "venues" },
  { label: "Helipad", value: "Helipad", eventKey: "venues" },
  { label: "Karaoke bar", value: "Karaoke bar", eventKey: "venues" },
  { label: "Kids play area", value: "Kids play area", eventKey: "venues" },
  { label: "Lawn", value: "Lawn", eventKey: "venues" },
  { label: "Limousine", value: "Limousine", eventKey: "venues" },
  { label: "Meeting Center", value: "Meeting Center", eventKey: "venues" },
  { label: "Meeting room", value: "Meeting room", eventKey: "venues" },
  { label: "Museum", value: "Museum", eventKey: "venues" },
  { label: "Nightclub", value: "Nightclub", eventKey: "venues" },
  { label: "Park", value: "Park", eventKey: "venues" },
  { label: "Party bus", value: "Party bus", eventKey: "venues" },
  { label: "Patio", value: "Patio", eventKey: "venues" },
  { label: "Photo studio", value: "Photo studio", eventKey: "venues" },
  { label: "Poolside", value: "Poolside", eventKey: "venues" },
  { label: "Recording studio", value: "Recording studio", eventKey: "venues" },
  { label: "Restaurant", value: "Restaurant", eventKey: "venues" },
  { label: "Restaurant/Bar", value: "Restaurant/Bar", eventKey: "venues" },
  {
    label: "Restaurant/Lounge &Bar",
    value: "Restaurant/Lounge &Bar",
    eventKey: "venues",
  },
  { label: "Rooftop", value: "Rooftop", eventKey: "venues" },
  {
    label: "Rooftop restaurant",
    value: "Rooftop restaurant",
    eventKey: "venues",
  },
  {
    label: "Social clubs and lounges",
    value: "Social clubs and lounges",
    eventKey: "venues",
  },
  { label: "Spa", value: "Spa", eventKey: "venues" },
  { label: "Sports club", value: "Sports club", eventKey: "venues" },
  { label: "Sports venue", value: "Sports venue", eventKey: "venues" },
  { label: "Stadium", value: "Stadium", eventKey: "venues" },
  { label: "Streaming studio", value: "Streaming studio", eventKey: "venues" },
  { label: "Supper club", value: "Supper club", eventKey: "venues" },
  { label: "Temple", value: "Temple", eventKey: "venues" },
  { label: "Terrace", value: "Terrace", eventKey: "venues" },
  { label: "Theme Park", value: "Theme Park", eventKey: "venues" },
  { label: "Therapy room", value: "Therapy room", eventKey: "venues" },
  { label: "Training room", value: "Training room", eventKey: "venues" },
  { label: "TV film studio", value: "TV film studio", eventKey: "venues" },
  { label: "Warehouse", value: "Warehouse", eventKey: "venues" },
  { label: "Yoga Studio", value: "Yoga Studio", eventKey: "venues" },
  { label: "Zoo", value: "Zoo", eventKey: "venues" },
];

const sortedEventTypes = eventTypes.sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
});
const Hero = ({ filters, setFilters }) => {
  const [showHotelVenue, setShowHotelVenue] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [numberOfGuests, setNumberOfGuests] = useState("");

  const names = [
    "Auditorium",
    "Ballroom",
    "Beach",
    "Kids Play Area",
    "Museum",
    "Restaurant",
    "Yacht",
  ];
  const images = [
    {
      name: "Auditorium",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979090/WhatsApp_Image_2024-11-07_at_4.58.04_PM_1_uxxesc.jpg",
    },
    {
      name: "Ballroom",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730978955/WhatsApp_Image_2024-11-07_at_4.58.04_PM_bgekuh.jpg",
    },
    {
      name: "Beach",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979091/WhatsApp_Image_2024-11-07_at_4.58.04_PM_2_lcdmmf.jpg",
    },
    {
      name: "Kids Play Area",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979988/WhatsApp_Image_2024-11-07_at_5.15.52_PM_d4lplx.jpg",
    },
    {
      name: "Museum",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979089/WhatsApp_Image_2024-11-07_at_4.59.51_PM_yoqh7g.jpg",
    },
    {
      name: "Restaurant",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979651/Restaurant-min_rj6fvl.png",
    },
    {
      name: "Yacht",
      img: "https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730979360/WhatsApp_Image_2024-11-07_at_5.05.38_PM_unhkbl.jpg",
    },
  ];
  const cities = [
    { name: "Abu Dhabi", value: "Abu Dhabi" },
    { name: "Ajman", value: "Ajman" },
    { name: "Fujairah", value: "Fujairah" },
    { name: "Dubai", value: "Dubai" },
    { name: "Ras Al Khaimah", value: "Ras Al Khaimah" },
    { name: "Sharjah", value: "Sharjah" },
    { name: "Umm Al Quwain", value: "Umm Al Quwain" },
  ];
  const cities1 = [
    { label: "Abu Dhabi", value: "Abu Dhabi" },
    { label: "Ajman", value: "Ajman" },
    { label: "Fujairah", value: "Fujairah" },
    { label: "Dubai", value: "Dubai" },
    { label: "Ras Al Khaimah", value: "Ras Al Khaimah" },
    { label: "Sharjah", value: "Sharjah" },
    { label: "Umm Al Quwain", value: "Umm Al Quwain" },
  ];
  useEffect(() => {
    console.log(selectedCity);
    if (selectedEvent.eventKey === "venues") {
      setFilters({
        city: selectedCity,
        standingGuests: numberOfGuests,
        venueTypes: [selectedEvent.label],
      });
    } else {
      const typeKeys = [
        "Conference/Seminars",
        "Corporate Events",
        "Weddings",
        "Dining",
        "Performances & Exhibitions",
        "Teen/Children",
        "Milestone Birthdays",
        "Tea Party/Showers",
        "Special Big Events",
        "Cultural Celeberations",
        "Filming, Photography & Media",
        "Cultural",
        "Art Spaces",
      ];
      if (typeKeys.includes(selectedEvent.eventKey)) {
        setFilters({
          city: selectedCity,
          standingGuests: numberOfGuests,
          eventTypes: {
            [selectedEvent.eventKey]: [selectedEvent.label],
          },
        });
      } else {
        setFilters({
          city: selectedCity,
          standingGuests: numberOfGuests,
        });
      }
    }
  }, [selectedCity, numberOfGuests, selectedEvent]);

  return (
    <div className="relative mx-auto lg:h-[100vh] lg:max-h-[750px] h-auto">
      <div className="flex justify-center w-full sm:pt-20 pt-12 mx-auto ">
        <div className="w-[90%]  flex items-center  gap-10 justify-between mt-5">
          <div className="lg:w-[60%] w-full flex flex-col sm:items-start items-center sm:gap-6 gap-4">
            <h1 className="xl:text-[76px] sm:text-5xl leading-none text-[32px] sm:text-start text-center font-bold ">
              Search & Compare
              <span className="text-[#FF3939] "> Venues Across UAE.</span>
            </h1>
            <p className="sm:text-[20px] text-[14px] sm:text-start text-center lg:font-[400]">
              The UAE's #1 venue search platform for discovering venues for
              meetings, corporates, parties, wedding & beyond.
            </p>
            <div className="bg-white py-5 px-5 z-20  flex md:gap-0 gap-4 justify-between items-end md:flex-row w-full flex-col h-full rounded-lg shadow-lg">
              <div className="xl:w-[90%] w-full  md:gap-0  grid md:grid-cols-3 grid-cols-1 h-full">
                <div className="flex  w-full ">
                  <div className=" w-full">
                    <h1 className="md:text-[16px] text-[#5E5E5E] md:font-[400]">
                      Event/Venue
                    </h1>
                    <div className="w-full md:text-[16px]">
                      <SelectAndSearch
                        options={sortedEventTypes}
                        onSelect={(value) => {
                          setSelectedEvent(value);
                        }}
                        selectedOption={selectedEvent.label}
                      />
                    </div>
                  </div>
                  <div className="w-[1px] md:block hidden h-full bg-[#CECECE] mx-4"></div>
                </div>

                <div className="flex  w-full">
                  <div className=" w-full">
                    <h1 className="text-[16px] md:font-[400] text-[#5E5E5E] mb-[6px] ">
                      No. of guests
                    </h1>
                    <div className="flex items-center gap-2 w-full">
                      <input
                        type="number"
                        className="w-full outline-none text-[16px] font-[400] text-[#B0B0B0] placeholder-[#B0B0B0] bg-transparent"
                        placeholder="Enter no. of guests"
                        name=""
                        id=""
                        value={numberOfGuests}
                        onChange={(e) => setNumberOfGuests(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-[1px] md:block hidden h-full bg-[#CECECE] mx-4"></div>
                </div>

                <div className="flex  w-full">
                  <div className=" w-full">
                    <h1 className="text-[16px] md:font-[400] text-[#5E5E5E]">
                      City
                    </h1>

                    <div className="w-full">
                      <SelectAndSearch
                        options={cities1}
                        selectedOption={selectedCity}
                        onSelect={(value) => setSelectedCity(value.label)}
                      />
                    </div>
                  </div>
                  <div className="w-[2px] md:block hidden h-full  mx-4"></div>
                </div>
              </div>

              <Link
                to="/listingvenues/1"
                state={{
                  city: selectedCity,
                  event: selectedEvent,
                  guests: numberOfGuests,
                }}
                className="flex h-full  md:w-[12%] w-full justify-center items-center"
              >
                <button className="bg-[#FE4747] md:w-auto w-full rounded-lg text-white py-2 px-4 lg:text-[16px] lg:font-[500]">
                  Search
                </button>
              </Link>
            </div>
            <p
              onClick={() => setShowHotelVenue(true)}
              className="underline text-[16px] cursor-pointer z-10 text-[#FF3939]"
            >
              Are you looking for hotel stay + Venue
            </p>
          </div>

          <div className="w-[30%] lg:flex hidden  justify-center">
            {/* <img src={img} alt='' /> */}
            <div className="relative w-full  h-auto min-h-[400px] mb-0 justify-center gap-5 flex flex-col items-center">
              {/* Hidden SVG mask definition */}
              <div className=" w-[354px] h-full flex -mt-8 mr-7 justify-center items-center relative">
                <svg className="absolute left-1/2 -translate-x-1/2 top-0 h-full flex justify-center">
                  <defs>
                    <mask
                      id="mask0_3556_4522"
                      maskUnits="userSpaceOnUse"
                      x="39"
                      y="0"
                      width="314"
                      height="392"
                    >
                      <path
                        d="M195.953 196C206.733 196 215.962 192.162 223.638 184.485C231.315 176.808 235.153 167.58 235.153 156.8C235.153 146.02 231.315 136.792 223.638 129.115C215.962 121.438 206.733 117.6 195.953 117.6C185.173 117.6 175.945 121.438 168.268 129.115C160.592 136.792 156.753 146.02 156.753 156.8C156.753 167.58 160.592 176.808 168.268 184.485C175.945 192.162 185.173 196 195.953 196ZM195.953 392C143.36 347.247 104.078 305.678 78.1083 267.295C52.1383 228.912 39.1533 193.387 39.1533 160.72C39.1533 111.72 54.915 72.6833 86.4383 43.61C117.962 14.5367 154.467 0 195.953 0C237.44 0 273.945 14.5367 305.468 43.61C336.992 72.6833 352.753 111.72 352.753 160.72C352.753 193.387 339.768 228.912 313.798 267.295C287.828 305.678 248.547 347.247 195.953 392Z"
                        fill="white"
                      />
                    </mask>
                  </defs>
                </svg>

                {/* Images masked with the defined SVG */}
                <div className="image-container w-full h-full flex justify-center relative">
                  {images?.map((e, index) => (
                    <img
                      key={index}
                      src={e?.img}
                      alt={`Masked ${index + 1}`}
                      className="w-full h-full object-cover absolute left-0 top-0"
                      style={{
                        mask: "url(#mask0_3556_4522)",
                        WebkitMask: "url(#mask0_3556_4522)",
                        animationDelay: `${index * 2}s`, // Staggered delay for each image
                      }}
                    />
                  ))}
                </div>
              </div>
              {/* Text container for image names */}
              <div className="text-container w-full h-auto relative flex justify-center items-center">
                {names.map((e, index) => (
                  <div
                    key={index}
                    className="lg:text-[40px] sm:text-3xl text-2xl text-[#FE4747] font-semibold tracking-wide	"
                  >
                    {e}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFF5F5] flex justify-center py-8 px-2 w-full mb-3 mt-[100px]">
        <div className="sm:flex hidden items-center gap-2">
          <IoIosCheckmarkCircle className="text-[#FE4747] text-3xl" />
          <h1 className="text-[#222222] font-medium text-[32px]">
            No Booking fees, No commission - Just Connections.
          </h1>
        </div>
        <div className="sm:hidden flex items-center justify-between gap-5">
          <div className="flex items-center gap-2">
            <IoIosCheckmarkCircle className="text-[#FE4747] text-xl" />
            <h1 className="text-[#222222] text-xs font-semibold">
              No Booking fees
            </h1>
          </div>
          <div className="w-[2px] h-full bg-[#FE4747]"></div>
          <div className="flex items-center gap-2">
            <IoIosCheckmarkCircle className="text-[#FE4747] text-xl" />
            <h1 className="text-[#222222] text-xs font-semibold">
              No commission - Just Connections.
            </h1>
          </div>
        </div>
      </div>
      <img src={shadeRight} className="absolute top-[0%] right-0 z-1" alt="" />
      <img src={shadeLeft} className="absolute top-[0%] left-0 z-1" alt="" />

      <HotelVenuModal
        onSubmit={() => {
          console.log("submit");
          setShowHotelVenue(false);
          window.location.href = "/listing/1";
        }}
        filters={filters}
        setFilters={setFilters}
        cities={cities}
        eventTypes={sortedEventTypes}
        showHotelVenue={showHotelVenue}
        setShowHotelVenue={setShowHotelVenue}
      />
    </div>
  );
};

export default Hero;
