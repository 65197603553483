// GoogleMapComponent.jsx
import React, { useEffect, useState } from "react";
import MapContainer from "../hostel_listing_form/overview-form/googleMap";
import extractMapCoordinates from "../../utils/extractMapCoordinates";

const GoogleMapComponent = ({ data }) => {
  const [mapLocation, setMapLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (data?.lat && data?.lng) {
      setMapLocation({ lat: data?.lat, lng: data?.lng });
    } else if (data?.mapLink && data?.mapLink !== "") {
      const coords = extractMapCoordinates(data?.mapLink);
      if (coords) {
        setMapLocation(coords);
      } else {
        console.log("Invalid map link");
      }
    } else {
      if (
        data?.city &&
        data?.area &&
        data?.country &&
        typeof window !== "undefined"
      ) {
        const address = `${data.city}, ${data.area}, ${data.country}`;
        geocodeAddress(address);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setCenter({
      lat: data.lat,
      lng: data.lng,
    });
  }, [data]);

  

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0].geometry.location;
        const coords = {
          lat: location.lat(),
          lng: location.lng(),
        };
        setMapLocation(coords);
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };

  return (
    <div className="py-5 lg:w-3/4 w-full border-t mx-auto">
      <h2 className="text-2xl font-bold mb-4">Where We Are Located</h2>
      {/* <LoadScript googleMapsApiKey="AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center} zoom={10}>
                    <MarkerF
                        position={center} />
                </GoogleMap>
            </LoadScript> */}
      <MapContainer
        isHotel={false}
        isVenue={true}
        lat={mapLocation?.lat}
        lng={mapLocation?.lng}
        mapLocation={mapLocation}
        setMapLocation={setMapLocation}
        draggable={false}
        showHeader={false}
      />
    </div>
  );
};

export default GoogleMapComponent;
