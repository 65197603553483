import { useQuery } from "@tanstack/react-query";
import EnquiruStatuszero from "./EnquiruStatuszero";
import { base_URL } from "../../config/config";
import axios from "axios";

export const EnquireStatusCard = ({ startDate, endDate, venueName }) => {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const { data, isLoading } = useQuery({
    queryKey: ["enquieyStausCard", startDate, endDate, venueName],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/dashboard/getEnquiriesByStatusdashbaord`,
        {
          params: {
            vendorId: vendorId,
            startDate: startDate ? startDate.toLocaleDateString("en-CA") : null,
            endDate: endDate ? endDate.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return response;
    },
  });
  // Counting statuses
  const successfulCount =
    data?.data?.find((item) => item.name === "Successful")?.count || 0;
  const newCount = data?.data?.find((item) => item.name === "New")?.count || 0;
  const activeCount =
    data?.data?.find((item) => item.name === "Active")?.count || 0;
  const lostCount =
    data?.data?.find((item) => item.name === "Lost")?.count || 0;

  if (isLoading)
    return (
      <div className="w-full h-[35vh] rounded-xl md:w-[35%] animate-pulse bg-black/20"></div>
    );

  if (
    !data ||
    (successfulCount === 0 &&
      newCount === 0 &&
      activeCount === 0 &&
      lostCount === 0)
  ) {
    return <EnquiruStatuszero />;
  }

  return (
    <>
      <div className="w-full bg-[#FFFFFF]  rounded-xl md:w-[35%]">
        <div className="ml-[20px] mt-[20px]">
          <div className="text-[#222222] text-[16px] md:text-[20px] font-medium">
            Enquiry status
          </div>
          <p className="text-[12px] md:text-[14px] font-normal text-[#717171]">
            Total enquiry status
          </p>
        </div>
        <div className="flex justify-center items-center">
          <div className="relative w-64 h-64">
            <div className="absolute top-5 md:top-[33px] z-[1000] left-1/2 transform -translate-x-1/2 bg-[#7A79FF] rounded-full w-[87.02px] h-[87.02px] flex items-center justify-center text-white">
              <div className="text-center">
                <div className="text-[20px] md:text-[25.88px] font-bold">
                  {successfulCount}
                </div>
                <div className="text-[10.46px] font-normal">Successful</div>
              </div>
            </div>
            <div className="absolute bottom-5 md:bottom-0 left-0 z-[100] bg-[#27C2B0] rounded-full h-[70px] w-[70px] md:w-[102.62px] md:h-[102.62px] flex items-center justify-center text-white">
              <div className="text-center">
                <div className="text-[20px] md:text-[25.88px] font-bold">
                  {newCount}
                </div>
                <div className="text-[10.46px] font-normal">New</div>
              </div>
            </div>
            <div className="absolute bottom-[27px] right-0 bg-[#F5BA48] rounded-full w-[120px] h-[120px] md:w-[141.51px] md:h-[141.51px] flex items-center justify-center text-white">
              <div className="text-center">
                <div className="text-[20px] md:text-[25.88px] font-bold">
                  {activeCount}
                </div>
                <div className="text-[10.46px] font-normal">Active</div>
              </div>
            </div>
            <div className="absolute top-[60%] md:top-[87%] right-[122px] md:right-[100px] bg-[#4990FB] rounded-full w-[59.25px] h-[59.25px] flex items-center justify-center text-white">
              <div className="text-center">
                <div className="text-[20px] md:text-[25.88px] font-bold">
                  {lostCount}
                </div>
                <div className="text-[10.46px] font-normal">Lost</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
