"use client";
import React, { useState } from "react";
import imgBanner from "../../assets/pics/fmvImage.jfif";
import { Award, Calender, Mic, Target, Analytic, Enquiry } from "../../svgs";

const Enquiries = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const data = [
    {
      head: "Increased Global <br/>Enquiries ",
      icon: <Calender />
    },
    {
      head: "Enjoy Commission-Free <br/> Leads",
      icon: <Award />
    },
    {
      head: `Expand Your Reach to <br/>the Right Audience`,
      icon: <Target />
    },
    {
      head: "Dedicated Support and <br/> Seamless Onboarding",
      icon: <Mic />
    },
    {
      head: "Interactive Performance <br/> Dashboard",
      icon: <Analytic />
    },
    {
      head: "Streamline Enquiry <br/> Management",
      icon: <Enquiry />
    },
  ];

  return (
    <div className="flex justify-center pt-20 sm:px-24 px-4">
      <div className="w-[90%]">
        <h1 className="text-[#222222] sm:text-4xl text-3xl font-semibold">
          Why list with us?
        </h1>
        <div className="flex items-center mt-10 gap-8">
          <div className="lg:w-[60%] w-full h-full">
            <div className=" grid sm:grid-cols-2 grid-cols-1 gap-6">
              {data.map((e, i) => (
                <div
                  key={i}
                  className={` cursor-pointer flex sm:flex-row flex-col items-center sm:gap-5 gap-3  transition duration-300 ${hoveredIndex === i ? "text-[#FE4747]" : "text-[#5E5E5E]"}`}
                  onMouseEnter={() => setHoveredIndex(i)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <div >
                    {e.icon}
                  </div>
                  <h1
                    className=" text-[15px]  font-bold  sm:text-start text-center cursor-pointer"
                    dangerouslySetInnerHTML={{ __html: e.head }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-[40%] lg:flex hidden justify-center">
            <img src={imgBanner} className="w-full rounded-xl h-full" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiries;
