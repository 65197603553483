import React, {useState, useEffect} from "react";

function SaveFavTableReport({ data, startDate, endDate }) {
  const [displayData, setDisplayData] = useState([]);
    useEffect(() => {
      setDisplayData(data);
    }, [data]);

  return (
    <div className="rounded-xl hidden md:inline">
      <div className="overflow-x-auto rounded-xl bg-white mt-4">
        <table className="w-full text-left">
          <thead className="bg-[#F4F4F4]/70">
            <tr>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Devices
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Page impression
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Added to favourites
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry received out of favourites
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry successful out of received
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {displayData &&
              displayData.length > 0 &&
              displayData.map((deviceData, index) => (
                <tr className="hover:bg-gray-50" key={index}>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {deviceData.device || "Unknown"}
                  </td>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {startDate || endDate ? deviceData.favImpressionsByDate : deviceData.favImpressions || 0}
                  </td>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {deviceData.addedToFavourites || 0}
                  </td>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {deviceData.enquiries || 0}
                  </td>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {deviceData.favSuccessfulEnquiries || 0}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex items-center justify-end gap-x-3 px-6 py-3 border-t">
          <div className="flex items-center gap-2">
            <span className="text-sm text-[#222222]">Display:</span>
            <select className="px-2 py-1 border rounded-lg">
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="flex items-center gap-2">
            <button className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50">
              Previous
            </button>
            <button className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveFavTableReport;
