import React, { useState, useEffect } from "react";
import { Tick } from "../svgs";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify"; // For user feedback
import { base_URL } from "../config/config"; // Define your API base URL here
import { useSelector } from "react-redux";

const CheckInboxModal = () => {
  const [isResending, setIsResending] = useState(false);
  const [timer, setTimer] = useState(0); // Timer state
  const { formData, resendEmail } = useSelector((state) => state.auth);
  // console.log(formData);

  const handleResendEmail = async () => {
    setIsResending(true); // Start resending

    try {
      // Call the API to resend the verification email
      await axios.post(`${base_URL}/api/auth/resend-verification-email`, {
        email: resendEmail, // Use the email from Redux state
      });

      // Show success message
      toast.success("Verification email resent successfully!");

      // Start a 60-second timer
      setTimer(60);
    } catch (error) {
      // Handle error, show error message
      toast.error(
        error.response?.data?.message ||
          "Failed to resend verification email. Please try again."
      );
    } finally {
      setIsResending(false); // Stop the loading state
    }
  };

  // Timer countdown effect
  useEffect(() => {
    let interval = null;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div className="w-full py-3 text-[#B0B0B0] flex flex-col items-center gap-3">
      <Tick />
      <h1 className="text-dark mt-5 lg:text-4xl text-center font-bold sm:text-3xl text-2xl">
        Check your inbox
      </h1>
      <p className="text-lg text-center">
        We just emailed a verification link to {resendEmail}. Once it arrives,
        it will be valid for 10 minutes.
      </p>
      <p className="text-center text-sm mt-3">
        If you haven’t received an email after a while,{" "}
        {timer > 0 ? (
          <span className="text-primary">
            Resend in {timer} second{timer > 1 ? "s" : ""}...
          </span>
        ) : (
          <Link
            to="#"
            className="text-primary"
            onClick={handleResendEmail}
            disabled={isResending} // Disable the link while resending
          >
            {isResending ? "Resending..." : "Click here to try again"}
          </Link>
        )}
      </p>
    </div>
  );
};

export default CheckInboxModal;
