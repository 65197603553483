import { CiCalendar } from "react-icons/ci";
import { MdAccessTime } from "react-icons/md";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { GiButterflyFlower } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useState } from "react";
import Declinemodal from "./message/components/DeclineModal";
import { base_URL } from "../../../config/config";
import { toast } from "react-hot-toast";

const Card = ({ data, onClick, daysPassed, fetchEnquiries }) => {
  const { _id } = data;
  const [isModalVisible, setIsModalVisible] = useState(false);
  if (data) {
    data.updatedAt = new Date(data.updatedAt);
  }

  const closeEnquiry = async (declineReason, declineJustification) => {
    // console.log({
    //   enquiryId: _id,
    //   status: "Customer Declined",
    //   statusMessage: "Enquiry Declined by Customer",
    //   declineReason,
    //   declineJustification,
    // });
    try {
      const response = await fetch(`${base_URL}/api/enquiry/updateEnquiry`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          enquiryId: _id,
          status: "Customer Declined",
          statusMessage: "Enquiry Declined by Customer",
          declineReason,
          declineJustification,
        }),
      });

      const data = await response.json();
      // console.log(data);
      toast.success("Enquiry Declined Successfuly");
      setIsModalVisible(false);
      fetchEnquiries();
      return data;
    } catch (err) {
      toast.error("Error declining enquiry");
    }
  };
  // console.log(daysPassed);
  return (
    <div className="w-full md:h-[188px] shadow-md md:flex items-center h-auto rounded-l-[8px] rounded-r-[8px] bg-white mb-4">
      <Declinemodal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        closeEnquiry={closeEnquiry}
      />
      <div className="md:w-[25%] w-full md:h-full h-[200px] flex justify-center items-center">
        <img
          src={data?.venueImage}
          alt="venue"
          className="w-full h-full rounded-[8px] bg-cover"
        />
      </div>

      <div className="md:px-8 px-3 py-4 w-full">
        <div className="md:flex justify-between items-start pb-7 border-b border-b-[#EBEBEB]">
          <div className="flex justify-between items-center">
            <h2
              className="text-[20px] font-[500] text-[#222222] cursor-pointer hover:text-[#FE4747] transition-all"
              onClick={() => onClick(data)}
            >
              {data?.enquiryType === "hotel"
                ? `${data?.hotelName}`
                : `${data?.venueName}, ${data?.hotelName}`}
            </h2>
            {(data?.status !== "Customer Declined" ||
              data?.status === "Venue Declined" ||
              data?.status === "Event Date Passed") && (
              <div
                className=" justify-end items-center md:hidden flex cursor-pointer"
                onClick={() => setIsModalVisible(true)}
              >
                <IoMdClose className="text-[#717171] text-[20px] cursor-pointer" />
                <p className="text-[14px] text-[#717171] cursor-pointer">
                  Close Enquiry
                </p>
              </div>
            )}
          </div>
          <div className="flex md:justify-end justify-between items-center md:flex-col flex-row gap-2 md:mt-0 mt-1">
            <div
              className={`px-2 py-1 text-[14px] font-[400] text-center rounded-[8px] ${
                data?.status === "Declined by you" ||
                data?.status === "Not Accepted"
                  ? "text-[##F25700] bg-[#ffeceb]"
                  : data?.status === "Event Date Passed" ||
                    data?.status === "Customer Declined" ||
                    data?.status === "Venue Declined"
                  ? "bg-[#f8f8f8] text-[#5b5b5b]"
                  : data?.status === "Active"
                  ? "bg-[#ebfaf8] text-[#1fa998]"
                  : data?.status === "Successful"
                  ? "bg-[#DFDFFF] text-[#4A49D9]"
                  : "bg-[#f8f8f8] text-[#5b5b5b]"
              }`}
            >
              {data.status === "Lost"
                ? `Event date passed`
                : data.status === "Customer Declined"
                ? "Declined by you"
                : data.status}
            </div>
            <p className="text-[14px] text-[#717171] text-right">
              {new Date(data.updatedAt)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-")}
            </p>
          </div>
        </div>
        <div className="md:flex justify-between items-center pt-4">
          <div className="flex flex-wrap md:justify-start justify-between items-center md:gap-3 text-black font-[500] text-[14px]">
            <div className="flex justify-start items-center gap-1 md:w-auto w-[40%] md:mb-0 mb-1">
              <CiCalendar className=" text-[20px]" />
              <p className="text-[14px]">
                {new Date(data.startDate)
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, "-")}
              </p>
            </div>
            <div className="flex justify-start items-center gap-1 md:w-auto w-[40%] md:mb-0 mb-1">
              <MdAccessTime className=" text-[20px]" />
              <p className="text-[14px]">{data.startTime}</p>
            </div>
            <div className="flex justify-start items-center gap-1 md:w-auto w-[40%] md:mb-0 mb-1">
              <MdOutlinePeopleAlt className="text-[20px]" />
              <p className="text-[14px] ">{data.guests} Guests</p>
            </div>
            <div className="flex justify-start items-center gap-1 md:w-auto w-[40%] md:mb-0 mb-1">
              <GiButterflyFlower className=" text-[20px]" />
              <p className="text-[14px] ">{data.eventType}</p>
            </div>
          </div>
          {(data.status === "Not Accepted" ||
            data.status === "Active" ||
            data.status === "Event Date Passed") && (
            <div
              className=" justify-end items-center md:flex hidden cursor-pointer"
              onClick={() => setIsModalVisible(true)}
            >
              <IoMdClose className="text-[#717171] text-[20px]" />
              <p className="text-[14px] text-[#717171]">Close Enquiry</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
