import React, { useContext, useState, useEffect } from "react";
import ToggleSwitch from "../../shared/common/custom-toggle";
import AddFeatureModal from "./add-feature-form";
import { FiScissors } from "react-icons/fi";
import { HotelContext } from "../../../context/HotelContext";
import Modal from "../../shared/custom-modal/custom-modal";
import * as FaIcons from "react-icons/fa";

const defaultFeatures = [
  {
    name: "Health Club",
    icon: <img src="/features/medical.svg" />,
    isDefault: true,
  },
  {
    name: "Indoor Pool",
    icon: <img src="/features/pool.svg" />,
    isDefault: true,
  },
  {
    name: "Outdoor Pool",
    icon: <img src="/features/outdoor-pool.svg" />,
    isDefault: true,
  },
  { name: "Spa", icon: <img src="/features/massage.svg" /> },
  { name: "Salon", icon: <img src="/features/scissors.svg" /> },
  { name: "Tennis Courts", icon: <img src="/features/sports.svg" /> },
  { name: "Whirlpool", icon: <img src="/features/whirlpool.svg" /> },
];

const RecreationalFeatures = () => {
  const { formData, setFormData } = useContext(HotelContext);
  const [features, setFeatures] = useState(defaultFeatures); // Initialize with default features
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [featureToEdit, setFeatureToEdit] = useState(null);

  // Ensure amenities and recreationalFeatures are defined
  const recreationalFeatures = formData?.amenities?.recreationalFeatures || {};

  // access the array of objects name : iconname
  const recreationalFeaturesIcon =
    formData?.amenities?.recreationalFeatures || {};

  useEffect(() => {
    // Map existing features into an array with proper icons
    const updatedFeatures = Object.keys(recreationalFeatures).map(
      (feature) => ({
        name: feature,
        icon: recreationalFeaturesIcon[feature] || "FaAccusoft", // Fallback icon if none exists
      })
    );

    // Update the features state with the merged list
    setFeatures((prevFeatures) => {
      // Create a map of previous features to avoid duplicates
      const existingFeaturesMap = new Map(
        prevFeatures.map((item) => [item.name, item])
      );

      // Combine the existing features with the updated features
      const combinedFeatures = [...prevFeatures];

      updatedFeatures.forEach((newFeature) => {
        if (!existingFeaturesMap.has(newFeature.name)) {
          combinedFeatures.push(newFeature);
        }
      });

      return combinedFeatures;
    });
  }, [recreationalFeatures, recreationalFeaturesIcon]);

  const handleToggle = (featureName) => {
    const updatedFeatures = {
      ...recreationalFeatures,
      [featureName]: !recreationalFeatures[featureName],
    };

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        recreationalFeatures: updatedFeatures,
      },
    }));
  };

  const handleUpdateFeature = (updatedFeature) => {
    // Create shallow copies for updates
    const updatedRoomFeatures = { ...recreationalFeatures };

    // Preserve the existing icon if only the name is updated
    if (featureToEdit?.name !== updatedFeature?.name) {
      const existingIcon = updatedRoomFeatures[featureToEdit?.name];

      // Remove the old name
      delete updatedRoomFeatures[featureToEdit?.name];

      // Add the new name with the preserved icon
      updatedRoomFeatures[updatedFeature?.name] =
        existingIcon || updatedFeature?.icon;
    }

    // Check if the icon has changed
    if (featureToEdit?.icon !== updatedFeature?.icon) {
      updatedRoomFeatures[updatedFeature?.name] = updatedFeature?.icon;
    }

    // Update the features list
    const updatedFeatures = features?.map((feature) =>
      feature?.name === featureToEdit?.name ? updatedFeature : feature
    );

    setFeatures(updatedFeatures);

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData?.amenities,
        recreationalFeatures: updatedRoomFeatures,
      },
    }));

    // Clear the feature being edited
    setFeatureToEdit(null);
  };

  const handleAddFeature = (newFeature) => {
    if (featureToEdit) {
      // Editing an existing feature
      const updatedFeatures = features.map((feature) =>
        feature.name === featureToEdit.name
          ? { ...feature, ...newFeature }
          : feature
      );
      setFeatures(updatedFeatures); // Update features list

      setFormData((prevData) => {
        const amenities = prevData?.amenities || {};
        const recreationalFeatures = { ...amenities.recreationalFeatures };
        const recreationalFeaturesIcons = {
          ...amenities.recreationalFeaturesIcons,
        };

        // Update form data for both recreationalFeatures and icons
        recreationalFeatures[featureToEdit.name] = newFeature.icon;
        recreationalFeaturesIcons[featureToEdit.name] = newFeature.icon;

        return {
          ...prevData,
          amenities: {
            ...amenities,
            recreationalFeatures,
            recreationalFeaturesIcons,
          },
        };
      });

      setFeatureToEdit(null); // Reset the featureToEdit after update
    } else {
      // Adding a new feature if not editing
      setFeatures((prevFeatures) => [...prevFeatures, newFeature]);

      setFormData((prevData) => {
        const amenities = prevData?.amenities || {};
        const recreationalFeatures = amenities?.recreationalFeatures || {};
        const recreationalFeaturesIcons =
          amenities?.recreationalFeaturesIcons || {};

        // Update form data with the new feature
        return {
          ...prevData,
          amenities: {
            ...amenities,
            recreationalFeaturesIcons: {
              ...recreationalFeaturesIcons,
              [newFeature.name]: newFeature.icon,
            },
            recreationalFeatures: {
              ...recreationalFeatures,
              [newFeature.name]: newFeature.icon,
            },
          },
        };
      });
    }
  };

  const handleEditFeature = (feature) => {
    setFeatureToEdit(feature);
    setIsModalOpen(true);
  };

  const handleDeleteFeature = (featureName) => {
    const updatedFeatures = features.filter(
      (feature) => feature.name !== featureName
    );
    setFeatures(updatedFeatures);

    const updatedRecreationalFeatures = { ...recreationalFeatures };
    delete updatedRecreationalFeatures[featureName];

    setFormData((prevData) => ({
      ...prevData,
      amenities: {
        ...prevData.amenities,
        recreationalFeatures: updatedRecreationalFeatures,
      },
    }));
  };

  const renderIcon = (iconName) => {
    const IconComponent = FaIcons[iconName];
    return IconComponent ? <IconComponent size={32} /> : null;
  };
  return (
    <div className="flex">
      <div className="w-full">
        <div className="flex justify-between items-center w-full pb-5">
          <h2 className="text-2xl font-semibold text-neutralBlack mb-4">
            Recreational Features
          </h2>
          <button
            onClick={() => {
              setFeatureToEdit(null);
              setIsModalOpen(true);
            }}
            className="text-primary font-medium text-base underline"
          >
            Add Custom Feature
          </button>
        </div>

        {features &&
          features.map((feature, index) => {
            const isDefaultFeature = defaultFeatures.find(
              (defaultFeature) => defaultFeature?.name === feature?.name
            );

            return (
              <div
                key={index}
                className="flex py-1 items-center justify-between lg:w-[60%] w-full mb-4"
              >
                <div className="flex items-center w-[80%] justify-between">
                  <div className="flex items-center space-x-4">
                    {isDefaultFeature ? (
                      feature.icon
                    ) : (
                      <span className="text-2xl mr-2">
                        {renderIcon(feature?.icon)}
                      </span>
                    )}
                    <span
                      className="text-base text-darkGray"
                      style={{
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        maxWidth: "20ch",
                      }}
                    >
                      {feature?.name}
                    </span>
                  </div>
                  <ToggleSwitch
                    isOn={recreationalFeatures[feature.name] || false}
                    handleToggle={() => handleToggle(feature.name)}
                  />
                </div>
                {isDefaultFeature ? null : (
                  <div className="flex items-center gap-1">
                    <button
                      className="text-sm text-[#1252B1] hover:text-red-600"
                      onClick={() => handleEditFeature({ ...feature, index })}
                    >
                      <img src="/edit.svg" />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-600"
                      onClick={() => handleDeleteFeature(feature.name)}
                    >
                      <img src="/trash.svg" />
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        {formData?.amenities?.errors && (
          <p className="text-red-500 text-base pt-1">
            {formData?.amenities?.errors.recreationalFeatures}
          </p>
        )}
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
        <AddFeatureModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onSave={handleAddFeature}
          featureToEdit={featureToEdit}
          onUpdate={handleUpdateFeature}
        />
      </Modal>
    </div>
  );
};

export default RecreationalFeatures;
