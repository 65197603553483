import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Add carousel styles
import { MdClose, MdFullscreen } from "react-icons/md";
import ChevronRight from "@mui/icons-material/ChevronRight";
import GalleryVenue from "./GalleryVenue";

const HeroSection = ({ data = [], videos, basicInfo }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  // Toggle modal visibility
  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  // Open the modal with all photos
  const handleShowAllPhotos = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleFullScreen = () => {
    const elem = document.querySelector(".custom-carousel");
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div className="p-6">
      {/* Main image and right side images */}
      <div className="flex justify-start items-center gap-1  mb-4 lg:gap-0 lg:px-8 px-0">
        <Link to="/" className="text-[#b0b0b0] text-[14px]">
          Home
        </Link>
        <ChevronRight
          style={{
            fontSize: "22px",
            color: "#b0b0b0",
          }}
        />
        <Link to="/listing/1" className="text-[#b0b0b0] text-[14px]">
          Venues
        </Link>
        <ChevronRight
          style={{
            fontSize: "22px",
            color: "#b0b0b0",
          }}
        />
        <Link to="" className="text-[#b0b0b0] text-[14px]">
          {basicInfo?.hotelName}
        </Link>
        <ChevronRight
          style={{
            fontSize: "22px",
            color: "#b0b0b0",
          }}
        />
        <Link to="" className="text-[#000000] text-[14px]">
          {basicInfo?.venueName || "Venue"}
        </Link>
      </div>
      {/* Gallery */}
      <GalleryVenue
        photos={data}
        videos={videos}
        handleExpandClick={handleExpandClick}
        handleShowAllPhotos={handleShowAllPhotos}
      />

      {/* Modal for showing all photos */}
      {isModalOpen && (
        <div className="custom-carousel z-[5000] absolute inset-0 bg-black/80 flex items-center justify-center">
          <div className="text-[16px] text-white flex gap-x-2 absolute top-2 z-[5000]">
            <p>{`${activeIndex + 1} / ${data?.length + videos?.length} `}</p>
            <button
              className="text-white mr-3 text-lg cursor-pointer"
              onClick={toggleFullScreen}
            >
              <MdFullscreen />
            </button>
            <button
              className="text-white text-lg cursor-pointer"
              onClick={handleCloseModal}
            >
              <MdClose />
            </button>
          </div>

          <Carousel
            selectedItem={activeIndex}
            showArrows
            onChange={(index) => setActiveIndex(index)}
            showThumbs={true}
            showStatus={false}
            infiniteLoop
            className="custom-carousel"
            showIndicators={false}
            renderThumbs={() => [
              ...data?.map((image, index) => (
                <img
                  key={`thumb-${index}`}
                  src={image}
                  alt={`Thumbnail ${index}`}
                  className="object-cover w-full h-full rounded"
                />
              )),
              ...videos?.map((video, index) => {
                const isYouTubeLink =
                  video.includes("youtube.com") || video.includes("youtu.be");

                return isYouTubeLink ? (
                  <img
                    key={`thumb-video-${index}`}
                    src={`https://img.youtube.com/vi/${
                      video.split("v=")[1] || video.split("/").pop()
                    }/0.jpg`} // YouTube video thumbnail
                    alt={`Video Thumbnail ${index}`}
                    className="object-cover w-full h-full rounded"
                  />
                ) : (
                  <img
                    key={`thumb-video-${index}`}
                    src="/video-thumbnail-placeholder.png" // Placeholder for MP4 videos
                    alt={`Video Thumbnail ${index}`}
                    className="object-cover w-full h-full rounded"
                  />
                );
              }),
            ]}
          >
            {data?.map((image, index) => (
              <div className="md:h-[556px] h-[228px] max-h-[566px] max-w-[100vw]">
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index}`}
                  className="object-contain overflow-hidden h-full w-full"
                />
              </div>
            ))}
            {videos?.map((video, index) => {
              const isYouTubeLink =
                video.includes("youtube.com") || video.includes("youtu.be");

              return isYouTubeLink ? (
                <div
                  key={index + data?.length + 1}
                  className="w-[90%] sm:w-[69%] m-auto h-[590px] relative mb-1"
                >
                  <iframe
                    className="rounded-lg w-full h-full object-cover cursor-pointer"
                    src={`https://www.youtube.com/embed/${
                      video.split("v=")[1] || video.split("/").pop()
                    }`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded YouTube Video"
                    onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                    preload="metadata"
                  />
                </div>
              ) : (
                <video
                  key={index + data?.length + 1}
                  className="rounded-lg mb-4 sm:w-[69%] h-[590px] w-full object-cover cursor-pointer mx-auto"
                  controls
                  onLoadedMetadata={(e) => (e.target.currentTime = 1)}
                  preload="metadata"
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              );
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
