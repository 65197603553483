import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layout/dashboard-layout";
import EnquiryDashboard from "../../components/EnquiryVendorDashbaord/EnquiryDashboard/EnquiryDashboard";
import Enquirydetails from "../../components/EnquiryVendorDashbaord/Enquirydetails/Enquirydetails";
import { useUser } from "../../context/user";
import { fetchUserById } from "../../api/loggedUser";

export default function Enquiryvendor() {
  const {
    selectedEnquiryDetails,
    setSelectedEnquiryDetails,
    dashboardUser,
    setDashboardUser,
    activeEnquiryDetails,
    setActiveEnquiryDetails,
  } = useUser();
  const vendorId = localStorage.getItem("vendorId");
  const [user, setUser] = useState(null);
  const [dashboardPath, setDashboardPath] = useState("/dashboard");
  useEffect(() => {
    const fetchUser = async () => {
      try {
        let response;
        response = await fetchUserById(vendorId);
        setUser(response?.vendor);
        // Check if user has any listings
        const hasVenueListing = response?.vendor?.venueForms?.length > 0;
        const hasRestaurantListing =
          response?.vendor?.RestaurantForm?.length > 0;
        const hasHotelListing = response?.vendor?.Hotel?.length > 0;

        // Set path to vendor dashboard if admin AND has required listings
        if (
          response?.vendor?.isAdmin &&
          (hasVenueListing || hasRestaurantListing) &&
          hasHotelListing
        ) {
          setDashboardPath("/vendor-dashboard");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  return (
    <DashboardLayout>
      <div className="flex w-full py-4 justify-center">
        <div className="w-[90%] flex flex-col gap-5">
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <Link
                  to={dashboardPath}
                  className="text-[#FE4747] text-[10px] md:text-[16px]"
                >
                  Dashboard
                </Link>{" "}
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.813111 11.8536C0.617849 11.6583 0.617849 11.3417 0.813111 11.1464L5.79289 6.16667L0.813111 1.18689C0.617849 0.991625 0.617849 0.675041 0.813111 0.479779C1.00837 0.284517 1.32496 0.284517 1.52022 0.479779L6.85355 5.81311C7.04881 6.00838 7.04881 6.32496 6.85355 6.52022L1.52022 11.8536C1.32496 12.0488 1.00837 12.0488 0.813111 11.8536Z"
                    fill="#222222"
                  />
                </svg>
                <p
                  onClick={() => setActiveEnquiryDetails(false)}
                  className={`cursor-pointer ${
                    activeEnquiryDetails
                      ? "text-[10px] md:text-[16px] text-[#FE4747]"
                      : "text-black text-[10px] md:text-[16px]"
                  }`}
                >
                  Enquiry List
                </p>
                {
                  // only show when enquiry details are true
                  activeEnquiryDetails && (
                    <div className="flex gap-x-1 items-center">
                      <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.813111 11.8536C0.617849 11.6583 0.617849 11.3417 0.813111 11.1464L5.79289 6.16667L0.813111 1.18689C0.617849 0.991625 0.617849 0.675041 0.813111 0.479779C1.00837 0.284517 1.32496 0.284517 1.52022 0.479779L6.85355 5.81311C7.04881 6.00838 7.04881 6.32496 6.85355 6.52022L1.52022 11.8536C1.32496 12.0488 1.00837 12.0488 0.813111 11.8536Z"
                          fill="#222222"
                        />
                      </svg>
                      <p className="text-[10px] md:text-[16px]">
                        Enquiry Details
                      </p>
                    </div>
                  )
                }
              </div>
              <h2 className="text-xl font-medium md:text-2xl md:font-semibold">
                Enquiry Overview
              </h2>
            </div>
          </div>
          {
            /* EnquiryDashboard */
            !activeEnquiryDetails && (
              <EnquiryDashboard
                setActiveEnquiryDetails={setActiveEnquiryDetails}
                activeEnquiryDetails={activeEnquiryDetails}
                user={user}
              />
            )
          }
          {
            /* Details of enquiry */
            activeEnquiryDetails && (
              <Enquirydetails
                vednordetails={dashboardUser}
                selectedEnquiryDetails={selectedEnquiryDetails}
                setSelectedEnquiryDetails={setSelectedEnquiryDetails}
                user={user}
              />
            )
          }
        </div>
      </div>
    </DashboardLayout>
  );
}
