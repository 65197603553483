import axios from "axios";
import React, { useState } from "react";
import { base_URL } from "../../config/config";
import { useQuery } from "@tanstack/react-query";

function EnquiryStatusTableReport({ startDate, endDate, venueName }) {
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // for a team member invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const { data, isLoading } = useQuery({
    queryKey: [
      "enquiryStatusAnalysisTable",
      currentPage,
      pageSize,
      startDate,
      endDate,
      venueName,
    ],
    queryFn: async () => {
      const response = await axios.get(`${base_URL}/api/vendor-users`, {
        params: {
          vendorId: vendorId,
          page: currentPage,
          limit: pageSize,
          startDate: startDate ? startDate?.toLocaleDateString("en-CA") : null, // Converts to YYYY-MM-DD
          endDate: endDate ? endDate?.toLocaleDateString("en-CA") : null,
          venueName,
        },
      });
      return response?.data;
    },
  });

  // Pagination Handlers
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(Number(newPageSize));
    setCurrentPage(1); // Reset to first page when changing page size
  };
  const { primaryVendor, teamMembers, totalVendorEnquiries, pagination } =
    data || {};

  if (isLoading)
    return (
      <div className="h-[40vh] mt-4 rounded-lg animate-pulse bg-black/20"></div>
    );
  return (
    <div className="  rounded-xl inline  ">
      <div className="overflow-x-auto rounded-xl bg-white mt-4">
        <table className="w-full text-left  ">
          <thead className="bg-[#F4F4F4]/70 ">
            <tr>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Users name
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Total enquiry received
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry Accepted by user
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Successful Enquiry
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry declined by client
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry declined by venue
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {/* Primary Vendor Row */}
            {primaryVendor && (
              <tr className="hover:bg-gray-50 bg-gray-100">
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {primaryVendor?.name}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {primaryVendor?.totalEnquiries}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {primaryVendor?.acceptedEnquiries || 0}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {primaryVendor?.successfulEnquiries || 0}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {primaryVendor?.customerDeclinedEnquiries || 0}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {primaryVendor?.venueDeclinedEnquiries || 0}
                </td>
              </tr>
            )}
            {teamMembers?.map((member) => (
              <tr key={member?.email} className="hover:bg-gray-50">
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {member?.name}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {totalVendorEnquiries?.total}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {member?.acceptedEnquiries}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {member?.successfulEnquiries}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {member?.customerDeclinedEnquiries}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {member?.venueDeclinedEnquiries}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex flex-col md:flex-row items-center justify-end gap-x-3 px-6 py-3 border-t">
          <div className="flex items-center gap-2">
            <span className="text-sm text-[#222222]">Display:</span>
            <select
              value={pageSize}
              onChange={handlePageSizeChange}
              className="px-2 py-1 border rounded-lg"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">50</option>
            </select>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {pagination?.currentPage} of {pagination?.totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
              disabled={currentPage === pagination?.totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryStatusTableReport;
