import { Button, Typography } from "@mui/material";
import { ReactComponent as CouponIcon } from "../../../assets/images/coupon_icon.svg";

function TitleDescriptionCoupon({ title, description, isCoupon = false }) {
  return (
    <div className="flex flex-col items-center justify-center">
      <Typography
        variant="h4"
        variantMapping={{
          xs: "h5",
          md: "h4",
        }}
        className="text-[#222222]"
        gutterBottom
        sx={{
          mt: { xs: 3, md: 3 },
          fontWeight: "600",
          fontSize: { xs: "1.4rem", md: "2rem" },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        className="text-[#717171]"
        sx={{
          fontWeight: "500",
          textAlign: "center",
          mb: 1,
          fontSize: { xs: "1rem", md: "16px" },
        }}
      >
        {description}
      </Typography>
      {isCoupon && (
        <Button
          variant="contained"
          startIcon={<CouponIcon />}
          sx={{
            py: 1,
            width: "70%",
            backgroundColor: "green.light",
            color: "green.text",
            textTransform: "none",
            mt: 2,
            mb: 2,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "rgba(3, 159, 141, 0.2)",
            },
          }}
          // fullWidth
        >
          Start your Free 6 Month Trial
        </Button>
      )}
    </div>
  );
}

export default TitleDescriptionCoupon;
