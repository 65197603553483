import React, { useCallback, useState } from "react";
import { Modal } from "./SettingsDashboard";
import AWS from "aws-sdk";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { base_URL } from "../../config/config";
import { CoPresentOutlined } from "@mui/icons-material";

// AWS Configuration
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
function ProfilePhoto({ user }) {
  const [photoModal, setPhotoModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const queryClient = useQueryClient();
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  // Mutation for updating profile photo
  const updateProfileMutation = useMutation({
    mutationFn: async (profilePic) => {
      const response = await axios.patch(
        `${base_URL}/api/dashboard/upload-pic`,
        {
          vendorId: user?._id,
          profilePic,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("vendor");
      // queryClient.invalidateQueries("user");
      setPhotoModal(false);
    },
  });

  // Handle file upload to S3 and then update DB
  const handleFileUpload = useCallback(
    async (file) => {
      const fileName = `profile-photos/profile-${
        user?._id
      }-${Date.now()}.${file?.name.split(".").pop()}`;

      const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileName,
        ContentType: file.type,
      };

      try {
        const upload = myBucket.upload(params);

        upload.on("httpUploadProgress", (evt) => {
          setUploadProgress(Math.round((evt.loaded / evt.total) * 100));
        });

        const result = await upload.promise();
        const imageUrl = result.Location;

        // Update profile pic URL in database
        await updateProfileMutation.mutateAsync(imageUrl);
      } catch (error) {
        console.error("Upload error:", error);
      }
    },
    [user?._id, updateProfileMutation]
  );

  // Handle camera capture
  const handleCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // Create video element to show stream
      const video = document.createElement("video");
      video.srcObject = stream;
      await video.play();

      // Create canvas to capture frame
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Capture frame
      canvas.getContext("2d").drawImage(video, 0, 0);

      // Convert to file
      canvas.toBlob((blob) => {
        handleFileUpload(
          new File([blob], "profile.jpg", { type: "image/jpeg" })
        );
      }, "image/jpeg");

      // Stop camera
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Camera error:", error);
    }
  };

  // Handle gallery selection
  const handleGallery = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        handleFileUpload(file);
      }
    };
    input.click();
  };
  return (
    <>
      <div className="flex items-center justify-center mb-8">
        <div className="relative">
          {/* Profile Photo Circle */}
          <div
            onClick={() => setPhotoModal(true)}
            className="cursor-pointer active:opacity-55"
          >
            {user?.profilePic ? (
              <img
                src={user?.profilePic}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover"
              />
            ) : (
              <img src="/profileopen.svg" alt="Default profile" />
            )}
          </div>
        </div>
      </div>

      {/* Photo Update Modal */}
      <Modal isOpen={photoModal} onClose={() => setPhotoModal(false)}>
        <div className="w-[280px] md:w-[520px] mx-auto">
          <h2 className="text-center font-bold mb-8 text-[22px] lg:text-[32px]">
            Update Profile Photo
          </h2>

          {uploadProgress > 0 && uploadProgress < 100 && (
            <div className="mb-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          )}

          {/* Camera Option */}
          <button
            className="w-full flex items-center gap-3  px-4 active:opacity-55 rounded-lg transition-colors"
            onClick={handleCamera}
          >
            <img src="/cameraopen.svg" alt="" />
            <span className="text-[16px] font-normal">Camera</span>
          </button>

          <div className="border-t mt-[16px]  border-[#DDDDDD]"></div>

          {/* Gallery Option */}
          <button
            className="mb-[35px] mt-[16px] w-full flex items-center gap-3  active:opacity-55 px-4  rounded-lg transition-colors"
            onClick={handleGallery}
          >
            <img src="/galleryopen.svg" alt="" />
            <span className="text-[16px] font-normal">Gallery</span>
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ProfilePhoto;
