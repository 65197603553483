export const VenuePopup = ({ venues, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div className="bg-white w-[20%] p-4 rounded-xl shadow-lg">
      <h3 className="font-semibold text-[16px] text-center  mb-6">
        Assigned Venues
      </h3>
      <ol className="list-decimal pl-5 mb-2">
        {" "}
        {venues?.map((venue, index) => (
          <li key={index} className="py-1">
            {venue}
          </li>
        ))}
      </ol>
      <button
        className="mt-2 bg-red-500 text-white w-full py-2 px-3 rounded-lg"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  </div>
);
