"use client";
import React, { useRef, useState } from "react";
import Navbar from "../../components/webComps/Navbar";
import { IoIosArrowForward } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { MdCalendarToday } from "react-icons/md";
import { IoMapOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { PiTag } from "react-icons/pi";

// import img from "../../assets/pics/fmvImage.png";
import img from "../../assets/pics/Location.png";
import ourstory from "../../assets/pics/ourstory.png";
import mission from "../../assets/pics/mission.png";
import vision from "../../assets/pics/vision.png";
import Footer from "../../components/webComps/Footer";
import aboutBg from "../../assets/pics/aboutbg.png";

import { Link } from "react-router-dom";
const About = () => {
  const [activeTab, setActiveTab] = useState("venueOperators");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const heroAfterRef = useRef(null);

  const scrollToHeroAfter = () => {
    if (heroAfterRef.current) {
      heroAfterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="overflow-hidden  bg-[#FCFCFC] ">
      <div ref={heroAfterRef}>
        <Navbar />
      </div>
      <div
        style={{ backgroundImage: `url(${aboutBg})` }}
        className="w-full xl:h-[80vh] lg:h-[70vh] h-[60vh] ] bg-center bg-cover relative flex justify-center items-center bg-no-repeat"
      >
        <div className="bg-[#000000] bg-opacity-80 absolute top-0 left-0 w-full h-full z-40"></div>
        <div className="flex items-center text-xs absolute top-4 left-8 z-50">
          <Link to="/home">
            <p className="text-[#B0B0B0]">Home</p>
          </Link>
          <IoIosArrowForward className="text-white" />
          <p className="text-[#B0B0B0]">Search Results</p>
          <IoIosArrowForward className="text-white" />
          <Link to="/about">
            <p className="text-[#FE4747]">About Us</p>
          </Link>
        </div>
        <div className="text-white flex flex-col items-center z-50 gap-3 lg:w-[45%] w-[70%]">
          <h1 className="sm:text-[64px] text-3xl text-center font-[500]">
            About Us
          </h1>
          <div>
            <p className="text-center sm:text-lg text-sm text-white font-[400] mt-2">
              We connect event planners with exceptional venues across the UAE.
            </p>
            {/* <p className="text-center sm:text-lg text-sm">
              Expertise in Seamless Venue Discovery for Your Ideal Space and
              Budget
            </p>
            <p className="text-center sm:text-lg text-sm text-[#FE4747]">
              We specialize in helping you find the perfect place at the perfect
              price, making your search effortless and hassle-free.
            </p>
            <p className="text-center sm:text-lg text-sm">
              We simplify your search for the perfect place at the perfect
              price.
            </p> */}
          </div>
        </div>
      </div>
      <div className="flex justify-center py-16">
        <div className="w-[88%] flex flex-col gap-20">
          {/* Effortless Sections */}
          <div className="flex lg:flex-row flex-col-reverse items-start gap-12">
            <div className="lg:w-1/2 w-full flex lg:justify-start justify-center  flex-col items-start gap-4">
              <h1 className="text-4xl font-semibold">Who are we</h1>

              <p className="text-[20px] text-justify text-[#717171]">
                <span className="text-black">FindMyVenue</span> is the UAE’s
                most comprehensive platform for showcasing event spaces to a
                wide audience actively seeking venues. At{" "}
                <span className="text-black">FindMyVenue</span>, we help you
                make the right connections that grow into lasting relationships.
                Through innovative technology, we do more than just list event
                spaces - we match their unique features with the specific needs
                of event planners.
              </p>
            </div>
            <div className="lg:w-1/2 w-full flex lg:justify-start justify-center">
              <img src={img} alt="" />
            </div>
          </div>

          {/* <div className="flex flex-col items-center gap-8 relative">
                      <img
                        src={shadeRight}
                        className="w-full h-full absolute top-20 -right-[30%]"
                        alt=""
                      />
                      <img
                        src={shadeLeft}
                        className="w-full h-full absolute top-20 -left-[30%]"
                        alt=""
                      />
                      <div className="w-full">
                        <img src={banner} className="w-full h-full" alt="" />
                      </div>
                      <h1 className="sm:text-4xl text-3xl font-semibold mt-6">
                        From idea to innovation: Our story
                      </h1>
                      <p className="sm:text-lg text-sm text-justify">
                        Find My Venue was born from a simple desire; to create a seamless
                        way to discover unique event spaces. It all began when our
                        founders, Jharna and Harsha, seasoned event planners, J witnessed
                        firsthand the frustration of clients struggling to find
                        distinctive venues. Struggling through countless websites and
                        outdated directories, they realized there had to be a better way.
                        Inspired to streamline this process, they assembled a team of
                        like-minded individuals passionate about innovation in the events
                        industry. Their shared vision sparked the idea of Find My Venue, a
                        premier platform for efficient venue discovery and a reliable lead
                        generator for venue managers.
                      </p>
                    </div> */}

          {/* Our Story */}

          <div className="flex lg:flex-row-reverse flex-col items-start gap-12">
            <div className="lg:w-1/2 w-full flex lg:justify-start justify-center  flex-col items-start gap-4">
              <h1 className="text-4xl font-semibold">Our Story</h1>

              <p className="text-[20px] text-justify text-[#717171]">
                We are <span className="text-black">Jharna Kalwani</span> and{" "}
                <span className="text-black">Harsha Khubchandani</span> ,
                award-winning luxury event management experts and founders of
                Red Velvet Events, where we crafted bespoke, high-end
                experiences across the UAE and beyond. With 14 years of skin in
                the game, we saw firsthand how difficult it was for event
                planners to find unique venues that perfectly matched their
                requirements. The search process was often tedious, leaving both
                venue seekers and managers frustrated. That’s why we launched
                Find My Venue - a solution designed for effortless venue
                discovery at your fingertips.
              </p>

              <h1 className="text-2xl font-semibold text-[#717171]">
                <span className=" text-[#FE4747]">FindMyVenue:</span> Where
                Vision Meets Venue
              </h1>
            </div>
            <div className="lg:w-1/2 w-full lg:justify-start justify-center lg:block hidden">
              <img className="w-full" src={ourstory} alt="" />
            </div>
          </div>

          {/* Our Mission */}

          <div className="flex bg-[#ffebea] rounded-md shadow-lg lg:flex-row pb-6 flex-col-reverse items-start gap-8">
            <div className="lg:w-1/2 w-full lg:mt-8 flex lg:justify-start justify-center flex-col items-start p-6 gap-4">
              <h1 className="text-4xl font-bold  text-[#FE4747]">Mission</h1>
              <p className="text-[20px] text-[#717171] text-justify">
                To simplify venue discovery and create connections between event
                planners and venue operators using innovative technology. We aim
                to transform the event landscape, driving mutual success for
                all.
              </p>
            </div>
            <div className="lg:w-1/2 w-full flex lg:justify-center justify-center ">
              <img
                className="w-64 h-auto rounded-md object-cover lg:ml-4"
                src={mission}
                alt="Our Mission"
              />
            </div>
          </div>

          {/* Our Vision */}

          <div className="flex bg-[#ebebeb] rounded-md shadow-lg lg:flex-row-reverse pt-6 flex-col-reverse items-start gap-8">
            <div className="lg:w-1/2 w-full mt-8 flex lg:justify-start justify-center flex-col items-start p-6 gap-4">
              <h1 className="text-4xl font-bold text-[#FE4747]">Vision</h1>
              <p className="text-md text-[#717171] text-justify">
                To simplify venue discovery and create connections between event
                planners and venue operators using innovative technology. We aim
                to transform the event landscape, driving mutual success for
                all.
              </p>
            </div>
            <div className="lg:w-1/2 w-full h-full flex lg:justify-center justify-center items-end">
              <img
                className="w-64 h-auto rounded-md object-cover lg:ml-4"
                src={vision}
                alt="Our Vision"
              />
            </div>
          </div>

          {/* Find My Venue */}
          <div className="md:flex justify-between items-center">
            <div className="md:w-[30%] w-full">
              <h1 className="sm:text-4xl text-3xl sm:text-start text-center font-semibold ">
                The Find My Venue Advantage
              </h1>
            </div>

            <div className=" flex flex-col items-center gap-4 rounded-xl md:mt-4 mt-0  p-6 sm:px-12 px-4 md:w-[57%] w-full">
              <div className="flex sm:gap-8 gap-4  justify-start w-full">
                <div
                  className={`sm:text-[20px] text-[18px] cursor-pointer border-b-2 ${
                    activeTab === "venueOperators"
                      ? "text-[#222222] font-[500] border-b-[#27c2b0]"
                      : "text-[#717171] font-[400] border-#[717171]"
                  }`}
                  onClick={() => handleTabClick("venueOperators")}
                >
                  <h1>For Venue Operators</h1>
                </div>
                <div
                  className={`sm:text-[20px] text-[18px] cursor-pointer border-b-2 ${
                    activeTab === "venueseekers"
                      ? "text-[#222222] font-[500] border-b-[#27c2b0]"
                      : "text-[#717171] font-[400] border-b-[#717171]"
                  }`}
                  onClick={() => handleTabClick("venueseekers")}
                >
                  <h1>For Venue Seekers</h1>
                </div>
              </div>

              <div className="flex flex-col gap-10 mt-5">
                {activeTab === "venueOperators" && (
                  <div className="gap-8">
                    <div className="flex items-center border border-gray-300 rounded-md px-6 py-8 mb-2 shadow-sm">
                      <div className="w-[93%]">
                        <h1 className="flex justify-start gap-2 items-center sm:text-2xl text-xl font-semibold cursor-pointer">
                          <IoEyeOutline className="h-6 w-6 text-[#FE4747] mr-2" />
                          Boosted Visibility
                        </h1>
                        <p className="text-lg text-[#717171] mt-2">
                          Increase your venue's visibility to event planners
                          with our aggressive marketing efforts.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center border border-gray-300 rounded-md px-6 py-8 mb-2 shadow-sm">
                      <div className="w-[93%]">
                        <h1 className="flex justify-start gap-2 items-center sm:text-2xl text-xl font-semibold cursor-pointer">
                          <MdCalendarToday className="h-6 w-6 text-[#FE4747] mr-2" />
                          Year-round bookings
                        </h1>
                        <p className="text-lg text-[#717171] mt-2">
                          Minimize downtime with consistent bookings throughout
                          the year.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center border border-gray-300 rounded-md px-6 py-8 mb-2 shadow-sm">
                      <div className="w-[93%]">
                        <h1 className="flex justify-start gap-2 items-center sm:text-2xl text-xl font-semibold cursor-pointer">
                          <IoMapOutline className="h-6 w-6 text-[#FE4747] mr-2" />
                          Global Exposure
                        </h1>
                        <p className="text-lg text-[#717171] mt-2">
                          Tap into a worldwide network of event planners,
                          expanding your venue's reach beyond borders.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "venueseekers" && (
                  <div className="gap-8">
                    <div className="flex items-center border border-gray-300 rounded-md px-6 py-8 mb-2 shadow-sm">
                      <div className="w-[93%]">
                        <h1 className="flex justify-start gap-2 items-center sm:text-2xl text-xl font-semibold cursor-pointer">
                          <CiSearch className="h-6 w-6 text-[#FE4747] mr-2" />
                          Hassle-Free Search
                        </h1>
                        <p className="text-lg text-[#717171] mt-2">
                          Use advanced technology and filters to quickly find
                          the perfect venue that matches your specific needs.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center border border-gray-300 rounded-md px-6 py-8 mb-2 shadow-sm">
                      <div className="w-[93%]">
                        <h1 className="flex justify-start gap-2 items-center sm:text-2xl text-xl font-semibold cursor-pointer">
                          <CiLocationOn className="h-6 w-6 text-[#FE4747] mr-2" />
                          Unique Venues
                        </h1>
                        <p className="text-lg text-[#717171] mt-2">
                          We list distinctive venues across the UAE that are
                          otherwise difficult to discover.
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center border border-gray-300 rounded-md px-6 py-8 mb-2 shadow-sm">
                      <div className="w-[93%]">
                        <h1 className="flex justify-start gap-2 items-center sm:text-2xl text-xl font-semibold cursor-pointer">
                          <PiTag className="h-6 w-6 text-[#FE4747] mr-2" />
                          Absolutely Free
                        </h1>
                        <p className="text-lg text-[#717171] mt-2">
                          Explore endless list of venues with no hidden charges
                          or expenses.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Verified Section */}
          {/* <div className="flex lg:flex-row flex-col items-start gap-12">
            <div className="lg:w-1/2 w-full flex lg:justify-start justify-center">
              <img src={trustImg} alt="" />
            </div>
            <div className="lg:w-1/2 w-full flex flex-col items-start gap-4">
              <h1 className="sm:text-4xl text-3xl font-semibold">
                Verified Venues Vetted By Our Team
              </h1>
              <p className="sm:text-lg text-sm text-justify">
                All venue listings are personally vetted by our team. We
                meticulously research and curate a collection of exceptional
                event spaces. Discover hidden gems, rooftop terraces, art
                galleries, and more across the UAE, catering to a variety of
                event themes, sizes, and budgets.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <div>
        <Footer onScrollDownClick={scrollToHeroAfter} />
      </div>
    </div>
  );
};

export default About;
