import { useParams } from "react-router-dom";
import Hero from "../events/components/Hero";
import NavbarCustomer from "../../../components/NavbarCustomer";
import Listing from "../../home/listing/Listing";
import ExploreCities from "./ExploreCities";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";

const City = ({ filters, setFilters }) => {
  const { city } = useParams();
  const count = localStorage.getItem("cityTotalCount");
  return (
    <div>
      <NavbarCustomer filters={filters} setFilters={setFilters} />
      <div className="text-[#717171] flex items-center my-4 md:mx-8 mx-1 gap-2">
        <Link to="/">Home</Link>
        <RiArrowRightSLine />
        <p className="text-dark">{city}</p>
      </div>
      <Hero
        filters={filters}
        setFilters={setFilters}
        eventTypeName={""}
        cityName={city}
        cityTotalCount={count}
      />
      <ExploreCities city={city} />
      {/* <div className="min-h-[50px]"></div> */}
      <h2 className="md:px-8 px-4 text-[#222222] md:text-[32px] text-[18px] font-semibold mb-0">
        Search results for all venues in {city}
      </h2>
      <Listing filters={filters} setFilters={setFilters} showNav={false} />
    </div>
  );
};

export default City;
