/* eslint-disable */
import Navbar from "../../../components/NavbarCustomer";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { useEffect, useState } from "react";
import Card from "./Card";
import LastSearch from "./LastSearch";
import { useNavigate } from "react-router-dom";
import { base_URL } from "../../../config/config";
import toast from "react-hot-toast";

const tabs = [
  {
    title: "Current",
    count: 0,
  },
  {
    title: "Past",
    count: 0,
  },
];

const getDaysPassed = (date) => {
  const currentDate = new Date();
  const activityDate = new Date(date);
  const diffTime = Math.abs(currentDate - activityDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const Enquiry = ({ filters, setFilters }) => {
  const searchData = JSON.parse(localStorage.getItem("lastSearchData"));
  const customerId = JSON.parse(localStorage.getItem("authUser"))._id;
  customerId.replace('"', "");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [tabCount, setTabCount] = useState({ Current: 0, Past: 0 });
  const [enquiries, setEnquiries] = useState([]);

  const fetchEnquiries = async () => {
    const fetchPayload = {
      customerId,
    };
    try {
      const response = await fetch(`${base_URL}/api/enquiry/getEnquiry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fetchPayload),
      });
      // console.log(response);
      const data = await response.json();
      // console.log(data.enquiries);
      setEnquiries(data.enquiries);
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setTabCount({
      Current: enquiries.length,
    });
  }, [enquiries]);

  const closeEnquiry = async (enquiryId) => {
    try {
      const response = await fetch(`${base_URL}/api/enquiry/updateEnquiry`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          enquiryId: enquiryId,
          status: "Customer Declined",
          statusMessage: "Enquiry Declined by Customer",
        }),
      });
      // console.log(response);
      const data = await response.json();
      // console.log(data.enquiries);
      setEnquiries((prev) =>
        prev.filter((item) =>
          item._id === enquiryId ? (item.status = "Customer Declined") : item
        )
      );
      toast.success("Enquiry Closed Successfully");
      return data;
    } catch (err) {
      // console.log(err);
      toast.error("Error in closing Enquiry");
    }
  };

  useEffect(() => {
    fetchEnquiries();
  }, []);

  const handleActiveTab = (index) => {
    setActiveTab(index);
  };

  const handleCardClick = (venueData) => {
    const id = venueData._id;
    navigate(`/messages/${id}`);
  };
  return (
    <div className="bg-[#f9f9fc] min-h-[100vh]">
      <Navbar filters={filters} setFilters={setFilters} />
      <div className="h-full mx-auto md:w-[85%] w-[90%] mt-7">
        <div className="flex justify-start items-center gap-1">
          <Link to="/" className=" text-[#C2C2C2] text-[14px] ">
            Home
          </Link>
          <FaAngleRight className="text-[#C2C2C2] text-[16px]" />
          <Link to="#" className="text-black text-[14px]">
            My Enquiries
          </Link>
        </div>
        <h1 className="text-black text-[24px] font-[500] my-9">My Enquiries</h1>
        <div className="flex justify-start items-center gap-5">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`flex items-center gap-1 cursor-pointer ${
                index === activeTab
                  ? "border-b-2 border-[#FE4747] text-[#FE4747]"
                  : "text-black"
              }`}
              onClick={() => handleActiveTab(index)}
            >
              <p className=" text-[14px]">{tab.title}</p>
              <span className="text-[14px]">
                (
                {tab.title === "Current"
                  ? tabCount.Current
                  : tabCount.Past || 0}
                )
              </span>
            </div>
          ))}
        </div>
        <div className="w-full mt-10 ">
          {enquiries?.length > 0 &&
            enquiries?.map((item) => {
              const daysPassed = getDaysPassed(item.startDate);
              return (
                <Card
                  key={item._id}
                  data={item}
                  daysPassed={daysPassed}
                  onClick={handleCardClick}
                  close={closeEnquiry}
                  fetchEnquiries={fetchEnquiries}
                />
              );
            })}
        </div>
        <div className="w-full py-11">
          <h1 className="text-black text-[24px] font-[500] mb-3">
            Browse your last search
          </h1>
          {searchData && <LastSearch data={searchData} />}
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
