import React from "react";
import { Button, Typography, Box } from "@mui/material";
import Tooltip from "../venuePreview/Tooltip";
import { useTheme } from "@mui/material/styles";

function IconButtonWithTooltip({
  label,
  icon,
  tooltip,
  isSelectedButton,
  handleButtonClick,
}) {
  const theme = useTheme();
  // Handler to stop event propagation when clicking on the tooltip
  const handleTooltipClick = (e) => {
    e.stopPropagation();
  };
  return (
    <Button
      variant="contained"
      sx={{
        height: "48px",
        borderRadius: "8px",
        boxShadow: "none",
        color: isSelectedButton
          ? theme.palette.primary.main
          : theme.palette.grey.text,
        backgroundColor: isSelectedButton
          ? theme.palette.primary.light
          : theme.palette.common.white,
        border: `1px solid ${
          isSelectedButton
            ? theme.palette.primary.main
            : theme.palette.grey.border
        }`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingLeft: "12px",
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
          color: "#fe4747", // Changes button text color on hover
          borderColor: "#fe4747", // Changes border color on hover
          "& .icon": {
            color: "#fe4747", // Changes icon color on hover
          },
          "& .label": {
            color: "#fe4747", // Changes label color on hover
          },
        },
      }}
      onClick={handleButtonClick}
    >
      <Box className="icon" sx={{ marginRight: 2 }}>
        {icon}
      </Box>
      <Typography
        className="label"
        sx={{
          textTransform: "none",
          fontSize: "14px",
          textAlign: "left",
          flexGrow: 1,
          color: "#717171", // Initial color for text label
        }}
      >
        {label}
      </Typography>
      <Box
        onClick={handleTooltipClick}
        sx={{ marginLeft: "", textAlign: "left" }}
      >
        <Tooltip text={tooltip} />
      </Box>
    </Button>
  );
}

export default IconButtonWithTooltip;
