import { useQuery } from "@tanstack/react-query";
import React from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { base_URL } from "../../config/config";
import axios from "axios";

function CountryGraph({ startDate, endDate, venueName }) {
  // for a team member invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const { data, isLoading } = useQuery({
    queryKey: ["countryAnalysisGraph", startDate, endDate, venueName],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/country-enquiries-analysis/graph`,
        {
          params: {
            vendorId: vendorId,
            startDate: startDate
              ? startDate?.toLocaleDateString("en-CA")
              : null, // Converts to YYYY-MM-DD
            endDate: endDate ? endDate?.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return response?.data;
    },
  });
  if (isLoading)
    return (
      <div className="h-[20vh] mt-[32px] rounded-lg animate-pulse bg-black/20"></div>
    );
  return (
    <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px]">
      <div className="mb-8">
        <p className="text-[14px] font-medium">
          Enquiries Received by Countries Report
        </p>
        <p className="text-[12px] text-[#00000005]/60 ">
          Enquiries received per country
        </p>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data}>
          <XAxis dataKey="name" tick={{ fontSize: 12 }} tickLine={false} />
          <YAxis
            type="number"
            tickLine={false}
            domain={[0, "dataMax"]}
            allowDecimals={false}
            tick={{ fontSize: 12 }}
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <Tooltip
            content={({ payload }) => {
              if (!payload || payload.length === 0) return null;
              const value = payload[0].value; // Access the first value only
              return (
                <div className="tooltip text-[#4990FB] bg-white rounded-lg p-3 shadow-md">
                  <p>{`Enquiries : ${value}`}</p>
                </div>
              );
            }}
          />

          {/* <Legend /> */}
          <Area
            type="monotone"
            dataKey="value"
            stroke="none"
            fill="rgba(73, 144, 251, 0.06)"
            // fillOpacity={0.3}
          />
          <Bar
            dataKey="value"
            fill="#4990FB"
            barSize={window.innerWidth < 640 ? 20 : 80}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CountryGraph;
