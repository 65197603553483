import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { base_URL } from "../../config/config";
import toast from "react-hot-toast";
import axios from "axios";

const useUpdatePassword = () => {
  const updatePasswordFn = async ({ email, oldPassword, newPassword }) => {
    const response = await axios.post(
      `${base_URL}/api/dashboard/update-password`,
      {
        email,
        oldPassword,
        newPassword,
      }
    );
    return response.data;
  };

  return useMutation({
    mutationFn: updatePasswordFn,
    onSuccess: (_, { setPassModal }) => {
      toast.success("Password updated successfully!");
      setPassModal(false);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to update password");
    },
  });
};
function PassModal({ user, setPassModal }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const mutation = useUpdatePassword();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New Password and Confirm Password do not match");
      return;
    }

    mutation.mutate({
      email: user?.email,
      oldPassword,
      newPassword,
      setPassModal,
    });
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 md:w-[400px] mx-auto  bg-white rounded-xl"
    >
      <h2 className="text-center font-bold mb-4 text-[22px] lg:text-[32px]">
        Change Password?
      </h2>

      {/* Old Password */}
      <div className="relative">
        <label className="mt-[32px]   text-[14px] font-normal text-[#5E5E5E]">
          Old Password
        </label>
        <input
          type={showOldPassword ? "text" : "password"}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="Enter Old Password"
          className="w-full mt-1 md:w-[400px] px-4 placeholder:text-[#B0B0B0] placeholder:text-[14px] py-2 border rounded-lg focus:outline-none focus:ring-0 "
        />
        <button
          type="button"
          className="absolute right-3 top-10"
          onClick={() => setShowOldPassword(!showOldPassword)}
        >
          {showOldPassword ? <IoMdEyeOff size={20} /> : <IoMdEye size={20} />}
        </button>
      </div>

      {/* New Password */}
      <div className="relative">
        <label className="mt-[32px]   text-[14px] font-normal text-[#5E5E5E]">
          New Password
        </label>
        <input
          type={showNewPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter New Password"
          className="w-full mt-1 md:w-[400px] px-4 placeholder:text-[#B0B0B0] placeholder:text-[14px] py-2 border rounded-lg focus:outline-none focus:ring-0 "
        />
        <button
          type="button"
          className="absolute right-3 top-10"
          onClick={() => setShowNewPassword(!showNewPassword)}
        >
          {showNewPassword ? <IoMdEyeOff size={20} /> : <IoMdEye size={20} />}
        </button>
      </div>

      {/* Confirm New Password */}
      <div className="relative">
        <label className="mt-[32px]   text-[14px] font-normal text-[#5E5E5E]">
          Confirm New Password
        </label>
        <input
          type={showConfirmPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Enter Confirm Password"
          className="w-full mt-1 md:w-[400px] px-4 placeholder:text-[#B0B0B0] placeholder:text-[14px] py-2 border rounded-lg focus:outline-none focus:ring-0 "
        />
        <button
          type="button"
          className="absolute right-3 top-10"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
        >
          {showConfirmPassword ? (
            <IoMdEyeOff size={20} />
          ) : (
            <IoMdEye size={20} />
          )}
        </button>
      </div>

      {/* Submit Button */}
      <div className="flex justify-center">
        <button
          type="submit"
          className="mt-[20px] md:mt-[40px] active:opacity-75 bg-[#FF3939] text-white w-full py-2 rounded-xl"
          disabled={mutation.isLoading}
        >
          {mutation.isLoading ? "Updating..." : "Submit"}
        </button>
      </div>
    </form>
  );
}

export default PassModal;
