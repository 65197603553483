import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { RxCross2 } from "react-icons/rx";
import { getCityCoordinates } from "../map/getCityCoordinates";

const SinglevenueMap = ({ venue }) => {
  const [coordinates, setCoordinates] = useState({
    lat: venue.location?.lat || 0,
    lng: venue.location?.lng || 0,
  });

  const [selectedVenue, setSelectedVenue] = useState(null);

  useEffect(() => {
    const cityCoordinates = getCityCoordinates(venue.location?.city);
    if (cityCoordinates) {
      setCoordinates(cityCoordinates);
    }
  }, [venue]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg", // replace with your actual API key
  });

  const handleMarkerHover = () => {
    setSelectedVenue(venue);
  };

  const handleMouseOut = () => {
    setSelectedVenue(null);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div id="map" className="md:border-t border-[#EBEBEB] md:pt-7">
      <h2 className="md:text-2xl text-[20px] md:font-bold font-semibold mb-4">
        Location
      </h2>
      <div className="md:h-[75vh] h-[50vh] w-full relative">
        <GoogleMap
          center={coordinates}
          zoom={14}
          mapContainerStyle={{ height: "100%", width: "100%" }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          {/* Marker */}
          <Marker
            position={coordinates}
            onMouseOver={handleMarkerHover}
            onMouseOut={handleMouseOut}
          />

          {/* Show Venue Details on Hover */}
          {selectedVenue && (
            <div className="venue-details absolute top-1/2 left-1/2 -translate-x-1/2 w-auto p-1 bg-white shadow-lg rounded-lg z-10">
              <div className="p-2">
                <button
                  onClick={() => setSelectedVenue(null)}
                  className="absolute top-2 right-2  p-0 rounded-full shadow-md"
                >
                  <RxCross2 size={18} />
                </button>

                <h1 className="text-lg font-semibold">
                  {selectedVenue.basicDetails?.venueName}
                </h1>
                <p className="text-gray-600">
                  {selectedVenue.location?.city}, {selectedVenue.location?.area}
                </p>

                {/* Price Information */}
                <div className="text-red-600 font-semibold text-lg">
                  AED{" "}
                  {selectedVenue.pricing?.["Minimum Spend Pricing"]?.[0]
                    ?.minSpend || "N/A"}
                </div>
                <p className="text-gray-600 text-sm">Min. spend</p>
              </div>
            </div>
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default SinglevenueMap;
