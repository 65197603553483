import axios from "axios";
import React, { useState } from "react";
import { base_URL } from "../../config/config";
import { useQuery } from "@tanstack/react-query";

function AvgBudgetTableReport({ startDate, endDate, venueName }) {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Retrieve vendor ID
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  // Fetch data using React Query
  const { data, isLoading } = useQuery({
    queryKey: [
      "device-category-budgets",
      currentPage,
      pageSize,
      startDate,
      endDate,
      venueName,
    ],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/device-category-budgets`,
        {
          params: {
            vendorId: vendorId,
            page: currentPage,
            limit: pageSize,
            startDate: startDate
              ? startDate?.toLocaleDateString("en-CA")
              : null, // Converts to YYYY-MM-DD
            endDate: endDate ? endDate?.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return response?.data;
    },
  });

  // Pagination handlers
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < data?.pagination?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing page size
  };

  if (isLoading)
    return (
      <div className="h-[40vh] mt-4 rounded-lg animate-pulse bg-black/20"></div>
    );
  return (
    <div className="rounded-xl inline">
      <div className="overflow-x-auto rounded-xl bg-white mt-4">
        <table className="w-full text-left">
          <thead className="bg-[#F4F4F4]/70">
            <tr>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Device Category
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Number of enquiry received avg budget
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Accepted Enquiry avg budget
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Successful enquiry avg budget
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry declined by client avg budget
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry declined by venue avg budget
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {data?.budgetData?.map((budget, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {budget?.deviceCategory || "Old devices"}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {budget?.averageBudget}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {budget?.acceptedEnquiriesAvgBudget}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {budget?.successfulEnquiriesAvgBudget}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {budget?.declinedByClientAvgBudget}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {budget?.declinedByVendorAvgBudget}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination (optional) */}
        <div className="flex items-center justify-end gap-x-3 px-6 py-3 border-t">
          <div className="flex items-center gap-2">
            <span className="text-sm text-[#222222]">Display:</span>
            <select
              value={pageSize}
              onChange={handlePageSizeChange}
              className="px-2 py-1 border rounded-lg"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
            >
              Previous
            </button>
            <span className="text-sm text-[#222222]">
              Page {currentPage} of {data?.pagination?.totalPages || 1}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === (data?.pagination?.totalPages || 1)}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvgBudgetTableReport;
