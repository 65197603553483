"use client";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { BiChair } from "react-icons/bi";
import { LuPersonStanding } from "react-icons/lu";
import houses from "../../assets/pics/houses.svg";
import { useNavigate } from "react-router-dom";

const HotelChildCards = ({ data }) => {
  console.log(data, "to show");
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleFavorite = (itemIndex, imageIndex) => {
    setFavorites((prevState) => ({
      ...prevState,
      [itemIndex]: {
        ...prevState[itemIndex],
        [imageIndex]: !prevState[itemIndex]?.[imageIndex],
      },
    }));
  };
  const handleViewPrev = (id) => {
    const cf = "hpv";
    navigate(`/preview/${cf}/${id}`);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const mainCarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // lg
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // md
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // sm
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col border-t border-b pb-8 pt-4">
        <div>
          <p className="text-[#222222] text-[32px] font-bold  font-outfit mb-3">
            Other venues available at this space
          </p>
        </div>
        <div
          className={`w-full ${isMobileView ? "" : "grid grid-cols-3 gap-6"}`}
        >
          {isMobileView ? (
            <Slider {...mainCarouselSettings}>
              {data &&
                data?.slice(0, 9).map((item, itemIndex) => (
                  <div key={itemIndex} className="p-2">
                    <div
                      className="bg-white rounded-lg shadow-lg cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        handleViewPrev(item?.id);
                      }}
                    >
                      {item?.imagesAndVideos?.images?.length > 1 ? (
                        <Slider {...imageSettings}>
                          {item?.imagesAndVideos?.images?.map(
                            (e, imageIndex) => (
                              <div key={imageIndex} className="w-full relative">
                                <img
                                  src={
                                    item?.imagesAndVideos?.images[imageIndex]
                                  }
                                  alt=""
                                  className="rounded-lg w-full h-[150px]"
                                />
                                <div
                                  className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                                  onClick={() =>
                                    toggleFavorite(itemIndex, imageIndex)
                                  }
                                >
                                  {favorites[itemIndex]?.[imageIndex] ? (
                                    <IoMdHeart className="text-primary" />
                                  ) : (
                                    <IoMdHeartEmpty className="text-primary" />
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </Slider>
                      ) : (
                        <div className="w-full relative">
                          <img
                            src={item?.imagesAndVideos?.images[0]}
                            alt=""
                            className="rounded-lg w-full h-[260px]"
                          />
                          <div
                            className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                            onClick={() => toggleFavorite(itemIndex, 0)}
                          >
                            <IoMdHeart className="text-primary" />
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col items-start gap-2 p-3">
                        <h1 className="text-darkGray lg:text-xl text-base font-medium cursor-pointer hover:text-[#FE4747]">
                          {item.name}
                        </h1>
                        <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                          {item.loc}
                        </p>
                        <div className="flex items-center w-full  gap-4">
                          <div className="flex items-center gap-1">
                            <BiChair className="text-sm text-primary" />
                            <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                              {item.sitted}
                            </p>
                          </div>

                          <div className="flex items-center gap-1">
                            <LuPersonStanding className="text-sm text-primary" />
                            <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                              {item.stand}
                            </p>
                          </div>
                        </div>

                        <h1 className="text-secondary text-xl">•</h1>
                        <div className="flex items-center gap-1">
                          <img src={houses} alt="" />
                          <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                            {item.activity} . {item.setting}
                          </p>
                        </div>
                        <div className="flex  justify-between items-start w-full">
                          {item.minSpendPricing &&
                          item.venueRental &&
                          !Number.isNaN(item.minSpendPricing) &&
                          !Number.isNaN(item.venueRental) ? (
                            <>
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {item.minSpendPricing}
                                </p>
                                {/* Show one "Min. spend" text */}
                                <p className="text-sm">Min. spend</p>
                              </div>
                              {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
                            </>
                          ) : (
                            <>
                              {/* Case 2: Both minSpendPerPerson and venueRental available */}
                              {item.minSpendPerPerson &&
                              item.venueRental &&
                              !Number.isNaN(item.minSpendPerPerson) &&
                              !Number.isNaN(item.venueRental) ? (
                                <>
                                  <div className="">
                                    <p className="text-primary font-medium">
                                      AED {item.venueRental}
                                    </p>
                                    <p className="text-sm">Min. spend</p>
                                  </div>
                                  <div className="">
                                    <p className="text-primary font-medium">
                                      AED {item.minSpendPerPerson}
                                    </p>
                                    <p className="text-sm">Per guest</p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {/* Render each one individually if other cases don't match */}
                                  {item.minSpendPerPerson &&
                                    !Number.isNaN(item.minSpendPerPerson) && (
                                      <div className="">
                                        <p className="text-primary font-medium">
                                          AED {item.minSpendPerPerson}
                                        </p>
                                        <p className="text-sm">Per guest</p>
                                      </div>
                                    )}
                                  {item.minSpendPricing &&
                                    !Number.isNaN(item.minSpendPricing) && (
                                      <div className="">
                                        <p className="text-primary font-medium">
                                          AED {item.minSpendPricing}
                                        </p>
                                        <p className="text-sm">Min. spend</p>
                                      </div>
                                    )}
                                  {item.venueRental &&
                                    !Number.isNaN(item.venueRental) && (
                                      <div className="">
                                        <p className="text-primary font-medium">
                                          AED {item.venueRental}
                                        </p>
                                        <p className="text-sm">Min. spend</p>
                                      </div>
                                    )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          ) : (
            data &&
            data?.slice(0, 9).map((item, itemIndex) => (
              <div
                key={itemIndex}
                className="bg-white  rounded-lg shadow-lg cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  handleViewPrev(item._id);
                }}
              >
                {item?.imagesAndVideos?.images?.length > 1 ? (
                  <Slider {...imageSettings}>
                    {item?.imagesAndVideos?.images?.map((e, imageIndex) => (
                      <div key={imageIndex} className="w-[247px] relative">
                        <img
                          src={e}
                          alt=""
                          className="rounded-lg w-full h-[150px]"
                        />
                        <div
                          className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                          onClick={() => toggleFavorite(itemIndex, imageIndex)}
                        >
                          {favorites[itemIndex]?.[imageIndex] ? (
                            <IoMdHeart className="text-primary" />
                          ) : (
                            <IoMdHeartEmpty className="text-primary" />
                          )}
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className=" relative cursor-pointer">
                    <img
                      src={item?.imagesAndVideos?.images}
                      alt=""
                      className="rounded-lg w-full h-[150px]"
                    />
                    <div
                      className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                      onClick={() => toggleFavorite(itemIndex, 0)}
                    >
                      <IoMdHeart className="text-primary" />
                    </div>
                  </div>
                )}
                <div className="flex flex-col items-start gap-2 p-3">
                  <h1 className="text-darkGray text-xl font-medium cursor-pointer hover:text-[#FE4747]">
                    {item?.basicDetails?.venueName},
                    {item?.basicDetails?.hotelName}
                  </h1>
                  {/* <p className="text-secondary">
                {item?.location?.city},{item?.location?.area}
              </p> */}

                  <div className="flex items-center w-full gap-4">
                    <div className="flex items-center gap-1">
                      <BiChair className="text-lg text-primary" />
                      <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                        {item?.capacity?.sitting}
                      </p>
                    </div>
                    <div className="flex items-center gap-1">
                      <LuPersonStanding className="text-lg text-primary" />
                      <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                        {item?.capacity?.standing}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center w-full justify-between gap-2 ">
                    {/* if no venuetype than make it invisible */}
                    {item?.additionalDetails?.venueSetting[0] ? (
                      <>
                        <div className="flex items-center gap-1 ">
                          <img src={houses} alt="" />
                          <p className="text-secondary text-xs">
                            {item?.venueType?.venueRepresent
                              ? `${item?.venueType?.venueRepresent} `
                              : ""}
                            {item?.venueType?.venueRepresent && (
                              <span
                                style={{
                                  fontSize: "24px",
                                  verticalAlign: "middle",
                                }}
                              >
                                &bull;{" "}
                              </span>
                            )}
                            {item?.additionalDetails?.venueSetting
                              ?.sort()
                              .map((setting) => setting)
                              .join(" & ")}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center gap-1 mt-3 invisible">
                          <img src={houses} alt="" />
                          <p className="text-secondary text-sm">
                            {item?.venueType?.venueRepresent
                              ? `${item?.venueType?.venueRepresent}`
                              : ""}
                            {item?.additionalDetails?.venueSetting
                              ?.sort()
                              .map((setting) => setting)
                              .join(" & ")}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default HotelChildCards;
