import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import tickRedIcon from "./../../../assets/images/tick_red_icon.svg";

export const BenefitItem = ({ text, highlight, isHighlightFirst = false }) => {
  const theme = useTheme();
  // Define a condition that prevents the tick icon from showing for these specific texts
  const hideTickIcon =
    highlight === "Restaurants with alcohol" ||
    highlight === "Restaurants without alcohol";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mt: 1,
      }}
    >
      {!hideTickIcon && (
        <img
          src={tickRedIcon}
          alt="Benefits Icon"
          style={{
            marginRight: theme.spacing(1),
            width: "15px",
            height: "15px",
          }}
        />
      )}

      <Typography
        variant="body2"
        color={theme.palette.grey.text}
        sx={{ textAlign: "left" }}
      >
        {isHighlightFirst && highlight && (
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: theme.palette.black.text,
              fontWeight: "600",
              marginRight: "2px",
            }}
          >
            {highlight}
          </Typography>
        )}
        {text}
        {!isHighlightFirst && highlight && (
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: theme.palette.black.text,
              fontWeight: "600",
            }}
          >
            {highlight}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

const BenefitsList = ({ additionalCharges = [], isKids = false, others }) => {
  return (
    <Box sx={{ mb: 2 }}>
      {isKids ? (
        <>
          <BenefitItem text="Free six-month trial with annual membership" />
          <BenefitItem text="Cancel anytime before the trial ends" />
          <BenefitItem text="Unlimited venue listings on multi-venue plan" />
          <BenefitItem text="Assign Multiple venue managers for each venue" />
          <BenefitItem
            text="Detailed profile and landing page on"
            highlight=" Findmyvenue.com"
          />
          <BenefitItem text="Direct website enquiries" />
          <BenefitItem text="No booking fees or commissions on the platform" />
          <BenefitItem text="Direct access to potential clients" />
          <BenefitItem text="Detailed reporting and analytics" />
          <BenefitItem text="1 x social media post coverage" />
          {/* <BenefitItem text="Digital guided onboarding" /> */}
        </>
      ) : (
        <>
          <BenefitItem text="Unlimited venue listings, with rooms" />
          <BenefitItem text="Free six-month trial with annual membership" />
          <BenefitItem text="Cancel anytime before the trial ends" />
          <BenefitItem text="Unlimited venue listings on multi-venue plan" />
          <BenefitItem text="Assign Multiple venue managers for each venue" />
          <BenefitItem
            text="Detailed profile and landing page on"
            highlight=" Findmyvenue.com"
          />
          <BenefitItem text="Direct website enquiries" />
          <BenefitItem text="No booking fees or commissions on the platform" />
          <BenefitItem text="Gain access to venue seekers" />
          <BenefitItem text="Detailed reporting and analytics" />
          <BenefitItem text="1 x social media post coverage" />
        </>
      )}
      <Divider sx={{ borderStyle: "dashed", mb: 2, mt: 2 }} />

      {!isKids && !others && (
        <Box sx={{ display: "flex", alignItems: "center", pl: 2 }}>
          <Typography variant="body1" sx={{ fontWeight: "600", mb: 2 }}>
            Additional Charges
          </Typography>
        </Box>
      )}

      {additionalCharges.map((charge, index) => (
        <BenefitItem
          key={index}
          text={charge.text}
          highlight={charge.highlight}
          isHighlightFirst={true}
        />
      ))}
    </Box>
  );
};

export default BenefitsList;
