import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Funnelzerostate from "./Funnelzerostate";

function PerformanceFunnelGraph({ data1, startDate, endDate }) {
  let [totalImpressions, setTotalImpressions] = React.useState(0);
  let [totalPageVisits, setTotalPageVisits] = React.useState(0);
  let [totalEnquiries, setTotalEnquiries] = React.useState(0);
  let data = [
    {
      name: "Venues Impressions",
      value: totalImpressions,
    },
    {
      name: "Page Visited",
      value: totalPageVisits,
    },
    {
      name: "Enquiries",
      value: totalEnquiries,
    },
  ];

  const totalVenuesData = (data) => {
    const totals = data?.reduce(
      (acc, venue) => {
        acc.impressions +=
          startDate || endDate ? venue?.impressionsByDate : venue?.impressions;
        acc.pageVisits +=
          startDate || endDate ? venue?.pageVisitsByDate : venue?.pageVisits;
        acc.enquiries += venue.enquiries;
        return acc;
      },
      { impressions: 0, pageVisits: 0, enquiries: 0 }
    );

    setTotalImpressions(totals?.impressions);
    setTotalPageVisits(totals?.pageVisits);
    setTotalEnquiries(totals?.enquiries);
  };

  useEffect(() => {
    totalVenuesData(data1 || []);
  }, [data1]);

  useEffect(() => {
    // console.log("Data1", data);
    // console.log(totalImpressions, totalPageVisits, totalEnquiries);
  }, [totalImpressions, totalPageVisits, totalEnquiries]);

  return (
    <>
      {/* funnel zero state */}
      {/* <Funnelzerostate /> */}
      <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px]">
        <div className="mb-[20px] md:mb-[100px] flex flex-col gap-1">
          <p className="text-[#222222] text-[16px] md:text-[20px] font-medium">
            Performance Funnel
          </p>
          <p className="text-[#717171] text-[12px] md:text-[14px] font-normal">
            Response time from your team
          </p>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={data}>
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[0, "dataMax"]} />
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke="none"
              fill="rgba(73, 144, 251, 0.06)"
              // fillOpacity={0.3}
            />
            <Bar
              dataKey="value"
              fill="#4990FB"
              barSize={window.innerWidth < 640 ? 20 : 224}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default PerformanceFunnelGraph;
