import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import {FaStar,FaRegStar} from "react-icons/fa";

const RatingCheckBox = ({ label, isChecked, onChange, name, value }) => {
  return (
    <div className="flex items-start gap-3">
      <input
        type="checkbox"
        value={value}
        id={`custom-checkbox-${label}`}
        className="hidden peer"
        checked={isChecked}
        onChange={onChange}
        name={name}
      />
      <label
        htmlFor={`custom-checkbox-${label}`} // Matching label for checkbox
        className="w-5 h-5 flex-shrink-0 relative rounded-sm border border-gray bg-transparent cursor-pointer  flex items-center justify-center peer-checked:bg-black"
      >
        {isChecked && <IoCheckmarkSharp className="text-lg text-white" />}
      </label>
      <p className="text-dark -mt-1">
        {label === "1 Star" ? (
          <div className="flex items-center gap-2">
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
          </div>
        ) : label === "2 Star" ? (
          <div className="flex items-center gap-2">
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
          </div>
        ) : label === "3 Star" ? (
          <div className="flex items-center gap-2">
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
          </div>
        ) : label === "4 Star" ? (
          <div className="flex items-center gap-2">
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaRegStar className="text-[#F2C200] text-xl" />
            </span>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
            <span>
              <FaStar className="text-[#F2C200] text-xl" />
            </span>
          </div>
        )}
      </p>
    </div>
  );
};

export default RatingCheckBox;
