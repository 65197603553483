import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import TitleDescriptionCoupon from "../components/TitleDescriptionCoupon";
import LabelledRadioGroup from "../components/LabelledRadioGroup";
import RestaurantCard from "../components/RestaurantCard";
import VenueCard from "../components/VenueCard";
import MessageCard from "../components/MessageCard";
import CircularProgressLoader from "../../../components/CircularProgressLoader";
import calculateHotelPayment from "../../../utils/calculateHotelPayment";
import PaymentHotelView from "../payment/PaymentHotelView";
import BillingForm from "../components/BillingForm";
import { hotel } from "../../../api/hotel.request";
import { payment } from "../../../api/hotelPayment";
import { fetchLoggedUser } from "../../../api/loggedUser";
import { updateOnboarding } from "../../../api/signup.request";

function HotelFlow({
  activeStep,
  handleContinue,
  toggleBackButton,
  isBackButtonClicked,
  resetBackButtonClicked,
}) {
  const [withAlcoholNumber, setWithAlcoholNumber] = useState(0);
  const [withoutAlcoholNumber, setWithoutAlcoholNumber] = useState(0);
  const [quantityNumber, setQuantityNumber] = useState(0);
  const [stayAlong, setStayAlong] = useState("yes");
  const [paymentData, setPaymentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [billingInformation, setBillingInformation] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        setUser(response.vendor);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const handleWithAlcoholNumberChange = (event) => {
    setWithAlcoholNumber(event.target.value);
  };

  const handleWithoutAlcoholNumberChange = (event) => {
    setWithoutAlcoholNumber(event.target.value);
  };

  const handleQuantityNumberChange = (event) => {
    setQuantityNumber(event.target.value);
  };

  const handleStayAlongChange = (event) => {
    setStayAlong(event.target.value);
  };

  useEffect(() => {
    let timeoutId;
    if (activeStep === 2) {
      toggleBackButton(false);
      const data = calculateHotelPayment(
        withAlcoholNumber,
        withoutAlcoholNumber
      );
      setPaymentData({
        ...data,
        quantityNumber: quantityNumber,
      });

      // Delay the continue action by 3 seconds
      timeoutId = setTimeout(() => {
        handleContinue();
      }, 3000);
    } else {
      toggleBackButton(true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeStep, toggleBackButton, handleContinue]);

  const handlePayment = () => {
    // Retrieve grandTotal from local storage
    const storedGrandTotal = localStorage.getItem("grandTotal");
    // Retrieve discount from local storage
    const discountis = localStorage.getItem("specialDiscount");
    // Check if storedGrandTotal is not null
    const amount = storedGrandTotal ? parseInt(storedGrandTotal, 10) : 0;
    // Check if discountis is not null
    const specialDiscount = discountis ? parseInt(discountis, 10) : 0;

    // this breakdown send to backend in paymentPayloadobject
    const breakdown = {
      type: "HotelStayandVenue",
      StayAlong: stayAlong,
      withAlcoholNumber: withAlcoholNumber,
      withoutAlcoholNumber: withoutAlcoholNumber,
      VenuesCount: quantityNumber,
      payment: true,
      specialDiscount,
    };

    // Prepare data for payment API call
    const paymentPayload = {
      email: user?.email,
      cardHolderName: "Hotel and Venue",
      savedForFuture: true,
      // commenting for testing
      amount: amount,
      // amount: 1,
      specialDiscount,
      description: "Payment for hotel and venue",
      billingInformation: billingInformation,
      breakdown,
    };

    // Make payment API call
    setLoading(true);
    payment(paymentPayload)
      .then((response) => {
        if (
          response.success &&
          response.session &&
          typeof response.session.url === "string"
        ) {
          const addedOn = new Date();

          const hotelData = {
            addedOn,
            type: "HotelStayandVenue",
            StayAlong: stayAlong,
            withAlcoholNumber: withAlcoholNumber,
            withoutAlcoholNumber: withoutAlcoholNumber,
            VenuesCount: quantityNumber,
            payment: true,
          };
          updateOnboarding(hotelData);
          setTimeout(() => {
            window.location.href = response.session.url;
          }, 1000);
        } else {
          console.error("URL not found or invalid");
        }
      })
      .then((result) => {
        if (result.session && result.session.url) {
          window.location.href = result.session.url;
          return;
        }
        handleContinue();
        const addedOn = new Date();
        // // Prepare data for hotel API call
        const hotelData = {
          addedOn,
          type: "HotelStayandVenue",
          StayAlong: stayAlong,
          RestaurantsWithAlcohol: withAlcoholNumber,
          RestaurantsWithoutAlcohol: withoutAlcoholNumber,
          VenuesCount: quantityNumber,
          paymentId: result.paymentId,
          payment: true,
        };

        // // Make hotel API call
        return hotel(hotelData);
      })
      .then((hotelResponse) => {
        console.log("Hotel API response:", hotelResponse);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle the error appropriately (e.g., show an error message to the user)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleContinueWithPayment = (shouldHandlePayment) => {
    if (shouldHandlePayment) {
      handlePayment();
    } else {
      handleContinue();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      {activeStep === 1 && (
        <Box sx={{ width: { xs: "100%", md: "624px" } }}>
          <TitleDescriptionCoupon
            title={"Tell us more about your venue"}
            description={"This will help us find the best plan for you"}
          />
          <Box sx={{ height: "2rem" }}></Box>
          <LabelledRadioGroup
            label={
              "Would you like to list your stay along with venues for events?"
            }
            value={stayAlong}
            handleChange={handleStayAlongChange}
          />
          <RestaurantCard
            description={<>How many restaurants you own and manage</>}
            withAlcoholNumber={withAlcoholNumber}
            withoutAlcoholNumber={withoutAlcoholNumber}
            handleWithAlcoholNumberChange={handleWithAlcoholNumberChange}
            handleWithoutAlcoholNumberChange={handleWithoutAlcoholNumberChange}
          />
          <VenueCard
            title={"Venues"}
            description={"All venues which are not restaurants"}
            toolTipText={
              "Make sure all the venues are part of the same hotel for example ballroom, meeting room, beach, lawn"
            }
            quantityNumber={quantityNumber}
            handleQuantityNumberChange={handleQuantityNumberChange}
          />
        </Box>
      )}
      {activeStep === 2 && (
        <MessageCard
          loaderComponent={<CircularProgressLoader color={"red"} />}
          primaryText={
            "Creating a plan suitable to your venue. Please wait a moment"
          }
          secondaryText={"Please wait for a moment..."}
        />
      )}
      {/* 3 - Payment */}
      {activeStep === 3 && (
        <PaymentHotelView
          paymentData={paymentData}
          handleContinue={handleContinue}
          user={user}
        />
      )}
      {/* 4 - Billing*/}
      {activeStep === 4 && (
        <BillingForm
          loading={loading}
          handleContinue={handleContinueWithPayment}
          shouldHandlePayment={true}
          setBillingInformation={setBillingInformation}
        />
      )}
      {/* 5 - Payment Message Card*/}
      {activeStep === 5 && <MessageCard />}
      {activeStep < 2 && (
        <Box
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              textTransform: "none",
              mt: "1rem",
              boxShadow: "none",
              width: { xs: "100%", md: "400px" },
            }}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default HotelFlow;
