import React, { useState, useEffect } from "react";

import DoneIcon from "@mui/icons-material/Done";
import icon1 from "../../assets2/icons/placeOffers/1.svg";
import icon2 from "../../assets2/icons/placeOffers/2.svg";
import icon3 from "../../assets2/icons/placeOffers/3.svg";
import icon4 from "../../assets2/icons/placeOffers/4.svg";
import icon5 from "../../assets2/icons/placeOffers/5.svg";
import icon6 from "../../assets2/icons/placeOffers/6.svg";
import icon7 from "../../assets2/icons/placeOffers/7.svg";
import icon8 from "../../assets2/icons/placeOffers/8.svg";
import icon9 from "../../assets2/icons/placeOffers/9.svg";
import icon10 from "../../assets2/icons/placeOffers/10.svg";
import icon11 from "../../assets2/icons/placeOffers/11.svg";
import icon12 from "../../assets2/icons/placeOffers/12.svg";
import icon13 from "../../assets2/icons/placeOffers/13.svg";
import icon14 from "../../assets2/icons/placeOffers/14.svg";
import icon15 from "../../assets2/icons/placeOffers/15.svg";
import icon16 from "../../assets2/icons/placeOffers/16.svg";
import icon17 from "../../assets2/icons/placeOffers/17.svg";
import icon18 from "../../assets2/icons/placeOffers/18.svg";
import icon19 from "../../assets2/icons/placeOffers/19.svg";
import icon20 from "../../assets2/icons/placeOffers/20.svg";
import icon21 from "../../assets2/icons/placeOffers/21.svg";
import icon22 from "../../assets2/icons/placeOffers/22.svg";
import icon23 from "../../assets2/icons/placeOffers/23.svg";
import icon24 from "../../assets2/icons/placeOffers/24.svg";
import icon25 from "../../assets2/icons/placeOffers/25.svg";
import icon26 from "../../assets2/icons/placeOffers/26.svg";
import icon53 from "../../assets2/icons/placeOffers/53.svg";
import icon54 from "../../assets2/icons/placeOffers/54.svg";
import icon55 from "../../assets2/icons/placeOffers/55.svg";
import icon56 from "../../assets2/icons/placeOffers/56.svg";

const cateringItems = [
  {
    name: "Offers Catering",
    icon: icon1,
    key: "venueProvideInHouseCatering",
  },
  {
    name: "Outside Catering Allowed",
    icon: icon2,
    key: "externalCatering",
  },
  {
    name: "Bring Your Own Alcohol Allowed",
    icon: icon3,
    key: "bringYourOwnAlcoholAllowed",
  },
  {
    name: "Corkage Fee for Bring Your Own Alcohol",
    icon: icon4,
    key: "corkageFeeForBringYourOwnAlcohol",
  },
  {
    name: "Alcohol/Liquor License",
    icon: icon5,
    key: "alcoholLiquorLicense",
  },
  {
    name: "Refreshments",
    icon: icon6,
    key: "refreshments",
  },
];

const facilitiesData = [
  {
    name: "Natural Light",
    icon: icon7,
    key: "Natural Light",
  },
  {
    name: "Flatscreen TV",
    icon: icon8,
    key: "Flatscreen TV",
  },
  {
    name: "Wifi",
    icon: icon9,
    key: "Wifi",
  },
  {
    name: "Flipchart",
    icon: icon10,
    key: "Flipchart",
  },
  {
    name: "Air Conditioning",
    icon: icon11,
    key: "Air conditioning",
  },
  {
    name: "Storage Space",
    icon: icon53,
    key: "Storage Space",
  },
  {
    name: "Podium",
    icon: icon54,
    key: "Podium",
  },
  {
    name: "Conference Call Facilities",
    icon: icon55,
    key: "Conference call facilities",
  },
  {
    name: "Whiteboard",
    icon: icon56,
    key: "Whiteboard",
  },
];

const parkingData = [
  {
    name: "Parking available",
    icon: icon13,
    key: "parkingAvailable",
  },
  {
    name: "Valet Parking",
    icon: icon14,
    key: "valetParking",
  },
];
const equipmentData = [
  {
    name: "Banquet chairs",
    icon: icon15,
    key: "Banquet chairs",
  },
  {
    name: "Cocktail tables",
    icon: icon16,
    key: "Cocktail tables",
  },
  {
    name: "Bar",
    icon: icon17,
    key: "Bar",
  },
  {
    name: "Stage deck",
    icon: icon18,
    key: "Stage deck",
  },
  {
    name: "Storage space",
    icon: icon19,
    key: "Storage space",
  },
  {
    name: "Dance floor",
    icon: icon20,
    key: "Dance floor",
  },
];

const musicData = [
  {
    name: "Indoor Music Allowed Until",
    icon: icon21,
    key: "indoorMusicAllowed",
  },
  {
    name: "Outdoor Music Allowed Until",
    icon: icon22,
    key: "outdoorMusicAllowed",
  },
  {
    name: "PA System Speakers",
    icon: icon23,
    key: "paSystemSpeakers",
  },
  {
    name: "Space Has Noise Restirctions",
    icon: icon24,
    key: "spaceHasNoiseRestrictions",
  },
  {
    name: "Client Can Play their own Music",
    icon: icon25,
    key: "clientsCanPlayOwnMusic",
  },
  {
    name: "Clients can bring their own DJ",
    icon: icon26,
    key: "clientsCanBringOwnDJ",
  },
];

const Feature1Item = ({ feature, available }) => {
  return (
    <div
      className={`flex items-center md:w-[70%] md:text-[16px] text-[12px] w-full mb-2 ${
        available ? "block" : "hidden"
      }`}
    >
      <img src={feature.icon} alt="icon" className="text-xl mr-3" />
      <span>{feature.name}</span>
    </div>
  );
};

const FeaturesSection = ({ data, UploadDocuments }) => {
  const [formData, setFormData] = useState(data || {});
  const [showMenu, setShowMenu] = useState(false);
  const [showText, setShowText] = useState("Show More");

  useEffect(() => {
    setFormData(data);
  }, [data]);
  const handleShowMenu = () => {
    if (showMenu) {
      setShowMenu(false);
      setShowText("Show More");
    } else {
      setShowMenu(true);
      setShowText("Show Less");
    }
  };
  const hasMenuData =
    formData?.cateringAndDrinks?.availableMenu?.length > 0 ||
    formData?.cateringAndDrinks?.servingStyle?.length > 0 ||
    formData?.cateringAndDrinks?.cuisine?.length > 0;

  return (
    <div className="py-5 w-full border-t mx-auto">
      <div className="bg-white rounded-lg">
        <h2 className="text-3xl gap-5 font-semibold mb-6">
          What this place offers
        </h2>
        {/* {formData.facilities?.activitiesForKidsIncludes?.length > 0 && (
          <div className="md:flex justify-between items-start gap-8 mt-7 border-b border-[#EBEBEB] pb-7">
            <div className="md:w-[20%] w-full text-md font-semibold  ">
              Activities For Kids
            </div>
            <div className="md:w-[75%] w-full  gap-2">
              <div className="grid md:grid-cols-2 grid-cols-1 md:mt-0 mt-2 ">
                {formData.facilities?.activitiesForKidsIncludes?.map(
                  (item, index) => (
                    <>
                      <div key={index} className="flex items-center gap-1">
                        <DoneIcon className="text-2xl text-[#B0B0B0] mr-3" />
                        <span>{item}</span>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        )} */}
        <div className="md:flex justify-between items-start gap-8 mt-3 md:border-b border-[#EBEBEB] md:pb-7 ">
          <div className="md:w-[20%] w-full ">
            <h1 className="text-md font-semibold ">Catering & Drinks</h1>
            {UploadDocuments?.sampleMenus &&
              UploadDocuments?.sampleMenus?.length > 0 && (
                <button
                  class="text-red-500 underline cursor-pointer"
                  onClick={() => {
                    UploadDocuments?.sampleMenus?.forEach((menuLink) => {
                      const link = document.createElement("a");
                      link.href = menuLink;
                      link.setAttribute("download", ""); // Use the filename from the URL, or you can provide a specific one
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                  }}
                >
                  View Menu
                </button>
              )}
          </div>
          {/* Showm more/less */}
          <div className="md:w-[75%] w-full gap-2">
            <div className="mb-3 grid grid-cols-2 gap-3 w-full md:mt-0 mt-2">
              {cateringItems.map((item, index) => {
                const available =
                  formData?.cateringAndDrinks?.[item.key] || false;
                return (
                  <Feature1Item
                    key={index}
                    feature={item}
                    available={available}
                  />
                );
              })}
              {hasMenuData && (
                <p
                  className="text-[16px] text-[#FE4747] underline mt-1 cursor-pointer"
                  onClick={() => {
                    handleShowMenu();
                  }}
                >
                  {showText}
                </p>
              )}
            </div>
            {showMenu && (
              <>
                {formData.cateringAndDrinks?.availableMenu?.length > 0 && (
                  <>
                    <h2 className="text-md mb-1 font-semibold col-span-1">
                      Menu
                    </h2>
                    <div className="grid grid-cols-2">
                      {formData.cateringAndDrinks.availableMenu.map(
                        (item, index) => (
                          <div key={index} className="flex items-center gap-1">
                            <DoneIcon className="text-2xl text-[#B0B0B0] mr-2" />
                            <span>{item}</span>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
                {formData.cateringAndDrinks?.servingStyle?.length > 0 && (
                  <>
                    <h2 className="text-md mb-1 font-semibold col-span-1 mt-3">
                      Serving Style
                    </h2>
                    <div className="grid grid-cols-2">
                      {formData.cateringAndDrinks.servingStyle.map(
                        (item, index) => (
                          <div key={index} className="flex items-center gap-1">
                            <DoneIcon className="text-2xl text-[#B0B0B0] mr-2" />
                            <span>{item}</span>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}
                {formData.cateringAndDrinks?.cuisine?.length > 0 && (
                  <>
                    <h2 className="text-md mb-1 font-semibold col-span-1 mt-3">
                      Cuisine Type
                    </h2>
                    <div className="grid grid-cols-2">
                      {formData.cateringAndDrinks.cuisine.map((item, index) => (
                        <div key={index} className="flex items-center gap-1">
                          <DoneIcon className="text-2xl text-[#B0B0B0] mr-2" />
                          <span>{item}</span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="md:flex justify-between items-start gap-8 mt-7 md:border-b border-[#EBEBEB]  md:pb-7">
          <div className="md:w-[20%] w-full text-md font-semibold  ">
            Facilities
          </div>
          <div className="md:w-[75%] w-full  gap-2">
            <div className="grid grid-cols-2 md:mt-0 mt-2">
              {/* {Object.entries(formData?.facilities?.facilities?.[0])
                .filter(([key, value]) => key !== "_id" && value) // Exclude `_id` and only include true values
                .map(([key, value], index) => (
                  <div key={index} className="flex items-center gap-1">
                    <DoneIcon className="text-xl mr-2" />
                    <span>{key}</span>
                  </div>
                ))} */}
              {facilitiesData.map((item, index) => {
                const available =
                  formData?.facilities?.facilities?.[item.key] || false;
                return (
                  <Feature1Item
                    key={index}
                    feature={item}
                    available={available}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {formData?.equipment?.equipment.length > 1 && (
          <div className="md:flex w-full justify-between items-start gap-8 mt-7 md:border-b border-[#EBEBEB] md:pb-7">
            <div className="md:w-[20%] w-full text-md font-semibold  ">
              Equipment
            </div>
            <div className="mmd:w-[75%] w-full gap-2">
              <div className="grid grid-cols-2">
                {equipmentData.map((item, index) => {
                  const available =
                    formData?.equipment?.equipment?.[0]?.[item.key] || false;
                  return (
                    <Feature1Item
                      key={index}
                      feature={item}
                      available={available}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {formData.audio?.indoorMusicAllowed && (
          <div className="md:flex w-full justify-between items-start gap-8 mt-7 md:border-b border-[#EBEBEB] md:pb-7">
            <div className="md:w-[20%] w-full text-md font-semibold  ">
              Audio
            </div>
            <div className="md:w-[75%] w-full  gap-2">
              <div className="grid grid-cols-2 md:mt-0 mt-2">
                {musicData.map((item, index) => {
                  const available = formData?.audio?.[item.key] || false;
                  return (
                    <Feature1Item
                      key={index}
                      feature={item}
                      available={available}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {formData.location?.parkingAvailable && (
          <div className="md:flex justify-between items-start gap-8 mt-7 md:border-b border-[#EBEBEB] md:pb-7">
            <div className="md:w-[20%] w-full text-md font-semibold  ">
              Parking
            </div>
            <div className="md:w-[75%] w-full  gap-2">
              <div className="grid grid-cols-2  md:mt-0 mt-2">
                {parkingData.map((item, index) => {
                  const available = formData?.location?.[item.key] || false;
                  return (
                    <Feature1Item
                      key={index}
                      feature={item}
                      available={available}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        <div className="md:flex justify-between items-start gap-8 mt-7 md:mb-8 mb-3 md:border-b border-[#EBEBEB] md:pb-7">
          <div className="md:w-[20%] w-full text-md font-semibold  ">
            Space Rules
          </div>
          <div className="md:w-[75%] w-full  gap-2">
            <div className="grid grid-cols-2">
              <div className="col-span-1">
                <p className="md:text-[16px] text-[12px] md:font-medium font-[400] mt-1 cursor-pointer">
                  Age Policy
                </p>
                <p className="md:text-[16px] text-[12px] mt-1 cursor-pointer">
                  {formData?.spaceRules?.agePolicy.join("-") || "None"}
                </p>
              </div>
              <div className="col-span-1">
                <p className="md:text-[16px] text-[12px] md:font-medium font-[400] mt-1 cursor-pointer">
                  Ticketed Events Allowed
                </p>
                <p className="md:text-[16px] text-[12px]  mt-1 cursor-pointer">
                  {formData?.spaceRules?.ticketedEventsAllowed ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
