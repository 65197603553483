"use client";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import houses from "../../assets/pics/houses.svg";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";

const DetailComponent = ({ data }) => {
  const [favorites, setFavorites] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);

  const toggleFavorite = (itemIndex, imageIndex) => {
    setFavorites((prevState) => ({
      ...prevState,
      [itemIndex]: {
        ...prevState[itemIndex],
        [imageIndex]: !prevState[itemIndex]?.[imageIndex],
      },
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const mainCarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // lg
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // md
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // sm
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`w-full ${isMobileView ? "" : "grid grid-cols-4 gap-6"}`}>
      {isMobileView ? (
        <Slider {...mainCarouselSettings}>
          {data.map((item, itemIndex) => (
            <div key={itemIndex} className="p-2">
              <div className="bg-white rounded-lg shadow-lg">
                <Slider {...imageSettings}>
                  {item.images.map((e, imageIndex) => (
                    <div key={imageIndex} className="w-full relative">
                      <img
                        src={e.img1}
                        alt=""
                        className="rounded-lg w-full h-full"
                      />
                      <div
                        className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                        onClick={() => toggleFavorite(itemIndex, imageIndex)}
                      >
                        {favorites[itemIndex]?.[imageIndex] ? (
                          <IoMdHeart className="text-primary" />
                        ) : (
                          <IoMdHeartEmpty className="text-primary" />
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
                <div className="flex flex-col items-start gap-2 p-3">
                  <h1 className="text-darkGray lg:text-xl text-base font-medium">
                    {item.name}
                  </h1>
                  <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                    {item.loc}
                  </p>
                  <div className="flex items-center w-full  gap-4">
                    <div className="flex items-center gap-1">
                      {/* chair */}
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2272_13995)">
                          <path
                            d="M15.0644 10.2681V0.5H13.8925V1.75015H6.10748V0.5H4.93561V10.2681C4.24789 10.6965 3.78906 11.4592 3.78906 12.3274V20.5H4.96094V16.1075H15.0389V20.5H16.2108V12.3272C16.2108 11.4592 15.752 10.6965 15.0644 10.2681ZM4.96094 12.3272C4.96094 11.6363 5.52307 11.0742 6.21399 11.0742H13.7859C14.4768 11.0742 15.0389 11.6363 15.0389 12.3272V12.6655H4.96094V12.3272ZM13.8925 6.41217H6.10748V2.92203H13.8925V6.41217ZM6.10748 7.58405H13.8925V9.90506C13.8571 9.90353 13.8217 9.90231 13.7859 9.90231H6.21399C6.17828 9.90231 6.14288 9.90353 6.10748 9.90506V7.58405ZM15.0389 14.9356H4.96094V13.8374H15.0389V14.9356Z"
                            fill="#FE4747"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2272_13995">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                        {item.sitting}
                      </p>
                    </div>
                    <div className="flex items-center gap-1">
                      {/* standing image */}
                      <img src="/standingnew.svg" alt="" />
                      <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                        {item.stand}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center gap-1">
                      <img src={houses} alt="" />
                      <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                        {item.activity}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start w-full justify-between">
                    <div className="flex flex-col items-center">
                      <h1 className="text-primary lg:text-lg sm:text-base text-sm ">
                        {item.aed1}
                      </h1>
                      <p className="text-secondary sm:text-sm text-[10px] mt-1">
                        {item.guest}
                      </p>
                    </div>
                    <div className="flex flex-col items-center">
                      <h1 className="text-primary lg:text-lg sm:text-base text-sm">
                        {item.aed2}
                      </h1>
                      <p className="text-secondary sm:text-sm text-[10px] mt-1">
                        {item.spend}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        data.map((item, itemIndex) => (
          <div key={itemIndex} className="bg-white rounded-lg shadow-lg">
            <Slider {...imageSettings}>
              {item.images.map((e, imageIndex) => (
                <div key={imageIndex} className="w-full relative">
                  <img
                    src={e.img1}
                    alt=""
                    className="rounded-lg w-full h-full"
                  />
                  <div
                    className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                    onClick={() => toggleFavorite(itemIndex, imageIndex)}
                  >
                    {favorites[itemIndex]?.[imageIndex] ? (
                      <IoMdHeart className="text-primary" />
                    ) : (
                      <IoMdHeartEmpty className="text-primary" />
                    )}
                  </div>
                </div>
              ))}
            </Slider>
            <div className="flex flex-col items-start gap-2 p-3">
              <h1 className="text-darkGray text-xl font-medium">{item.name}</h1>
              <p className="text-secondary">{item.loc}</p>
              <div className="flex items-center w-full  gap-4">
                <div className="flex items-center gap-1">
                  {/* chair */}
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2272_13995)">
                      <path
                        d="M15.0644 10.2681V0.5H13.8925V1.75015H6.10748V0.5H4.93561V10.2681C4.24789 10.6965 3.78906 11.4592 3.78906 12.3274V20.5H4.96094V16.1075H15.0389V20.5H16.2108V12.3272C16.2108 11.4592 15.752 10.6965 15.0644 10.2681ZM4.96094 12.3272C4.96094 11.6363 5.52307 11.0742 6.21399 11.0742H13.7859C14.4768 11.0742 15.0389 11.6363 15.0389 12.3272V12.6655H4.96094V12.3272ZM13.8925 6.41217H6.10748V2.92203H13.8925V6.41217ZM6.10748 7.58405H13.8925V9.90506C13.8571 9.90353 13.8217 9.90231 13.7859 9.90231H6.21399C6.17828 9.90231 6.14288 9.90353 6.10748 9.90506V7.58405ZM15.0389 14.9356H4.96094V13.8374H15.0389V14.9356Z"
                        fill="#FE4747"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2272_13995">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p className="text-secondary">{item.sitting}</p>
                </div>
                <div className="flex items-center justify-center gap-1">
                  {/* standing image */}
                  <img src="/standingnew.svg" alt="" />

                  <p className="text-secondary">{item.stand}</p>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-1">
                  <img src={houses} alt="" />
                  <p className="text-secondary">{item.activity}</p>
                </div>
              </div>
              <div className="flex items-start w-full justify-between">
                <div className="flex flex-col items-center">
                  <h1 className="text-primary text-lg">{item.aed1}</h1>
                  <p className="text-secondary text-sm mt-1">{item.guest}</p>
                </div>
                <div className="flex flex-col items-center">
                  <h1 className="text-primary text-lg">{item.aed2}</h1>
                  <p className="text-secondary text-sm mt-1">{item.spend}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default DetailComponent;
