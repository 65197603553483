import React from "react";
import { MdScatterPlot } from "react-icons/md";

function Recipientpopup({
  loading,
  setEmail,
  email,
  setIsPopupOpen,
  handleEmailSubmit,
}) {
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg md:w-1/3">
        <h3 className="text-lg font-semibold mb-4">Enter Recipient's Email</h3>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="border border-gray-300 p-2 rounded-lg w-full mb-4"
          placeholder="Recipient's Email"
        />
        <div className="flex justify-end gap-4">
          <button
            onClick={() => setIsPopupOpen(false)}
            className="px-4 py-2 bg-gray-200 rounded-lg"
          >
            Cancel
          </button>
          <div className="flex items-center justify-center gap-x-2 bg-blue-500 text-white rounded-lg px-4 py-2">
            <button
              disabled={loading}
              onClick={handleEmailSubmit}
              className=" "
            >
              Send Report
            </button>
            {loading && <MdScatterPlot className=" animate-spin" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recipientpopup;
