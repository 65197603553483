import React from "react";

function EditOrganizationDetails({
  handleOrganizationSave,
  organizationDetails,
  setOrganizationDetails,
  isLoading,
  user,
}) {
  return (
    <form onSubmit={handleOrganizationSave}>
      <div className="space-y-4 w-[400px] mx-auto">
        {/* Title */}
        <h2 className="text-center font-bold mb-4 text-[22px] lg:text-[32px]">
          Edit Organisation Details
        </h2>

        {/* Organisation Input */}
        <div className="mt-[32px]">
          <label className="block mt-[32px] text-[14px] font-normal text-[#5E5E5E]">
            Organisation
          </label>
          <input
            type="text"
            placeholder="Enter organisation name"
            value={organizationDetails.organization}
            onChange={(e) =>
              setOrganizationDetails({
                ...organizationDetails,
                organization: e.target.value,
              })
            }
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none"
          />
        </div>

        {/* Job Title Input */}
        <div>
          <label className="block text-[14px] font-normal text-[#5E5E5E]">
            Job Title
          </label>
          <input
            type="text"
            value={organizationDetails.jobTitle}
            placeholder="Enter job title"
            onChange={(e) =>
              setOrganizationDetails({
                ...organizationDetails,
                jobTitle: e.target.value,
              })
            }
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none"
          />
        </div>

        {/* Save Button */}
        <div className="flex justify-center mt-[40px]">
          <button
            type="submit"
            disabled={isLoading}
            className="active:opacity-55 bg-[#FF3939] mt-[40px] text-white w-full py-2 rounded-xl"
          >
            {isLoading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditOrganizationDetails;
