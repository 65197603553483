import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
import VerifyPhoneModal from "./VerifyPhoneModal";
import { Modal } from "./SettingsDashboard";

function EditLoginDetails({
  loginDetails,
  setLoginDetails,
  user,
  setLoginModal,
  handleLoginSave,
}) {
  const [country, setCountry] = useState("AE");
  const [countryCode, setCountryCode] = useState("+971");
  const handleCountryChange = (countryCode) => {
    if (countryCode) {
      setCountry(countryCode);

      try {
        // Directly get the calling code for the selected country
        const dialCode = getCountryCallingCode(countryCode);
        setCountryCode(`+${dialCode}`);

        // If no number is entered, set a placeholder with the new country code
        if (!loginDetails?.mobileNumber) {
          setLoginDetails({
            ...loginDetails,
            mobileNumber: `+${dialCode}`,
          });
        }
      } catch (error) {
        console.error("Error getting country calling code:", error);
        setCountryCode("+");
      }
    }
  };

  const handleMobileNumberChange = (value) => {
    console.log(value, "number is this");
    setLoginDetails({
      ...loginDetails,
      mobileNumber: value,
    });
  };

  return (
    <>
      <form onSubmit={handleLoginSave}>
        <div className="space-y-4 w-[400px] mx-auto">
          <h2 className="text-center font-bold mb-4 text-[22px] lg:text-[32px]">
            Edit Mobile Number
          </h2>

          {/* Mobile Number Input */}
          <div className="relative">
            <div className="absolute left-[60px] top-1/2 transform   z-10 pointer-events-none">
              {countryCode}
            </div>
            <label
              htmlFor=""
              className="relative  font-outfit text-[14px] font-normal text-[#5E5E5E]"
            >
              Mobile Number
            </label>
            <PhoneInput
              id="mobile-input"
              placeholder="123 456 7890"
              value={loginDetails?.mobileNumber || ""}
              defaultCountry="AE"
              withCountryCallingCode={true}
              autoComplete="on"
              onCountryChange={handleCountryChange}
              onChange={handleMobileNumberChange}
              style={{
                border: "1px solid #d1d5db",
                boxShadow: "none",
                borderRadius: "10px",
                outline: "none",
                width: "100%",
                padding: "0.5rem",
                // marginTop: "0.3rem",
              }}
              className="no-focus-border  "
            />
          </div>

          {/* Save Button */}
          <div className="flex justify-center mt-[40px]">
            <button
              type="submit"
              className="active:opacity-55 bg-[#FF3939] mt-[40px] text-white w-full py-2 rounded-xl"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditLoginDetails;
