import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import CustomCheckBox from "./CustomCheckbox";

const CustomListingSelector = ({
  selectOptions,
  label,
  content,
  selectedOptions = [],
  setSelectedOptions,
  handleOpenPopup,
  openPopup,
}) => {
  // Handle checkbox changes (add/remove)
  const handleOptionChange = (e) => {
    const { name, checked, value } = e.target;
    // console.log(name, checked, value);
    if (checked) {
      // Add the selected option
      setSelectedOptions([...selectedOptions, value]);
    } else {
      // Remove the unselected option
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  return (
    <div className="w-full pt-6 mt-4 border-t  border-grayNeutral">
      <div className="w-full flex cursor-pointer items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <p className="text-dark font-medium">{label}</p>
        </div>
        <IoIosArrowDown
          className={`flex-shrink-0 text-xl rotate-0 ${
            openPopup && "rotate-180"
          } transition-all duration-150`}
          onClick={handleOpenPopup}
        />
      </div>

      {openPopup &&
        (content ? (
          <div className="custom-content">{content}</div>
        ) : (
          <div className="border-2 border-[#EBEBEB] custom-scrollbar rounded-lg p-2 w-full overflow-auto h-max max-h-48 flex flex-col mt-2 gap-3 outline-none">
            {selectOptions.map((e, i) => (
              <div key={i.value} className="flex items-center gap-3">
                <CustomCheckBox
                  label={e.name}
                  isChecked={selectedOptions.includes(e.value)}
                  onChange={handleOptionChange}
                  name={e.name}
                  value={e?.value}
                />
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default CustomListingSelector;
