import axios from "axios";
import React, { useState } from "react";
import { base_URL } from "../../config/config";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function DeleteFolderModal({ preName, onClose, isOpen }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("authUser"));
  const handleDelete = async () => {
    setIsLoading(true);
    console.log("clicked");
    console.log(user?._id);
    console.log(preName);
    try {
      await fetch(`${base_URL}/api/auth/delete-folder`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: user?._id,
          folderName: preName,
        }),
      });
      toast.success("successfully deleted folder");
      navigate("/my-favorites");
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg py-4 px-6 w-[380px]  space-y-3 h-52 flex flex-col justify-center items-center  ">
        <div className="flex justify-center items-center relative w-full ">
          <p className="text-center text-[24px] mb-4">Delete Favorites?</p>
          <RxCross2
            size={22}
            className="absolute top-1 right-0 cursor-pointer text-dark"
            onClick={onClose}
          />
        </div>

        <div className=" text-center text-[16px]">
          "{preName}" will be permanently deleted.
        </div>

        <button
          onClick={handleDelete}
          className="bg-primary mt-5 text-white rounded-lg w-[160px] py-2 text-sm"
        >
          {isLoading ? "Deleting..." : "Delete"}
        </button>
      </div>
    </div>
  );
}

export default DeleteFolderModal;
