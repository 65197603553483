/* eslint-disable */

import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { venueStayData } from "../../data/venueStayData";
import { FilterColor } from "../../svgs";
import VenueStayDetailComponent from "./VenueStayDetails";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { MdChevronRight } from "react-icons/md";
import Map from "../map/MapHotel";
import SliderDetailVenueStayTab from "./SliderDetailVenueStayTab";
import { RxCross2 } from "react-icons/rx";
import { setCompareVenuesAndStay } from "../../store/features/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { base_URL } from "../../config/config";
const fetchHotels = async (page, filters) => {
  try {
    const { data } = await axios.get(`${base_URL}/api/hotels/get`, {
      params: {
        page,
        limit: 24,
        ...filters,
      },
    });
    // console.log("Fetched hotels:", data);
    return data;
  } catch (error) {
    console.error("Error fetching hotels:", error);
    return { hotels: [], total: 0 }; // Return default value on error
  }
};

const fetchHotelsData = async (page, filters) => {
  const hotelsData = await fetchHotels(page, filters);
  const hotelsList = hotelsData.hotels || [];
  // console.log("Hotels List:", hotelsList);

  return {
    hotels: [...hotelsList],
    total: hotelsData.total || 0,
  };
};

const sortOptions = [
  {
    name: "Price Per Room",
    options: ["Low to High", "High to Low"],
  },
  {
    name: "Offers Availability",
  },
  {
    name: "Newest",
  },
  { name: "Popularity (Most Viewed)" },
  // "Customer Ratings",
];

const VenuStayTab = ({ filters, isShowMap, setIsShowMap, city, setCount }) => {
  const [filterVenues, setFilterVenues] = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const [page, setPage] = useState(venueStayData.currentPage);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [totalVenues, setTotalVenues] = useState(0);
  const [venues, setVenues] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isCompareMode, setIsCompareMode] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [sortType, setSortType] = useState("default");
  const [showSubSort, setShowSubSort] = useState("");
  const [sortSubType, setSortSubType] = useState("default");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("VenuStayTab rendered with filters:", filters);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["hotels", page, filters],
    queryFn: () => fetchHotelsData(page, filters),
    keepPreviousData: true,
    onSuccess: (data) => {
      // console.log(filters);
      setVenues((prevVenues) => [...prevVenues, ...data.hotels]);
      setCount((prev) => ({ ...prev, venueStay: data.total }));
      setTotalVenues(data.total);
    },
  });

  const handleScroll = () => {
    const bottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight;
    if (bottom && page < data?.totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (page > 1) {
      refetch();
    }
  }, [page]);

  useEffect(() => {
    if (data) {
      // console.log("Data:", data.hotels);
      // This useEffect could be unnecessary if you are already updating in onSuccess
      setTotalVenues(data.total);
      setVenues(data.hotels);
    }
  }, [data]);

  // useEffect(() => {
  //   console.log("venues", venues);
  // }, [data]);
  useEffect(() => {
    setCount((prev) => ({ ...prev, venueStay: totalVenues }));
  }, [venues]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (mapBounds && venues.length > 0) {
      const visibleVenues = venues.filter((venue) => {
        const lat = venue.overview.locationDetails.lat;
        const lng = venue.overview.locationDetails.lng;

        return (
          lat >= mapBounds.south &&
          lat <= mapBounds.north &&
          lng >= mapBounds.west &&
          lng <= mapBounds.east
        );
      });
      if (JSON.stringify(visibleVenues) !== JSON.stringify(venues)) {
        // console.log("Visible Venues:", visibleVenues);
        setFilterVenues(visibleVenues);
      }
    }
  }, [mapBounds, venues]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVenueSelect = (venue) => {
    setSelectedVenues((prevSelected) => {
      const alreadySelected = prevSelected.some(
        (item) => item._id === venue._id
      );
      if (alreadySelected) {
        return prevSelected.filter((item) => item._id !== venue._id);
      } else if (prevSelected.length < 3) {
        return [...prevSelected, venue];
      } else {
        return prevSelected;
      }
    });
  };

  const handleRemove = (id) => {
    setSelectedVenues((prevSelected) =>
      prevSelected.filter((venue) => venue._id !== id)
    );
  };
  const handleCompare = () => {
    dispatch(setCompareVenuesAndStay(selectedVenues));
    navigate("/compare-venues-stay");
  };

  const handleSort = (type) => {
    setSortType(type);
    if (
      type === "Offers Availability" ||
      type === "Newest" ||
      type === "Popularity (Most Viewed)"
    ) {
      setShowSort(false);
    }
    if (type === "Price Per Room") {
      setShowSubSort("pricePerRoom");
    } else {
      return;
    }
  };
  const handleSubSort = (type) => {
    setSortSubType(type);
    setShowSubSort("");
    setShowSort(false);
    // console.log("sortSubType", sortSubType);
  };

  const sortByPageVisits = async () => {
    try {
      const response = await axios.get(
        `${base_URL}/api/impressions/get-pagevisits`
      );
      // console.log("Page visits:", response.data);
      const sortedVenues = venues.sort((a, b) => {
        let visitA, visitB;
        response.data.forEach((visit) => {
          if (visit.venueId === a._id) {
            // console.log("Visit A:", visit.totalPageVisits);
            visitA = visit.totalPageVisits;
          }
          if (visit.venueId === b._id) {
            // console.log("Visit B:", visit.totalPageVisits);
            visitB = visit.totalPageVisits;
          }
        });
        return visitB - visitA;
      });
      // console.log("Sorted Venues:", sortedVenues);
      setVenues(sortedVenues);
      setCount((prev) => ({ ...prev, venueStay: sortedVenues.length }));
      setFilterVenues(sortedVenues);
    } catch (error) {
      console.error("Error fetching page visits:", error);
    }
  };

  useEffect(() => {
    let sortedVenues = [];
    if (sortType === "Offers Availability") {
      sortedVenues = venues.map((venue) => {
        const offers = venue?.offerPackage || [];
        if (offers.length > 0) {
          return venue;
        } else {
          return;
        }
      });
      // console.log(sortedVenues);
      sortedVenues = sortedVenues.filter((venue) => venue !== undefined);
      setFilterVenues(sortedVenues);
      setCount((prev) => ({ ...prev, venueStay: sortedVenues.length }));
      setVenues(sortedVenues);
      setTotalVenues(sortedVenues.length);
    } else if (sortType === "Newest") {
      sortedVenues = venues.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFilterVenues(sortedVenues);
      setCount((prev) => ({ ...prev, venueStay: sortedVenues.length }));
      setVenues(sortedVenues);
    } else if (sortType === "Price Per Room") {
      if (sortSubType === "Low to High") {
        sortedVenues = venues.sort((a, b) => {
          const minSpendA = a?.pricing?.basePrice || 0;
          const minSpendB = b?.pricing?.basePrice || 0;
          return minSpendA - minSpendB;
        });
        setFilterVenues(sortedVenues);
        setCount((prev) => ({ ...prev, venueStay: sortedVenues.length }));
        setVenues(sortedVenues);
      } else if (sortSubType === "High to Low") {
        sortedVenues = venues.sort((a, b) => {
          const minSpendA = a?.pricing?.basePrice || 0;
          const minSpendB = b?.pricing?.basePrice || 0;
          return minSpendB - minSpendA;
        });
        setFilterVenues(sortedVenues);
        setCount((prev) => ({ ...prev, venueStay: sortedVenues.length }));
        setVenues(sortedVenues);
      }
    } else if (sortType === "Popularity (Most Viewed)") {
      sortByPageVisits();
      setCount((prev) => ({ ...prev, venueStay: sortedVenues.length }));
    } else {
      sortedVenues = venues;
    }
  }, [sortType, sortSubType]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading venues</p>;

  return (
    <div className="relative w-full">
      <div className="flex flex-col gap-4">
        <div className="flex w-full justify-between sm:flex-row flex-col font-medium sm:items-center">
          <h1 className="text-lg">
            {filterVenues.length
              ? `${filterVenues.length} `
              : `${totalVenues} `}
            venues in {filters.city || "All Cities"}
          </h1>
          <div className="flex items-center justify-end gap-6">
            {/* <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 cursor-pointer">
                <p>Compare</p>
                <Boxes />
              </div>
            </div> */}
            <div className="flex items-center gap-2 relative">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => {
                  setShowSort(!showSort);
                  setSortType("default");
                }}
              >
                <p className="cursor-pointer">Sort</p>
                <FilterColor className="cursor-pointer" />
              </div>
              {showSort && (
                <div className="absolute top-8 right-0 bg-white shadow-md z-50 w-[170px] rounded-lg p-2">
                  <div className="flex flex-col gap-1 w-full">
                    {sortOptions.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center  w-full justify-between "
                      >
                        <div
                          className=" flex justify-between items-center w-full hover:bg-[#f5f5f5]  p-2 rounded-lg cursor-pointer"
                          onClick={() => handleSort(option.name)}
                        >
                          <p className="text-[14px] font-normal">
                            {option.name}
                          </p>
                          {(option.name === "Price Per Person" ||
                            option.name === "Minimum Spend") && (
                            <MdChevronRight />
                          )}
                        </div>{" "}
                        {option?.options &&
                          showSubSort === "pricePerRoom" &&
                          sortType === option.name && (
                            <div className="absolute top-0  bg-white shadow-md z-50  rounded-lg right-[175px] w-[160px] p-2">
                              <div className="flex flex-col gap-1 w-full">
                                {option.options.map((subOption, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center gap-2 cursor-pointer w-full justify-between "
                                  >
                                    <div
                                      className="text-[14px] w-full font-normal cursor-pointer p-2 rounded-lg hover:bg-[#f5f5f5]"
                                      onClick={() => handleSubSort(subOption)}
                                    >
                                      {subOption}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isShowMap ? (
          <div className={`grid w-full lg:grid-cols-3 gap-6 `}>
            <div
              className={`md:grid w-full lg:col-span-1 lg:grid-cols-1 hidden overflow-y-auto grid-cols-1 gap-6`}
            >
              {isShowMap
                ? filterVenues?.map((venue, index) => (
                    <VenueStayDetailComponent
                      key={index}
                      data={venue}
                      isCompareMode={isCompareMode}
                      isSelected={selectedVenues.some(
                        (selectedVenue) => selectedVenue._id === venue._id
                      )}
                      onSelect={handleVenueSelect}
                    />
                  ))
                : venues?.map((venue, index) => (
                    <VenueStayDetailComponent
                      key={index}
                      data={venue}
                      isCompareMode={isCompareMode}
                      isSelected={selectedVenues.some(
                        (selectedVenue) => selectedVenue._id === venue._id
                      )}
                      onSelect={handleVenueSelect}
                    />
                  ))}
            </div>
            <div className="lg:col-span-2">
              <div className="relative ">
                <Map
                  city={filters.city ? filters.city : city}
                  setIsShowMap={setIsShowMap}
                  places={venues}
                  setMapBounds={setMapBounds}
                  setFilterVenues={setFilterVenues}
                />
              </div>
              {isMobileView && isShowMap && filterVenues.length > 0 && (
                <div className="absolute top-[54vh] overflow-hidden w-full ">
                  <SliderDetailVenueStayTab data={filterVenues} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`grid w-full lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6`}
          >
            {venues?.map((venue, index) => (
              <VenueStayDetailComponent
                key={index}
                data={venue}
                isCompareMode={isCompareMode}
                isSelected={selectedVenues.some(
                  (selectedVenue) => selectedVenue._id === venue._id
                )}
                onSelect={handleVenueSelect}
              />
            ))}
          </div>
        )}
      </div>
      {selectedVenues?.length > 0 && isCompareMode && (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md z-50 p-4 px-6 flex items-center justify-between gap-4">
          <div className="flex items-center gap-5 overflow-x-auto px-2 md:px-0">
            <span className="text-dark md:text-[16px] text-[11px]">
              Compare Venues (Up to 3 Venues)
            </span>
            {selectedVenues?.map((venue) => (
              <div
                key={venue._id}
                className="flex items-center bg-gray-100 shadow-md rounded p-2 md:w-60 gap-y-2 gap-x-7 ml-2"
              >
                <img
                  src={venue?.photoVideo.images[0]}
                  alt={venue.name}
                  className="md:h-10 md:w-10 h-6 w-6 object-cover rounded"
                />
                <span className="md:text-sm font-medium text-xs ">
                  {" "}
                  {venue?.overview?.basicDetails?.hotelName || "Undefined"}
                </span>
                <button
                  onClick={() => handleRemove(venue._id)}
                  className=" text-lg font-bold focus:outline-none"
                >
                  <RxCross2 size={22} className="text-primary cursor-pointer" />
                </button>
              </div>
            ))}
          </div>
          <div>
            <div className="flex justify-center items-center gap-4">
              <button
                onClick={handleCompare}
                className="bg-[#FE4747] md:w-auto w-full text-[12px] rounded-lg text-white py-2 md:px-4 px-3 lg:text-[16px] lg:font-[500]"
              >
                Compare
              </button>
              <div>
                <RxCross2
                  size={22}
                  className="text-primary cursor-pointer"
                  onClick={() => setIsCompareMode(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenuStayTab;
