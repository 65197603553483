import React, { useEffect, useState, useContext } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import { HotelContext } from "../../../context/HotelContext";
import { VenueContext } from "../../../context/VenueContext";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 25.276987,
  lng: 55.296249,
};

const MapContainer = ({
  lat,
  lng,
  isHotel,
  isVenue,
  onLocationChange,
  mapLocation,
  setMapLocation,
  draggable = true,
  showHeader = true,
}) => {
  const [markerPosition, setMarkerPosition] = useState({
    lat,
    lng,
  });
  const [mapCenter, setMapCenter] = useState({
    lat,
    lng,
  });
  const venueContext = useContext(VenueContext);
  const hotelContext = useContext(HotelContext);
  useEffect(() => {
    if (isVenue) {
      if (
        venueContext?.formData?.location?.lat &&
        venueContext?.formData?.location?.lng
      ) {
        setMapLocation({
          lat: venueContext?.formData?.location?.lat,
          lng: venueContext?.formData?.location?.lng,
        });
      }
    } else if (isHotel) {
      if (
        hotelContext?.formData?.locationDetails?.lat &&
        hotelContext?.formData?.locationDetails?.lng
      ) {
        setMapLocation({
          lat: hotelContext?.formData?.locationDetails?.lat,
          lng: hotelContext?.formData?.locationDetails?.lng,
        });
      }
    }
  }, [isHotel, isVenue]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg",
    libraries: ["places"],
  });

  if (loadError) return <div>Error loading maps</div>;

  const handleMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    setMapLocation({ lat: newLat, lng: newLng });
    if (onLocationChange) {
      onLocationChange(newLat, newLng);
    }
  };

  const handlePinLocationClick = () => {
    setMapCenter(markerPosition);
  };

  const handleMapClick = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    setMapLocation({ lat: newLat, lng: newLng });
    setMapCenter({ lat: newLat, lng: newLng });
    if (onLocationChange) {
      onLocationChange(newLat, newLng);
    }
  };

  const onLoad = (marker) => {
    console.log("marker: ", marker);
  };

  return (
    <div>
      {showHeader && (
        <div className="flex justify-between items-center w-full mb-2">
          <p className="text-sm text-secondary">Map Location</p>
          <Button
            type="button"
            className="text-primary text-base flex underline font-medium"
            onClick={handlePinLocationClick}
            style={{ textTransform: "none" }}
          >
            Pin Location
          </Button>
        </div>
      )}
      {isLoaded ? (
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={mapLocation}
          onClick={handleMapClick}
        >
          <MarkerF
            position={mapLocation}
            draggable={draggable}
            onDragEnd={handleMarkerDragEnd}
          />
        </GoogleMap>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="400px"
        >
          <CircularProgress />
          <Typography variant="body1" marginLeft={2}>
            Loading Map...
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default MapContainer;
