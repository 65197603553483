import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import logo from "../assets/images/logo.svg";
import profileIcon from "../assets/images/userres.svg";
import profileIcon2 from "../assets/images/downres.svg";
import Cookies from "js-cookie";
import { useFetchUserByIdQuery } from "../../src/components/SettingsDashboard/Hooks";
import {
  toggleExplore,
  toggleMobileExplore,
  setHoveredOption,
  setActiveSubOption,
} from "../store/features/modalSlice";
const startOptions = [
  {
    name: "Celebrations",
    filterKey: "eventTypes",
    subKey: [
      "Weddings",
      "Weddings",
      "Dining",
      "Dining",
      "Dining",
      "Dining",
      "Milestone Birthdays",
      "Tea Party/Showers",
      "Tea Party/Showers",
      "Teen/Children",
      "Special Big Events",
    ],
    subOptions: [
      "Wedding Ceremony",
      "Wedding Reception",
      "Anniversary Party",
      "Engagement Reception",
      "Graduation Party",
      "New Year's Eve Party",
      "Birthday Party",
      "Baby Shower",
      "Bridal Shower",
      "Teen Party",
      "Gender Reveals",
      "Christmas Party",
      "Cultural Celeberations",
    ],
  },
  {
    name: "Art & Culture",
    filterKey: "eventTypes",
    subKey: [
      "Filming, Photography & Media",
      "Art Spaces",
      "Art Spaces",
      "Performances & Exhibitions",
      "Performances & Exhibitions",
      "Performances & Exhibitions",
    ],
    subOptions: [
      "Photoshoot",
      "Art Exhibitions",
      "Pop Up Event",
      "Concert",
      "Fashion Show",
      "Live Performance",
    ],
  },
  {
    name: "Corporate",
    filterKey: "eventTypes",
    subKey: [
      "Conference/Seminars",
      "Conference/Seminars",
      "Conference/Seminars",
      "Conference/Seminars",
      "Conference/Seminars",
      "Corporate Events",
      "Conference/Seminars",
      "Corporate Events",
      "Corporate Events",
      "Corporate Events",
    ],
    subOptions: [
      "Conference",
      "Exhibitions",
      "Meetings",
      "Networking",
      "Product Launch",
      "Team Building",
      "Training",
      "Office Party",
      "Corporate Gala Dinner",
      "Corporate Retreat",
    ],
  },
  {
    name: "City",
    filterKey: "city",
    subOptions: [
      "Dubai",
      "Abu Dhabi",
      "Ras Al Khaimah",
      "Sharjah",
      "Fujairah",
      "Ajman",
      "Umm Al Quwain",
    ],
  },
  {
    name: "Venue Type",
    filterKey: "venueTypes",
    subOptions: [
      "Beach",
      "Deserts",
      "Boats and Yachts",
      "Venue with Onsite Activities",
      "Sports Venue",
      "Exhibition Hall",
      "Meeting room",
      "Kids play area",
      "Restaurant",
      "Ballroom",
      "Blank Canvas",
      "Auditorium",
      "Warehouse",
      "Indoor",
      "Outdoor",
      "Indoor & Outdoor",
    ],
  },
  {
    name: "Hotel Stay + Venue",
    filterKey: "hotelStayAndVenue",
    subOptions: [
      "Airport Hotel",
      "All-Inclusive Resort",
      "Beach Hotel",
      "Boutique Hotel",
      "Budget Hotel",
      "Business Hotel",
      "Casino Hotel",
      "City Hotel",
      "Desert Hotel",
      "Eco-friendly Hotel",
      "Floating Hotel",
      "Glamping Hotel",
      "Golf Resorts",
      "Heritage Hotel",
      "Historic Hotel",
      "Island Hotel",
      "Luxury Hotel",
      "Resort Hotel",
      "Safari Hotel",
      "Wellness Hotel",
    ],
  },
];

function ResponsiveAppBar({ setFilters }) {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const {
    data: userData,
    isLoading: isFetchingUser,
    error,
  } = useFetchUserByIdQuery(vendorId);

  // Extract user from fetched data
  const user = userData?.vendor;
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [showExploreOptions, setShowExploreOptions] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const { openExplore, openMobileExplore, hoveredOption, activeSubOption } =
    useSelector((state) => state.modal);
  const isLoggedIn = !!localStorage.getItem("accessToken");
  if ((openMobileExplore, toggleMobileExplore)) {
  }

  const setShowExploreOptionsHandler = () => {
    if (
      location.pathname === "/" ||
      location.pathname.includes("/cancellation-policy") ||
      location.pathname.includes("/privacy-policy") ||
      location.pathname.includes(
        "/terms&conditions" || location.pathname.includes("/terms-of-use")
      ) ||
      location.pathname.includes("/list-venues") ||
      location.pathname.includes("/faqs")
    ) {
      setShowExploreOptions(true);
    }
  };

  useEffect(() => {
    setShowExploreOptionsHandler();
  }, []);

  useEffect(() => {
    // Add event listener to close Explore when clicking outside
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        openExplore // Only close if open
      ) {
        dispatch(toggleExplore());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, openExplore]);

  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  const handleLogout = () => {
    Cookies.remove("token", { path: "/" });
    localStorage.clear();
    navigate("/");
  };

  const handleSelectOption = (category, option) => {
    if (category === "hotelStayAndVenue") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        hotelType: [option],
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [category]: [option],
      }));
    }
    dispatch(setHoveredOption(null));
    dispatch(setActiveSubOption(null));
    dispatch(toggleExplore());

    if (category === "hotelStayAndVenue") {
      navigate("/listing/2");
    } else {
      navigate(`/listing/1`);
    }
  };
  return (
    <AppBar position="static" className="z-[9999]">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ width: "100%", zIndex: 10 }}>
          <div style={{ display: "block", width: "auto", zIndex: 10 }}>
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{ display: "block", width: "100%", height: "auto" }}
            />
          </div>

          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{ display: "flex", alignItems: "center", zIndex: 10 }}
            ref={dropdownRef}
          >
            {showExploreOptions && (
              <div
                className="sm:flex hidden items-center gap-2 cursor-pointer relative mr-7"
                onClick={() => dispatch(toggleExplore())}
              >
                <h1 className="text-white">Explore</h1>
                {openExplore ? (
                  <IoMdArrowDropup className="text-white text-lg" />
                ) : (
                  <IoMdArrowDropdown className="text-white text-lg" />
                )}
                {openExplore && (
                  <div
                    className="bg-white text-black rounded-lg absolute top-9 left-0 shadow-xl p-2"
                    style={{ zIndex: 9999 }}
                  >
                    {startOptions.map((e, i) => (
                      <div
                        key={i}
                        style={{ zIndex: 9999 }}
                        className={`py-2 px-2 w-36 flex-1 text-sm flex items-center justify-between gap-2 rounded-md hover:bg-[#FFF5F5] ${
                          hoveredOption === i ? "bg-[#FFF5F5]" : ""
                        }`}
                        onMouseEnter={() => {
                          dispatch(setHoveredOption(i));
                          dispatch(
                            setActiveSubOption(activeSubOption === i ? null : i)
                          );
                        }}
                        onMouseLeave={() => {
                          dispatch(setHoveredOption(null));
                        }}
                        // onClick={(event) => {
                        //   event.stopPropagation();
                        //   dispatch(
                        //     setActiveSubOption(activeSubOption === i ? null : i)
                        //   );
                        // }}
                      >
                        {e.name}
                        <MdOutlineKeyboardArrowRight className="text-lg flex-shrink-0" />
                        {activeSubOption === i && e.subOptions && (
                          <div
                            style={{ zIndex: 9999 }}
                            className={`bg-white custom-scrollbar  rounded-md absolute right-full overflow-auto max-h-screen  grid ${
                              i === 1
                                ? "grid-cols-1 xl:w-[32rem] w-[28rem]"
                                : i === 0
                                ? "grid-cols-2 xl:w-[22rem] w-[20rem]"
                                : i === 4
                                ? "grid-cols-3 xl:w-[40rem] w-[32rem]"
                                : "grid-cols-2 xl:w-[22rem] w-[20rem]"
                            } w-full mr-1 top-0 p-2 shadow-lg`}
                          >
                            {e.subOptions.map((subOption, subIndex) => (
                              <div
                                key={subIndex}
                                className="px-4 py-3 rounded-md text-sm  hover:bg-[#FFF0F0] cursor-pointer"
                                onClick={() => {
                                  if (e.filterKey === "eventTypes") {
                                    const subKeyValue =
                                      startOptions[i].subKey[subIndex];
                                    const eventType = {
                                      [subKeyValue]: [subOption],
                                    };

                                    handleSelectOption(e.filterKey, eventType);
                                  } else {
                                    handleSelectOption(e.filterKey, subOption);
                                  }
                                  dispatch(toggleExplore());
                                }}
                              >
                                {subOption}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {location.pathname !== "/onboarding" && (
              <Link
                to="/list-venues"
                style={{
                  marginRight: "16px",
                  paddingRight: "8px",
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                List your Venue
              </Link>
            )}
            <Box
              sx={{ display: { md: "flex" }, alignItems: "center", zIndex: 10 }}
            >
              <IconButton onClick={handleOpenProfileMenu} sx={{ zIndex: 10 }}>
                {user?.profilePic ? (
                  <>
                    <Box
                      component="img"
                      src={user?.profilePic}
                      alt="profile"
                      sx={{ height: 45, width: 45 }}
                      className="rounded-full"
                    />
                  </>
                ) : (
                  <>
                    <Box
                      component="img"
                      src={profileIcon}
                      alt="profile"
                      sx={{ height: 45, width: 45 }}
                    />
                  </>
                )}

                <Box
                  component="img"
                  src={profileIcon2}
                  alt="profile"
                  className="ml-3"
                />
              </IconButton>
              <Menu
                anchorEl={anchorElProfile}
                open={Boolean(anchorElProfile)}
                onClose={handleCloseProfileMenu}
                sx={{ zIndex: 11 }}
              >
                {isLoggedIn ? (
                  <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                ) : (
                  <MenuItem
                    component={Link}
                    to="/login"
                    onClick={handleCloseProfileMenu}
                  >
                    Log In
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
