/* eslint-disable */

"use client";
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdOutlineStarPurple500 } from "react-icons/md";
import houses from "../../assets2/images/houses.svg";
import bed from "../../assets2/images/bed.svg";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { usePreview } from "../../context/previewContext";
import { useNavigate } from "react-router-dom";
import NewFavoriteFolderModal from "../myFav/NewFavoriteFolderModal";
import AddedFavoriteModal from "../myFav/AddedFavoriteModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MdDone } from "react-icons/md";
import { getEnquiries } from "../../lib/apis";
import Coupon from "../../assets2/icons/Coupon.svg";
import { base_URL } from "../../config/config";
import { DeviceType } from "../../hooks/deviceType";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

function RatingComponent(ratingValue) {
  // console.log(ratingValue);
  const rating = (
    <div className="flex items-center">
      {Array.from({ length: Number(ratingValue) }).map((_, index) => (
        <MdOutlineStarPurple500
          key={index}
          className="text-[#f2c200] text-xl"
        />
      ))}
    </div>
  );

  return rating;
}

const DetailComponent = ({ data, isCompareMode, isSelected, onSelect }) => {
  const deviceType = DeviceType();
  const cardRef = useRef();
  const [ratingValue, setRatingValue] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const favoriteVenuesAndStay =
    user?.favoritesFolder?.map((folder) => folder.venuesAndStay).flat() || [];
  const [favItem, setFavItem] = useState(null);
  const [numberOfVenues, setNumberOfVenues] = useState(undefined);
  const [newFolder, setNewFolder] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddedOpen, setIsModalAddedOpen] = useState(false);
  const [isSubmittedEnquiry, setIsSubmittedEnquiry] = useState(false);
  const [impressions, setImpressions] = useState(new Set());
  const navigate = useNavigate();
  if (isMobileView) {
    // console.log("");
  }
  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-folders/${user?._id}`
      );
      if (!response.ok) {
        console.log("Failed to fetch Venues");
        return;
      }
      const data = await response.json();
      console.log(data);
      return data.folders;
    } catch (err) {
      console.log(err);
    }
  };
  const { folders, refetch } = useQuery({
    queryKey: ["favoriteFolder", user?._id],
    queryFn: getFavoriteFolder,
    enabled: !!user?._id, // Ensure the query only runs when these values are available
  });

  const getVenuesNumber = async () => {
    try {
      const response = await axios.post(
        `${base_URL}/api/hotels/number-of-venues`,
        {
          hotelName: data?.overview?.basicDetails?.hotelName,
        }
      );
      // console.log(data?.overview?.basicDetails?.hotelName);
      // console.log(response.data.totalVenues);
      if (response.data.totalVenues !== 0) {
        setNumberOfVenues(response.data.totalVenues);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVenuesNumber();
  }, [data]);

  const handleImpression = (id) => {
    setImpressions((prevImpressions) => {
      if (!prevImpressions.has(id)) {
        const newSet = new Set(prevImpressions);
        newSet.add(id);
        // console.log(`Venue ${id} impressed`);
        updateImpressions(data?._id);
        return newSet;
      }
      return prevImpressions;
    });
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // console.log(handleImpression);
        if (handleImpression && entry.isIntersecting) {
          handleImpression(data?._id); // Trigger impression when visible
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the card is visible
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [data?._id, handleImpression]);
  const updateImpressions = async (venueId) => {
    const impressionData = {
      venueId: venueId,
      vendorId: data?.vendorId,
      date: new Date(),
      deviceCategory: deviceType,
      impressions: 1,
      venueName: `${data?.basicDetails?.hotelName}, ${data?.basicDetails?.hotelChain}`,
      customerId: user?._id,
    };
    try {
      const response = await fetch(
        `${base_URL}/api/impressions/update-impressions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(impressionData),
        }
      );
      // console.log(response);
      const data = await response.json();
      // console.log(data);
      return data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  const isFavorite = favoriteVenuesAndStay?.some(
    (favorite) => favorite.id === data._id
  );
  // Check if the end date is not in the past
  const hasOffers =
    data?.offerPackage?.length > 0 &&
    data?.offerPackage?.some((offer) => {
      const currentDate = new Date();
      const endDate = new Date(offer?.endDate);
      return endDate >= currentDate;
    });
  const handleClickFavorite = (itemId) => {
    if (!user) {
      return toast.error("Please login first");
    }
    if (isFavorite) {
      return;
    }
    setFavItem(itemId);

    user?.favoritesFolder?.length > 0
      ? setIsModalAddedOpen(true)
      : setIsModalOpen(true);
  };

  const setEnquiryData = async () => {
    const enquiriesData = await getEnquiries();
    const venueEnquiries = enquiriesData?.hotelEnquiries;
    const enquiryId = venueEnquiries?.map((enquiry) => enquiry.venueId);
    if (enquiryId?.includes(data._id)) {
      setIsSubmittedEnquiry(true);
    }
  };

  useEffect(() => {
    setEnquiryData();
  }, []);

  useEffect(() => {
    setRatingValue(Number(data?.overview?.basicDetails?.hotelRating));
  }, [data]);
  const handleViewPreviewDetails = async () => {
    const cf = "hpv";
    navigate(`/hotel-preview/${cf}/${data._id}`);
  };
  const updatePageVisits = async (venueId, venueName, customerId) => {
    const pageVisitData = {
      venueId: venueId,
      venueName: venueName,
      date: new Date(),
      vendorId: data?.vendorId,
      deviceCategory: deviceType,
      customerId: customerId,
      pageVisits: 1,
      isFav: isFavorite,
    };
    // console.log(pageVisitData);
    try {
      const response = await fetch(
        `${base_URL}/api/impressions/update-impressions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pageVisitData),
        }
      );
      // console.log(response);
      const data = await response.json();
      // console.log(data);
      return data;
    } catch (err) {
      // console.log(err);
      return err;
    }
  };

  useEffect(() => {
    // console.log("Data inside Stay Detail Component", data);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      data?.photoVideo?.images?.length > 0
        ? Math.min(data.photoVideo?.images?.length, 1)
        : 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <>
      {newFolder && (
        <NewFavoriteFolderModal
          isOpen={newFolder}
          refetch={refetch}
          onClose={() => setNewFolder(false)}
          itemId={favItem}
          type={"venueAndStay"}
        />
      )}
      {user?.favoritesFolder?.length > 0 ? (
        <AddedFavoriteModal
          setNewFolder={setNewFolder}
          isOpen={isModalAddedOpen}
          refetch={refetch}
          itemId={favItem}
          data={folders}
          type={"venueAndStay"}
          onClose={() => setIsModalAddedOpen(false)}
        />
      ) : (
        <NewFavoriteFolderModal
          isOpen={isModalOpen}
          itemId={favItem}
          refetch={refetch}
          type={"venueAndStay"}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <div className="bg-white rounded-lg shadow-lg relative" ref={cardRef}>
        {isCompareMode && (
          <div className="flex justify-center items-center gap-2 absolute bottom-3 left-3 z-10">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onSelect(data)}
              className=" h-5 w-6"
            />
            <span className="text-[16px] text-dark">Compare</span>
          </div>
        )}
        {data?.photoVideo?.images?.length === 1 ? (
          <div className="w-full relative">
            <div className="w-[100%] h-[250px]">
              <img
                src={data.photoVideo.images[0]}
                alt=""
                className="rounded-lg w-full h-full bg-cover"
              />
            </div>
            <div
              className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
              onClick={() => handleClickFavorite(data?._id, 0)}
            >
              {isFavorite ? (
                <IoMdHeart className="text-primary" />
              ) : (
                <IoMdHeartEmpty className="text-primary" />
              )}
            </div>
            {isSubmittedEnquiry && (
              <div className="bg-[#EAF9F7] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#039f8d] absolute top-3 left-3 rounded-md cursor-pointer">
                <span>
                  <MdDone className="text-md text-[#039f8d]" />
                </span>
                Enquiry Sent
              </div>
            )}
            {hasOffers && (
              <div className="bg-[#fff6e6] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#4B3A19] absolute bottom-3 right-3 rounded-md cursor-pointer">
                <span className="mr-1">
                  <img src={Coupon} alt="" />
                </span>
                Offer Available
              </div>
            )}
          </div>
        ) : data?.photoVideo?.images?.length < 1 ? (
          <div className="w-full relative">
            <div className="w-[100%] h-[250px] bg-[#9b9b9b] rounded-lg">
              {/* <img
              src="https://via.placeholder.com/150"
              alt="askh"
              className="rounded-lg w-full h-full bg-cover"
            /> */}
            </div>
            {/* <div className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer">
            <IoMdHeart className="text-primary" />
          </div> */}
          </div>
        ) : (
          <Slider {...imageSettings}>
            {data?.photoVideo?.images?.map((e, imageIndex) => (
              <div key={imageIndex} className="w-full relative">
                <div className="w-[100%] h-[250px]">
                  <img
                    src={e}
                    alt=""
                    className="rounded-lg w-full h-full bg-cover"
                  />
                </div>
                <div
                  className="bg-white absolute top-3 right-4 p-1.5 rounded-full cursor-pointer"
                  onClick={() => handleClickFavorite(data?._id, imageIndex)}
                >
                  {isFavorite ? (
                    <IoMdHeart className="text-primary" />
                  ) : (
                    <IoMdHeartEmpty className="text-primary" />
                  )}
                </div>
                {isSubmittedEnquiry && (
                  <div className="bg-[#EAF9F7] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#039f8d] absolute top-3 left-3 rounded-md cursor-pointer">
                    <span>
                      <MdDone className="text-md text-[#039f8d]" />
                    </span>
                    Enquiry Sent
                  </div>
                )}
                {hasOffers && (
                  <div className="bg-[#fff6e6] flex justify-center items-center py-[2px] px-2 h-[25px] text-sm text-[#4B3A19] absolute bottom-3 right-3 rounded-md cursor-pointer">
                    <span className="mr-1">
                      <img src={Coupon} alt="" />
                    </span>
                    Offer Available
                  </div>
                )}
              </div>
            ))}
          </Slider>
        )}

        <div className="flex flex-col items-start gap-2 p-3">
          <h1
            className="text-darkGray text-lg font-medium cursor-pointer hover:text-[#FE4747]"
            onClick={() =>
              handleViewPreviewDetails(
                data?._id,
                data?.overview?.basicDetails?.hotelName,
                user?._id
              )
            }
          >
            {data?.overview?.basicDetails?.hotelName || "Undefined"}
          </h1>
          <div className="flex items-center">
            {RatingComponent(ratingValue)}
          </div>
          <p className="text-[#717171]">
            {data?.overview?.locationDetails?.city ||
            data?.overview?.locationDetails?.area ? (
              <>
                {data?.overview?.locationDetails?.city},{" "}
                {data?.overview?.locationDetails?.area}
              </>
            ) : (
              ""
            )}
          </p>
          <div className="flex items-center w-full justify-start gap-4">
            <div className="flex items-center gap-1">
              <img src={bed} alt="" />
              <p className="text-[#717171]">
                {data?.accommodation?.accommodations || "0"}
              </p>
            </div>
            {numberOfVenues && numberOfVenues !== 0 && <h1>.</h1>}
            {numberOfVenues && numberOfVenues !== 0 && (
              <div className="flex items-center gap-1">
                <img src={houses} alt="" />
                <p className=" font-medium text-sm text-[#717171]">
                  {numberOfVenues}
                </p>
              </div>
            )}
          </div>
          {data?.pricing?.basePrice && (
            <div
              className={`flex items-start w-full justify-between  ${
                isCompareMode && "pb-7"
              }`}
            >
              <div className="flex flex-col items-left">
                <h1 className="text-primary text-lg">
                  AED {data?.pricing?.basePrice || "undefined"}
                </h1>
                <p className="text-[#717171] text-sm mt-1">
                  {data?.pricing?.selectedOptions?.[0]?.duration ||
                    "Starting price per room per night"}
                </p>
              </div>
              {/* <div className="flex flex-col items-center">
            <h1 className="text-primary text-lg">
              {data?.pricing?.selectedOptions?.[0]?.minSpend || "N/A"}
            </h1>
            <p className="text-gray text-sm mt-1">
              {data?.pricing?.selectedOptions?.[0]?.duration || "Min. spend"}
            </p>
          </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DetailComponent;
