import React from "react";

function Venueselectfilter({ venueName, setVenueName, venueNames }) {
  const venues = venueNames?.venues?.filter((item) => item) || [];
  const hotels = venueNames?.hotels?.filter((item) => item) || [];

  // Combine venues and hotels into a single array
  const combinedLocations = [...venues, ...hotels];

  return (
    <select
      value={venueName || ""}
      onChange={(e) => setVenueName(e.target.value)}
      className="border border-[#B0B0B0] rounded-lg px-4 py-2 pr-6 outline-none text-sm w-full md:w-[197px] appearance-none bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%226%22%20viewBox%3D%220%200%2010%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.174019%200.688226L0.17402%200.688225C0.281236%200.57126%200.42254%200.512523%200.599191%200.512523H9.40081C9.57742%200.512523%209.71871%200.571806%209.82594%200.689904C9.93375%200.808645%209.9875%200.946822%209.9875%201.10498C9.9875%201.11278%209.9843%201.12733%209.97655%201.14987C9.96895%201.17193%209.95748%201.20034%209.94204%201.23519C9.91131%201.30452%209.8652%201.39861%209.80364%201.51755L5.62731%205.69388C5.53216%205.78903%205.43416%205.85814%205.33338%205.90159C5.23256%205.94504%205.12149%205.96686%205%205.96686C4.87851%205.96686%204.76744%205.94504%204.66662%205.90159C4.56584%205.85814%204.46785%205.78902%204.37269%205.69384L0.194725%201.51591C0.138084%201.45927%200.0935546%201.39611%200.0610483%201.32642C0.0287014%201.25705%200.0125%201.1827%200.0125%201.10322C0.0125%200.943893%200.0662612%200.805758%200.174019%200.688226Z%22%20fill%3D%22%23222222%22%20stroke%3D%22%23222222%22%20strokeWidth%3D%220.025%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-[center_right_1rem]"
    >
      <option value="">All Locations</option>
      {combinedLocations?.length > 0 &&
        combinedLocations?.map((location, index) => (
          <option key={index} value={location}>
            {location}
          </option>
        ))}
    </select>
  );
}

export default Venueselectfilter;
