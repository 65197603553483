import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import CustomCheckbox from "../../components/shared/common/checkBox";

const ArenaHall = ({
  onContinue,
  onClose,
  filteredData,
  setFormData,
  formData,
  title,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Data with `value` fields corresponding to formData keys
  const data = [
    { name: "Basic Information", value: "basicDetails" },
    { name: "Catering", value: "cateringAndDrinks" },
    { name: "Additional Details", value: "additionalDetails" },
    { name: "Equipment", value: "equipment" },
    { name: "Location", value: "location" },
    { name: "Amenities", value: "facilities" },
    // { name: 'Manager Information', value: 'managerInformation' },
    { name: "Audio", value: "audio" },
    { name: "Capacity", value: "capacity" },
    { name: "Parking", value: "parking" },
    { name: "Pricing", value: "pricing" },
    { name: "Licenses", value: "UploadDocuments" },
    { name: "Offers & Packages", value: "offersAndPackages" },
    { name: "Upload Photos & Videos", value: "imagesAndVideos" },
  ];

  const handleCheckboxChange = (value) => {
    setSelectedOptions((prev) => {
      const isSelected = prev.includes(value);
      const updated = isSelected
        ? prev.filter((option) => option !== value)
        : [...prev, value];

      // Update formData based on selected options
      const updatedFormData = { ...formData };

      // If there's data in filteredData, update formData with those values
      if (filteredData.length > 0) {
        const filtered = filteredData[0];
        data.forEach((option) => {
          if (updated.includes(option.value)) {
            updatedFormData[option.value] = filtered[option.value] || {};
          }
        });
      }

      // Update formData with selected options
      if (
        updatedFormData &&
        updatedFormData.basicInformation &&
        updatedFormData.basicInformation.isPrimary
      ) {
        updatedFormData.basicInformation.isPrimary = false;
      }

      setFormData(updatedFormData);
      return updated;
    });
  };
  return (
    <div className="bg-white relative rounded-lg flex flex-col items-center lg:w-[40%] sm:w-[65%] w-[90%] p-4">
      <RxCross2
        onClick={onClose}
        className="absolute text-xl cursor-pointer top-8 right-10"
      />
      <h1 className="text-3xl mt-4 font-semibold w-[90%] text-center">
        {title}
      </h1>
      <p className="text-offGray text-center w-[80%] mt-2">
        Select options that are applicable
      </p>

      <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4 mt-5 px-5">
        {data.map((e, i) => (
          <div key={i} className="flex items-center gap-3">
            <CustomCheckbox
              label={e.name}
              checked={selectedOptions.includes(e.value)}
              onChange={() => handleCheckboxChange(e.value)}
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center flex-col items-center w-full mt-8">
        <button
          className="py-2 w-[85%] px-2 rounded-lg text-white text-sm bg-primary"
          onClick={onContinue}
        >
          Continue
        </button>
        <button className="py-2 px-4" onClick={onClose}>
          Back
        </button>
      </div>
    </div>
  );
};

export default ArenaHall;
