import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import CustomInput from "../shared/CustomInput";
import Button from "../shared/Button";
import { toast } from "react-toastify"; // for notifications

import {
  closeModals,
  toggleUpdatePassword,
} from "../../store/features/modalSlice";
import { useDispatch } from "react-redux";
import { base_URL } from "../../config/config";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [encryptedMail, setEncryptedMail] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get("email");

    // If email exists in the query, dispatch toggleReset to open the reset password modal
    if (emailParam) {
      const decryptMail = window.atob(emailParam);
      setEncryptedMail(decryptMail);
    }
  }, [location, dispatch]);

  const handlePasswordUpdate = async () => {
    if (!newPassword || !confirmPassword) {
      setError("Please fill in both password fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setIsLoading(true);

    try {
      // Send a PUT request to the update-password API with the token and new password
      const response = await axios.put(`${base_URL}/api/auth/update-password`, {
        newPassword,
        email: encryptedMail,
      });

      // Handle success
      toast.success("Password updated successfully!");
      dispatch(toggleUpdatePassword());
      setIsLoading(false);

      // Optionally redirect the user to the login page after resetting the password
      // window.location.href = "/login";
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to update password. Please try again.";
      toast.error(errorMessage);
    }
  };

  return (
    <div>
      <p className="w-[330px] text-center mx-auto mb-[10px] text-[#717171] font-semibold">
        Enter your new password to reset it.
      </p>
      <div className="flex flex-col gap-4">
        <CustomInput
          label={"New Password"}
          type={"password"}
          placeholder={"Enter your new password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <CustomInput
          label={"Confirm Password"}
          type={"password"}
          placeholder={"Re-enter your new password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={error}
        />

        <Button
          onClick={handlePasswordUpdate}
          label={isLoading ? "Updating..." : "Update Password"}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
