import { useState, useEffect } from "react";

export function DeviceType() {
  const [deviceType, setDeviceType] = useState("Desktop");

  useEffect(() => {
    // Ensure this runs only on the client (browser)
    if (typeof window !== "undefined") {
      // Function to determine device type
      const checkDeviceType = () => {
        const width = window.innerWidth;

        if (width <= 640) {
          setDeviceType("Mobile");
        } else if (width <= 1024) {
          setDeviceType("Tablet");
        } else {
          setDeviceType("Desktop");
        }
      };

      // Check device type on mount
      checkDeviceType();

      // Add event listener for window resize
      window.addEventListener("resize", checkDeviceType);

      // Cleanup event listener
      return () => {
        window.removeEventListener("resize", checkDeviceType);
      };
    }
  }, []); 

  return deviceType;
}
