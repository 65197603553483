import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function DateRangleSelector({ startDate, setStartDate, endDate, setEndDate }) {
  return (
    <div className="z-[10000] relative flex items-center border bg-[#FFFFFF] border-[#B0B0B0] rounded-lg px-4 py-2 w-full md:w-[232px]">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2 flex-1">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
            className="outline-none border-none w-full text-sm bg-[#FFFFFF] text-gray-700"
            popperProps={{
              positionFixed: true,
              strategy: "fixed",
            }}
          />
          <span className="text-[#B0B0B0]">-</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
            className="outline-none border-none w-full text-sm bg-[#FFFFFF] text-gray-700"
            popperProps={{
              positionFixed: true,
              strategy: "fixed",
            }}
          />
        </div>
        {/* calendar svg */}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-2 flex-shrink-0"
        >
          <path
            d="M4.375 0C4.54076 0 4.69973 0.065848 4.81694 0.183058C4.93415 0.300269 5 0.45924 5 0.625V1.25H15V0.625C15 0.45924 15.0658 0.300269 15.1831 0.183058C15.3003 0.065848 15.4592 0 15.625 0C15.7908 0 15.9497 0.065848 16.0669 0.183058C16.1842 0.300269 16.25 0.45924 16.25 0.625V1.25H17.5C18.163 1.25 18.7989 1.51339 19.2678 1.98223C19.7366 2.45107 20 3.08696 20 3.75V17.5C20 18.163 19.7366 18.7989 19.2678 19.2678C18.7989 19.7366 18.163 20 17.5 20H2.5C1.83696 20 1.20107 19.7366 0.732233 19.2678C0.263392 18.7989 0 18.163 0 17.5V3.75C0 3.08696 0.263392 2.45107 0.732233 1.98223C1.20107 1.51339 1.83696 1.25 2.5 1.25H3.75V0.625C3.75 0.45924 3.81585 0.300269 3.93306 0.183058C4.05027 0.065848 4.20924 0 4.375 0ZM1.25 5V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H17.5C17.8315 18.75 18.1495 18.6183 18.3839 18.3839C18.6183 18.1495 18.75 17.8315 18.75 17.5V5H1.25Z"
            fill="#222222"
          />
        </svg>
      </div>
    </div>
  );
}

export default DateRangleSelector;
