import React, { useState } from "react";
import Slider from "react-slick";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import NewFavoriteFolderModal from "../myFav/NewFavoriteFolderModal";
import { useNavigate } from "react-router-dom";
import { usePreview } from "../../context/previewContext";
import { useDispatch, useSelector } from "react-redux";
import AddedFavoriteModal from "../myFav/AddedFavoriteModal";
import { toast } from "react-toastify";
const CustomNextArrow = ({ onClick }) => (
  <div
    className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
    onClick={onClick}
  >
    <SlArrowRight className="text-[#000]" />
  </div>
);

const CustomPrevArrow = ({ onClick }) => (
  <div
    className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
    onClick={onClick}
  >
    <SlArrowLeft className="text-[#000] text-xl" />
  </div>
);

const imageSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

const mainCarouselSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function SliderDetailVenueTab({ data }) {
  const user = useSelector((state) => state.auth.user);
  const favoriteVenues =
    user?.favoritesFolder?.map((folder) => folder.venues).flat() || [];
  const [newFolder, setNewFolder] = useState(false);
  const [favItem, setFavItem] = useState(null);
  const [isModalAddedOpen, setIsModalAddedOpen] = useState(false);
  const { setPreviewData } = usePreview();
  const navigate = useNavigate();
  const isFavorite = favoriteVenues.some(
    (favorite) => favorite._id === data._id
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClickFavorite = (itemId, imageIndex) => {
    if (!user) {
      return toast.error("Please login first");
    }

    if (isFavorite) return;

    setFavItem(itemId);

    user?.favoritesFolder?.length > 0
      ? setIsModalAddedOpen(true)
      : setIsModalOpen(true);
  };

  const handleViewPreviewDetails = () => {
    setPreviewData({ type: "venue", data });
    console.log("This is Venue Preview");
    navigate("/preview");
  };
  return (
    <>
      {" "}
      {newFolder && (
        <NewFavoriteFolderModal
          isOpen={newFolder}
          onClose={() => setNewFolder(false)}
          itemId={favItem}
          type={"venue"}
        />
      )}
      {user?.favoritesFolder.length > 0 ? (
        <AddedFavoriteModal
          setNewFolder={setNewFolder}
          isOpen={isModalAddedOpen}
          itemId={favItem}
          data={user?.favoritesFolder}
          type={"venue"}
          onClose={() => setIsModalAddedOpen(false)}
        />
      ) : (
        <NewFavoriteFolderModal
          isOpen={isModalOpen}
          itemId={favItem}
          type={"venue"}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <div>
        <Slider {...mainCarouselSettings}>
          {data.map((item, itemIndex) => (
            <div key={`${item.id}-${itemIndex}`} className="p-2">
              <div className="bg-white rounded-lg shadow-lg w-full">
                {item?.imagesAndVideos?.images?.length === 1 ? (
                  <div className="w-full relative h-[247px]">
                    <img
                      src={item?.imagesAndVideos?.images?.[0]}
                      alt=""
                      className="rounded-lg w-full h-full"
                    />
                    <div
                      className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                      onClick={() => handleClickFavorite(data._id, 0)}
                    >
                      {isFavorite ? (
                        <IoMdHeart className="text-primary" />
                      ) : (
                        <IoMdHeartEmpty className="text-primary" />
                      )}
                    </div>
                  </div>
                ) : (
                  <Slider {...imageSettings}>
                    {item?.imagesAndVideos?.images?.map((e, imageIndex) => (
                      <div
                        key={imageIndex}
                        className="w-full relative h-[247px]"
                      >
                        <img
                          src={e}
                          alt=""
                          className="rounded-lg w-full h-full"
                        />
                        <div
                          className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                          onClick={() =>
                            handleClickFavorite(data._id, imageIndex)
                          }
                        >
                          {isFavorite ? (
                            <IoMdHeart className="text-primary" />
                          ) : (
                            <IoMdHeartEmpty className="text-primary" />
                          )}
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}

                <div className="flex flex-col items-start gap-2 p-3">
                  <h1
                    className="text-darkGray text-[16] font-medium"
                    onClick={() => handleViewPreviewDetails()}
                  >
                    {item?.basicDetails?.venueName || "Undefined"},{" "}
                    {item?.basicDetails?.hotelName || "Undefined"}
                  </h1>
                  <p className="text-gray">
                    {item?.location?.city || "Undefined"},{" "}
                    {item?.location?.area || "Undefined"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default SliderDetailVenueTab;
