import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { FiPlus } from "react-icons/fi";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../lib/apis";
import { setUser } from "../../store/features/authSlice";
import { base_URL } from "../../config/config";

const AddedFavoriteModal = ({
  isOpen,
  onClose,
  type,
  itemId,
  setNewFolder,
}) => {
  const user = useSelector((state) => state.auth.user);
  const userId = user?._id;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();

  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-folders/${user?._id}`
      );
      if (!response.ok) {
        console.log("Failed to fetch Venues");
        return;
      }
      const data = await response.json();
      // console.log(data);
      return data.folders;
    } catch (err) {
      console.log(err);
    }
  };
  const { data, refetch } = useQuery({
    queryKey: ["favoriteFolder", user?._id],
    queryFn: getFavoriteFolder,
    enabled: !!user?._id, // Ensure the query only runs when these values are available
  });

  const handleNewFolder = () => {
    onClose();

    setNewFolder(true);
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (itemName) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === itemName ? null : itemName
    );
  };

  const handleCreate = async () => {
    if (!selectedItem) return toast.error("Please select a folder");
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${base_URL}/api/favorite/add-favorite`,
        {
          userId,
          name: selectedItem,
          itemId,
          type,
        }
      );

      if (response.data.success) {
        const user = await getUser(userId);
        dispatch(setUser(user));
        refetch();
        toast.success("successfully added to your favorites");
        onClose();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg py-4 px-8 space-y-3 max-h-[70vh] w-[460px] flex flex-col justify-center items-center">
          <div className="flex justify-center items-center relative w-full">
            <p className="text-center text-[24px] mb-4">
              Add to my favorite list
            </p>
            <RxCross2
              size={22}
              className="absolute top-1 right-0 cursor-pointer text-dark"
              onClick={onClose}
            />
          </div>
          <div className="grid grid-cols-2 w-full overflow-y-auto gap-y-4 gap-x-1">
            {data?.map((item) => (
              <div
                key={item?.name}
                className="h-[270px] w-[187px] bg-white shadow-md  rounded-md"
              >
                <img
                  src={item?.image}
                  alt=""
                  className="h-[182px] w-full object-cover"
                />

                <div className="w-full p-2">
                  <div className="flex justify-between items-center">
                    <span className="text-[16px]">{item?.name}</span>
                    <input
                      type="checkbox"
                      className="w-5 h-5 p-2 checked:bg-dark"
                      checked={selectedItem === item?.name}
                      onChange={() => handleCheckboxChange(item?.name)}
                    />
                  </div>
                  <p className="text-[14px]">{item?.count || 0} saved</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-center items-center w-full">
            <div
              className="flex items-center gap-2 cursor-pointer w-[60%] pt-4"
              onClick={handleNewFolder}
            >
              <FiPlus size={20} className="text-dark" />
              <span className="text-[16px]">Create new Favorites</span>
            </div>
            <button
              onClick={handleCreate}
              className="bg-primary mt-5 text-white rounded-lg w-[40%] py-2 text-sm"
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddedFavoriteModal;
