import React, { useState, useRef, useEffect } from "react";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
const SearchableDropdown = ({
  label = "Event Type",
  options = [],
  value,
  onChange,
  error,
  selectedEventType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Filter options based on search term
  useEffect(() => {
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  const handleSelect = (option) => {
    console.log(option?.label, "hooo");
    onChange(option?.label);
    setSearchTerm("");
    setIsOpen(false);
  };

  return (
    <div className="mt-3 relative" ref={dropdownRef}>
      <label className="md:text-[16px] text-[14px] text-[#222222]">
        {label}
      </label>
      <div className="relative w-full mt-1">
        <div className="relative w-full">
          <input
            type="text"
            className="w-full border border-[#DDDDDD] rounded-md px-2 py-[10px] md:text-[16px] text-[14px]"
            placeholder={label}
            value={isOpen ? searchTerm : value?.label || selectedEventType}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setIsOpen(true);
            }}
            onClick={() => setIsOpen(true)}
          />
          <button
            type="button"
            className="absolute right-2 top-1/2 -translate-y-1/2"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              <FaArrowUp className="text-gray-400" />
            ) : (
              <FaArrowDown className="text-gray-400" />
            )}
          </button>
        </div>

        {isOpen && (
          <div className="absolute top-full mt-1 w-full left-0 z-[99999]">
            <div className="bg-white border border-[#DDDDDD] rounded-md w-full">
              <ul className="w-full m-auto overflow-auto custom-scrollbar max-h-[300px]">
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleSelect(option)}
                      className="p-2 cursor-pointer hover:bg-[#fff5f5] hover:rounded-md"
                    >
                      {option.label}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500">No matches found</li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
      {error && <p className="text-[#FE4747] text-sm">{error}</p>}
    </div>
  );
};

export default SearchableDropdown;
