import calculateRestaurantPayment from "./calculateRestaurantPayment";
import costStructure from "../constants/costStructure";

function calculateRestaurantVenuePayment(
  withAlcoholNumber,
  withoutAlcoholNumber,
  sameLocation
) {
  let baseCharge = 2000;

  if (sameLocation === "yes") {
    baseCharge = 1000;
  } else if (sameLocation === "no") {
    baseCharge = 2000;
  }

  const {
    withAlcoholRate,
    withoutAlcoholRate,
    withAlcoholCost,
    withoutAlcoholCost,
    withAlcoholSaving,
    withoutAlcoholSaving,
    subTotal: restaurantSubTotal,
  } = calculateRestaurantPayment(withAlcoholNumber, withoutAlcoholNumber);

  const subTotal = restaurantSubTotal;

  const vatPercentage = costStructure.vat;
  const vat = (subTotal * vatPercentage) / 100;

  const grandTotal = subTotal + vat;

  return {
    baseCharge: baseCharge,
    withAlcoholRate: withAlcoholRate,
    withoutAlcoholRate: withoutAlcoholRate,
    withAlcoholNumber: withAlcoholNumber,
    withoutAlcoholNumber: withoutAlcoholNumber,
    withAlcoholCost: withAlcoholCost,
    withoutAlcoholCost: withoutAlcoholCost,
    withAlcoholSaving: withAlcoholSaving,
    withoutAlcoholSaving: withoutAlcoholSaving,
    subTotal: subTotal,
    vat: vat,
    grandTotal: grandTotal,
  };
}

export default calculateRestaurantVenuePayment;
