import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Budgetzero from "./Budgetzero";
import { base_URL } from "../../config/config";

const formatYAxis = (value) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value;
};

const BudgetGraph = ({ startDate, endDate, venueName }) => {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const { data, isLoading } = useQuery({
    queryKey: ["budgetAnalytics", startDate, endDate, venueName],
    queryFn: async () => {
      const { data } = await axios.get(
        `${base_URL}/api/dashboard/getBudgetAnalysis`,
        {
          params: {
            vendorId,
            startDate: startDate ? startDate.toLocaleDateString("en-CA") : null,
            endDate: endDate ? endDate.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return data;
    },
  });

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const percentageChange = payload[0]?.payload?.percentageChange;
      const isPositive = percentageChange > 0;

      return (
        <div className="bg-white p-2 border border-gray-200 rounded-md shadow-sm">
          <p className="text-[10px] text-[#B0B0B0] font-medium">
            {payload[0]?.payload?.month}
          </p>
          <div className="text-[12px] text-[#222222] flex items-center gap-1">
            AED {payload[0]?.value?.toLocaleString()}
            {percentageChange !== undefined && percentageChange !== null && (
              <span
                className={`text-[9px] p-1 rounded-lg ml-1 ${
                  isPositive
                    ? "text-green-700 bg-green-100"
                    : "text-red-700 bg-red-100"
                }`}
              >
                {isPositive ? "+" : ""}
                {percentageChange}%
              </span>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  // Show Budgetzero component when no data
  if ((!data || data.length === 0) && !isLoading) {
    return <Budgetzero />;
  }

  // Calculate average budget for the title
  const averageBudget = data?.length
    ? Math.round(data.reduce((sum, item) => sum + item.budget, 0) / data.length)
    : 0;

  return (
    <div className="p-4 bg-[#FFFFFF] rounded-2xl">
      <div className="space-y-0 pb-2">
        <div className="flex items-baseline gap-2">
          <p className="text-[20px] md:text-[28px] font-bold">
            AED {averageBudget.toLocaleString()}
          </p>
          <span className="text-[14px] md:text-[16px] text-[#222222]">
            Average budget
          </span>
        </div>
        <p className="text-[12px] md:text-[14px] text-[#717171]">
          Average budget per enquiry
        </p>
      </div>
      <div>
        <div className="h-[190px] w-full mt-3">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              margin={{ top: 5, right: 0, bottom: 5, left: -3 }}
            >
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#DFDFFF" />
                  <stop offset="95%" stopColor="#DFDFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#E5E7EB"
                horizontal={true}
              />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fill: "#717171" }}
                dy={10}
                padding={{ left: 0, right: 0 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fill: "#717171" }}
                width={50}
                dx={-8}
                tickFormatter={formatYAxis}
                domain={[0, "auto"]}
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="budget"
                stroke="#7A79FF"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorGradient)"
                strokeDasharray="3 3"
                dot={false}
                activeDot={{
                  r: 6,
                  fill: "#4F46E5",
                  stroke: "#FFFFFF",
                  strokeWidth: 2,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default BudgetGraph;
