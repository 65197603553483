/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

import Navbar from "../../components/Navbar";
import { VenueContext } from "../../context/VenueContext";
import HeroSection from "../../components/venue-preview/hero-section";
import OverviewSection from "../../components/venue-preview/overview-section";
import AboutThisHotel from "../../components/venue-preview/about-hotel";
import { useQuery } from "@tanstack/react-query";
import { getVenueDataById } from "../../api/venue-api";
import Highlights from "../../components/venue-preview/heighlight";
import OffersAndPackages from "../../components/venue-preview/offer-package";
import Pricing from "../../components/venue-preview/pricing";
import CapacityLayout from "../../components/venue-preview/capacity";
import WhatThisPlaceOffers from "../../components/venue-preview/catering-drinking";
import Facilities from "../../components/venue-preview/facilities-equilment";
import EventType from "../../components/venue-preview/event-type";
import GoogleMapComponent from "../../components/venue-preview/location";
import { getVendorHotel } from "../../api/hotel-api";
import { fetchLoggedUser } from "../../api/loggedUser";

const VenuePreview = () => {
  const { formData, setFormData } = useContext(VenueContext);
  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    poBox: "",
    country: "",
    mapLink: "",
    lat: null,
    lng: null,
  });


  const vendorId = localStorage?.getItem("vendorId");
  
  const { data: user } = useQuery({
    queryKey: ["loggedin-user"],
    queryFn: async () => {
      const response = await fetchLoggedUser();
      return response?.vendor;
    },
  });

  const { data: hotel, isPending } = useQuery({
    queryKey: ["vendor-hotel"],
    queryFn: async () => {
      if (user?.onBoardingDetails?.type === "HotelStayandVenue") {
        const res = await getVendorHotel(vendorId);
        setLocationDetails({
          ...locationDetails,
          addressLine1: res?.overview?.locationDetails?.addressLine1,
          addressLine2: res?.overview?.locationDetails?.addressLine2,
          city: res?.overview?.locationDetails?.city,
          area: res?.overview?.locationDetails?.area,
          poBox: res?.overview?.locationDetails?.poBox,
          country: res?.overview?.locationDetails?.country,
          mapLink: res?.overview?.locationDetails?.mapLink,
          lat: res?.overview?.locationDetails?.lat,
          lng: res?.overview?.locationDetails?.lng,
        });
        return res;
      } else {
        setLocationDetails({
          ...locationDetails,
          addressLine1: formData?.location?.addressLine1,
          addressLine2: formData?.location?.addressLine2,
          city: formData?.location?.city,
          area: formData?.location?.area,
          poBox: formData?.location?.poBox,
          country: formData?.location?.country,
          mapLink: formData?.location?.mapLink,
          lat: formData?.location?.lat,
          lng: formData?.location?.lng,
        });
        return true;
      }
    },
    enabled: !!user,
  });
  const { data, error, isLoading } = useQuery({
    queryKey: ["venueData", vendorId],
    queryFn: async () => {
      if (!vendorId) return null;
      return await getVenueDataById(formData?._id);
    },

    enabled: !!vendorId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const videos1 = formData?.imagesAndVideos?.videos;
  const videos2 = formData?.imagesAndVideos?.videoLinks;
  const allVideos = [...videos1, ...videos2];

  return (
    <div>
      <Navbar />
      <HeroSection
        data={formData?.imagesAndVideos?.images}
        videos={allVideos}
        id={formData?._id}
      />
      <div className="max-w-[1200px] mx-auto md:px-0 px-5">
        <OverviewSection data={formData} locationDetails={locationDetails} />
        <Highlights data={formData} />
        <AboutThisHotel data={formData} />

        <OffersAndPackages data={formData?.offersAndPackages} />
        <Pricing data={formData?.pricing} />
        <CapacityLayout
          data={formData?.capacity}
          UploadDocuments={formData?.UploadDocuments}
        />
        <WhatThisPlaceOffers data={formData} />
        <Facilities data={formData} />

        <EventType data={formData} />
        <div id="map">
          <GoogleMapComponent data={locationDetails} />
        </div>
      </div>
    </div>
  );
};

export default VenuePreview;
