import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import chair from "../../assets2/images/Chair.svg";
import houses from "../../assets2/images/houses.svg";
import people from "../../assets2/images/people.svg";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { usePreview } from "../../context/previewContext";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { base_URL } from "../../config/config";
import axios from "axios";
import { useSelector } from "react-redux";
import MovetoOtherFolderModal from "./MovetoOtherFolderModal";
import { RxCross2 } from "react-icons/rx";

const VenueFavoriteDetail = ({
  data,
  name,
  refetch,
  isCompareMode,
  onSelect,
  setCompareType,
  handleRemove,
  handleCompare,
  selectedVenues,
  setIsCompareMode,
  compareType,
  isBulkEnquiry,
  clearSelectedVenues,
  selectedEnquiries,
  handleBulkEnquiry,
  handleEnquiryRemove,
  onEnquirySelect,
  setEnquiryType,
  enquiryType,
  setIsBulkEnquiry,
}) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSelectedCompare, setIsSelectedCompare] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [options, setOptions] = useState(false);
  const navigate = useNavigate();
  const { setPreviewData } = usePreview();
  const [moveToOther, setMoveToOther] = useState(false);
  const [itemId, setItemId] = useState(null);

  if (isMobileView) {
    console.log("");
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const handleViewPreviewDetails = () => {
    const cf = "dhpv";
    navigate(`/preview/${cf}/${data?._id}`);
  };

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      data?.imagesAndVideos?.images?.length > 0
        ? Math.min(data.imagesAndVideos.images.length, 1)
        : 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const handleDelete = async () => {
    try {
      await fetch(`${base_URL}/api/auth/delete-folder`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: user?._id,
          folderName: name,
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const removeHandle = async (itemId) => {
    try {
      const response = await axios.put(`${base_URL}/api/auth/unfav-item`, {
        customerId: user?._id,
        itemId,
        type: "venues",
        folderName: name,
      });
      const data = await response.data;
      if (
        data.folder.venues.length === 0 &&
        data.folder.venuesAndStay.length === 0
      ) {
        await handleDelete();
      }

      toast.success(`Successfully removed from ${name}`);
      refetch();
      navigate("/my-favorites");
    } catch (error) {
      console.log(error);
    }
  };

  const moveHandle = (id) => {
    // console.log("clicked");
    setItemId(id);
    setMoveToOther(true);
  };

  return (
    <>
      {moveToOther && (
        <MovetoOtherFolderModal
          isOpen={moveToOther}
          onClose={() => setMoveToOther(false)}
          data={user.favoritesFolder}
          type={"venues"}
          itemId={itemId}
          preName={name}
        />
      )}
      <div
        className={`${isCompareMode ? "mb-0 " : "mb-20"} ${
          isBulkEnquiry ? "mb-0 " : "mb-20"
        }bg-white rounded-lg shadow-lg relative`}
      >
        {data?.imagesAndVideos?.images?.length === 1 ? (
          <div className="w-full relative">
            <div className="w-[100%] h-[250px]">
              <img
                src={data?.imagesAndVideos?.images?.[0]}
                alt=""
                className="rounded-lg w-full h-full bg-cover"
              />
            </div>

            <div
              className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer z-50"
              onClick={() => setOptions(!options)}
            >
              <BsThreeDotsVertical size={20} />
            </div>
            {options && (
              <div className="bg-white absolute top-12 right-8 rounded-md w-40 z-0 flex flex-col justify-center items-center">
                <div
                  className="text-[14px] cursor-pointer py-3 px-3"
                  onClick={() => removeHandle(data?._id)}
                >
                  Unfavorite
                </div>
                <div
                  className="text-[14px] cursor-pointer py-3 px-3"
                  onClick={() => moveHandle(data?._id)}
                >
                  Move to other list
                </div>
              </div>
            )}
          </div>
        ) : (
          <Slider {...imageSettings}>
            {data?.imagesAndVideos?.images?.map((e, imageIndex) => (
              <div key={imageIndex} className="w-full relative">
                <div className="w-[100%] h-[250px]">
                  <img
                    src={e}
                    alt=""
                    className="rounded-lg w-full h-full bg-cover"
                  />
                </div>
                <div
                  className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer z-50"
                  onClick={() => setOptions(!options)}
                >
                  <BsThreeDotsVertical size={20} />
                </div>
                {options && (
                  <div className="bg-white absolute top-12 right-8 rounded-md w-40 z-0 flex flex-col justify-center items-center">
                    <div
                      className="text-[14px] cursor-pointer py-3 px-3"
                      onClick={() => removeHandle(data?._id)}
                    >
                      Unfavorite
                    </div>
                    <div
                      className="text-[14px] cursor-pointer py-3 px-3"
                      onClick={() => moveHandle(data?._id)}
                    >
                      Move to other list
                    </div>
                    {/* {isCompareMode && (
                      <div className="flex justify-center items-center gap-2 absolute bottom-3 left-3 z-10">
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => {
                            onSelect(data);
                            setCompareType("venues");
                            console.log(compareType);
                          }}
                          className=" h-5 w-6"
                        />
                        <span className="text-[16px] text-dark">Compare</span>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            ))}
          </Slider>
        )}

        {selectedVenues?.length > 0 &&
          isCompareMode &&
          compareType === "venues" && (
            <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md z-50 p-4 px-6 flex items-center overflow-x-auto justify-between gap-4">
              <div className="flex items-center gap-5 overflow-x-auto custom-scrollbar1 px-2 md:px-0">
                <span className="text-dark md:text-[16px] text-[11px]">
                  Venues
                </span>
                {selectedVenues?.map((venue) => (
                  <div
                    key={venue._id}
                    className="flex items-center bg-gray-100 shadow-md rounded p-2 md:min-w-60 gap-y-2 gap-x-7 ml-2"
                  >
                    <img
                      src={venue?.imagesAndVideos?.images?.[0]}
                      alt={venue?.name}
                      className="md:h-10 md:w-10 h-6 w-6 object-cover rounded"
                    />
                    <span className="md:text-sm font-medium text-xs ">
                      {" "}
                      {venue?.basicDetails?.venueName || "Undefined"},{" "}
                      {venue?.basicDetails?.hotelName || "Undefined"}
                    </span>
                    <button
                      onClick={() => handleRemove(venue?._id)}
                      className=" text-lg font-bold focus:outline-none"
                    >
                      <RxCross2
                        size={22}
                        className="text-primary cursor-pointer"
                      />
                    </button>
                  </div>
                ))}
              </div>
              <div>
                <div className="flex justify-center items-center gap-4">
                  <button
                    onClick={handleCompare}
                    className="bg-[#FE4747] md:w-auto w-full text-[12px] rounded-lg text-white py-2 md:px-4 px-3 lg:text-[16px] lg:font-[500]"
                  >
                    Compare
                  </button>

                  <div>
                    <RxCross2
                      size={22}
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        setIsSelectedCompare(false);
                        clearSelectedVenues();
                        setIsCompareMode(false);
                        setCompareType("both");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        {selectedEnquiries?.length > 0 &&
          isBulkEnquiry &&
          enquiryType === "venues" && (
            <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md z-50 p-4 px-6 flex items-center justify-between gap-4 overflow-x-auto ">
              <div className="flex items-center gap-5 overflow-x-auto custom-scrollbar1 px-2 md:px-0">
                <span className="text-dark md:text-[16px] text-[11px]">
                  Venues
                </span>
                {selectedEnquiries?.map((venue) => (
                  <div
                    key={venue._id}
                    className="flex items-center bg-gray-100 shadow-md rounded p-2 md:min-w-60 gap-y-2 gap-x-7 ml-2 mb-1"
                  >
                    <img
                      src={venue?.imagesAndVideos?.images[0]}
                      alt={venue?.name}
                      className="md:h-10 md:w-10 h-6 w-6 object-cover rounded"
                    />
                    <span className="md:text-sm font-medium text-xs ">
                      {" "}
                      {venue?.basicDetails?.venueName || "Undefined"}
                    </span>
                    <button
                      onClick={() => handleEnquiryRemove(venue?._id)}
                      className=" text-lg font-bold focus:outline-none"
                    >
                      <RxCross2
                        size={22}
                        className="text-primary cursor-pointer"
                      />
                    </button>
                  </div>
                ))}
              </div>
              <div>
                <div className="flex justify-center items-center gap-4">
                  <button
                    onClick={handleBulkEnquiry}
                    className="bg-[#FE4747] md:w-auto w-full text-[12px] rounded-lg text-white py-2 md:px-4 px-3 lg:text-[16px] lg:font-[500]"
                  >
                    Send Enquiry
                  </button>
                  <div>
                    <RxCross2
                      size={22}
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        setIsBulkEnquiry(false);
                        setEnquiryType("");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className={`flex flex-col items-start gap-2 p-3`}>
          <h1
            className="text-darkGray text-lg font-medium cursor-pointer hover:text-[#FE4747]"
            onClick={() => handleViewPreviewDetails()}
          >
            {data?.basicDetails?.venueName || "Undefined"},{" "}
            {data?.basicDetails?.hotelName || "Undefined"}
          </h1>
          <p className="text-gray">
            {data?.location?.city || "Undefined"},{" "}
            {data?.location?.area || "Undefined"}
          </p>
          <div className="flex items-center w-full justify-between gap-4">
            <div className="flex items-center gap-1">
              <img src={chair} alt="" />
              <p className="text-gray">
                {data?.capacity?.sitting || "Undefined"}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <img src={people} alt="" />
              <p className="text-gray">
                {data?.capacity?.standing || "Undefined"}
              </p>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <img src={houses} alt="" />
              <p className=" font-medium text-sm text-gray">
                {data?.venueType?.venueRepresent} .{" "}
                {data?.additionalDetails?.venueSetting?.[0]}
              </p>
            </div>
          </div>
          <div
            className={`flex items-start w-full justify-between ${
              compareType === "both" || compareType === "venues"
                ? "mb-8"
                : "mb-0"
            } ${
              enquiryType === "both" || enquiryType === "venues"
                ? "mb-8"
                : "mb-0"
            }`}
          >
            {data?.pricing?.minSpendPerPerson?.[0]?.minSpend && (
              <div className="flex flex-col items-center">
                {data?.pricing?.minSpendPerPerson?.[0]?.minSpend && (
                  <h1 className="text-primary text-lg">
                    AED{" "}
                    {data?.pricing?.minSpendPerPerson?.[0]?.minSpend || "N/A"}
                  </h1>
                )}
                <p className="text-gray text-sm mt-1">per guest</p>
              </div>
            )}
            {data?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend && (
              <div className="flex flex-col items-center">
                <h1 className="text-primary text-lg">
                  AED{" "}
                  {data?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend ||
                    "N/A"}
                </h1>
                <p className="text-gray text-sm mt-1">Min. spend</p>
              </div>
            )}
          </div>
          {(compareType === "both" || compareType === "venues") &&
            isCompareMode && (
              <div className="flex justify-between my-1 absolute bottom-2  w-[90%] mx-auto items-center ">
                <input
                  type="checkbox"
                  checked={
                    selectedVenues?.find((venue) => venue._id === data._id) ||
                    false
                  }
                  onChange={() => {
                    const isSelected = selectedVenues?.find(
                      (venue) => venue._id === data._id
                    );

                    if (!isSelected && selectedVenues.length >= 3) {
                      toast.error("Maximum 3 venues can be selected");
                      return;
                    }
                    onSelect(data);
                    setCompareType("venues");
                    setIsSelectedCompare(!isSelectedCompare);
                  }}
                  className=" h-5 w-6"
                />
                <span className="text-[16px] text-dark">Compare</span>
              </div>
            )}
          {(enquiryType === "both" || enquiryType === "venues") &&
            isBulkEnquiry && (
              <div className="flex justify-between my-1 absolute bottom-2  w-[90%] mx-auto items-center gap-2 ">
                <input
                  type="checkbox"
                  checked={
                    selectedEnquiries?.find(
                      (enquiry) => enquiry._id === data._id
                    ) || false
                  }
                  onChange={() => {
                    onEnquirySelect(data);
                    setEnquiryType("venues");
                  }}
                  className=" h-5 w-6"
                />
                <span className="text-[16px] text-dark">Bulk Enquiry</span>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default VenueFavoriteDetail;
