import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleResetWorkMail,
  closeModals,
} from "../../store/features/modalSlice"; // Import closeModals
import { Tick } from "../../svgs";

const PasswordUpdated = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Set up a 5-second timer
    const timer = setTimeout(() => {
      dispatch(closeModals()); // Dispatch the closeModals action after 5 seconds
    }, 5000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [dispatch]);

  const goBack = () => {
    dispatch(toggleResetWorkMail());
    window.location.replace("/");
  };
  return (
    <div className="w-full py-3 text-[#B0B0B0] flex flex-col items-center gap-3">
      <Tick />
      <h1 className="text-dark mt-5 text-center font-bold sm:text-3xl text-2xl">
        Password Updated
      </h1>
      <p className="text-lg text-center">
        Your password has been updated successfully. Redirecting to login page.
      </p>
      <p className="text-center text-sm mt-3">
        Entered wrong email?{" "}
        <button onClick={goBack} to="#" className="text-primary">
          Back to Home Page in (5s)
        </button>
      </p>
    </div>
  );
};

export default PasswordUpdated;
