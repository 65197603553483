import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function SaveFavGraph({ data1, startDate, endDate }) {
  const [totalImpressions, setTotalImpressions] = useState(0);
  const [totalPageVisits, setTotalPageVisits] = useState(0);
  const [totalEnquiries, setTotalEnquiries] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const data = [
    { name: "Impressions", value: totalImpressions },
    { name: "Added to Favourites", value: totalPageVisits },
    { name: "Enquiries from Favourites", value: totalEnquiries },
  ];

  const calculateTotals = (data) => {
    let totals = { impressions: 0, pageVisits: 0, enquiries: 0 };

    totals = data.reduce(
      (acc, venue) => {
        acc.impressions +=
          startDate || endDate
            ? venue.favImpressionsByDate
            : venue.favImpressions;
        acc.pageVisits += venue.addedToFavourites;
        acc.enquiries += venue.enquiries;
        return acc;
      },
      { impressions: 0, pageVisits: 0, enquiries: 0 }
    );

    setTotalImpressions(totals.impressions);
    setTotalPageVisits(totals.pageVisits);
    setTotalEnquiries(totals.enquiries);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true); // Reset loading state
    if (data1) {
      calculateTotals(data1);
    }
  }, [data1]); // Recalculate on changes
  const truncate = (str, maxLength) =>
    str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  return (
    <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px]">
      <div className="mb-8">
        <p className="text-[14px] font-medium">Saved as Favourite</p>
        <p className="text-[12px] text-[#00000005]/60 ">
          venue saved as favourites by customers
        </p>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <div className="w-10 h-10 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={data}>
            <XAxis
              dataKey="name"
              tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
              tickFormatter={(name) =>
                window.innerWidth < 640 ? truncate(name, 5) : name
              }
              tickLine={false}
            />
            <YAxis
              type="number"
              domain={[0, "dataMax"]}
              allowDecimals={false}
              tick={{ fontSize: 12 }}
              tickLine={false}
            />
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke="none"
              fill="rgba(73, 144, 251, 0.06)"
              // fillOpacity={0.3}
            />
            <Bar dataKey="value" fill="#3b82f6" barSize={120} />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default SaveFavGraph;
