import React, { useEffect, useState } from "react";
import CustomListingSelector from "../../shared/CustomListingSelector";
import RatingListingSelector from "../../shared/RatingListingSelector";
import { Filter } from "../../../svgs";
import { LuSearch } from "react-icons/lu";
import { cities, statesAndCities } from "../../../data";
import {
  chains,
  hotelType,
  eventType,
  getLast50Years,
  businessFeatures,
  recreationalFeatures,
  roomFeatures,
  ratingFilters,
} from "../../../data/hotelFilters";
import CustomInput from "../../shared/CustomInput";
import { RxCross1 } from "react-icons/rx";
import { IoMdArrowDropdown } from "react-icons/io";

function countValidKeyValuePairs(obj) {
  let count = 0;

  // Iterate through the keys of the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Check the conditions based on the value type
      if (Array.isArray(value)) {
        // Check if the array length is at least 1
        if (value.length >= 1) {
          count++;
        }
      } else if (typeof value === "string") {
        // Check if the string has at least 1 character
        if (value.length > 0) {
          count++;
        }
      } else if (typeof value === "number") {
        // Check if the number is greater than zero
        if (value > 0) {
          count++;
        }
      } else if (typeof value === "object" && value !== null) {
        // Check if the object is not null or undefined
        if (Object.keys(value).length > 0) {
          // Checks if the object has any properties
          count++;
        }
      }
    }
  }

  return count;
}

const popupKeyPair = {
  area: false,
  numberOfRooms: false,
  basePrice: false,
  eventType: false,
  hotelType: false,
  hotelChain: false,
  builtYear: false,
  renovatedYear: false,
  businessFeatures: false,
  recreationalFeatures: false,
  roomFeatures: false,
  ratings: false,
};

const VenueStayFilter = ({ filters, setFilters }) => {
  // console.log("Filters", filters);

  const [showFilter, setShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [select, setSelect] = useState("");
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [city, setCity] = useState(filters.city);
  const [selectedEventType, setSelectedEventType] = useState();
  const [selectedHotelType, setSelectedHotelType] = useState(filters.hotelType);
  const [selectedBuiltYear, setSelectedBuiltYear] = useState("");
  const [selectedRenovatedYear, setSelectedRenovatedYear] = useState("");
  const { years1, years2 } = getLast50Years();
  const [openPopup, setOpenPopup] = useState(popupKeyPair);

  const [numberOfRooms, setNumberOfRooms] = useState(filters.numberOfRooms);
  useEffect(() => {
    // console.log(filters.numberOfRooms);
    setNumberOfRooms(filters.numberOfRooms);
    setSelectedEventType(filters.eventType);
  }, [filters]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [hotelChain, setHotelChain] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [selectedRoomFeatures, setSelectedRoomFeatures] = useState([]);
  const [selectedBusinessFeatures, setSelectedBusinessFeatures] = useState([]);
  const [selectedRecreationalFeatures, setSelectedRecreationalFeatures] =
    useState([]);
  const handleOpenPopup = (name) => {
    setOpenPopup((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: e.target.value,
    }));
  };

  const handleSelectChange = (e) => {
    setSelect(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      select: e.target.value,
    }));
  };

  useEffect(() => {
    countValidKeyValuePairs(filters);
  }, [filters]);

  const handleCityChange = (e) => {
    setCity(e.target.value);
    // console.log(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      city: e.target.value,
    }));
  };

  const handleAreaChange = (selectedOptions) => {
    setSelectedArea(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      area: selectedOptions,
    }));
  };

  const handleSelectedEventType = (selectedOptions) => {
    setSelectedEventType(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      eventType: selectedOptions,
    }));
  };

  const handleSelectedHotelType = (selectedOptions) => {
    setSelectedHotelType(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      hotelType: selectedOptions,
    }));
  };

  const handleSelectedBuiltYear = (selectedOptions) => {
    setSelectedBuiltYear(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      builtYear: selectedOptions,
    }));
  };
  const handleSelectedRenovatedYear = (selectedOptions) => {
    setSelectedRenovatedYear(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      renovatedYear: selectedOptions,
    }));
  };

  const handlehotelChain = (selectedOptions) => {
    setHotelChain(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      hotelChain: selectedOptions,
    }));
  };

  const handleRoomChange = (e) => {
    setNumberOfRooms(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      numberOfRooms: e.target.value,
    }));
  };

  const handleBasePriceChange = (e) => {
    setBasePrice(e.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      basePrice: e.target.value,
    }));
  };

  const handleSelectedRoomFeatures = (selectedOptions) => {
    setSelectedRoomFeatures(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      roomFeatures: selectedOptions,
    }));
  };

  const handleSelectedBusinessFeatures = (selectedOptions) => {
    setSelectedBusinessFeatures(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      businessFeatures: selectedOptions,
    }));
  };

  const handleSelectedRecreationalFeatures = (selectedOptions) => {
    setSelectedRecreationalFeatures(selectedOptions);
    setFilters((prevFilters) => ({
      ...prevFilters,
      recreationalFeatures: selectedOptions,
    }));
  };

  const handleRatingChange = (newRating) => {
    setSelectedRatings(newRating);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ratings: newRating,
    }));
  };

  const handleShowFilters = () => {
    setShowFilter(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseFilters = () => {
    setShowFilter(false);
    document.body.style.overflow = "auto";
  };

  const resetAll = () => {
    setCity("");
    setNumberOfRooms("");
    setSelectedEventType([]);
    setSelectedHotelType([]);
    setSelectedBuiltYear([]);
    setSelectedRenovatedYear([]);
    setHotelChain("");
    setBasePrice("");
    setSelectedRatings([]);
    setSelectedRoomFeatures([]);
    setSelectedBusinessFeatures([]);
    setSelectedRecreationalFeatures([]);
    setSelectedArea([]);
    setSearchValue("");
    setFilters({
      search: "",
      city: "",
      area: [],
      numberOfRooms: "",
      eventType: [],
      hotelType: [],
      builtYear: [],
      renovatedYear: [],
      hotelChain: "",
      basePrice: "",
      ratings: [],
      roomFeatures: [],
      businessFeatures: [],
      recreationalFeatures: [],
    });
  };

  return (
    <div className=" w-full   flex flex-col  border-grayNeutral ">
      <div className="md:flex hidden items-center justify-between w-full py-4">
        <h1 className="text-dark lg:text-2xl sm:text-xl font-semibold text-lg">
          Filter by {countValidKeyValuePairs(filters)}
        </h1>
        <p
          className="text-primary underline font-medium cursor-pointer"
          onClick={resetAll}
        >
          Reset All
        </p>
      </div>

      {
        <div className="flex items-end justify-between sm:gap-5 md:mt-0 mt-4 gap-3">
          <div className="flex-1">
            <p className="mb-2">Search</p>
            <div className="border border-grayNeutral flex items-center gap-2 justify-between px-3 py-2 rounded-full">
              <input
                type="text"
                placeholder="Search"
                className="bg-transparent outline-none w-[90%]"
                name=""
                id=""
                value={filters.search || searchValue}
                onChange={handleSearchChange}
              />
              <LuSearch className="flex-shrink-0" />
            </div>
          </div>
          <div
            onClick={handleShowFilters}
            className="border md:hidden block h-full mb-1 rounded-md p-3"
          >
            <Filter />
          </div>
        </div>
      }

      <div className="mt-4 md:block hidden">
        <p className="text-dark mb-2">Location (Cities)</p>
        <div className="relative cursor-pointer">
          <select
            value={city}
            onChange={handleCityChange}
            className="w-full text-grayNeutral py-2 px-3 cursor-pointer appearance-none border outline-none border-bordered rounded-md"
          >
            <option value="" disabled>
              Select City
            </option>
            {cities.map((city) => (
              <option key={city.value} value={city.value}>
                {city.label}
              </option>
            ))}
          </select>
          <IoMdArrowDropdown className="absolute text-dark text-xl right-2 top-1/2 -translate-y-1/2" />
        </div>
      </div>

      <div className="md:block hidden">
        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("area")}>Area</span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedArea([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    area: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={
            city
              ? statesAndCities[city]
              : [{ name: "No city selected yet!", value: "" }]
          }
          selectedOptions={selectedArea} // Pass selected options
          setSelectedOptions={handleAreaChange}
          handleOpenPopup={() => handleOpenPopup("area")}
          openPopup={openPopup.area}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("numberOfRooms")}>
                No. of Rooms
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setNumberOfRooms("");
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    numberOfRooms: "",
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          content={
            <CustomInput
              placeholder={"Enter your input"}
              type="number"
              value={numberOfRooms}
              onChange={handleRoomChange}
              name="numberOfRooms"
            />
          }
          handleOpenPopup={() => handleOpenPopup("numberOfRooms")}
          openPopup={openPopup.numberOfRooms}
        />
        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("basePrice")}>
                Price Per Person
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setBasePrice("");
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    basePrice: "",
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          content={
            <CustomInput
              placeholder={"Enter base price"}
              type="number"
              value={basePrice}
              onChange={handleBasePriceChange}
              name="basePrice"
              error={basePrice === "" ? "Please enter the base price" : ""}
            />
          }
          handleOpenPopup={() => handleOpenPopup("basePrice")}
          openPopup={openPopup.basePrice}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("eventType")}>
                Event Type
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedEventType([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    eventType: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={eventType}
          selectedOptions={selectedEventType} // Pass selected options
          setSelectedOptions={handleSelectedEventType}
          handleOpenPopup={() => handleOpenPopup("eventType")}
          openPopup={openPopup.eventType}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("hotelType")}>
                Hotel Type
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedHotelType([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    hotelType: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={hotelType}
          selectedOptions={filters.hotelType || selectedHotelType}
          setSelectedOptions={handleSelectedHotelType}
          handleOpenPopup={() => handleOpenPopup("hotelType")}
          openPopup={openPopup.hotelType}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("hotelChain")}>
                Hotel Chain
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setHotelChain("");
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    hotelChain: "",
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={chains}
          selectedOptions={hotelChain} // Pass selected options
          setSelectedOptions={handlehotelChain}
          handleOpenPopup={() => handleOpenPopup("hotelChain")}
          openPopup={openPopup.hotelChain}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("builtYear")}>
                Built Year
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedBuiltYear([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    builtYear: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={years1}
          selectedOptions={selectedBuiltYear}
          setSelectedOptions={handleSelectedBuiltYear}
          handleOpenPopup={() => handleOpenPopup("builtYear")}
          openPopup={openPopup.builtYear}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("renovatedYear")}>
                Renovated Year
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedRenovatedYear([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    renovatedYear: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={years2}
          selectedOptions={selectedRenovatedYear}
          setSelectedOptions={handleSelectedRenovatedYear}
          handleOpenPopup={() => handleOpenPopup("renovatedYear")}
          openPopup={openPopup.renovatedYear}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("businessFeatures")}>
                Business Features
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedBusinessFeatures([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    businessFeatures: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={businessFeatures}
          selectedOptions={selectedBusinessFeatures} // Pass selected options
          setSelectedOptions={handleSelectedBusinessFeatures}
          handleOpenPopup={() => handleOpenPopup("businessFeatures")}
          openPopup={openPopup.businessFeatures}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("recreationalFeatures")}>
                Recreational Features
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedRecreationalFeatures([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    recreationalFeatures: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={recreationalFeatures}
          selectedOptions={selectedRecreationalFeatures} // Pass selected options
          setSelectedOptions={handleSelectedRecreationalFeatures}
          handleOpenPopup={() => handleOpenPopup("recreationalFeatures")}
          openPopup={openPopup.recreationalFeatures}
        />

        <CustomListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("roomFeatures")}>
                Room Features
              </span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedRoomFeatures([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    roomFeatures: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={roomFeatures}
          selectedOptions={selectedRoomFeatures} // Pass selected options
          setSelectedOptions={handleSelectedRoomFeatures}
          handleOpenPopup={() => handleOpenPopup("roomFeatures")}
          openPopup={openPopup.roomFeatures}
        />

        <RatingListingSelector
          label={
            <p>
              <span onClick={() => handleOpenPopup("ratings")}>Ratings</span>{" "}
              <span
                className="underline text-[#FE4747]"
                onClick={() => {
                  setSelectedRatings([]);
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    ratings: [],
                  }));
                }}
              >
                Reset
              </span>
            </p>
          }
          selectOptions={ratingFilters}
          selectedOptions={selectedRatings} // Pass selected options
          setSelectedOptions={handleRatingChange}
          handleOpenPopup={() => handleOpenPopup("ratings")}
          openPopup={openPopup.ratings}
        />
      </div>

      {/* filters in mobile view */}
      {showFilter && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="bg-white h-full w-full px-4 pt-6 overflow-y-auto">
            <div className="relative">
              <div className="flex items-center justify-between w-full py-4">
                <h1 className="text-dark lg:text-2xl sm:text-xl font-semibold text-lg">
                  Filter by {Object.keys(filters).length || "0"}
                </h1>
                <div className="" onClick={handleCloseFilters}>
                  <RxCross1 className="text-xl" />
                </div>
              </div>

              <div className="flex-1">
                <p className="mb-2">Search</p>
                <div className="border border-grayNeutral flex items-center gap-2 justify-between px-3 py-2 rounded-full">
                  <input
                    type="text"
                    placeholder="Search"
                    className="bg-transparent outline-none w-[90%]"
                    name=""
                    id=""
                    value={filters.search || searchValue}
                    onChange={handleSearchChange}
                  />
                  <LuSearch className="flex-shrink-0" />
                </div>
              </div>

              <div className="mt-4">
                <p className="text-dark mb-2">Location (Cities)</p>
                <div className="relative cursor-pointer">
                  <select
                    value={select}
                    onChange={handleSelectChange}
                    className="w-full text-grayNeutral py-2 px-3 cursor-pointer appearance-none  border outline-none border-bordered rounded-md"
                  >
                    <option value="">Dubai</option>
                    <option value="individual">Abu Dubai</option>
                    <option value="eventPlanner">Sharja</option>
                    <option value="corporate">Riaz</option>
                  </select>
                  <IoMdArrowDropdown className="absolute text-dark text-xl right-2 top-1/2 -translate-y-1/2" />
                </div>
              </div>

              <div className="pb-4">
                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("area")}>Area</span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedArea([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            area: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={
                    city
                      ? statesAndCities[city]
                      : [{ name: "No city selected yet!", value: "" }]
                  }
                  selectedOptions={selectedArea} // Pass selected options
                  setSelectedOptions={handleAreaChange}
                  handleOpenPopup={() => handleOpenPopup("area")}
                  openPopup={openPopup.area}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("numberOfRooms")}>
                        No. of Rooms
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setNumberOfRooms("");
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            numberOfRooms: "",
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  content={
                    <CustomInput
                      placeholder={"Enter your input"}
                      type="number"
                      value={numberOfRooms}
                      onChange={handleRoomChange}
                      name="numberOfRooms"
                    />
                  }
                  handleOpenPopup={() => handleOpenPopup("numberOfRooms")}
                  openPopup={openPopup.numberOfRooms}
                />
                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("basePrice")}>
                        Price Per Person
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setBasePrice("");
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            basePrice: "",
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  content={
                    <CustomInput
                      placeholder={"Enter base price"}
                      type="number"
                      value={basePrice}
                      onChange={handleBasePriceChange}
                      name="basePrice"
                      error={
                        basePrice === "" ? "Please enter the base price" : ""
                      }
                    />
                  }
                  handleOpenPopup={() => handleOpenPopup("basePrice")}
                  openPopup={openPopup.basePrice}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("eventType")}>
                        Event Type
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedEventType([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            eventType: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={eventType}
                  selectedOptions={selectedEventType} // Pass selected options
                  setSelectedOptions={handleSelectedEventType}
                  handleOpenPopup={() => handleOpenPopup("eventType")}
                  openPopup={openPopup.eventType}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("hotelType")}>
                        Hotel Type
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedHotelType([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            hotelType: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={hotelType}
                  selectedOptions={filters.hotelType || selectedHotelType}
                  setSelectedOptions={handleSelectedHotelType}
                  handleOpenPopup={() => handleOpenPopup("hotelType")}
                  openPopup={openPopup.hotelType}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("hotelChain")}>
                        Hotel Chain
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setHotelChain("");
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            hotelChain: "",
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={chains}
                  selectedOptions={hotelChain} // Pass selected options
                  setSelectedOptions={handlehotelChain}
                  handleOpenPopup={() => handleOpenPopup("hotelChain")}
                  openPopup={openPopup.hotelChain}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("builtYear")}>
                        Built Year
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedBuiltYear([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            builtYear: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={years1}
                  selectedOptions={selectedBuiltYear}
                  setSelectedOptions={handleSelectedBuiltYear}
                  handleOpenPopup={() => handleOpenPopup("builtYear")}
                  openPopup={openPopup.builtYear}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("renovatedYear")}>
                        Renovated Year
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedRenovatedYear([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            renovatedYear: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={years2}
                  selectedOptions={selectedRenovatedYear}
                  setSelectedOptions={handleSelectedRenovatedYear}
                  handleOpenPopup={() => handleOpenPopup("renovatedYear")}
                  openPopup={openPopup.renovatedYear}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("businessFeatures")}>
                        Business Features
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedBusinessFeatures([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            businessFeatures: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={businessFeatures}
                  selectedOptions={selectedBusinessFeatures} // Pass selected options
                  setSelectedOptions={handleSelectedBusinessFeatures}
                  handleOpenPopup={() => handleOpenPopup("businessFeatures")}
                  openPopup={openPopup.businessFeatures}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span
                        onClick={() => handleOpenPopup("recreationalFeatures")}
                      >
                        Recreational Features
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedRecreationalFeatures([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            recreationalFeatures: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={recreationalFeatures}
                  selectedOptions={selectedRecreationalFeatures} // Pass selected options
                  setSelectedOptions={handleSelectedRecreationalFeatures}
                  handleOpenPopup={() =>
                    handleOpenPopup("recreationalFeatures")
                  }
                  openPopup={openPopup.recreationalFeatures}
                />

                <CustomListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("roomFeatures")}>
                        Room Features
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedRoomFeatures([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            roomFeatures: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={roomFeatures}
                  selectedOptions={selectedRoomFeatures} // Pass selected options
                  setSelectedOptions={handleSelectedRoomFeatures}
                  handleOpenPopup={() => handleOpenPopup("roomFeatures")}
                  openPopup={openPopup.roomFeatures}
                />

                <RatingListingSelector
                  label={
                    <p>
                      <span onClick={() => handleOpenPopup("ratings")}>
                        Ratings
                      </span>{" "}
                      <span
                        className="underline text-[#FE4747]"
                        onClick={() => {
                          setSelectedRatings([]);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            ratings: [],
                          }));
                        }}
                      >
                        Reset
                      </span>
                    </p>
                  }
                  selectOptions={ratingFilters}
                  selectedOptions={selectedRatings} // Pass selected options
                  setSelectedOptions={handleRatingChange}
                  handleOpenPopup={() => handleOpenPopup("ratings")}
                />
              </div>

              <div className="flex sticky bottom-0 shadow-lg items-center gap-4 bg-white py-1.5 text-sm w-full">
                <button
                  className="bg-white py-3 flex justify-center items-center underline w-full"
                  onClick={() => {
                    resetAll();
                    handleCloseFilters();
                  }}
                >
                  Reset all
                </button>
                <button
                  className="bg-primary py-3 rounded-md flex justify-center text-white items-center  w-full"
                  onClick={() => {
                    handleCloseFilters();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenueStayFilter;
