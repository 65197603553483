import { useQuery} from "@tanstack/react-query";
import * as api from "../api/venue.request";

export const useFetchVendorVenues = (vendorId) => {
  return useQuery({
    queryKey: ["vendor-venues"],
    queryFn: async () => await api.fetchVendorVenues(vendorId),
    enabled: !!vendorId,
    refetchOnWindowFocus: false,
  });
};
