import React from "react";
import {
  OverviewIcon1,
  OverviewIcon2,
  OverviewIcon3,
  OverviewIcon5,
} from "../../svgs";

const OverviewSection = ({ data, numberOfVenues }) => {
  const overviewDetails = [
    {
      icon: <OverviewIcon1 />,
      label: "Starts From",
      value: `${data?.pricing?.basePrice || "Price Missing"} AED per Night`,
    },
    {
      icon: <OverviewIcon2 />,
      label: "Rooms",
      value: data?.accommodation?.accommodations || "0",
    },
    {
      icon: <OverviewIcon3 />,
      label: "Venues",
      value: numberOfVenues || "0",
    },
    {
      icon: <OverviewIcon5 />,
      label: "Hotel Chain",
      value: data?.overview?.basicDetails?.hotelChain,
    },
  ];

  return (
    <>
      <div className=" w-full mx-auto">
        <h3 className="text-2xl font-bold py-4">Overview</h3>

        <div className="grid lg:grid-cols-4 grid-cols-2 md:gap-4 gap-6 mb-4">
          {overviewDetails.map((detail, index) => (
            <div
              key={index}
              className="min-h-[150px] md:w-[85%] w-[163px] flex flex-col gap-2 gap-y-3 text-center text-black items-center bg-[#F7F7F7] p-4 rounded-lg shadow-md"
            >
              <div>{detail.icon}</div>
              <div className="text-[16px] lg:text-[18px] mt-2">
                {detail.label !== "Starts From" ? (
                  <>
                    <p>{detail.value}</p>
                    <p>{detail.label}</p>
                  </>
                ) : (
                  <>
                    <p>{detail.label}</p>
                    <p>{detail.value}</p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="h-[1px] bg-[#EBEBEB] w-full my-10"></div>

        <h3 className="text-2xl font-bold mb-4">Highlights</h3>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:p-6 p-4 w-full rounded-xl bg-[#FFEBEB]">
          <div className="col-span-3 md:border-r border-gray-300 pr-4 pl-4 md:pl-0">
            <h3 className="text-xl font-medium mb-4">Hotel Type</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              {data?.overview?.basicDetails?.selectedTags?.types?.map(
                (item, index) => (
                  <div key={index} className="flex items-center">
                    <span
                      className={`${
                        item.disabled
                          ? "text-gray-400 line-through text-[16px]"
                          : "text-black text-[16px]"
                      }`}
                    >
                      {item}
                    </span>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="col-span-1 pl-4">
            <div className="flex flex-col gap-3 items-start">
              <h3 className="text-xl font-medium">Build Year</h3>
              <span className="text-black">
                {data?.overview?.additionalDetails?.hotelBuiltYear
                  ? new Date(
                      data?.overview?.additionalDetails?.hotelBuiltYear
                    ).getFullYear()
                  : ""}
              </span>

              <h3 className="text-xl font-medium">Renovated Year</h3>
              <span className="text-black">
                {data?.overview?.additionalDetails?.lastRenovatedYear
                  ? new Date(
                      data?.overview?.additionalDetails?.lastRenovatedYear
                    ).getFullYear()
                  : ""}
              </span>
            </div>
          </div>
        </div>

        <div className="md:flex justify-between mt-5 p-2 rounded-lg bg-[#FFEBEB]">
          <div className="p-4 rounded-lg w-1/2 mr-2">
            <h4 className="md:text-[16px] text-[#222222] text-[14px] font-medium mb-2">
              View
            </h4>
            <p className="text-[#222222] text-sm">
              {data?.overview?.basicDetails?.selectedTags?.views?.join(", ") ||
                "Views Missing"}
            </p>
          </div>
          <div className="p-4 md:border-l border-gray-300 md:pl-5 w-1/2 md:ml-2">
            <h4 className="md:text-[16px] text-[#222222] text-[14px] font-medium mb-2">
              Style
            </h4>
            <p className="text-[#222222] text-sm">
              {data?.overview?.basicDetails?.selectedTags?.styles
                ? Object.entries(
                    data?.overview?.basicDetails?.selectedTags?.styles || {}
                  )
                    .flatMap(([key, values]) => values)
                    .join(", ")
                : "Styles Missing"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewSection;
