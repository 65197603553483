/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import CustomCheckbox from "../../shared/common/checkBox";
import ToggleSwitch from "../../shared/common/custom-toggle";
import MapContainer from "../../hostel_listing_form/overview-form/googleMap";
import p1 from "../../../assets/parking.svg";
import p2 from "../../../assets/Valet.svg";
import { VenueContext } from "../../../context/VenueContext";
import UAEStateCitySelector from "../../hostel_listing_form/overview-form/state-city"; // Import this component if needed
import extractMapCoordinates from "../../../utils/extractMapCoordinates";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { base_URL } from "../../../config/config";
import { getOnboarding } from "../../../api/signup.request";
import { useLocation } from "react-router-dom";
import { useUser } from "../../../context/user";
const defaultLocation = {
  lat: 25.276987,
  lng: 55.296249,
};

const LocationVenueForm = () => {
  const queryClient = useQueryClient();
  const { editItem } = useUser();
  // refetch map whenever link changes
  const [refetchMap, setRefetchMap] = useState("");
  const user = queryClient.getQueryData(["loggedin-user"]);
  const vendorId = localStorage?.getItem("vendorId");
  const [countries, setCountries] = useState([]);
  const [mapLocation, setMapLocation] = useState(defaultLocation);
  const [mapLinkError, setMapLinkError] = useState("");
  const { formData, setFormData } = useContext(VenueContext);
  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    area: "",
    poBox: "",
    country: "United Arab Emirates",
    mapLink: "",
    lat: null,
    lng: null,
    accessibilityFeatures: {},
    parkingAvailable: {
      parkingAvailable: false,
      valetParking: false,
    },
  });

  const {
    data: boardingData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => {
      return await getOnboarding(vendorId);
    },
    refetchOnWindowFocus: false,
  });

  const path = useLocation();
  useEffect(() => {
    // Fetch country data
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryNames = data
          .map((country) => ({
            value: country.name.common,
            label: country.name.common,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountries(countryNames);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });

    // Initialize location details from VenueContext
    if (formData?.location) {
      let updatedLocation = { ...formData.location };

      // checking below for child card, if child card dont have location information assign from parent(edititem) so that we can continue
      if (editItem) {
        updatedLocation = {
          addressLine1:
            formData?.location?.addressLine1 ||
            editItem?.location?.addressLine1 ||
            "",
          addressLine2:
            formData?.location?.addressLine2 ||
            editItem?.location?.addressLine2 ||
            "",
          area: formData?.location?.area || editItem?.location?.area || "",
          city: formData?.location?.city || editItem?.location?.city || "",
          poBox: formData?.location?.poBox || editItem?.location?.poBox || "",
          mapLink:
            formData?.location?.mapLink || editItem?.location?.mapLink || "",
        };
      }
      setLocationDetails(updatedLocation);
    }
  }, [formData.location, editItem]);
  useEffect(() => {
    if (
      boardingData &&
      boardingData?.type === "HotelStayandVenue" &&
      path.pathname?.includes("venu-Listing-Form")
    ) {
      setLocationDetails({
        ...locationDetails,
        addressLine1:
          boardingData?.hotels[0]?.overview?.locationDetails?.addressLine1,
        addressLine2:
          boardingData?.hotels[0]?.overview?.locationDetails?.addressLine2,
        city: boardingData?.hotels[0]?.overview?.locationDetails?.city,
        area: boardingData?.hotels[0]?.overview?.locationDetails?.area,
        poBox: boardingData?.hotels[0]?.overview?.locationDetails?.poBox,
        country: boardingData?.hotels[0]?.overview?.locationDetails?.country,
        mapLink: boardingData?.hotels[0]?.overview?.locationDetails?.mapLink,
        lat: boardingData?.hotels[0]?.overview?.locationDetails?.lat,
        lng: boardingData?.hotels[0]?.overview?.locationDetails?.lng,
      });
    }
    if (!isLoading) {
      // Update map location whenever location details change
      updateMapLocation();
    }
  }, [isLoading, boardingData]);
  useEffect(() => {
    const fetchAndSetLocationDetails = async () => {
      try {
        if (!isLoading) {
          // Update map location whenever location details change
          updateMapLocation();
        }

        const coords = await fetchCoordinatesFromShortLink(
          locationDetails?.mapLink
        );
        if (coords) {
          setMapLocation(coords);
          setMapLinkError(""); // Reset error message

          const updatedLocation = {
            ...locationDetails,
            lat: coords?.lat,
            lng: coords?.lng,
            mapLink: locationDetails?.mapLink,
          };

          setLocationDetails(updatedLocation);
          setFormData((prevData) => ({
            ...prevData,
            location: updatedLocation,
          }));
        } else {
          console.log("Invalid map link");
        }
      } catch (error) {
        console.error("Error in fetching and setting location details:", error);
      }
    };

    fetchAndSetLocationDetails();
  }, [isLoading, boardingData, locationDetails?.mapLink]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const updatedLocation = {
      ...locationDetails,
      [name]: value,
    };

    setLocationDetails(updatedLocation);
    setFormData((prevData) => ({
      ...prevData,
      location: updatedLocation,
    }));
  };
  console.log(countries, mapLinkError, error,);

  const handleInputChangeforpobox = (e) => {
    const { name, value } = e.target;

    // Ensure the value is numeric
    const numericValue = value.replace(/\D/g, "");

    const updatedLocation = {
      ...locationDetails,
      [name]: numericValue,
    };

    setLocationDetails(updatedLocation);
    setFormData((prevData) => ({
      ...prevData,
      location: updatedLocation,
    }));
  };
  const handleFeatureChange = (feature) => {
    const updatedFeatures = {
      ...locationDetails.accessibilityFeatures,
      [feature]: !locationDetails.accessibilityFeatures[feature],
    };
    const updatedLocation = {
      ...locationDetails,
      accessibilityFeatures: updatedFeatures,
    };
    setLocationDetails(updatedLocation);
    setFormData((prevData) => ({
      ...prevData,
      location: updatedLocation,
    }));
  };

  const handleCheckboxChange = (field) => {
    const updatedParking = {
      ...locationDetails?.parkingAvailable,
      [field]: !locationDetails?.parkingAvailable[field],
    };
    const updatedLocation = {
      ...locationDetails,
      parkingAvailable: updatedParking,
    };
    setLocationDetails(updatedLocation);
    setFormData((prevData) => ({
      ...prevData,
      location: updatedLocation,
    }));
  };
  const handleLocationChange = (lat, lng) => {
    setMapLocation({ lat, lng });
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: {
        ...prevFormData.location,
        lat,
        lng,
      },
    }));
  };
  const updateMapLocation = async () => {
    const { addressLine1, city, area, mapLink, lat, lng } = locationDetails;
    if (lat && lng) {
      setMapLocation({ lat, lng });
      return;
    }

    if (addressLine1 && city && area && (!mapLink || mapLink === "")) {
      const address = `${addressLine1}, ${city}, ${area}`;
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key="AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg`
        );
        const results = response.data.results[0];

        if (results) {
          const { lat, lng } = results.geometry.location;
          setMapLocation({ lat, lng });
        } else {
          setMapLocation(defaultLocation);
        }
      } catch (error) {
        console.error("Error fetching location details:", error);
        setMapLocation(defaultLocation);
      }
    } else if (mapLink) {
      const coords = extractMapCoordinates(mapLink);
      if (coords) {
        setMapLocation(coords);
      } else {
        console.log("Invalid map link");
        setMapLinkError("Invalid Google Maps link");
        setMapLocation(defaultLocation);
      }
    } else {
      setMapLocation(defaultLocation);
    }
  };
  const fetchCoordinatesFromShortLink = async (shortLink) => {
    try {
      // Step 1: Check if the input is a short link (like "https://maps.app.goo.gl/...")
      if (shortLink.includes("https://maps.app.goo.gl/")) {
        // Step 2: Expand the short URL using your API
        const response = await axios.post(`${base_URL}/api/expand-link`, {
          shortLink,
        });
        const expandedUrl = response?.data?.expandedUrl;
        if (expandedUrl) {
          // Step 3: Extract the coordinates from the expanded URL
          return extractMapCoordinates(expandedUrl);
        } else {
          console.error("Failed to expand the URL.");
          return null;
        }
      } else {
        // If the link is already expanded, directly extract coordinates
        return extractMapCoordinates(shortLink);
      }
    } catch (err) {
      console.log(err.response?.data?.error || "Something went wrong");
      return null;
    }
  };
  const handleMapLinkChange = async (e) => {
    const mapLink = e.target.value;
    setRefetchMap(mapLink);
    // Step 4: Fetch coordinates (expanding short link if needed)
    const googleMapsFullLinkPattern =
      /^https?:\/\/(www\.)?google\.(com|[a-z]{2,3})\/maps\/.+$/;
    const googleMapsShortLinkPattern = /^https?:\/\/maps\.app\.goo\.gl\/.+$/;

    if (
      !googleMapsFullLinkPattern.test(mapLink) &&
      !googleMapsShortLinkPattern.test(mapLink)
    ) {
      setMapLinkError("Please enter a valid Google Maps link.");
      return; // Stop further execution if not a valid Google Maps link
    }

    const coords = await fetchCoordinatesFromShortLink(mapLink);
    if (coords) {
      setMapLocation(coords);
      setMapLinkError(""); // Reset error message
      const updatedLocation = {
        ...locationDetails,
        lat: coords?.lat,
        lng: coords?.lng,
        mapLink,
      };
      setLocationDetails(updatedLocation);
      setFormData((prevData) => ({
        ...prevData,
        location: updatedLocation,
      }));
    } else {
      console.log("Invalid map link");
    }
  };

  const handleStateChange = (selectedState) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: {
        ...prevFormData.location,
        area: selectedState,
        city: "", // Reset city when state changes
      },
    }));
  };

  const handleCityChange = (selectedCity) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: {
        ...prevFormData.location,
        city: selectedCity,
      },
    }));
  };
  // refetch map whenever link changes
  useEffect(() => {
    if (refetchMap) {
      const coords = extractMapCoordinates(refetchMap);
      if (coords) {
        setMapLocation(coords);
      } else {
        setMapLocation(defaultLocation);
      }
    }
  }, [refetchMap]);
  console.log(editItem, "ddds");
  return (
    <div className="p-6 bg-white rounded-lg">
      <h2 className="text-2xl lg:text-3xl text-neutralBlack font-semibold mb-4">
        Location
      </h2>

      <form className="w-full flex flex-wrap gap-4 justify-between mx-auto">
        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary font-noraml mb-2">
            Address Line 1
          </label>
          <input
            type="text"
            name="addressLine1"
            // value={locationDetails.addressLine1}
            value={
              editItem?.location?.addressLine1 ||
              locationDetails.addressLine1 ||
              ""
            }
            onChange={handleInputChange}
            placeholder="House number and street name"
            className="w-full py-2 px-3 border border- rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            disabled={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
            readOnly={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
          />
          {formData?.location?.errors?.addressLine1 ? (
            <p className="text-red-500 text-base pt-1">
              {formData.location.errors.addressLine1}
            </p>
          ) : null}
        </div>
        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary  mb-2">Address Line 2</label>
          <input
            type="text"
            name="addressLine2"
            // value={locationDetails.addressLine2 || ""}
            value={
              editItem?.location?.addressLine2 ||
              locationDetails.addressLine2 ||
              ""
            }
            onChange={handleInputChange}
            placeholder="Apartment number, suite number"
            className="w-full py-2 px-3 border border- rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            disabled={
              // user?.onBoardingDetails?.type === "HotelStayandVenue" &&
              path.pathname?.includes("venu-Listing-Form") ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
            readOnly={
              // user?.onBoardingDetails?.type === "HotelStayandVenue" &&
              path.pathname?.includes("venu-Listing-Form") ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
          />
        </div>
        {(path.pathname?.includes("venu-restaurant-Form/Restaurant") &&
          editItem !== null) ||
        (path.pathname?.includes("create-Listing/Venue") &&
          editItem !== null) ? (
          <>
            {/* allow to select */}
            <UAEStateCitySelector
              selectedState={
                editItem?.location?.area || locationDetails.area || ""
              }
              selectedCity={
                editItem?.location?.city || locationDetails.city || ""
              }
              onStateChange={handleStateChange}
              onCityChange={handleCityChange}
              errors={
                (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                  path.pathname?.includes("venu-Listing-Form")) ||
                (path.pathname?.includes("/create-Listing/Venue") &&
                  !formData?.basicDetails?.isPrimaryVenue)
                  ? null
                  : formData?.location?.errors
              }
              disabled={
                (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                  path.pathname?.includes("venu-Listing-Form")) ||
                (path.pathname?.includes("/create-Listing/Venue") &&
                  !formData?.basicDetails?.isPrimaryVenue) ||
                ((path.pathname?.includes("venu-restaurant-Form") ||
                  path.pathname?.includes("create-Listing/Restaurant")) &&
                  !formData?.basicDetails?.isPrimaryVenue)
              }
            />
          </>
        ) : (
          <>
            <UAEStateCitySelector
              selectedState={locationDetails.area}
              selectedCity={locationDetails.city}
              onStateChange={handleStateChange}
              onCityChange={handleCityChange}
              errors={
                (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                  path.pathname?.includes("venu-Listing-Form")) ||
                (path.pathname?.includes("/create-Listing/Venue") &&
                  !formData?.basicDetails?.isPrimaryVenue)
                  ? null
                  : formData?.location?.errors
              }
              disabled={
                (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                  path.pathname?.includes("venu-Listing-Form")) ||
                (path.pathname?.includes("/create-Listing/Venue") &&
                  !formData?.basicDetails?.isPrimaryVenue) ||
                ((path.pathname?.includes("venu-restaurant-Form") ||
                  path.pathname?.includes("create-Listing/Restaurant")) &&
                  !formData?.basicDetails?.isPrimaryVenue)
              }
            />
          </>
        )}
        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary font- mb-2">PO Box</label>
          <input
            type="text"
            name="poBox"
            // value={locationDetails.poBox || ""}
            value={editItem?.location?.poBox || locationDetails.poBox || ""}
            onChange={handleInputChangeforpobox}
            // onChange={(e) => {
            //   // Ensure only numeric input is accepted
            //   const value = e.target.value.replace(/\D/g, "");
            //   handleInputChange({ target: { name: e.target.name, value } });
            // }}
            onKeyDown={(e) => {
              // Prevent scientific notation and arrow keys
              if (["e", "E", "ArrowUp", "ArrowDown"].includes(e.key)) {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.preventDefault()}
            placeholder="Enter PO Box"
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            inputMode="numeric" // Hints the input should accept numeric values
            pattern="\d*" // Validates that input must be numeric
            step="0"
            // disabled={
            //   (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
            //     path.pathname?.includes("venu-Listing-Form")) ||
            //   (path.pathname?.includes("/create-Listing/Venue") &&
            //     !formData?.basicDetails?.isPrimaryVenue) ||
            //   ((path.pathname?.includes("venu-restaurant-Form") ||
            //     path.pathname?.includes("create-Listing/Restaurant")) &&
            //     !formData?.basicDetails?.isPrimaryVenue)
            //     ? true
            //     : false
            // }
            disabled={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
            }
            readOnly={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
          />
        </div>
        <div className="lg:w-[47%] w-full mb-4">
          <label className="block text-secondary font- mb-2">Country</label>
          <select
            name="country"
            value={locationDetails.country || "United Arab Emirates"}
            onChange={handleInputChange}
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            disabled={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
          >
            {/* {countries.map((country, index) => (
                            <option key={index} value={country.value}>{country.label}</option>
                        ))} */}
            <option value="United Arab Emirates">United Arab Emirates</option>
          </select>
        </div>
        <div className="w-full mb-4">
          <MapContainer
            isHotel={false}
            isVenue={true}
            lat={mapLocation.lat}
            lng={mapLocation.lng}
            mapLocation={mapLocation}
            setMapLocation={setMapLocation}
            onLocationChange={handleLocationChange}
          />
        </div>
        <div className="w-full mb-4">
          <label className="block text-secondary text-sm mb-2">
            Map Link <span className="text-red-500 ml-1 text-[15px]">*</span>
          </label>
          <input
            type="text"
            name="mapLink"
            value={editItem?.location?.mapLink || locationDetails.mapLink || ""}
            onChange={handleMapLinkChange}
            placeholder="Enter Google Map Link"
            className="w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            disabled={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
            readOnly={
              (user?.onBoardingDetails?.type === "HotelStayandVenue" &&
                path.pathname?.includes("venu-Listing-Form")) ||
              (path.pathname?.includes("/create-Listing/Venue") &&
                !formData?.basicDetails?.isPrimaryVenue) ||
              ((path.pathname?.includes("venu-restaurant-Form") ||
                path.pathname?.includes("create-Listing/Restaurant")) &&
                !formData?.basicDetails?.isPrimaryVenue)
                ? true
                : false
            }
          />
          {/* {user?.onBoardingDetails?.type !== "HotelStayandVenue" &&
            formData?.location?.errors?.mapLink &&
            path.pathname?.includes("venu-Listing-Form") ? (
            <p className="text-red-500 text-base pt-1">
              {formData.location.errors.mapLink}
            </p>
          ) : null}
          {user?.onBoardingDetails?.type !== "HotelStayandVenue" &&
            mapLinkError &&
            path.pathname?.includes("venu-Listing-Form") ? (
            <p className="text-red-500 text-base pt-1">{mapLinkError}</p>
          ) : null} */}

          <p className="text-mediumGray text-sm leading-[21.84px]">
            Please navigate to google, copy the link for your location & paste
            it.
          </p>
          {formData?.location?.errors?.mapLink ? (
            <p className="text-red-500 text-base pt-1">
              {formData.location.errors.mapLink}
            </p>
          ) : null}
        </div>
        <div className="w-full mb-4 ">
          <h3 className="text-lg font-semibold mb-2">Accessibility Features</h3>
          <div className="lg:w-[70%]  mb-4 flex justify-between lg:gap-10 gap-5 items-center ">
            <CustomCheckbox
              label="Airport Shuttle"
              checked={
                locationDetails.accessibilityFeatures?.airportShuttle || false
              }
              onChange={() => handleFeatureChange("airportShuttle")}
            />
            <CustomCheckbox
              label="Metro"
              checked={locationDetails.accessibilityFeatures?.metro || false}
              onChange={() => handleFeatureChange("metro")}
            />
            <CustomCheckbox
              label="Taxi"
              checked={locationDetails.accessibilityFeatures?.taxi || false}
              onChange={() => handleFeatureChange("taxi")}
            />
            <CustomCheckbox
              label="Bus"
              checked={locationDetails.accessibilityFeatures?.bus || false}
              onChange={() => handleFeatureChange("bus")}
            />
          </div>
        </div>
        <div className="w-full mb-4 space-y-5">
          <h3 className="text-xl font-semibold mb-2">Parking</h3>
          <div className="flex py-1 items-center justify-between lg:w-1/3 w-full mb-4">
            <div className="flex items-center gap-4 ">
              <img src={p1} alt="" className="" />
              <span className="text-base font-medium text-black">
                Parking Available
              </span>
            </div>
            <div className="flex items-center gap-2 relative">
              <ToggleSwitch
                isOn={
                  locationDetails?.parkingAvailable?.parkingAvailable || false
                }
                handleToggle={() => handleCheckboxChange("parkingAvailable")}
              />
            </div>
          </div>

          <div className="flex py-1 items-center justify-between lg:w-1/3 w-full mb-4">
            <div className="flex items-center gap-4 ">
              <img src={p2} alt="" className="" />
              <span className="text-base font-medium text-black">
                Valet Parking
              </span>
            </div>
            <div className="flex items-center gap-2 relative">
              <ToggleSwitch
                isOn={locationDetails?.parkingAvailable?.valetParking || false}
                handleToggle={() => handleCheckboxChange("valetParking")}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LocationVenueForm;
