import axios from 'axios';
import { base_URL } from '../config/config';

const API_BASE_URL = `${base_URL}/api`;

export const checkVerify = async (emailObj, password) => {
  try {
    const email = emailObj.email;
    let data = { email: email,password:true };

    let response;

    response = await axios.post(`${API_BASE_URL}/vendor/check/verify`, data);
    return response.data;
  } catch (error) {
    console.error('Error checking email verification:', error);
    throw error;
  }
};
