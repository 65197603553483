import React, { useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconButtonWithTooltip from "./IconButtonWithTooltip";
import HotelIcon from "../../../assets/icons/hotel.svg"; // Import as asset
import VenueIcon from "../../../assets/icons/venue.svg"; // Import as asset
import HotelReverseIcon from "../../../assets/icons/hotel-reverse.svg"; // Import as asset
import VenueReverseIcon from "../../../assets/icons/venue-reverse.svg"; // Import as asset

function TypeOfVenue({
  title,
  description,
  buttonLabel1,
  buttonLabel2,
  tooltip1,
  tooltip2,
  selectedButton,
  handleButton1Click,
  handleButton2Click,
}) {
  const theme = useTheme();

  // State to track hovered icons
  const [hoveredButton, setHoveredButton] = useState(null);

  // Handler for mouse enter
  const handleMouseEnter = (buttonIndex) => {
    setHoveredButton(buttonIndex);
  };

  // Handler for mouse leave
  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: { md: "center" },
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "600", fontSize: "32px" }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mt: 1,
            color: theme.palette.grey.text,
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          py: 3,
          borderRadius: 8,
          textAlign: "center",
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "1rem",
            width: "100%",
          }}
          labelColor={
            selectedButton === 1 ? theme.palette.primary.main : "#717171"
          }
        >
          <IconButtonWithTooltip
            label={buttonLabel1}
            icon={
              <img
                src={hoveredButton === 1 ? HotelReverseIcon : HotelIcon}
                alt="Hotel Icon"
                width={24}
                height={24}
                style={{
                  filter:
                    selectedButton === 1
                      ? `invert(37%) sepia(99%) saturate(7471%) hue-rotate(0deg) brightness(101%) contrast(101%)`
                      : "none",
                }}
              />
            }
            labelColor={
              selectedButton === 1 ? theme.palette.primary.main : "#717171"
            }
            tooltip={tooltip1}
            isSelectedButton={selectedButton === 1}
            handleButtonClick={handleButton1Click}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
          />
          <IconButtonWithTooltip
            label={buttonLabel2}
            icon={
              <img
                src={hoveredButton === 2 ? VenueReverseIcon : VenueIcon}
                alt="Venue Icon"
                width={24}
                height={24}
                style={{
                  filter:
                    selectedButton === 2
                      ? `invert(37%) sepia(99%) saturate(7471%) hue-rotate(0deg) brightness(101%) contrast(101%)`
                      : "none",
                }}
              />
            }
            tooltip={tooltip2}
            isSelectedButton={selectedButton === 2}
            handleButtonClick={handleButton2Click}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default TypeOfVenue;
