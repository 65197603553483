import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
const ExploreEvents = ({ eventTypeName, eventsData }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute sm:-right-10 sm:top-1/2 transform sm:-translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#FE4747] text-xl" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute sm:-left-10 sm:top-1/2 transform sm:-translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#FE4747] text-xl" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust for how many items to show
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    afterChange: (current) => setActiveIndex(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="px-8 my-20">
      <h1 className="font-semibold md:text-[40px] text-[24px] text-[#222222] text-center mb-9">
        Explore Venues by {eventTypeName}
      </h1>
      <div className="md:w-[95%] w-[98%] mx-auto">
        {eventsData && (
          <Slider {...settings}>
            {eventsData.map((item, index) => (
              <div
                key={index}
                className="flex justify-center items-center mx-4 max-w-[90%] min-h-[500px]"
              >
                <div className="w-full min-h-[500px] shadow-md rounded-lg flex justify-center items-center relative">
                  <div className="w-full h-full">
                    <img
                      src={item.img}
                      alt="bgImg"
                      className="w-full h-full bg-cover rounded-lg"
                    />
                  </div>
                  <div className="w-full h-[100px]  absolute bottom-0 rounded-lg left-0 flex justify-center items-center">
                    <div className="">
                      <h2 className="font-semibold text-2xl text-[#222222]">
                        {item.title}
                      </h2>
                      <p className="text-[#5E5E5E] text-center mt-3 text-[16px] font-medium">
                        {item.venues} Venues
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ExploreEvents;
