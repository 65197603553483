import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useUser } from "../../context/user";

const ListonVenue = ({
  data,
  onContinue,
  onClose,
  onSelectVenue,
  combineData,
}) => {
  const [selectedVenue, setSelectedVenue] = useState(null);

  const handleVenueChange = (venue) => {
    setSelectedVenue(venue);
  };
  const { editItem, setEditItem } = useUser();
  console.log(editItem);
  const handleContinue = () => {
    console.log(selectedVenue, "hope");
    setEditItem(selectedVenue);
    if (selectedVenue) {
      const filteredData = data.filter(
        (venue) =>
          venue.basicDetails?.venueName ===
          selectedVenue.basicDetails?.venueName
      );
      onSelectVenue(filteredData);
      onContinue();
    }
  };

  return (
    <div className="bg-white relative rounded-lg flex flex-col items-center lg:w-[40%] sm:w-[65%] w-[90%] p-4">
      <RxCross2
        onClick={onClose}
        className="absolute text-xl cursor-pointer top-8 right-10"
      />
      <h1 className="text-3xl mt-4 font-semibold w-[90%] text-center">
        List on venue
      </h1>
      <p className="text-offGray text-center w-[80%] mt-3">
        Use any of the options below to duplicate listing
      </p>

      <div className="mt-8 flex flex-col items-start gap-10 w-[94%]">
        {(combineData !== "hotel" && data && data.length > 0) ||
        (combineData === "hotel" && data && data.length > 1) ? (
          (combineData === "hotel" ? data.slice(1) : data).map((venue, i) => (
            <div key={i} className="flex items-center gap-2">
              <input
                type="radio"
                name="venueRadio"
                className="w-5 h-5"
                checked={
                  selectedVenue?.basicDetails?.venueName ===
                  venue?.basicDetails?.venueName
                }
                onChange={() => handleVenueChange(venue)}
              />
              <p className="font-medium">{venue?.basicDetails?.venueName}</p>
            </div>
          ))
        ) : (
          <p className="text-red-500 font-medium flex justify-center text-center w-full">
            No venues to autofill from
          </p>
        )}
      </div>

      <div className="flex justify-center flex-col items-center w-full mt-8">
        <button
          className="py-2 w-[85%] px-2 rounded-lg text-white text-sm bg-primary"
          onClick={handleContinue}
          disabled={!selectedVenue}
        >
          Continue
        </button>
        <button className="py-2 px-4" onClick={onClose}>
          Back
        </button>
      </div>
    </div>
  );
};

export default ListonVenue;
