import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import StatusDropdown from "../StatusDropdown/StatusDropdown";

const MobileEnquiryDetails = ({
  enquiryData,
  vednordetails,
  updateStatus,
  user,
}) => {
  const [isEnquiryDetailsOpen, setIsEnquiryDetailsOpen] = useState(false);
  const [isCustomerInfoOpen, setIsCustomerInfoOpen] = useState(false);
  const [isManagerInfoOpen, setIsManagerInfoOpen] = useState(false);

  return (
    <div className="w-full bg-white p-4 lg:hidden rounded-xl">
      {/* Enquiry Details Section */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-[#212B36]">
            Enquiry Details
          </h3>
          <div className="flex gap-x-3 items-center">
            {isEnquiryDetailsOpen && (
              <StatusDropdown
                enquiry={enquiryData}
                updateStatus={updateStatus}
                user={user}
              />
            )}

            <BsChevronDown
              onClick={() => setIsEnquiryDetailsOpen(!isEnquiryDetailsOpen)}
              className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${
                isEnquiryDetailsOpen ? "rotate-180" : ""
              }`}
            />
          </div>
        </div>

        <div
          className={`space-y-3 overflow-hidden transition-all duration-200 ${
            isEnquiryDetailsOpen
              ? "max-h-full opacity-100"
              : "max-h-0 opacity-0"
          }`}
        >
          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">Event Start Date</span>
            <span className="text-sm text-[#222222]">
              {enquiryData?.startDate}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">Event End Date</span>
            <span className="text-sm text-[#222222]">
              {enquiryData?.endDate}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">Flexible</span>
            <span className="text-sm text-[#222222]">
              {enquiryData?.flexibility === true ? "Yes" : "No"}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">Event Type</span>
            <span className="text-sm text-[#222222]">
              {enquiryData?.eventType}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">Event Budget</span>
            <span className="text-sm text-[#222222]">
              AED {enquiryData?.budget || "--"}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">Number of Guest</span>
            <span className="text-sm text-[#222222]">
              {enquiryData?.guests}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">Number of Rooms</span>
            <span className="text-sm text-[#222222]">
              {enquiryData?.rooms || "--"}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-[#717171] text-sm">
              Number of Venue Required
            </span>
            <span className="text-sm text-[#222222]">
              {enquiryData?.numberOfVenues || "--"}
            </span>
          </div>
        </div>
      </div>

      {/* Customer Info Section */}
      <div className="mb-6">
        <button
          onClick={() => setIsCustomerInfoOpen(!isCustomerInfoOpen)}
          className="w-full flex justify-between items-center mb-4"
        >
          <h4 className="text-lg font-medium text-[#212B36]">Customer Info</h4>
          <BsChevronDown
            className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${
              isCustomerInfoOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        <div
          className={`space-y-3 overflow-hidden transition-all duration-200 ${
            isCustomerInfoOpen ? "max-h-48 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div className="flex items-center gap-2">
            <img src="/person.svg" alt="" className="w-5 h-5" />
            <span className="text-sm">{enquiryData?.customerId?.fullName}</span>
          </div>

          <div className="flex items-center gap-2">
            <img src="/mail.svg" alt="" className="w-5 h-5" />
            <span className="text-sm">{enquiryData?.customerId?.email}</span>
          </div>

          <div className="flex items-center gap-2">
            <img src="/call.svg" alt="" className="w-5 h-5" />
            <span className="text-sm">
              {enquiryData?.customerId?.mobileNumber}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <img src="/business_center.svg" alt="" className="w-5 h-5" />
            <span className="text-sm">{enquiryData?.customerId?.select}</span>
          </div>
        </div>
      </div>

      {/* Manager Info Section */}
      <div>
        <button
          onClick={() => setIsManagerInfoOpen(!isManagerInfoOpen)}
          className="w-full flex justify-between items-center mb-4"
        >
          <h4 className="text-lg font-medium text-[#212B36]">Manager Info</h4>
          <BsChevronDown
            className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${
              isManagerInfoOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        <div
          className={`space-y-3 overflow-hidden transition-all duration-200 ${
            isManagerInfoOpen ? "max-h-12 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div className="flex items-center gap-2">
            <img src="/person.svg" alt="" className="w-5 h-5" />
            <span className="text-sm">{vednordetails?.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileEnquiryDetails;
