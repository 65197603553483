import React, { useState } from "react";
import defaultImg from "../../assets/pics/whyImg.png";
import img1 from "../../assets/whyus/onestop.svg";
import img2 from "../../assets/pics/whyImg.png";
import img3 from "../../assets/whyus/free.svg";
import img4 from "../../assets/whyus/fewclick.svg";
import img5 from "../../assets/whyus/seamless.svg";
import img6 from "../../assets/whyus/filter.svg";

const WhyUs = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const data = [
    {
      head: "One-Stop Solution",
      des: "Discover a variety of venues for any event, all in one convenient platform",
      img: img1,
    },
    {
      head: "Discover Hidden Gems",
      des: "Explore unique and unconventional spaces",
      img: img2,
    },
    {
      head: "Absolutely Free",
      des: "No booking fees or commissions for venue seekers",
      img: img3,
    },
    {
      head: "Accessible Anywhere",
      des: "No matter where you are, effortlessly search and connect with venues, in just a few clicks",
      img: img4,
    },
    {
      head: "User-Friendly",
      des: "Easy-to-use platform for quick search, shortlist, and enquiry",
      img: img5,
    },
    {
      head: "Advanced Filtering Tech",
      des: "Use smart filters to find venues that perfectly match your needs",
      img: img6,
    },
  ];

  return (
    <div className="flex justify-center pt-20 sm:px-20 px-4">
      <div className="md:w-[90%] flex flex-col">
        {/* right image on top for mobile */}
        <div className=" flex  justify-center items-center">
          <img
            src={hoveredIndex !== null ? data[hoveredIndex].img : defaultImg}
            alt="Why us"
            className="flex lg:hidden transition duration-300 h-[200px] w-[200px] md:h-auto md:w-auto"
          />
        </div>
        <h1 className="mt-6 md:mt-0 text-[#222222] text-center md:text-start sm:text-4xl text-3xl font-semibold">
          Why Us
        </h1>
        <div className="hidden md:flex items-center mt-5">
          <div className="lg:w-[60%] w-full h-full">
            <div className="flex flex-wrap justify-between items-start  gap-4">
              {data?.map((e, i) => (
                <div
                  key={i}
                  className={`text-[#B0B0B0] cursor-pointer transition duration-300 md:w-[40%] w-full ${
                    hoveredIndex === i ? "text-[#FE4747]" : ""
                  }`}
                  onMouseEnter={() => setHoveredIndex(i)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <h1 className="sm:text-xl text-lg font-semibold text-">
                    {e.head}
                  </h1>
                  <p className="mt-2 sm:text-base text-sm">{e.des}</p>
                </div>
              ))}
            </div>
          </div>
          {/* right image  */}
          <div className="w-[40%] lg:flex hidden justify-center">
            <img
              src={hoveredIndex !== null ? data[hoveredIndex].img : defaultImg}
              alt="Why us"
              className="transition duration-300"
            />
          </div>
        </div>

        {/* for mobile */}
        <div className="flex flex-col md:hidden items-center mt-5">
          <div className="lg:w-[60%] w-full h-full">
            <div className="grid grid-cols-2  justify-between items-start  gap-4">
              {data?.map((e, i) => (
                <div
                  key={i}
                  className={`text-[#B0B0B0] cursor-pointer transition duration-300 md:w-[40%] w-full ${
                    hoveredIndex === i ? "text-[#FE4747]" : ""
                  }`}
                  onMouseEnter={() => setHoveredIndex(i)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <h1 className="text-[14px] font-semibold text-">{e.head}</h1>
                  <p className="mt-2 text-[12px] text-sm">{e.des}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
