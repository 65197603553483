import React from "react";
import { statesAndCities } from '../../../constants/area-data';

function BillingAddress({ billingInformation, setBillingInformation }) {
	const cities = [
		'Dubai',
		'Abu Dhabi',
		'Ras Al Khaimah',
		'Sharjah',
		'Ajman',
		'Fujairah',
		'Umm Al Quwain',
	];

	const countryMap = {
		Sharjah: 'United Arab Emirates',
		'Abu Dhabi': 'United Arab Emirates',
		Ajman: 'United Arab Emirates',
		'Umm Al Quwain': 'United Arab Emirates',
		'Ras Al Khaimah': 'United Arab Emirates',
		Fujairah: 'United Arab Emirates',
		Dubai: 'United Arab Emirates',
	};

	// Handler to update the city and corresponding country and area
	const handleCityChange = (e) => {
		const city = e.target.value;
		setBillingInformation({
			...billingInformation,
			city: city,
			country: countryMap[city],
			area: '', // Reset area in billing info when city changes
		});
	};

	// Handler to update the area
	const handleAreaChange = (e) => {
		const area = e.target.value;
		setBillingInformation({
			...billingInformation,
			area: area,
		});
	};

	// Handler to update the other input fields (like address lines, PO Box)
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setBillingInformation((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<div className="p-6 bg-white shadow-md rounded-lg border border-[#DDDDDD]">
			<h2 className="text-[16px] font-medium mt-[4px] mb-[16px]">Billing Address</h2>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
				<div>
					<label className="text-[14px] font-normal text-[#5E5E5E]">Address Line 1</label>
					<input
						type="text"
						name="addressLine1"
						placeholder="House number and street name"
						className="mt-1 w-full p-2 border border-gray-300 rounded-md"
						value={billingInformation?.addressLine1 || ''}
						onChange={handleInputChange}
					/>
				</div>
				<div>
					<label className="text-[14px] font-normal text-[#5E5E5E]">Address Line 2</label>
					<input
						type="text"
						name="addressLine2"
						placeholder="Apartment number, suite number"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						value={billingInformation?.addressLine2 || ''}
						onChange={handleInputChange}
					/>
				</div>

				{/* City Selector */}
				<div>
					<label className="block text-[14px] font-normal text-[#5E5E5E]">City</label>
					<select
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						value={billingInformation?.city || ''}
						onChange={handleCityChange}
					>
						<option>Select city name</option>
						{cities.map((city) => (
							<option key={city} value={city}>
								{city}
							</option>
						))}
					</select>
				</div>

				{/* Area Selector */}
				<div>
					<label className="block text-[14px] font-normal text-[#5E5E5E]">Area</label>
					<select
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						value={billingInformation?.area || ''}
						onChange={handleAreaChange}
						disabled={!billingInformation?.city}
					>
						<option>Select area</option>
						{billingInformation?.city &&
							statesAndCities[billingInformation.city]?.sort((a, b) => a.localeCompare(b)).map((area) => (
								<option key={area} value={area}>
									{area}
								</option>
							))}
					</select>
				</div>

				<div>
					<label className="block text-[14px] font-normal text-[#5E5E5E]">PO Box</label>
					<input
						type="text"
						name="poBox"
						placeholder="Enter PO Box"
						className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
						value={billingInformation?.poBox || ''}
						onChange={handleInputChange}
					/>
				</div>

				{/* Country (automatically set based on city) */}
				<div>
					<label className="block text-[14px] font-normal text-[#5E5E5E]">Country</label>
					<select className="mt-1 block w-full p-2 border border-gray-300 rounded-md" value={billingInformation?.country || ''} disabled>
						<option>{billingInformation?.country || 'Select city first'}</option>
					</select>
				</div>
			</div>
		</div>
	);
}

export default BillingAddress;