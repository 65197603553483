import React from "react";
import { FaArrowLeftLong } from "react-icons/fa6";

const NavPopup = ({ onClose, subOptions, selectedName, openPopup }) => {
  // console.log(selectedName, "->", subOptions);
  return (
    <div
      className={`bg-white text-[#FE4747] overflow-auto fixed  right-0 z-50 w-full  transition-transform duration-300 ease-in-out ${
        openPopup ? "translate-x-0" : "translate-x-full"
      }`}
      style={{ top: "80px", height: "calc(100vh - 80px)" }}
    >
      <div
        onClick={onClose}
        className="flex  items-center gap-3 text-[16px] font-medium p-4 cursor-pointer"
      >
        <FaArrowLeftLong className="" /> Back
      </div>
      <div className="p-6">
        <h2 className="text-[20px] font-bold mb-8">{selectedName}</h2>
        <ul className="flex flex-col gap-5 pl-10">
          {subOptions.map((option, index) => (
            <li key={index} className="text-[16px] font-medium">
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavPopup;
