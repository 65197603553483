import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/pics/whiteLogo.svg";
import Cookies from "js-cookie";

const Navbar = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("accessToken");

  const handleLogout = () => {
    // Clear cookies and localStorage data
    Cookies.remove("token", { path: "/" });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("vendorId");
    localStorage.removeItem("invitedBy");
    localStorage.removeItem("checkAdmin");

    // Navigate to login page or refresh to show logged-out state
    navigate("/login");
  };

  return (
    <div className="bg-[#fe4747] flex justify-center w-full py-3 z-[100]">
      <div className="w-[90%] flex justify-between items-center">
        {/* Logo */}
        <div>
          <Link to="/home">
            <img alt="logo" src={logo} />
          </Link>
        </div>

        {/* Links based on login status */}
        <div className="flex items-center gap-8 relative">
          {isLoggedIn ? (
            <>
              <Link to="/list-venues" className="text-white sm:flex hidden">
                List your Venue
              </Link>
              <button
                onClick={handleLogout}
                className="text-white px-3 py-2 rounded-md"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-white">
                Login
              </Link>
              <Link
                to="/register"
                className="bg-white text-[#222222] py-2 px-3 rounded-[4px] text-[14px] font-medium"
              >
                Signup
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
