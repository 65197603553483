import React from "react";

function EditPersonalDetails({
  handlePersonalSave,
  personalDetails,
  setPersonalDetails,
  isLoadingUser,
}) {
  return (
    <form onSubmit={handlePersonalSave}>
      <div className="space-y-4 w-[400px] mx-auto">
        <h2 className="text-center font-bold mb-4 text-[22px] lg:text-[32px]">
          Edit Profile
        </h2>
        <div className="mt-[32px]">
          <label className="block text-[14px] font-normal text-[#5E5E5E]">
            Full Name
          </label>
          <input
            type="text"
            value={personalDetails.name}
            placeholder="Enter name"
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                name: e.target.value,
              })
            }
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none"
          />
        </div>
        <div>
          <label className="block text-[14px] font-normal text-[#5E5E5E]">
            Email
          </label>
          <input
            type="email"
            placeholder="Enter email"
            value={personalDetails.email}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                email: e.target.value,
              })
            }
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none"
          />
        </div>
        <div className="flex justify-center mt-[40px]">
          <button
            type="submit"
            className="active:opacity-55 bg-[#FF3939] mt-[40px] text-white w-full py-2 rounded-xl"
          >
            {isLoadingUser ? "Sending verification..." : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditPersonalDetails;
