import React from "react";

function PersonalDetails({ setPersonalModal, user }) {
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <h2 className="text-[20px] text-[#212B36] font-medium">
            Personal Details
          </h2>
        </div>
        <button
          onClick={() => setPersonalModal(true)}
          className="active:opacity-55 w-[53px] h-[25px] text-[14px] text-[#222222] border border-[#27C2B0] rounded-md hover:bg-gray-50"
        >
          Edit
        </button>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <p className="text-[14px] text-[#717171] font-normal">Name</p>
          <p className="text-[#222222] text-[16px]">{user?.name}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="text-[14px] text-[#717171] font-normal">Email</p>
          <p className="text-[#222222] text-[16px]">{user?.email}</p>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
