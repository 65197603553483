import React, { useEffect, useState } from "react";
import { RiArrowRightSLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../components/NavbarCustomer";
import { useSelector } from "react-redux";

function CompareVenues({ filters, setFilters }) {
  const [isMobileView, setIsMobileView] = useState(false);
  const data = useSelector((state) => state.auth.compareVenues);
  const closeLink =
    useSelector((state) => state.auth.compareVLink) || "/my-favorites";
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobileView ? (
        <>
          <div className="">
            <div className="bg-white">
              <Navbar filters={filters} setFilters={setFilters} />
              <div className="flex justify-between items-center px-4 py-4 ">
                <h1 className="text-[24px] font-medium">Comparing Venues</h1>
                <RxCross2
                  className="cursor-pointer"
                  size={26}
                  onClick={() => navigate(closeLink)}
                />
              </div>
              <div className="px-4 py-4 space-y-4 overflow-x-auto custom-scrollbar1">
                <div className="flex justify-center items-center gap-6 w-full">
                  {data?.map((venue, index) => (
                    <div
                      key={index}
                      className="flex flex-col justify-center items-center "
                    >
                      <img
                        src={venue.imagesAndVideos?.images[0]}
                        alt={venue.name}
                        className="w-32 h-28 object-cover rounded-lg"
                      />
                      <p className="text-[16px] mt-2 text-center font-semibold">
                        {venue?.basicDetails?.venueName || "Undefined"},{" "}
                        {venue?.basicDetails?.hotelName || "Undefined"}
                      </p>
                    </div>
                  ))}
                </div>

                <div className="flex flex-col items-center pb-4 w-full">
                  <table className="mt-4 w-full text-center text-sm">
                    <tbody>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        City
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.location?.area || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Area
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.location?.city || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Venue Type
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.additionalDetails?.venueSetting?.length ===
                            1
                              ? venue?.additionalDetails?.venueSetting[0]
                              : venue?.additionalDetails?.venueSetting[0] +
                                " & " +
                                venue?.additionalDetails?.venueSetting[1]}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Event Hire
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.additionalDetails?.venueHire?.replace(
                              " venue",
                              ""
                            )}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Venue Size
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {`${venue?.capacity?.venueSize} sqm ` || ""}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Capacity - Sitting
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.capacity?.sitting || ""}pax
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Capacity - Standing
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.capacity?.standing || ""}pax
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Offers In-house catering
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.cateringAndDrinks
                              ?.venueProvideInHouseCatering
                              ? "In-house catering available"
                              : "No In-house catering"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        External Catering Allowed
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.cateringAndDrinks?.externalCatering
                              ? "External Catering Allowed"
                              : "External catering not allowed"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Alcohol service allowed
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.cateringAndDrinks?.alcoholLiquorLicense
                              ? "Alcohol service allowed"
                              : "No alcohol service allowed"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Bring your own alcohol
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.cateringAndDrinks
                              ?.bringYourOwnAlcoholAllowed
                              ? "Bring your own alcohol allowed"
                              : "Bring your own alcohol not allowed"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Indoor Music
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.audio?.indoorMusicAllowed
                              ? "Indoor Music allowed"
                              : "No Indoor music allowed"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Outdoor Music
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.audio?.outdoorMusicAllowed
                              ? "Outdoor Music allowed"
                              : "No Outdoor Music allowed "}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Bring your own DJ
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            {venue?.cateringAndDrinks?.clientsCanBringOwnDJ
                              ? "Bring your own DJ"
                              : "External DJ not allowed"}
                          </td>
                        ))}
                      </tr>
                      <td
                        colSpan={data?.length || 1}
                        className="px-4 py-5 text-[16px] text-center"
                      >
                        Price
                      </td>
                      <tr className="bg-[#F7F7F7]">
                        {data?.map((venue, index) => (
                          <td key={index} className="px-4 py-5 text-[16px]">
                            <div>
                              Minimum Spend:{" "}
                              {venue?.pricing?.["Minimum Spend Pricing"]?.[0]
                                ?.minSpend * venue?.capacity?.sitting}{" "}
                              AED
                              <br />
                              Minimum Spend per person:{" "}
                              {
                                venue?.pricing?.["Minimum Spend Pricing"]?.[0]
                                  ?.minSpend
                              }{" "}
                              AED
                            </div>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="">
          <div className="bg-white">
            <Navbar />
            <div className="text-[#717171] flex items-center gap-2 p-6 px-10">
              <Link to="/">Home</Link>
              <RiArrowRightSLine />
              <p className="text-[#717171] cursor-pointer">Search Results</p>
              <RiArrowRightSLine />
              <p className="text-dark">Compare</p>
            </div>
            <div className="flex w-full justify-between items-center px-10">
              <h1 className="text-[32px] font-semibold text-[#222222]">
                Compare
              </h1>
              <div>
                <RxCross2
                  className="cursor-pointer"
                  size={26}
                  // onClick={() => navigate("/listing/1")}
                  onClick={() => navigate(closeLink)}
                />
              </div>
            </div>
            <div className="px-6 py-4 overflow-x-auto custom-scrollbar1">
              <div className="min-w-full inline-block">
                <table className="w-full whitespace-nowrap text-left">
                  <thead>
                    <tr>
                      <th className=" py-2 font-bold text-[24px] text-left pl-4">
                        Comparing Venues
                      </th>
                      {data?.map((venue, index) => (
                        <th key={index} className="px-4 py-2 text-left">
                          <div className="flex items-center text-left p-2 border border-[#EBEBEB] rounded-[8px] justify-start gap-1 shadow-sm max-w-[300px]">
                            <img
                              src={venue.imagesAndVideos?.images[0]}
                              alt={venue.name}
                              className="w-20 h-16 object-cover rounded-lg mr-2"
                            />
                            <p className="text-[14px] font-medium text-[#222222] hidden md:block truncate">
                              {venue?.basicDetails?.venueName || "Undefined"},{" "}
                              {venue?.basicDetails?.hotelName || "Undefined"}
                            </p>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px] min-w-[300px]">
                        City
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left min-w-[300px]"
                        >
                          {venue?.location?.area || ""}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Area
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 text-[#222222] py-5 text-left"
                        >
                          {venue?.location?.city || ""}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Venue Type
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 text-[#222222] py-5 text-left"
                        >
                          {venue?.additionalDetails?.venueSetting?.length === 1
                            ? venue?.additionalDetails?.venueSetting[0]
                            : venue?.additionalDetails?.venueSetting[0] +
                              " & " +
                              venue?.additionalDetails?.venueSetting[1]}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Event Hire
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 text-[#222222] py-5 text-left"
                        >
                          {venue?.additionalDetails?.venueHire?.replace(
                            " venue",
                            ""
                          ) || ""}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Venue Size
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 text-[#222222] py-5 text-left"
                        >
                          {`${venue?.capacity?.venueSize} sqm ` || ""}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Capacity - Sitting
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 text-[#222222] py-5 text-left"
                        >
                          {venue?.capacity?.sitting || ""}pax
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Capacity - Standing
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.capacity?.standing || ""}pax
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Offers In-house catering
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.cateringAndDrinks?.venueProvideInHouseCatering
                            ? "In-house catering available"
                            : "No In-house catering"}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        External Catering Allowed
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.cateringAndDrinks?.externalCatering
                            ? "External Catering Allowed"
                            : "External catering not allowed"}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Alcohol service allowed
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.cateringAndDrinks?.alcoholLiquorLicense
                            ? "Alcohol service allowed"
                            : "No alcohol service allowed"}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Bring your own alcohol
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue?.cateringAndDrinks?.bringYourOwnAlcoholAllowed
                            ? "Bring your own alcohol allowed"
                            : "Bring your own alcohol not allowed"}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px] ">
                        Indoor Music
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[#222222] text-left"
                        >
                          {venue?.audio?.indoorMusicAllowed
                            ? "Indoor Music allowed"
                            : "No Indoor music allowed"}
                        </td>
                      ))}
                    </tr>
                    <tr className="bg-[#F7F7F7] py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Outdoor Music
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue?.audio?.outdoorMusicAllowed
                            ? "Outdoor Music allowed"
                            : "No Outdoor Music allowed "}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Bring your own DJ 
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          {venue?.cateringAndDrinks?.clientsCanBringOwnDJ
                            ? "Bring your own DJ"
                            : "External DJ not allowed"}
                        </td>
                      ))}
                    </tr>
                    <tr className="py-6 px-2 bg-[#F7F7F7] mt-2">
                      <td className="px-4 py-5 text-left font-medium text-[#222222] text-[16px]">
                        Price
                      </td>
                      {data?.map((venue, index) => (
                        <td
                          key={index}
                          className="px-4 py-5 text-[16px] text-[#222222] text-left"
                        >
                          <div>
                            Minimum Spend:{" "}
                            {venue?.pricing?.["Minimum Spend Pricing"]?.[0]
                              ?.minSpend * venue?.capacity?.sitting}{" "}
                            AED
                            <br />
                            Minimum Spend per person:{" "}
                            {
                              venue?.pricing?.["Minimum Spend Pricing"]?.[0]
                                ?.minSpend
                            }{" "}
                            AED
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompareVenues;
