import costStructure from "../constants/costStructure";

function calculateVenuePayment(quantityNumber, sameLocation, items = []) {
  let baseCharge = 0;
  let subTotal = 0;




  // Calculate base charge based on the counts
  baseCharge = 1000 * items?.length;

 
  subTotal = baseCharge;

  // Calculate VAT
  const vatPercentage = costStructure.vat;
  const vat = (subTotal * vatPercentage) / 100;

  // Calculate Grand Total
  const grandTotal = subTotal + vat;


  return {
    baseCharge,
    quantityNumber,
    subTotal,
    vat,
    grandTotal,
  };
}


export default calculateVenuePayment;


// import costStructure from "../constants/costStructure";

// function calculateKidsPayment(quantityNumber, sameLocation , items) {
//   let baseCharge = 1000;
//   let subTotal = 0;

//   if (sameLocation === "no") {
//     subTotal = baseCharge * quantityNumber;
//   } else if (sameLocation === "yes") {
//     subTotal = baseCharge;
//   }

//   const vatPercentage = costStructure.vat;
//   const vat = (subTotal * vatPercentage) / 100;

//   const grandTotal = subTotal + vat;

//   return {
//     baseCharge: baseCharge,
//     quantityNumber: quantityNumber,
//     subTotal: subTotal,
//     vat: vat,
//     grandTotal: grandTotal,
//   };
// }

// export default calculateKidsPayment;
