import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MobileEnquiryDashboard from "../MobileEnquiryDashbaord/MobileEnquiryDashbaord";
import StatusDropdown from "../StatusDropdown/StatusDropdown";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { base_URL } from "../../../config/config";
import axios from "axios";
import { useUser } from "../../../context/user";
import moment from "moment/moment";
import Venueselectfilter from "../../VendorDashboard/Venueselectfilter";
export const useEnquiries = ({
  vendorId,
  page,
  pageSize,
  venueName,
  date,
  status,
}) => {
  return useQuery({
    queryKey: [
      "enquiriesData",
      vendorId,
      page,
      pageSize,
      venueName,
      date,
      status,
    ],
    queryFn: async () => {
      const params = new URLSearchParams({
        page: page.toString(),
        limit: pageSize.toString(),
        ...(venueName && { venueName }),
        ...(date && { date }),
        ...(status && status !== "All" && { status }),
      });

      const { data } = await axios.get(
        `${base_URL}/vendor-enquiries/${vendorId}?${params}`
      );
      return data;
    },
    keepPreviousData: true,
  });
};
const MetricCard = ({ title, value, change, isPositive }) => {
  // Format the change value to handle decimals and edge cases
  const formatChange = (changeValue) => {
    if (typeof changeValue !== "number" || isNaN(changeValue)) {
      return "0";
    }
    // Round to 1 decimal place and avoid -0
    const roundedChange = Math.round(changeValue * 10) / 10;
    return roundedChange === 0 ? "0" : roundedChange.toString();
  };

  const displayValue = value !== "N/A" ? value : "0";
  const displayChange = formatChange(change);
  const changeColor = isPositive ? "text-[#1A9882]" : "text-[#D10C00]";
  const prefix = displayChange > 0 ? "+" : "";

  return (
    <div className="min-h-[65px] md:h-[112px] lg:h-auto min-w-[207px] flex-shrink-0 md:min-w-[250px] lg:min-w-full flex flex-col gap-2 bg-white border border-[#EBEBEB] rounded-lg p-4 shadow-sm">
      <h3 className="text-[14px] text-[#777980]">{title}</h3>
      <div className="flex items-end justify-between gap-2">
        <span className="text-[16px] xl:text-[32px] font-[600]">
          {displayValue}
        </span>
        <div
          className={`flex items-center gap-x-1 font-medium text-[12px] lg:text-[16px] ${changeColor}`}
        >
          {prefix}
          {displayChange}%
          <img
            src={isPositive ? "/enqincrement.svg" : "/enqdecrement.svg"}
            alt={isPositive ? "Increase" : "Decrease"}
            className="w-4 h-4"
          />
        </div>
      </div>
    </div>
  );
};
const EnquiryDashboard = ({
  setActiveEnquiryDetails,
  activeEnquiryDetails,
  user,
}) => {
  // Enquiry for dropdpwn state
  const [selectedOption, setSelectedOption] = useState("");
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [selectedDate, setSelectedDate] = useState("");
  // context
  const { selectedEnquiryDetails, setSelectedEnquiryDetails } = useUser();
  const queryClient = useQueryClient();
  // for a team member invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const [activeTab, setActiveTab] = useState("All");

  // Fetch New enquiries (status "Not Accepted" || 'New)
  const { data: newEnquiries, isLoading: loadingNewEnquiries } = useQuery({
    queryKey: ["newEnquiries", vendorId],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/vendor-enquiries/new/${vendorId}`
      );
      return response.data;
    },
    enabled: !!vendorId,
  });

  // Fetch Successful enquiries
  const { data: successfulEnquiries, isLoading: loadingSuccessfulEnquiries } =
    useQuery({
      queryKey: ["successfulEnquiries", vendorId],
      queryFn: async () => {
        const response = await axios.get(
          `${base_URL}/api/vendor-enquiries/successful/${vendorId}`
        );
        return response.data;
      },
      enabled: !!vendorId,
    });

  // Fetch Open Enquiries
  const { data: openEnquiries, isLoading: loadingOpenEnquiries } = useQuery({
    queryKey: ["openEnquiries", vendorId],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/vendor-enquiries/open/${vendorId}`
      );
      return response.data;
    },
    enabled: !!vendorId,
  });

  // Fetch Lost Enquiries
  const { data: lostEnquiries, isLoading: loadinglostEnquiries } = useQuery({
    queryKey: ["lostEnquiries", vendorId],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/vendor-enquiries/lost/${vendorId}`
      );
      return response.data;
    },
    enabled: !!vendorId,
  });
  // Fetch all Venue name
  const { data: venueNames } = useQuery({
    queryKey: ["venueNames", vendorId],
    queryFn: async () => {
      const response = await axios.get(`${base_URL}/api/enquiries/venues`, {
        params: { vendorId },
      });
      return response?.data;
    },
    enabled: !!vendorId,
  });

  // Status Update
  const updateStatusMutation = useMutation({
    mutationFn: async ({ id, newStatus, statusMessage, vendorEmail }) => {
      const response = await axios.patch(
        `${base_URL}/api/vendor-enquiries/${id}/status`,
        { status: newStatus, statusMessage, vendorEmail }
      );
      return response.data;
    },

    onMutate: async ({ enquiryId, newStatus }) => {
      // Cancel any outgoing refetches (Full System)
      await queryClient.cancelQueries({
        queryKey: ["vendorEnquiries", vendorId],
      });

      // Snapshot the previous value
      const previousEnquiries = queryClient.getQueryData([
        "vendorEnquiries",
        vendorId,
      ]);

      // Optimistically update to new status
      queryClient.setQueryData(["vendorEnquiries", vendorId], (old) =>
        old?.map((enquiry) =>
          enquiry?._id === enquiryId
            ? { ...enquiry, status: newStatus }
            : enquiry
        )
      );
      // Return a context object with the snapshotted value
      return { previousEnquiries };
    },
    // If mutation fails, roll back to previous state
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ["vendorEnquiries", vendorId],
        context.previousEnquiries
      );
    },
    // Always refetch after error or success
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["vendorEnquiries", vendorId],
      });
      queryClient.invalidateQueries({
        queryKey: ["successfulEnquiries", vendorId],
      });
      queryClient.invalidateQueries({
        queryKey: ["newEnquiries", vendorId],
      });
      queryClient.invalidateQueries({
        queryKey: ["openEnquiries", vendorId],
      });
      queryClient.invalidateQueries({
        queryKey: ["lostEnquiries", vendorId],
      });
      queryClient.invalidateQueries({
        queryKey: ["vendorMetrics", vendorId],
      });
      queryClient.invalidateQueries({
        queryKey: ["enquiriesData", vendorId],
      });
    },
  });

  // Function to update status
  const updateStatus = useCallback(
    (id, newStatus, statusMessage) => {
      if (!id) {
        console.error("Invalid enquiry ID:", id);
        return;
      }

      updateStatusMutation.mutate({
        id,
        newStatus,
        statusMessage,
        vendorEmail: user?.email,
      });
    },
    [updateStatusMutation, user?.email]
  );
  const [updatedMetrics, setUpdatedMetrics] = useState([]);

  // Fetch metrics data
  const { data: metricsData } = useQuery({
    queryKey: ["vendorMetrics", vendorId],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/vendor-metrics/${vendorId}`
      );
      return response.data;
    },
    enabled: !!vendorId,
  });

  // Fetch vendor response time
  const { data: responseTimeData } = useQuery({
    queryKey: ["vendorResponseTime", vendorId],
    queryFn: () => fetchVendorResponseTime(vendorId),
    enabled: !!vendorId,
  });

  // Function to fetch vendor response time
  const fetchVendorResponseTime = async (vendorId) => {
    try {
      const { data } = await axios.get(
        `${base_URL}/api/vendor-response-time/${vendorId}`
      );
      return data;
    } catch (error) {
      console.error("Error fetching response time:", error.message);
      return { averageResponseTime: 0, totalEnquiries: 0 };
    }
  };

  // Utility function to format response time
  const formatResponseTime = (minutes) => {
    if (!minutes || minutes === 0) return "N/A";
    if (minutes < 60) return `${Math.round(minutes)} mins`;
    return `${Math.round(minutes / 60)} hrs`;
  };

  // Calculate response time change
  const calculateResponseTimeChange = (responseTimeData) => {
    if (!responseTimeData?.previousAverageResponseTime) return 0;
    if (responseTimeData?.previousAverageResponseTime === 0) return 0;

    const current = responseTimeData.averageResponseTime || 0;
    const previous = responseTimeData.previousAverageResponseTime;

    return Math.round(((current - previous) / previous) * 100);
  };

  // Main effect for updating metrics
  useEffect(() => {
    if (metricsData && responseTimeData) {
      const updated = [
        {
          title: "New Enquiries",
          value: metricsData?.newEnquiries?.current,
          change: metricsData?.newEnquiries?.percentageChange,
          isPositive: metricsData?.newEnquiries?.percentageChange >= 0,
        },
        {
          title: "Open Enquiries",
          value: metricsData?.openEnquiries?.current,
          change: metricsData?.openEnquiries?.percentageChange,
          isPositive: metricsData?.openEnquiries?.percentageChange >= 0,
        },
        {
          title: "Successful Enquiries",
          value: metricsData?.successfulEnquiries?.current,
          change: metricsData?.successfulEnquiries?.percentageChange,
          isPositive: metricsData?.successfulEnquiries?.percentageChange >= 0,
        },
        {
          title: "Average Response Time",
          value: formatResponseTime(responseTimeData?.averageResponseTime),
          change: calculateResponseTimeChange(responseTimeData),
          isPositive: calculateResponseTimeChange(responseTimeData) <= 0,
        },
      ];

      setUpdatedMetrics(updated);
    }
  }, [metricsData, responseTimeData]);

  const formatRelativeTime = (timestamp) => {
    return timestamp ? moment(timestamp).fromNow() : "--";
  };

  const isWithin15Minutes = (timestamp) => {
    return timestamp
      ? moment().diff(moment(timestamp), "minutes") <= 15
      : false;
  };

  const { data: enquiriesData, isLoading: enquiriesLoading } = useEnquiries({
    vendorId,
    page: currentPage,
    pageSize,
    venueName: selectedVenue,
    date: selectedDate,
    status: activeTab,
  });

  const tabs = [
    { label: "All", count: enquiriesData?.statusCounts?.total || 0 },
    { label: "New", count: newEnquiries?.length || 0 },
    { label: "Open", count: openEnquiries?.length || 0 },
    {
      label: "Successful",
      count: successfulEnquiries?.length || 0,
    },
    { label: "Lost", count: lostEnquiries?.length || 0 },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };
  const handleTabChange = (tabLabel) => {
    setActiveTab(tabLabel);
    setCurrentPage(1);
  };

  // Function to handle date change
  const handleDateChange = (e) => {
    const rawDate = e.target.value; // The original YYYY-MM-DD format
    setSelectedDate(rawDate); // Store the raw date in state
    setCurrentPage(1);
  };

  const handleVenueChange = (e) => {
    setSelectedVenue(e.target.value);
    setCurrentPage(1);
  };
  const handleClickArrow = async (enquiry) => {
    if (enquiry?.status === "Not Accepted") {
      // Update status to Active (Which means Ongoing)
      await updateStatus(enquiry?._id, "Active", `Active by ${user?.email}`);
      const updatedEnquiry = { ...enquiry, status: "Active" };
      setSelectedEnquiryDetails(updatedEnquiry);
      setActiveEnquiryDetails(true);
      return;
    }
    if (enquiry?.status !== "Not Accepted" || enquiry?.status !== "New") {
      setSelectedEnquiryDetails(enquiry);
      setActiveEnquiryDetails(true);
      return;
    }
    // Trigger the status update
    queryClient.setQueryData(["activeEnquiry"], {
      id: enquiry?._id,
      status: "Active",
      message: `Active by ${user?.email}`,
    });

    updateStatus(enquiry?._id, "Active", `Active by ${user?.email}`);
    setActiveEnquiryDetails(true);
    setSelectedEnquiryDetails(enquiry);
  };

  return (
    <div className="w-full  ">
      <div className=" mx-auto space-y-6">
        {/* Metrics Grid */}
        <div className="overflow-x-auto lg:overflow-hidden">
          {/* parent */}
          <div className="flex gap-4 w-max lg:w-full lg:grid lg:grid-cols-4">
            {updatedMetrics?.map((metric, index) => (
              <MetricCard key={index} {...metric} />
            ))}
          </div>
        </div>

        {/* Filters */}
        <div className=" flex-wrap gap-4 items-center hidden md:flex">
          {tabs?.map((tab) => (
            <button
              key={tab.label}
              onClick={() => handleTabChange(tab.label)}
              className={`px-4 py-2 text-[14px] font-normal ${
                activeTab === tab.label
                  ? "text-[#FE4747] border-b-2 border-[#FE4747]"
                  : "text-[#222222]"
              }`}
            >
              {`${tab.label} (${tab.count})`}
            </button>
          ))}

          <div className="ml-auto flex gap-4">
            <select
              value={selectedVenue}
              onChange={handleVenueChange}
              className="border border-[#B0B0B0] text-[#B0B0B0] rounded-lg px-4 py-2 pr-6 outline-none text-sm w-full md:w-[197px] appearance-none bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%226%22%20viewBox%3D%220%200%2010%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.174019%200.688233L0.17402%200.688232C0.281236%200.571268%200.42254%200.512531%200.599191%200.512531H9.40081C9.57742%200.512531%209.71871%200.571814%209.82594%200.689912C9.93375%200.808653%209.9875%200.946829%209.9875%201.10499C9.9875%201.11278%209.9843%201.12734%209.97655%201.14987C9.96895%201.17194%209.95748%201.20035%209.94204%201.2352C9.91131%201.30453%209.8652%201.39862%209.80364%201.51756L5.62731%205.69389C5.53216%205.78904%205.43416%205.85814%205.33338%205.90159C5.23256%205.94505%205.12149%205.96687%205%205.96687C4.87851%205.96687%204.76744%205.94505%204.66662%205.90159C4.56584%205.85814%204.46785%205.78903%204.37269%205.69385L0.194725%201.51592C0.138084%201.45928%200.0935546%201.39612%200.0610483%201.32643C0.0287014%201.25706%200.0125%201.18271%200.0125%201.10323C0.0125%200.9439%200.0662612%200.805766%200.174019%200.688233Z%22%20fill%3D%22%23222222%22%20stroke%3D%22%23222222%22%20stroke-width%3D%220.025%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-[center_right_1rem]"
            >
              <option value="" className="text-[#B0B0B0]">
                Enquiry for
              </option>
              {venueNames?.venues?.map((venue, index) => (
                <option key={index} value={venue}>
                  {venue}
                </option>
              ))}
            </select>

            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className="px-2 border border-[#B0B0B0] rounded-lg appearance-none outline-none h-[40px] md:w-[253px] bg-white text-[#B0B0B0] "
            />
          </div>
        </div>

        {/* Table */}
        {enquiriesLoading && (
          <div className="h-[40vh] mt-[5px] rounded-lg animate-pulse bg-black/20"></div>
        )}
        {!enquiriesLoading && (
          <div className="bg-white  rounded-xl hidden md:inline  ">
            <div className="overflow-x-auto rounded-xl bg-white mt-4">
              <table className="w-full text-left  ">
                <thead className="bg-[#F4F4F4]/70 ">
                  <tr>
                    <th className="xl:whitespace-nowrap px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Customer name
                    </th>
                    <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Event type
                    </th>
                    <th className="xl:whitespace-nowrap px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Venue name
                    </th>
                    <th className="xl:whitespace-nowrap px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Event date
                    </th>
                    <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Guest
                    </th>
                    <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Budget
                    </th>
                    <th className="xl:whitespace-nowrap px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Last message
                    </th>
                    <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                      Status
                    </th>
                    <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]"></th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {enquiriesData?.enquiries?.map((enquiry) => (
                    <tr key={enquiry?.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        {enquiry?.customerId?.fullName}
                      </td>
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        {enquiry?.eventType}
                      </td>
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        {enquiry?.venueName || enquiry?.hotelName}
                      </td>
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        {enquiry?.startDate}
                      </td>
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        {enquiry?.guests}
                      </td>
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        AED {enquiry?.budget || "--"}
                      </td>
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        <div className="flex items-center gap-2">
                          <div
                            className={`bg-[#D03660] rounded-full w-2 ${
                              isWithin15Minutes(enquiry?.lastMessage?.time)
                                ? "visible mr-2 h-2"
                                : "invisible "
                            }`}
                          ></div>
                          {formatRelativeTime(enquiry?.lastMessage?.time)}
                        </div>
                      </td>
                      <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                        <StatusDropdown
                          enquiry={enquiry}
                          updateStatus={updateStatus}
                          user={user}
                        />
                      </td>
                      <td
                        onClick={() => handleClickArrow(enquiry)}
                        className="cursor-pointer active:opacity-55 px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]"
                      >
                        <svg
                          className="w-5 h-5 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination */}
              <div className="flex items-center justify-end gap-x-3 px-6 py-3 border-t">
                <div className="flex items-center gap-2">
                  <span className="text-sm text-[#222222]">Display:</span>
                  <select
                    value={pageSize}
                    onChange={handlePageSizeChange}
                    className="px-2 py-1 border rounded-lg"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                  </select>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of{" "}
                    {Math.ceil(enquiriesData?.totalPages) || 1}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
                    disabled={
                      currentPage === Math.ceil(enquiriesData?.totalPages)
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* mobile compoennt */}
        <MobileEnquiryDashboard
          user={user}
          filteredEnquiries={enquiriesData?.enquiries}
          updateStatus={updateStatus}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          isLoading={enquiriesLoading}
          setActiveEnquiryDetails={setActiveEnquiryDetails}
          setSelectedEnquiryDetails={setSelectedEnquiryDetails}
        />
      </div>
    </div>
  );
};

export default EnquiryDashboard;
