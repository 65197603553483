import React from "react";
import IconButtonWithTooltip from "./IconButtonWithTooltip";
import Tooltip from "../venuePreview/Tooltip";

const Pricing = ({ data }) => {
  // Extract pricing data from props
  const basePrice = data?.basePrice || "";
  const mentionSeasons = data?.mentionSeasons || false;
  const highDemandMonths = data?.highDemandMonths || [];
  const midDemandMonths = data?.midDemandMonths || [];
  const lowDemandMonths = data?.lowDemandMonths || [];

  // Map months to season categories with colors
  const seasonColors = {
    high: "text-red-500 border-red-500",
    mid: "text-yellow-500 border-yellow-500",
    low: "text-green-500 border-green-500",
  };

  // Combine all months into one array with color information
  const combinedSeasons = [
    ...highDemandMonths.map((month) => ({
      month,
      season: "high",
      color: seasonColors.high,
    })),
    ...midDemandMonths.map((month) => ({
      month,
      season: "mid",
      color: seasonColors.mid,
    })),
    ...lowDemandMonths.map((month) => ({
      month,
      season: "low",
      color: seasonColors.low,
    })),
  ];

  const monthOrder = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const groupConsecutiveMonths = (months, season, color) => {
    // Get the index of each month and sort them
    const sortedMonths = months
      .map((month) => monthOrder.indexOf(month))
      .sort((a, b) => a - b);

    // Group consecutive months
    const grouped = [];
    let tempGroup = [sortedMonths[0]];

    for (let i = 1; i < sortedMonths.length; i++) {
      if (sortedMonths[i] === sortedMonths[i - 1] + 1) {
        tempGroup.push(sortedMonths[i]);
      } else {
        grouped.push(tempGroup);
        tempGroup = [sortedMonths[i]];
      }
    }

    // Add the last group
    if (tempGroup.length) grouped.push(tempGroup);

    // Convert back to month names and format the ranges
    return grouped.map((group) => {
      const formattedMonths =
        group.length === 1
          ? monthOrder[group[0]]
          : `${monthOrder[group[0]]}-${monthOrder[group[group.length - 1]]}`;
      return { month: formattedMonths, season, color };
    });
  };

  // Group consecutive months for each season
  const highDemandSeasons = groupConsecutiveMonths(
    highDemandMonths,
    "high",
    seasonColors.high
  );
  const midDemandSeasons = groupConsecutiveMonths(
    midDemandMonths,
    "mid",
    seasonColors.mid
  );
  const lowDemandSeasons = groupConsecutiveMonths(
    lowDemandMonths,
    "low",
    seasonColors.low
  );

  const sortedCombinedSeasons = combinedSeasons.sort(
    (a, b) => monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month)
  );

  // Determine the season labels based on dynamic data
  const seasonLabels = {
    high: "High Season",
    mid: "Shoulder Season",
    low: "Low Season",
  };

  return (
    <div className="md:py-8 py-2 w-full md:border-t mx-auto">
      <div className="bg-white rounded-lg mb-8">
        <div className="md:flex block justify-between flex-wrap md:items-start items-center mb-4">
          <div className="col-span-1 flex justify-start items-center gap-1">
            <h2 className="text-2xl font-bold">Pricing</h2>
            <div className="md:flex hidden justify-start md:items-center items-start md:mt-0 mt-2 gap-2 md:ml-0 -ml-4 w-full">
              <Tooltip text="Prices vary based on your needs. Submit an enquiry for a personalized quote." />
            </div>
          </div>
          <span className="col-span-3 text-[#5E5E5E] md:text-xl text-sm md:w-[73%] w-full">
            Starting From{" "}
            <span className="font-semibold text-black md:text-xl text-sm">
              {basePrice} AED/ Room
            </span>
          </span>
        </div>
        {mentionSeasons && (
          <div className="flex justify-between flex-wrap items-start mb-4">
            <h3 className="text-2xl font-medium mb-4">Seasonality</h3>
            <div className="lg:w-[73%] w-full">
              <div className="flex flex-wrap gap-2 mb-4 w-full">
                {sortedCombinedSeasons.map((season, index) => (
                  <div
                    key={index}
                    className={`flex items-center justify-center w-11 h-8 border rounded-full ${season.color}`}
                  >
                    <span className="text-sm text-black">{season.month}</span>
                  </div>
                ))}
              </div>

              <div className="flex justify-start gap-20">
                {Object.entries({
                  high: highDemandSeasons,
                  mid: midDemandSeasons,
                  low: lowDemandSeasons,
                }).map(([seasonType, months]) => (
                  <div key={seasonType} className="flex items-start">
                    <span
                      className={`w-3 h-3 mt-[6px] rounded-full inline-block mr-2 ${
                        seasonType === "high"
                          ? "bg-red-500"
                          : seasonType === "mid"
                          ? "bg-yellow-500"
                          : "bg-green-500"
                      }`}
                    ></span>
                    <div>
                      <p className="font-semibold">
                        {seasonLabels[seasonType]}
                      </p>
                      <ul className="list-disc gap-2 items-center ml-7">
                        {months.map(({ month }, idx) => (
                          <li key={idx} className="text-black text-[14px]">
                            {month}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pricing;
