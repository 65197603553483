import React from "react";

function OrganizationDetails({ setOrganizationModal, user }) {
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <h2 className="text-[20px] text-[#212B36] font-medium">
            Organisation Details
          </h2>
        </div>
        <button
          onClick={() => setOrganizationModal(true)}
          className="active:opacity-55 w-[53px] h-[25px] text-[14px] text-[#222222] border border-[#27C2B0] rounded-md hover:bg-gray-50"
        >
          Edit
        </button>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <p className="text-[14px] text-[#717171] font-normal">Organisation</p>
          <p className="text-[#222222] text-[16px]">
            {user?.organization === "" ? "-" : user?.organization}
          </p>
        </div>
        <div className="flex justify-between items-center">
          <p className="text-[14px] text-[#717171] font-normal">Job Title</p>
          <p className="text-[#222222] text-[16px]">
            {user?.role === "" ? "-" : user?.role}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrganizationDetails;
