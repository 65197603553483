import React from "react";
import Container from "@mui/material/Container";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import backgroundIcon from "../../assets/images/background_icon.svg";
import MessageCard from "./components/MessageCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as TickGreenIcon } from "../../assets/images/tick_green_icon.svg";
import { useTheme } from "@mui/material/styles";

function ResendEmail() {
  const theme = useTheme();
  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: { md: "center" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <MessageCard
            loaderComponent={
              <CheckCircleIcon
                sx={{
                  fontSize: 60,
                  mt: 4,
                  color: theme.palette.green.text,
                }}
              />
            }
            primaryText={"Email sent again!"}
            secondaryText={`We've sent another email. Please verify.`}
          />
        </Container>
      </div>
    </>
  );
}

export default ResendEmail;
