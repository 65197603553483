import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from "./Rating";
import HotelDetailComp from "./HotelDetailComp";
import axios from "axios";
import { base_URL } from "../../config/config";

const HotelCarousel = ({ data }) => {
  const [provideData, setProvideData] = useState([]);

  const getVenuesNumber = async (name) => {
    try {
      const response = await axios.post(
        `${base_URL}/api/hotels/number-of-venues`,
        { hotelName: name }
      );
      return response.data.totalVenues || 0;
    } catch (error) {
      console.log(error);
      return 0; // Return 0 if there's an error
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        // console.log("data", data);
        try {
          const dataArr = await Promise.all(
            data.map(async (e) => {
              const numberOfVenues = await getVenuesNumber(
                e?.overview?.basicDetails?.hotelName
              );

              return {
                id: e._id,
                images: e?.photoVideo?.images,
                name: e?.overview?.basicDetails?.hotelName,
                loc: `${e?.overview?.locationDetails?.area}, ${e?.overview?.locationDetails?.city}`,
                room: e.accommodation.accommodations,
                venu: numberOfVenues,
                aed: `${e?.pricing?.basePrice} AED`,
                price: "Starting price from per room per night",
                rating: (
                  <Rating rating={e?.overview?.basicDetails?.hotelRating} />
                ),
              };
            })
          );
          // console.log("dataArr", dataArr");
          setProvideData(dataArr);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [data]);

  return (
    <div className="relative w-full">
      <div className="mt-8 flex">
        <HotelDetailComp data={provideData} />
      </div>
      <div className="flex justify-center w-full my-10">
        <button
          className="bg-[#FE4747] text-white rounded-lg w-[120px] py-2"
          onClick={() => (window.location.href = "/listing/2")}
        >
          Explore More
        </button>
      </div>
    </div>
  );
};

export default HotelCarousel;
