import React from "react";
import { BsSend } from "react-icons/bs";
import { IoIosAttach } from "react-icons/io";

export default function Disabledsend() {
  return (
    <div className=" flex justify-between gap-x-2 md:gap-x-0 items-center w-[95%] mx-auto md:py-6 py-2">
      <div className="bg-[#F7F7F7] flex justify-start items-center gap-3 md:w-[90%] w-[85%] md:px-4 px-2 rounded-[8px] h-[48px] border border-[#DDDDDD]">
        <IoIosAttach className="text-[#000000] " size={24} />
        <input
          readOnly
          type="file"
          accept=".jpg,.jpeg,.png,.pdf"
          style={{ display: "none" }}
        />
        <input
          type="text"
          readOnly
          placeholder="Type something, or press '/' for auto response..."
          className="w-[90%] h-[40px] border-none bg-[#F7F7F7] text-[#B0B0B0] placeholder-[#B0B0B0] outline-none"
        />
      </div>
      <button
        disabled
        className="w-[48px] h-[48px]   rounded-[6px] bg-[#DDDDDD] flex justify-center items-center"
      >
        <BsSend className="text-white" size={20} />
      </button>
    </div>
  );
}
