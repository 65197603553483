import React from 'react';
import ResponsiveAppBar from "../../components/ResponsiveAppBar";


const TermsOfUse = () => {
    return (
        <>
            <ResponsiveAppBar />
            <div

                className='flex justify-center '
            >
                <div className="min-h-screen  sm:p-8 p-4 md:w-[60%] sm:w-[80%] w-full">
                    <h1 className="text-3xl font-bold text-center mb-8 text-custom">Terms & Conditions</h1>
                    <div className="mt-8 text-custom ">

                        <p className="mb-4 " >
                            Welcome to www.findmyvenue.com and the associated mobile application, along with all upgrades, updates, enhancement and modifications thereto (the  <span className='font-bold'> “Platform”) </span>, owned by Find My Venue, a company having its registered office address at Meydan Grand Stand, 6th floor, Meydan Road, Nad Al Sheba, Dubai, UAE  (hereinafter referred to as <span className='font-bold'> “Find My Venue”)</span>. Find My Venue is an online marketplace aggregator which connects Venue Operators with Users wherein Users can browse through the venues and services offered by the Venue Operators and send User Enquiries to the Venue Operators on the Platform.
                        </p>
                        <p className="mb-4">
                            Please read these terms of use <span className='font-bold'> (“Terms”) </span>carefully, as it contains the terms and conditions governing your use of the Platform and any content such as text, data, information, software, graphics, audio, video or photographs that Find My Venue may make available through the Platform and any services that Find My Venue may provide through the Platform. In order to use the Platform, you must first agree to these Terms. By performing an affirmative action and thereafter, accessing the Platform, you agree and undertake that you are giving a free, specific, informed, unconditional and unambiguous consent to Find My Venue, agreeing to these Terms and concluding a legally binding contract with Find My Venue for the purposes specified herein. Please do not access or use the Platform if you do not agree with any of the terms provided herein or are unable to be bound by them. As a condition of your access to and use of the Platform, you hereby agree that you will comply with all applicable laws and regulations when using the Platform.
                        </p>

                        <p className="mb-4">
                            Find My Venue reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms, at any time. Your acceptance of the revised terms of use and thereafter, use of the Platform following the posting of changes shall be deemed to mean that you accept and agree to the revisions made to these Terms. As long as you comply with these Terms, Find My Venue grants you a personal, revocable, non-exclusive, non-transferable, non-sub-licensable, and limited license to access and use the Platform.
                        </p>

                        <p className="mb-4">
                            You should read these Terms, as well as access and read all further linked information, if any, referred to in these Terms, as such information contains further terms and conditions that apply to you as a user of the Platform. Such linked information including but not limited to Find My Venue’s privacy policy <span className="font-bold"> (“Privacy Policy”) </span>is hereby incorporated by reference into these Terms.
                        </p>

                        <p className="mb-4">
                            If you are unwilling or unable to be legally bound by these Terms, please do not give your consent or use the Platform. You cannot accept these Terms if: (a) you are not lawfully entitled to use the Platform; or (b) if you are not of legal age to form a binding agreement with Find My Venue.
                        </p>


                        <h1 className="text-3xl font-bold text-center mb-8 text-custom">Terms Of Use For Users</h1>

                        <p className="mb-4">
                            In these Terms, unless the context or meaning otherwise requires: (a) all capitalised words and expressions defined by inclusion in quotation and/or parenthesis anywhere in these Terms, have the same meanings as ascribed to such words and expressions; and (b) following words and expressions shall bear the meanings ascribed to them below:
                        </p>

                        <p className='mb-4 '>
                            <span className='font-bold '>"Event"</span>
                            shall mean the User’s specific requirements and specification for a venue, which shall be conveyed to the Venue Operator through the User Enquiry. An Event can take place on one date or over several dates immediately following one another.
                        </p>


                        <p className='mb-4 '>
                            <span className='font-bold '>"User"</span>
                            shall mean any individual who has created an Account on the Platform and visits and/or uses the Platform for the purpose of making User Enquiries, in accordance with the terms contained herein.
                        </p>



                        <p className='mb-4 '>
                            <span className='font-bold '>"User Enquiry"</span>
                            shall mean an enquiry sent by a User to the Venue Operator through the Platform
                        </p>


                        <p className='mb-4 '>
                            <span className='font-bold '>"Venue Operator"</span>
                            shall mean a firm, company, partnership, society, or other legal entity, who uses the Platform for the purpose of listing its venues, and is registered as a ‘Venue Operator’ on the Platform in accordance with the applicable terms and/or agreement.
                        </p>

                        <p className='mb-4 font-bold'>
                            MEMBERSHIP ELIGIBILITY
                        </p>
                        <p className='mb-4'>
                            1.1. Use of the Platform is available only to natural persons who can enter into legally binding contracts under applicable law. Persons who are "incompetent to contract" within the meaning applicable law including minors and persons of unsound mind are not eligible to use the Platform. Any person under the age of 21 (Twenty One) or who is incompetent to contract, shall not register on the Platform and shall not transact on or use the Platform, unless such use or transaction is made by a verifiable consent by such person’s legal guardian or parents on the Platform. Any minor or person not competent to contract, that is using or transacting on the Platform in violation of these Terms shall be doing so at their own risk and Find My Venue shall not be responsible or liable for any activities or interactions of such persons. Find My Venue reserves the right to terminate any person’s membership and/or refuse to provide such person with access to the Platform if it is brought to the notice of Find My Venue or if Find My Venue discovers for itself that such person is not eligible to use the Platform.
                        </p>

                        <p className='mb-4 font-bold'>
                            ACCEPTANCE OF TERMS
                        </p>
                        <p className='mb-4'>
                            In order to use the Platform, the User must first agree to these Terms. The User can accept these Terms by:
                        </p>


                        <ul className='list-disc pl-6 mb-4'>
                            <li className='mb-2'>
                                Signing up with Find My Venue and logging onto the Platform by using the Login Credentials, as defined below in Clause 3;
                            </li>
                            <li className='mb-2'>
                                Actual usage of the Platform. In this case, the User understands and agrees that the earlier affirmative consent given by him/her on the Platform shall be treated by Find My Venue as a continued acceptance of these Terms; or
                            </li>
                            <li className='mb-2'>
                                By clicking to accept these Terms, if and when prompted on the Platform.
                            </li>
                        </ul>


                        <p className='mb-4 font-bold'>
                            OPENING AN ACCOUNT AND SIGN IN/LOG IN
                        </p>

                        <p className='mb-4'>
                            The Platform allows only limited and restricted access to the services for unregistered users.
                        </p>

                        <p className='mb-4'>
                            In order to send User Enquiries to Venue Operators via the Platform, the User will have to create an account on the Platform <span className='font-bold'> (“Account”),</span> which can be done by providing/using the mobile number and email ID of the User. The User will also have to provide Event details, including but not limited to, the type of event, dates, budget, and other specifications or requirements.
                        </p>

                        <p className='mb-4'>
                            While signing up with Find My Venue to use the Platform, the User shall not:
                        </p>
                        <ul className='list-disc pl-6 mb-4'>
                            <li className='mb-2'>
                                Create an Account for anyone other than the User, unless such person's prior permission has been obtained;
                            </li>
                            <li className='mb-2'>
                                Use an Account that is the name of another person with the intent to impersonate that person; or
                            </li>
                            <li className='mb-2'>
                                Create more than one Account on the Platform.
                            </li>
                        </ul>

                        <p className='mb-4'>
                            Find My Venue cannot and will not be liable for any loss or damage arising from the User’s failure to comply with this Clause.
                        </p>

                        <p className='mb-4'>
                            The User hereby confirms and undertakes that the information provided by the User while signing up is and will be true, accurate, up-to-date, and complete at all times. The User agrees that if the User provides any information to Find My Venue that is untrue, inaccurate, not up-to-date, incomplete, or becomes untrue, inaccurate, not up-to-date or incomplete, Find My Venue shall have the right to indefinitely suspend or terminate or block access to the Account on the Platform and refuse to provide the User access to the Platform. The User is advised to use due caution when providing any information to Find My Venue accordingly.
                        </p>

                        <p className='mb-4'>
                            Once registered, the User can log in to the Account by using the e-mail ID and password provided at the time of signing up (“Login Credentials”).
                        </p>

                        <p className='mb-4 font-bold'>
                            ACCOUNT CONFIDENTIALITY OBLIGATIONS
                        </p>


                        <p className='mb-4'>
                            The User agrees that the sole responsibility of maintaining the security and confidentiality of the Login Credentials rests with the User at all times. The User shall not share these Login Credentials with any third party.
                        </p>

                        <p className='mb-4'>
                            Find My Venue reserves the right to take any and all action, as it deems necessary or reasonable, with regards to protecting and safeguarding the security of the Platform and the Account.
                        </p>

                        <p className='mb-4'>
                            The User is solely responsible for all activities that occur under the Account and/or the User undertakes on the Platform and hereby undertakes that no such activity shall be unlawful or in violation of any applicable laws, rules, and regulations.
                        </p>

                        <p className='mb-4'>
                            In no event and under no circumstances shall Find My Venue be held liable for any liabilities or damages resulting from or arising out of the User’s use of the Platform or the Login Credentials, theft of the Login Credentials or release by the User of the Login Credentials to a third party, or the User’s authorization to allow another person to access and use the Platform using the Account.
                        </p>

                        <p className='mb-4'>
                            In case of any misappropriation or unauthorized access of the Account, the User agrees to communicate the same to Find My Venue immediately, in the manner indicated by Find My Venue as set out in Clause 20 below. The User may be held liable for any losses incurred by Find My Venue or any other user of or visitor to the Platform due to authorized or unauthorized use of the Account, as a result of his/her failure in keeping the Login Credentials confidential.
                        </p>



                        <p className='mb-4 font-bold'>
                            SCOPE OF SERVICES
                        </p>



                        <p className='mb-4'>
                            The User will need to register and/or comply with these Terms (as applicable) in order to be able to use and access the services on the Platform, which includes enabling Users to make User Enquiries via the Platform and other related services as may be made available on the Platform from time to time by Find My Venue (“Services”).
                        </p>

                        <p className='mb-4'>
                            Find My Venue only acts as an aggregator to enable Users to connect with Venue Operators and make User Enquiries on the Platform. The User acknowledges that any User Enquiries with the Venue Operator shall take place solely on the Platform.
                        </p>

                        <p className='mb-4'>
                            Any bookings and payments shall be made by the User directly with the Venue Operator and not to Find My Venue on the Platform. Find My Venue is not a party to the booking between the Venue Operator and User, and the Venue Operator shall be solely responsible for providing the venue and related services to the User. It is the Venue Operator, and not Find My Venue which determines the booking fees and other dues payable by the User related to the booking of a venue.
                        </p>

                        <p className='mb-4'>
                            Any and all pricing, rate cards, and other fees shall be mutually discussed directly between the User and Venue Operator. The User acknowledges that Find My Venue shall in no way be responsible nor guarantees the accuracy and/or validity of any pricing, fees, or rate cards mentioned on the Platform or communicated by the Venue Operators.
                        </p>

                        <p className='mb-4'>
                            The User understands and acknowledges that, while describing the Venue Operators and its services on the Platform, Find My Venue endeavours to be as accurate as possible. However, Find My Venue cannot and does not warrant that the description of the Venue Operator and its services provided on the Platform is accurate, reliable, complete, current, and/or error-free. The Platform may contain typographical errors or inaccuracies and may not be complete or updated. Additionally, the picture of the venues that the User can see on the Platform depends on pictures uploaded by the Venue Operators, and Find My Venue does not guarantee that the display of any picture will accurately reflect the general appearance of the venue. It is expressly clarified that the venue pictures and other information are merely indicative and may not match the actual venue and Find My Venue shall in no way be responsible for any such discrepancies.
                        </p>

                        <p className='mb-4'>
                            Find My Venue may delist or discontinue some or all of the Venue Operators listed and/or Services provided on the Platform, as it may deem fit, including certain features and the support for certain devices and platforms, at any time.
                        </p>

                        <h3 className=' mb-4 font-bold'>
                            USE OF THE PLATFORM
                        </h3>

                        <p className='mb-4'>
                            The User agrees, undertakes, and covenants that, during the use of the Platform, the User shall not host, display, upload, modify, publish, transmit, store, update, or share any information that:
                        </p>

                        <ul className='list-disc pl-6 mb-4'>
                            <li className='mb-2'>
                                Belongs to another person or entity and to which the User does not have any right.
                            </li>
                            <li className='mb-2'>
                                Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another person's privacy, insulting or harassing on the basis of gender, hateful or racially or ethnically objectionable, or promoting enmity between different groups on the grounds of religion or caste with the intent to incite violence, or otherwise inconsistent or contrary to the applicable laws in force in any manner whatsoever.
                            </li>
                            <li className='mb-2'>
                                Is misleading in any way.
                            </li>
                            <li className='mb-2'>
                                Is harmful to minors.
                            </li>
                            <li className='mb-2'>
                                Involves the transmission of "junk mail", "chain letters", unsolicited mass mailing, and/or "spamming".
                            </li>
                            <li className='mb-2'>
                                Infringes upon or violates any third party's rights including, but not limited to, any patent, trademark, copyright or other proprietary rights or intellectual property rights, rights of privacy (including without limitation, unauthorized disclosure of a person's name, email address, physical address or phone number).
                            </li>
                            <li className='mb-2'>
                                Provides instructional information about illegal activities such as violating someone's privacy or providing or creating computer viruses.
                            </li>
                            <li className='mb-2'>
                                Tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, Account information or information of other Users/visitors of the Platform, bulletins, or other unauthorized areas of the Platform or solicits passwords or personally identifying information for commercial or unlawful purposes from other Users/visitors of the Platform.
                            </li>
                            <li className='mb-2'>
                                Engages in commercial activities without Find My Venue’s prior written consent such as contests, sweepstakes, barter, advertising, etc.
                            </li>
                            <li className='mb-2'>
                                Interferes with another User/visitor’s use of the Platform.
                            </li>
                            <li className='mb-2'>
                                Impersonates another person.
                            </li>
                            <li className='mb-2'>
                                Refers to any website or URL that, in Find My Venue’s sole discretion, contains material that is inappropriate for the Platform or any other website, and/or contains content that would be prohibited or violates the letter or spirit of these Terms.
                            </li>
                            <li className='mb-2'>
                                Deceives or misleads the addressee/users/Venue Operators about the source or sender of the messages or knowingly and intentionally communicates any information which is patently false or misleading or grossly offensive or menacing in nature but may reasonably be perceived as a fact.
                            </li>
                            <li className='mb-2'>
                                Is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
                            </li>
                            <li className='mb-2'>
                                Contains software viruses or any other computer codes, files, or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms or other computer programming routines that may damage, detrimentally interfere with, diminish value of, covertly intercept or steal any system, data or Personal Information of another User/visitor.
                            </li>
                            <li className='mb-2'>
                                Violates any applicable law for the time being in force.
                            </li>
                        </ul>

                        <p className='mb-4'>
                            The User agrees and acknowledges that the provision of Services on the Platform may be interrupted, including for maintenance, repairs, upgrades, or network or equipment failures. Find My Venue strives to keep the Platform up and running; however, all online services suffer occasional disruptions and outages, and Find My Venue isn’t liable for any disruption or loss the User may suffer as a result.
                        </p>

                        <p className='mb-4'>
                            The User agrees and acknowledges that (a) the User shall not use the Platform for any illegal or unauthorized purpose; and (b) the User’s use of any information or materials on the Platform is entirely at the User’s own risk, for which Find My Venue shall not be liable. The User agrees to comply with all laws, rules, and regulations applicable to the use of the Platform.
                        </p>

                        <p className='mb-4'>
                            Find My Venue shall have the right, but not the obligation, to monitor access to or use of the Platform to ensure the User’s compliance with these Terms or applicable laws or other legal requirements, at its sole discretion.
                        </p>

                        <h3 className=' mb-4 font-bold'>
                            USER’S POSTS AND REVIEWS
                        </h3>

                        <p className='mb-4'>
                            The Platform may allow the User to post certain content, data, or information belonging to the User, such as reviewing and allowing the User to share the User’s experience and views about a particular Venue Operator, rate Venue Operators, post the User’s comments and reviews in relation to contents and/or Venue Operators on the Platform on specific pages of the Platform, as well as submit/post any photos, videos, suggestions, comments, questions or other information to Find My Venue using the Platform (collectively referred to as “User Content”).
                        </p>

                        <p className='mb-4'>
                            The User, being the originator of the User Content, is responsible for the User Content that the User uploads, posts, publishes, transmits, or otherwise makes available on the Platform. The User represents that the User exclusively owns the copyright in the User Content and has obtained all relevant consents and approvals in order to post any User Content. The User further represents that all such User Content will be in accordance with applicable law. The User acknowledges that Find My Venue does not endorse any User Content on the Platform and is not responsible or liable for any User Content. Find My Venue reserves the right to disable access to the User Content on the Platform. The User agrees that all User Content posted on the Platform at all times:
                        </p>

                        <ul className='list-disc pl-6 mb-4'>
                            <li className='mb-2'>
                                Is in accordance with the Terms and applicable law.
                            </li>
                            <li className='mb-2'>
                                Is fair, genuine, honest, and accurately represents the User’s experience.
                            </li>
                            <li className='mb-2'>
                                Does not contain any content that is threatening, offensive, spiteful, obscene, defamatory or otherwise unlawful in nature.
                            </li>
                            <li className='mb-2'>
                                Does not infringe any third party rights, including without limitation third party copyrights, other intellectual property rights, and/or rights of privacy.
                            </li>
                            <li className='mb-2'>
                                Is not construed as an advertisement for the User or for any third party’s products, services or businesses.
                            </li>
                        </ul>

                        <p className='mb-4'>
                            In case a third party appears in a User Content, the User confirms that the User has obtained prior consent of such third party before uploading or posting such User Content. The User further agrees that the User shall indemnify Find My Venue against any and all claims raised by such third party with respect to the User Content.
                        </p>

                        <p className='mb-4'>
                            The User hereby grants Find My Venue a perpetual, non-revocable, worldwide, royalty-free, and sub-licensable right and license to use, copy, distribute, display, publish, transmit, make available, reproduce, modify, adapt the User Content and create derivative works of the User Content in any manner as deemed appropriate by Find My Venue in any form including but not limited to print, broadcast, online, and across any and all platforms owned by Find My Venue, and on any social media channels including but not limited to Instagram, Facebook, TikTok, and Twitter.
                        </p>

                        <p className='mb-4'>
                            The User represents and warrants that the User owns or otherwise controls all of the rights to the User Content that the User posts or that the User otherwise provides on or through the Platform; and that, as at the date that the User Content is posted or submitted on the Platform: (a) the User Content is accurate; (b) use of the User Content does not breach these Terms; and (c) such User Content is lawful.
                        </p>

                        <p className='mb-4'>
                            The User further represents and warrants that while posting any User Content on the Platform the User shall not use any offensive, libelous, derogatory, hateful, or racially or ethnically objectionable language.
                        </p>

                        <p className='mb-4 font-bold'>
                            BOOKING CHANGES, CANCELLATIONS AND REFUND
                        </p>
                        <p className='mb-4'>
                            The cancellation procedures, penalties, and refund policies for bookings vary depending on the policies of the applicable Venue Operator. Any such cancellation procedures, penalties, and refund policies are those of the Venue Operator and not of Find My Venue, and Find My Venue has no responsibility or liability in this regard. The User agrees that he/she will address any complaints, questions, requests for re-scheduling or cancellation of the booking directly with the Venue Operator. Find My Venue is not responsible for (a) resolving any disputes between Users and Venue Operators, and/or (b) processing any changes to or cancellations of bookings, and disclaims any and all liability in this regard.
                        </p>

                        <p className='mb-4 font-bold'>
                            AGREEMENT TO RECEIVE COMMUNICATION
                        </p>
                        <p className='mb-4'>
                            The User hereby by way of accepting these Terms consents to the receipt of communication from Find My Venue by way of Short Message Service (SMS) messages, WhatsApp messages, e-mails, promotional, marketing calls, and newsletters. The communications could relate to the User’s registration and/or transactions that the User carries out through the Platform and promotions that are undertaken by Find My Venue, Services from Find My Venue and its third-party partners. The User also consents to receiving communications from Venue Operators on the Platform as a result of User Enquiries made by the User.
                        </p>

                        <p className='mb-4 font-bold'>
                            LINKS TO THIRD PARTY WEBSITES
                        </p>
                        <p className='mb-4'>
                            The Platform may contain links and interactive functionality interacting with the websites of third parties for the purpose of marketing and promotions. Find My Venue is not responsible for and has no liability for the functionality, actions, inactions, privacy settings, privacy policies, terms, or content of any such websites. Before enabling any sharing functions to communicate with any such website or otherwise visiting any such website, Find My Venue strongly recommends that the User reviews and understands the terms and conditions, privacy policies, settings, and information-sharing functions of each such third-party website.
                        </p>

                        <p className='mb-4 font-bold'>
                            LIMITATION OF LIABILITY
                        </p>
                        <p className='mb-4'>
                            The User hereby acknowledges that Find My Venue shall not be held liable to the User for any special, consequential, incidental, and exemplary or punitive damages, or loss of profit or revenues.
                        </p>
                        <p className='mb-4'>
                            Find My Venue shall also not be liable under any circumstance for damages arising out or related in any way to (a) any errors, mistakes, omissions or inaccuracies of content; (b) any unauthorized access to or use of the secure servers and/or any and all personal and/or other user information stored therein; (c) any interruption or cessation of transmission to or from the Services on the Platform or the failure of the Services to remain operational for any period of time; (d) any bugs, viruses, trojan horses, or the like, which may be transmitted to or through the Platform by any third party; (e) any loss of the User’s data arising from any use of or inability to use any parts of the Platform; (f) the User’s failure to keep Login Credentials or Account details secure and confidential; (g) non-availability of the Services in certain geographical areas; and/or (h) for any loss or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the Platform, whether based on warranty, contract, tort, or any other legal theory, and whether or not Find My Venue is advised of the possibility of such damages.
                        </p>
                        <p className='mb-4'>
                            Find My Venue, its employees, agents, officers, and directors shall not be liable under any circumstances for any damages arising out of or in any way related to any acts or omissions of a Venue Operator or failure of the Venue Operator in performing and/or delivering any or all of its services to the User.
                        </p>
                        <p className='mb-4'>
                            To the extent permitted by applicable law, Find My Venue, its employees, agents, officers, and directors shall not be liable under any circumstances for any damages arising out of or in any way related to Services and/or information offered or provided on the Platform or by third-party vendors.
                        </p>

                        <p className='mb-4 font-bold'>
                            DISCLAIMER OF WARRANTIES
                        </p>
                        <p className='mb-4'>
                            THE PLATFORM AND ALL INFORMATION, CONTENT, MATERIALS, AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO THE USER THROUGH THE PLATFORM (COLLECTIVELY, THE "CONTENTS") ARE PROVIDED BY FIND MY VENUE ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. FIND MY VENUE MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE PLATFORM, THE ACCURACY OR COMPLETENESS OF THE CONTENTS AND THE ACCURACY OF THE INFORMATION, INCLUDING BUT NOT LIMITED TO, THE LISTINGS OR ANY VENUES WILL MEET THE USER’S REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. FIND MY VENUE MAKES NO WARRANTY REGARDING THE QUALITY OF ANY LISTINGS, VENUES, OR ANY SERVICES OR WEBSITE CONTENT. FIND MY VENUE SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO THE USER’S COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENT OR INFORMATION. THE USER EXPRESSLY AGREES THAT THE USE OF THE PLATFORM IS AT THE USER’S SOLE RISK. FIND MY VENUE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE PLATFORM OR THE CONTENTS INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. TO THE FULL EXTENT PERMITTED BY LAW, FIND MY VENUE DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE PLATFORM AND ITS CONTENTS, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE OR USE.
                        </p>
                        <p className='mb-4'>
                            THE VENUE OPERATOR AND THE USER ARE SOLELY RESPONSIBLE FOR ALL USER ENQUIRIES, COMMUNICATIONS AND INTERACTIONS ON THE PLATFORM OR THROUGH ANY OTHER MEANS. THE USER UNDERSTANDS THAT FIND MY VENUE DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF VENUE OPERATORS OF THE SERVICES OR REVIEW OR VISIT ANY VENUES. BOTH THE VENUE OPERATOR AND USER AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH EACH OTHER AND WITH OTHER PERSONS WITH WHOM THEY COMMUNICATE OR INTERACT WITH AS A RESULT OF THE USE OF SERVICES.
                        </p>

                        <p className='mb-4 font-bold'>
                            INDEMNIFICATION
                        </p>
                        <p className='mb-4'>
                            The User shall indemnify and hold harmless Find My Venue, its affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any and all claims or demands, or actions (including reasonable attorneys' fees), made by any Venue Operator or third party or penalty imposed due to or arising out of (a) the User’s breach of these Terms, Privacy Policy and other policies, or (b) the User’s violation of any law, rules or regulations or the rights (including infringement of any intellectual property rights) of a Venue Operator or third party, or (c) any claim from a Venue Operator arising from any acts or omissions of the User.
                        </p>

                        <p className='mb-4 font-bold'>
                            CONTENT AND INTELLECTUAL PROPERTY RIGHTS
                        </p>
                        <p className='mb-4'>
                            Other than (a) User Content, and (b) the trademarks, logos, and service marks displayed on the Platform that are the property of Venue Operators and other third parties, Find My Venue solely and exclusively owns copyrights, trademarks, service marks, logos, trade names, and other intellectual and proprietary rights associated with Find My Venue’s services provided on the Platform and Contents (“Platform Content”) and is protected under applicable law.
                        </p>
                        <p className='mb-4'>
                            Through the User’s use of the Platform, by no means are any rights impliedly or expressly granted to the User in respect of such Platform Content. Find My Venue reserves the right to change or modify the Services and/or the Platform Content (as the case may be) from time to time at its sole discretion.
                        </p>
                        <p className='mb-4'>
                            The User hereby acknowledges that the Platform Content and the Services constitute original works and have been developed, compiled, prepared, revised, selected, and arranged by Find My Venue and others through the application of methods and standards of judgment developed and applied through the expenditure of substantial time, effort, and money and constitutes valuable intellectual property of Find My Venue and such others. The User thereby agrees to protect the proprietary rights of Find My Venue and such others during and after the term of these Terms. The User may not selectively download portions of the Platform without retaining the copyright notices. The User may download authorized material from the Platform only for the User’s own personal use and for no commercial purposes whatsoever.
                        </p>
                        <p className='mb-4'>
                            The User shall use the Platform strictly in accordance with these Terms, and shall not, directly or indirectly, (a) decompile, disassemble, reverse engineer, or attempt to derive the source code of, or in any manner decrypt, the Platform; (b) make any modification, adaptation or improvement, enhancement, translation or derivative work from the Platform; (c) violate any applicable laws, rules or regulations in connection with his/her access or use of the Platform, including his/her use of any Platform Content; (d) remove or obscure any proprietary notice (including any notices of copyright or trademark) forming a part of the Platform; (e) use the Platform for any commercial or revenue generation endeavors, or other purposes for which it is not designed or intended; (f) distribute or transmit the Platform or other services; (g) use the Platform for data mining, scraping, crawling, redirecting, and/or for any purpose not in accordance with these Terms; (h) use the Platform for creating a service or software that is directly or indirectly, competitive with the Platform or any Services; or (i) derive any confidential information, processes, data or algorithms from the Platform.
                        </p>
                        <p className='mb-4'>
                            Any infringement shall lead to appropriate legal proceedings against the User at appropriate forum for seeking all available remedies under applicable laws of the country.
                        </p>

                        <p className='mb-4 font-bold'>
                            TERMINATION OF ACCESS TO SERVICES
                        </p>
                        <p className='mb-4'>
                            The Account can be terminated at any time by:
                        </p>
                        <ul className='list-disc pl-6 mb-4'>
                            <li className='mb-2'>
                                The User, by requesting for such termination/deactivation of his/her Account on the Platform; or by
                            </li>
                            <li className='mb-2'>
                                Find My Venue, in its sole discretion, for no reason or any reason including the User’s violation of these Terms or lack of use of Services.
                            </li>
                        </ul>

                        <p className='mb-4'>
                            The User acknowledges that the termination of Services may come into effect without any prior notice, and Find My Venue shall thereafter, immediately deactivate or delete the Account and all related information and/or bar any further access to the Account or the Services, subject to applicable law. Further, the User agrees that Find My Venue shall not be liable for any discontinuation or termination of services by any Venue Operator or third party.
                        </p>
                        <p className='mb-4'>
                            The disclaimer of warranties, the limitation of liability, and the governing law provisions shall survive any termination of these Terms.
                        </p>

                        <p className='mb-4 font-bold'>
                            PRIVACY POLICY
                        </p>
                        <p className='mb-4'>
                            The personal information/data provided by the User to Find My Venue during the course of usage of the Platform will be treated as strictly confidential and in accordance with the Privacy Policy and applicable laws and regulations. If the User objects to his/her information being transferred or used, the User is advised not to use the Platform.
                        </p>

                        <p className='mb-4 font-bold'>
                            SEVERABILITY AND WAIVER
                        </p>
                        <p className='mb-4'>
                            These Terms, the Privacy Policy and other referenced material herein or on the Platform, are the entire agreement between the User and Find My Venue with respect to the Services offered on the Platform and shall govern the future relationship and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between the User and Find My Venue with respect thereto. If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.
                        </p>

                        <p className='mb-4 font-bold'>
                            ASSIGNMENT
                        </p>
                        <p className='mb-4'>
                            It is expressly agreed by the parties that Find My Venue may assign all or part of its rights or duties under these Terms without such assignment being considered a change to these Terms and without notice to the User, except to the extent provided by law.
                        </p>

                        <p className='mb-4 font-bold'>
                            GOVERNING LAW & DISPUTE RESOLUTION
                        </p>
                        <p className='mb-4'>
                            These Terms shall be governed by and interpreted and construed in accordance with the laws of the United Arab Emirates. The place of jurisdiction shall exclusively be in Dubai, United Arab Emirates. In the event of any dispute arising out of these Terms the same shall be settled by a binding arbitration under the Arbitration Rules of the Dubai International Arbitration Centre, which Rules are deemed to be incorporated by reference into this clause. The arbitration proceedings shall be conducted by a sole arbitrator, appointed jointly by both parties. The venue and seat of arbitration shall be Dubai, United Arab Emirates. The language to be used in the arbitration shall be English.
                        </p>

                        <p className='mb-4 font-bold'>
                            HOW TO CONTACT US
                        </p>
                        <p className='mb-4'>
                            For registering your concerns, complaints, and grievances or if the User has questions or concerns about these Terms, the User may contact the designated officer of Find My Venue at the following email address: <a href='mailto:support@findmyvenue.com' className='text-blue-500'>support@findmyvenue.com</a>, or by calling on the following number: +971-4-3380909
                        </p>


                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsOfUse;