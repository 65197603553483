import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";
import Favzero from "./Favzero";
import Timespentzero from "./Timespentzero";
import BudgetGraph from "./BudgetGraph";
import FavGraph from "./FavGraph";
import TimeSpentGraph from "./TimeSpentGraph";

const data = [
  { month: "Mar", budget: 10, favorites: 15, time: 20 },
  { month: "Apr", budget: 15, favorites: 12, time: 18 },
  { month: "May", budget: 20, favorites: 25, time: 35 },
  { month: "Jun", budget: 80, favorites: 95, time: 75 },
  { month: "Jul", budget: 85, favorites: 65, time: 80 },
  { month: "Sept", budget: 75, favorites: 55, time: 45 },
  { month: "Oct", budget: 90, favorites: 70, time: 65 },
  { month: "Nov", budget: 70, favorites: 45, time: 40 },
  { month: "Dec", budget: 85, favorites: 35, time: 70 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-2 border border-gray-200 rounded-md shadow-sm">
        <p className="text-[10px] text-[#B0B0B0] font-medium">
          <span>22 Dec</span>
        </p>
        <div className="text-[12px] text-[#222222] flex items-center gap-1">
          {payload[0].value.toLocaleString()}
          <span className="text-[#2C2C75] text-[9px] p-1 bg-[#DFDFFF] rounded-lg ml-1">
            +3.4%
          </span>
        </div>
      </div>
    );
  }
  return null;
};

const BudgetFavTimeGraph = ({ startDate, endDate, venueName }) => {
  const renderGraph = (title, dataKey, value, subtitle) => (
    <div className="p-4 bg-[#FFFFFF] rounded-2xl">
      <div className="space-y-0 pb-2">
        <div className="flex items-baseline gap-2">
          <p className="text-[20px] md:text-[28px] font-bold">{value}</p>
          <span className="text-[14px] md:text-[16px] text-[#222222]">
            {subtitle}
          </span>
        </div>
        <p className="text-[12px] md:text-[14px] text-[#717171]">
          No. of users saved venue as favourite
        </p>
      </div>
      <div>
        <div className="h-[190px] w-full mt-3">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={data}
              margin={{ top: 5, right: 0, bottom: 5, left: -10 }} // Adjusted margins
            >
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#DFDFFF" />
                  <stop offset="95%" stopColor="#DFDFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#E5E7EB"
                horizontal={true}
              />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fill: "#717171" }}
                dy={10}
                padding={{ left: 0, right: 0 }} // Remove padding
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fill: "#717171" }}
                domain={[0, 100]}
                ticks={[0, 20, 40, 60, 80, 100]}
                width={40} // Add fixed width for Y-axis
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey={dataKey}
                stroke="#7A79FF"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorGradient)"
                strokeDasharray="3 3"
                dot={false}
                activeDot={{
                  r: 6,
                  fill: "#4F46E5",
                  stroke: "#FFFFFF",
                  strokeWidth: 2,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4  mt-4 lg:mt-7">
      <BudgetGraph
        startDate={startDate}
        endDate={endDate}
        venueName={venueName}
      />

      <FavGraph startDate={startDate} endDate={endDate} venueName={venueName} />

      {/* {renderGraph(
        "Average time spent",
        "time",
        "12 mins",
        "Average time spent"
      )} */}
      <TimeSpentGraph
        startDate={startDate}
        endDate={endDate}
        venueName={venueName}
      />
    </div>
  );
};

export default BudgetFavTimeGraph;
