import React, { useState, useEffect } from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const formatYAxis = (value) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`;
  }
  return value;
};
function FunnelGraph({ data1, startDate, endDate }) {
  let [totalImpressions, setTotalImpressions] = React.useState(0);
  let [totalPageVisits, setTotalPageVisits] = React.useState(0);
  let [totalEnquiries, setTotalEnquiries] = React.useState(0);
  let data = [
    {
      name: "Venues Impressions",
      value: totalImpressions,
    },
    {
      name: "Page Visited",
      value: totalPageVisits,
    },
    {
      name: "Enquiries",
      value: totalEnquiries,
    },
  ];

  const totalVenuesData = (data) => {
    const totals = data?.reduce(
      (acc, venue) => {
        acc.impressions +=
          startDate || endDate ? venue?.impressionsByDate : venue?.impressions;
        acc.pageVisits +=
          startDate || endDate ? venue?.pageVisitsByDate : venue?.pageVisits;
        acc.enquiries += venue.enquiries;
        return acc;
      },
      { impressions: 0, pageVisits: 0, enquiries: 0 }
    );

    setTotalImpressions(totals?.impressions);
    setTotalPageVisits(totals?.pageVisits);
    setTotalEnquiries(totals?.enquiries);
  };

  useEffect(() => {
    totalVenuesData(data1 || []);
  }, [data1]);

  useEffect(() => {
    // console.log("Data1", data);
    // console.log(totalImpressions, totalPageVisits, totalEnquiries);
  }, [totalImpressions, totalPageVisits, totalEnquiries]);
  const truncate = (str, maxLength) =>
    str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  return (
    <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px]">
      <div className="mb-8">
        <p className="text-[14px] font-medium">Performance Funnel Report</p>
        <p className="text-[12px] text-[#00000005]/60 ">
          Response time from your team
        </p>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={data}
          margin={{ top: 5, right: 0, bottom: 5, left: -34 }}
        >
          <XAxis
            dataKey="name"
            tickLine={false}
            tick={{
              fontSize: window.innerWidth < 640 ? 10 : 12,
              fill: "#717171",
            }}
            tickFormatter={(name) =>
              window.innerWidth < 640 ? truncate(name, 5) : name
            }
          />
          <YAxis
            type="number"
            domain={[0, "dataMax"]}
            tick={{
              fontSize: window.innerWidth < 640 ? 10 : 12,
              fill: "#717171",
            }}
            tickFormatter={formatYAxis}
            tickLine={false}
          />
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            stroke="none"
            fill="rgba(73, 144, 251, 0.06)"
            // fillOpacity={0.3}
          />
          <Bar
            dataKey="value"
            fill="#4990FB"
            barSize={window.innerWidth < 640 ? 20 : 80}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default FunnelGraph;
