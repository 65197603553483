import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { base_URL } from "../../config/config";
import axios from "axios";
import toast from "react-hot-toast";

function EmailVerify({ onClose, personalDetails, handlePersonalSave }) {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verifying, setVerifying] = useState(true);

  useEffect(() => {
    const verifyEmailToken = async () => {
      try {
        // Get pending changes from localStorage
        const pendingChanges = JSON.parse(
          localStorage.getItem("pendingProfileChanges")
        );

        const response = await axios.post(
          `${base_URL}/api/dashboard/verify-email-token`,
          {
            token,
            ...pendingChanges,
          }
        );
        if (response.data.success) {
          // Clear pending changes after successful verification
          localStorage.removeItem("pendingProfileChanges");
          // Store verification status in localStorage (so to keep track (later we can show toaster on settigns dashboard))
          localStorage.setItem("emailJustVerified", "true");
          navigate("/settings-dashboard");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || "Verification failed");
        navigate("/settings-dashboard");
      } finally {
        setVerifying(false);
      }
    };

    if (token) {
      verifyEmailToken();
    }
  }, [token, navigate]);

  return (
    <div className="fixed inset-0 font-outfit flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg md:w-[600px]">
        {/* Close Button */}
        <div className="flex justify-end">
          <img
            onClick={onClose}
            src="/cross.svg"
            alt="close"
            className="cursor-pointer"
          />
        </div>
        <div className="text-center mt-4">
          <h2 className="text-[#1D1F2C] text-[32px] font-semibold mt-4">
            Verify your email
          </h2>

          <p className="mt-[32px] text-[#222222] font-normal text-[20px]">
            We&apos;ve sent an email to{" "}
            <strong>{personalDetails?.email}</strong> to verify <br /> your
            email address and activate your account.
          </p>

          <p className="mt-[40px] text-[14px] text-[#5E5E5E]">
            If you haven&apos;t received an email after a while.{" "}
            <span
              onClick={handlePersonalSave}
              className="text-[#FE4747] cursor-pointer active:opacity-55"
            >
              Click here to try again
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default EmailVerify;
