/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { eventTypes } from "./event-type-data";
import p1 from "../../assets2/icons/venuPre1.svg";
import p2 from "../../assets2/icons/venuPre2.svg";
import p3 from "../../assets2/icons/catering/Catering.svg";
import p4 from "../../assets2/icons/catering/external.svg";
import p5 from "../../assets2/icons/catering/nAllowed.png";
import p6 from "../../assets2/icons/catering/vector.svg";
import Tooltip from "./Tooltip";

const OverviewSection = ({ data, locationDetails }) => {
  // Overview details pulled dynamically from data
  const getIconForEventType = (title) => {
    const eventType = eventTypes.find((type) => type.title === title);
    return eventType ? eventType.icon : null; // Return null if no match is found
  };
  const overviewDetails = [
    {
      icon: (
        <img
          className=""
          alt="data?.additionalDetails?.venueHire"
          src={getIconForEventType(data?.additionalDetails?.venueHire)}
        />
      ),
      label: "Private space",
      value: data?.additionalDetails?.venueHire,
    },
    {
      icon: <img src={p2} className="w-8 h-8" alt="standing" />,
      label: "Sitting",
      value: `Upto ${data?.capacity?.sitting || "N/A"} Sitting`,
    },
    {
      icon: <img src={p1} className="w-8 h-8" alt="standing" />,
      label: "Standing",
      value: `Upto ${data?.capacity?.standing || "N/A"} Standing`,
    },
    {
      icon: <img src={p3} className="w-8 h-8" alt="Offers catering" />,
      label: "Offers catering",
      value: "Offers catering",
    },
    {
      icon:
        data?.cateringAndDrinks?.externalCatering === false ? (
          <div className="h-[38px] w-auto relative">
            <img
              src={p5}
              className=" w-full h-full bg-cover"
              alt="Does not offer catering"
            />

            <img
              src={p6}
              className="w-ful h-full absolute top-0 left-0"
              alt="Does not offer catering"
            />
          </div>
        ) : (
          <img src={p4} className="w-8 h-8" alt="Outside catering" />
        ),
      label: "Outside catering",
      value: "Outside catering allowed",
    },
  ];
  return (
    <div className="w-full mx-auto">
      <h3 className="text-2xl font-bold py-4">Overview</h3>

      <div className="grid md:grid-cols-5 grid-cols-2 justify-between gap-4 mb-4">
        {overviewDetails?.map((detail, index) => (
          <div
            key={index}
            className="md:h-[150px] h-[100px] w-[85%]  relative flex flex-col md:px-8 px-2 md:py-5 py-2 md:gap-y-2 gap-y-1 md:text-center text-left  text-black items-center bg-[#F7F7F7]  rounded-lg shadow-md justify-around"
          >
            <div>{detail.icon}</div>
            <div>
              {detail.value === "Outside catering allowed" ? (
                <p
                  className={`text-[14px] lg:text-[20px] md:text-center text-left ${
                    data?.cateringAndDrinks?.externalCatering
                      ? "text-black"
                      : "text-[#717171]"
                  }`}
                >
                  Outside Catering
                </p>
              ) : (
                <p className="text-[14px] lg:text-[20px] md:text-center text-left text-black">
                  {detail.value}
                </p>
              )}
              {/* <p
                className={`text-[14px] lg:text-[20px] md:text-center text-left ${
                  detail.value == "Outside catering allowed"
                    ? "text-black"
                    : "text-[#717171]"
                }`}
              >
                {detail.value == "Outside catering allowed"
                  ? "Outside catering"
                  : detail?.value}
              </p> */}
            </div>
            {detail?.label === "Private space" && (
              <div className="md:absolute hidden top-2 right-6">
                {detail?.value ? (
                  <Tooltip
                    text={
                      detail?.value == "Private"
                        ? "Private hire is a space within venue that is exclusively reserved for your event"
                        : detail?.value == "Whole venue"
                        ? "The entire venue is exclusively available for your event."
                        : detail?.value == "Semi-private"
                        ? "Semi-private hire involves booking a section of a venue that is partially secluded or separated, allowing for some privacy while other events or guests may also be present."
                        : detail?.value == "Shared space"
                        ? "A non-exclusive, common area with open access for everyone."
                        : ""
                    }
                  />
                ) : null}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OverviewSection;
