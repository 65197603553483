export const eventType = [
  { name: "New Year’s Eve Party", value: "New-Year’s-Eve-Party" },
  { name: "Baby Shower", value: "babyShower" },
  { name: "Kids Birthday", value: "kidsBirthday" },
  { name: "Asian", value: "asian" },
  { name: "Office Party", value: "officeParty" },
  { name: "Wedding", value: "wedding" },
  { name: "Baby Shower", value: "babyShower" },
  { name: "Kids Birthday", value: "kidsBirthday" },
  { name: "Asian", value: "asian" },
  { name: "Office Party", value: "officeParty" },
  { name: "Wedding", value: "wedding" },
];

export const venueType = [
  { name: "Adventure venue", value: "Adventure venue" },
  { name: "Amphitheatre", value: "Amphitheatre" },
  { name: "Aquarium", value: "Aquarium" },
  { name: "Art Gallery", value: "Art Gallery" },
  { name: "Art studio", value: "Art studio" },
  { name: "Auditorium", value: "Auditorium" },
  { name: "Ballroom", value: "Ballroom" },
  { name: "Bar", value: "Bar" },
  { name: "Beach", value: "Beach" },
  { name: "Beach club", value: "Beach club" },
  { name: "Blank canvas", value: "Blank canvas" },
  { name: "Boats & Yachts", value: "Boats & Yachts" },
  { name: "Cafes", value: "Cafes" },
  { name: "Church", value: "Church" },
  { name: "Cinema", value: "Cinema" },
  { name: "Co-working space", value: "Co-working space" },
  { name: "Community center", value: "Community center" },
  { name: "Concert hall", value: "Concert hall" },
  { name: "Conference center", value: "Conference center" },
  { name: "Courtyard", value: "Courtyard" },
  { name: "Dance Studio", value: "Dance Studio" },
  { name: "Desert", value: "Desert" },
  { name: "Entertainment venue", value: "Entertainment venue" },
  { name: "Escape Rooms", value: "Escape Rooms" },
  { name: "EXPO", value: "EXPO" },
  { name: "Farm", value: "Farm" },
  { name: "Fine dining restaurant", value: "Fine dining restaurant" },
  { name: "Garden", value: "Garden" },
  { name: "Golf courses", value: "Golf courses" },
  { name: "Helipad", value: "Helipad" },
  { name: "Karaoke bar", value: "Karaoke bar" },
  { name: "Kids play area", value: "Kids play area" },
  { name: "Lawn", value: "Lawn" },
  { name: "Limousine", value: "Limousine" },
  { name: "Meeting Center", value: "Meeting Center" },
  { name: "Meeting room", value: "Meeting room" },
  { name: "Museum", value: "Museum" },
  { name: "Nightclub", value: "Nightclub" },
  { name: "Park", value: "Park" },
  { name: "Party bus", value: "Party bus" },
  { name: "Patio", value: "Patio" },
  { name: "Photo studio", value: "Photo studio" },
  { name: "Poolside", value: "Poolside" },
  { name: "Recording studio", value: "Recording studio" },
  { name: "Restaurant", value: "Restaurant" },
  { name: "Restaurant/Bar", value: "Restaurant/Bar" },
  { name: "Restaurant/Lounge &Bar", value: "Restaurant/Lounge &Bar" },
  { name: "Rooftop", value: "Rooftop" },
  { name: "Rooftop restaurant", value: "Rooftop restaurant" },
  { name: "Social clubs and lounges", value: "Social clubs and lounges" },
  { name: "Spa", value: "Spa" },
  { name: "Sports club", value: "Sports club" },
  { name: "Sports venue", value: "Sports venue" },
  { name: "Stadium", value: "Stadium" },
  { name: "Streaming studio", value: "Streaming studio" },
  { name: "Supper club", value: "Supper club" },
  { name: "Temple", value: "Temple" },
  { name: "Terrace", value: "Terrace" },
  { name: "Theme Park", value: "Theme Park" },
  { name: "Therapy room", value: "Therapy room" },
  { name: "Training room", value: "Training room" },
  { name: "TV film studio", value: "TV film studio" },
  { name: "Warehouse", value: "Warehouse" },
  { name: "Yoga Studio", value: "Yoga Studio" },
  { name: "Zoo", value: "Zoo" },
];

export const cities = [
  { label: "Abu Dhabi", value: "Abu Dhabi" },
  { label: "Ajman", value: "Ajman" },
  { label: "Dubai", value: "Dubai" },
  { label: "Fujairah", value: "Fujairah" },
  { label: "Ras Al Khaimah", value: "Ras Al Khaimah" },
  { label: "Sharjah", value: "Sharjah" },
  { label: "Umm Al Quwain", value: "Umm Al Quwain" },
];

export const area = [
  { name: "Marina (50)", value: "marina(50)" },
  { name: "Downtown (10)", value: "downtown(10)" },
  { name: "Miracle Garden (20)", value: "miracleGarden(20)" },
  { name: "Discovery Garden (30)", value: "discoveryGarden(30)" },
  { name: "Palm Jumeirah (40)", value: "palmJumeirah(40)" },
];

export const venueHire = [
  { name: "Private", value: "Private" },
  { name: "Semi-Private", value: "Semi-private" },
  { name: "Shared space", value: "Shared space" },
  { name: "Whole Venue", value: "Whole venue" },
];

export const Catering = [
  { name: "In-house catering", value: "venueProvideInHouseCatering" },
  { name: "External catering", value: "externalCatering" },
  {
    name: "Alcohol provided by venue ",
    value: "isVenueAlcohlic",
  },
  {
    name: "Bring your own alcohol (corkage fee applicable)",
    value: "bringYourOwnAlcoholAllowed",
  },
];

export const venueSettings = [
  { name: "Indoor", value: "Indoor" },
  { name: "Outdoor", value: "Outdoor" },
  { name: "Indoor + Outdoor", value: "Indoor + Outdoor" },
];

export const Menu = [
  { name: "Arabic", value: "Arabic" },
  { name: "Asian", value: "Asian" },
  { name: "American", value: "American" },
  { name: "Barbeque", value: "Barbeque" },
  { name: "Brazilian", value: "Brazilian" },
  { name: "Chinese", value: "Chinese" },
  { name: "Contemporary", value: "Contemporary" },
  { name: "European", value: "European" },
  { name: "French", value: "French" },
  { name: "Fusion", value: "Fusion" },
  { name: "Indian", value: "Indian" },
  { name: "International", value: "International" },
  { name: "Italian", value: "Italian" },
  { name: "Japanese", value: "Japanese" },
  { name: "Korean", value: "Korean" },
  { name: "Kosher", value: "Kosher" },
  { name: "Lebanese", value: "Lebanese" },
  { name: "Mediterranean", value: "Mediterranean" },
  { name: "Middle Eastern", value: "Middle Eastern" },
  { name: "Mexican", value: "Mexican" },
  { name: "Persian", value: "Persian" },
  { name: "Peruvian", value: "Peruvian" },
  { name: "Thai", value: "Thai" },
  { name: "Turkish", value: "Turkish" },
  { name: "Vegetarian", value: "Vegetarian" },
];

export const Amenities = [
  { name: "Air conditioning", value: "Air conditioning" },
  {
    name: "Conference call facilities",
    value: "Conference call facilities",
  },
  { name: "Flatscreen TV", value: "Flatscreen TV" },
  { name: "Flipchart", value: "Flipchart" },
  { name: "Natural Light", value: "Natural Light" },
  { name: "Podium", value: "Podium" },
  { name: "Storage space", value: "Storage space" },
  { name: "Wifi", value: "Wifi" },
  { name: "Whiteboard", value: "Whiteboard" },
];

export const eventTypes = {
  "Conference/Seminars": [
    {
      name: "Annual General meeting",
      value: "Annual General meeting",
    },
    { name: "Auctions", value: "Auctions" },
    { name: "Board Meetings", value: "Board Meetings" },
    { name: "Breakout", value: "Breakout" },
    { name: "Conference", value: "Conference" },
    { name: "Conventions", value: "Conventions" },
    { name: "Executive Summits", value: "Executive Summits" },
    { name: "Exhibitions", value: "Exhibitions" },
    { name: "Meetings", value: "Meetings" },
    { name: "Networking", value: "Networking" },
    { name: "Presentation", value: "Presentation" },
    { name: "Press Conference", value: "Press Conference" },
    { name: "Product Launch", value: "Product Launch" },
    { name: "Seminars", value: "Seminars" },
    { name: "Symposium", value: "Symposium" },
    { name: "Trade Shows", value: "Trade Shows" },
    { name: "Training", value: "Training" },
    { name: "Workshops", value: "Workshops" },
  ],

  "Corporate Events": [
    { name: "Activity Day", value: "Activity Day" },
    { name: "Award Ceremony", value: "Award Ceremony" },
    { name: "Away day", value: "Away day" },
    { name: "Brainstorm", value: "Brainstorm" },
    { name: "Charity event", value: "Charity event" },
    { name: "Corporate Gala dinner", value: "Corporate Gala dinner" },
    { name: "Corporate Reception", value: "Corporate Reception" },
    { name: "Corporate Retreat", value: "Corporate Retreat" },
    { name: "FAM Trip", value: "FAM Trip" },
    { name: "Fundraiser", value: "Fundraiser" },
    { name: "Incentive trip", value: "Incentive trip" },
    { name: "Office Party", value: "Office Party" },
    { name: "Outdoor Activity", value: "Outdoor Activity" },
    { name: "Pop Up event", value: "Pop Up event" },
    { name: "PR events", value: "PR events" },
    { name: "Promotional Events", value: "Promotional Events" },
    { name: "Staff Party", value: "Staff Party" },
    { name: "Summer Party", value: "Summer Party" },
    { name: "Team Building", value: "Team Building" },
    { name: "Training Program", value: "Trainging Program" },
  ],

  Weddings: [
    { name: "Wedding", value: "Wedding" },
    { name: "Arabic Wedding", value: "Arabic Wedding" },
    { name: "Christian Wedding", value: "Christian Wedding" },
    { name: "Church Wedding", value: "Church Wedding" },
    { name: "Civil Partnership", value: "Civil Partnership" },
    { name: "Civil Wedding", value: "Civil Wedding" },
    { name: "Garden Wedding", value: "Garden Wedding" },
    { name: "Hindu Wedding", value: "Hindu Wedding" },
    { name: "Indian Wedding", value: "Indian Wedding" },
    { name: "Marquee Wedding", value: "Marquee Wedding" },
    { name: "Outdoor Wedding", value: "Outdoor Wedding" },
    { name: "Unique Wedding", value: "Unique Wedding" },
    { name: "Wedding Ceremony", value: "Wedding Ceremony" },
    { name: "Wedding Reception", value: "Wedding Reception" },
  ],

  Dining: [
    { name: "BBQ", value: "BBQ" },
    { name: "Anniversary Party", value: "Anniversary Party" },
    { name: "Cocktail Masterclass", value: "Cocktail Masterclass" },
    { name: "Cocktail Reception", value: "Cocktail Reception" },
    { name: "Communion", value: "Communion" },
    { name: "Condolences", value: "Condolences" },
    { name: "Drinks Reception", value: "Drinks Reception" },
    { name: "Engagement", value: "Engagement" },
    { name: "Farewell Party", value: "Farewell Party" },
    { name: "Funeral reception", value: "Funeral reception" },
    { name: "Graduation Party", value: "Graduation Party" },
    { name: "New Years Eve Party", value: "New Years Eve Party" },
    { name: "Party", value: "Party" },
    { name: "Private Party", value: "Private Party" },
    { name: "Retirement Party", value: "Retirement Party" },
    { name: "Reunions", value: "Reunions" },
    { name: "Stag Party", value: "Stag Party" },
    { name: "Wine Tasting", value: "Wine Tasting" },
  ],

  "Milestone Birthdays": [
    { name: "16th Birthday Party", value: "16th Birthday Party" },
    { name: "18th Birthday Party", value: "18th Birthday Party" },
    { name: "21st Birthday Party", value: "21st Birthday Party" },
    { name: "25th Birthday Party", value: "25th Birthday Party" },
    { name: "30th Birthday Party", value: "30th Birthday Party" },
    { name: "40th Birthday Party", value: "40th Birthday Party" },
    { name: "50th Birthday Party", value: "50th Birthday Party" },
    { name: "Birthday Party", value: "Birthday Party" },
  ],

  "Tea Party/Showers": [
    { name: "Afternoon Tea", value: "Afternoon Tea" },
    { name: "Baby Christening", value: "Baby Christening" },
    { name: "Baby Shower", value: "Baby Shower" },
    {
      name: "Bachelorette Party/Bachelor",
      value: "Bachelorette Party/Bachelor",
    },
    { name: "Bridal Shower", value: "Bridal Shower" },
    { name: "High Tea", value: "High Tea" },
  ],

  "Teen/Children": [
    {
      name: "Children Birthday Party",
      value: "Children Birthday Party",
    },
    { name: "Kids Party", value: "Kids Party" },
    { name: "Kids Birthday Party", value: "Kids Birthday Party" },
    { name: "Teen Party", value: "Teen Party" },
  ],

  "Special Big Events": [
    { name: "Gender Reveals", value: "Gender Reveals" },
    { name: "Proposals", value: "Proposals" },
  ],

  "Cultural Celeberations": [
    { name: "Brunches", value: "Brunches" },
    { name: "Christmas dinner", value: "Christmas dinner" },
    { name: "Christmas Party", value: "Christmas Party" },
    { name: "Diwali", value: "Diwali" },
    { name: "Eid", value: "Eid" },
    { name: "Halloween Party", value: "Halloween Party" },
    { name: "Holiday Party", value: "Holiday Party" },
    { name: "Iftar", value: "Iftar" },
    { name: "Ladies night", value: "Ladies night" },
    { name: "Ramadan", value: "Ramadan" },
    { name: "Suhoor", value: "Suhoor" },
    { name: "Xmas Party", value: "Xmas Party" },
  ],

  "Filming, Photography & Media": [
    {
      name: "Filming/Video Recording",
      value: "Filming/Video Recording",
    },
    { name: "Photoshoot", value: "Photoshoot" },
    { name: "Podcast Recording", value: "Podcast Recording" },
  ],

  Cultural: [
    { name: "Book Launch", value: "Book Launch" },
    { name: "Film Festival", value: "Film Festival" },
    { name: "Music Festival", value: "Music Festival" },
  ],

  "Art Spaces": [
    { name: "Art Exhibitions", value: "Art Exhibitions" },
    { name: "Pop Up Event", value: "Pop Up Event" },
  ],

  "Performances & Exhibitions": [
    { name: "Concert", value: "Concert" },
    { name: "Dance Performance", value: "Dance Performance" },
    { name: "Fashion Show", value: "Fashion Show" },
    { name: "GIG", value: "GIG" },
    { name: "Live Performance", value: "Live Performance" },
    { name: "Performing Arts", value: "Performing Arts" },
    { name: "Screening", value: "Screening" },
    { name: "Stand-up Comedy", value: "Stand-up Comedy" },
    { name: "Theatre (Drama/Plays)", value: "Theatre (Drama/Plays)" },
  ],
};

const statesAndCities1 = {
  Dubai: [
    { name: "Academic City", value: "Academic City" },
    { name: "Al Awir", value: "Al Awir" },
    { name: "Al Barsha", value: "Al Barsha" },
    { name: "Al Fahidi", value: "Al Fahidi" },
    { name: "Al Furjan", value: "Al Furjan" },
    { name: "Al Karama", value: "Al Karama" },
    { name: "Al Khawaneej", value: "Al Khawaneej" },
    { name: "Al Mamzar", value: "Al Mamzar" },
    { name: "Al Mankhool", value: "Al Mankhool" },
    { name: "Al Muteena", value: "Al Muteena" },
    { name: "Al Mizhar", value: "Al Mizhar" },
    { name: "Al Muraqqabat", value: "Al Muraqqabat" },
    { name: "Al Nahda", value: "Al Nahda" },
    { name: "Al Quoz", value: "Al Quoz" },
    { name: "Al Qusais", value: "Al Qusais" },
    { name: "Al Raffa", value: "Al Raffa" },
    { name: "Al Rigga", value: "Al Rigga" },
    { name: "Al Safa", value: "Al Safa" },
    { name: "Al Satwa", value: "Al Satwa" },
    { name: "Al Sufouh", value: "Al Sufouh" },
    { name: "Al Warqa", value: "Al Warqa" },
    { name: "Al Wasl", value: "Al Wasl" },
    { name: "Arabian Ranches", value: "Arabian Ranches" },
    { name: "Arabian Ranches 2", value: "Arabian Ranches 2" },
    { name: "Barsha Heights (Tecom)", value: "Barsha Heights (Tecom)" },
    { name: "Bluewaters Island", value: "Bluewaters Island" },
    { name: "Business Bay", value: "Business Bay" },
    { name: "Bur Dubai", value: "Bur Dubai" },
    { name: "City Walk", value: "City Walk" },
    { name: "Damac Hills", value: "Damac Hills" },
    { name: "Deira", value: "Deira" },
    { name: "Discovery Gardens", value: "Discovery Gardens" },
    { name: "Downtown Dubai", value: "Downtown Dubai" },
    { name: "Dubai Creek Harbour", value: "Dubai Creek Harbour" },
    { name: "Dubai Festival City", value: "Dubai Festival City" },
    { name: "Dubai Harbour", value: "Dubai Harbour" },
    { name: "Dubai Hills Estate", value: "Dubai Hills Estate" },
    { name: "Dubai Industrial City", value: "Dubai Industrial City" },
    {
      name: "Dubai International Financial Centre (DIFC)",
      value: "Dubai International Financial Centre (DIFC)",
    },
    {
      name: "Dubai Investment Park (DIP)",
      value: "Dubai Investment Park (DIP)",
    },
    { name: "Dubai Internet City", value: "Dubai Internet City" },
    { name: "Dubai Media City", value: "Dubai Media City" },
    { name: "Dubai Land", value: "Dubai Land" },
    { name: "Dubai Silicon Oasis", value: "Dubai Silicon Oasis" },
    { name: "Dubai Sports City", value: "Dubai Sports City" },
    {
      name: "Dubai South (Dubai World Central)",
      value: "Dubai South (Dubai World Central)",
    },
    { name: "Dubai Marina", value: "Dubai Marina" },
    { name: "Dubai Production City", value: "Dubai Production City" },
    { name: "Emirates Hills", value: "Emirates Hills" },
    { name: "Gardens", value: "Gardens" },
    { name: "Green Community", value: "Green Community" },
    { name: "Hatta", value: "Hatta" },
    { name: "Hor Al Anz", value: "Hor Al Anz" },
    { name: "International City", value: "International City" },
    { name: "Jebel Ali", value: "Jebel Ali" },
    {
      name: "Jebel Ali Free Zone (JAFZA)",
      value: "Jebel Ali Free Zone (JAFZA)",
    },
    { name: "Jumeirah", value: "Jumeirah" },
    { name: "Jumeirah Golf Estates", value: "Jumeirah Golf Estates" },
    { name: "Jumeirah Bay Island", value: "Jumeirah Bay Island" },
    {
      name: "Jumeirah Beach Residence (JBR)",
      value: "Jumeirah Beach Residence (JBR)",
    },
    { name: "Jumeirah Islands", value: "Jumeirah Islands" },
    {
      name: "Jumeirah Lakes Towers (JLT)",
      value: "Jumeirah Lakes Towers (JLT)",
    },
    {
      name: "Jumeirah Village Circle (JVC)",
      value: "Jumeirah Village Circle (JVC)",
    },
    {
      name: "Jumeirah Village Triangle (JVT)",
      value: "Jumeirah Village Triangle (JVT)",
    },
    { name: "Khawaneej", value: "Khawaneej" },
    { name: "Layan", value: "Layan" },
    { name: "Marsa Al Arab", value: "Marsa Al Arab" },
    { name: "Meydan", value: "Meydan" },
    { name: "Mirdif", value: "Mirdif" },
    { name: "Motor City", value: "Motor City" },
    { name: "Mudon", value: "Mudon" },
    { name: "Muhaisnah", value: "Muhaisnah" },
    { name: "Nad Al Sheba", value: "Nad Al Sheba" },
    { name: "Oud Metha", value: "Oud Metha" },
    { name: "Palm Jumeirah", value: "Palm Jumeirah" },
    { name: "Port Rashid", value: "Port Rashid" },
    { name: "Port Saeed", value: "Port Saeed" },
    { name: "Ras Al Khor", value: "Ras Al Khor" },
    { name: "Remraam", value: "Remraam" },
    { name: "Serena", value: "Serena" },
    { name: "Sheikh Zayed Road", value: "Sheikh Zayed Road" },
    { name: "Silicon Oasis", value: "Silicon Oasis" },
    { name: "Sports City", value: "Sports City" },
    { name: "The Greens", value: "The Greens" },
    { name: "The Lakes", value: "The Lakes" },
    { name: "The Meadows", value: "The Meadows" },
    { name: "The Springs", value: "The Springs" },
    { name: "The Sustainable City", value: "The Sustainable City" },
    { name: "The Views", value: "The Views" },
    { name: "The Villa", value: "The Villa" },
    { name: "Town Square", value: "Town Square" },
    { name: "Trade Centre", value: "Trade Centre" },
    { name: "Umm Al Sheif", value: "Umm Al Sheif" },
    { name: "Umm Suqeim", value: "Umm Suqeim" },
    { name: "Victory Heights", value: "Victory Heights" },
    { name: "Zabeel", value: "Zabeel" },
  ],
  "Abu Dhabi": [
    { name: "Al Khalidiyah", value: "Al Khalidiyah" },
    { name: "Al Mafraq", value: "Al Mafraq" },
    { name: "Al Markaziya", value: "Al Markaziya" },
    { name: "AbuDhabi Gate City", value: "AbuDhabi Gate City" },
    { name: "Airport Road", value: "Airport Road" },
    { name: "Al Gurm", value: "Al Gurm" },
    { name: "Al Bateen", value: "Al Bateen" },
    { name: "Al Faya", value: "Al Faya" },
    { name: "Al Danah", value: "Al Danah" },
    { name: "AL Bahia", value: "AL Bahia" },
    { name: "Al Dhafra Industrial Area", value: "Al Dhafra Industrial Area" },
    { name: "Al Manaseer", value: "Al Manaseer" },
    { name: "Al Ain (Green City)", value: "Al Ain (Green City)" },
    { name: "Al Maryah Island", value: "Al Maryah Island" },
    { name: "Al Muntazah", value: "Al Muntazah" },
    { name: "Al Mina", value: "Al Mina" },
    { name: "Al Ghadeer", value: "Al Ghadeer" },
    { name: "Al Manhal", value: "Al Manhal" },
    { name: "Al Dhafrah", value: "Al Dhafrah" },
    { name: "Al Maqtaa", value: "Al Maqtaa" },
    { name: "Al Karama", value: "Al Karama" },
    { name: "Al Falah", value: "Al Falah" },
    { name: "Al Marina", value: "Al Marina" },
    { name: "Al Muroor", value: "Al Muroor" },
    { name: "Baniyas", value: "Baniyas" },
    { name: "Al Qurm", value: "Al Qurm" },
    {
      name: "Al Zahiyah (Tourist Club Area)",
      value: "Al Zahiyah (Tourist Club Area)",
    },
    { name: "Al Raha Beach", value: "Al Raha Beach" },
    { name: "Al Seef Village", value: "Al Seef Village" },
    { name: "Al Reem Island", value: "Al Reem Island" },
    { name: "Al Mushrif", value: "Al Mushrif" },
    { name: "Madinat Khalifa", value: "Madinat Khalifa" },
    { name: "Al Rowdah", value: "Al Rowdah" },
    { name: "Al Raha Gardens", value: "Al Raha Gardens" },
    { name: "Al Wathba", value: "Al Wathba" },
    { name: "Hydra Village", value: "Hydra Village" },
    { name: "Capital Centre", value: "Capital Centre" },
    { name: "Marina Village", value: "Marina Village" },
    { name: "Al Shamkha", value: "Al Shamkha" },
    { name: "Al Wahda", value: "Al Wahda" },
    { name: "Liwa Oasis", value: "Liwa Oasis" },
    { name: "Khalifa City", value: "Khalifa City" },
    { name: "Al Raha Beach", value: "Al Raha Beach" },
    { name: "Al Raha Gardens", value: "Al Raha Gardens" },
    { name: "Al Reef", value: "Al Reef" },
    { name: "Al Reem Island", value: "Al Reem Island" },
    { name: "Al Samha", value: "Al Samha" },
    { name: "Al Shawamekh", value: "Al Shawamekh" },
    { name: "Al Wathba", value: "Al Wathba" },
    { name: "Khor Al Maqta", value: "Khor Al Maqta" },
    { name: "Bain Al Jessrain", value: "Bain Al Jessrain" },
    { name: "Baniyas", value: "Baniyas" },
    { name: "Khalifa City", value: "Khalifa City" },
    { name: "Masdar City", value: "Masdar City" },
    { name: "Mussafah", value: "Mussafah" },
    { name: "Shakhbout City", value: "Shakhbout City" },
    { name: "Yas Island", value: "Yas Island" },
  ],
  "Ras Al Khaimah": [
    { name: "Al Jazeera Al Hamra", value: "Al Jazeera Al Hamra" },
    { name: "Al Qusaidat", value: "Al Qusaidat" },
    { name: "Khatt", value: "Khatt" },
    { name: "Al Dhait", value: "Al Dhait" },
    { name: "Al Mamourah", value: "Al Mamourah" },
    { name: "Julphar", value: "Julphar" },
    { name: "Al Seer", value: "Al Seer" },
    { name: "Al Hamra Village", value: "Al Hamra Village" },
    { name: "Al Nakheel", value: "Al Nakheel" },
    { name: "Khuzam", value: "Khuzam" },
    { name: "Al Ghail", value: "Al Ghail" },
    { name: "Al Oraibi", value: "Al Oraibi" },
    { name: "Al Qurm", value: "Al Qurm" },
    { name: "Al Rams", value: "Al Rams" },
    { name: "Wadi Bih", value: "Wadi Bihi" },
    { name: "Ghalilah", value: "Ghalilah" },
    { name: "Wadi Shah", value: "Wadi Shah" },
    { name: "Mina Al Arab", value: "Mina Al Arab" },
    { name: "Al Jazirah Al Hamra", value: "Al Jazirah Al Hamra" },
    { name: "Al Mairid", value: "Al Mairid" },
    { name: "Al Ghayl", value: "Al Ghayl" },
    { name: "Sidroh", value: "Sidroh" },
    { name: "Dafan Al Khor", value: "Dafan Al Khor" },
    { name: "Digdaga", value: "Digdaga" },
    { name: "Shamal Julphar", value: "Shamal Julphar" },
    { name: "Adhen", value: "Adhen" },
    { name: "Al Wadi", value: "Al Wadi" },
    { name: "Al Riffa", value: "Al Riffa" },
    { name: "Masafi", value: "Masafi" },
    { name: "Wadi Asfai", value: "Wadi Asfai" },
    { name: "Al Hamraniyah", value: "Al Hamraniyah" },
    { name: "Sha’am", value: "Sha’am" },
    { name: "Al Dhaya", value: "Al Dhaya" },
    { name: "Jebel Jais", value: "Jebel Jais" },
  ],
  Sharjah: [
    { name: "Al Majarrah", value: "Al Majarrah" },
    { name: "Al Mahatta", value: "Al Mahatta" },
    { name: "Al Mamzar", value: "Al Mamzar" },
    { name: "Al Madam", value: "Al Madam" },
    { name: "Al Jazzat", value: "Al Jazzat" },
    { name: "Al Gharayen", value: "Al Gharayen" },
    { name: "Al Shahba", value: "Al Shahba" },
    { name: "Al Heera", value: "Al Heera" },
    { name: "Al Nouf", value: "Al Nouf" },
    { name: "Al Mujarrah", value: "Al Mujarrah" },
    { name: "Al Soor", value: "Al Soor" },
    { name: "Al Layyah", value: "Al Layyah" },
    { name: "Al Majaz", value: "Al Majaz" },
    { name: "Al Nahda", value: "Al Nahda" },
    { name: "Al Nabba", value: "Al Nabba" },
    { name: "Al Nud", value: "Al Nud" },
    { name: "Al Khawaneej", value: "Al Khawaneej" },
    { name: "Al Ghafya", value: "Al Ghafya" },
    { name: "Al Barashi", value: "Al Barashi" },
    { name: "Al Mareija", value: "Al Mareija" },
    { name: "Al Muwafjah", value: "Al Muwafjah" },
    { name: "Al Rolla", value: "Al Rolla" },
    { name: "Al Khaledia", value: "Al Khaledia" },
    { name: "Al Falaj", value: "Al Falaj" },
    { name: "Al Rifah", value: "Al Rifah" },
    { name: "Halwan Suburb", value: "Halwan Suburb" },
    { name: "Dasman", value: "Dasman" },
    { name: "Al Taawun", value: "Al Taawun" },
    { name: "Al Rahmaniya", value: "Al Rahmaniya" },
    { name: "Al Ghubaiba", value: "Al Ghubaiba" },
    { name: "Al Goaz", value: "Al Goaz" },
    { name: "Abu Shagara", value: "Abu Shagara" },
    { name: "Al Zubair Orchards", value: "Al Zubair Orchards" },
    { name: "Al Dhaid", value: "Al Dhaid" },
    { name: "Al Fisht", value: "Al Fisht" },
    { name: "Al Abar", value: "Al Abar" },
    { name: "Al Azra", value: "Al Azra" },
    { name: "Al Butina", value: "Al Butina" },
    { name: "Al Darari", value: "Al Darari" },
    { name: "Al Fayha", value: "Al Fayha" },
    { name: "Al Gharb", value: "Al Gharb" },
    { name: "Al Hazannah", value: "Al Hazannah" },
    { name: "Al Juraina", value: "Al Juraina" },
    { name: "Al Khan", value: "Al Khan" },
    { name: "Al Manakh", value: "Al Manakh" },
    { name: "Al Musalla", value: "Al Musalla" },
    { name: "Al Qadisiya", value: "Al Qadisiya" },
    { name: "Al Qasba", value: "Al Qasba" },
    { name: "Al Qasimiya", value: "Al Qasimiya" },
    { name: "Al Qulayaa", value: "Al Qulayaa" },
    { name: "Al Sabkha", value: "Al Sabkha" },
    { name: "Al Sajaa", value: "Al Sajaa" },
    { name: "Al Sharq", value: "Al Sharq" },
    { name: "Al Suyoh", value: "Al Suyoh" },
    { name: "Al Sweihat", value: "Al Sweihat" },
    { name: "Al Tai", value: "Al Tai" },
    { name: "Al Wahda", value: "Al Wahda" },
    { name: "Al Yarmook", value: "Al Yarmook" },
    { name: "Al Zubair", value: "Al Zubair" },
    { name: "Bu Tina", value: "Bu Tina" },
    { name: "Hoshi", value: "Hoshi" },
    { name: "Industrial Area", value: "Industrial Area" },
    { name: "Maysaloon", value: "Maysaloon" },
    { name: "Muwafjah", value: "Muwafjah" },
    { name: "Muwaileh", value: "Muwaileh" },
    { name: "Muwaileh Commercial", value: "Muwaileh Commercial" },
    { name: "Samnan", value: "Samnan" },
    { name: "Sharjah Industrial Area", value: "Sharjah Industrial Area" },
    { name: "Sharjah Waterfront City", value: "Sharjah Waterfront City" },
    { name: "Sharqan", value: "Sharqan" },
    { name: "University City", value: "University City" },
  ],

  Fujairah: [
    { name: "Al Aqah", value: "Al Aqah" },
    { name: "Al Badiyah", value: "Al Badiyah" },
    { name: "Al Buthna", value: "Al Buthna" },
    { name: "Al Faseel", value: "Al Faseel" },
    { name: "Al Ghurfah", value: "Al Ghurfah" },
    { name: "Al Hail", value: "Al Hail" },
    { name: "Al Hilal", value: "Al Hilal" },
    { name: "Al Mahatta", value: "Al Mahatta" },
    { name: "Al Nakheel", value: "Al Nakheel" },
    { name: "Al Owaid", value: "Al Owaid" },
    { name: "Al Qurayyah", value: "Al Qurayyah" },
    { name: "Al Rifaa", value: "Al Rifaa" },
    { name: "Al Rughaylat", value: "Al Rughaylat" },
    { name: "Al Sharia", value: "Al Sharia" },
    { name: "Al Taween", value: "Al Taween" },
    { name: "Dadna", value: "Dadna" },
    { name: "Dhadnah", value: "Dhadnah" },
    { name: "Dibba Al Fujairah", value: "Dibba Al Fujairah" },
    { name: "Masafi", value: "Masafi" },
    { name: "Merashid", value: "Merashid" },
    { name: "Murabah", value: "Murabah" },
    { name: "Qidfa", value: "Qidfa" },
    { name: "Sakamkam", value: "Sakamkam" },
    { name: "Wadi Wurayah", value: "Wadi Wurayah" },
    { name: "Zubarah", value: "Zubarah" },
  ],
  Ajman: [
    { name: "Al Bustan", value: "Al Bustan" },
    { name: "Al Hamidiya", value: "Al Hamidiya" },
    { name: "Al Jurf", value: "Al Jurf" },
    { name: "Al Nakhil", value: "Al Nakhil" },
    { name: "Al Rashidiya", value: "Al Rashidiya" },
    { name: "Al Raqaib", value: "Al Raqaib" },
    { name: "Al Sawan", value: "Al Sawan" },
    { name: "Al Zahya", value: "Al Zahya" },
    { name: "Al Yasmeen", value: "Al Yasmeen" },
    { name: "Ajman Corniche", value: "Ajman Corniche" },
    { name: "Ajman Free Zone", value: "Ajman Free Zone" },
    { name: "Ajman Industrial Area", value: "Ajman Industrial Area" },
    { name: "Ajman Marina", value: "Ajman Marina" },
    { name: "Ajman Uptown", value: "Ajman Uptown" },
    { name: "Al Amerah", value: "Al Amerah" },
    { name: "Al Bahya", value: "Al Bahya" },
    { name: "Al Bustan", value: "Al Bustan" },
    { name: "Al Heliow", value: "Al Heliow" },
    { name: "Al Jurf Industrial", value: "Al Jurf Industrial" },
    { name: "Al Mowaihat", value: "Al Mowaihat" },
    { name: "Al Nuaimiya", value: "Al Nuaimiya" },
    { name: "Al Rawda", value: "Al Rawda" },
    { name: "Al Rumailah", value: "Al Rumailah" },
    { name: "Al Tallah", value: "Al Tallah" },
    { name: "Al Zahra", value: "Al Zahra" },
    { name: "Emirates City", value: "Emirates City" },
    { name: "Garden City", value: "Garden City" },
    { name: "Helio", value: "Helio" },
    { name: "Mushairef", value: "Mushairef" },
    { name: "Mushrif", value: "Mushrif" },
    {
      name: "Sheikh Khalifa Bin Zayed City",
      value: "Sheikh Khalifa Bin Zayed City",
    },
  ],
  "Umm Al Quwain": [
    { name: "Al Maarid", value: "Al Maarid" },
    { name: "Al Neefah", value: "Al Neefah" },
    { name: "Al Falaj Al Kabir", value: "Al Falaj Al Kabir" },
    { name: "Al Salamah", value: "Al Salamah" },
    { name: "Al Raas", value: "Al Raas" },
    { name: "Al Riqa", value: "Al Riqa" },
    { name: "Al Humrah", value: "Al Humrah" },
    { name: "Al Aahad", value: "Al Aahad" },
    { name: "Al Haditha", value: "Al Haditha" },
    { name: "Al Madar", value: "Al Madar" },
    { name: "Old Town (Al Dour)", value: "Old Town (Al Dour)" },
    { name: "Al Dar Al Baida", value: "Al Dar Al Baida" },
    { name: "Al Mareija", value: "Al Mareija" },
    { name: "Al Riqqah", value: "Al Riqqah" },
    { name: "Al Labsa", value: "Al Labsa" },
    { name: "Al Rifaah", value: "Al Rifaah" },
    { name: "Al Khuraija", value: "Al Khuraija" },
    { name: "Al Abraq", value: "Al Abraq" },
    { name: "Al Hamriyah", value: "Al Hamriyah" },
    { name: "Al Rashidiyah", value: "Al Rashidiyah" },
    { name: "Falaj Al Mualla", value: "Falaj Al Mualla" },
    { name: "Al Maidan", value: "Al Maidan" },
    { name: "Al Ramlah", value: "Al Ramlah" },
    { name: "Al Surrah", value: "Al Surrah" },
    { name: "Al Khor", value: "Al Khor" },
    { name: "Al Salam City", value: "Al Salam City" },
    { name: "Al Shabiyah", value: "Al Shabiyah" },
    { name: "Al Raudah", value: "Al Raudah" },
    { name: "Al Shuweihat", value: "Al Shuweihat" },
    { name: "Al Siniyah Island", value: "Al Siniyah Island" },
    { name: "Al Raudat", value: "Al Raudat" },
    { name: "Al Dhaidah", value: "Al Dhaidah" },
  ],
};
let statesAndCities = statesAndCities1;
for (const state in statesAndCities) {
  statesAndCities[state].sort((a, b) => a.name.localeCompare(b.name));
}
export { statesAndCities };
