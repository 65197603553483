import React from "react";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import ByCOuntryzerostate from "./ByCOuntryzerostate";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { base_URL } from "../../config/config";

// Extended map of country names to ISO codes
const countryToCode = {
  India: "IN",
  UAE: "AE",
  Russia: "RU",
  USA: "US",
  China: "CN",
  Japan: "JP",
  Germany: "DE",
  France: "FR",
  UK: "GB",
  Canada: "CA",
  Australia: "AU",
  Brazil: "BR",
  Singapore: "SG",
  Thailand: "TH",
  Malaysia: "MY",
  Indonesia: "ID",
  Vietnam: "VN",
  Philippines: "PH",
  SouthKorea: "KR",
  SaudiArabia: "SA",
  Netherlands: "NL",
  Bahrain: "BH",
};

const EnquiryByCountry = ({ startDate, endDate, venueName }) => {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const { data: response, isLoading } = useQuery({
    queryKey: ["worldMapData", startDate, endDate, venueName],
    queryFn: async () => {
      const { data } = await axios.get(
        `${base_URL}/api/dashboard/getWorldMapEnquiries`,
        {
          params: {
            vendorId,
            startDate: startDate ? startDate.toLocaleDateString("en-CA") : null,
            endDate: endDate ? endDate.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return data;
    },
  });

  const countryData = Array.isArray(response) ? response : [];

  // Create color mapping using ISO codes
  const countryColors = countryData.reduce((acc, item) => {
    const code = countryToCode[item?.name];
    if (code && item?.color) {
      acc[code] = item.color;
    }
    return acc;
  }, {});

  const mapOptions = {
    map: worldMill,
    backgroundColor: "#ffffff",
    regionStyle: {
      initial: {
        fill: "#EAEAEA",
        "fill-opacity": 1,
        stroke: "#D3D3D3",
        strokeWidth: 1,
        "stroke-opacity": 1,
      },
      hover: {
        "fill-opacity": 0.8,
        cursor: "pointer",
      },
    },
    series: {
      regions: [
        {
          values: countryColors,
          attribute: "fill",
        },
      ],
    },
    zoomOnScroll: false,
    zoomButtons: false,
    onRegionTipShow: (event, tip, code) => {
      // Find country data by matching the ISO code
      const countryEntry = countryData.find(
        (item) => countryToCode[item?.name] === code
      );

      if (countryEntry) {
        // Format the value with commas for thousands
        const formattedValue = countryEntry.value.toLocaleString();
        tip.html(
          `<div style="font-family: Arial; font-size: 12px; padding: 4px;">
            <strong>${countryEntry.name}</strong>: ${formattedValue} enquiries
           </div>`
        );
      }
    },
  };

  if (isLoading) {
    return (
      <div className="w-full h-[40vh] rounded-xl font-outfit pb-3 animate-pulse bg-black/20"></div>
    );
  }

  if (!countryData.length) {
    return <ByCOuntryzerostate />;
  }

  return (
    <div className="flex flex-col bg-white p-4 rounded-xl mt-[32px]">
      <div className="md:mb-[24px] flex flex-col gap-1">
        <p className="text-[#222222] text-[16px] md:text-[20px] font-medium">
          Most enquiries received by countries
        </p>
        <p className="text-[#717171] text-[12px] md:text-[14px] font-normal">
          No. of enquiries received
        </p>
      </div>

      <div className="w-full flex flex-col lg:flex-row lg:items-center gap-2 mt-[10px] lg:mt-[60px] px-6 lg:px-[40px] pb-[10px] lg:pb-[30px]">
        <div className="w-full lg:w-[75%] h-[319px]">
          <VectorMap {...mapOptions} />
        </div>

        <div className="w-full lg:w-[35%]">
          <div className="flex justify-between mb-4 border-b border-[#EBEBEB] pb-4">
            <p className="text-[#222222] text-[20px] font-medium">Countries</p>
            <p className="text-[#222222] text-[20px] font-medium">
              No. of enquiries
            </p>
          </div>

          {countryData?.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-3">
              <div className="flex items-center gap-2">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{ backgroundColor: item?.color || "#D3D3D3" }}
                />
                <p className="text-[#222222] text-[16px] font-normal">
                  {item?.name || "Unknown"}
                </p>
              </div>
              <p className="text-[#222222] text-[16px] font-normal">
                {item?.value?.toLocaleString() || 0}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EnquiryByCountry;
