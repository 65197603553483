import React from "react";
import CategoryCarousel from "./CategoryCarousel";

const ExploreVenues = ({ venues, setFilters }) => {
  return (
    <div className="flex justify-center pt-20">
      <div className="w-[90%] mt-8">
        <h1 className="sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4">
          Explore Venues by Category
        </h1>
        <CategoryCarousel Data={venues} setFilters={setFilters} />
      </div>
    </div>
  );
};

export default ExploreVenues;
