import React, { useEffect, useState, useRef } from "react";
import CustomInput from "./shared/CustomInput";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";

const HotelVenuModal = ({
  cities,
  filters,
  setFilters,
  eventTypes,
  showHotelVenue,
  setShowHotelVenue,
}) => {
  const cityRef = useRef(null);
  const eventTypeRef = useRef(null);
  const [venueType, setVenueType] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [showSelect, setShowSelect] = useState({
    city: false,
    eventType: false,
  });
  const [select, setSelect] = useState({
    city: "",
    eventType: {},
  });

  const [inputs, setInputs] = useState({
    guests: "",
    rooms: "",
  });

  const handleShowSelect = (name) => {
    setShowSelect({ ...showSelect, [name]: !showSelect[name] });
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // console.log(select, inputs);
    setFilters({
      ...filters,
      city: select.city,
      standingGuests: inputs.guests,
      numberOfRooms: inputs.rooms,
    });
  }, [select, inputs]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cityRef.current &&
        !cityRef.current.contains(event.target) &&
        showSelect.city
      ) {
        setShowSelect((prev) => ({ ...prev, city: false }));
      }
      if (
        eventTypeRef.current &&
        !eventTypeRef.current.contains(event.target) &&
        showSelect.eventType
      ) {
        setShowSelect((prev) => ({ ...prev, eventType: false }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSelect]);
  if (!showHotelVenue) return null;

  return (
    <div className=" fixed top-0 left-0 w-full h-full flex p-4 px-6 items-center  justify-center z-[99]">
      <div
        className="fixed top-0 left-0 w-full h-full bg-[#000000B2]"
        onClick={() => setShowHotelVenue(false)}
      ></div>
      <div className="relative bg-white w-full max-w-[500px] p-4 rounded-lg shadow-lg">
        <div className="absolute top-2 right-2">
          <RxCross1
            className="text-2xl cursor-pointer"
            onClick={() => setShowHotelVenue(false)}
          />
        </div>
        <h1 className="lg:text-2xl sm:text-xl text-lg text-center font-semibold ">
          Are you looking for venue + hotel accommodation?
        </h1>
        <div className="grid grid-cols-2 gap-4 my-6">
          <CustomInput
            label={"Number of guests"}
            placeholder={"Enter number of guests"}
            value={inputs.guests}
            name="guests"
            onChange={handleInputChange}
          />
          <CustomInput
            label={"Number of rooms"}
            placeholder={"Enter number of rooms"}
            value={inputs.rooms}
            name="rooms"
            onChange={handleInputChange}
          />
          <div ref={cityRef}>
            <p className="text-dark">City</p>
            <div className="relative cursor-pointer mt-[1px]">
              <div
                className="flex items-center justify-between w-full border border-gray-300 px-2 py-2 rounded-md custom-scrollbar"
                onClick={() => handleShowSelect("city")}
              >
                <label htmlFor="city" className="text-[#717171]">
                  {select.city || "Select City"}
                </label>
                <IoMdArrowDropdown className="text-dark text-xl" />
              </div>
              {showSelect.city && (
                <div className="absolute z-[9999] w-full bg-white border border-gray-300 rounded-md shadow-lg">
                  {cities &&
                    Array.isArray(cities) &&
                    cities.map((city) => (
                      <div
                        key={city.name}
                        className="p-1 hover:bg-[#dbeafe] cursor-pointer"
                        onClick={() => {
                          setSelect({ ...select, city: city.name });
                          setShowSelect({ ...showSelect, city: false });
                        }}
                      >
                        {city.name}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div ref={eventTypeRef}>
            <p className="text-dark">Event/Venue</p>
            <div className="relative cursor-pointer">
              <div
                className="flex items-center justify-between w-full border border-gray-300 px-2 py-2 rounded-md custom-scrollbar"
                onClick={() => handleShowSelect("eventType")}
              >
                <label htmlFor="eventType" className="text-[#717171]">
                  {selectedEvent || "Event/Venue"}
                </label>
                <IoMdArrowDropdown className="text-dark text-xl" />
              </div>
              {showSelect.eventType && (
                <div className="absolute z-[9999] w-full bg-white border border-gray-300 max-h-[260px] overflow-y-auto custom-scrollbar rounded-md shadow-lg">
                  {eventTypes &&
                    Array.isArray(eventTypes) &&
                    eventTypes.map((event, index) => {
                      // console.log(event);
                      return (
                        <div
                          key={index} // Fallback to index if label is undefined
                          className="p-1 hover:bg-[#dbeafe] cursor-pointer"
                          onClick={() => {
                            setSelectedEvent(event.label);
                            if (event.eventKey !== "venues") {
                              setFilters({
                                ...filters,
                                eventTypes: {
                                  [event.eventKey]: [event.label],
                                },
                              });
                            } else {
                              console.log("setting event Type");
                              setFilters({
                                ...filters,
                                venueTypes: [event.label],
                              });
                            }
                            setShowSelect({ ...showSelect, eventType: false });
                          }}
                        >
                          {event.label || "Unknown Label"}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
        </div>
        <Link
          className=""
          to="/listing/2"
          onClick={() => {
            setSelect({ ...select, eventType: null });
            setVenueType("");
          }}
          state={{
            city: select.city,
            eventType: select.eventType,
            standingGuests: inputs.guests,
            numberofRooms: inputs.rooms,
          }}
        >
          <button className="bg-[#FE4747]  w-full rounded-lg text-white py-2 px-4 lg:text-[16px] lg:font-[500]">
            Search
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HotelVenuModal;
