import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { base_URL } from "../../config/config";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/features/authSlice";

function Verifyuser() {
  const dispatch = useDispatch();
  const { accessToken } = useParams();
  const navigate = useNavigate();

  const verifyEmail = async () => {
    try {
      const response = await axios.post(`${base_URL}/api/auth/verify-email`, {
        token: accessToken,
        isVerified: true,
      });
      const data = response.data;

      if (data.success) {
        localStorage.setItem("authUser", JSON.stringify(data.user));
        dispatch(setUser(data.user));
        navigate("/");
      } else if (data.success === false) {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      verifyEmail();
    }
  }, []);
  return <div>verifying...</div>;
}

export default Verifyuser;
