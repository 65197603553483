export const chains = [
  {
    name: "Accor",
    value: "Accor",
  },
  {
    name: "Aman Resorts",
    value: "Aman Resorts",
  },
  {
    name: "APA Group",
    value: "APA Group",
  },
  {
    name: "Archipelago International",
    value: "Archipelago International",
  },
  {
    name: "Ascott Limited (The)",
    value: "Ascott Limited (The)",
  },
  {
    name: "Avari Hotels",
    value: "Avari Hotels",
  },
  {
    name: "Banyan Tree Holdings",
    value: "Banyan Tree Holdings",
  },
  {
    name: "Barriére",
    value: "Barriére",
  },
  {
    name: "Belmond Limited",
    value: "Belmond Limited",
  },
  {
    name: "Best Western Hotels",
    value: "Best Western Hotels",
  },
  {
    name: "Blackstone Group (The)",
    value: "Blackstone Group (The)",
  },
  {
    name: "B TG Homeinns",
    value: "B TG Homeinns",
  },
  {
    name: "Centara Hotels & Resorts",
    value: "Centara Hotels & Resorts",
  },
  {
    name: "China Lodging Group",
    value: "China Lodging Group",
  },
  {
    name: "Choice Hotels International",
    value: "Choice Hotels International",
  },
  {
    name: "Cinnamon Hotels & Resorts",
    value: "Cinnamon Hotels & Resorts",
  },
  {
    name: "Compass Hospitality",
    value: "Compass Hospitality",
  },
  {
    name: "Dalata Hotel Group",
    value: "Dalata Hotel Group",
  },
  {
    name: "Disney Experiences",
    value: "Disney Experiences",
  },
  {
    name: "Dorchester Collection",
    value: "Dorchester Collection",
  },
  {
    name: "Drury Hotels",
    value: "Drury Hotels",
  },
  {
    name: "Dusit International",
    value: "Dusit International",
  },
  {
    name: "Elite Hotels of Sweden",
    value: "Elite Hotels of Sweden",
  },
  {
    name: "Eurobuilding Hotels",
    value: "Eurobuilding Hotels",
  },
  {
    name: "Extended Stay America",
    value: "Extended Stay America",
  },
  {
    name: "Fab Hotels",
    value: "Fab Hotels",
  },
  {
    name: "First Hotels",
    value: "First Hotels",
  },
  {
    name: "Four Seasons Hotels and Resorts",
    value: "Four Seasons Hotels and Resorts",
  },
  {
    name: "General Hotel Management (GHM)",
    value: "General Hotel Management (GHM)",
  },
  {
    name: "Green Tree Inns",
    value: "Green Tree Inns",
  },
  {
    name: "Hilton Worldwide",
    value: "Hilton Worldwide",
  },
  {
    name: "Huazhu Hotels Group",
    value: "Huazhu Hotels Group",
  },
  {
    name: "Hyatt Hotels Corporation",
    value: "Hyatt Hotels Corporation",
  },
  {
    name: "IHG Hotels & Resorts (IHG)",
    value: "IHG Hotels & Resorts (IHG)",
  },
  {
    name: "Indian Hotels Company Limited (IHCL)",
    value: "Indian Hotels Company Limited (IHCL)",
  },
  {
    name: "Interstate Hotels & Resorts",
    value: "Interstate Hotels & Resorts",
  },
  {
    name: "InTown Suites",
    value: "InTown Suites",
  },
  {
    name: "Jetwing Hotels Ltd",
    value: "Jetwing Hotels Ltd",
  },
  {
    name: "Jinjiang International",
    value: "Jinjiang International",
  },
  {
    name: "Jumeirah",
    value: "Jumeirah",
  },
  {
    name: "Kempinski",
    value: "Kempinski",
  },
  {
    name: "Langham Hospitality Group",
    value: "Langham Hospitality Group",
  },
  {
    name: "Lifestyle Holidays Group",
    value: "Lifestyle Holidays Group",
  },
  {
    name: "Loews Hotels",
    value: "Loews Hotels",
  },
  {
    name: "Lotte Hotels & Resorts",
    value: "Lotte Hotels & Resorts",
  },
  {
    name: "Magnuson Hotels",
    value: "Magnuson Hotels",
  },
  {
    name: "Mandarin Oriental Hotel Group",
    value: "Mandarin Oriental Hotel Group",
  },
  {
    name: "Marriott International",
    value: "Marriott International",
  },
  {
    name: "Melia Hotels International",
    value: "Meliå Hotels International",
  },
  {
    name: "MGM Resorts International",
    value: "MGM Resorts International",
  },
  {
    name: "Millennium & Copthorne Hotels",
    value: "Millennium & Copthorne Hotels",
  },
  {
    name: "Minor Hotels",
    value: "Minor Hotels",
  },
  {
    name: "NH Hotel Group",
    value: "NH Hotel Group",
  },
  {
    name: "Nordic Choice Hotels",
    value: "Nordic Choice Hotels",
  },
  {
    name: "Oberoi Group (The)",
    value: "Oberoi Group (The)",
  },
  {
    name: "Okura Nikko Hotel Management",
    value: "Okura Nikko Hotel Management",
  },
  {
    name: "Omni Hotels & Resorts",
    value: "Omni Hotels & Resorts",
  },
  {
    name: "ONYX Hospitality Group",
    value: "ONYX Hospitality Group",
  },
  {
    name: "OYO",
    value: "OYO",
  },
  {
    name: "Pan Pacific Hotels and Resorts",
    value: "Pan Pacific Hotels and Resorts",
  },
  {
    name: "Prince Hotels",
    value: "Prince Hotels",
  },
  {
    name: "Radisson Hotel Group",
    value: "Radisson Hotel Group",
  },
  {
    name: "Red Lion Hotels Corporation",
    value: "Red Lion Hotels Corporation",
  },
  {
    name: "Red Planet Hotels",
    value: "Red Planet Hotels",
  },
  {
    name: "Red Roof Inn",
    value: "Red Roof Inn",
  },
  {
    name: "RIU Hotels & Resorts",
    value: "RIU Hotels & Resorts",
  },
  {
    name: "Rocco Forte Hotels",
    value: "Rocco Forte Hotels",
  },
  {
    name: "Rosewood Hotel Group",
    value: "Rosewood Hotel Group",
  },
  {
    name: "S Hotels & Resorts",
    value: "S Hotels & Resorts",
  },
  {
    name: "Scandic Hotels",
    value: "Scandic Hotels",
  },
  {
    name: "Shahpura Hotels",
    value: "Shahpura Hotels",
  },
  {
    name: "Shangri-La Hotels and Resorts",
    value: "Shangri-La Hotels and Resorts",
  },
  {
    name: "Shilo Inns",
    value: "Shilo Inns",
  },
  {
    name: "Soneva",
    value: "Soneva",
  },
  {
    name: "Tokyu Hotels",
    value: "Tokyu Hotels",
  },
  {
    name: "Toyoko Inn",
    value: "Toyoko Inn",
  },
  {
    name: "Travelodge",
    value: "Travelodge",
  },
  {
    name: "Treebo",
    value: "Treebo",
  },
  {
    name: "Warwick Hotels and Resorts",
    value: "Warwick Hotels and Resorts",
  },
  {
    name: "Westgate Resorts",
    value: "Westgate Resorts",
  },
  {
    name: "Whitbread",
    value: "Whitbread",
  },
  {
    name: "Wyndham Hotels & Resorts",
    value: "Wyndham Hotels & Resorts",
  },
];

export const hotelType = [
  { name: "Airport Hotel", value: "Airport Hotel" },
  { name: "All-Inclusive Resort", value: "All-Inclusive Resort" },
  { name: "Beach Hotel", value: "Beach Hotel" },
  { name: "Boutique Hotel", value: "Boutique Hotel" },
  { name: "Budget Hotel", value: "Budget Hotel" },
  { name: "Business Hotel", value: "Business Hotel" },
  { name: "Casino Hotel", value: "Casino Hotel" },
  { name: "City Hotel", value: "City Hotel" },
  { name: "Desert Hotel", value: "Desert Hotel" },
  { name: "Eco-friendly Hotel", value: "Eco-friendly Hotel" },
  { name: "Floating Hotel", value: "Floating Hotel" },
  { name: "Glamping Hotel", value: "Glamping Hotel" },
  { name: "Golf Resorts", value: "Golf Resorts" },
  { name: "Heritage Hotel", value: "Heritage Hotel" },
  { name: "Historic Hotel", value: "Historic Hotel" },
  { name: "Island Hotel", value: "Island Hotel" },
  { name: "Luxury Hotel", value: "Luxury Hotel" },
  { name: "Resort Hotel", value: "Resort Hotel" },
  { name: "Safari Hotel", value: "Safari Hotel" },
  { name: "Wellness Hotel", value: "Wellness Hotel" },
];

export const eventType = [
  {
    name: "Company Retreats",
    value: "Company Retreats",
  },
  {
    name: "Corporate Off-sites",
    value: "Corporate Off-sites",
  },
  {
    name: "Executive Meetings",
    value: "Executive Meetings",
  },
  {
    name: "Family Reunions",
    value: "Family Reunions",
  },
  {
    name: "Destination Weddings",
    value: "Destination Weddings",
  },
  {
    name: "Group Gateways",
    value: "Group Gateways",
  },
  {
    name: "Wellness Retreats",
    value: "Wellness Retreats",
  },
  {
    name: "Educational Camps or Workshops",
    value: "Educational Camps",
  },
  {
    name: "Special Events or Celebrations",
    value: "Special Events",
  },
  {
    name: "Training Programs",
    value: "Training Programs",
  },
];

export const roomFeatures = [
  {
    name: "Calls (Local)",
    value: "Calls (Local)",
  },
  {
    name: "Calls (Toll-free)",
    value: "Calls (Toll-free)",
  },
  {
    name: "Concierge Services",
    value: "Concierge Services",
  },
  {
    name: "Laundary Service",
    value: "Laundary Service",
  },
  {
    name: "Luggage Storage",
    value: "Luggage Storage",
  },
  {
    name: "Internet Access",
    value: "Internet Access",
  },
  {
    name: "Room Service",
    value: "Room Service",
  },
  {
    name: "Air Conditioning",
    value: "Air Conditioning",
  },
  {
    name: "Kitchen",
    value: "Kitchen",
  },
];

export const businessFeatures = [
  {
    name: "Business Centre",
    value: "Business Centre",
  },
  {
    name: "Video Conference",
    value: "Video Conference",
  },
  {
    name: "VIP Services",
    value: "VIP Services",
  },
  {
    name: "AV Equipment",
    value: "AV Equipment",
  },
  {
    name: "Onsite AV Staff",

    value: "Onsite AV Staff",
  },
  {
    name: "High Speed Internet (100 Mbps)",
    value: "High Speed Internet (100 Mbps)",
  },
];

export const recreationalFeatures = [
  {
    name: "Health Club",
    value: "Health Club",
  },
  {
    name: "Indoor Pool",
    value: "Indoor Pool",
  },
  {
    name: "Outdoor Pool",
    value: "Outdoor Pool",
  },
  {
    name: "Spa",

    value: "Spa",
  },
  {
    name: "Salon",
    value: "Salon",
  },
  {
    name: "Tennis Courts",
    value: "Tennis Courts",
  },
  {
    name: "Whirlpool",
    value: "Whirlpool",
  },
];

export function getLast50Years() {
  const currentYear = new Date().getFullYear();
  const years1 = [];
  const years2 = [];
  for (let i = 0; i < 50; i++) {
    const year = currentYear - i;
    years1.push({
      name: year.toString(),
      value: year.toString(),
    });
    years2.push({
      name: "RY " + year.toString(),
      value: year.toString(),
    });
  }
  return { years1, years2 };
}

export const ratingFilters = [
  {
    name: "1 Star",
    value: "1",
  },
  {
    name: "2 Star",
    value: "2",
  },
  {
    name: "3 Star",
    value: "3",
  },
  {
    name: "4 Star",
    value: "4",
  },
  {
    name: "5 Star",
    value: "5",
  },
];
