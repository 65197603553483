/* eslint-disable */

import React, { useState } from "react";
import "./App.css";
import Login from "./pages/onboarding/Login";
import Register from "./pages/onboarding/Register";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./constants/theme";
import Onboarding from "./pages/onboarding/Onboarding";
import EmailVerificationSuccessful from "./pages/onboarding/EmailVerificationSuccessful";
import EmailVerificationSuccessfulId from "./pages/onboarding/EmailVerificationSuccessfulId";
import Reset from "./pages/onboarding/Reset";
import ResetPassword from "./pages/onboarding/ResetPassword";
import PaymentSuccess from "./pages/onboarding/PaymentSuccess";
import PaymentRejected from "./pages/onboarding/PaymentRejected";
import ResendEmail from "./pages/onboarding/ResendEmail";
import Dashboard from "./pages/hotel_listing/Dashboard";
import HostelListingForm from "./pages/hotel_listing/hotel-listing";
import VenuListingForm from "./pages/hotel_listing/venu-listing";
import HotelPreview from "./pages/hotel_listing/hotel-preview";
import DashboardVenueProfile from "./components/dashboard-components/dashboard-venue-profile/Dashboard-Venue-Profile";
import { HotelProvider } from "./context/HotelContext";
import { VenueProvider } from "./context/VenueContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Team from "./pages/team";
import TeamDetails from "./pages/team/team-details";
import TermsAndConditions from "./pages/onboarding/TermsCondition";
import Policy from "./pages/onboarding/components/policy";
import ProtectedRoute from "./hooks/protectRoute";
import RestaurantListingForm from "./pages/hotel_listing/restaurant-listing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import VenuePreview from "./pages/hotel_listing/venue-preview";
import TermsOfUse from "./pages/onboarding/term-use";
import PrivacyPolicy from "./pages/onboarding/privacy-policey";
import { UserProvider } from "./context/user";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import ListVenues from "./pages/listVenues/ListVenues";
import ContactUs from "./pages/contactUs/ContactUs";
import MyVenues from "./pages/myVenues/MyVenues";
import RestaurantPreview from "./pages/hotel_listing/restaurant-preview";
import EditVenuListingForm from "./pages/hotel_listing/edit-venue-form";
import EditHostelListingForm from "./pages/hotel_listing/edit-hotel";
import EditVenuePreview from "./pages/hotel_listing/edit-venue-preview";
import EditResListingForm from "./pages/hotel_listing/edit-restaurant-form";
import LoginActivator from "./pages/login-activator/LoginActivator";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import FAQs from "./pages/onboarding/FAQs";
import MySubscriptions from "./pages/onboarding/MySubscriptions/MySubscriptions";
import CheckOnBoarding from "./hooks/checkOnboarding";
import Listing from "./pages/home/listing/Listing";
import { Provider } from "react-redux";
import store from "./store";
import { PreviewProvider } from "./context/previewContext";
import Preview from "./pages/home/preview/Preview";
import Favorite from "./pages/home/myFav/MyFavorites";
import Profile from "./pages/home/profile/Profile";
import Enquiry from "./pages/home/enquiry/Enquiry";
import Message from "./pages/home/enquiry/message/Message";
import FavoriteDetail from "./pages/home/myFav/FavoriteDetail";
import CompareVenues from "./pages/home/listing/CompareVenues";
import CompareVenueAndStay from "./pages/home/listing/CompareVenueAndStay";
import Enquiryvendor from "./pages/enquiryvendor";
import CustomerHotelPreview from "./pages/home/hotel-preview/Preview";
import VerifyUser from "./pages/user/Verifyuser";
import Reports from "./pages/reports";
import VendorDashboard from "./components/VendorDashboard/VendorDashboard";
import EventLandingPage from "./pages/landingPages/events/Event";
import CityLandingPage from "./pages/landingPages/city/City";
import SettingsDashbaord from "./components/SettingsDashboard/SettingsDashboard";
import CustomerPasswordReset from "./pages/home/password-reset/Reset";
import EmailVerify from "./components/SettingsDashboard/EmailVerify";
import Listingvenuesonly from "./pages/home/listing/Listingsvenuesonly";
const queryClient = new QueryClient();

const App = () => {
  const [filters, setFilters] = useState({});
  return (
    <>
      <Provider store={store}>
        <PreviewProvider>
          <div className="App">
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <UserProvider>
              <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                  <HotelProvider>
                    <VenueProvider>
                      <Router>
                        <ScrollToTop />
                        <Routes>
                          {/* <Route path="/" element={<LandingPage />} /> */}
                          <Route
                            path="/login-activator/:email"
                            element={<LoginActivator />}
                          />
                          <Route path="/login" element={<Login />} />
                          <Route path="/register" element={<Register />} />
                          <Route
                            path="/cancellation-policy"
                            element={<Policy />}
                          />
                          <Route
                            path="/terms&conditions"
                            element={<TermsAndConditions />}
                          />
                          <Route
                            path="/privacy-policy"
                            element={<PrivacyPolicy />}
                          />
                          <Route
                            path="/terms-of-use"
                            element={<TermsOfUse />}
                          />
                          <Route path="/faqs" element={<FAQs />} />
                          <Route
                            path="/success/:id"
                            element={<EmailVerificationSuccessfulId />}
                          />
                          <Route
                            path="/success"
                            element={<EmailVerificationSuccessful />}
                          />
                          <Route path="/reset" element={<Reset />} />
                          <Route
                            path="/resetPassword"
                            element={<ResetPassword />}
                          />
                          <Route
                            path="/payment-success"
                            element={<PaymentSuccess />}
                          />
                          <Route
                            path="/payment-failed"
                            element={<PaymentRejected />}
                          />
                          <Route path="/resend" element={<ResendEmail />} />
                          <Route path="/about" element={<About />} />

                          <Route path="/list-venues" element={<ListVenues />} />
                          <Route path="/contact" element={<ContactUs />} />

                          {/* Protected Routes */}
                          <Route
                            path="/onboarding"
                            element={<ProtectedRoute element={Onboarding} />}
                          />
                          <Route
                            path="/dashboard"
                            element={<CheckOnBoarding element={Dashboard} />}
                          />
                          <Route path="/enquiry" element={<Enquiry />} />
                          <Route
                            path="/enquiryvendor"
                            element={<Enquiryvendor />}
                          />
                          <Route
                            path="/hostel-Listing-Form/:id"
                            element={
                              <ProtectedRoute element={HostelListingForm} />
                            }
                          />
                          <Route
                            path="/hostel-Listing-Form/:category/:id"
                            element={
                              <ProtectedRoute element={EditHostelListingForm} />
                            }
                          />
                          <Route
                            path="/venu-Listing-Form"
                            element={
                              <ProtectedRoute element={VenuListingForm} />
                            }
                          />
                          <Route
                            path="/create-Listing/:category/:id"
                            element={
                              <ProtectedRoute element={EditVenuListingForm} />
                            }
                          />
                          <Route
                            path="/venu-restaurant-Form"
                            element={
                              <ProtectedRoute element={RestaurantListingForm} />
                            }
                          />
                          <Route
                            path="/venu-restaurant-Form/:category/:id"
                            element={
                              <ProtectedRoute element={EditResListingForm} />
                            }
                          />
                          <Route
                            path="/user-Profile"
                            element={
                              <ProtectedRoute element={DashboardVenueProfile} />
                            }
                          />
                          <Route
                            path="/hotel-preview"
                            element={<ProtectedRoute element={HotelPreview} />}
                          />
                          <Route
                            path="/venue-preview"
                            element={<ProtectedRoute element={VenuePreview} />}
                          />
                          <Route
                            path="/venue-preview/:category/:id"
                            element={
                              <ProtectedRoute element={EditVenuePreview} />
                            }
                          />
                          <Route
                            path="/restaurant-preview"
                            element={
                              <ProtectedRoute element={RestaurantPreview} />
                            }
                          />
                          <Route
                            path="/team"
                            element={<ProtectedRoute element={Team} />}
                          />
                          <Route
                            path="/mysubscriptions"
                            element={
                              <ProtectedRoute element={MySubscriptions} />
                            }
                          />
                          <Route
                            path="/team/team-details/:id"
                            element={<ProtectedRoute element={TeamDetails} />}
                          />
                          <Route path="/myVenue" element={<MyVenues />} />
                          {/* Reports */}
                          <Route path="/reports" element={<Reports />} />
                          {/* Vendor Dashbaord */}
                          <Route
                            path="/vendor-dashboard"
                            element={<VendorDashboard />}
                          />

                          <Route
                            path="/settings-dashboard"
                            element={<SettingsDashbaord />}
                          />
                          {/* For Verifying email of settings Dashbaord */}
                          <Route
                            path="/verify-email/:token"
                            element={<EmailVerify />}
                          />
                          {/*---------------------------customer panel------------------- */}
                          <Route
                            path="/"
                            element={
                              <Home filters={filters} setFilters={setFilters} />
                            }
                          />
                          <Route
                            path="/listing/:tab"
                            element={
                              <Listing
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          {/* only see venues tabs through Search from homepage */}
                          <Route
                            path="/listingvenues/:tab"
                            element={
                              <Listingvenuesonly
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/preview/:cf/:id"
                            element={
                              <Preview
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />

                          <Route
                            path="/hotel-preview/:cf/:id"
                            element={
                              <CustomerHotelPreview
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/my-favorites"
                            element={
                              <Favorite
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/profile"
                            element={
                              <Profile
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/enquiries"
                            element={
                              <Enquiry
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/messages/:id"
                            element={
                              <Message
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/favorite-detail/:customerId/:folderName"
                            element={
                              <FavoriteDetail
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/compare-venues"
                            element={
                              <CompareVenues
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/compare-venues-stay"
                            element={
                              <CompareVenueAndStay
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/user/verify-email/:accessToken"
                            element={<VerifyUser />}
                          />
                          <Route
                            path="/user/reset-password/:token"
                            element={
                              <CustomerPasswordReset
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                          <Route
                            path="/event/:eventType"
                            element={
                              <EventLandingPage
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />

                          <Route
                            path="/city/:city"
                            element={
                              <CityLandingPage
                                filters={filters}
                                setFilters={setFilters}
                              />
                            }
                          />
                        </Routes>
                      </Router>
                    </VenueProvider>
                  </HotelProvider>
                </QueryClientProvider>
              </ThemeProvider>
            </UserProvider>
          </div>
        </PreviewProvider>
      </Provider>
    </>
  );
};

export default App;
