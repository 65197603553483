import React from "react";
import icon5 from "../../assets2/icons/placeOffers/5.svg";
import icon3 from "../../assets2/icons/placeOffers/3.svg";
import icon26 from "../../assets2/icons/placeOffers/26.svg";
import icon22 from "../../assets2/icons/placeOffers/22.svg";

const Highlights = ({ data }) => {
  // console.log({ data });
  let highlightsData1;
  if (data) {
    highlightsData1 = [...data?.venueType?.venueType];
  }
  // console.log(highlightsData1);

  // Utility function to convert time to 12-hour format
  const formatTimeTo12Hour = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Converts 0 to 12 for midnight, and handles 12-hour conversion
    return `${formattedHour}:${minute} ${period}`;
  };
  let highlightsData2;
  if (data) {
    highlightsData2 = [
      {
        icon: <img src={icon5} alt="icon" />,
        text: "Alcohol/ Liquor License",
        condition: data?.cateringAndDrinks?.alcoholLiquorLicense,
      },
      {
        icon: <img src={icon3} alt="icon" />,
        text: `Indoor music allowed until ${formatTimeTo12Hour(
          data?.audio?.indoorMusicAllowedTime
        )}`,
        condition: data?.audio?.indoorMusicAllowed,
      },
      {
        icon: <img src={icon26} alt="icon" />,
        text: "Bring your own DJ",
        condition: data?.audio?.clientsCanBringOwnDJ,
      },
      {
        icon: <img src={icon22} alt="icon" />,
        text: `Outdoor music allowed until ${formatTimeTo12Hour(
          data?.audio?.outdoorMusicAllowedTime
        )}`,
        condition: data?.audio?.outdoorMusicAllowed,
      },
    ];
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="py-5  mx-auto w-full rounded-lg">
      <div className="flex flex-col gap-4">
        <h2 className="md:text-2xl text-[20px] font-semibold">Highlights</h2>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 p-8 w-full rounded-xl bg-[#FFEBEB]">
          <div className="md:col-span-4 col-span-4 md:border-r  border-gray-300 md:pr-6 pb-3">
            <h3 className="text-xl font-medium mb-4">Venue Type</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {highlightsData1?.map((item, index) => (
                <div key={index} className="flex items-center ">
                  <span
                    className={`${
                      item.disabled
                        ? "text-gray-400 line-through text-[16px]"
                        : "text-black text-[16px]"
                    }`}
                  >
                    {item}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="md:col-span-1 col-span-4 pr-4 md:pl-0">
            <h3 className="text-xl font-medium mb-4">Setting</h3>
            <div className="flex flex-col gap-2 items-start">
              <span
                className={`${
                  !data?.additionalDetails?.venueSetting?.includes("Indoor")
                    ? "text-gray-400 line-through text-[16px]"
                    : "text-black text-[16px]"
                }`}
              >
                Indoor Space
              </span>
              <span
                className={`${
                  !data?.additionalDetails?.venueSetting?.includes("Outdoor")
                    ? "text-gray-400 line-through"
                    : "text-black"
                }`}
              >
                Outdoor Space
              </span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-8 w-full rounded-xl bg-[#FFEBEB]">
          {highlightsData2?.map((item, index) => (
            <div key={index} className="flex items-center">
              <span
                className={`text-2xl mr-4 ${
                  !item.condition ? "text-gray-400" : ""
                }`}
              >
                {item.icon}
              </span>
              <span
                className={`${
                  !item.condition ? "text-gray-400 line-through" : "text-black"
                }`}
              >
                {item.text}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Highlights;
