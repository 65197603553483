import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layout/dashboard-layout";
import { useUser } from "../../context/user";
import { Link } from "react-router-dom";
import PersonalDetails from "./PersonalDetails";
import OrganizationDetails from "./OrganizationDetails";
import EditLoginDetails from "./EditLoginDetails";
import LoginDetails from "./LoginDetails";
import ProfilePhoto from "./ProfilePhoto";
import EditOrganizationDetails from "./EditOrganizationDetails";
import EditPersonalDetails from "./EditPersonalDetails";
import { fetchUserById } from "../../api/loggedUser";
import { useQueryClient } from "@tanstack/react-query";
import { useFetchUserByIdQuery, useUpdateOrganization } from "./Hooks";
import toast from "react-hot-toast";
import EmailVerify from "./EmailVerify";
import axios from "axios";
import { base_URL } from "../../config/config";
import VerifyPhoneModal from "./VerifyPhoneModal";
export const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-2 md:p-0">
      <div className="bg-white p-6 rounded-lg shadow-lg md:w-[600px]">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            src="/cross.svg"
            alt="close"
            className="cursor-pointer"
          />
        </div>
        {children}
      </div>
    </div>
  );
};

const ProfileSettings = ({ user, updateOrganization, isLoading, vendorId }) => {
  const [personalModal, setPersonalModal] = useState(false);
  const [organizationModal, setOrganizationModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [verifyPhoneModal, setVerifyPhoneModal] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const queryClient = useQueryClient();
  // Prefill Edit fields
  useEffect(() => {
    if (user) {
      setPersonalDetails({
        name: user?.name || "-",
        email: user?.email || "-",
      });

      setOrganizationDetails({
        organization: user?.organization || "-",
        jobTitle: user?.role || "-",
      });

      setLoginDetails({
        mobileNumber: user?.phone || "-",
        password: "********",
      });
    }
  }, [user]);

  const [personalDetails, setPersonalDetails] = useState({
    name: user?.name || "-",
    email: user?.email || "-",
  });

  const [organizationDetails, setOrganizationDetails] = useState({
    organization: user?.organization || "-",
    jobTitle: user?.role || "-",
  });

  const [loginDetails, setLoginDetails] = useState({
    mobileNumber: user?.phone || "-",
    password: "********",
  });
  // save email/Name
  const handlePersonalSave = async (e) => {
    e.preventDefault();
    setIsLoadingUser(true);
    try {
      // Store pending changes in localStorage
      localStorage.setItem(
        "pendingProfileChanges",
        JSON.stringify({
          name: personalDetails.name,
          email: personalDetails.email,
          vendorId: localStorage.getItem("vendorId"),
        })
      );

      // First, send verification email
      const verifyResponse = await axios.post(
        `${base_URL}/api/dashboard/verify-email`,
        {
          email: personalDetails?.email,
          name: personalDetails?.name,
        }
      );
      if (verifyResponse?.data?.success) {
        toast.success("Verification email sent! Please check your inbox.");
        setPersonalModal(false);
      }
      setEmailVerificationModal(true);
    } catch (error) {
      console.error("Verification error:", error);
      toast.error(error.response?.data?.message || "Error updating profile");
    } finally {
      setIsLoadingUser(false);
    }
  };

  const handleLoginSave = async (e) => {
    e.preventDefault();

    setLoginModal(false);
    setVerifyPhoneModal(true);
  };

  // save organizational details
  const handleOrganizationSave = async (e) => {
    e.preventDefault();

    updateOrganization(
      {
        vendorId,
        ...organizationDetails,
      },
      {
        onSuccess: (data) => {
          toast.success("Organization details updated");
          setOrganizationModal(false);
        },
        onError: (error) => {
          console.error("Failed to update organization details:", error);
        },
      }
    );
  };
  // When user verifies the email show toaster and clear local storasge
  useEffect(() => {
    const emailJustVerified = localStorage.getItem("emailJustVerified");
    if (emailJustVerified) {
      toast.success("Email verified successfully!");
      localStorage.removeItem("emailJustVerified");
    }
  }, []);
  return (
    <div className="mt-8 bg-white rounded-lg p-6 shadow-sm  w-full">
      {/* Profile Photo Section */}
      <ProfilePhoto user={user} />
      {/* Personal Details Section */}
      <PersonalDetails
        personalDetails={personalDetails}
        user={user}
        setPersonalModal={setPersonalModal}
      />

      {/* Organization Details Section */}
      <OrganizationDetails
        user={user}
        setOrganizationModal={setOrganizationModal}
        organizationDetails={organizationDetails}
      />

      {/* Login Details Section */}
      <LoginDetails
        setLoginModal={setLoginModal}
        loginDetails={loginDetails}
        user={user}
      />

      {/* ------------------------------------------Edit Modals------------------------------------ */}
      {/* Personal Details Modal */}
      <Modal isOpen={personalModal} onClose={() => setPersonalModal(false)}>
        <EditPersonalDetails
          setPersonalDetails={setPersonalDetails}
          personalDetails={personalDetails}
          handlePersonalSave={handlePersonalSave}
          isLoadingUser={isLoadingUser}
        />
      </Modal>

      {/* Organization Details Modal Edit*/}
      <Modal
        isOpen={organizationModal}
        onClose={() => setOrganizationModal(false)}
      >
        <EditOrganizationDetails
          user={user}
          setOrganizationDetails={setOrganizationDetails}
          organizationDetails={organizationDetails}
          handleOrganizationSave={handleOrganizationSave}
          isLoading={isLoading}
        />
      </Modal>

      {/* Login Details Modal Edit */}
      <Modal isOpen={loginModal} onClose={() => setLoginModal(false)}>
        <EditLoginDetails
          handleLoginSave={handleLoginSave}
          loginDetails={loginDetails}
          setLoginDetails={setLoginDetails}
          user={user}
          setLoginModal={setLoginModal}
        />
      </Modal>
      {/* Email verification Modal */}
      <Modal
        isOpen={emailVerificationModal}
        onClose={() => setEmailVerificationModal(false)}
      >
        <EmailVerify
          handlePersonalSave={handlePersonalSave}
          personalDetails={personalDetails}
          onClose={() => setEmailVerificationModal(false)}
        />
      </Modal>
      {/* PhoneModal */}
      <Modal
        isOpen={verifyPhoneModal}
        onClose={() => setVerifyPhoneModal(false)}
      >
        <VerifyPhoneModal
          user={user}
          setVerifyPhoneModal={setVerifyPhoneModal}
          loginDetails={loginDetails}
        />
      </Modal>
    </div>
  );
};

function SettingsDashboard() {
  const invitedBy = localStorage.getItem("invitedBy");
  const [dashboardPath, setDashboardPath] = useState("/dashboard");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const { mutate: updateOrganization, isLoading } = useUpdateOrganization();
  const {
    data: userData,
    isLoading: isFetchingUser,
    error,
  } = useFetchUserByIdQuery(vendorId);

  // Extract user from fetched data
  const user = userData?.vendor;

  useEffect(() => {
    if (user) {
      const hasVenueListing = user.venueForms?.length > 0;
      const hasRestaurantListing = user.RestaurantForm?.length > 0;
      const hasHotelListing = user.Hotel?.length > 0;

      if (
        user.isAdmin &&
        (hasVenueListing || hasRestaurantListing) &&
        hasHotelListing
      ) {
        setDashboardPath("/vendor-dashboard");
      }
    }
  }, [user]);
  return (
    <DashboardLayout>
      <div className="flex flex-col p-3 md:p-6 font-outfit overflow-hidden">
        {/* Header (Breadcrumb) */}
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col gap-3">
            <h2 className="text-xl font-medium md:text-2xl md:font-semibold">
              Profile
            </h2>
            <div className="flex items-center gap-2">
              <Link
                to={dashboardPath}
                className="text-[#FE4747] text-[10px] md:text-[16px]"
              >
                Dashboard
              </Link>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.813111 11.8536C0.617849 11.6583 0.617849 11.3417 0.813111 11.1464L5.79289 6.16667L0.813111 1.18689C0.617849 0.991625 0.617849 0.675041 0.813111 0.479779C1.00837 0.284517 1.32496 0.284517 1.52022 0.479779L6.85355 5.81311C7.04881 6.00838 7.04881 6.32496 6.85355 6.52022L1.52022 11.8536C1.32496 12.0488 1.00837 12.0488 0.813111 11.8536Z"
                  fill="#222222"
                />
              </svg>
              <p className="text-[#222222] text-[14px]">Profile</p>
            </div>
          </div>
        </div>

        <div className="w-full md:max-w-[744px] mx-auto">
          <ProfileSettings
            user={user}
            updateOrganization={updateOrganization}
            isLoading={isLoading}
            vendorId={vendorId}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default SettingsDashboard;
