export const venueStayData = {
  total: 3,
  totalPages: 1,
  currentPage: 1,
  venues: [
    {
      _id: "66fbecc287163e185d1be375",
      vendorId: "66fbec5f179829de74757b3e",
      status: "completed",
      basicDetails: {
        venueName: "First Venue",
        hotelName: "First Hotel",
        groupName: "",
        hasMultipleEventSpaces: true,
        isPrimaryVenue: true,
        venueDescription:
          "A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.",
        slotName: "Multi Venue 1",
        _id: "6708e3770673212005c4f029",
      },
      additionalDetails: {
        venueView: [
          "Harbor",
          "Cliffside",
          "Landscaped Grounds",
          "Island",
          "Park",
        ],
        venueSetting: ["Outdoor"],
        venueStyle: {
          traditionalStyle: [],
          modernContemporaryStyle: ["High-tech"],
          eclecticStyle: ["Retro"],
          culturalRegionalStyle: ["Japanese Traditional"],
          _id: "6708e3770673212005c4f02a",
        },
        selectedEventTypes: [],
        venueHire: "Shared space",
        _id: "6708e3770673212005c4f02b",
      },
      venueType: {
        venueType: [
          "Kids play area",
          "Therapy room",
          "Yoga Studio",
          "Spa",
          "Helipad",
          "Lawn",
          "Park",
          "Stadium",
          "Theme Park",
        ],
        venueRepresent: "Park",
        _id: "6708e3770673212005c4f02c",
      },
      eventType: {
        business: {
          conferenceSeminar: [],
          corporateEvents: [],
          _id: "6708e3770673212005c4f02d",
        },
        weddingAndDining: {
          weddings: [],
          dining: [],
          banquet: [],
          _id: "6708e3770673212005c4f02e",
        },
        parties: {
          socialParties: [],
          clubbingNightOut: [],
          drinksReceptions: [],
          christmasParties: [],
          babyShowerTeaParty: [],
          childrenTeen: [],
          genericTags: [],
          _id: "6708e3770673212005c4f02f",
        },
        artAndCultural: {
          filmingAndPhotography: [],
          cultural: [],
          artSpaces: [],
          performancesExhibitions: [],
          _id: "6708e3770673212005c4f030",
        },
        eventVenuesForKids: false,
        eventVenuesForKidsIncludes: [],
        Dining: [
          "BBQ",
          "Anniversary Party",
          "Cocktail Masterclass",
          "Cocktail Reception",
          "Condolences",
          "Drinks Reception",
          "Engagement",
          "Farewell Party",
          "Funeral reception",
          "Graduation Party",
          "New Years Eve Party",
          "Party",
          "Private Party",
          "Retirement Party",
          "Reunions",
          "Stag Party",
          "Wine Tasting",
        ],
        Weddings: [
          "Arabic Wedding",
          "Beach Wedding",
          "Christian Wedding",
          "Church Wedding",
          "Civil Partnership",
          "Civil Wedding",
          "Garden Wedding",
          "Hindu Wedding",
          "Indian Wedding",
          "Marquee Wedding",
          "Outdoor Wedding",
          "Unique Wedding",
          "Wedding Ceremony",
          "Wedding Reception",
        ],
        _id: "6708e3770673212005c4f031",
      },
      location: {
        addressLine1: "sda",
        addressLine2: "ads",
        city: "Al Warqa",
        area: "Dubai",
        poBox: "",
        country: "United Arab Emirates",
        mapLink: "https://maps.app.goo.gl/tp44MmRFPxf5LTvo9",
        lat: 25.1440702,
        lng: 54.9333595,
        parkingAvailable: {
          parkingAvailable: true,
          valetParking: true,
          _id: "6708e3770673212005c4f032",
        },
        accessibilityFeatures: [],
        _id: "6708e3770673212005c4f033",
      },
      capacity: {
        venueSize: "22",
        unit: "Sq. feet",
        standing: "32",
        sitting: "42",
        additionalLayout: {
          ushaped: {
            enabled: false,
            value: "",
          },
          _id: "6708e3770673212005c4f034",
        },
        _id: "6708e3770673212005c4f035",
      },
      offersAndPackages: [],
      cateringAndDrinks: {
        venueProvideInHouseCatering: false,
        availableMenu: [],
        cuisine: [],
        externalCatering: false,
        alcoholLiquorLicense: false,
        alcoholLiquorLicenseTime: "",
        corkageFeeForBringYourOwnAlcohol: false,
        bringYourOwnAlcoholAllowed: false,
        refreshments: false,
        refreshmentsForGuests: [""],
        shisha: false,
        servingStyle: [],
        isVenueAlcohlic: false,
        _id: "6708e3770673212005c4f036",
      },
      spaceRules: {
        moreSpaceRules: "",
        ticketedEventsAllowed: false,
        _id: "6708e3770673212005c4f037",
      },
      facilities: {
        facilities: [
          {
            _id: "6708e3770673212005c4f038",
            Podium: true,
          },
        ],
        activitiesForKids: false,
        activitiesForKidsIncludes: [],
        _id: "6708e3770673212005c4f039",
      },
      equipment: {
        equipment: [],
        _id: "6708e3770673212005c4f03a",
      },
      audio: {
        indoorMusicAllowed: false,
        indoorMusicAllowedTime: "",
        outdoorMusicAllowed: false,
        outdoorMusicAllowedTime: "",
        paSystemSpeakers: false,
        clientsCanPlayOwnMusic: false,
        clientsCanBringOwnDJ: false,
        spaceHasNoiseRestrictions: true,
        _id: "6708e3770673212005c4f03b",
      },
      UploadDocuments: {
        floorPlans: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/floorPlans/javascript_tutorial%20%282%29.pdf",
        ],
        tradeLicense: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/tradeLicense/javascript_tutorial%20%282%29.pdf",
        ],
        sampleMenus: [],
        brochure: [],
        selectedDocuments: ["floorPlans", "tradeLicense"],
      },
      imagesAndVideos: {
        images: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%283%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%284%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%282%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%281%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%281%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
        ],
        videos: [],
        videoLinks: [],
        coverPhoto: "",
      },
      itemStatus: "Accepted",
      createdAt: "2024-10-01T12:36:18.181Z",
      updatedAt: "2024-10-01T12:43:14.757Z",
      __v: 0,
      pricing: {
        "Minimum Spend Pricing": [
          {
            id: 1727786330580,
            type: "Minimum Spend Pricing",
            selectedDays: ["Mon"],
            minSpend: "22",
            duration: "per session",
            from: "01:00",
            to: "01:00",
          },
        ],
        selectedOptions: [
          {
            type: "Minimum Spend Pricing",
            details: {
              id: 1727786330580,
              type: "Minimum Spend Pricing",
              selectedDays: ["Mon"],
              minSpend: "22",
              duration: "per session",
              from: "01:00",
              to: "01:00",
            },
          },
          {
            type: "Venue Rental",
            details: {
              id: 1727786340300,
              type: "Venue Rental",
              selectedDays: ["Sun"],
              minSpend: "22",
              duration: "per session",
              from: "09:30",
              to: "09:30",
            },
          },
        ],
        "Venue Rental": [
          {
            id: 1727786340300,
            type: "Venue Rental",
            selectedDays: ["Sun"],
            minSpend: "22",
            duration: "per session",
            from: "09:30",
            to: "09:30",
          },
        ],
        minimumSpendPricing: [],
        venueRental: [],
        minSpendPerPerson: [],
        selectedPricingOptions: [],
        _id: "6708e3770673212005c4f03c",
      },
    },
    {
      _id: "66fbecc287163e185d1be376",
      vendorId: "66fbec5f179829de74757b3g",
      status: "completed",
      basicDetails: {
        venueName: "Second Venue",
        hotelName: "Second Hotel",
        groupName: "",
        hasMultipleEventSpaces: true,
        isPrimaryVenue: true,
        venueDescription:
          "A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.",
        slotName: "Multi Venue 1",
        _id: "6708e3770673212005c4f029",
      },
      additionalDetails: {
        venueView: [
          "Harbor",
          "Cliffside",
          "Landscaped Grounds",
          "Island",
          "Park",
        ],
        venueSetting: ["Outdoor"],
        venueStyle: {
          traditionalStyle: [],
          modernContemporaryStyle: ["High-tech"],
          eclecticStyle: ["Retro"],
          culturalRegionalStyle: ["Japanese Traditional"],
          _id: "6708e3770673212005c4f02a",
        },
        selectedEventTypes: [],
        venueHire: "Shared space",
        _id: "6708e3770673212005c4f02b",
      },
      venueType: {
        venueType: [
          "Kids play area",
          "Therapy room",
          "Yoga Studio",
          "Spa",
          "Helipad",
          "Lawn",
          "Park",
          "Stadium",
          "Theme Park",
        ],
        venueRepresent: "Park",
        _id: "6708e3770673212005c4f02c",
      },
      eventType: {
        business: {
          conferenceSeminar: [],
          corporateEvents: [],
          _id: "6708e3770673212005c4f02d",
        },
        weddingAndDining: {
          weddings: [],
          dining: [],
          banquet: [],
          _id: "6708e3770673212005c4f02e",
        },
        parties: {
          socialParties: [],
          clubbingNightOut: [],
          drinksReceptions: [],
          christmasParties: [],
          babyShowerTeaParty: [],
          childrenTeen: [],
          genericTags: [],
          _id: "6708e3770673212005c4f02f",
        },
        artAndCultural: {
          filmingAndPhotography: [],
          cultural: [],
          artSpaces: [],
          performancesExhibitions: [],
          _id: "6708e3770673212005c4f030",
        },
        eventVenuesForKids: false,
        eventVenuesForKidsIncludes: [],
        Dining: [
          "BBQ",
          "Anniversary Party",
          "Cocktail Masterclass",
          "Cocktail Reception",
          "Condolences",
          "Drinks Reception",
          "Engagement",
          "Farewell Party",
          "Funeral reception",
          "Graduation Party",
          "New Years Eve Party",
          "Party",
          "Private Party",
          "Retirement Party",
          "Reunions",
          "Stag Party",
          "Wine Tasting",
        ],
        Weddings: [
          "Arabic Wedding",
          "Beach Wedding",
          "Christian Wedding",
          "Church Wedding",
          "Civil Partnership",
          "Civil Wedding",
          "Garden Wedding",
          "Hindu Wedding",
          "Indian Wedding",
          "Marquee Wedding",
          "Outdoor Wedding",
          "Unique Wedding",
          "Wedding Ceremony",
          "Wedding Reception",
        ],
        _id: "6708e3770673212005c4f031",
      },
      location: {
        addressLine1: "sda",
        addressLine2: "ads",
        city: "Al Sharq",
        area: "Sharja",
        poBox: "",
        country: "United Arab Emirates",
        mapLink: "https://maps.app.goo.gl/tp44MmRFPxf5LTvo9",
        lat: 25.1440702,
        lng: 54.9333595,
        parkingAvailable: {
          parkingAvailable: true,
          valetParking: true,
          _id: "6708e3770673212005c4f032",
        },
        accessibilityFeatures: [],
        _id: "6708e3770673212005c4f033",
      },
      capacity: {
        venueSize: "23",
        unit: "Sq. feet",
        standing: "24",
        sitting: "25",
        additionalLayout: {
          ushaped: {
            enabled: false,
            value: "",
          },
          _id: "6708e3770673212005c4f034",
        },
        _id: "6708e3770673212005c4f035",
      },
      offersAndPackages: [],
      cateringAndDrinks: {
        venueProvideInHouseCatering: false,
        availableMenu: [],
        cuisine: [],
        externalCatering: false,
        alcoholLiquorLicense: false,
        alcoholLiquorLicenseTime: "",
        corkageFeeForBringYourOwnAlcohol: false,
        bringYourOwnAlcoholAllowed: false,
        refreshments: false,
        refreshmentsForGuests: [""],
        shisha: false,
        servingStyle: [],
        isVenueAlcohlic: false,
        _id: "6708e3770673212005c4f036",
      },
      spaceRules: {
        moreSpaceRules: "",
        ticketedEventsAllowed: false,
        _id: "6708e3770673212005c4f037",
      },
      facilities: {
        facilities: [
          {
            _id: "6708e3770673212005c4f038",
            Podium: true,
          },
        ],
        activitiesForKids: false,
        activitiesForKidsIncludes: [],
        _id: "6708e3770673212005c4f039",
      },
      equipment: {
        equipment: [],
        _id: "6708e3770673212005c4f03a",
      },
      audio: {
        indoorMusicAllowed: false,
        indoorMusicAllowedTime: "",
        outdoorMusicAllowed: false,
        outdoorMusicAllowedTime: "",
        paSystemSpeakers: false,
        clientsCanPlayOwnMusic: false,
        clientsCanBringOwnDJ: false,
        spaceHasNoiseRestrictions: true,
        _id: "6708e3770673212005c4f03b",
      },
      UploadDocuments: {
        floorPlans: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/floorPlans/javascript_tutorial%20%282%29.pdf",
        ],
        tradeLicense: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/tradeLicense/javascript_tutorial%20%282%29.pdf",
        ],
        sampleMenus: [],
        brochure: [],
        selectedDocuments: ["floorPlans", "tradeLicense"],
      },
      imagesAndVideos: {
        images: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%283%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%284%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%282%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%281%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%281%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
        ],
        videos: [],
        videoLinks: [],
        coverPhoto: "",
      },
      itemStatus: "Accepted",
      createdAt: "2024-10-01T12:36:18.181Z",
      updatedAt: "2024-10-01T12:43:14.757Z",
      __v: 0,
      pricing: {
        "Minimum Spend Pricing": [
          {
            id: 1727786330580,
            type: "Minimum Spend Pricing",
            selectedDays: ["Mon"],
            minSpend: "22",
            duration: "per session",
            from: "01:00",
            to: "01:00",
          },
        ],
        selectedOptions: [
          {
            type: "Minimum Spend Pricing",
            details: {
              id: 1727786330580,
              type: "Minimum Spend Pricing",
              selectedDays: ["Mon"],
              minSpend: "22",
              duration: "per session",
              from: "01:00",
              to: "01:00",
            },
          },
          {
            type: "Venue Rental",
            details: {
              id: 1727786340300,
              type: "Venue Rental",
              selectedDays: ["Sun"],
              minSpend: "22",
              duration: "per session",
              from: "09:30",
              to: "09:30",
            },
          },
        ],
        "Venue Rental": [
          {
            id: 1727786340300,
            type: "Venue Rental",
            selectedDays: ["Sun"],
            minSpend: "22",
            duration: "per session",
            from: "09:30",
            to: "09:30",
          },
        ],
        minimumSpendPricing: [],
        venueRental: [],
        minSpendPerPerson: [],
        selectedPricingOptions: [],
        _id: "6708e3770673212005c4f03c",
      },
    },
    {
      _id: "66fbecc287163e185d1be377",
      vendorId: "66fbec5f179829de74757b3f",
      status: "completed",
      basicDetails: {
        venueName: "Third Venue",
        hotelName: "Third Hotel",
        groupName: "",
        hasMultipleEventSpaces: true,
        isPrimaryVenue: true,
        venueDescription:
          "A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.A venue with multiple event spaces is often called a 'multi-purpose venue' or 'venue complex,' highlighting its versatility and variety. It offers diverse options for hosting various events in one location. Opting for this choice allows you to add more event spaces to the venue.",
        slotName: "Multi Venue 1",
        _id: "6708e3770673212005c4f029",
      },
      additionalDetails: {
        venueView: [
          "Harbor",
          "Cliffside",
          "Landscaped Grounds",
          "Island",
          "Park",
        ],
        venueSetting: ["Outdoor"],
        venueStyle: {
          traditionalStyle: [],
          modernContemporaryStyle: ["High-tech"],
          eclecticStyle: ["Retro"],
          culturalRegionalStyle: ["Japanese Traditional"],
          _id: "6708e3770673212005c4f02a",
        },
        selectedEventTypes: [],
        venueHire: "Shared space",
        _id: "6708e3770673212005c4f02b",
      },
      venueType: {
        venueType: [
          "Kids play area",
          "Therapy room",
          "Yoga Studio",
          "Spa",
          "Helipad",
          "Lawn",
          "Park",
          "Stadium",
          "Theme Park",
        ],
        venueRepresent: "Park",
        _id: "6708e3770673212005c4f02c",
      },
      eventType: {
        business: {
          conferenceSeminar: [],
          corporateEvents: [],
          _id: "6708e3770673212005c4f02d",
        },
        weddingAndDining: {
          weddings: [],
          dining: [],
          banquet: [],
          _id: "6708e3770673212005c4f02e",
        },
        parties: {
          socialParties: [],
          clubbingNightOut: [],
          drinksReceptions: [],
          christmasParties: [],
          babyShowerTeaParty: [],
          childrenTeen: [],
          genericTags: [],
          _id: "6708e3770673212005c4f02f",
        },
        artAndCultural: {
          filmingAndPhotography: [],
          cultural: [],
          artSpaces: [],
          performancesExhibitions: [],
          _id: "6708e3770673212005c4f030",
        },
        eventVenuesForKids: false,
        eventVenuesForKidsIncludes: [],
        Dining: [
          "BBQ",
          "Anniversary Party",
          "Cocktail Masterclass",
          "Cocktail Reception",
          "Condolences",
          "Drinks Reception",
          "Engagement",
          "Farewell Party",
          "Funeral reception",
          "Graduation Party",
          "New Years Eve Party",
          "Party",
          "Private Party",
          "Retirement Party",
          "Reunions",
          "Stag Party",
          "Wine Tasting",
        ],
        Weddings: [
          "Arabic Wedding",
          "Beach Wedding",
          "Christian Wedding",
          "Church Wedding",
          "Civil Partnership",
          "Civil Wedding",
          "Garden Wedding",
          "Hindu Wedding",
          "Indian Wedding",
          "Marquee Wedding",
          "Outdoor Wedding",
          "Unique Wedding",
          "Wedding Ceremony",
          "Wedding Reception",
        ],
        _id: "6708e3770673212005c4f031",
      },
      location: {
        addressLine1: "sda",
        addressLine2: "ads",
        city: "Al Gurm",
        area: "Abu Dhabi",
        poBox: "",
        country: "United Arab Emirates",
        mapLink: "https://maps.app.goo.gl/tp44MmRFPxf5LTvo9",
        lat: 25.1440702,
        lng: 54.9333595,
        parkingAvailable: {
          parkingAvailable: true,
          valetParking: true,
          _id: "6708e3770673212005c4f032",
        },
        accessibilityFeatures: [],
        _id: "6708e3770673212005c4f033",
      },
      capacity: {
        venueSize: "24",
        unit: "Sq. feet",
        standing: "29",
        sitting: "41",
        additionalLayout: {
          ushaped: {
            enabled: false,
            value: "",
          },
          _id: "6708e3770673212005c4f034",
        },
        _id: "6708e3770673212005c4f035",
      },
      offersAndPackages: [],
      cateringAndDrinks: {
        venueProvideInHouseCatering: false,
        availableMenu: [],
        cuisine: [],
        externalCatering: false,
        alcoholLiquorLicense: false,
        alcoholLiquorLicenseTime: "",
        corkageFeeForBringYourOwnAlcohol: false,
        bringYourOwnAlcoholAllowed: false,
        refreshments: false,
        refreshmentsForGuests: [""],
        shisha: false,
        servingStyle: [],
        isVenueAlcohlic: false,
        _id: "6708e3770673212005c4f036",
      },
      spaceRules: {
        moreSpaceRules: "",
        ticketedEventsAllowed: false,
        _id: "6708e3770673212005c4f037",
      },
      facilities: {
        facilities: [
          {
            _id: "6708e3770673212005c4f038",
            Podium: true,
          },
        ],
        activitiesForKids: false,
        activitiesForKidsIncludes: [],
        _id: "6708e3770673212005c4f039",
      },
      equipment: {
        equipment: [],
        _id: "6708e3770673212005c4f03a",
      },
      audio: {
        indoorMusicAllowed: false,
        indoorMusicAllowedTime: "",
        outdoorMusicAllowed: false,
        outdoorMusicAllowedTime: "",
        paSystemSpeakers: false,
        clientsCanPlayOwnMusic: false,
        clientsCanBringOwnDJ: false,
        spaceHasNoiseRestrictions: true,
        _id: "6708e3770673212005c4f03b",
      },
      UploadDocuments: {
        floorPlans: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/floorPlans/javascript_tutorial%20%282%29.pdf",
        ],
        tradeLicense: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/tradeLicense/javascript_tutorial%20%282%29.pdf",
        ],
        sampleMenus: [],
        brochure: [],
        selectedDocuments: ["floorPlans", "tradeLicense"],
      },
      imagesAndVideos: {
        images: [
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%283%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%284%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%282%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%282%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20%281%29%20-%20Copy.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/OIP%20%281%29.jpeg",
          "https://st-findmyvenue.s3.ap-south-1.amazonaws.com/images/download%20-%20Copy.jpeg",
        ],
        videos: [],
        videoLinks: [],
        coverPhoto: "",
      },
      itemStatus: "Accepted",
      createdAt: "2024-10-01T12:36:18.181Z",
      updatedAt: "2024-10-01T12:43:14.757Z",
      __v: 0,
      pricing: {
        "Minimum Spend Pricing": [
          {
            id: 1727786330580,
            type: "Minimum Spend Pricing",
            selectedDays: ["Mon"],
            minSpend: "22",
            duration: "per session",
            from: "01:00",
            to: "01:00",
          },
        ],
        selectedOptions: [
          {
            type: "Minimum Spend Pricing",
            details: {
              id: 1727786330580,
              type: "Minimum Spend Pricing",
              selectedDays: ["Mon"],
              minSpend: "22",
              duration: "per session",
              from: "01:00",
              to: "01:00",
            },
          },
          {
            type: "Venue Rental",
            details: {
              id: 1727786340300,
              type: "Venue Rental",
              selectedDays: ["Sun"],
              minSpend: "22",
              duration: "per session",
              from: "09:30",
              to: "09:30",
            },
          },
        ],
        "Venue Rental": [
          {
            id: 1727786340300,
            type: "Venue Rental",
            selectedDays: ["Sun"],
            minSpend: "22",
            duration: "per session",
            from: "09:30",
            to: "09:30",
          },
        ],
        minimumSpendPricing: [],
        venueRental: [],
        minSpendPerPerson: [],
        selectedPricingOptions: [],
        _id: "6708e3770673212005c4f03c",
      },
    },
  ],
};
