import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layout/dashboard-layout";
import { useQuery } from "@tanstack/react-query";
import { base_URL } from "../../config/config";
import axios from "axios";
import { TotalEnquiriesCard } from "./TotalEnquiriesCard";
import { EnquireStatusCard } from "./EnquireStatusCard";
import PerformanceFunnelGraph from "./PerformanceFunnelGraph";
import EnquiryByCountry from "./EnquiryByCountry";
import BudgetFavTimeGraph from "./Budgetfavtimegraph";
import { TotaleventType } from "./TotaleventType";
import Responserate from "./Responserate";
import Venueselectfilter from "./Venueselectfilter";
import Tooltipdashbaord from "./Tooltipdashbaord";
import DateRangleSelector from "./DateRangleSelector";
import { useUser } from "../../context/user";
import { MdScatterPlot } from "react-icons/md";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
function VendorDashboard() {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [venueName, setVenueName] = useState("all");
  const [loadingDownload, setLoadingDownload] = useState(false);

  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const { user } = useUser();
  const { data: venueNames } = useQuery({
    queryKey: ["venueNamesDashboardVendor", vendorId],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/enquiries/venues-for-vendordashbaord`,
        {
          params: { vendorId },
        }
      );
      return response?.data || [];
    },
    enabled: !!vendorId,
  });
  // Query for total venues data (Funnel Report)
  const {
    data: totalVenuesData,
    isLoading: isLoadingTotalVenues,
    isError: isErrorTotalVenues,
    refetch,
  } = useQuery({
    queryKey: ["totalVenues", vendorId, startDate, endDate, venueName],
    queryFn: async () => {
      // date format: yyyy-mm-dd
      const start = startDate?.toISOString().split("T")[0];
      const end = endDate?.toISOString().split("T")[0];
      const response = await axios.post(
        `${base_URL}/api/impressions/find-by-vendor-id`,
        {
          vendorId,
          startDate: start,
          endDate: end,
          venueName,
        }
      );
      return response?.data?.statsByDevice;
    },
    enabled: !!vendorId,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Download Dashboard Data
  const downloadPDF = async () => {
    setLoadingDownload(true);

    try {
      // Get the dashboard container
      const dashboard = document.getElementById("dashboard-container");

      // Create a wrapper div for proper width handling
      const wrapper = document.createElement("div");
      wrapper.style.width = "1200px"; // Set fixed width for consistency
      wrapper.style.padding = "40px"; // Add padding

      // Clone the dashboard content into the wrapper
      const clone = dashboard.cloneNode(true);
      wrapper.appendChild(clone);
      document.body.appendChild(wrapper);

      // Configure html2canvas with specific width settings
      const canvas = await html2canvas(wrapper, {
        scale: 2,
        useCORS: true,
        logging: false,
        allowTaint: true,
        backgroundColor: "#F3F3F3",
        width: 1200, // Match wrapper width
        windowWidth: 1200,
        onclone: (clonedDoc) => {
          // Ensure all flex containers take full width
          const flexContainers = clonedDoc.querySelectorAll(".md\\:flex");
          flexContainers.forEach((container) => {
            container.style.width = "100%";
            container.style.maxWidth = "100%";
          });

          // Adjust grid containers
          const gridContainers = clonedDoc.querySelectorAll(".grid");
          gridContainers.forEach((container) => {
            container.style.width = "100%";
            container.style.maxWidth = "100%";
          });

          return new Promise((resolve) => setTimeout(resolve, 500));
        },
      });

      // Remove the temporary wrapper
      document.body.removeChild(wrapper);

      // Initialize PDF in landscape orientation for better width
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [canvas.width, canvas.height], // Custom format to fit all content
      });

      // Calculate dimensions maintaining aspect ratio
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      // Add the image to PDF
      pdf.addImage(
        canvas.toDataURL("image/png", 1.0),
        "PNG",
        0,
        0,
        pdfWidth,
        pdfHeight,
        undefined,
        "FAST"
      );

      // Handle multiple pages if content is too tall
      if (pdfHeight > pdf.internal.pageSize.getHeight()) {
        let remainingHeight = pdfHeight;
        let currentPage = 1;
        const pageHeight = pdf.internal.pageSize.getHeight();

        while (remainingHeight > pageHeight) {
          pdf.addPage();
          pdf.addImage(
            canvas.toDataURL("image/png", 1.0),
            "PNG",
            0,
            -(pageHeight * currentPage),
            pdfWidth,
            pdfHeight,
            undefined,
            "FAST"
          );
          currentPage++;
          remainingHeight -= pageHeight;
        }
      }

      // Save the PDF
      pdf.save(`Vendor_Dashboard_${new Date().toLocaleDateString()}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoadingDownload(false);
    }
  };
  const fetchSubscriptionStatus = async (email) => {
    const response = await fetch(
      `${base_URL}/get-stripe-details-dashbaord-vendor`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch subscription status");
    }

    return response.json();
  };
  const {
    data: subscriptionData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["subscriptionStatus", user?.email],
    queryFn: () => fetchSubscriptionStatus(user?.email),
    enabled: !!user?.email && isOpen,
  });

  const isOnTrial =
    (user?.trialEndsAt && moment(user?.trialEndsAt).isAfter(moment())) ||
    subscriptionData?.isOnTrial;

  const daysLeft = isOnTrial
    ? moment(user?.trialEndsAt || subscriptionData?.trialEnd).diff(
        moment(),
        "days"
      )
    : 0;

  const lastPaymentDate = subscriptionData?.lastPayment
    ? moment(subscriptionData.lastPayment).format("DD MMMM, YYYY")
    : "N/A";

  const nextPaymentDate = subscriptionData?.nextPayment
    ? moment(subscriptionData?.nextPayment).format("DD MMMM, YYYY")
    : "N/A";
  return (
    <DashboardLayout>
      <div className="flex flex-col p-3 md:p-6 font-outfit overflow-hidden">
        {/* Header */}
        <div className="flex flex-wrap justify-between gap-4">
          {/* Left Section */}
          <div className="flex  flex-col gap-3">
            <div className="flex items-center gap-2">
              <p className="text-xl md:text-[32px] font-bold text-[#222222]">
                Hello {user?.name || ""},
              </p>
              {/* Subscribed img (btn) */}
              <div className="relative">
                <img
                  onClick={() => setIsOpen(!isOpen)}
                  src={isOnTrial ? "/trialsub.svg" : "/greensubscribed.svg"}
                  alt="Subscription Status"
                  className="cursor-pointer active:opacity-55"
                  draggable={false}
                />

                {/* Tooltip */}
                {isOpen && (
                  <Tooltipdashbaord
                    isLoading={isLoading}
                    isOnTrial={isOnTrial}
                    error={error}
                    daysLeft={daysLeft}
                    lastPaymentDate={lastPaymentDate}
                    nextPaymentDate={nextPaymentDate}
                    isOpen={isOpen}
                    tooltipRef={tooltipRef}
                    setIsOpen={setIsOpen}
                  />
                )}
              </div>
            </div>
            <p className="text-sm md:text-[16px] text-[#5E5E5E]">
              Check out how your venue is doing
            </p>
          </div>

          {/* Right Section */}
          <div className="flex flex-wrap md:flex-nowrap gap-4 items-end justify-end w-full md:w-auto md:relative md:top-2">
            {/* Download Icon */}
            {loadingDownload && (
              <MdScatterPlot className="text-[#7bc9c0] animate-spin relative bottom-2" />
            )}
            {!loadingDownload && (
              <img
                onClick={downloadPDF}
                src="/greendownload.svg"
                alt=""
                className="cursor-pointer active:opacity-55"
                draggable={false}
              />
            )}

            {/* Filters */}
            <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
              {/* Venue Selector */}
              <Venueselectfilter
                venueName={venueName}
                setVenueName={setVenueName}
                venueNames={venueNames}
              />

              {/* Date Range Selector */}
              <DateRangleSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
        </div>
        {/* Total Enquiry Graph & Enquiry Status Card */}
        <div className="flex flex-col w-full" id="dashboard-container">
          <div className="grid grid-cols-1 md:flex md:w-full gap-6 mt-[32px]">
            <TotalEnquiriesCard
              startDate={startDate}
              endDate={endDate}
              venueName={venueName}
            />
            <EnquireStatusCard
              startDate={startDate}
              endDate={endDate}
              venueName={venueName}
            />
          </div>
          {/* Performance funnel graph */}
          <PerformanceFunnelGraph
            key={venueName}
            data1={totalVenuesData}
            venueName={venueName}
            startDate={startDate}
            endDate={endDate}
          />
          {/* Most enquires received by countries */}
          <EnquiryByCountry
            startDate={startDate}
            endDate={endDate}
            venueName={venueName}
          />
          {/* EventType & Response rate */}
          <div className="grid grid-cols-1 md:flex md:w-full gap-6 mt-[32px]">
            <TotaleventType
              startDate={startDate}
              endDate={endDate}
              venueName={venueName}
            />
            <Responserate
              startDate={startDate}
              endDate={endDate}
              venueName={venueName}
            />
          </div>

          {/* Avg Budget & favourite & avg time spent graph (3 graphs)*/}
          <BudgetFavTimeGraph
            startDate={startDate}
            endDate={endDate}
            venueName={venueName}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default VendorDashboard;
