const DescriptionIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 14.7913H12.5C12.6774 14.7913 12.8258 14.7315 12.9454 14.6118C13.0651 14.4922 13.125 14.3437 13.125 14.1663C13.125 13.989 13.0651 13.8405 12.9454 13.7209C12.8258 13.6012 12.6774 13.5413 12.5 13.5413H7.5C7.32264 13.5413 7.17417 13.6012 7.05458 13.7209C6.93486 13.8405 6.875 13.989 6.875 14.1663C6.875 14.3437 6.93486 14.4922 7.05458 14.6118C7.17417 14.7315 7.32264 14.7913 7.5 14.7913ZM7.5 11.458H12.5C12.6774 11.458 12.8258 11.3981 12.9454 11.2784C13.0651 11.1588 13.125 11.0104 13.125 10.833C13.125 10.6556 13.0651 10.5072 12.9454 10.3876C12.8258 10.2679 12.6774 10.208 12.5 10.208H7.5C7.32264 10.208 7.17417 10.2679 7.05458 10.3876C6.93486 10.5072 6.875 10.6556 6.875 10.833C6.875 11.0104 6.93486 11.1588 7.05458 11.2784C7.17417 11.3981 7.32264 11.458 7.5 11.458ZM5.25646 17.9163C4.83549 17.9163 4.47917 17.7705 4.1875 17.4788C3.89583 17.1872 3.75 16.8309 3.75 16.4099V3.58947C3.75 3.16849 3.89583 2.81217 4.1875 2.52051C4.47917 2.22884 4.83549 2.08301 5.25646 2.08301H11.2517C11.4525 2.08301 11.6456 2.12204 11.831 2.20009C12.0163 2.27801 12.1774 2.38537 12.3142 2.52217L15.8108 6.01884C15.9476 6.15565 16.055 6.31669 16.1329 6.50197C16.211 6.68738 16.25 6.88051 16.25 7.08134V16.4099C16.25 16.8309 16.1042 17.1872 15.8125 17.4788C15.5208 17.7705 15.1645 17.9163 14.7435 17.9163H5.25646ZM11.25 6.32967V3.33301H5.25646C5.19229 3.33301 5.13354 3.35974 5.08021 3.41322C5.02674 3.46655 5 3.5253 5 3.58947V16.4099C5 16.4741 5.02674 16.5328 5.08021 16.5861C5.13354 16.6396 5.19229 16.6663 5.25646 16.6663H14.7435C14.8077 16.6663 14.8665 16.6396 14.9198 16.5861C14.9733 16.5328 15 16.4741 15 16.4099V7.08301H12.0033C11.7885 7.08301 11.6092 7.01113 11.4656 6.86738C11.3219 6.72377 11.25 6.54454 11.25 6.32967Z"
        fill={color}
      />
    </svg>
  );
};

export default DescriptionIcon;
