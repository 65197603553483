import { useState, useEffect } from "react";
import axios from "axios";
import countries from "i18n-iso-countries";

// Load country names in English
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const locationApis = [
  "https://ip-api.com/json/",
  "https://ipinfo.io/json",
  "https://geolocation-db.com/json/",
];

export function useCountryLocation() {
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      for (const api of locationApis) {
        try {
          const response = await axios.get(api);
          let countryCode;

          if (api.includes("ip-api.com")) {
            countryCode = response.data.countryCode; // Short code
          } else if (api.includes("ipinfo.io")) {
            countryCode = response.data.country; // Short code
          } else {
            countryCode = response.data.country_code; // Short code
          }

          const fullCountryName =
            countries.getName(countryCode, "en") || "Unknown";
          setCountry(fullCountryName);
          setLoading(false);
          return;
        } catch (error) {
          // console.error(`Error with ${api}:`, error);
        }
      }
      setError("Failed to fetch location");
      setLoading(false);
    };

    fetchLocation();
  }, []);

  return { country, loading, error };
}
