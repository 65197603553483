import React, { useState } from "react";
import toast from "react-hot-toast";

export default function Declinemodal({
  isVisible,
  onClose,
  submitDeclineModal,
}) {
  const [selectedReason, setSelectedReason] = useState("");

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleContinue = () => {
    if (selectedReason) {
      submitDeclineModal(selectedReason);
    } else {
      toast.error("Please select a reason before proceeding!");
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg md:w-[600px]">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            src="/cross.svg"
            alt=""
            className="cursor-pointer"
          />
        </div>
        <h2 className="text-center font-bold mb-4 text-[22px] lg:text-[32px]">
          Reason for rejection
        </h2>
        <div className="mb-4 flex flex-col gap-y-3">
          {[
            {
              value: "Client is no longer interested",
              label: "Client is no longer interested",
            },
            {
              value: "Client didn't respond",
              label: "Client didn't respond",
            },
            {
              value: "Space isn't available on selected date",
              label: "Space isn't available on selected date",
            },
            {
              value: "Don't host the type of event",
              label: "Don't host the type of event",
            },
            {
              value: "Doesn't meet the requirements",
              label: "Doesn't meet the requirements",
            },
            { value: "other", label: "Other" },
          ].map((option) => (
            <label key={option.value} className="flex items-center gap-x-3">
              <input
                type="radio"
                name="reason"
                value={option.value}
                onChange={handleReasonChange}
              />
              {option.label}
            </label>
          ))}
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleContinue}
            className="bg-[#FF3939] text-white px-4 py-2 rounded-xl"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
