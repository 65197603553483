import React, { useContext, useState, useEffect } from "react";
import { VenueContext } from "../../context/VenueContext";
import {
  validateCateringAndDrinks,
  validateSpaceRules,
  // validateOffersAndPackages,
  validatePricing,
  validateBasicDetails,
  validateAdditionalDetails,
  validateFacilitiesStepper,
  validateUploadDocumentstepper,
  validateCapacityTypeStepper,
  validateUploadPhotosAndVideosStepper,
} from "./validation";
import { useParams } from "react-router-dom";

const steps = [
  {
    title: "Overview",
    subSteps: [
      "Basic Details",
      "Additional Details",
      "Venue Type",
      "Event Type",
      "Location",
    ],
  },
  { title: "Capacity", subSteps: [] },
  { title: "Pricing", subSteps: [] },
  { title: "Offers & Packages", subSteps: [] },
  { title: "Catering & Drinks", subSteps: [] },
  { title: "Space Rules", subSteps: [] },
  {
    title: "Facilities & Equipment",
    subSteps: ["Facilities", "Equipment"],
  },
  { title: "Audio", subSteps: [] },
  { title: "Upload Documents", subSteps: [] },
  { title: "Photos & Videos", subSteps: [] },
  { title: "Preview", subSteps: [] },
];

const Stepper = ({
  currentStep,
  currentSubStep,
  setCurrentStep,
  setCurrentSubStep,
}) => {
  const { formData, setFormData } = useContext(VenueContext);
  const [errors, setErrors] = useState({});
  const { id } = useParams();

  useEffect(() => {
    // Set the step you want based on the id
    if (id) {
      if (currentStep !== parseInt(10)) {
        setCurrentStep(parseInt(0)); // Ensure it's a valid number
      }
    }
  }, [id, setCurrentStep]);

  useEffect(() => {
    const validateStep = (stepTitle) => {
      let isInvalid = false;

      switch (stepTitle) {
        case "Catering & Drinks":
          isInvalid = validateCateringAndDrinks(formData.cateringAndDrinks);
          if (
            JSON.stringify(formData.cateringmissing) !==
            JSON.stringify({ isError: isInvalid })
          ) {
            setFormData((prevData) => ({
              ...prevData,
              cateringmissing: { isError: isInvalid },
            }));
          }
          break;

        case "Space Rules":
          isInvalid = validateSpaceRules(formData.spaceRules);
          if (
            JSON.stringify(formData.spacerulesmissing) !==
            JSON.stringify({ isError: isInvalid })
          ) {
            setFormData((prevData) => ({
              ...prevData,
              spacerulesmissing: { isError: isInvalid },
            }));
          }
          break;

        case "Facilities & Equipment":
          if (currentSubStep === 0) {
            isInvalid = validateFacilitiesStepper(formData.facilities);
            if (
              JSON.stringify(formData.facilitiesdatamissing) !==
              JSON.stringify({ isError: isInvalid })
            ) {
              setFormData((prevData) => ({
                ...prevData,
                facilitiesdatamissing: { isError: isInvalid },
              }));
            }
          }
          break;

        case "Upload Documents":
          isInvalid = validateUploadDocumentstepper(formData.UploadDocuments);
          if (
            JSON.stringify(formData.uploadDocumentsdatamissing) !==
            JSON.stringify({ isError: isInvalid })
          ) {
            setFormData((prevData) => ({
              ...prevData,
              uploadDocumentsdatamissing: { isError: isInvalid },
            }));
          }
          break;

        case "Pricing":
          // Set isInvalid to true if formData.pricing is undefined
          // Set isInvalid to true if formData.pricing is undefined or null
          if (formData?.pricing === undefined || formData?.pricing === null) {
            isInvalid = true;
          } else {
            // Perform the regular validation if pricing exists
            isInvalid = validatePricing(formData.pricing);
          }

          // Only update formData if there is a difference to avoid unnecessary re-renders
          const newPricingMissing = { isError: isInvalid };
          if (
            formData?.pricingmissing === undefined ||
            JSON.stringify(formData?.pricingmissing) !==
              JSON.stringify(newPricingMissing)
          ) {
            setFormData((prevData) => ({
              ...prevData,
              pricingmissing: newPricingMissing,
            }));
          }
          break;

        case "Overview":
          if (currentSubStep === 0) {
            isInvalid = validateBasicDetails(formData.basicDetails);
            if (
              JSON.stringify(formData.overviewbasicdetailsmissing) !==
              JSON.stringify({ isError: isInvalid })
            ) {
              setFormData((prevData) => ({
                ...prevData,
                overviewbasicdetailsmissing: { isError: isInvalid },
              }));
            }
          }
          break;

        case "Additional Details":
          isInvalid = validateAdditionalDetails(formData.additionalDetails);
          if (
            JSON.stringify(formData.additionaldetailsmissing) !==
            JSON.stringify({ isError: isInvalid })
          ) {
            setFormData((prevData) => ({
              ...prevData,
              additionaldetailsmissing: { isError: isInvalid },
            }));
          }
          break;

        case "Capacity":
          isInvalid = validateCapacityTypeStepper(formData.capacity);
          if (
            JSON.stringify(formData.additionaldetailsmissing) !==
            JSON.stringify({ isError: isInvalid })
          ) {
            setFormData((prevData) => ({
              ...prevData,
              additionaldetailsmissing: { isError: isInvalid },
            }));
          }
          break;

        case "Photos & Videos":
          isInvalid = validateUploadPhotosAndVideosStepper(
            formData.imagesAndVideos
          );
          if (
            JSON.stringify(formData.photosandvideosmissing) !==
            JSON.stringify({ isError: isInvalid })
          ) {
            setFormData((prevData) => ({
              ...prevData,
              photosandvideosmissing: { isError: isInvalid },
            }));
          }
          break;

        default:
          break;
      }

      return isInvalid;
    };

    const newErrors = {};
    for (let i = 0; i < currentStep; i++) {
      const stepTitle = steps[i].title;
      const stepSubSteps = steps[i].subSteps || [];

      const isInvalid = validateStep(stepTitle);
      newErrors[stepTitle] = isInvalid;

      stepSubSteps.forEach((subStep) => {
        const subStepInvalid = validateStep(stepTitle, subStep);
        newErrors[stepTitle] = subStepInvalid;
      });
    }

    setErrors(newErrors);
  }, [currentStep, currentSubStep, formData]);

  const getStepIndicatorClass = (stepIndex) => {
    const stepTitle = steps[stepIndex].title;
    if (stepIndex < currentStep) {
      return errors[stepTitle] ? "bg-[#FFF6E6]" : "bg-green1"; // Completed steps with or without errors
    }
    if (stepIndex === currentStep) {
      return "bg-transparent"; // Current step being filled
    }
    return "bg-transparent"; // Default state for future steps
  };

  const showTickMark = (stepIndex) => {
    const stepTitle = steps[stepIndex].title;
    return stepIndex < currentStep && !errors[stepTitle]; // Only show tick if completed and no errors
  };

  const showExclamationMark = (stepIndex) => {
    const stepTitle = steps[stepIndex].title;
    return stepIndex < currentStep && errors[stepTitle]; // Only show exclamation if completed and has errors
  };

  useEffect(() => {
    if (currentStep === 0) {
      setErrors({});
    }
  }, [currentStep]);

  return (
    <div className="w-full bg-white p-4">
      <ul className="relative space-y-3">
        {steps.map((step, index) => (
          <div key={index}>
            <li
              className={`flex items-center cursor-pointer relative mb-10 ${
                currentStep === index || Math.floor(currentStep) === index
                  ? "text-green1"
                  : "text-[#B0B0B0]"
              }`}
              onClick={() => setCurrentStep(index)}
            >
              <div className="flex items-center">
                <span
                  className={`w-7 h-7 flex items-center justify-center rounded-full border-2 ${
                    currentStep === index || Math.floor(currentStep) === index
                      ? "border-green1"
                      : "border-[#B0B0B0]"
                  } ${getStepIndicatorClass(index)}`}
                >
                  {/* Conditionally render tick mark or exclamation mark */}
                  {showTickMark(index) && (
                    <svg
                      className="w-4 h-4 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  )}
                  {showExclamationMark(index) && (
                    <svg
                      className="w-4 h-4 text-yellow-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke-width="2"
                        d="M12 8v4m0 4h.01M4.93 4.93l14.14 14.14M4.93 19.07l14.14-14.14"
                      ></path>
                    </svg>
                  )}
                  {!showTickMark(index) && !showExclamationMark(index) && (
                    <span
                      className={`w-2 h-2 rounded-full ${
                        currentStep === index ||
                        Math.floor(currentStep) === index
                          ? "bg-green1"
                          : "bg-[#B0B0B0]"
                      }`}
                    ></span>
                  )}
                </span>
                {index !== steps.length - 1 && (
                  <span
                    className={`min-h-full absolute left-o top-8 min-w-[2px] ${
                      currentStep > index || Math.floor(currentStep) === index
                        ? "bg-green1"
                        : "bg-[#B0B0B0]"
                    } ml-3`}
                  ></span>
                )}
              </div>
              <span
                className={`${
                  currentStep >= index ? "text-black" : "border-[#B0B0B0] "
                } ml-3`}
              >
                {step.title}
              </span>
            </li>
            {step?.subSteps?.length > 0 &&
              Math.floor(currentStep) === index && (
                <ul className="lg:ml-8">
                  {step?.subSteps?.map((subStep, subIndex) => (
                    <li
                      key={subIndex}
                      className={`flex relative items-center cursor-pointer font-normal mb-2 text-sm ${
                        currentSubStep >= subIndex
                          ? "text-black"
                          : "text-[#B0B0B0]"
                      }`}
                      onClick={() => setCurrentSubStep(subIndex)}
                    >
                      {/* Green left border for completed or active sub-steps */}
                      <span
                        className={`min-h-6 rounded-md min-w-[2px] font-normal ${
                          currentSubStep >= subIndex
                            ? "bg-green1"
                            : "bg-[#B0B0B0]"
                        } ml-3`}
                      ></span>

                      {/* Sub-step text */}
                      <span
                        className={`ml-3 font-normal ${
                          currentSubStep >= subIndex
                            ? "text-black"
                            : "text-[#B0B0B0]"
                        }`}
                      >
                        {subStep}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Stepper;
