"use client";
import { RxCross1, RxArrowLeft } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { toggleSignUp } from "../../store/features/modalSlice";

const Modal = ({ isOpen, onClose, children, label, modalClass }) => {
  const dispatch = useDispatch();
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full flex p-4 px-6 items-center  justify-center z-[9999]">
      <div
        className="fixed top-0 left-0 w-full h-full bg-[#000000B2] "
        onClick={onClose}
      ></div>
      <div
        className={`fixed xl:w-[35%] md:w-[48%] sm:w-[80%] w-[90%] max-h-[96%] pb-5 custom-scrollbar flex flex-col gap-4 overflow-auto justify-between bg-white px-6 py-3 rounded-lg  shadow-lg ${modalClass}`}
      >
        <div className="flex justify-between">
          <div className={label === "Additional Details" ? "block" : "hidden"}>
            <button onClick={() => dispatch(toggleSignUp())}>
              <RxArrowLeft className="text-2xl" />
            </button>
          </div>
          <div className="w-full flex  justify-center">
            <h1 className="sm:text-2xl text-xl font-bold">{label}</h1>
          </div>
          <div className="">
            <button className="" onClick={onClose}>
              <RxCross1 className="text-2xl" />
            </button>
          </div>
        </div>

        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
