import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import OutlinedLabelledTextField from "../../components/OutlinedLabelledTextfield";
import backgroundIcon from "../../assets/images/background_icon.svg";
import { useTheme } from "@mui/material/styles";
import MessageCard from "../onboarding/components/MessageCard";
import { register } from "../../api/signup.request";
import EmailVerification from "./EmailVerification";
import { toast } from "react-toastify";
import { LoaderIcon } from "react-hot-toast";
// import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/shared/Modal";
import SignUpModal from "../../components/SignUpModal";
import LoginModal from "../../components/LoginModal";
import AdditionalDetails from "../../components/AdditionalDetails";
import SocialModal from "../../components/SocialModal";
import CheckInboxModal from "../../components/CheckInboxModal";
import ResetPassword from "../../components/forget-password/ResetPassword";
import ResetWorkMail from "../../components/forget-password/ResetWorkMail";
import ResetMailSend from "../../components/forget-password/ResetMailSent";
import PasswordUpdated from "../../components/forget-password/PasswordUpdated";

import {
  toggleSignUp,
  toggleLogin,
  toggleSocial,
  toggleAdditional,
  toggleCheckInbox,
  closeModals,
  toggleReset,
} from "../../store/features/modalSlice";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
function Register() {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [fullName, setFullName] = useState("");
  const [workMail, setWorkMail] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [encryptedMail, setEncryptedMail] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [country, setCountry] = useState("AE"); // Default country
  const [countryCode, setCountryCode] = useState("+971"); // Default UAE country code

  const handlePhoneChange = (value) => {
    setMobileno(value || "");
  };

  const handleCountryChange = (countryCode) => {
    if (countryCode) {
      setCountry(countryCode);

      try {
        // Directly get the calling code for the selected country
        const dialCode = getCountryCallingCode(countryCode);
        setCountryCode(`+${dialCode}`);

        // If no number is entered, set a placeholder with the new country code
        if (!mobileno) {
          setMobileno(`+${dialCode}`);
        }
      } catch (error) {
        console.error("Error getting country calling code:", error);
        setCountryCode("+");
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const {
    showSignUp,
    showLogin,
    showReset,
    showSocial,
    resetWorkMail,
    showAdditional,
    showCheckInbox,
    resetMailSend,
    updatePassword,
  } = useSelector((state) => state.modal);

  let verifyPassword;
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get("email");

    // If email exists in the query, dispatch toggleReset to open the reset password modal
    if (emailParam) {
      const decryptMail = window.atob(emailParam);
      setEncryptedMail(decryptMail);
      dispatch(toggleReset());
    }
  }, [location, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset errors
    setEmailError(false);
    setPasswordError(false);
    setPasswordMatchError(false);
    if (!isValidEmail(workMail)) {
      setEmailError(true);
      toast.error("Invalid email format");
      return;
    }

    if (!isValidPassword(createPassword)) {
      setPasswordError(true);
      toast.error("Password must be at least 8 characters long.");
      return;
    }

    if (!doPasswordsMatch(createPassword, confirmPassword)) {
      setPasswordMatchError(true);
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
      const signupData = {
        email: workMail,
        name: fullName,
        password: createPassword,
        phone: mobileno,
      };

      const response = await register(signupData);

      // Store the tokens and vendor ID in localStorage
      // localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("vendorId", response?.newVendor?._id);

      // Check if vendorId is stored correctly
      const storedVendorId = localStorage.getItem("vendorId");
      if (!storedVendorId) {
        throw new Error("Failed to store vendor ID. Please try again.");
      }

      // Proceed only if vendorId is stored successfully
      setApiResponse(response);
      setIsSubmitted(true);
      setLoading(false);
      toast.success("Please verify your email address.");
    } catch (error) {
      setLoading(false);
      console.error("Error registering venue:", error);
      toast.error(error.message || "An error occurred during registration.");
    }
  };

  const isValidPassword = (password) => {
    const minLength = 8;
    const maxLength = 30;
    return password.length >= minLength && password.length <= maxLength;
  };
  const [processingTime, setProcessingTime] = useState(false);
  const handleRetryRegistration = async () => {
    try {
      setProcessingTime(true);
      const signupData = {
        email: workMail.toLowerCase(),
        name: fullName,
        password: createPassword,
      };

      const response = await register(signupData);
      setApiResponse(response);
      setIsSubmitted(true);
      toast.success(response.message);
    } catch (error) {
      console.error("Error registering venue:", error);
    } finally {
      setProcessingTime(false);
    }
  };

  const doPasswordsMatch = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const isValidEmail = (email) => {
    const freeEmailDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
    ];
    const emailParts = email.split("@");
    if (emailParts.length !== 2) return false;

    const domain = emailParts[1];
    return (
      !freeEmailDomains.includes(domain) &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    );
  };
  const isFormValid = () => {
    // Remove non-digit characters (like '+', spaces) for validation
    const cleanedNumber = mobileno.replace(/[^0-9]/g, "").slice(-10);
    // Ensure the cleaned number is between 10 and 15 digits
    const isMobileValid =
      cleanedNumber.length >= 10 && cleanedNumber.length <= 15;
    return (
      fullName.trim() !== "" &&
      workMail.trim() !== "" &&
      isMobileValid &&
      createPassword.trim() !== "" &&
      confirmPassword.trim() !== "" &&
      createPassword === confirmPassword && // Ensure passwords match
      checked
    );
  };
  // Full name validation with letters, spaces, and backspace
  const handleFullNameChange = (e) => {
    const inputName = e.target.value;
    const lastChar = inputName.slice(-1);

    // Allow backspace or valid characters
    if (lastChar === "" || /^[a-zA-Z\s]*$/.test(lastChar)) {
      setFullName(inputName);
    } else {
      toast.error("Full name should only contain letters and spaces.");
    }
  };
  return (
    <>
      <ResponsiveAppBar />
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "30%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "65vh",
        }}
      >
        {isSubmitted ? (
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
            }}
          >
            <Box
              sx={{
                // mt: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "85%",
              }}
            >
              <EmailVerification
                email={workMail}
                password={(verifyPassword = "email")}
              />
              <MessageCard
                loaderComponent={
                  <img
                    src="/assets/verify-email_icon.png"
                    alt="verify-email_icon"
                  />
                }
                processingTime={processingTime}
                primaryText={"Verify your email"}
                secondaryText={`We've sent an email to ${workMail} to verify your email address and activate your\naccount.`}
                richText={"If you haven't received an email after a while,"}
                richLinkText={"click here to try again"}
                onRichLinkClick={handleRetryRegistration}
              />
            </Box>
          </Container>
        ) : (
          <>
            <Container>
              <Box
                sx={{
                  mt: "11px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="p"
                  gutterBottom
                  className="text-center text-[24px] sm:text-[32px] w-full whitespace-nowrap"
                  sx={{
                    mt: { xs: 7, md: 10 },
                    fontWeight: 600, // Set the font weight to bold
                  }}
                >
                  Please fill in the details below{" "}
                  <br className="inline md:hidden" /> to &nbsp;
                  <span
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                    }}
                  >
                    Register
                  </span>
                </Typography>
                <div
                  onClick={() => dispatch(toggleSignUp())}
                  className="text-dark cursor-pointer mb-0 underline mx-auto"
                >
                  Register as Customer
                </div>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ mb: { md: 4 }, color: "grey.dark" }}
                >
                  Ready to list your venue?
                </Typography>
              </Box>
            </Container>
            <Container
              maxWidth="sm"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  mt: "36px",
                  width: {
                    md: "80%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  },
                }}
              >
                <form onSubmit={handleSubmit}>
                  <OutlinedLabelledTextField
                    id="fullName"
                    label="Full Name"
                    placeholder="Enter Full Name"
                    type="text"
                    maxLength={40}
                    value={fullName}
                    onChange={handleFullNameChange}
                  />

                  <OutlinedLabelledTextField
                    id="workMail"
                    label="Work email"
                    placeholder="user@workapp.com"
                    type="email"
                    value={workMail}
                    onChange={(e) => setWorkMail(e.target.value)}
                    error={emailError}
                    helperText={
                      emailError ? "Please enter a valid email address" : ""
                    }
                  />

                  <OutlinedLabelledTextField
                    id="createPassword"
                    label="Create password"
                    placeholder="Create a password"
                    type={showPassword ? "text" : "password"}
                    value={createPassword}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCreatePassword(value);
                      if (value.length >= 8) {
                        setPasswordError(false); // Reset the error if the password is valid
                      }
                    }}
                    error={passwordError || passwordMatchError}
                    helperText={
                      passwordError
                        ? "Password must be at least 8 characters long."
                        : passwordMatchError
                        ? "Passwords do not match"
                        : "Password should be at least 8 characters long."
                    }
                    eye={true}
                    showPassword={showPassword}
                    togglefunction={togglePasswordVisibility}
                  />
                  <OutlinedLabelledTextField
                    id="confirmPassword"
                    label="Confirm password"
                    placeholder="Re-enter your password"
                    type={showPasswordConfirm ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={passwordMatchError}
                    helperText={
                      passwordMatchError ? "Passwords do not match" : ""
                    }
                    eye={true}
                    showPassword={showPasswordConfirm}
                    togglefunction={togglePasswordConfirmVisibility}
                  />
                  <div className=" w-full mb-4">
                    <div className="relative">
                      <div className="absolute left-[60px] top-1/2 transform   z-10 pointer-events-none">
                        {countryCode}
                      </div>
                      <label
                        htmlFor=""
                        className="relative text-[#5E5E5E] font-outfit text-[16px] font-[300]"
                      >
                        Mobile No.
                      </label>
                      <PhoneInput
                        id="mobile-input"
                        placeholder="Enter your contact no."
                        value={mobileno}
                        defaultCountry="AE"
                        withCountryCallingCode={true}
                        autoComplete="on"
                        onCountryChange={handleCountryChange}
                        onChange={handlePhoneChange}
                        style={{
                          border: "1px solid #d1d5db",
                          boxShadow: "none",
                          borderRadius: "10px",
                          outline: "none",
                          width: "100%",
                          padding: "0.5rem",
                          // marginTop: "0.3rem",
                        }}
                        className="no-focus-border  "
                      />
                    </div>
                  </div>

                  <Box
                    sx={{ mb: { xs: 2, md: 7 } }}
                    className="relative bottom-[1px]"
                  >
                    <FormControlLabel
                      sx={{ marginInline: "auto" }}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                          sx={{
                            transform: "scale(1.5)",
                            marginRight: "10px",
                            color: "grey.border",
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            color: "grey.text",
                            fontSize: "1rem",

                            textAlign: { xs: "left" },
                          }}
                        >
                          I agree to all the{" "}
                          <Link
                            href="/terms&conditions"
                            color="primary"
                            target="_blank"
                            sx={{ textDecoration: "none" }}
                          >
                            Terms & Conditions
                          </Link>{" "}
                          and{" "}
                          <Link
                            href="/privacy-policy"
                            color="primary"
                            target="_blank"
                            sx={{ textDecoration: "none" }}
                          >
                            Privacy Policy
                          </Link>
                        </Typography>
                      }
                    />
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{
                      mb: { xs: 1, md: 3 },
                      borderRadius: "8px",
                      mt: 0,
                      backgroundColor: isFormValid()
                        ? "primary.main"
                        : "grey.border",
                      color: isFormValid() ? "white" : "text",
                      textTransform: "none", // Ensure text is not transformed to uppercase
                      "&:disabled": {
                        backgroundColor: "#DDDDDD", // Color when disabled
                        color: "#B0B0B0",
                      },
                    }}
                    disabled={!isFormValid()}
                  >
                    {loading ? <LoaderIcon /> : "Register"}
                  </Button>
                </form>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem",
                    color: "grey.text",
                    marginInline: "auto",
                  }}
                  paddingBottom={8}
                >
                  Already have an account?{" "}
                  <Link
                    href="/login"
                    color="primary"
                    sx={{ textDecoration: "none" }}
                  >
                    Log in
                  </Link>
                </Typography>
              </Box>
            </Container>
          </>
        )}
      </div>
      <Modal
        isOpen={showSignUp}
        onClose={() => dispatch(closeModals())}
        label="Sign up"
      >
        <SignUpModal
          setShowSignUp={() => dispatch(toggleSignUp())}
          onLoginClick={() => dispatch(toggleLogin())}
          onSubmit={() => dispatch(toggleCheckInbox())}
          onSocialClick={() => dispatch(toggleSocial())}
        />
      </Modal>
      <Modal
        isOpen={showLogin}
        onClose={() => dispatch(closeModals())}
        label="Log in"
      >
        <LoginModal
          onSignUpClick={() => dispatch(toggleSignUp())}
          onSubmit={() => dispatch(closeModals())}
          onSocialClick={() => dispatch(toggleSocial())}
        />
      </Modal>
      <Modal
        isOpen={showReset}
        onClose={() => dispatch(closeModals())}
        label="Reset Password"
      >
        <ResetPassword
          onSubmit={() => dispatch(closeModals())}
          mail={encryptedMail}
        />
      </Modal>
      <Modal
        isOpen={updatePassword}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password"
      >
        <PasswordUpdated
          onSubmit={() => dispatch(closeModals())}
          mail={encryptedMail}
        />
      </Modal>

      <Modal
        isOpen={resetMailSend}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password Email Sent"
      >
        <ResetMailSend onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={resetWorkMail}
        onClose={() => dispatch(closeModals())}
        label="Reset Password"
      >
        <ResetWorkMail onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={resetMailSend}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password Email Sent"
      >
        <ResetMailSend onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={showSocial}
        onClose={() => dispatch(closeModals())}
        label=""
      >
        <SocialModal
          onLoginClick={() => dispatch(toggleLogin())}
          onAdditionalClick={() => dispatch(toggleAdditional())}
        />
      </Modal>

      <Modal
        isOpen={showAdditional}
        onClose={() => dispatch(closeModals())}
        label="Additional Details"
      >
        <AdditionalDetails onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={showCheckInbox}
        onClose={() => dispatch(closeModals())}
        label=""
      >
        <CheckInboxModal />
      </Modal>
    </>
  );
}

export default Register;
