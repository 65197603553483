// src/components/Dropdown.js
import React from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';

const Dropdown = ({ label, options, value, onChange }) => {
    return (
        <div className="mb-4 relative">
            <label className="block text-secondary text-sm font-outfitt mb-2">
                {label}
            </label>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 top-7 pointer-events-none">
                <BiSolidDownArrow />
            </span>
            <select
                value={value}
                onChange={onChange}
                className="rounded-lg appearance-none border  w-full py-3 px-3 text-secondary leading-tight focus:outline-none focus:shadow-outline"
            >
                <option value="" className='text-offGray text-sm font-outfitt' disable>Select the {label}</option>
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}


            </select>
        </div>
    );
};

export default Dropdown;
