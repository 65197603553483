import React, { useContext, useEffect, useState } from "react";
import { HotelContext } from "../../context/HotelContext";
import { useParams } from "react-router-dom";

const Stepper = ({
  currentStep,
  currentSubStep,
  setCurrentStep,
  setCurrentSubStep,
}) => {
  // State to track completed sub-steps
  const [completedSubSteps, setCompletedSubSteps] = useState({});

  // Initialize state for steps
  const [steps, setSteps] = useState([
    {
      title: "Overview",
      errorIcon: false,
      subSteps: ["Basic Details", "Additional Details", "Location"],
    },
    { title: "Accommodation", errorIcon: false, subSteps: [] },
    { title: "Pricing", errorIcon: false, subSteps: [] },
    { title: "Offers & Packages", errorIcon: false, subSteps: [] },
    {
      title: "Amenities",
      errorIcon: false,
      subSteps: ["Room Features", "Business Features", "Recreational Features"],
    },
    { title: "Photos & Videos", errorIcon: false, subSteps: [] },
    { title: "Preview", errorIcon: false, subSteps: [] },
  ]);

  const { id } = useParams(); // Access the dynamic id from the URL

  useEffect(() => {
    if (id) {
      if (currentStep !== parseInt(6)) {
        setCurrentStep(parseInt(0)); // Ensure it's a valid number
      }
    }
  }, [id, setCurrentStep]);

  const { formData, setFormData } = useContext(HotelContext);
  useEffect(() => {
    // Create a copy of the steps state
    const updatedSteps = [...steps];

    const updateErrorState = (stepIndex, hasError) => {
      updatedSteps[stepIndex] = {
        ...updatedSteps[stepIndex],
        errorIcon: hasError,
      };
    };

    // Validate Overview step
    const validateOverview = () => {
      const basicDetails = formData?.overview?.basicDetails || {};
      const additionalDetails = formData?.overview?.additionalDetails || {};
      return (
        !basicDetails?.hotelName ||
        !basicDetails?.hotelRating ||
        !basicDetails?.hotelWebsite ||
        !basicDetails?.hotelDescription ||
        !additionalDetails?.hotelBuiltYear ||
        !additionalDetails?.tradeLicenseFile
      );
    };

    const validateAccomodation = () => {
      const accommodation = formData?.accommodation?.accommodations;
      return !accommodation || accommodation === 0; // Returns true for errors (no data or 0)
    };
    // Validate Pricing step (Show cross if empty)
    const validatePricing = () => {
      return (
        !formData?.pricing?.basePrice ||
        Object.keys(formData?.pricing).length === 0
      );
    };

    const validateAmenities = () => {
      const roomFeatures = formData?.amenities?.roomFeatures || {};
      const businessFeatures = formData?.amenities?.businessFeatures || {};

      const hasSelectedFeature = Object.values(roomFeatures).some(
        (isEnabled) => isEnabled
      );
      const hasSelectedFeature2 = Object.values(businessFeatures).some(
        (isEnabled) => isEnabled
      );

      return !hasSelectedFeature || !hasSelectedFeature2;
    };

    const validatePhotosVideos = () => {
      return (
        !formData?.photoVideo?.images ||
        formData?.photoVideo?.images?.length === 0
      );
    };

    const updateFormDataWithErrors = (field, hasError) => {
      if (formData?.errors?.[field] !== hasError) {
        setFormData((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: hasError },
        }));
      }
    };

    if (currentStep >= 0) {
      const overviewError = validateOverview();
      updateErrorState(0, overviewError);
      updateFormDataWithErrors("overview", overviewError);

      const accomodationError = validateAccomodation();
      updateErrorState(1, accomodationError);
      updateFormDataWithErrors("accommodation", accomodationError);

      const pricingError = validatePricing();
      updateErrorState(2, pricingError); // Step index 2 is "Pricing"
      updateFormDataWithErrors("pricing", pricingError);

      const photosVideosError = validatePhotosVideos();
      updateErrorState(5, photosVideosError);
      updateFormDataWithErrors("photosVideos", photosVideosError);

      const amenitiesError = validateAmenities();
      updateErrorState(4, amenitiesError);
      updateFormDataWithErrors("amenities", amenitiesError);
    }

    setSteps(updatedSteps);
  }, [currentStep, currentSubStep, formData]);

  const showTickMark = (stepIndex) => {
    return stepIndex < currentStep && !steps[stepIndex].errorIcon;
  };

  const showExclamationMark = (stepIndex) => {
    return stepIndex < currentStep && steps[stepIndex].errorIcon;
  };

  const getStepIndicatorClass = (stepIndex) => {
    if (stepIndex < currentStep) {
      return steps[stepIndex].errorIcon ? "bg-[#FFF6E6]" : "bg-green1";
    }
    return "bg-transparent";
  };

  const handleSubStepClick = (index, subIndex) => {
    setCurrentSubStep(subIndex);

    // Mark the sub-step as completed
    setCompletedSubSteps((prevCompleted) => ({
      ...prevCompleted,
      [`${index}-${subIndex}`]: true, // Mark the step as completed
    }));
  };

  return (
    <div className="w-full bg-white p-4">
      <ul className="relative lg:space-y-3 flex lg:block overflow-x-auto">
        {steps.map((step, index) => (
          <div key={index}>
            <li
              id={`step-${index}`} // Assigning a unique ID to each step
              className={`flex lg:items-center lg:flex-row flex-col font-normal cursor-pointer relative mb-10 min-w-32 ${
                currentStep === index || Math.floor(currentStep) === index
                  ? "text-[#039f8d]"
                  : "text-[#B0B0B0]"
              }`}
              onClick={() => setCurrentStep(index)}
            >
              <div className="flex items-center">
                <span
                  className={`w-7 h-7 flex items-center justify-center font-normal rounded-full border-2 ${
                    currentStep === index || Math.floor(currentStep) === index
                      ? "border-green1"
                      : "border-[#B0B0B0]"
                  } ${getStepIndicatorClass(index)}`}
                >
                  {showTickMark(index) && (
                    <svg
                      className="w-4 h-4 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  )}
                  {showExclamationMark(index) && (
                    <svg
                      className="w-4 h-4 text-yellow-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M4.93 4.93l14.14 14.14M4.93 19.07l14.14-14.14"
                      ></path>
                    </svg>
                  )}
                  {!showTickMark(index) && !showExclamationMark(index) && (
                    <span
                      className={`w-2 h-2 rounded-full ${
                        currentStep === index ||
                        Math.floor(currentStep) === index
                          ? "bg-green1"
                          : "bg-[#B0B0B0]"
                      }`}
                    ></span>
                  )}
                </span>
                {index !== steps.length - 1 && (
                  <span
                    className={`lg:min-h-full min-h-[2px] absolute lg:left-0 left-4 font-normal lg:top-8 top-3 lg:min-w-[2px] min-w-[100px] ${
                      currentStep > index || Math.floor(currentStep) === index
                        ? "bg-green1"
                        : "bg-[#B0B0B0]"
                    } ml-3`}
                  ></span>
                )}
              </div>
              <span
                className={`lg:ml-3 text-xs lg:text-sm font-normal ${
                  currentStep >= index ? "text-black" : "border-[#B0B0B0]"
                }`}
              >
                {step.title}
              </span>
            </li>
            {step?.subSteps?.length > 0 &&
              Math.floor(currentStep) === index && (
                <ul className="lg:ml-8">
                  {step?.subSteps?.map((subStep, subIndex) => (
                    <li
                      key={subIndex}
                      className={`flex relative items-center cursor-pointer font-normal mb-2 text-sm ${
                        currentSubStep >= subIndex
                          ? "text-black"
                          : "text-[#B0B0B0]"
                      }`}
                      onClick={() => setCurrentSubStep(subIndex)}
                    >
                      {/* Green left border for completed or active sub-steps */}
                      <span
                        className={`min-h-6 rounded-md min-w-[2px] font-normal ${
                          currentSubStep >= subIndex
                            ? "bg-green1"
                            : "bg-[#B0B0B0]"
                        } ml-3`}
                      ></span>

                      {/* Sub-step text */}
                      <span
                        className={`ml-3 font-normal ${
                          currentSubStep >= subIndex
                            ? "text-black"
                            : "text-[#B0B0B0]"
                        }`}
                      >
                        {subStep}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default Stepper;
