import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Timespentzero from "./Timespentzero";
import { base_URL } from "../../config/config";

const TimeSpentGraph = ({ startDate, endDate, venueName }) => {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const {
    data: analyticsData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["timeSpentAnalytics", startDate, endDate, venueName, vendorId],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/dashboard/timeSpentByCustomer`,
        {
          params: {
            vendorId,
            startDate: startDate?.toLocaleDateString("en-CA"),
            endDate: endDate?.toLocaleDateString("en-CA"),
            venueName,
          },
        }
      );
      return response.data;
    },
  });

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const percentageChange = payload[0]?.payload?.percentageChange;
      const isPositive = percentageChange > 0;
      const totalTime = payload[0]?.payload?.averageTime || 0;

      return (
        <div className="bg-white p-2 border border-gray-200 rounded-md shadow-sm">
          <p className="text-[10px] text-[#B0B0B0] font-medium">
            {payload[0]?.payload?.month}
          </p>
          <div className="text-[12px] text-[#222222] flex items-center gap-1">
            {Math.round(totalTime)} mins
            {percentageChange !== null && percentageChange !== undefined && (
              <span
                className={`text-[9px] p-1 rounded-lg ml-1 ${
                  isPositive
                    ? "text-green-700 bg-green-100"
                    : "text-red-700 bg-red-100"
                }`}
              >
                {isPositive ? "+" : ""}
                {percentageChange}%
              </span>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <div className="p-4 bg-white rounded-2xl animate-pulse bg-black/20"></div>
    );
  }

  if (
    !analyticsData ||
    !Array.isArray(analyticsData) ||
    analyticsData?.length === 0
  ) {
    return <Timespentzero />;
  }

  // Calculate overall average time for the header
  const averageTime = Math.round(
    analyticsData?.reduce((sum, item) => sum + (item?.averageTime || 0), 0) /
      analyticsData?.length
  );

  // Calculate total visits
  const totalVisits = analyticsData?.reduce(
    (sum, item) => sum + (item.totalVisits || 0),
    0
  );
  return (
    <div className="p-4 bg-white rounded-2xl">
      <div className="space-y-0 pb-2">
        <div className="flex items-baseline gap-2">
          <p className="text-[20px] md:text-[28px] font-bold">
            {averageTime} mins
          </p>
          <span className="text-[14px] md:text-[16px] text-[#222222]">
            Average time spent
          </span>
        </div>
        <p className="text-[12px] md:text-[14px] text-[#717171]">
          {/* Based on {totalVisits} total visits */}
          Average time spent by clients
        </p>
      </div>
      <div>
        <div className="h-[190px] w-full mt-3">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={analyticsData}
              margin={{ top: 5, right: 0, bottom: 5, left: -7 }}
            >
              <defs>
                <linearGradient
                  id="timeColorGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor="#DFDFFF" />
                  <stop offset="95%" stopColor="#DFDFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#E5E7EB"
                horizontal={true}
              />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12, fill: "#717171" }}
                dy={10}
                padding={{ left: 0, right: 0 }}
              />
              <YAxis
                dataKey="averageTime"
                axisLine={false}
                tickLine={false}
                dx={-10}
                tick={{ fontSize: 12, fill: "#717171" }}
                width={40}
              />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="averageTime"
                stroke="#7A79FF"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#timeColorGradient)"
                strokeDasharray="3 3"
                dot={false}
                activeDot={{
                  r: 6,
                  fill: "#4F46E5",
                  stroke: "#FFFFFF",
                  strokeWidth: 2,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TimeSpentGraph;
