import React, { useState } from "react";
import BrochureModal from "../BrochureModal/BrochureModal";

const defaultAwards = [
  { year: "2016", title: "Best Hotel Awards Winner" },
  { year: "2017", title: "Best Hotel Awards Winner" },
  { year: "2018", title: "Best Hotel Awards Winner" },
  { year: "2019", title: "Best Hotel Awards Winner" },
];

const AboutThisHotel = ({ data }) => {
  const awards = data.additionalDetails?.awards || defaultAwards;
  const [showMore, setShowMore] = useState(false);
  console.log(data, "hotels");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentType, setDocumentType] = useState(null);
  const handleViewBrochure = (type) => {
    setIsModalOpen(true);
    setDocumentType(type);
  };

  return (
    <div className="pb-5 lg:w-3/4 w-full mx-auto">
      {/* changing into margin y because need to decrease the space between section into hotel preview */}
      {/* Render the BrochureModal/floorplan modal/menu modal */}
      <BrochureModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        brochures={
          documentType === "brochure"
            ? data?.additionalDetails?.brochureFiles
            : documentType === "sampleMenus"
            ? data?.UploadDocuments?.sampleMenus
            : documentType === "floorPlans"
            ? data?.UploadDocuments?.floorPlans
            : [] // Default to an empty array if no match
        }
        title={
          documentType === "brochure"
            ? "Brochure"
            : documentType === "floorPlans"
            ? "Floor Plan"
            : documentType === "sampleMenus"
            ? "Sample Menu"
            : "Document"
        }
      />
      <div className="bg-white py-6   rounded-lg border-t   mb-0">
        <h2 className="text-2xl font-bold mb-4">About This Hotel</h2>
        <div className="flex justify-between ">
          {/* Conditionally render the "View Brochure" button */}
          {data?.additionalDetails?.brochureFiles?.length > 0 && (
            <button
              onClick={() => handleViewBrochure("brochure")}
              className="text-primary font-medium mb-4"
            >
              View Brochure
            </button>
          )}
          {/* floorplans */}
          {data?.UploadDocuments?.floorPlans?.length > 0 && (
            <button
              onClick={() => handleViewBrochure("floorPlans")}
              className="text-primary font-medium mb-4"
            >
              View Floor Plans
            </button>
          )}
          {/* menus */}
          {data?.UploadDocuments?.sampleMenus?.length > 0 && (
            <button
              onClick={() => handleViewBrochure("sampleMenus")}
              className="text-primary font-medium mb-4"
            >
              View Sample Menus
            </button>
          )}
        </div>
        {/* <p className={`text-gray-700 mb-4 ${showMore ? "" : "line-clamp-3"}`}>
          {data?.basicDetails?.hotelDescription}
        </p> */}
        <pre
          className={`whitespace-pre-line font-outfit font-[400] text-[#222222] mb-4 ${
            showMore ? "" : "line-clamp-3"
          }`}
        >
          {data?.basicDetails?.hotelDescription}
        </pre>
        <button
          onClick={() => setShowMore(!showMore)}
          className="text-primary font-medium"
        >
          {showMore ? "Show Less" : "Show More"}
        </button>
      </div>
      {awards?.length > 0 &&
        awards[0]?.name !== "" &&
        awards[0]?.year !== "" && (
          <div className="bg-white py-6 rounded-lg border-t md:flex block justify-between  gap-5 items-start mb-0">
            <h2 className="text-2xl font-bold mb-4 lg:w-1/5 w-full">Awards</h2>
            <ul className="space-y-2 lg:w-[73%] flex-col  flex justify-center w-full">
              {awards?.map((award, index) => (
                <li key={index} className="flex gap-5 items-center text">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.8435 3.00433L20.0552 0.5H4.01331L4.22479 3.00433H0.703125V5.66797C0.703125 8.67877 2.60449 11.2534 5.26923 12.2556C5.44757 12.7128 5.67371 13.1511 5.94745 13.567C6.78204 14.8344 7.98193 15.7739 9.35925 16.2857V19.5192L6.26093 20.7245V24.5H17.8074V20.7245L14.709 19.5192V16.2857C16.0864 15.7739 17.2864 14.8344 18.121 13.5668C18.3948 13.1511 18.6209 12.7128 18.7991 12.2556C21.464 11.2534 23.3654 8.67877 23.3654 5.66797V3.00433H19.8435ZM21.9591 5.66797C21.9591 7.72003 20.8552 9.51868 19.2101 10.5025L19.7249 4.41058H21.9591V5.66797ZM16.4013 23.0938H7.66718V21.6864L10.1944 20.7031H13.8739L16.4013 21.6864V23.0938ZM13.3028 19.2969H10.7657V16.6479C11.1083 16.699 11.4569 16.7257 11.8096 16.7257H12.2589C12.6116 16.7257 12.9602 16.699 13.3028 16.6479V19.2969ZM12.2589 15.3196H11.8096C9.91571 15.3196 8.16339 14.3752 7.12189 12.7935C6.70313 12.1574 6.42224 11.4554 6.28674 10.7065L5.54334 1.90625H18.5251L17.7817 10.7063C17.6462 11.4554 17.3654 12.1574 16.9466 12.7935C15.9051 14.3752 14.1526 15.3196 12.2589 15.3196ZM2.10938 5.66797V4.41058H4.34363L4.85815 10.5025C3.21332 9.51868 2.10938 7.72003 2.10938 5.66797Z"
                      fill="#222222"
                    />
                    <path
                      d="M14.7982 11.8723L14.7231 8.94171L16.5066 6.61481L13.6961 5.78076L12.0342 3.3656L10.3724 5.78076L7.56189 6.61481L9.34534 8.94153L9.27008 11.8723L12.0342 10.8953L14.7982 11.8723ZM12.8192 6.98743L14.1471 7.38147L13.3044 8.48083L13.3401 9.8653L12.0342 9.40369L10.7283 9.8653L10.7639 8.48065L9.92139 7.38147L11.2491 6.98743L12.0342 5.84631L12.8192 6.98743Z"
                      fill="#222222"
                    />
                  </svg>

                  <p>
                    {award.name} -{" "}
                    {new Date(award.year).toLocaleDateString("en-GB", {
                      // month: 'long',
                      year: "numeric",
                    })}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        )}
    </div>
  );
};

export default AboutThisHotel;
