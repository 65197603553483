import axios from "axios";
import { base_URL } from "../config/config";
// const VendorId = localStorage?.getItem("vendorId");

// Create an axios instance with default configurations
const API_BASE_URL = `${base_URL}/api`;
const apiService = axios.create({
  baseURL: base_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getAllVenues = async (page = 3, limit = 10) => {
  try {
    const response = await apiService.get(`api/venueform/getAllVenueForms`, {
      params: { page, limit },
    });
    return response.data.forms;
  } catch (error) {
    console.error("Error fetching venue forms:", error);
    throw error;
  }
};
// Function to get venue data by vendor ID
export const getVenueData = async (vendorId) => {
  try {
    const response = await apiService.get(
      `/api/venueform/getVenue/${vendorId}`
    );
    return response.data.venueForms; // Adjust the response data handling based on actual API response
  } catch (error) {
    console.error("Error fetching venue data:", error);
    throw new Error(
      error.response?.data?.error ||
        "An error occurred while fetching venue data."
    );
  }
};

export const getVenueDataById = async (id) => {
  try {
    const response = await apiService.get(`/api/venueform/getVenue/${id}`);
    return response.data.venueForm; // Adjust the response data handling based on actual API response
  } catch (error) {
    console.error("Error fetching venue data:", error);
    throw new Error(
      error.response?.data?.error ||
        "An error occurred while fetching venue data."
    );
  }
};

// Function to save venue data
export const saveVenueData = async (data) => {
  try {
    const response = await apiService.post(
      "/api/venueform/createVenueForm",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error saving venue data:", error);
    throw new Error(
      error.response?.data?.error ||
        "An error occurred while saving venue data."
    );
  }
};

export const saveDataForVenue = async (data) => {
  try {
    const response = await apiService.post("/api/venueform/createVenue", data);
    return response.data;
  } catch (error) {
    console.error("Error saving venue data:", error);
    throw new Error(
      error.response?.data?.error ||
        "An error occurred while saving venue data."
    );
  }
};

export const updateVenueForm = async (data) => {
  try {
    const response = await apiService.post(
      "/api/venueform/updateVenueForm",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error saving venue data:", error);
    throw new Error(
      error.response?.data?.error ||
        "An error occurred while saving venue data."
    );
  }
};

// Function to get venue Details by the venuId
export const getVenueDetails = async (venueId) => {
  try {
    const response = await apiService.get(
      `/api/vendor/venue-detail/${venueId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching venue details:", error);
    throw new Error(
      error.response?.data?.error ||
        "An error occurred while fetching venue detals."
    );
  }
};

// Function to get venue data by vendor ID
export const getAlcoholicVenueCount = async (VendorId) => {
  try {
    const response = await apiService.get(
      `/api/venueform/getAlcoholicVenueCount/${VendorId}`
    );
    return response.data.counts; // Adjust the response data handling based on actual API response
  } catch (error) {
    console.error("Error fetching venue data:", error);
    throw new Error(
      error.response?.data?.error ||
        "An error occurred while fetching venue data."
    );
  }
};

export const deleteItem = async ({ category, id }) => {
  const response = await axios.delete(
    `${API_BASE_URL}/venueform/deleteItem/${category}/${id}`
  );
  return response.data;
};

export const getSingleVenue = async ({ category, id }) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/venueform/getSingleVeune/${category}/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching onboarding details:", error);
  }
};
