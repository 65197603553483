import { createSlice } from "@reduxjs/toolkit";
const storedUser = localStorage.getItem("authUser");

const initialState = {
  formData: {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    newsletterSubscription: false,
  },
  additionalData: {
    select: "",
    companyName: "",
    mobileNumber: "",
  },
  isSubmitting: false,
  showSignUp: true,
  showAdditional: false,
  resendEmail: "",
  user: storedUser ? JSON.parse(storedUser) : null,
  compareVenues: [],
  compareVLink: "",
  compareVenuesAndStay: [],
  compareVSLink: "",
};

const authSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      state.formData = {
        ...state.formData,
        [action.payload.name]: action.payload.value,
      };
    },
    setAdditionalData: (state, action) => {
      state.additionalData = {
        ...state.additionalData,
        [action.payload.name]: action.payload.value,
      };
    },
    toggleCheckBox: (state) => {
      state.formData.newsletterSubscription =
        !state.formData.newsletterSubscription;
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    showSignUpModal: (state) => {
      state.showSignUp = true;
      state.showAdditional = false;
    },
    setResendEmail: (state, action) => {
      state.resendEmail = action.payload; // Separate handler for resendEmail
    },
    showAdditionalModal: (state) => {
      state.showSignUp = false;
      state.showAdditional = true;
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
      state.additionalData = initialState.additionalData;
      state.isSubmitting = false;
    },
    setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem("authUser", JSON.stringify(state.user));
      state.favorite = action.payload.favoriteFolder;
    },
    clearUser(state) {
      state.user = null;
      localStorage.removeItem("authUser");
    },
    setCompareVenues(state, action) {
      state.compareVenues = action.payload;
    },
    setCompareVenuesAndStay(state, action) {
      state.compareVenuesAndStay = action.payload;
    },
    setCompareVLink(state, action) {
      state.compareVLink = action.payload;
    },
    setCompareVSLink(state, action) {
      state.compareVSLink = action.payload;
    },
  },
});

export const {
  setFormData,
  setAdditionalData,
  toggleCheckBox,
  setSubmitting,
  showSignUpModal,
  setResendEmail,
  showAdditionalModal,
  resetFormData,
  setUser,
  clearUser,
  setCompareVenues,
  setCompareVenuesAndStay,
  setCompareVLink,
  setCompareVSLink,
} = authSlice.actions;

export default authSlice.reducer;
