import React, { useState } from "react";
import { toast } from "react-toastify";

const CancelSubscriptionModal = ({ isOpen, onClose, onContinue }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [enterReason, setEnterReason] = useState("");
  if (!isOpen) return null;

  const handleCancellationDone = () => {
    if (!selectedReason) {
      // Show error if no reason is selected
      toast.error("Please select a reason for cancellation.", {
        position: "top-center",
        hideProgressBar: true,
      });
    } else if (!enterReason) {
      toast.error("Please provide a description for cancellation.", {
        position: "top-center",
        hideProgressBar: true,
      });
    } else {
      localStorage.setItem("selectedReason", selectedReason);
      localStorage.setItem("enterReason", enterReason);
      setSelectedReason("");
      setEnterReason("");
      onContinue();
    }
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-[90%] md:w-[681px] md:h-[482px] p-6 rounded-xl shadow-lg relative">
        <div className=" flex justify-end ">
          <img
            src="/cancelsub.svg"
            alt=""
            onClick={onClose}
            className="cursor-pointer active:opacity-55"
          />
        </div>
        <h2 className="text-[22px] md:text-[32px] text-center  text-[#1D1F2C] font-bold">
          Cancel Subscription
        </h2>
        {/* content */}
        <div className="flex flex-col  md:w-[601px] mt-[22px] md:mt-[32px] ">
          <p className="mb-4 text-[#222222] text-[12px] md:text-[16px] font-normal ">
            We're sorry to see you go! We'd really appreciate it if you could
            let us know what we could be doing better. We&apos;re always trying
            to improve and we value customer feedback above all.
          </p>
          <label className="block mb-2 text-[12px] md:text-[14px] font-normal text-[#5E5E5E] mt-[6px] md:mt-[16px]">
            Reason to cancel your subscription
          </label>
          <select
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
            className="block w-full p-2 border rounded-lg border-[#B0B0B0] "
          >
            <option value="">Select a reason</option>
            <option value="Venue closed down">Venue closed down</option>
            <option value="Using another platform">
              Using another platform
            </option>
            <option value="Too expensive">Too expensive</option>
            <option value="Tech issues and supportes">
              Tech issues and support
            </option>
            <option value="Other">Other</option>
          </select>
          <textarea
            value={enterReason}
            onChange={(e) => setEnterReason(e.target.value)}
            placeholder="Enter your reason"
            className="w-full p-2 border border-[#DDDDDD] rounded-lg mt-[16px]"
          ></textarea>
          <button
            onClick={handleCancellationDone}
            className="bg-[#FE4747] active:opacity-55 text-white p-2 rounded-xl w-full md:w-[400px] mx-auto mt-[30px]"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
