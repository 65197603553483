import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { base_URL } from "../../config/config";
import { useQuery } from "@tanstack/react-query";

function MovetoOtherFolderModal({ preName, itemId, type, isOpen, onClose }) {
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const handleCheckboxChange = (itemName) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === itemName ? null : itemName
    );
  };

  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-folders/${userData?._id}`
      );
      if (!response.ok) {
        console.log("Failed to fetch Venues");
        return;
      }
      const data = await response.json();
      // console.log(data);
      const folders = data.folders.filter((folder) => folder.name !== preName);
      return folders;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: foldersInfo } = useQuery({
    queryKey: ["favoriteFolder", userData?._id],
    queryFn: getFavoriteFolder,
    enabled: !!userData?._id, // Ensure the query only runs when these values are available
  });

  const handleMove = async () => {
    setIsLoading(true);
    try {
      await fetch(`${base_URL}/api/auth/move-item`, {
        method: "PUT", // Using the HTTP method 'PUT' as in your original code
        headers: {
          "Content-Type": "application/json", // Set content type to JSON
        },
        body: JSON.stringify({
          customerId: userData?._id,
          prevFolderName: preName,
          newFolderName: selectedItem,
          itemId,
          type,
        }), // Convert the data to JSON
      });

      toast.success(`Successfully moved to ${selectedItem}`);
      setIsLoading(false);
      navigate("/my-favorites");
    } catch (error) {
      // Handle network or other unexpected errors
      console.error("Error moving item:", error);
      toast.error("An unexpected error occurred");
      setIsLoading(false);
    } finally {
      setIsLoading(false); // Always stop the loading spinner
    }
  };

  if (!isOpen) return;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg py-4 px-8 space-y-3 max-h-[70vh] w-[460px] flex flex-col justify-center items-center">
          <div className="flex justify-center items-center relative w-full">
            <p className="text-center text-[24px] mb-4">Move to other list</p>
            <RxCross2
              size={22}
              className="absolute top-1 right-0 cursor-pointer text-dark"
              onClick={onClose}
            />
          </div>
          <div className="grid grid-cols-2 w-full overflow-y-auto gap-y-4 gap-x-1">
            {foldersInfo && foldersInfo.length > 0 ? (
              foldersInfo?.map((item) => (
                <div
                  key={item.name}
                  className="h-[270px] w-[187px] bg-white shadow-md  rounded-md"
                >
                  <img
                    src={item.image}
                    alt=""
                    className="h-[182px] w-full object-cover"
                  />
                  <div className="w-full p-2">
                    <div className="flex justify-between items-center">
                      <span className="text-[16px]">{item.name}</span>
                      <input
                        type="checkbox"
                        className="w-5 h-5 p-2 checked:bg-dark"
                        checked={selectedItem === item.name}
                        onChange={() => handleCheckboxChange(item.name)}
                      />
                    </div>
                    <p className="text-[14px]">{item?.count || 0} saved</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center">
                No items available
              </div>
            )}
          </div>

          <div className="flex justify-center items-center w-full">
            <button
              onClick={handleMove}
              className="bg-primary mt-5 text-white rounded-lg w-[40%] py-2 text-sm"
            >
              {isLoading ? "Moving..." : "Move"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MovetoOtherFolderModal;
