const cityCoordinates = {
    "Dubai": { lat: 25.276987, lng: 55.296249 },
    "Abu Dhabi": { lat: 24.453884, lng: 54.377344 },
    "Ras Al Khaimah": { lat: 25.800692, lng: 55.976200 },
    "Sharjah": { lat: 25.346255, lng: 55.420932 },
    "Ajman": { lat: 25.405216, lng: 55.513643 },
    "Fujairah": { lat: 25.128809, lng: 56.326485 },
    "Umm Al Quwain": { lat: 25.564733, lng: 55.553346 },
  };
  
  export function getCityCoordinates(city) {
    if(!city) {
      return { lat: 25.276987, lng: 55.296249 };
    }
    const coordinates = cityCoordinates[city];
    return coordinates ? { ...coordinates } : null;
  }