/* eslint-disable */
import React, { useState } from "react";
import Navbar from "../../../components/NavbarCustomer";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { IoMdHeart } from "react-icons/io";
import { base_URL } from "../../../config/config";
import { useQuery } from "@tanstack/react-query";

function MyFavorites({ filters, setFilters }) {
  const userData = JSON.parse(localStorage.getItem("authUser"));

  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-folders/${userData?._id}`
      );
      if (!response.ok) {
        console.log("Failed to fetch Venues");
        return;
      }
      const data = await response.json();
      // console.log(data);
      return data.folders;
    } catch (err) {
      console.log(err);
    }
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["favoriteFolder", userData?._id],
    queryFn: getFavoriteFolder,
    enabled: !!userData?._id, // Ensure the query only runs when these values are available
  });

  return (
    <>
      <Navbar filters={filters} setFilters={setFilters} />

      <div className="bg-white sm:px-8 px-4 py-5">
        <div className="text-[#717171] flex items-center my-4 gap-2">
          <Link to="/">Home</Link>
          <RiArrowRightSLine />
          <p className="text-dark">My Favorites</p>
        </div>
        <div>
          <h1 className="text-[24px] font-medium">
            My Favorites {` (${data?.length})` || " (0)"}
          </h1>
        </div>
        {isLoading ? (
          "Loading..."
        ) : isError ? (
          error.message
        ) : data && data?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 py-3 w-full">
            {data?.map((folder, index) => {
              return (
                <div
                  className="bg-white rounded-lg shadow-md h-[370px] cursor-pointer"
                  key={index}
                >
                  <Link
                    to={`/favorite-detail/${userData?._id}/${folder?.name}`}
                  >
                    <img
                      src={folder?.image}
                      alt="venue"
                      className="w-full h-[288px] object-cover rounded-lg"
                    />
                    <div className="p-3">
                      <h1 className="text-[20px] font-medium">
                        {folder?.name}
                      </h1>
                      <p className="text-[#717171] text-[16px]">
                        {folder?.count} saved
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center h-[50vh] gap-3 w-full">
            <IoMdHeart size={47} fill="black" />
            <h1 className="text-[24px] font-medium text-center">
              You don't have any favorite list yet. Favorite a venue to get
              started.
            </h1>
            <div className="flex justify-center w-full">
              <Link to="/listing/1">
                <button className="bg-primary text-white rounded-lg w-[120px] py-2 text-sm">
                  Explore Venues
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MyFavorites;
