import React, { useState } from "react";

const PriceRangeGraph = ({
  minSpendPerPerson,
  setMinSpendPerPerson,
  updateFilters,
}) => {
  const [activeSlider, setActiveSlider] = useState(null);
  const min = minSpendPerPerson?.min || 0;
  const max = minSpendPerPerson?.max || 6000;

  const barHeights = [
    60, 90, 120, 180, 140, 160, 100, 80, 90, 40, 60, 130, 60, 90, 80, 90, 40,
    60, 130,
  ];

  const getBarColor = (barIdx) => {
    const barPosition = (barIdx / barHeights.length) * 6000;
    return barPosition >= min && barPosition <= max ? "#FE4747" : "#EBEBEB";
  };

  const handleMinChange = (e) => {
    setActiveSlider("min");
    const newMinValue = Math.min(Number(e.target.value), max - 50);
    setMinSpendPerPerson({ min: newMinValue, max });
    updateFilters({ min: newMinValue, max });
  };

  const handleMaxChange = (e) => {
    setActiveSlider("max");
    const newMaxValue = Math.max(Number(e.target.value), min + 50);
    setMinSpendPerPerson({ min, max: newMaxValue });
    updateFilters({ min, max: newMaxValue });
  };

  return (
    <div className="w-full max-w-lg mx-auto mt-10">
      <div className="relative flex flex-col items-center">
        <div className="flex items-end justify-between w-full h-40">
          {barHeights.map((height, idx) => (
            <div
              key={idx}
              className="w-[10px] rounded-full"
              style={{
                height: `${height}px`,
                backgroundColor: getBarColor(idx),
              }}
            ></div>
          ))}
        </div>

        <div
          className="absolute bottom-8 left-0 right-0 h-2 bg-[#FE4747]"
          style={{
            left: `${(min / 6000) * 100}%`,
            right: `${100 - (max / 6000) * 100}%`,
          }}
        ></div>

        {/* Min Slider */}
        <input
          type="range"
          min="0"
          max="6000"
          value={min}
          onChange={handleMinChange}
          className="absolute w-full bottom-10 h-2 appearance-none bg-transparent pointer-events-none range-thumb"
          style={{
            pointerEvents: "all",
            zIndex: activeSlider === "min" ? "10" : "2",
          }}
          onMouseDown={() => setActiveSlider("min")}
          onMouseUp={() => setActiveSlider(null)}
        />

        {/* Max Slider */}
        <input
          type="range"
          min="0"
          max="6000"
          value={max}
          onChange={handleMaxChange}
          className="absolute w-full bottom-7 h-2 appearance-none bg-transparent pointer-events-none range-thumb"
          style={{
            pointerEvents: "all",
            zIndex: activeSlider === "max" ? "10" : "2",
          }}
          onMouseDown={() => setActiveSlider("max")}
          onMouseUp={() => setActiveSlider(null)}
        />

        {/* Value display */}
        <div className="flex justify-between w-[100%] mt-4 text-black">
          <input
            type="number"
            value={min}
            className="border-none outline-none mr-5 text-black"
            onChange={(e) => {
              const value = Math.min(
                Math.max(Number(e.target.value), 0),
                max - 50
              );
              setMinSpendPerPerson({
                min: value,
                max,
              });
              updateFilters({ min: value, max });
            }}
          />
          <input
            type="number"
            value={max}
            className="border-none outline-none w-[13%] text-black"
            onChange={(e) => {
              const value = Math.max(
                Math.min(Number(e.target.value), 2000),
                min + 50
              );
              setMinSpendPerPerson({
                min,
                max: value,
              });
              updateFilters({ min, max: value });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceRangeGraph;
