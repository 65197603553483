import React, { useState, useRef, useEffect } from "react";
import { BsSend } from "react-icons/bs";
import {
  FaCalendarAlt,
  FaUsers,
  FaUser,
  FaMapMarkerAlt,
  FaUtensils,
  FaNotesMedical,
} from "react-icons/fa";
import { IoIosAttach } from "react-icons/io";
import Declinemodal from "../Declinemodal/Declinemodal";
import JustificationModal from "../JustificationModal/JustificationModal";
import { base_URL } from "../../../config/config";
import axios from "axios";
import { io } from "socket.io-client";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Disabledsend from "./Disabledsend";
import { useUser } from "../../../context/user";
import AWS from "aws-sdk";
import moment from "moment";
import { NoteAdd } from "@mui/icons-material";
// Configure AWS SDK
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const Vendorchat = ({ enquiryData, vednordetails, updateStatus, user }) => {
  const [declineReason, setDeclineReason] = useState("");
  const [justificationText, setJustificationText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  // for team mebers invitedBy will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [showInitialView, setShowInitialView] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [justificationModalVisible, setJustificationModal] = useState(false);
  // context
  const { selectedEnquiryDetails, setSelectedEnquiryDetails } = useUser();
  // for tracking uploading of images/docs
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  // for selecting of file(to be uploaded)
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  // Fetch messages using Tanstack Query
  const { data: fetchedMessages, isLoading } = useQuery({
    queryKey: ["messages", enquiryData?._id],
    queryFn: () => fetchMessages(enquiryData?._id),
    enabled: !!enquiryData?._id,
    onSuccess: (data) => {
      setMessages(data);
    },
  });

  // Function to fetch messages
  const fetchMessages = async (enquiryId) => {
    if (!enquiryId) return [];
    const response = await axios.get(
      `${base_URL}/api/message/get/${enquiryId}`
    );
    return response.data;
  };

  // Mutation for sending messages
  const sendMessageMutation = useMutation({
    mutationFn: (messageData) =>
      axios.post(`${base_URL}/api/message/send`, messageData),
    onSuccess: (response) => {
      // Invalidate and refetch messages
      queryClient.invalidateQueries({
        queryKey: ["messages", enquiryData?._id],
      });
      // Reset input
      setInputValue("");
    },
    onError: (error) => {
      console.error("Error sending vendor message", error);
    },
  });
  const suggestions = [
    {
      text: "Sure, I'll be glad to assist you with that. Can I have your name please?",
      id: 1,
    },
    {
      text: "To help you better, can I please know your name.",
      id: 2,
    },
    {
      text: "Yes, of course, I'll be happy to help",
      id: 3,
    },
    {
      text: "Yes, please allow me to help you with that.",
      id: 4,
    },
  ];

  // Socket connection effect
  const socketRef = useRef(null);
  useEffect(() => {
    // Create socket connection
    const newSocket = io(`${base_URL}`, {
      query: {
        userId: vendorId,
        enquiryId: enquiryData?._id,
        role: "vendor",
      },
    });

    // Store socket in ref
    socketRef.current = newSocket;

    // Connection event
    newSocket.on("connect", () => {
      // Join the specific enquiry room
      newSocket.emit("joinEnquiryRoom", enquiryData?._id);
    });

    // Listen for new messages
    newSocket.on("newMessage", (message) => {
      // Invalidate and refetch messages when a new message arrives
      queryClient.invalidateQueries({
        queryKey: ["messages", enquiryData?._id],
      });
    });

    // Cleanup socket on component unmount
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [vendorId, enquiryData?._id, queryClient]);

  // Handle sending a message for vendor (text messages)
  const handleSendMessage = async () => {
    if (!inputValue.trim()) return;

    const messageData = {
      content: inputValue,
      enquiryId: enquiryData?._id,
      customerId: enquiryData?.customerId,
      vendorId: enquiryData?.vendorId,
      // if user?.invitedBy field is there means he/she is a team member
      managerIds: user?.invitedBy ? [user?._id] : [],
      senderId: user?._id,
      senderId: enquiryData?.vendorId,
      sender: "vendor",
    };

    // Use mutation to send message
    sendMessageMutation.mutate(messageData);

    // Emit message via socket
    if (socketRef.current) {
      socketRef.current.emit("newMessage", {
        enquiryId: enquiryData?._id,
        message: messageData,
      });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value === "/") {
      setShowSuggestions(true);
    } else if (!value.startsWith("/")) {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    addMessage(suggestion, "vendor");

    setInputValue("");
    setShowSuggestions(false);
  };

  // Add new message to chat
  const addMessage = (content, sender) => {
    const messageData = {
      content: content,
      enquiryId: enquiryData?._id,
      customerId: enquiryData?.customerId,
      vendorId: enquiryData?.vendorId,
      managerIds: user?.invitedBy ? [user?._id] : [],
      senderId: enquiryData?.vendorId,
      sender: "vendor",
    };

    // Use mutation to send message
    sendMessageMutation.mutate(messageData);

    // Emit message via socket
    if (socketRef.current) {
      socketRef.current.emit("newMessage", {
        enquiryId: enquiryData?._id,
        message: messageData,
      });
    }
  };
  // active status when when accept requests
  const handleReplyAndContinue = () => {
    setShowInitialView(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }

    updateStatus(enquiryData?._id, "Active", `Active by ${user?.email}`);
    setSelectedEnquiryDetails((prevDetails) => ({
      ...prevDetails,
      status: "Active",
    }));
  };

  const handleDeclineClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setJustificationModal(true);
  };

  const handleJustificationModalClose = () => {
    setJustificationModal(false);
  };

  const handleDeclineModal = (reason) => {
    setDeclineReason(reason);
    setIsModalVisible(false);
    setJustificationModal(true);
  };

  const handleSubmitJustification = async (text) => {
    try {
      const response = await axios.post(
        `${base_URL}/api/enquiries/${enquiryData?._id}/decline`,
        {
          declineReason: declineReason,
          declineJustification: text,
          vendorId,
        }
      );
      setJustificationText(text);
      setJustificationModal(false);

      // Optionally handle successful response
      toast.success("Enquiry declined successfully");
      setSelectedEnquiryDetails((prevDetails) => ({
        ...prevDetails,
        status: "Venue Declined",
        declineReason,
      }));
    } catch (error) {
      toast.error("Error submitting decline reason:", error);
    }
  };
  // directly open chat if already chatting is been done
  useEffect(() => {
    if (
      fetchedMessages?.messages[0]?.message?.length > 0 ||
      enquiryData?.status === "Active" ||
      enquiryData?.status === "Customer Declined" ||
      enquiryData?.status === "Venue Declined"
    ) {
      setShowInitialView(false);
    }
  }, [fetchedMessages]);
  const messagesEndRef = useRef(null);

  // Scroll to bottom function
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  // Scroll on new messages or when messages load
  useEffect(() => {
    scrollToBottom();
  }, [fetchedMessages?.messages[0]?.message, showInitialView]);
  // console.log(enquiryData, "enquiryData");

  const handleSuccess = () => {
    toast.success("Enquiry successfull");
    updateStatus(
      enquiryData?._id,
      "Successful",
      `Successful by ${user?.email}`
    );
    setSelectedEnquiryDetails((prevDetails) => ({
      ...prevDetails,
      status: "Successful",
    }));
  };
  const fileInputRef2 = useRef(null);
  const handleAttachClick = () => {
    console.log("ho[");
    if (enquiryData?.status === "Closed") return;
    console.log("ho[22");
    fileInputRef2.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setIsUploading(true);
      setUploadProgress(0);

      const filename = `${Date.now()}-${file.name}`;

      // S3 upload parameters with progress tracking
      const params = {
        Key: `chats/${filename}`,
        Body: file,
        ContentType: file.type,
        Bucket: S3_BUCKET,
      };

      // Upload to S3 with progress tracking
      const uploadPromise = myBucket.upload(params);

      // Track upload progress
      uploadPromise.on("httpUploadProgress", (progress) => {
        const percentCompleted = Math.round(
          (progress.loaded / progress.total) * 100
        );
        setUploadProgress(percentCompleted);
      });

      // Wait for upload to complete
      const uploadResponse = await uploadPromise.promise();

      const messageData = {
        content: uploadResponse?.Location,
        enquiryId: enquiryData?._id,
        customerId: enquiryData?.customerId,
        vendorId: enquiryData?.vendorId,
        managerIds: user?.invitedBy ? [user?._id] : [],
        senderId: enquiryData?.vendorId,
        sender: "vendor",
        fileType: file?.type,
        fileName: file?.name,
      };

      // Send message with file
      sendMessageMutation.mutate(messageData, {
        onSettled: () => {
          // Reset upload state
          setIsUploading(false);
          setUploadProgress(0);
        },
      });

      // Emit file message via socket
      if (socketRef.current) {
        socketRef.current.emit("newMessage", {
          enquiryId: enquiryData?._id,
          message: messageData,
        });
      }
    } catch (error) {
      console.error("File upload error:", error);
      setIsUploading(false);
      setUploadProgress(0);
      toast.error("Failed to upload file. Please try again.");
    }
  };

  const formatRelativeTime = (timestamp) => {
    if (!timestamp) return "--";

    console.log(timestamp, "check");

    // Parse the date with the correct format
    const parsedDate = moment(timestamp, "DD/MM/YYYY HH:mm");

    // Check if the date is valid
    if (!parsedDate.isValid()) return "--";

    // Return the relative time in "x hours/days ago" format
    return parsedDate.fromNow();
  };

  return (
    <>
      <div className="w-full lg:w-[70%] rounded-xl bg-white">
        <div className="h-full flex flex-col relative ">
          {/* Chat Header */}
          <div className="flex flex-col md:flex-row justify-between items-center gap-3 mb-4 border-b p-4">
            <div className="flex  gap-x-2 justify-center items-center">
              {!user?.profilePic ? (
                <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                  <svg
                    className="w-6 h-6 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </div>
              ) : (
                <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
                  <img
                    src={user?.profilePic}
                    alt=""
                    className="size-10 rounded-full"
                  />
                </div>
              )}

              <div>
                <h4 className="font-medium text-[#000000] text-[13px] md:text-[20px]">
                  {vednordetails?.email}
                </h4>
                <p className=" text-[#000000] text-[14px]">
                  #{vednordetails?._id}
                </p>
              </div>
            </div>
            {/* Success and Decline Buttons */}
            {enquiryData?.status !== "Successful" &&
              enquiryData?.status !== "Venue Declined" &&
              enquiryData?.status !== "Customer Declined" && (
                <div className="flex gap-4 mt-4">
                  {/* Decline Button */}
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-xl hover:bg-red-600 transition duration-300"
                    onClick={handleDeclineClick}
                  >
                    Decline
                  </button>

                  {/* Successful Button */}
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded-xl hover:bg-green-600 transition duration-300"
                    onClick={handleSuccess}
                  >
                    Successful
                  </button>
                </div>
              )}
          </div>

          {/* Chat Messages Area */}
          <div className="flex-1 p-4 overflow-y-auto">
            {/* Enquiry Box */}
            <div className="sm:mx-auto  flex flex-col w-full md:w-[40%] rounded-xl border border-gray-300 p-4 shadow-lg">
              {/* Sender Info */}
              <div className="flex items-center mb-2">
                <div className="font-medium text-[12px] md:text-[14px] text-gray-800">
                  <p>{enquiryData?.customerId?.fullName} sent an enquiry</p>
                </div>
              </div>
              {/* Enquiry Details with Icons */}
              <div className="text-sm text-gray-600 text-[10px] md:text-[12px]">
                <div className="flex items-center">
                  <FaUsers className="mr-2 text-gray-600" />
                  <p>Party for {enquiryData?.guests} guests</p>
                </div>
                <div className="flex items-center">
                  <FaMapMarkerAlt className="mr-2 text-gray-600" />
                  <p>
                    {enquiryData?.hotelName} {enquiryData?.venueName}
                  </p>
                </div>
                <div className="flex items-center">
                  <FaCalendarAlt className="mr-2 text-gray-600" />
                  <p>
                    {enquiryData?.startDate} - {enquiryData?.endDate}
                  </p>
                </div>
                <div className="flex items-center">
                  <FaUtensils className="mr-2 text-gray-600" />
                  <p>Event Type ({enquiryData?.eventType})</p>
                </div>
                {enquiryData?.additionalNotes !== "" && (
                  <div className="flex items-center">
                    <FaNotesMedical className="mr-2 text-gray-600" />
                    <p>Notes ({enquiryData?.additionalNotes})</p>
                  </div>
                )}
              </div>

              {/* Date */}
              <div className="text-xs text-gray-500 mt-2">
                <p>{enquiryData?.createAt}</p>
              </div>
            </div>
            {showInitialView ? (
              <div className="flex flex-col items-center justify-center h-full">
                <img src="clock.svg" alt="" />
                <p className="text-[#1D1F2C] text-[16px] text-center md:text-[24px] font-bold">
                  You have a new enquiry from{" "}
                  {enquiryData?.customerId?.fullName}
                </p>
                <div className="text-center text-[12px]  md:text-[16px] text-[#717171]">
                  Received {formatRelativeTime(enquiryData?.createdAt)}{" "}
                </div>
                {["Venue Declined", "Customer Declined"].includes(
                  enquiryData?.status
                ) && (
                  <div className="flex gap-4 mt-[70px]">
                    <button
                      onClick={handleReplyAndContinue}
                      className="md:w-[180px] px-1 md:px-4 py-2 border-2 border-[#27C2B0] text-[#27C2B0] rounded-lg text-[16px] font-medium active:opacity-55 transition-colors"
                    >
                      Reply and Continue
                    </button>
                    <button
                      onClick={handleDeclineClick}
                      className="md:w-[180px] px-1 md:px-4 py-2 border-2 border-[#D03660] text-[#D03660] rounded-lg text-[16px] font-medium active:opacity-55 transition-colors"
                    >
                      Decline
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="space-y-4 max-h-[50vh]">
                {isLoading ? (
                  <div className="text-center">Loading messages...</div>
                ) : (
                  fetchedMessages?.messages[0]?.message?.map(
                    (message, index) => (
                      <div
                        key={index}
                        className="flex justify-start gap-2 items-start"
                      >
                        <div
                          className={`w-full flex flex-col mt-3 justify-${
                            message?.sender === "customer" ? "start" : "end"
                          } items-${
                            message?.sender === "customer" ? "start" : "end"
                          }`}
                        >
                          <div className="flex justify-start items-start gap-2 md:max-w-[80%] mx-w-[90%]">
                            {message?.sender === "customer" && (
                              <div className="min-h-[40px] min-w-[40px] rounded-full flex justify-center items-center bg-[#eaeff1]">
                                {!fetchedMessages?.customerProfilePic ? (
                                  <img src="/messageprofile.svg" alt="" />
                                ) : (
                                  <img
                                    src={fetchedMessages?.customerProfilePic}
                                    alt=""
                                    className="size-10 rounded-full"
                                  />
                                )}
                              </div>
                            )}
                            <div
                              className={`flex flex-col -mt-[14px] ${
                                message?.sender === "customer"
                                  ? "items-start"
                                  : "items-end justify-end"
                              }`}
                            >
                              <div
                                className={`${
                                  message?.sender === "customer"
                                    ? "border border-[#DDDDDD] rounded-r-[12px] text-[#222222]"
                                    : "bg-[#dfdfff] text-[#222222] rounded-l-[12px]"
                                } w-[fit-content] p-3 rounded-t-[12px] mt-3 mb-1`}
                              >
                                {message?.isFile ? (
                                  <div className="flex items-center gap-2">
                                    {message?.fileType?.includes("image") ? (
                                      <img
                                        src={message?.content}
                                        alt={message?.fileName}
                                        className="max-w-[200px] max-h-[200px] object-cover"
                                      />
                                    ) : (
                                      <a
                                        href={message?.content}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 underline"
                                      >
                                        {message?.fileName}
                                      </a>
                                    )}
                                  </div>
                                ) : (
                                  <p className="text-[14px]">
                                    {message?.content}
                                  </p>
                                )}
                              </div>
                              <p
                                className={`text-[12px] mb-2 text-[#717171] ${
                                  message?.sender === "customer"
                                    ? "text-left"
                                    : "text-right flex justify-end items-end"
                                }`}
                              >
                                {new Date(message?.time).toLocaleTimeString(
                                  [],
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </p>
                            </div>
                            {message?.sender === "vendor" && (
                              <div className="min-h-[40px] min-w-[40px] rounded-full flex justify-center items-center bg-[#eaeff1]">
                                {!fetchedMessages?.vendorProfilePic ? (
                                  <img src="/messageprofile.svg" alt="" />
                                ) : (
                                  <img
                                    src={fetchedMessages?.vendorProfilePic}
                                    alt=""
                                    className="size-10 rounded-full"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )
                )}
                {/* Lost message */}
                {enquiryData?.status === "Lost" && (
                  <p className="text-[10px] lg:text-[14px] text-[#5E5E5E] text-center">
                    The enquiry has been officially discontinued.
                  </p>
                )}
                {(enquiryData?.status === "Customer Declined" ||
                  enquiryData?.status === "Venue Declined") && (
                  <div className="sm:mx-auto mt-4   flex flex-col w-full md:w-[55%] rounded-xl border border-gray-300 p-4 shadow-lg">
                    {/* Sender Info */}
                    <div className="flex items-center mb-2">
                      {enquiryData?.status === "Customer Declined" ? (
                        <div className="font-medium text-[12px] md:text-[14px] text-gray-800">
                          <p>
                            Enquiry declined by{" "}
                            {enquiryData?.customerId?.fullName}
                          </p>
                        </div>
                      ) : (
                        <div className="font-medium text-[12px] md:text-[14px] text-gray-800">
                          <p>Enquiry declined by {vednordetails?.name}</p>
                        </div>
                      )}
                    </div>
                    {/* Enquiry Details with Icons */}
                    <div className="text-sm text-gray-600 text-[10px] md:text-[12px]">
                      <div className="flex items-center">
                        <p className="text-[12px]">Reason:</p>
                        <p className="text-[12px]">
                          {enquiryData?.declineReason || "No reason"}
                        </p>
                      </div>
                    </div>
                    {/* Date */}
                    <div className="text-xs text-gray-500 mt-2">
                      <p>{enquiryData?.createAt}</p>
                    </div>
                  </div>
                )}

                {/* Invisible div to trigger scroll */}
                <div ref={messagesEndRef} />
              </div>
            )}
          </div>

          {/* Chat Input & Suggestions Container */}
          <div className="relative w-full">
            {!["Lost", "Venue Declined", "Customer Declined"].includes(
              enquiryData?.status
            ) && (
              <div className="flex justify-between gap-x-2 md:gap-x-0 items-center w-[95%] mx-auto md:py-6 py-2">
                <div className="flex justify-start items-center gap-3 md:w-[90%] w-[85%] md:px-4 px-2 rounded-[8px] h-[48px] border border-[#DDDDDD]">
                  {/* File Upload Progress */}
                  {isUploading && (
                    <div className="absolute bottom-full left-0 right-0 mb-1">
                      <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                      <div className="text-center text-xs text-gray-500 mt-1">
                        {uploadProgress}% Uploaded
                      </div>
                    </div>
                  )}
                  <IoIosAttach
                    onClick={handleAttachClick}
                    className="text-[#000000] cursor-pointer"
                    size={24}
                  />
                  {/* for sharing images and docs */}
                  <input
                    ref={fileInputRef2}
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Type something, or press '/' for auto response..."
                    className="w-[90%] h-[40px] border-none text-black placeholder-[#B0B0B0] outline-none"
                  />
                </div>
                <button
                  onClick={handleSendMessage}
                  className="w-[48px] h-[48px] cursor-pointer active:opacity-55 rounded-[6px] bg-[#FF3939] flex justify-center items-center"
                >
                  <BsSend className="text-white" size={20} />
                </button>
              </div>
            )}
            {/* Disabled send */}
            {["Lost", "Venue Declined", "Customer Declined"].includes(
              enquiryData?.status
            ) && <Disabledsend />}
            {/* Suggestions Popup */}
            {showSuggestions && (
              <div className="absolute bottom-full right-4 text-[10px] md:text-[14px] font-normal grid gap-2    p-2  ">
                {suggestions.length > 0 && (
                  <button
                    onClick={() => handleSuggestionClick(suggestions[0].text)}
                    className="inline-flex  items-center p-2 hover:bg-gray-50 cursor-pointer border bg-white border-[#7A79FF] text-[#4A49D9] rounded-full"
                  >
                    {suggestions[0].text}
                  </button>
                )}

                <div className="flex gap-2">
                  {suggestions.slice(1, 3).map((suggestion) => (
                    <button
                      key={suggestion.id}
                      onClick={() => handleSuggestionClick(suggestion.text)}
                      className="inline-flex items-center p-2 hover:bg-gray-50 bg-white cursor-pointer border border-[#7A79FF] text-[#4A49D9] rounded-full"
                    >
                      {suggestion.text}
                    </button>
                  ))}
                </div>

                {suggestions.length > 3 && (
                  <button
                    onClick={() => handleSuggestionClick(suggestions[3].text)}
                    className="inline-flex items-center p-2 hover:bg-gray-50 bg-white cursor-pointer border border-[#7A79FF] text-[#4A49D9] rounded-full"
                  >
                    {suggestions[3].text}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* show decline modal */}
      <Declinemodal
        isVisible={isModalVisible}
        onClose={handleModalClose}
        submitDeclineModal={handleDeclineModal}
      />
      {/* Show Justification Modal */}
      <JustificationModal
        isVisible={justificationModalVisible}
        onClose={handleJustificationModalClose}
        onSubmitJustification={handleSubmitJustification}
      />
    </>
  );
};

export default Vendorchat;
