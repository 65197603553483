"use client";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import houses from "../../assets/pics/houses.svg";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { TbBed } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { base_URL } from "../../config/config";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { getUser } from "../../lib/apis";
import { setUser } from "../../store/features/authSlice";
import NewFavoriteFolderModal from "../../components/myFav/NewFavoriteFolderModal";
import AddedFavoriteModal from "../../components/myFav/AddedFavoriteModal";

const HotelDetailComp = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddedOpen, setIsModalAddedOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const favoriteVenuesAndStay =
    user?.favoritesFolder?.map((folder) => folder.venuesAndStay).flat() || [];
  const dispatch = useDispatch();
  const [newFolder, setNewFolder] = useState(false);
  const [favItem, setFavItem] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);

  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-folders/${user?._id}`
      );
      if (!response.ok) {
        console.log("Failed to fetch Venues");
        return;
      }
      setIsClicked(true);
      const data = await response.json();
      refetchFav();
      return data.folders;
    } catch (err) {
      console.log(err);
    }
  };
  const { folders, refetch } = useQuery({
    queryKey: ["favoriteFolder", user?._id],
    queryFn: getFavoriteFolder,
    enabled: !!user?._id,
  });
  const isFavorite = favoriteVenuesAndStay?.some(
    (favorite) => favorite.id === data._id
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const mainCarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // lg
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // md
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // sm
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleViewPrev = (id) => {
    const cf = "hpv";
    navigate(`/hotel-preview/${cf}/${id}`);
  };
  const getUserInfo = async () => {
    const userInfo = await getUser(user?._id);
    if (userInfo) dispatch(setUser(userInfo));
    let venuesAndStay = [];
    userInfo.favoritesFolder.forEach((folder) => {
      if (folder.venuesAndStay.length > 0) {
        venuesAndStay = [...venuesAndStay, ...folder.venuesAndStay];
      }
    });
    setIsClicked(false);
    return venuesAndStay;
  };
  const { data: userFavorites, refetch: refetchFav } = useQuery({
    queryKey: ["userFavorites"],
    queryFn: getUserInfo,
  });

  const handleClickFavorite = (e, itemId) => {
    e.stopPropagation();
    if (
      userFavorites &&
      userFavorites.find((favorite) => favorite.id === itemId)
    )
      return;
    if (!itemId) return;
    if (!user) {
      return toast.error("Please login first");
    }

    if (isFavorite) return;

    setFavItem(itemId);
    user?.favoritesFolder?.length > 0
      ? setIsModalAddedOpen(true)
      : setIsModalOpen(true);
  };

  return (
    <>
      {newFolder && (
        <NewFavoriteFolderModal
          isOpen={newFolder}
          refetch={refetch}
          onClose={() => {
            setNewFolder(false);
            refetchFav();
          }}
          itemId={favItem}
          type={"venueAndStay"}
        />
      )}
      {user?.favoritesFolder?.length > 0 ? (
        <AddedFavoriteModal
          setNewFolder={setNewFolder}
          isOpen={isModalAddedOpen}
          refetch={refetch}
          itemId={favItem}
          data={folders}
          type={"venueAndStay"}
          onClose={() => {
            setIsModalAddedOpen(false);
            refetchFav();
          }}
        />
      ) : (
        <NewFavoriteFolderModal
          isOpen={isModalOpen}
          itemId={favItem}
          refetch={refetch}
          type={"venueAndStay"}
          onClose={() => {
            setIsModalOpen(false);
            refetchFav();
          }}
        />
      )}
      <div className={`w-full ${isMobileView ? "" : "grid grid-cols-4 gap-6"}`}>
        {isMobileView ? (
          <Slider {...mainCarouselSettings}>
            {data &&
              data.slice(0, 4).map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className="p-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleViewPrev(item.id);
                  }}
                >
                  <div className="bg-white rounded-lg shadow-lg cursor-pointer">
                    {item.images.length > 1 ? (
                      <Slider {...imageSettings}>
                        {item.images.map((imag, imageIndex) => (
                          <div key={imageIndex} className="w-full relative">
                            <img
                              src={item.images[imageIndex]}
                              alt="hotel img"
                              className="rounded-lg w-full h-[260px]"
                            />
                            <div
                              className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                              onClick={(e) =>
                                handleClickFavorite(e, item?.id, imageIndex)
                              }
                            >
                              {/* {isFavorite ? (
                                <IoMdHeart className="text-primary" />
                              ) : (
                                <IoMdHeartEmpty className="text-primary" />
                              )} */}
                              {userFavorites &&
                              userFavorites.find(
                                (favorite) => favorite.id === item.id
                              ) ? (
                                <IoMdHeart className="text-primary" />
                              ) : (
                                <IoMdHeartEmpty className="text-primary" />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <div className="w-full relative">
                        <img
                          src={item?.images[0]}
                          alt=""
                          className="rounded-lg w-full h-[260px]"
                        />
                        <div
                          className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                          onClick={(e) => handleClickFavorite(e, item?.id, 0)}
                        >
                          {userFavorites &&
                          userFavorites.find(
                            (favorite) => favorite.id === item.id
                          ) ? (
                            <IoMdHeart className="text-primary" />
                          ) : (
                            <IoMdHeartEmpty className="text-primary" />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col items-start gap-2 p-3">
                      <h1
                        className="text-darkGray lg:text-xl text-base font-medium cursor-pointer hover:text-[#FE4747]"
                        onClick={() => handleViewPrev(item?.id)}
                      >
                        {item.name}
                      </h1>
                      <div className="flex justify-start gap-3">
                        {item.rating}
                      </div>
                      <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                        {item.loc}
                      </p>
                      <div className="flex items-center w-full justify-between gap-4">
                        <div className="flex items-center gap-1">
                          <TbBed className="text-sm text-primary" />
                          <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                            {item.room}
                          </p>
                        </div>
                        <h1 className="text-secondary text-xl">•</h1>
                        <div className="flex items-center gap-1">
                          <img src={houses} alt="" />
                          <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                            {item.venu}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-col w-full ">
                        <h1 className="text-primary lg:text-lg sm:text-base text-sm ">
                          {item.aed}
                        </h1>
                        <p className="text-secondary sm:text-sm text-[10px] mt-1">
                          {item.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        ) : (
          data &&
          data.slice(0, 4).map((item, itemIndex) => (
            <div
              key={itemIndex}
              className="bg-white rounded-lg shadow-lg cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                handleViewPrev(item.id);
              }}
            >
              {item.images.length > 1 ? (
                <Slider {...imageSettings}>
                  {item.images.map((imag, imageIndex) => (
                    <div
                      key={imageIndex}
                      className="w-full relative cursor-pointer"
                    >
                      <img
                        src={item.images[imageIndex]}
                        alt="img"
                        className="rounded-lg w-full h-[260px]"
                      />
                      <div
                        className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                        onClick={(e) =>
                          handleClickFavorite(e, item.id, imageIndex)
                        }
                      >
                        {userFavorites &&
                        userFavorites.find(
                          (favorite) => favorite.id === item.id
                        ) ? (
                          <IoMdHeart className="text-primary" />
                        ) : (
                          <IoMdHeartEmpty className="text-primary" />
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="w-full relative cursor-pointer">
                  <img
                    src={item?.images?.[0]}
                    alt="img"
                    className="rounded-lg w-full h-[260px]"
                  />
                  <div
                    className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                    onClick={(e) => handleClickFavorite(e, item.id, 0)}
                  >
                    {userFavorites &&
                    userFavorites.find(
                      (favorite) => favorite.id === item.id
                    ) ? (
                      <IoMdHeart className="text-primary" />
                    ) : (
                      <IoMdHeartEmpty className="text-primary" />
                    )}
                  </div>
                </div>
              )}
              <div className="flex flex-col items-start gap-2 p-3">
                <h1 className="text-darkGray text-xl font-medium cursor-pointer hover:text-[#FE4747]">
                  {item.name}
                </h1>
                {item.rating}
                <p className="text-secondary">{item.loc}</p>

                <div className="flex items-center w-full justify-between gap-4">
                  <div className="flex items-center gap-1">
                    <TbBed className="text-lg text-primary" />
                    <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                      {item.room}
                    </p>
                  </div>
                  {/* <h1 className="text-secondary text-xl">•</h1> */}
                  <div className="flex items-center gap-1">
                    <img src={houses} alt="" />
                    <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                      {item.venu}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col w-full ">
                  <h1 className="text-primary lg:text-lg sm:text-base text-sm ">
                    {item.aed}
                  </h1>
                  <p className="text-secondary sm:text-sm text-[10px] mt-1">
                    {item.price}
                  </p>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default HotelDetailComp;
