/* eslint-disable no-unused-vars */
// Navbar.js
import React, { useState, useContext, useEffect } from "react";
import profile from "../assets/pics/profile.svg";
import chevron_down from "../assets/pics/chevron_down.svg";
import Cookies from "js-cookie";
import { useAuth } from "../hooks/auth";
import { useNavigate } from "react-router-dom";
import logo from "../assets/pics/logoMain.svg";
import { Link } from "react-router-dom";
import { fetchLoggedUser } from "../api/loggedUser";
import { useUser } from "../context/user";
import { useFetchUserByIdQuery } from "./SettingsDashboard/Hooks";

const Navbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  // const user = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [user, setUser] = useState();
  const { dashboardUser, setDashboardUser } = useUser();
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");
  const {
    data: userData,
    isLoading: isFetchingUser,
    error,
  } = useFetchUserByIdQuery(vendorId);
  // Extract user from fetched data
  const userQuery = userData?.vendor;
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        setUser(response?.vendor);
        setDashboardUser(response?.vendor);
        // Check if `invitedBy` exists before setting it to `localStorage`
        if (response.vendor?.invitedBy) {
          localStorage.setItem("invitedBy", response?.vendor?.invitedBy);
        }
        localStorage.setItem("checkAdmin", response?.vendor?.isAdmin);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const handleLogin = () => {
    navigate("/Login-Page");
  };

  const handleLogout = () => {
    // Remove the token from cookies
    Cookies.remove("token", { path: "/" });

    // Remove the accessToken and vendorId from localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("vendorId");
    localStorage.removeItem("invitedBy");
    localStorage.removeItem("checkAdmin");

    // Redirect to the home page or login page
    navigate("/");
  };
  // Profile photo section
  const renderProfilePhoto = () => {
    if (isFetchingUser)
      return <div className="w-8 h-8 rounded-full bg-gray-200 animate-pulse" />;

    return userQuery?.profilePic ? (
      <img
        src={userQuery.profilePic}
        alt="Profile"
        className="w-8 h-8 rounded-full object-cover"
      />
    ) : (
      <img src={profile} alt="Default profile" className="w-8 h-8" />
    );
  };
  return (
    <div className="flex items-center justify-between bg-white p-6 shadow-lg h-[10vh]">
      {/* Sidebar Toggle Button (Visible only on mobile) */}
      <div className="lg:hidden flex gap-2 items-center z-50">
        <button onClick={toggleSidebar} className=" text-primary">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        <Link to="/home" className="flex items-center p-2  hover:rounded-lg">
          <img src={logo} alt="Dashboard" className="w-16" />
        </Link>
      </div>

      {/* Search Bar */}
      <div className="relative lg:block hidden w-full max-w-md ml-10"></div>

      {/* Right-side Icons */}
      <div className="flex items-center space-x-4 pr-4">
        <div className="relative"></div>
        <div className="flex items-center space-x-2 gap-[12px]">
          {renderProfilePhoto()}
          <div className="flex flex-col">
            <span className="text-[16px] font-medium">{user?.name}</span>
            <span className="text-xs text-[#717171] text-[12px]">
              {user?.isAdmin ? "Admin" : "Non Admin"}
            </span>
          </div>
        </div>
        <div className="relative">
          <img
            src={chevron_down}
            alt="Dropdown"
            className="h-[16.33px] w-[21.67px] cursor-pointer"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          />
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 py-2 w-48 bg-white border rounded shadow-lg">
              {true ? (
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              ) : (
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 w-full text-left"
                  onClick={handleLogin}
                >
                  Login
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
