import React, { useState } from "react";
import toast from "react-hot-toast";

export default function Declinemodal({ isVisible, onClose, closeEnquiry }) {
  const [selectedReason, setSelectedReason] = useState("");
  const [text, setText] = useState("");

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleTextArea = (e) => {
    setText(e.target.value);
  };

  const submitDeclineModal = () => {
    if (selectedReason === "") {
      toast.error("Please select a reason");
      return;
    }
    closeEnquiry(selectedReason, text);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg md:w-[600px]">
        <div className="flex justify-end">
          <img
            onClick={onClose}
            src="/cross.svg"
            alt=""
            className="cursor-pointer"
          />
        </div>
        <h2 className="text-center font-semibold mb-4 text-[22px] lg:text-[32px]">
          Reason for Closing
        </h2>
        <div className="mb-4 flex flex-col gap-y-3">
          {[
            {
              value: "No longer Interested",
              label: "No longer Interested",
            },
            {
              value: "Found another venue on FindMyVenue",
              label: "Found another venue on FindMyVenue",
            },
            {
              value: "Found another venue elsewhere",
              label: "Found another venue elsewhere",
            },
            {
              value: "Not meeting my requirements",
              label: "Not meeting my requirements",
            },
            {
              value: "In-direct contact with them",
              label: "In-direct contact with them",
            },
            {
              value: "Successful booking",
              label: "Successful booking",
            },
            { value: "other", label: "other" },
          ].map((option) => (
            <label key={option.value} className="flex items-center gap-x-3">
              <input
                type="radio"
                name="reason"
                value={option.value}
                onChange={handleReasonChange}
              />
              {option.label}
            </label>
          ))}
        </div>
        <textarea
          value={text}
          onChange={handleTextArea}
          className="w-full h-[150px] p-3 row-5 border border-gray-300 rounded-lg"
          placeholder="Enter your message here"
        ></textarea>

        <div className="flex justify-center">
          <button
            onClick={submitDeclineModal}
            className="bg-[#FE4747] text-white px-4 py-2 rounded-xl"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
