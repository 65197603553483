import React, { useRef, useState } from "react";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import Footer from "../../components/webComps/Footer";
import {
  Stack,
  TextField,
  Typography,
  Box,
  IconButton,
  Collapse,
  InputAdornment,
} from "@mui/material";
import { ExpandMore, ExpandLess, Search } from "@mui/icons-material";
import { Link } from "react-router-dom";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "16px",
        backgroundColor: "#fff",
      }}
      className="w-full"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{question}</Typography>
        <IconButton onClick={toggleAnswer}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>

      <Collapse in={isOpen}>
        <Typography variant="body1" mt={2}>
          {answer}
        </Typography>
      </Collapse>
    </Box>
  );
};

const FAQs = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const faq = [
    {
      question: "How does Find My Venue work?",
      answer:
        "We’re an online platform connecting venue operators with venue seekers. We don’t act as intermediaries but enable direct connections through features like the venue manager dashboard and inquiry system.",
    },
    {
      question:
        "Can you book through Find My Venue Platform? Are there any booking fees?",
      answer:
        "Our platform does not handle bookings directly, thus eliminating any booking fees. Instead, venue seekers submit enquiries to venue operators, and we facilitate the connections between them.",
    },
    {
      question:
        "Does Find My Venue charge a commission fee for the enquiries sent to venue providers?",
      answer:
        "We do not impose commission fees on enquiries sent. Instead, our role is solely to connect venue seekers and venue operators.",
    },
    {
      question: "What type of users typically visit the platform?",
      answer:
        "The platform caters to a diverse range of users, including social event planners, corporate organizers, and private individuals in search of venues for their events.",
    },
    {
      question:
        "Do I have to sign a contract with Find My Venue to partner up?",
      answer: (
        <span>
          There are no commitments or extensive paperwork involved. You have the
          freedom to remove your listing at any time. Joining Find My Venue
          merely involves agreeing to our &nbsp;
          <a href="/terms&conditions" className="text-blue-500 underline">
            terms and conditions
          </a>
          .
        </span>
      ),
    },
    {
      question: "If I need help to set up my profile, what do I do?",
      answer: (
        <span>
          Your account manager will be available to guide you through the
          onboarding and listing process. For further assistance, you can reach
          out to our customer support team at{" "}
          <a
            href="mailto:support@findmyvenue.com"
            className="text-blue-500 underline"
          >
            support@findmyvenue.com
          </a>
          .
        </span>
      ),
    },
    {
      question: "In which countries do you operate?",
      answer:
        "At present, our venue subscription model is accessible to venue hosts located throughout the United Arab Emirates.",
    },
    {
      question: "Can I cancel my subscription anytime?",
      answer: (
        <span>
          Certainly! You have the flexibility to cancel your subscription at any
          time, in accordance with the{" "}
          <Link to="/terms&conditions" className="text-blue-500 underline">
            terms and conditions
          </Link>
          .
        </span>
      ),
    },
    {
      question: "What is the purpose of subscription fees?",
      answer: (
        <span>
          Subscription fees allow us to cover operational, web development and
          significant marketing costs, as well as maintain a dedicated account
          management and customer support team for our vendors and users.
        </span>
      ),
    },
    {
      question: "What kind of venues can I list on Find My Venue?",
      answer:
        "You can list venues for various event types, from corporate gatherings to social occasions. Hotels can also list accommodations alongside event spaces for a complete event solution.",
    },
    {
      question: "Who can list a venue?",
      answer:
        "Any venue that can host events can be listed, from boutique hotels and restaurants to art studios, yachts, and hospitality groups. You must be the owner, employee, or an authorized representative to list a venue.",
    },
    {
      question:
        "Is there a limit to the number of venues I can list on Find My Venue?",
      answer:
        "You can list an unlimited number of venues, whether at a single location or multiple locations, depending on your subscription plan.",
    },
    {
      question: "How can I manage and update my venue listings and profile?",
      answer:
        "After subscribing, you can access your dashboard to update your profile, manage listings, add venues, and assign team roles. Easily edit details, pricing, availability, and photos to keep your listing accurate and attract clients.",
    },
    {
      question: "Can I promote special offers on Find My Venue?",
      answer:
        "Sure, you can regularly update and promote special offers through your dashboard. These offers will be displayed to users on your venue page.",
    },
    {
      question: "How can I contact customer support if I have any questions?",
      answer: (
        <span>
          Our dedicated support team is here to assist you. You can reach out to
          us by visiting our{" "}
          <a href="/contact" className="text-blue-500 underline">
            Contact Us
          </a>{" "}
          page or via email at{" "}
          <a
            href="mailto:support@findmyvenue.com"
            className="text-blue-500 underline"
          >
            support@findmyvenue.com
          </a>
          .
        </span>
      ),
    },
    // {
    //   question:
    //     "What are the terms and conditions for listing on Find My Venue?",
    //   answer: "To view our terms and conditions, click here.",
    // },
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter the FAQ list based on search term
  const filteredFAQ = faq.filter((faqItem) =>
    faqItem.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const heroAfterRef = useRef(null);

  const scrollToHeroAfter = () => {
    if (heroAfterRef.current) {
      heroAfterRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div ref={heroAfterRef}>
        <ResponsiveAppBar />
      </div>
      <Stack
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"90%"}
        className="mx-auto mb-20"
      >
        <Stack
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mt-10 w-full"
        >
          <Typography
            variant={"h4"}
            fontSize={{ xs: "32px", md: "40px" }}
            fontWeight={600}
            className="font-semibold text-3xl md:text-4xl w-full text-center md:text-left"
          >
            Frequently Asked Questions
          </Typography>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            onChange={handleSearch}
            value={searchTerm}
            style={{
              marginBottom: "20px",
              maxWidth: "335px",
              borderRadius: "50px",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px", // Outer border radius
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack
          flexDirection={"column"}
          width={{ xs: "100%", md: "80%", lg: "70%" }}
          alignItems={"center"}
          className="mt-10"
        >
          {searchTerm === "" ? (
            // Show the full FAQ list when the search term is empty
            faq.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))
          ) : filteredFAQ.length > 0 ? (
            // Show filtered FAQs
            filteredFAQ.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))
          ) : (
            // Show no results message if there are no matches
            <Typography variant="h6" color="textSecondary">
              No FAQs found.
            </Typography>
          )}
        </Stack>
      </Stack>
      <Footer onScrollDownClick={scrollToHeroAfter} />
    </div>
  );
};

export default FAQs;
