import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import backgroundIcon from "../../assets/images/background_icon.svg";
import { useTheme } from "@mui/material/styles";
import MessageCard from "../onboarding/components/MessageCard";
import { fetchUserById } from "../../api/loggedUser";
import CircularProgress from "@mui/material/CircularProgress"; // Import for a loading spinner

function EmailVerificationSuccessful() {
  const theme = useTheme();
  const [buttonRef, setButtonRef] = useState("/onboarding");
  const [isLoading, setIsLoading] = useState(false);

  const checkUser = async () => {
    try {
      setIsLoading(true);
      const storedVendorId = localStorage.getItem("vendorId");
      const user = await fetchUserById(storedVendorId);
      if (user?.vendor?.onBoardingDetails) {
        setButtonRef("/dashboard");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <div
        className="emailsuccessfull"
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <CircularProgress /> // Show a loading spinner while fetching data
            ) : (
              <MessageCard
                loaderComponent={
                  <img
                    src="/assets/email-success_icon.png"
                    alt="/email-success_icon"
                  />
                }
                primaryText={"Email Verification Successful!"}
                secondaryText={
                  "Let's start the listing process. Click the button below to continue."
                }
                buttonText={"List your Venue"}
                buttonHref={buttonRef}
              />
            )}
          </Box>
        </Container>
      </div>
    </>
  );
}

export default EmailVerificationSuccessful;
