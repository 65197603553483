export const statesAndCities = {
	Dubai: [
		'Jebel Ali',
		'Hatta',
		'Al Quoz',
		'Al Qusais',
		'Al Warqa',
		'Academic City',
		'Al Awir',
		'Al Muteena',
		'Al Fahidi',
		'Al Mizhar',
		'Al Karama',
		'Al Furjan',
		'Al Safa',
		'Al Khawaneej',
		'Al Raffa',
		'Al Barsha',
		'Al Wasl',
		'Arabian Ranches 2',
		'Arabian Ranches',
		'Al Muraqqabat',
		'Al Rigga',
		'Al Mamzar',
		'Al Satwa',
		'Al Nahda',
		'Al Mankhool',
		'Al Sufouh',
		'Barsha Heights (Tecom)',
		'City Walk',
		'Business Bay',
		'Bur Dubai',
		'Damac Hills',
		'Dubai Festival City',
		'Downtown Dubai',
		'Bluewaters Island',
		'Discovery Gardens',
		'Dubai Creek Harbour',
		'Deira',
		'Dubai International Financial Centre (DIFC)',
		'Hor Al Anz',
		'Dubai Media City',
		'Jumeirah Village Circle (JVC)',
		'Dubai Harbour',
		'Dubai Industrial City',
		'Jumeirah Golf Estates',
		'Jebel Ali',
		'Jumeirah Village Triangle (JVT)',
		'Jebel Ali Free Zone (JAFZA)',
		'Jumeirah',
		'Emirates Hills',
		'Dubai Hills Estate',
		'Dubai Land',
		'Dubai South (Dubai World Central)',
		'Gardens',
		'Green Community',
		'Jumeirah Lakes Towers (JLT)',
		'Jumeirah Bay Island',
		'Jumeirah Beach Residence (JBR)',
		'Dubai Investment Park (DIP)',
		'Dubai Internet City',
		'International City',
		'Dubai Silicon Oasis',
		'Jumeirah Islands',
		'Dubai Marina',
		'Dubai Sports City',
		'Dubai Production City',
		'Khawaneej',
		'Layan',
		'Marsa Al Arab',
		'Meydan',
		'Mirdif',
		'Motor City',
		'Mudon',
		'Muhaisnah',
		'Nad Al Sheba',
		'Oud Metha',
		'Palm Jumeirah',
		'Port Rashid',
		'Port Saeed',
		'Ras Al Khor',
		'Remraam',
		'Serena',
		'Sheikh Zayed Road',
		'Silicon Oasis',
		'Sports City',
		'The Greens',
		'The Lakes',
		'The Meadows',
		'The Springs',
		'The Sustainable City',
		'The Views',
		'The Villa',
		'Town Square',
		'Trade Centre',
		'Umm Al Sheif',
		'Umm Suqeim',
		'Victory Heights',
		'Zabeel',
	],
	'Abu Dhabi': [
		'Al Khalidiyah',
		'Al Mafraq',
		'Al Markaziya',
		'AbuDhabi Gate City',
		'Airport Road',
		'Al Gurm',
		'Al Bateen',
		'Al Faya',
		'Al Danah',
		'AL Bahia',
		'Khor Al Maqta',
		'Al Dhafra Industrial Area',
		'Al Manaseer',
		'Al Ain (Green City)',
		'Al Maryah Island',
		'Al Muntazah',
		'Al Mina',
		'Al Ghadeer',
		'Al Manhal',
		'Al Dhafrah',
		'Al Maqtaa',
		'Al Karama',
		'Al Falah',
		'Al Marina',
		'Al Muroor',
		'Baniyas',
		'Al Qurm',
		'Al Zahiyah (Tourist Club Area)',
		'Al Raha Beach',
		'Al Seef Village',
		'Al Reem Island',
		'Al Mushrif',
		'Madinat Khalifa',
		'Al Rowdah',
		'Al Raha Gardens',
		'Al Wathba',
		'Hydra Village',
		'Capital Centre',
		'Marina Village',
		'Al Shamkha',
		'Al Wahda',
		'Liwa Oasis',
		'Khalifa City',
		'Masdar City',
		'Khalifa City A',
		'Khalifa City B',
		'Hamdan Street',
		'Al Shawamekh',
		'Bain Al Jessrain (Between Two Bridges)',
		'Danet Abu Dhabi',
		'Hili',
		'Hudayriat Island',
		'Madinat Zayed',
		'KIZAD (Khalifa Industrial Zone Abu Dhabi)',
		'Al Mushrif Village',
		'Al Zaab',
		'ICAD I, II, III (Industrial City of Abu Dhabi)',
		'Corniche Road',
		'Al Reef',
		'Al Nahyan',
		'Electra Street',
		'Corniche Area',
		'Mina Zayed',
		'Mohammed Bin Zayed City (MBZ)',
		'Mussafah',
		'Mussafah Industrial Area',
		'Rawdhat Abu Dhabi',
		'Saadiyat Island',
		'Shakhbout City',
		'Shamkha South',
		'Shati Al Raha',
		'The Galleria',
		'Tourist Club Area (Al Zahiyah)',
		'Western Region (Al Dhafra)',
		'Yas Island',
	],
	'Ras Al Khaimah': [
		'Al Jazeera Al Hamra',
		'Al Qusaidat',
		'Khatt',
		'Al Dhait',
		'Al Mamourah',
		'Julphar',
		'Al Seer',
		'Al Hamra Village',
		'Al Nakheel',
		'Khuzam',
		'Al Ghail',
		'Al Oraibi',
		'Al Qurm',
		'Al Rams',
		'Wadi Bih',
		'Ghalilah',
		'Wadi Shah',
		'Mina Al Arab',
		'Al Jazirah Al Hamra',
		'Al Mairid',
		'Al Ghayl',
		'Sidroh',
		'Dafan Al Khor',
		'Digdaga',
		'Shamal Julphar',
		'Adhen',
		'Al Wadi',
		'Al Riffa',
		'Masafi',
		'Wadi Asfai',
		'Al Hamraniyah',
		'Sha’am',
		'Al Dhaya',
		'Jebel Jais',
	],
	Sharjah: [
		'Al Majarrah',
		'Al Mahatta',
		'Al Mamzar',
		'Al Madam',
		'Al Jazzat',
		'Al Gharayen',
		'Al Shahba',
		'Al Heera',
		'Al Nouf',
		'Al Mujarrah',
		'Al Soor',
		'Al Layyah',
		'Al Majaz',
		'Al Nahda',
		'Al Nabba',
		'Al Nud',
		'Al Khawaneej',
		'Al Ghafya',
		'Al Barashi',
		'Al Mareija',
		'Al Muwafjah',
		'Al Rolla',
		'Al Khaledia',
		'Al Falaj',
		'Al Rifah',
		'Halwan Suburb',
		'Dasman',
		'Al Taawun',
		'Al Rahmaniya',
		'Al Ghubaiba',
		'Al Goaz',
		'Abu Shagara',
		'Al Zubair Orchards',
		'Al Dhaid',
		'Al Fisht',
		'Al Abar',
		'Al Azra',
		'Al Butina',
		'Al Darari',
		'Al Fayha',
		'Al Gharb',
		'Al Hazannah',
		'Al Juraina',
		'Al Khan',
		'Al Manakh',
		'Al Musalla',
		'Al Qadisiya',
		'Al Qasba',
		'Al Qasimiya',
		'Al Qulayaa',
		'Al Sabkha',
		'Al Sajaa',
		'Al Sharq',
		'Al Suyoh',
		'Al Sweihat',
		'Al Tai',
		'Al Wahda',
		'Al Yarmook',
		'Al Zubair',
		'Bu Tina',
		'Hoshi',
		'Industrial Area',
		'Maysaloon',
		'Muwafjah',
		'Muwaileh',
		'Muwaileh Commercial',
		'Samnan',
		'Sharjah Industrial Area',
		'Sharjah Waterfront City',
		'Sharqan',
		'University City',
	],

	Fujairah: [
		'Al Aqah',
		'Al Badiyah',
		'Al Buthna',
		'Al Faseel',
		'Al Ghurfah',
		'Al Hail',
		'Al Hilal',
		'Al Mahatta',
		'Al Nakheel',
		'Al Owaid',
		'Al Qurayyah',
		'Al Rifaa',
		'Al Rughaylat',
		'Al Sharia',
		'Al Taween',
		'Dadna',
		'Dhadnah',
		'Dibba Al Fujairah',
		'Masafi',
		'Merashid',
		'Murabah',
		'Qidfa',
		'Sakamkam',
		'Wadi Wurayah',
		'Zubarah',
	],
	Ajman: [
		'Al Amerah',
		'Al Hamriya',
		'Al Rashidiya 2',
		'Al Raqaib',
		'Al Jurf',
		'Al Sawan',
		'Al Nakhil',
		'Al Bustan',
		'Al Hamidiya',
		'Al Mowaihat',
		'Emirates City',
		'Al Heliow',
		'Al Jurf Industrial',
		'Al Rashidiya 3',
		'Mushrif',
		'Mushairef',
		'Helio',
		'Al Owan',
		'Al Zahya',
		'Al Yasmeen',
		'Al Rumailah',
		'Al Tallah',
		'Al Rawda',
		'Al Rashidiya',
		'Garden City',
		'Ajman Corniche',
		'Ajman Free Zone',
		'Ajman Industrial Area',
		'Ajman Marina',
		'Ajman Uptown',
		'Al Bahya',
		'Al Muntazi',
		'Al Nuaimiya',
		'Al Zahra',
		'Sheikh Khalifa Bin Zayed City',
	],
	'Umm Al Quwain': [
		'Al Maarid',
		'Al Neefah',
		'Al Falaj Al Kabir',
		'Al Salamah',
		'Al Raas',
		'Al Riqa',
		'Al Humrah',
		'Al Aahad',
		'Al Haditha',
		'Al Madar',
		'Old Town (Al Dour)',
		'Al Dar Al Baida',
		'Al Mareija',
		'Al Riqqah',
		'Al Labsa',
		'Al Rifaah',
		'Al Khuraija',
		'Al Abraq',
		'Al Hamriyah',
		'Al Rashidiyah',
		'Falaj Al Mualla',
		'Al Maidan',
		'Al Ramlah',
		'Al Surrah',
		'Al Khor',
		'Al Salam City',
		'Al Shabiyah',
		'Al Raudah',
		'Al Shuweihat',
		'Al Siniyah Island',
		'Al Raudat',
		'Al Dhaidah',
	],
};
