import React, { useState, useEffect, useRef } from "react";
import Input from "../shared/common/customInput";
import { IoIosInformationCircleOutline } from "react-icons/io";
import AssignVenues from "./assign-venues";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateTeamMember } from "../../api/teamMember.request";
import { base_URL } from "../../config/config";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
import { Radio, styled } from "@mui/material";
import { getOnboarding } from "../../api/signup.request";

// Custom radio button unchecked icon
const CustomIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 24, // Custom size for your Radio
  height: 24,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    background: "rgba(206,217,224,.5)",
  },
}));

// Custom radio button checked icon
const CustomCheckedIcon = styled(CustomIcon)({
  backgroundColor: "#0B8476", // Custom green color for checked state
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 24,
    height: 24,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#0B8476",
  },
});

function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomCheckedIcon />}
      icon={<CustomIcon />}
      {...props}
    />
  );
}

const EditUserModal = ({ setShowEditModal, setSnackbar, data }) => {
  // edit data coming in below state
  const modalRef = useRef();
  const [country, setCountry] = useState("AE"); // Default country
  const [countryCode, setCountryCode] = useState("+971"); // Default UAE country code
  const [fullname, setFullname] = useState(data?.name ? data?.name : "");
  const [email, setEmail] = useState(data?.email ? data?.email : "");
  const [mobileno, setMobileno] = useState(data?.phone ? data?.phone : "");
  const [role, setRole] = useState(data?.role ? data?.role : "");
  const [isAdmin, setIsAdmin] = useState(data?.isAdmin ? data?.isAdmin : null);
  const [assignVenue, setAssignVenue] = useState(false);
  const [roleAccess, setRoleAccess] = useState("admin");
  const [assignedVenues, setAssignedVenues] = useState(
    data?.assignedVenues ? data?.assignedVenues : []
  );
  const [assignedRestaurant, setAssignedRestaurant] = useState(
    data?.RestaurantForm ? data?.RestaurantForm : []
  );
  const [assignedHotel, setAssignedHotel] = useState(
    data?.Hotel ? data?.Hotel : []
  );
  // for sub admins invitedBy will be used and for others super admin vendorId will be used
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const queryClient = useQueryClient();

  const onClose = () => {
    setShowEditModal(false);
  };

  const handlePhoneChange = (value) => {
    setMobileno(value || "");
  };

  const handleCountryChange = (countryCode) => {
    if (countryCode) {
      setCountry(countryCode);

      try {
        // Directly get the calling code for the selected country
        const dialCode = getCountryCallingCode(countryCode);
        setCountryCode(`+${dialCode}`);

        // If no number is entered, set a placeholder with the new country code
        if (!mobileno) {
          setMobileno(`+${dialCode}`);
        }
      } catch (error) {
        console.error("Error getting country calling code:", error);
        setCountryCode("+");
      }
    }
  };
  // getting restaurants of vendor
  const {
    data: boardingData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["onboardingData"],
    queryFn: async () => await getOnboarding(vendorId),
    refetchOnWindowFocus: false,
  });
  const updateMutation = useMutation({
    mutationKey: ["teamMembers"],
    mutationFn: async (data) => {
      return updateTeamMember(data?.id, data?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers"]);
      setSnackbar({
        open: true,
        message: "User updated successfully!",
        severity: "success",
      });
      onClose();
    },
  });
  // Function to get venue names from IDs
  const getAssignedVenueNames = (venueIds, boardingData) => {
    const venueNames = [];
    (boardingData?.venueForms || []).forEach((venue) => {
      if (venueIds.includes(venue._id)) {
        venueNames.push(venue?.basicDetails?.venueName);
      }
    });
    return venueNames;
  };

  // Function to get restaurant names from IDs
  const getAssignedRestaurantNames = (restaurantIds, boardingData) => {
    const restaurantNames = [];
    (boardingData?.restaurantForms || []).forEach((restaurant) => {
      if (restaurantIds.includes(restaurant._id)) {
        restaurantNames.push(restaurant?.basicDetails?.venueName);
      }
    });
    return restaurantNames;
  };

  // Function to get hotel names from IDs
  const getAssignedHotelNames = (hotelIds, boardingData) => {
    const hotelNames = [];
    (boardingData?.hotels || []).forEach((hotel) => {
      if (hotelIds.includes(hotel._id)) {
        hotelNames.push(hotel?.overview?.basicDetails?.hotelName);
      }
    });
    return hotelNames;
  };
  const hanldeSubmit = async () => {
    const assignedVenueNames = getAssignedVenueNames(
      assignedVenues,
      boardingData
    );
    const assignedRestaurantNames = getAssignedRestaurantNames(
      assignedRestaurant,
      boardingData
    );
    const assignedHotelNames = getAssignedHotelNames(
      assignedHotel ? [assignedHotel] : [],
      boardingData
    );
    // console.log("Assigned Venue Names:", assignedVenueNames);
    // console.log("Assigned Restaurant Names:", assignedRestaurantNames);
    // console.log("Assigned Hotel Names:", assignedHotelNames);
    // Merge all names into a single array
    const allAssignedNames = [
      ...assignedVenueNames,
      ...assignedRestaurantNames,
      ...assignedHotelNames,
    ];

    if (!mobileno || mobileno?.length <= 7) {
      toast.error("Please enter valid phone number");
      return;
    }
    if (!fullname) {
      toast.error("Please provide your Full name");
      return;
    }

    try {
      // Update user data
      await updateMutation.mutateAsync({
        id: data?._id,
        data: {
          name: fullname,
          email,
          role,
          phone: mobileno,
          isAdmin,
          assignedVenues,
          RestaurantForm: assignedRestaurant,
          Hotel: Array.isArray(assignedHotel) ? assignedHotel : [assignedHotel],
          vendor: vendorId,
        },
      });

      // Send confirmation email
      const res = await fetch(`${base_URL}/auth/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          vendorId,
          assignedVenues,
          allAssignedNames,
        }),
      });

      const responseData = await res.json();
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  const onClick = () => {
    if (isAdmin) {
      // If admin, directly submit
      hanldeSubmit();
    } else {
      // If not admin, check if venues need to be assigned
      if (assignVenue) {
        hanldeSubmit();
      } else {
        setAssignVenue(true);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="md:fixed z-[999] inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        className="bg-white p-6 rounded-lg shadow-lg w-[90%] md:w-1/2 flex flex-wrap justify-between"
        ref={modalRef}
      >
        <div className="w-full flex justify-end">
          <p className="cursor-pointer" onClick={() => onClose()}>
            X
          </p>
        </div>
        <h2 className="text-2xl w-full font-semibold mb-4 text-center">
          Edit User
        </h2>
        {assignVenue ? (
          <AssignVenues
            setAssignedVenues={setAssignedVenues}
            assignedVenues={assignedVenues}
            assignedRestaurants={assignedRestaurant}
            setAssignedRestaurants={setAssignedRestaurant}
            assignedHotel={assignedHotel}
            setAssignedHotel={setAssignedHotel}
          />
        ) : (
          <>
            {" "}
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Full name"
                  type="text"
                  placeholder="Enter full name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Work Email"
                  type="text"
                  placeholder="user@workapp.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <div className="absolute left-[60px] top-1/2 transform   z-10 pointer-events-none">
                  {countryCode}
                </div>
                <label
                  htmlFor="mobileno"
                  className="mb-3 text-[#5E5E5E] text-[14px]"
                >
                  Mobile No.
                </label>
                <PhoneInput
                  id="mobile-input"
                  placeholder="Enter your contact no."
                  value={mobileno}
                  defaultCountry="AE"
                  withCountryCallingCode={true}
                  autoComplete="on"
                  onCountryChange={handleCountryChange}
                  onChange={handlePhoneChange}
                  style={{
                    border: "1px solid #d1d5db",
                    boxShadow: "none",
                    borderRadius: "10px",
                    outline: "none",
                    width: "100%",
                    padding: "0.5rem",
                    // marginTop: "0.3rem",
                  }}
                  className="no-focus-border  "
                />
              </div>
            </div>
            <div className="lg:w-[47%] w-full mb-4">
              <div className="relative">
                <Input
                  label="Role in the company"
                  type="text"
                  placeholder="Enter role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2">
                <CustomRadio
                  id="admin"
                  name="role"
                  value="admin"
                  checked={isAdmin} // Admin radio should be checked when isAdmin is true
                  onChange={() => setIsAdmin(true)}
                />
                <label for="huey">Grant Full Admin Access</label>
                <IoIosInformationCircleOutline
                  color="#B0B0B0"
                  style={{ fontSize: "24px" }}
                  data-tooltip-id="admin-tooltip"
                  data-tooltip-content="Grant user administrator rights."
                />
              </div>
              <div>
                <div className="flex items-center  gap-2">
                  <CustomRadio
                    id="nonadmin"
                    name="role"
                    value="nonadmin"
                    checked={!isAdmin} // Venue radio should be checked when isAdmin is false
                    onChange={() => setIsAdmin(false)}
                  />
                  <label for="huey">Grant Access to Venue & Enquiries</label>
                  <IoIosInformationCircleOutline
                    color="#B0B0B0"
                    style={{ fontSize: "24px" }}
                    data-tooltip-id="venue-tooltip"
                    data-tooltip-content="Grant access to specific venues and enquiries."
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col items-center w-full gap-3 mt-3">
          <button
            className="bg-primary w-[50%] text-white px-4 py-2 rounded-md"
            onClick={onClick}
          >
            {assignVenue
              ? "Submit"
              : updateMutation.isPending
              ? "Submitting..."
              : "Continue"}
          </button>
          <p
            className="text-primary font-semibold cursor-pointer"
            onClick={() => onClose()}
          >
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
