"use client";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { BiChair } from "react-icons/bi";
import { LuPersonStanding } from "react-icons/lu";
import houses from "../../assets/pics/houses.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUser } from "../../lib/apis";
import { base_URL } from "../../config/config";
import NewFavoriteFolderModal from "../../components/myFav/NewFavoriteFolderModal";
import AddedFavoriteModal from "../../components/myFav/AddedFavoriteModal";

const VenueDetailComp = ({ data }) => {
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddedOpen, setIsModalAddedOpen] = useState(false);
  const [newFolder, setNewFolder] = useState(false);
  const [favItem, setFavItem] = useState(null);

  const toggleFavorite = (itemIndex, imageIndex) => {
    setFavorites((prevState) => ({
      ...prevState,
      [itemIndex]: {
        ...prevState[itemIndex],
        [imageIndex]: !prevState[itemIndex]?.[imageIndex],
      },
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const mainCarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // lg
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // md
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // sm
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getFavoriteFolder = async () => {
    try {
      const response = await fetch(
        `${base_URL}/api/auth/get-folders/${user?._id}`
      );
      if (!response.ok) {
        console.log("Failed to fetch Venues");
        return;
      }
      setIsClicked(true);
      const data = await response.json();
      refetchFav1();
      return data.folders;
    } catch (err) {
      console.log(err);
    }
  };
  const { folders, refetch } = useQuery({
    queryKey: ["favoriteFolder", user?._id],
    queryFn: getFavoriteFolder,
    enabled: !!user?._id,
  });

  const handleViewPrev = (id) => {
    const cf = "hpv";
    navigate(`/hotel-preview/${cf}/${id}`);
  };
  const getUserInfo = async () => {
    const userInfo = await getUser(user?._id);
    let venuesAndStay = [];
    userInfo.favoritesFolder.forEach((folder) => {
      if (folder.venues.length > 0) {
        venuesAndStay = [...venuesAndStay, ...folder.venues];
      }
    });
    setIsClicked(false);
    console.log(userInfo);
    return venuesAndStay;
  };
  const { data: userFavorites, refetch: refetchFav1 } = useQuery({
    queryKey: ["userFavorites1"],
    queryFn: getUserInfo,
  });

  const handleClickFavorite = (e, itemId) => {
    e.stopPropagation();

    if (!itemId) return;
    if (
      userFavorites &&
      userFavorites.find((favorite) => favorite.id === itemId)
    )
      return;
    if (!user) {
      return toast.error("Please login first");
    }
    setFavItem(itemId);
    user?.favoritesFolder?.length > 0
      ? setIsModalAddedOpen(true)
      : setIsModalOpen(true);
  };

  if (!data) {
    return null;
  }

  return (
    <>
      {newFolder && (
        <NewFavoriteFolderModal
          isOpen={newFolder}
          refetch={refetch}
          onClose={() => {
            setNewFolder(false);
            refetchFav1();
          }}
          itemId={favItem}
          type={"venue"}
        />
      )}
      {user?.favoritesFolder?.length > 0 ? (
        <AddedFavoriteModal
          setNewFolder={setNewFolder}
          isOpen={isModalAddedOpen}
          refetch={refetch}
          itemId={favItem}
          data={folders}
          type={"venue"}
          onClose={() => {
            setIsModalAddedOpen(false);
            refetchFav1();
          }}
        />
      ) : (
        <NewFavoriteFolderModal
          isOpen={isModalOpen}
          itemId={favItem}
          refetch={refetch}
          type={"venue"}
          onClose={() => {
            setIsModalOpen(false);
            refetchFav1();
          }}
        />
      )}
      <div className={`w-full ${isMobileView ? "" : "grid grid-cols-4 gap-6"}`}>
        {isMobileView ? (
          <Slider {...mainCarouselSettings}>
            {data &&
              data.slice(0, 4).map((item, itemIndex) => (
                <div key={itemIndex} className="p-2">
                  <div
                    className="bg-white rounded-lg shadow-lg cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      handleViewPrev(item.id);
                    }}
                  >
                    {item.images.length > 1 ? (
                      <Slider {...imageSettings}>
                        {item?.images?.map((e, imageIndex) => (
                          <div key={imageIndex} className="w-full relative">
                            <img
                              src={item.images[imageIndex]}
                              alt=""
                              className="rounded-lg w-full h-[260px]"
                            />
                            <div
                              className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                              onClick={(e) =>
                                handleClickFavorite(e, item?.id, imageIndex)
                              }
                            >
                              {userFavorites &&
                              userFavorites.find(
                                (favorite) => favorite.id === item.id
                              ) ? (
                                <IoMdHeart className="text-primary" />
                              ) : (
                                <IoMdHeartEmpty className="text-primary" />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <div className="w-full relative">
                        <img
                          src={item.images[0]}
                          alt=""
                          className="rounded-lg w-full h-[260px]"
                        />
                        <div
                          className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                          onClick={(e) => handleClickFavorite(e, item?.id, 0)}
                        >
                          {userFavorites &&
                          userFavorites.find(
                            (favorite) => favorite.id === item.id
                          ) ? (
                            <IoMdHeart className="text-primary" />
                          ) : (
                            <IoMdHeartEmpty className="text-primary" />
                          )}
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col items-start gap-2 p-3">
                      <h1 className="text-darkGray lg:text-xl text-base font-medium cursor-pointer hover:text-[#FE4747]">
                        {item.name}
                      </h1>
                      <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                        {item.loc}
                      </p>
                      <div className="flex items-center w-full  gap-4">
                        <div className="flex items-center gap-1">
                          <BiChair className="text-sm text-primary" />
                          <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                            {item.sitted}
                          </p>
                        </div>

                        <div className="flex items-center gap-1">
                          <LuPersonStanding className="text-sm text-primary" />
                          <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                            {item.stand}
                          </p>
                        </div>
                      </div>

                      <h1 className="text-secondary text-xl">•</h1>
                      <div className="flex items-center gap-1">
                        <img src={houses} alt="" />
                        <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                          {item.activity} . {item.setting}
                        </p>
                      </div>
                      <div className="flex  justify-between items-start w-full">
                        {item.minSpendPricing &&
                        item.venueRental &&
                        !Number.isNaN(item.minSpendPricing) &&
                        !Number.isNaN(item.venueRental) ? (
                          <>
                            <div className="">
                              <p className="text-primary font-medium">
                                AED {item.minSpendPricing}
                              </p>
                              {/* Show one "Min. spend" text */}
                              <p className="text-sm">Min. spend</p>
                            </div>
                            {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
                          </>
                        ) : (
                          <>
                            {/* Case 2: Both minSpendPerPerson and venueRental available */}
                            {item.minSpendPerPerson &&
                            item.venueRental &&
                            !Number.isNaN(item.minSpendPerPerson) &&
                            !Number.isNaN(item.venueRental) ? (
                              <>
                                <div className="">
                                  <p className="text-primary font-medium">
                                    AED {item.venueRental}
                                  </p>
                                  <p className="text-sm">Min. spend</p>
                                </div>
                                <div className="">
                                  <p className="text-primary font-medium">
                                    AED {item.minSpendPerPerson}
                                  </p>
                                  <p className="text-sm">Per guest</p>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Render each one individually if other cases don't match */}
                                {item.minSpendPerPerson &&
                                  !Number.isNaN(item.minSpendPerPerson) && (
                                    <div className="">
                                      <p className="text-primary font-medium">
                                        AED {item.minSpendPerPerson}
                                      </p>
                                      <p className="text-sm">Per guest</p>
                                    </div>
                                  )}
                                {item.minSpendPricing &&
                                  !Number.isNaN(item.minSpendPricing) && (
                                    <div className="">
                                      <p className="text-primary font-medium">
                                        AED {item.minSpendPricing}
                                      </p>
                                      <p className="text-sm">Min. spend</p>
                                    </div>
                                  )}
                                {item.venueRental &&
                                  !Number.isNaN(item.venueRental) && (
                                    <div className="">
                                      <p className="text-primary font-medium">
                                        AED {item.venueRental}
                                      </p>
                                      <p className="text-sm">Min. spend</p>
                                    </div>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        ) : (
          data &&
          data.slice(0, 4).map((item, itemIndex) => (
            <div
              key={itemIndex}
              className="bg-white rounded-lg shadow-lg cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                handleViewPrev(item.id);
              }}
            >
              {item.images.length > 1 ? (
                <Slider {...imageSettings}>
                  {item?.images?.map((e, imageIndex) => (
                    <div key={imageIndex} className="w-full relative">
                      <img
                        src={item.images[imageIndex]}
                        alt=""
                        className="rounded-lg w-full h-[260px]"
                      />
                      <div
                        className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                        onClick={(e) =>
                          handleClickFavorite(e, item?.id, imageIndex)
                        }
                      >
                        {userFavorites &&
                        userFavorites.find(
                          (favorite) => favorite.id === item.id
                        ) ? (
                          <IoMdHeart className="text-primary" />
                        ) : (
                          <IoMdHeartEmpty className="text-primary" />
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="w-full relative cursor-pointer">
                  <img
                    src={item.images[0]}
                    alt=""
                    className="rounded-lg w-full h-[260px]"
                  />
                  <div
                    className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                    onClick={(e) => handleClickFavorite(e, item?.id, 0)}
                  >
                    {userFavorites &&
                    userFavorites.find(
                      (favorite) => favorite.id === item.id
                    ) ? (
                      <IoMdHeart className="text-primary" />
                    ) : (
                      <IoMdHeartEmpty className="text-primary" />
                    )}
                  </div>
                </div>
              )}
              <div className="flex flex-col items-start gap-2 p-3">
                <h1 className="text-darkGray text-xl font-medium cursor-pointer hover:text-[#FE4747]">
                  {item.name}
                </h1>
                <p className="text-secondary">{item.loc}</p>

                <div className="flex items-center w-full gap-4">
                  <div className="flex items-center gap-1">
                    <BiChair className="text-lg text-primary" />
                    <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                      {item.sitted}
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <LuPersonStanding className="text-lg text-primary" />
                    <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                      {item.stand}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <img src={houses} alt="" />
                  <p className="text-secondary lg:text-base sm:text-sm text-[11px]">
                    {item.activity} . {item.setting}
                  </p>
                </div>

                <div className="flex  justify-between items-start w-full">
                  {item.minSpendPricing &&
                  item.venueRental &&
                  !Number.isNaN(item.minSpendPricing) &&
                  !Number.isNaN(item.venueRental) ? (
                    <>
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {item.minSpendPricing}
                        </p>
                        {/* Show one "Min. spend" text */}
                        <p className="text-sm">Min. spend</p>
                      </div>
                      {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
                    </>
                  ) : (
                    <>
                      {/* Case 2: Both minSpendPerPerson and venueRental available */}
                      {item.minSpendPerPerson &&
                      item.venueRental &&
                      !Number.isNaN(item.minSpendPerPerson) &&
                      !Number.isNaN(item.venueRental) ? (
                        <>
                          <div className="">
                            <p className="text-primary font-medium">
                              AED {item.venueRental}
                            </p>
                            <p className="text-sm">Min. spend</p>
                          </div>
                          <div className="">
                            <p className="text-primary font-medium">
                              AED {item.minSpendPerPerson}
                            </p>
                            <p className="text-sm">Per guest</p>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Render each one individually if other cases don't match */}
                          {item.minSpendPerPerson &&
                            !Number.isNaN(item.minSpendPerPerson) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {item.minSpendPerPerson}
                                </p>
                                <p className="text-sm">Per guest</p>
                              </div>
                            )}
                          {item.minSpendPricing &&
                            !Number.isNaN(item.minSpendPricing) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {item.minSpendPricing}
                                </p>
                                <p className="text-sm">Min. spend</p>
                              </div>
                            )}
                          {item.venueRental &&
                            !Number.isNaN(item.venueRental) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {item.venueRental}
                                </p>
                                <p className="text-sm">Min. spend</p>
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default VenueDetailComp;
