import React from "react";
import HotelCarousel from "./HotelCarousel";
import { useQuery } from "@tanstack/react-query";
import { base_URL } from "../../config/config";

// Fetch hotels function
const getHotels = async () => {
  try {
    const response = await fetch(`${base_URL}/api/landing/hotels`);
    const data = await response.json();
    return data.hotels; // Return the hotels data
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch hotels");
  }
};

const ExploreHotel = () => {
  // Use the useQuery hook to fetch hotels
  const {
    data: hotels,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["hotels"], // The query key
    queryFn: getHotels, // The function to fetch data
  });

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="flex justify-center pt-20">
      <div className="w-[90%] mt-8">
        <h1 className="sm:text-4xl text-2xl text-darkGray font-semibold text-center my-4">
          Looking for Hotel stay with Venues
        </h1>
        <HotelCarousel data={hotels} />
      </div>
    </div>
  );
};

export default ExploreHotel;
