import React, { useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Tooltip from "./Tooltip";

const transformFormDataToPricingData = (formData) => {
  if (!formData) return [];
  const pricingTypes = Object.keys(formData);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    const period = +hours < 12 ? "AM" : "PM";
    const hour = +hours % 12 || 12;
    return `${hour}:${minutes} ${period}`;
  };

  return daysOfWeek.map((day) => {
    const dayShort = day.slice(0, 3);
    const dayData = pricingTypes.reduce((acc, type) => {
      const filteredData = Array.isArray(formData[type])
        ? formData[type].filter((item) => item.selectedDays?.includes(dayShort))
        : []; // Ensure that formData[type] is an array
      if (filteredData.length) {
        acc.push(
          ...filteredData.map((item) => ({
            type,
            time:
              item?.from && item?.to
                ? `${convertTo12HourFormat(
                    item.from
                  )} - ${convertTo12HourFormat(item.to)}`
                : "",
            price: `${item.minSpend} min. spend`,
            duration: item.duration,
          }))
        );
      }
      return acc;
    }, []);
    return {
      day,
      slots: dayData.length ? dayData : null,
      closed: !dayData.length,
      time: dayData.length ? dayData[0].time : "",
      price: dayData.length ? dayData[0].price : "",
      duration: dayData.length ? dayData[0].duration : "",
    };
  });
};

const Pricing = ({ data }) => {
  // const { formData } = useContext(VenueContext);
  const pricingData = transformFormDataToPricingData(data);
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="bg-white rounded-lg md:flex justify-between mb-8 md:py-5 py-1    w-full mx-auto">
      <div className="md:flex items-start gap-1">
        <h2 className="md:text-2xl text-[20px] font-bold">Pricing</h2>
        <div className="flex justify-start md:items-center items-start md:mt-0 mt-2 gap-2 md:ml-0 -ml-4 w-full">
          <Tooltip text="Prices vary based on your needs. Submit an enquiry for a personalized quote." />
          <p className="md:hidden block text-[14px] text-[#5E5E5E]">
            Prices vary based on your needs. Submit an enquiry for a
            personalized quote.
          </p>
        </div>
      </div>
      <div className="lg:w-[75%] md:mt-0 mt-4 w-full text-base text-[#222222] font-medium">
        {pricingData &&
          pricingData?.map((day, index) => (
            <div key={index} className="mb-4">
              <div
                className="flex justify-between items-center cursor-pointer md:border-t border-[#DDDDDD] md:py-4 py-0 w-full"
                onClick={() => toggleExpand(index)}
              >
                <h2 className="text-start md:text-[18px] text-[12px]">
                  {day.day}
                </h2>
                {!expanded === index && (
                  <h2 className="text-start md:text-[18px] text-[12px]">
                    {day.closed ? "Closed" : day.time}
                  </h2>
                )}
                <h2 className="text-start flex items-center gap-5">
                  {!expanded === index && !day.closed && day.price}
                  {day?.slots &&
                    (expanded === index ? (
                      <MdKeyboardArrowUp className="text-3xl" />
                    ) : (
                      <MdKeyboardArrowDown className="text-3xl" />
                    ))}
                </h2>
              </div>
              {expanded === index && day.slots && (
                <ul className="list-disc list-inside mt-2 md:py-4 py-0 space-y-2">
                  {day?.slots.map((slot, slotIndex) => (
                    <li
                      key={slotIndex}
                      className='font-normal text-[#222222] flex before:content-["•"] before:mr-4 before:text-black'
                    >
                      <div className="flex justify-between w-full md:text-[18px] text-[12px]">
                        <div className="mr-4 w-[18%]">
                          {slot?.duration === "perday"
                            ? "Per Day"
                            : slot?.duration === "persession"
                            ? "Per Session"
                            : slot?.duration === "permorning"
                            ? "Per Morning"
                            : slot?.duration === "perafternoon"
                            ? "Per Afternoon"
                            : slot?.duration === "perevening"
                            ? "Per Evening"
                            : slot?.duration
                            ? slot.duration
                                .split("_")
                                .map((word, index) =>
                                  index === 0
                                    ? word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                    : word
                                )
                                .join(" ")
                            : ""}
                        </div>
                        <div className="mr-4 md:text-[18px] text-[12px]">
                          {slot?.time}
                        </div>
                        AED{" "}
                        {slot?.price?.replace(/\d+/, (num) =>
                          Number(num).toLocaleString()
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Pricing;
