import React, { useState, useEffect } from "react";
import icon27 from "../../assets2/icons/placeOffers/27.svg";
import icon28 from "../../assets2/icons/placeOffers/28.svg";
import icon29 from "../../assets2/icons/placeOffers/29.svg";
import icon30 from "../../assets2/icons/placeOffers/30.svg";
import icon31 from "../../assets2/icons/placeOffers/31.svg";
import icon32 from "../../assets2/icons/placeOffers/32.svg";
import icon33 from "../../assets2/icons/placeOffers/33.svg";
import icon34 from "../../assets2/icons/placeOffers/34.svg";
import icon35 from "../../assets2/icons/placeOffers/35.svg";
import icon36 from "../../assets2/icons/placeOffers/36.svg";
import icon37 from "../../assets2/icons/placeOffers/37.svg";
import icon38 from "../../assets2/icons/placeOffers/38.svg";
import icon39 from "../../assets2/icons/placeOffers/39.svg";
import icon40 from "../../assets2/icons/placeOffers/40.svg";
import icon41 from "../../assets2/icons/placeOffers/41.svg";
import icon42 from "../../assets2/icons/placeOffers/42.svg";
import icon43 from "../../assets2/icons/placeOffers/43.svg";
import icon44 from "../../assets2/icons/placeOffers/44.svg";
import icon45 from "../../assets2/icons/placeOffers/45.svg";
import icon46 from "../../assets2/icons/placeOffers/46.svg";
import icon47 from "../../assets2/icons/placeOffers/47.svg";
import icon48 from "../../assets2/icons/placeOffers/48.svg";
import icon49 from "../../assets2/icons/placeOffers/49.svg";
import icon50 from "../../assets2/icons/placeOffers/50.svg";
import icon51 from "../../assets2/icons/placeOffers/51.svg";
import icon52 from "../../assets2/icons/placeOffers/52.svg";
import {
  FaPhone,
  FaConciergeBell,
  FaLuggageCart,
  FaBed,
  FaBusinessTime,
  FaMicrophone,
  FaSwimmingPool,
  FaSpa,
  FaShuttleVan,
  FaTaxi,
  FaParking,
} from "react-icons/fa";

const featuresData = [
  {
    category: "Room features",
    key: "roomFeatures",
    items: [
      { name: "Calls", icon: icon27, key: "callsLocal" },
      { name: "Calls (Local)", icon: icon28, key: "callsLocal" },
      {
        name: "Concierge Services",
        icon: FaConciergeBell,
        key: "conciergeServices",
      },
      { name: "Luggage Storage", icon: icon29, key: "luggageStorage" },
      { name: "Room Service", icon: icon30, key: "roomService" },
      { name: "Calls (Toll-free)", icon: icon31, key: "callsTollFree" },
      { name: "Laundry Service", icon: icon32, key: "laundryService" },
      { name: "Internet Access", icon: icon33, key: "internetAccess" },
      { name: "Kitchen", icon: icon34, key: "kitchen" },
    ],
  },
  {
    category: "Business features",
    key: "businessFeatures",
    items: [
      { name: "Business Centre", icon: icon35, key: "businessCentre" },
      { name: "VIP Services", icon: icon36, key: "vipServices" },
      { name: "AV Equipment", icon: icon37, key: "avEquipment" },
      {
        name: "High Speed Internet (100 Mbps)",
        icon: icon38,
        key: "highSpeedInternet",
      },
      { name: "Onsite AV Staff", icon: icon39, key: "onsiteAvStaff" },
      { name: "Video Conference", icon: icon39, key: "videoConference" },
    ],
  },
  {
    category: "Recreational features",
    key: "recreationalFeatures",
    items: [
      { name: "Health Club", icon: icon40, key: "healthClub" },
      { name: "Indoor Pool", icon: icon41, key: "indoorPool" },
      { name: "Outdoor Pool", icon: icon42, key: "outdoorPool" },
      { name: "Spa", icon: icon43, key: "spa" },
      { name: "Salon", icon: icon44, key: "salon" },
      { name: "Tennis Courts", icon: icon45, key: "tennisCourts" },
      { name: "Whirlpool", icon: icon46, key: "whirlpool" },
    ],
  },
];
const locationData = [
  {
    category: "Accessibility",
    key: "accessibilityFeatures",
    items: [
      { name: "Airport Shuttle", icon: icon47, key: "airportShuttle" },
      { name: "Bus", icon: icon48, key: "bus" },
      { name: "Taxi", icon: icon49, key: "taxi" },
      { name: "Metro", icon: icon50, key: "metro" },
    ],
  },
  {
    category: "Parking",
    key: "parkingFeatures",
    items: [
      { name: "Parking available", icon: icon51, key: "parkingAvailable" },
      { name: "Valet Parking", icon: icon52, key: "valetParking" },
    ],
  },
];

const FeatureItem = ({ feature, available }) => {
  return (
    <div
      className={`flex items-center w-1/2 mb-4 ${
        available ? "block" : "hidden"
      }`}
    >
      <img src={feature.icon} alt="icon" className="text-xl mr-3" />
      <span>{feature.name}</span>
    </div>
  );
};

const FeaturesSection = ({ data }) => {
  const [formData, setFormData] = useState(data || {});

  useEffect(() => {
    setFormData(data);
    // console.log("data in place-offers", data);
  }, [data]);

  return (
    <div className="md:py-5 py-1 w-full md:border-t mx-auto">
      <div className="bg-white rounded-lg">
        <h2 className="md:text-2xl text-[20px] gap-5 font-semibold mb-6">
          What this place offers
        </h2>
        {featuresData.map((category, index) => (
          <div key={index} className="mb-6 md:flex gap-[65px]  items-start">
            <h3 className="text-[20px] font-medium text-[#222222] mb-4 lg:w-1/5 w-full">
              {category.category}
            </h3>
            <div className="flex justify-between items-center flex-wrap ml-4 md:w-4/5 w-full">
              {category.items.map((feature, itemIndex) => {
                // console.log("feature", feature.name);
                const available =
                  formData?.amenities?.[category.key]?.[feature.name] || false;
                // console.log("available", available);
                return (
                  <FeatureItem
                    key={itemIndex}
                    feature={feature}
                    available={available}
                  />
                );
              })}
            </div>
          </div>
        ))}

        {locationData.map((category, index) => (
          <div key={index} className="mb-6 md:flex gap-[65px]  items-start">
            <h3 className="text-[20px] font-medium text-[#222222] mb-4 lg:w-1/5 w-full">
              {category.category}
            </h3>
            <div className="flex justify-between items-center flex-wrap ml-3 w-4/5">
              {category.items.map((feature, itemIndex) => {
                const available =
                  formData?.overview?.locationDetails?.[category.key]?.[
                    feature.key
                  ] || false;
                return (
                  <FeatureItem
                    key={itemIndex}
                    feature={feature}
                    available={available}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
