import React from "react";

function AvgTimeSpendTable({ data, startDate, endDate }) {
  return (
    <div className="  rounded-xl inline  ">
      <div className="overflow-x-auto rounded-xl bg-white mt-4">
        <table className="w-full text-left  ">
          <thead className="bg-[#F4F4F4]/70 ">
            <tr>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Device
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Page impressions
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Page visited
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Average time spent (in mins )
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {data &&
              data.length > 0 &&
              data.map((device, index) => (
                <tr className="hover:bg-gray-50" key={index}>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {device.device || "Unknown"}
                  </td>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {startDate || endDate
                      ? device.impressionsByDate
                      : device.impressions || 0}
                  </td>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {startDate || endDate
                      ? device.pageVisitsByDate
                      : device.pageVisits || 0}
                  </td>
                  <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                    {startDate || endDate
                      ? device.avgVisitTimeByDate
                      : device.avgVisitTime || 0}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="flex items-center justify-end gap-x-3 px-6 py-3 border-t">
          <div className="flex items-center gap-2">
            <span className="text-sm text-[#222222]">Display:</span>
            <select
              //   value={pageSize}
              //   onChange={handlePageSizeChange}
              className="px-2 py-1 border rounded-lg"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-2">
            <button
              //   onClick={() => handlePageChange(currentPage - 1)}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
              //   disabled={currentPage === 1}
            >
              Previous
            </button>
            {/* <span>
              Page {currentPage} of{" "}
              {Math.ceil(filteredEnquiries?.length / pageSize) || 1}
            </span> */}
            <button
              //   onClick={() => handlePageChange(currentPage + 1)}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
              //   disabled={
              //     currentPage === Math.ceil(filteredEnquiries?.length / pageSize)
              //   }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvgTimeSpendTable;
