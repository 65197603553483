function extractMapCoordinates(mapLink) {
  // This regex pattern looks for latitude and longitude in the URL
  const pattern = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
  const match = mapLink?.match(pattern);
  if (match && match[1] && match[2]) {
    return {
      lat: parseFloat(match[1]),
      lng: parseFloat(match[2]),
    };
  }
  return null;
}

export default extractMapCoordinates;
