import React, { useEffect, useState } from "react";
import logo from "../assets/pics/logoMain.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CrownIcon from "./shared/common/icons/crown-icon";
import DashboardIcon from "./shared/common/icons/dashboard-icon";
import DescriptionIcon from "./shared/common/icons/description-icon";
import TeamIcon from "./shared/common/icons/team-icons";
import { fetchLoggedUser } from "../api/loggedUser";
import { useUser } from "../context/user";
import EnquiryIcon from "./shared/common/icons/enquiry-icon";
import SettingsIcon from "./shared/common/icons/settings-icon";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [hideDashboard, setHideDashboard] = useState(false);
  const [vendorDashboard, setVendorDashboard] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    setEditBilling,
    setShowVenues,
    setActiveReports,
    setCountryReport,
    setFunnelReport,
    setEventTypeReport,
    setEventStatusReport,
    setTotalEnquiryReport,
    setAverageBudgetReport,
    setAverageResponseReport,
    setSaveAsFav,
    setAvgTimeSpendReport,
    setActiveEnquiryDetails,
  } = useUser();

  // when user click on subscription sider bar close all tabs if any open
  const handleClick = (to) => {
    setShowVenues(false);
    setEditBilling(false);
    setActiveReports(false);
    setFunnelReport(false);
    setCountryReport(false);
    setEventTypeReport(false);
    setEventStatusReport(false);
    setTotalEnquiryReport(false);
    setAverageBudgetReport(false);
    setAverageResponseReport(false);
    setSaveAsFav(false);
    setAvgTimeSpendReport(false);
    setActiveEnquiryDetails(false);
    navigate(to);
  };
  const [showVendorDashboard, setShowVendorDashboard] = useState(false);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const response = await fetchLoggedUser();
        setUser(response.vendor);

        // Check if user has any listings
        const hasVenueListing = response.vendor?.venueForms?.length > 0;
        const hasRestaurantListing =
          response.vendor?.RestaurantForm?.length > 0;
        const hasHotelListing = response.vendor?.Hotel?.length > 0;

        // Show vendor dashboard if admin AND has at least one listing in any category
        if (
          response.vendor?.isAdmin &&
          (hasVenueListing || hasRestaurantListing) &&
          hasHotelListing
        ) {
          setShowVendorDashboard(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  // Determine which dashboard to show
  const shouldShowZeroDashboard =
    !showVendorDashboard && !user?.invitedBy && !loading;
  return (
    <div
      className={`min-h-screen  top-0 left-0  h-full  w-full bg-gray-50 border-r shadow-md`}
    >
      <div className="p-4 hidden md:flex flex-col  items-start w-full justify-center">
        <img
          src={logo}
          alt="Logo"
          className="cursor-pointer"
          // onClick={() => handleClick("/home")}
        />
        {/* if admin full access else not */}
        <>
          <nav className="mt-10 md:mt-4 w-full ">
            <ul className="space-y-2">
              {/* zeroth dashbaord */}
              {shouldShowZeroDashboard && (
                <li>
                  <div
                    role="button"
                    onClick={() => handleClick("/dashboard")}
                    style={{
                      backgroundColor:
                        location.pathname === "/dashboard"
                          ? props.colour.backgroundcolour
                          : "",
                      marginRight: "7px",
                      borderRadius: "9px",
                    }}
                    className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg"
                  >
                    <DashboardIcon
                      color={
                        location.pathname === "/dashboard"
                          ? props.colour.colour
                          : "#B0B0B0"
                      }
                    />
                    <span
                      className="ml-3"
                      style={{
                        color:
                          location.pathname === "/dashboard"
                            ? props.colour.colour
                            : "",
                      }}
                    >
                      Dashboard
                    </span>
                  </div>
                </li>
              )}

              {/* Show vendor dashboard only if conditions are met */}
              {showVendorDashboard && (
                <li>
                  <div
                    role="button"
                    onClick={() => handleClick("/vendor-dashboard")}
                    style={{
                      backgroundColor:
                        location.pathname === "/vendor-dashboard"
                          ? props.colour.backgroundcolour
                          : "",
                      marginRight: "7px",
                      borderRadius: "9px",
                    }}
                    className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg"
                  >
                    <DashboardIcon
                      color={
                        location.pathname === "/vendor-dashboard"
                          ? props.colour.colour
                          : "#B0B0B0"
                      }
                    />
                    <span
                      className="ml-3"
                      style={{
                        color:
                          location.pathname === "/vendor-dashboard"
                            ? props.colour.colour
                            : "",
                      }}
                    >
                      Dashboard
                    </span>
                  </div>
                </li>
              )}
              {/* enquiry vendor */}
              <li>
                <div
                  role="button"
                  onClick={() => handleClick("/enquiryvendor")}
                  style={{
                    backgroundColor:
                      location.pathname === "/enquiryvendor"
                        ? props.colour.backgroundcolour
                        : "",
                  }}
                  className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                >
                  <EnquiryIcon
                    color={
                      location.pathname === "/enquiryvendor"
                        ? props.colour.colour
                        : "#B0B0B0"
                    }
                  />
                  <span
                    className="ml-3"
                    style={{
                      color:
                        location.pathname === "/enquiryvendor"
                          ? props.colour.colour
                          : "",
                    }}
                  >
                    Enquiry
                  </span>
                </div>
              </li>
              {/* Team */}
              <li>
                <div
                  role="button"
                  style={{
                    backgroundColor:
                      location.pathname === "/team"
                        ? props.colour.backgroundcolour
                        : "",
                  }}
                  onClick={() => handleClick("/team")}
                  className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                >
                  <TeamIcon
                    color={
                      location.pathname === "/team"
                        ? props.colour.colour
                        : "#B0B0B0"
                    }
                  />
                  <span
                    className="ml-3"
                    style={{
                      color:
                        location.pathname === "/team"
                          ? props.colour.colour
                          : "",
                    }}
                  >
                    My Team
                  </span>
                </div>
              </li>
              {/* My venues */}
              <li>
                <div
                  role="button"
                  style={{
                    backgroundColor:
                      location.pathname === "/venues"
                        ? props.colour.backgroundcolour
                        : "",
                  }}
                  onClick={() => handleClick("/myVenue")}
                  className="flex rounded-md whitespace-nowrap items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                >
                  <DescriptionIcon
                    color={
                      location.pathname === "/myVenue"
                        ? props.colour.colour
                        : "#B0B0B0"
                    }
                  />
                  <span
                    className="ml-3"
                    style={{
                      color:
                        location.pathname === "/myVenue"
                          ? props.colour.colour
                          : "",
                    }}
                  >
                    My Venues
                  </span>
                </div>
              </li>

              {user?.isAdmin && (
                <li>
                  <div
                    role="button"
                    style={{
                      backgroundColor:
                        location.pathname === "/mysubscriptions"
                          ? props.colour.backgroundcolour
                          : "",
                    }}
                    onClick={() => handleClick("/mysubscriptions")}
                    className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                  >
                    <CrownIcon
                      color={
                        location.pathname === "/mysubscriptions"
                          ? props.colour.colour
                          : "#B0B0B0"
                      }
                    />
                    <span
                      className="ml-3"
                      style={{
                        color:
                          location.pathname === "/mysubscriptions"
                            ? props.colour.colour
                            : "",
                      }}
                    >
                      My Subscription
                    </span>
                  </div>
                </li>
              )}
              {/* Settings */}
              <li>
                <div
                  role="button"
                  onClick={() => handleClick("/settings-dashboard")}
                  style={{
                    backgroundColor:
                      location.pathname === "/settings-dashboard"
                        ? props.colour.backgroundcolour
                        : "",
                  }}
                  className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                >
                  <SettingsIcon
                    color={
                      location.pathname === "/settings-dashboard"
                        ? props.colour.colour
                        : "#B0B0B0"
                    }
                  />
                  <span
                    className="ml-3"
                    style={{
                      color:
                        location.pathname === "/settings-dashboard"
                          ? props.colour.colour
                          : "",
                    }}
                  >
                    Settings
                  </span>
                </div>
              </li>
              {/* reports */}
              <li>
                <div
                  role="button"
                  onClick={() => handleClick("/reports")}
                  style={{
                    backgroundColor:
                      location.pathname === "/reports"
                        ? props.colour.backgroundcolour
                        : "",
                  }}
                  className="flex items-center p-2 text-gray-700 hover:bg-primary8 hover:text-primary hover:rounded-lg mr-2"
                >
                  <EnquiryIcon
                    color={
                      location.pathname === "/reports"
                        ? props.colour.colour
                        : "#B0B0B0"
                    }
                  />
                  <span
                    className="ml-3"
                    style={{
                      color:
                        location.pathname === "/reports"
                          ? props.colour.colour
                          : "",
                    }}
                  >
                    Reports
                  </span>
                </div>
              </li>
            </ul>
          </nav>
        </>
      </div>
    </div>
  );
};

export default Sidebar;
