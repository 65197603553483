"use client";
import { base_URL } from "../config/config";
import axios from "axios";

const API_BASE_URL = `${base_URL}/api/vendor`;

export const fetchLoggedUser = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get(`${API_BASE_URL}/LoggedUser`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching logged user:", error);
    throw error;
  }
};

export const fetchUserById = async (vendorId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-vendor/${vendorId}`);
    return response.data;
  } catch (error) {
    // console.error('Error fetching logged user:', error);
    throw error;
  }
};
