"use client";
import React, { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const CustomInput = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  label,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full ">
      <p className="text-dark mb-1 font-normal">{label}</p>
      <div className="w-full relative">
        <input
          type={showPassword && type === "password" ? "text" : type}
          name={name}
          value={value}
          onChange={onChange}
          className="w-full py-2 px-3 border border-bordered outline-none rounded-md"
          placeholder={placeholder}
          id=""
        />

        {type === "password" && (
          <div
            className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-gray"
            onClick={handleTogglePassword}
          >
            {showPassword ? <IoMdEyeOff size={20} /> : <IoMdEye size={20} />}
          </div>
        )}
      </div>
      {label === "Password" && (
        <p className="text-[#00000099] font-[0.75rem]">
          Password should be at least 8 characters long.
        </p>
      )}

      {error && name === "basePrice" ? (
        <p className="text-[#9ca3af] text-sm mt-1">{error}</p>
      ) : (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
};

export default CustomInput;
