/* eslint-disable */

import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DetailComponent from "./DetailComponent";
import { FilterColor } from "../../svgs";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { MdChevronRight } from "react-icons/md";
import Map from "../map/map";
import SliderDetailVenueTab from "./SliderDetailVenueTab";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { setCompareVenues } from "../../store/features/authSlice";
import { useNavigate } from "react-router-dom";
import { base_URL } from "../../config/config";

const fetchVenues = async (page, filters) => {
  const { data } = await axios.get(`${base_URL}/api/venues/get`, {
    params: {
      page,
      limit: 24,
      ...filters,
    },
  });
  return data;
};

const fetchRestaurants = async (page, filters) => {
  const { data } = await axios.get(`${base_URL}/api/restaurants/get`, {
    params: {
      page,
      limit: 24,
      ...filters,
    },
  });
  return data;
};

const fetchVenuesAndRestaurants = async (page, filters) => {
  console.log("VenueTab rendered with filters:", filters);

  const [venuesData = {}, restaurantsData = {}] = await Promise.all([
    fetchVenues(page, filters).catch(() => ({ venues: [], total: 0 })),
    fetchRestaurants(page, filters).catch(() => ({
      restaurants: [],
      total: 0,
    })),
  ]);

  // Safeguard in case the structure is undefined or incorrect
  const venuesList = venuesData.venues || [];
  const restaurantsList = restaurantsData.restaurants || [];
  console.log("venues list", [...venuesList, ...restaurantsList]);

  // Return combined data
  return {
    venues: [...venuesList, ...restaurantsList],
    total: (venuesData.total || 0) + (restaurantsData.total || 0),
  };
};

const sortOptions = [
  {
    name: "Price Per Person",
    options: ["Low to High", "High to Low"],
  },
  {
    name: "Minimum Spend",
    options: ["Low to High", "High to Low"],
  },
  {
    name: "Offers Availability",
  },
  {
    name: "Newest",
  },
  { name: "Popularity (Most Viewed)" },
  // "Customer Ratings",
];

const CategoryCarousel = ({
  filters,
  isShowMap,
  setIsShowMap,
  city,
  setCount,
}) => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [filterVenues, setFilterVenues] = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const [page, setPage] = useState(1);
  const [isCompareMode, setIsCompareMode] = useState(false);
  const [totalVenues, setTotalVenues] = useState(0);
  const [venues, setVenues] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSort, setShowSort] = useState(false);
  const [sortType, setSortType] = useState("default");
  const [showSubSort, setShowSubSort] = useState("");
  const [sortSubType, setSortSubType] = useState("default");

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["venuesAndRestaurants", page, filters],

    queryFn: () => fetchVenuesAndRestaurants(page, filters),
    keepPreviousData: true,
    onSuccess: (data) => {
      setVenues((prevVenues) => [...prevVenues, ...data.venues]);
      setTotalVenues(data.total);
    },
  });

  useEffect(() => {
    if (isShowMap) {
      setCount((prev) => ({ ...prev, venue: filterVenues.length }));
    } else {
      setCount((prev) => ({ ...prev, venue: venues.length || 0 }));
    }
  }, [isShowMap, venues, filterVenues]);

  const handleScroll = () => {
    const bottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight;
    if (bottom && page < data?.totalPages) {
      setPage((prevPage) => prevPage + 1); // Increment page
    }
  };

  // Fetch next page when page number changes
  useEffect(() => {
    if (page > 1) {
      refetch(); // Fetch data for the next page
    }
  }, [page, refetch]);

  useEffect(() => {
    if (data) {
      // console.log("Data:", data.venues);
      setTotalVenues(data.total);
      setVenues(data.venues);
    }
  }, [data]);
  useEffect(() => {
    setCount((prev) => ({ ...prev, venue: totalVenues }));
  }, [venues, totalVenues]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (mapBounds && venues.length > 0) {
      const visibleVenues = venues.filter((venue) => {
        const lat = venue.location.lat;
        const lng = venue.location.lng;

        return (
          lat >= mapBounds.south &&
          lat <= mapBounds.north &&
          lng >= mapBounds.west &&
          lng <= mapBounds.east
        );
      });
      if (JSON.stringify(visibleVenues) !== JSON.stringify(venues)) {
        setFilterVenues(visibleVenues);
      }
    }
  }, [mapBounds, venues]);
  useEffect(() => {
    if (filters?.minSpendPerPerson) {
      const filtered = venues.filter((venue) => {
        const minSpendPerson =
          venue?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend || 0;
        return (
          minSpendPerson >= filters?.minSpendPerPerson?.min?.min &&
          minSpendPerson <= filters?.minSpendPerPerson?.min?.max
        );
      });
      setFilterVenues(filtered);
      setVenues(filtered);
    }
  }, [filters, venues]);
  useEffect(() => {
    if (filters?.minSpendPerEvent) {
      const filtered = venues.filter((venue) => {
        const minSpendPerson =
          venue?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend || 0;
        return (
          minSpendPerson >= filters?.minSpendPerEvent?.min?.min &&
          minSpendPerson <= filters?.minSpendPerEvent?.min?.max
        );
      });
      setFilterVenues(filtered);
      setVenues(filtered);
    }
  }, [filters, venues]);

  useEffect(() => {
    if (filters.menus && filters.menus.length > 0) {
      const filtered = venues.filter((venue) => {
        const menu1 = venue?.cateringAndDrinks.availableMenu || [];
        const menu2 = venue?.cateringAndDrinks.cuisine || [];
        return filters.menus.every((menu) => {
          return menu2.includes(menu) || menu1.includes(menu);
        });
      });
      setFilterVenues(filtered);
      setVenues(filtered);
    }
  }, [filters, venues]);

  // Filter venues based on capacity
  useEffect(() => {
    if (filters.sittingGuests) {
      const filtered = venues.filter((venue) => {
        const capacity = Number(venue?.capacity?.sitting) || 0;
        return capacity >= filters?.sittingGuests;
      });
      // console.log("sittingFilteredVenues", filtered);
      setFilterVenues(filtered);
      setVenues(filtered);
    }
  }, [filters, venues]);

  // Update the count whenever filteredVenues changes
  useEffect(() => {
    setCount((prev) => ({ ...prev, venue: filterVenues.length }));
  }, [filterVenues, setCount]);

  useEffect(() => {
    if (filters.standingGuests) {
      const filteredVenues = venues.filter((venue) => {
        const capacity = Number(venue?.capacity?.standing) || 0;
        return capacity >= filters?.standingGuests;
      });
      // console.log("filteredVenues", filteredVenues);
      setFilterVenues(filteredVenues);

      setVenues(filteredVenues);
    }
  }, [filters, venues]);
  useEffect(() => {
    if (filters.venueSettings && filters.venueSettings.length > 0) {
      const filteredVenues = venues.filter((venue) => {
        const settings = venue?.additionalDetails?.venueSetting || [];
        // console.log("settings", settings);

        return filters.venueSettings.every((setting) => {
          if (setting === "Indoor + Outdoor") {
            return settings.includes("Indoor") && settings.includes("Outdoor");
          } else {
            return settings.includes(setting);
          }
        });
      });
      // console.log("filteredVenues", filteredVenues);
      setFilterVenues(filteredVenues);

      setVenues(filteredVenues);
    }
  }, [filters, venues]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleVenueSelect = (venue) => {
    setSelectedVenues((prevSelected) => {
      const alreadySelected = prevSelected.some(
        (item) => item._id === venue._id
      );
      if (alreadySelected) {
        return prevSelected.filter((item) => item._id !== venue._id);
      } else if (prevSelected.length < 3) {
        return [...prevSelected, venue];
      } else {
        return prevSelected;
      }
    });
  };

  const handleCompare = () => {
    dispatch(setCompareVenues(selectedVenues));
    navigate("/compare-venues");
  };
  const handleRemove = (id) => {
    setSelectedVenues((prevSelected) =>
      prevSelected.filter((venue) => venue._id !== id)
    );
  };

  const handleSort = (type) => {
    setSortType(type);
    if (
      type === "Offers Availability" ||
      type === "Newest" ||
      type === "Popularity (Most Viewed)"
    ) {
      setShowSort(false);
    }
    if (type === "Price Per Person") {
      setShowSubSort("pricePerPerson");
    } else if (type === "Minimum Spend") {
      setShowSubSort("minimumSpend");
    } else {
      return;
    }
  };
  const handleSubSort = (type) => {
    setSortSubType(type);
    setShowSubSort("");
    setShowSort(false);
    // console.log("sortSubType", sortSubType);
  };

  const sortByPageVisits = async () => {
    try {
      const response = await axios.get(
        `${base_URL}/api/impressions/get-pagevisits`
      );
      // console.log("Page visits:", response.data);
      const sortedVenues = venues.sort((a, b) => {
        let visitA, visitB;
        response.data.forEach((visit) => {
          if (visit.venueId === a._id) {
            // console.log("Visit A:", visit.totalPageVisits);
            visitA = visit.totalPageVisits;
          }
          if (visit.venueId === b._id) {
            // console.log("Visit B:", visit.totalPageVisits);
            visitB = visit.totalPageVisits;
          }
        });
        return visitB - visitA;
      });
      // console.log("Sorted Venues:", sortedVenues);
      setVenues(sortedVenues);
      setFilterVenues(sortedVenues);
    } catch (error) {
      console.error("Error fetching page visits:", error);
    }
  };

  useEffect(() => {
    let sortedVenues = [];
    if (sortType === "Offers Availability") {
      sortedVenues = venues.map((venue) => {
        const offers = venue?.offersAndPackages || [];
        if (offers.length > 0) {
          return venue;
        } else {
          return;
        }
      });
      console.log(sortedVenues);
      sortedVenues = sortedVenues.filter((venue) => venue !== undefined);
      setFilterVenues(sortedVenues);
      setVenues(sortedVenues);
    } else if (sortType === "Newest") {
      sortedVenues = venues.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFilterVenues(sortedVenues);
      setVenues(sortedVenues);
    } else if (sortType === "Minimum Spend") {
      if (sortSubType === "Low to High") {
        sortedVenues = venues.sort((a, b) => {
          const minSpendA =
            a?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend || 0;
          const minSpendB =
            b?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend || 0;
          return minSpendA - minSpendB;
        });
        setFilterVenues(sortedVenues);
        setVenues(sortedVenues);
      } else if (sortSubType === "High to Low") {
        sortedVenues = venues.sort((a, b) => {
          const minSpendA =
            a?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend || 0;
          const minSpendB =
            b?.pricing?.["Minimum Spend Pricing"]?.[0]?.minSpend || 0;
          return minSpendB - minSpendA;
        });
        setFilterVenues(sortedVenues);
        setVenues(sortedVenues);
      }
    } else if (sortType === "Price Per Person") {
      //{data?.pricing?.["Venue Rental"]?.[0]?.minSpend ||
      // data?.pricing?.["Min spend per person"]?.[0]?.minSpend ||
      // "0"}
      if (sortSubType === "Low to High") {
        sortedVenues = venues.sort((a, b) => {
          const minSpendA =
            a?.pricing?.["Min spend per person"]?.[0]?.minSpend ||
            a?.pricing?.["Venue Rental"]?.[0]?.minSpend ||
            0;
          const minSpendB =
            b?.pricing?.["Min spend per person"]?.[0]?.minSpend ||
            a?.pricing?.["Venue Rental"]?.[0]?.minSpend ||
            0;
          return minSpendA - minSpendB;
        });
        setFilterVenues(sortedVenues);
        setVenues(sortedVenues);
      }
      if (sortSubType === "High to Low") {
        sortedVenues = venues.sort((a, b) => {
          const minSpendA =
            a?.pricing?.["Min spend per person"]?.[0]?.minSpend ||
            a?.pricing?.["Venue Rental"]?.[0]?.minSpend ||
            0;
          const minSpendB =
            b?.pricing?.["Min spend per person"]?.[0]?.minSpend ||
            a?.pricing?.["Venue Rental"]?.[0]?.minSpend ||
            0;
          return minSpendB - minSpendA;
        });
        setFilterVenues(sortedVenues);
        setVenues(sortedVenues);
      }
    } else if (sortType === "Popularity (Most Viewed)") {
      sortByPageVisits();
    } else {
      sortedVenues = venues;
    }
  }, [sortType, sortSubType]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading venues</p>;
  return (
    <div className="relative w-full ">
      <div className="flex flex-col gap-4">
        <div
          className={`flex w-full justify-between font-medium items-center `}
        >
          <h1 className="text-lg">
            {filterVenues.length
              ? `${filterVenues.length} `
              : `${totalVenues} `}
            venues in {filters.city || "All Cities"}
          </h1>
          <div className="flex items-center gap-6">
            {/* <div className="flex items-center gap-2 cursor-pointer">
              <p>Compare</p>
              <Boxes />
            </div> */}

            <div className="flex items-center gap-2 relative">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => {
                  setShowSort(!showSort);
                  setSortType("default");
                }}
              >
                <p className="cursor-pointer">Sort</p>
                <FilterColor className="cursor-pointer" />
              </div>
              {showSort && (
                <div className="absolute top-8 right-0 bg-white shadow-md z-50 w-[170px] rounded-lg p-2">
                  <div className="flex flex-col gap-1 w-full">
                    {sortOptions.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center  w-full justify-between "
                      >
                        <div
                          className=" flex justify-between items-center w-full hover:bg-[#f5f5f5]  p-2 rounded-lg cursor-pointer"
                          onClick={() => handleSort(option.name)}
                        >
                          <p className="text-[14px] font-normal">
                            {option.name}
                          </p>
                          {(option.name === "Price Per Person" ||
                            option.name === "Minimum Spend") && (
                            <MdChevronRight />
                          )}
                        </div>
                        {option?.options &&
                          (showSubSort === "pricePerPerson" ||
                            showSubSort === "minimumSpend") &&
                          sortType === option.name && (
                            <div className="absolute top-0  bg-white shadow-md z-50  rounded-lg right-[175px] w-[160px] p-2">
                              <div className="flex flex-col gap-1 w-full">
                                {option.options.map((subOption, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center gap-2 cursor-pointer w-full justify-between "
                                  >
                                    <div
                                      className="text-[14px] w-full font-normal cursor-pointer p-2 rounded-lg hover:bg-[#f5f5f5]"
                                      onClick={() => handleSubSort(subOption)}
                                    >
                                      {subOption}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Render the component corresponding to the active tab */}
        {isShowMap ? (
          <div className={`grid w-full lg:grid-cols-3 gap-6 `}>
            <div
              className={`md:grid w-full lg:col-span-1 lg:grid-cols-1 hidden overflow-y-auto grid-cols-1 gap-6`}
            >
              {isShowMap
                ? filterVenues?.map((venue, index) => (
                    <DetailComponent
                      key={index}
                      data={venue}
                      isCompareMode={isCompareMode}
                      isSelected={selectedVenues.some(
                        (selectedVenue) => selectedVenue._id === venue._id
                      )}
                      onSelect={handleVenueSelect}
                    />
                  ))
                : venues?.map((venue, index) => (
                    <DetailComponent
                      key={index}
                      data={venue}
                      isCompareMode={isCompareMode}
                      isSelected={selectedVenues.some(
                        (selectedVenue) => selectedVenue._id === venue._id
                      )}
                      onSelect={handleVenueSelect}
                    />
                  ))}
            </div>
            <div className="lg:col-span-2">
              <div className="relative ">
                <Map
                  city={filters.city ? filters.city : city}
                  setIsShowMap={setIsShowMap}
                  places={venues}
                  setMapBounds={setMapBounds}
                  setFilterVenues={setFilterVenues}
                />
              </div>
              {isMobileView && isShowMap && filterVenues.length > 0 && (
                <div className="absolute top-[54vh] overflow-hidden w-full ">
                  <SliderDetailVenueTab data={filterVenues} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`grid w-full  lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6`}
          >
            {venues?.map((venue, index) => (
              <DetailComponent
                key={index}
                data={venue}
                isCompareMode={isCompareMode}
                isSelected={selectedVenues.some(
                  (selectedVenue) => selectedVenue._id === venue._id
                )}
                onSelect={handleVenueSelect}
              />
            ))}
          </div>
        )}
      </div>
      {selectedVenues?.length > 0 && isCompareMode && (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md z-50 p-4 px-6 flex items-center justify-between gap-4">
          <div className="flex items-center gap-5 overflow-x-auto px-2 md:px-0">
            <span className="text-dark md:text-[16px] text-[11px]">
              Compare Venues (Up to 3 Venues)
            </span>
            {selectedVenues?.map((venue) => (
              <div
                key={venue._id}
                className="flex items-center bg-gray-100 shadow-md rounded p-2 md:w-60 gap-y-2 gap-x-7 ml-2"
              >
                <img
                  src={venue?.imagesAndVideos.images[0]}
                  alt={venue.name}
                  className="md:h-10 md:w-10 h-6 w-6 object-cover rounded"
                />
                <span className="md:text-sm font-medium text-xs ">
                  {" "}
                  {venue?.basicDetails?.venueName || "Undefined"},{" "}
                  {venue?.basicDetails?.hotelName || "Undefined"}
                </span>
                <button
                  onClick={() => handleRemove(venue._id)}
                  className=" text-lg font-bold focus:outline-none"
                >
                  <RxCross2 size={22} className="text-primary cursor-pointer" />
                </button>
              </div>
            ))}
          </div>
          <div>
            <div className="flex justify-center items-center gap-4">
              <button
                onClick={handleCompare}
                className="bg-[#FE4747] md:w-auto w-full text-[12px] rounded-lg text-white py-2 md:px-4 px-3 lg:text-[16px] lg:font-[500]"
              >
                Compare
              </button>

              <div>
                <RxCross2
                  size={22}
                  className="text-primary cursor-pointer"
                  onClick={() => setIsCompareMode(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryCarousel;
