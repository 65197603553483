import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";

const CustomCheckBox = ({ label, isChecked, onChange, name, value }) => {
  return (
    <div className="flex items-start gap-3">
      {name !== "No city selected yet!" && (
        <input
          type="checkbox"
          value={value}
          id={`custom-checkbox-${label}`}
          className="hidden peer"
          checked={isChecked}
          onChange={onChange}
          name={name}
        />
      )}
      {name !== "No city selected yet!" && (
        <label
          htmlFor={`custom-checkbox-${label}`} // Matching label for checkbox
          className="w-5 h-5 flex-shrink-0 relative rounded-sm border border-gray bg-transparent cursor-pointer  flex items-center justify-center peer-checked:bg-black"
        >
          {isChecked && <IoCheckmarkSharp className="text-lg text-white" />}
        </label>
      )}

      <p className="text-dark -mt-1">{label}</p>
    </div>
  );
};

export default CustomCheckBox;
