import Navbar from "../../../components/NavbarCustomer";
import user from "../../../assets2/images/User.png";
import edit from "../../../assets2/images/edit.png";
import { toast } from "react-toastify";
import { base_URL } from "../../../config/config";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/features/authSlice";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input/input";
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import AWS from "aws-sdk";

// AWS Configuration
const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function Profile({ filters, setFilters }) {
  const savedUser = localStorage.getItem("authUser");
  const [country, setCountry] = useState("AE"); // Default country
  const [countryCode, setCountryCode] = useState("+971");
  const [parsedNumber, setParsedNumber] = useState(null);
  const [profileImage, setProfileImage] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [photoModal, setPhotoModal] = useState(false);
  // console.log(savedUser);
  const dispatch = useDispatch();

  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    customerType: "",
    companyName: "",
    profilePic: "",
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    customerType: "",
    companyName: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  // Handle file upload to S3 and then update DB
  const handleFileUpload = useCallback(
    async (file) => {
      const fileName = `profile-photos/profile-${Date.now()}.${file?.name
        .split(".")
        .pop()}`;

      const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileName,
        ContentType: file.type,
      };

      try {
        setUploadProgress(0);
        const upload = myBucket.upload(params);

        upload.on("httpUploadProgress", (evt) => {
          setUploadProgress(Math.round((evt.loaded / evt.total) * 100));
        });

        const result = await upload.promise();
        const imageUrl = result.Location;
        console.log(imageUrl, "uploadedone");
        setProfileImage(imageUrl);
      } catch (error) {
        console.error("Upload error:", error);
        toast.error("Failed to update profile photo");
      } finally {
        setUploadProgress(0);
        setPhotoModal(false);
      }
    },
    [dispatch, savedUser]
  );

  // Handle gallery selection
  const handleGallery = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        handleFileUpload(file);
      }
    };
    input.click();
  };
  // Handle camera capture
  const handleCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.createElement("video");
      video.srcObject = stream;
      await video.play();

      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      canvas.getContext("2d").drawImage(video, 0, 0);

      canvas.toBlob((blob) => {
        handleFileUpload(
          new File([blob], "profile.jpg", { type: "image/jpeg" })
        );
      }, "image/jpeg");

      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Camera error:", error);
      toast.error("Failed to access camera");
    }
  };
  useEffect(() => {
    if (savedUser) {
      const user = savedUser ? JSON.parse(savedUser) : {};
      const parsedNumber = parsePhoneNumberFromString(user.mobileNumber);
      if (parsedNumber) {
        setCountryCode(`+${parsedNumber.countryCallingCode}`); // Set the dialing code
        setParsedNumber(parsedNumber);
        setCountry(parsedNumber.country); // Set the country
      }
      setFormDetails({
        name: user.fullName,
        email: user.email,
        phoneNumber: parsedNumber?.nationalNumber
          ? parsedNumber.nationalNumber
          : "", // Set the national part of the number
        customerType: user.select,
        companyName: user.companyName,
      });
    }
    // console.log(savedUser);
  }, [savedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails({ ...formDetails, [name]: value });
  };

  const validation = () => {
    if (!formDetails.name) {
      setError((prev) => ({
        ...prev,
        name: "Please enter your name.",
      }));
      return false;
    }
    if (!formDetails.email) {
      setError((prev) => ({
        ...prev,
        email: "Please enter your email address.",
      }));
      return false;
    }
    if (!formDetails.phoneNumber) {
      setError((prev) => ({
        ...prev,
        phoneNumber: "Please enter your phone number.",
      }));
      return false;
    }
    // console.log(`${countryCode}${formDetails.phoneNumber}`);
    if (
      formDetails.phoneNumber &&
      !isValidPhoneNumber(`${formDetails.phoneNumber}`)
    ) {
      // console.log("Invalid phone number");
      // console.log(`${countryCode}${formDetails.phoneNumber}`);
      setError((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number.",
      }));
      return false;
    }
    if (!formDetails.customerType) {
      setError((prev) => ({
        ...prev,
        customerType: "Please select your customer type.",
      }));
      return false;
    }
    if (formDetails.customerType === "corporate" && !formDetails.companyName) {
      setError((prev) => ({
        ...prev,
        companyName: "Please enter your company name.",
      }));
    }
    return true;
  };

  const handleUpdateProfile = async () => {
    if (!validation()) {
      return;
    }
    const updatedFormDetails = {
      ...formDetails,
      phoneNumber: `${formDetails.phoneNumber}`,
      companyName:
        formDetails.customerType === "corporate" ? formDetails.companyName : "",
    };

    setIsLoading(true);
    try {
      // Make API call to update user profile
      const response = await axios.put(
        `${base_URL}/api/auth/updateProfile`,
        {
          fullName: updatedFormDetails.name,
          email: updatedFormDetails.email,
          mobileNumber: updatedFormDetails.phoneNumber.toString(),
          select: updatedFormDetails.customerType,
          companyName: updatedFormDetails.companyName,
          profilePic: profileImage,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      // Handle response
      if (response.status === 200) {
        toast.success("Profile updated successfully!");
        dispatch(setUser(response.data.user));
        localStorage.setItem("authUser", JSON.stringify(response.data.user));
      } else {
        toast.error("Failed to update profile. Please try again.");
        // console.log(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // console.log(error.response || error.message || error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const handleCountryChange = (countryCode) => {
    if (countryCode) {
      setCountry(countryCode);

      try {
        // Directly get the calling code for the selected country
        const dialCode = getCountryCallingCode(countryCode);
        setCountryCode(`+${dialCode}`);

        // If no number is entered, set a placeholder with the new country code
        if (!formDetails.phoneNumber) {
          setFormDetails({ ...formDetails, phoneNumber: `+${dialCode}` });
        }
      } catch (error) {
        console.error("Error getting country calling code:", error);
        setCountryCode("+");
      }
    }
  };

  const handleMobileNumberChange = (value) => {
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      phoneNumber: value,
    }));
  };
  useEffect(() => {
    if (savedUser) {
      const user = JSON.parse(savedUser);
      setProfileImage(user.profilePic);
    }
  }, [savedUser]);
  return (
    <>
      <Navbar filters={filters} setFilters={setFilters} />
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[845px] h-[568px] my-[50px]  ">
          <h1 className="text-[24px] font-[500]">Profile</h1>
          <div className="relative w-[167px] h-[167px] mt-5">
            {profileImage || user?.profilePic ? (
              <img
                src={profileImage || user?.profilePic}
                alt="profile"
                className="h-full w-full object-cover rounded-full"
              />
            ) : (
              <img
                src={user}
                alt="default profile"
                className="h-full w-full object-cover rounded-full"
              />
            )}

            <img
              src={edit}
              alt="edit"
              width={30}
              height={30}
              className="absolute bottom-0 right-[12px] cursor-pointer"
              onClick={() => setPhotoModal(true)}
            />
          </div>
          {/* Photo Upload Modal */}
          {photoModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 w-[280px] md:w-[520px]">
                <h2 className="text-center font-bold mb-8 text-[22px] lg:text-[32px]">
                  Update Profile Photo
                </h2>

                {uploadProgress > 0 && uploadProgress < 100 && (
                  <div className="mb-4">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                  </div>
                )}

                <button
                  className="w-full flex items-center gap-3 px-4 py-2 hover:bg-gray-100 rounded-lg"
                  onClick={handleCamera}
                >
                  <span className="text-[16px] font-normal">Camera</span>
                </button>

                <div className="border-t my-4 border-[#DDDDDD]"></div>

                <button
                  className="w-full flex items-center gap-3 px-4 py-2 hover:bg-gray-100 rounded-lg"
                  onClick={handleGallery}
                >
                  <span className="text-[16px] font-normal">Gallery</span>
                </button>

                <button
                  className="w-full mt-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
                  onClick={() => setPhotoModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <div className="mt-5">
            <h2 className="text-[24px] font-[500]">Basic Information</h2>

            <div className="flex justify-between items-center w-full mt-3 gap-8">
              <div className="w-1/2">
                <p className="text-dark mb-1">Name</p>
                <input
                  type="text"
                  name="name"
                  value={formDetails.name}
                  onChange={handleChange}
                  className="w-full py-2 px-3 border border-bordered outline-none rounded-md"
                  placeholder="Name"
                  id=""
                />
                <span className="text-red-400 text-sm">{error.name}</span>
              </div>
              <div className="w-1/2">
                <p className="text-dark mb-1">Email</p>
                <input
                  type="text"
                  name="email"
                  value={formDetails.email}
                  onChange={handleChange}
                  className="w-full py-2 px-3 border border-bordered outline-none rounded-md"
                  placeholder="Email"
                  id=""
                />
                <span className="text-red-400 text-sm">{error.email}</span>
              </div>
            </div>
            <div className="flex justify-between items-center gap-8 mt-3">
              <div className="w-1/2">
                <div className="relative">
                  <div className="absolute left-[60px] top-1/2 transform   z-10 pointer-events-none">
                    {countryCode}
                  </div>
                  <label
                    htmlFor=""
                    className="relative text-dark mb-1 font-outfit"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    id="mobile-input"
                    placeholder="Enter your contact no."
                    value={formDetails.phoneNumber || ""}
                    defaultCountry="AE"
                    withCountryCallingCode={true}
                    autoComplete="on"
                    onCountryChange={handleCountryChange}
                    onChange={(value) => handleMobileNumberChange(value)}
                    style={{
                      border: "1px solid #d1d5db",
                      boxShadow: "none",
                      borderRadius: "10px",
                      outline: "none",
                      width: "100%",
                      padding: "0.5rem",
                      // marginTop: "0.3rem",
                    }}
                    className="no-focus-border  "
                  />
                </div>
                <span className="text-red-400 text-sm">
                  {error.phoneNumber}
                </span>
              </div>

              <div className="w-1/2">
                <p className="text-dark mb-1">I am</p>
                <select
                  name="customerType"
                  label="Select an option"
                  id=""
                  className="w-full py-2 px-3 border border-bordered outline-none rounded-md text-gray"
                  onChange={handleChange}
                  value={formDetails.customerType}
                >
                  <option value="">Select an option</option>
                  <option value="individual">Individual</option>
                  <option value="eventPlanner">Event Planner</option>
                  <option value="corporate">Corporate</option>
                </select>
                <span className="text-red-400 text-sm">
                  {error.customerType}
                </span>
              </div>
            </div>
            {formDetails.customerType === "corporate" && (
              <div className="flex justify-start items-center gap-8 mt-3">
                <div className="w-[48%]">
                  <p className="text-dark mb-1">Company Name</p>
                  <input
                    type="text"
                    name="companyName"
                    value={formDetails.companyName}
                    onChange={handleChange}
                    className="w-full py-2 px-3 border border-bordered outline-none rounded-md"
                    placeholder="Company Name"
                    id=""
                  />
                </div>
              </div>
            )}

            <div className="flex justify-start items-center mt-5">
              <button
                className="bg-primary text-white py-2 px-5 rounded-md"
                disabled={isLoading}
                onClick={handleUpdateProfile}
              >
                {isLoading ? "Saving Changes..." : "Save Changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
