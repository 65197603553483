import { Download } from "@mui/icons-material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Totalenquiryzero from "./Totalenquiryzero";
import axios from "axios";
import { base_URL } from "../../config/config";
import { useQuery } from "@tanstack/react-query";

export const TotalEnquiriesCard = ({ startDate, endDate, venueName }) => {
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  const { data, isLoading } = useQuery({
    queryKey: ["top-venues-enquiries", startDate, endDate, venueName],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/dashboard/top-venues-enquiries`,
        {
          params: {
            vendorId: vendorId,
            startDate: startDate ? startDate.toLocaleDateString("en-CA") : null,
            endDate: endDate ? endDate.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return response?.data;
    },
  });

  if (isLoading)
    return (
      <div className="w-full h-[35vh] rounded-xl font-outfit md:w-[70%] pb-3 animate-pulse bg-black/20"></div>
    );

  if (!data || data?.totalEnquiries === 0) return <Totalenquiryzero />;
  const truncate = (str, maxLength) =>
    str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  return (
    <div className="w-full bg-[#FFFFFF] rounded-xl font-outfit md:w-[70%] pb-3">
      <div className="flex flex-row items-center justify-between ml-5 md:ml-[20px] mt-[20px]">
        <div className="flex flex-col gap-y-2">
          <div className="text-[20px] md:text-[32px] font-bold">
            {data?.totalEnquiries}{" "}
            <span className="text-[14px] md:text-[20px] font-medium">
              Total Enquiries
            </span>
          </div>
          <p className="text-[#717171] text-[14px] font-normal">
            No. of enquiries received on a venue
          </p>
        </div>
      </div>
      <div className="mt-4 w-full">
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data?.topVenues}
              margin={{ top: 5, right: 20, bottom: 5, left: -12 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <Tooltip
                // on hover bg should be transparent
                cursor={{ fill: "transparent" }}
                content={({ payload }) => {
                  if (!payload || payload?.length === 0) return null;
                  const { name, value } = payload[0]?.payload;
                  return (
                    <div className="tooltip text-[#4990FB] bg-white rounded-lg p-3 shadow-md">
                      <p className="inline md:hidden">{`${name}`}</p>
                      <p>{`Enquiries : ${value}`}</p>
                    </div>
                  );
                }}
              />
              <XAxis
                dataKey="name"
                tickLine={false}
                tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
                tickFormatter={(name) =>
                  window.innerWidth < 640 ? truncate(name, 5) : name
                }
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: 12 }}
                domain={[0, "dataMax"]}
                allowDecimals={false}
              />
              <Bar
                dataKey="value"
                fill="#27C2B0"
                barSize={window.innerWidth < 640 ? 20 : 80}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
