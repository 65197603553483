import React, { useEffect, useState } from "react";
import ImageCarousel from "./ImageCarousel";
import axios from "axios";
import { base_URL } from "../../config/config";
import { useNavigate } from "react-router-dom";

const Spaces = () => {
  const [areaCounts, setAreaCounts] = useState({});
  const navigate = useNavigate();

  // Fetch area counts from the API
  useEffect(() => {
    const fetchAreaCounts = async () => {
      try {
        const response = await axios.get(`${base_URL}/api/getAreaCount`);
        setAreaCounts(response?.data);
      } catch (error) {
        console.error("Error fetching area counts:", error);
      }
    };

    fetchAreaCounts();
  }, []);

  const handleRedirect = (cityName) => {
    navigate(`/city/${cityName}`);
  };
  return (
    <div className="flex justify-center w-full ">
      <div className="w-[90%] md:flex md:flex-col items-center ">
        <h1 className="text-[#222] sm:text-4xl text-2xl font-semibold text-center custom-m">
          Discover Popular Event Spaces in the UAE
        </h1>
        <div className="md:flex hidden h-full w-full gap-5 mt-10">
          <div
            className="bg-white border rounded-lg  relative flex-col  w-[20%] h-full flex items-center cursor-pointer"
            onClick={() => handleRedirect("Dubai")}
          >
            <img
              src="https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980056/WhatsApp_Image_2024-11-07_at_5.16.19_PM_1_mgljnw.jpg"
              alt=""
              className="rounded-lg  h-full  w-full object-cover z-20"
            />
            <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem] absolute rounded-b-lg  z-30">
              <h1 className=" text-lg font-medium">Dubai</h1>
              <p className=" text-[16px] text-[#5E5E5E] font-medium">
                {areaCounts.Dubai?.totalCount || 0} venues
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-5 h-full w-[80%]">
            <div className="flex gap-5">
              <div
                className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center cursor-pointer"
                onClick={() => handleRedirect("Abu Dhabi")}
              >
                <img
                  src="https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980342/Abu_Dhabi.7b61c879dfa310c3699c_ffeeyl.jpg"
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />

                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Abu Dhabi</h1>
                  <p className=" text-[16px] text-[#5E5E5E] font-medium">
                    {areaCounts["Abu Dhabi"]?.totalCount || 0} venues
                  </p>
                </div>
              </div>

              <div
                className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center cursor-pointer"
                onClick={() => handleRedirect("Sharjah")}
              >
                <img
                  src="https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980099/WhatsApp_Image_2024-11-07_at_5.16.19_PM_2_blujzd.jpg"
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />

                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Sharjah</h1>
                  <p className=" text-[16px] text-[#5E5E5E] font-medium">
                    {areaCounts.Sharjah?.totalCount || 0} venues
                  </p>
                </div>
              </div>
              <div
                className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center cursor-pointer"
                onClick={() => handleRedirect("Umm Al Quwain")}
              >
                <img
                  src="https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980284/Umm_Al_Quwain.b8a4c5cfb96c1b95bd7c_fpxc17.jpg"
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />

                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Umm Al Quwain</h1>
                  <p className=" text-[16px] text-[#5E5E5E] font-medium">
                    {areaCounts["Umm Al Quwain"]?.totalCount || 0} venues
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-5">
              <div
                className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center cursor-pointer"
                onClick={() => handleRedirect("Ras Al Khaimah")}
              >
                <img
                  src="https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980169/Ras_Al_Khaimah.603afa2691ff6d90c1c7_acx8bq.jpg"
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />

                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Ras Al Khaimah</h1>
                  <p className=" text-[16px] text-[#5E5E5E] font-medium">
                    {areaCounts["Ras Al Khaimah"]?.totalCount || 0} venues
                  </p>
                </div>
              </div>
              <div
                className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center cursor-pointer"
                onClick={() => handleRedirect("Fujairah")}
              >
                <img
                  src="https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980230/Fujairah.d85bd089364414199871_iyrvlw.jpg"
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />
                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem]  absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Fujairah</h1>
                  <p className=" text-[16px] text-[#5E5E5E] font-medium">
                    {areaCounts["Fujairah"]?.totalCount || 0} venues
                  </p>
                </div>
              </div>

              <div
                className="bg-white border rounded-lg  relative flex-col  w-fit h-full flex items-center cursor-pointer"
                onClick={() => handleRedirect("Ajman")}
              >
                <img
                  src="https://res.cloudinary.com/dyjnaxlbv/image/upload/v1730980022/WhatsApp_Image_2024-11-07_at_5.16.19_PM_mzjrhj.jpg"
                  alt=""
                  className="rounded-lg h-full w-full object-cover"
                />

                <div className=" flex flex-col items-center justify-end pb-3 bg-shadow-gradient bottom-0 w-full md:h-[7rem] h-[3rem] absolute rounded-b-lg  z-30">
                  <h1 className=" text-lg font-medium">Ajman</h1>
                  <p className=" text-[16px] text-[#5E5E5E] font-medium">
                    {areaCounts["Ajman"]?.totalCount || 0} venues
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden block mt-10">
          <ImageCarousel />
        </div>
      </div>
    </div>
  );
};

export default Spaces;
