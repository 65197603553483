/* eslint-disable */

"use client";
import { useState, useEffect } from "react";
import SocialMediaModal from "../myFav/SocialMediaModal";
import HeroSection from "./hero-section";
import AboutThisHotel from "./about-hotel";
import OverviewSection from "./overview-section";
import OffersAndPackages from "./offer-package";
import PricingSection from "./pricing";
import PlaceOffers from "./place-offers";
import EventTypes from "./event-type";
import SpecifyEvent from "../venuePreview/SpecifyEvent";
import RatingsAndReviews from "./ratingsAndReviews";
import { FaStar, FaMapMarkerAlt, FaRegMap } from "react-icons/fa";
import { CiShare2 } from "react-icons/ci";
import Map from "./map";
import { useSelector } from "react-redux";
import NewFavoriteFolderModal from "../myFav/NewFavoriteFolderModal";
import AddedFavoriteModal from "../myFav/AddedFavoriteModal";
import { toast } from "react-toastify";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { base_URL } from "../../config/config";
import { DeviceType } from "../../hooks/deviceType";
import axios from "axios";
import HotelChildCards from "./HotelChildCards";

// let startTime = Date.now();
let lastActive = Date.now();
let activeTime = 0;

export default function VenueStayPreview({ previewData }) {
  const [numberOfVenues, setNumberOfVenues] = useState(undefined);
  const [childVenues, setChildVenues] = useState([]);
  const deviceType = DeviceType();
  const user = useSelector((state) => state.auth.user);
  // const user1 = JSON.parse(localStorage.getItem("authUser"))?._id;
  const favoriteVenues =
    user?.favoritesFolder?.map((folder) => folder.venuesAndStay).flat() || [];
  // const [customerId, setCustomerId] = useState("");
  const [newFolder, setNewFolder] = useState(false);
  const [favItem, setFavItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddedOpen, setIsModalAddedOpen] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [totalActiveTime, setTotalActiveTime] = useState(0);
  const isFavorite = favoriteVenues.some(
    (favorite) => favorite.id === previewData?._id
  );

  const getVenuesNumber = async () => {
    try {
      const response = await axios.post(
        `${base_URL}/api/hotels/number-of-venues`,
        {
          hotelName: previewData?.overview?.basicDetails?.hotelName,
        }
      );
      // console.log(response, "hotelName");
      console.log(response?.data, "totalVenues");
      if (response.data.totalVenues !== 0) {
        setNumberOfVenues(response.data.totalVenues);
        setChildVenues(response?.data?.totalVenuesDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVenuesNumber();
  }, [previewData]);

  const updateActiveTime = () => {
    if (document.visibilityState === "hidden") {
      // User leaves the page
      activeTime += Date.now() - lastActive;
      // console.log(
      //   "Total active time (seconds):",
      //   (activeTime / 1000).toFixed(2)
      // );
      // console.log("Last active (timestamp):", new Date(lastActive));
      // console.log(
      //   "Active time (seconds):",
      //   ((Date.now() - lastActive) / 1000).toFixed(2)
      // );
      // console.log("Device type:", deviceType);
      const sendTime = ((Date.now() - lastActive) / 1000).toFixed(2);
      updateActiveTimeAPI(sendTime);
    } else if (document.visibilityState === "visible") {
      // User returns to the page
      lastActive = Date.now();
    }
  };

  // Attach visibility change listener once
  useEffect(() => {
    const handleVisibilityChange = () => updateActiveTime();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Final calculation before cleanup
      activeTime += Date.now() - lastActive;
      // const totalTime = (Date.now() - startTime) / 1000;
      const totalActiveInSeconds = activeTime / 1000;

      // console.log(`Time spent on venue ${previewData?._id}:`);
      // console.log(`- Total time (active + inactive): ${totalTime} seconds`);
      // console.log(`- Active time: ${totalActiveInSeconds} seconds`);

      // Optionally update the state or send analytics here
      setTotalActiveTime(totalActiveInSeconds);

      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [previewData?._id, user?._id, deviceType]);
  const updateActiveTimeAPI = async (time) => {
    // console.log("Updating active time...");
    // console.log("Total active time (seconds):", totalActiveTime);
    // const time = totalActiveTime;
    try {
      await axios.post(`${base_URL}/api/impressions/update-impressions`, {
        venueId: previewData?._id,
        venueName: `${previewData?.overview?.basicDetails?.hotelName}`,
        pageVisitTime: time,
        customerId: user?._id || "anonymous",
        vendorId: previewData?.vendorId,
        deviceCategory: deviceType,
        date: new Date().toISOString().split("T")[0],
      });

      // console.log("API response:", response.data);
      // toast.success("Active time updated successfully!");
    } catch (error) {
      console.error("Error updating active time:", error);
      // toast.error("Failed to update active time.");
    }
  };

  const handleClickFavorite = (itemId) => {
    if (!user) {
      return toast.error("Please login first");
    }

    if (isFavorite) return;

    setFavItem(itemId);

    user?.favoritesFolder?.length > 0
      ? setIsModalAddedOpen(true)
      : setIsModalOpen(true);
  };
  const videos1 = previewData?.photoVideo?.videos;
  const videos2 = previewData?.photoVideo?.videoLinks;

  const allVideos = [...videos1, ...videos2];
  return (
    <>
      {newFolder && (
        <NewFavoriteFolderModal
          isOpen={newFolder}
          onClose={() => setNewFolder(false)}
          itemId={favItem}
          type={"venueAndStay"}
        />
      )}
      {user?.favoritesFolder.length > 0 ? (
        <AddedFavoriteModal
          setNewFolder={setNewFolder}
          isOpen={isModalAddedOpen}
          itemId={favItem}
          data={user?.favoritesFolder}
          type={"venueAndStay"}
          onClose={() => setIsModalAddedOpen(false)}
        />
      ) : (
        <NewFavoriteFolderModal
          isOpen={isModalOpen}
          itemId={favItem}
          type={"venueAndStay"}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <HeroSection
        imagesData={previewData?.photoVideo?.images}
        basicDetails={previewData?.overview?.basicDetails}
        allVideo={allVideos}
      />
      {shareModal && (
        <SocialMediaModal
          isOpen={shareModal}
          onClose={() => setShareModal(false)}
          shareLink={window.location.href}
        />
      )}
      <div className="md:flex justify-between items-start  w-full  lg:px-[57px] px-6 ">
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-bold flex gap-4 items-center">
              {previewData?.overview?.basicDetails?.hotelName || "Undefined"}
              <span className="flex gap-2 items-center text-sm text-[#F2C200]">
                {previewData?.overview?.basicDetails?.hotelRating ? (
                  Array.from(
                    {
                      length: previewData?.overview?.basicDetails?.hotelRating,
                    },
                    (_, i) => <FaStar key={i} />
                  )
                ) : (
                  <FaStar className="text-gray-400" />
                )}
              </span>
            </h2>
          </div>

          <div className="text-gray-600 flex gap-2 items-center mb-4">
            <FaMapMarkerAlt className="inline mr-2" />
            <span className="font-medium underline decoration-2 underline-offset-2">
              {`${previewData?.overview?.locationDetails?.city || ""}, ${
                previewData?.overview?.locationDetails?.area || ""
              }` || "Location Missing"}
            </span>
            <a
              href="#map"
              className="flex ml-1 text-[#FE4747] font-semibold gap-2 items-center"
            >
              <FaRegMap />
              <span className="underline text-[#FE4747] underline-offset-2 decoration-2">
                Show on map
              </span>
            </a>
          </div>
        </div>
        <div className=" flex justify-between items-center gap-4">
          <div
            className="flex justify-center items-center gap-3 border border-black px-6 py-4 rounded-[8px] cursor-pointer"
            onClick={() => setShareModal(true)}
          >
            <CiShare2 className="text-black text-2xl" />
            Share
          </div>
          <div
            className="flex justify-center items-center gap-3 border border-black cursor-pointer px-6 py-4 rounded-[8px]"
            onClick={() => handleClickFavorite(previewData._id, 0)}
          >
            {isFavorite ? (
              <IoMdHeart className="text-primary text-2xl" />
            ) : (
              <IoMdHeartEmpty className="text-primary text-2xl" />
            )}
            Favourite
          </div>
        </div>
      </div>
      <div className="md:flex justify-between items-start  w-full  lg:px-[57px] px-6 mt-4">
        <div className="md:w-[68%] w-full">
          <OverviewSection data={previewData} numberOfVenues={numberOfVenues} />
          <OffersAndPackages data={previewData?.offerPackage} />
          {/* Suggested once hotel child cards */}
          {childVenues?.length > 1 && <HotelChildCards data={childVenues} />}

          <AboutThisHotel data={previewData} />
          <PricingSection data={previewData?.pricing} />
          <PlaceOffers data={previewData} />
          <EventTypes data={previewData} />
          <Map hotel={previewData} />
          {/* <RatingsAndReviews /> */}
        </div>
        <div className="md:w-[30%] w-full">
          <SpecifyEvent type={"hotel"} data={previewData} />
        </div>
      </div>
    </>
  );
}
