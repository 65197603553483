import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import EnquiryStatusGraphReport from "./EnquiryStatusGraphReport";
import EnquiryStatusTableReport from "./EnquiryStatusTableReport";
import { base_URL } from "../../config/config";
import { MdScatterPlot } from "react-icons/md";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
import Recipientpopup from "./Recipientpopup";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

function EnquiryStatusReport({
  setEventStatusReport,
  eventStatusReport,
  venueNames,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [email, setEmail] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [venueName, setVenueName] = useState("all");

  // Function to download the report as a PDF
  const downloadPDF = async () => {
    setLoadingDownload(true);
    // Capture the graph as an image
    const graph = document.getElementById("response-graph");
    const canvas = await html2canvas(graph);
    const imgData = canvas.toDataURL("image/png");

    // Initialize jsPDF instance
    const pdf = new jsPDF("l", "mm", "a4"); // Landscape orientation

    // Get the page width for the full width of the image
    const pageWidth = pdf.internal.pageSize.width;
    pdf.addImage(imgData, "PNG", 10, 10, pageWidth - 20, 100); // Adjust dimensions to fit A4 page

    // Add the table content to the PDF
    const tableContent = document.getElementById("response-table");

    const rows = tableContent.getElementsByTagName("tr");
    const tableData = [];

    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].getElementsByTagName("td");
      const rowData = [];

      // Only add rows that have non-empty cells
      for (let j = 0; j < cells.length; j++) {
        const cellContent = cells[j].textContent.trim();
        if (cellContent) {
          rowData.push(cellContent);
        }
      }
      // If the row has any data, push it to tableData
      if (rowData.length > 0) {
        tableData.push(rowData);
      }
    }

    // Add table to PDF
    pdf.autoTable({
      startY: 120, // Position the table below the graph
      head: [
        [
          "Users name",
          "Total enquiry received",
          "Enquiry Accepted by user",
          "Successful Enquiry",
          "Enquiry declined by client",
          "Enquiry declined by venue",
        ],
      ], // Column headers
      body: tableData, // Table data
    });

    // downlaod the PDF
    pdf.save("Enquiry_status_report.pdf");
    setLoadingDownload(false);
  };

  // Function to handle sharing
  const handleShare = async () => {
    setIsPopupOpen(true);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please provide an email address");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if email matches the regex pattern
    if (!emailPattern.test(email)) {
      toast.error("Please provide a valid email address");
      return;
    }
    setLoading(true);
    const graph = document.getElementById("response-graph");
    const canvas = await html2canvas(graph);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("l", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.width;
    pdf.addImage(imgData, "PNG", 10, 10, pageWidth - 20, 100);

    const tableContent = document.getElementById("response-table");
    const rows = tableContent.getElementsByTagName("tr");
    const tableData = [];

    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].getElementsByTagName("td");
      const rowData = [];
      for (let j = 0; j < cells.length; j++) {
        const cellContent = cells[j].textContent.trim();
        if (cellContent) {
          rowData.push(cellContent);
        }
      }
      if (rowData.length > 0) {
        tableData.push(rowData);
      }
    }

    pdf.autoTable({
      startY: 120,
      head: [
        [
          "Users name",
          "Total enquiry received",
          "Enquiry Accepted by user",
          "Successful Enquiry",
          "Enquiry declined by client",
          "Enquiry declined by venue",
        ],
      ],
      body: tableData,
    });

    const pdfData = pdf.output("datauristring");

    try {
      const response = await fetch(`${base_URL}/api/send-reports-pdf`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pdfData,
          email,
          subject: "Enquiry Status Report",
        }),
      });

      if (response.ok) {
        toast.success("PDF sent successfully!");
        setIsPopupOpen(false);
      } else {
        toast.error("Error sending PDF");
      }
    } catch (error) {
      console.error("Error sending PDF to backend:", error);
      toast.error("Error sending PDF");
    } finally {
      setLoading(false);
    }
  };

  // Safely handle the venue data regardless of structure (Fix screen breaking)
  const getVenueOptions = () => {
    if (!venueNames) return [];
    const venues = venueNames?.venues?.filter((item) => item) || [];
    const hotels = venueNames?.hotels?.filter((item) => item) || [];

    // Combine venues and hotels into a single array
    const combinedLocations = [...venues, ...hotels];
    return combinedLocations?.map((location, index) => (
      <option key={index} value={location}>
        {location}
      </option>
    )); // Remove any null values
  };
  return (
    <div className="flex flex-col">
      {isPopupOpen && (
        <Recipientpopup
          loading={loading}
          handleEmailSubmit={handleEmailSubmit}
          setIsPopupOpen={setIsPopupOpen}
          setEmail={setEmail}
          email={email}
        />
      )}
      {/* Top Section */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center">
        {/* Left Section */}
        <div className="flex flex-col gap-3">
          <h2 className="text-[12px] font-medium md:text-2xl md:font-semibold">
            Enquiry Status Report
          </h2>
          <div className="flex flex-wrap items-center gap-2 text-sm">
            <Link
              to="/vendor-dashboard"
              className="text-[#FE4747] text-[10px] md:text-[16px]"
            >
              Dashboard
            </Link>
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.813111 11.8536C0.617849 11.6583 0.617849 11.3417 0.813111 11.1464L5.79289 6.16667L0.813111 1.18689C0.617849 0.991625 0.617849 0.675041 0.813111 0.479779C1.00837 0.284517 1.32496 0.284517 1.52022 0.479779L6.85355 5.81311C7.04881 6.00838 7.04881 6.32496 6.85355 6.52022L1.52022 11.8536C1.32496 12.0488 1.00837 12.0488 0.813111 11.8536Z"
                fill="#222222"
              />
            </svg>
            <p
              onClick={() => setEventStatusReport(false)}
              className={`cursor-pointer ${
                eventStatusReport
                  ? "text-[#FE4747] text-[10px] md:text-[16px]"
                  : "text-black text-[10px] md:text-[16px]"
              }`}
            >
              Report List
            </p>
            {eventStatusReport && (
              <div className="flex gap-x-1 items-center">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.813111 11.8536C0.617849 11.6583 0.617849 11.3417 0.813111 11.1464L5.79289 6.16667L0.813111 1.18689C0.617849 0.991625 0.617849 0.675041 0.813111 0.479779C1.00837 0.284517 1.32496 0.284517 1.52022 0.479779L6.85355 5.81311C7.04881 6.00838 7.04881 6.32496 6.85355 6.52022L1.52022 11.8536C1.32496 12.0488 1.00837 12.0488 0.813111 11.8536Z"
                    fill="#222222"
                  />
                </svg>
                <p className="text-[10px] md:text-[16px]">Enquiry Status</p>
              </div>
            )}
          </div>
        </div>

        {/* Right Section */}
        <div className="flex flex-col md:flex-row items-center gap-4 mt-4 md:mt-0">
          {/* Venue Selector */}
          <select
            value={venueName}
            onChange={(e) => setVenueName(e.target.value)}
            className="border border-[#B0B0B0] text-[#222222] text-[14px] rounded-lg px-4 py-[10px] outline-none  w-[60%] md:w-[197px] appearance-none bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%226%22%20viewBox%3D%220%200%2010%206%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.174019%200.688233L0.17402%200.688232C0.281236%200.571268%200.42254%200.512531%200.599191%200.512531H9.40081C9.57742%200.512531%209.71871%200.571814%209.82594%200.689912C9.93375%200.808653%209.9875%200.946829%209.9875%201.10499C9.9875%201.11278%209.9843%201.12734%209.97655%201.14987C9.96895%201.17194%209.95748%201.20035%209.94204%201.2352C9.91131%201.30453%209.8652%201.39862%209.80364%201.51756L5.62731%205.69389C5.53216%205.78904%205.43416%205.85814%205.33338%205.90159C5.23256%205.94505%205.12149%205.96687%205%205.96687C4.87851%205.96687%204.76744%205.94505%204.66662%205.90159C4.56584%205.85814%204.46785%205.78903%204.37269%205.69385L0.194725%201.51592C0.138084%201.45928%200.0935546%201.39612%200.0610483%201.32643C0.0287014%201.25706%200.0125%201.18271%200.0125%201.10323C0.0125%200.9439%200.0662612%200.805766%200.174019%200.688233Z%22%20fill%3D%22%23222222%22%20stroke%3D%22%23222222%22%20stroke-width%3D%220.025%22%2F%3E%3C%2Fsvg%3E')] bg-no-repeat bg-[center_right_1rem]"
          >
            {/* Default option for all venues */}
            <option value="all">All venues</option>
            {/* Render dynamic venue names */}
            {getVenueOptions()}
          </select>

          {/* Date Range Selector */}
          <div className="w-[60%] md:w-[232px] flex flex-row items-center gap-2 md:gap-4 border bg-[#FFFFFF] border-[#B0B0B0] rounded-lg px-4 py-2 ">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="outline-none border-none w-full text-sm bg-[#FFFFFF] text-gray-700"
            />
            <span className="hidden md:inline mx-2">-</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="outline-none border-none w-full text-sm bg-[#FFFFFF] text-gray-700"
            />
          </div>

          {/* Download & Share Buttons */}
          <div className="flex items-center gap-4">
            {loadingDownload && (
              <MdScatterPlot className="text-[#7bc9c0] animate-spin" />
            )}
            {!loadingDownload && (
              <img
                onClick={downloadPDF}
                src="/download.svg"
                alt="Download"
                className="cursor-pointer active:opacity-55 w-6 h-6"
              />
            )}
            <img
              onClick={handleShare}
              src="/Share.svg"
              alt="Share"
              className="cursor-pointer active:opacity-55 w-6 h-6"
            />
          </div>
        </div>
      </div>

      {/* enquiry status Graph report */}
      <div id="response-graph">
        <EnquiryStatusGraphReport
          startDate={startDate}
          endDate={endDate}
          venueName={venueName}
        />
      </div>
      {/* enquiry status table report */}
      <div id="response-table">
        <EnquiryStatusTableReport
          startDate={startDate}
          endDate={endDate}
          venueName={venueName}
        />
      </div>
    </div>
  );
}

export default EnquiryStatusReport;
