import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ResponsiveAppBar from "../../components/ResponsiveAppBar";
import OutlinedLabelledTextField from "../../components/OutlinedLabelledTextfield";
import backgroundIcon from "../../assets/images/background_icon.svg";
import { useTheme } from "@mui/material/styles";
import { login } from "../../api/login.request";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchLoggedUser } from "../../api/loggedUser";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleSignUp,
  toggleLogin,
  toggleSocial,
  toggleAdditional,
  toggleCheckInbox,
  closeModals,
  toggleReset,
} from "../../store/features/modalSlice";
import Modal from "../../components/shared/Modal";
import LoginModal from "../../components/LoginModal";
import SignUpModal from "../../components/SignUpModal";
import AdditionalDetails from "../../components/AdditionalDetails";
import SocialModal from "../../components/SocialModal";
import CheckInboxModal from "../../components/CheckInboxModal";
import ResetPassword from "../../components/forget-password/ResetPassword";
import ResetWorkMail from "../../components/forget-password/ResetWorkMail";
import ResetMailSend from "../../components/forget-password/ResetMailSent";
import PasswordUpdated from "../../components/forget-password/PasswordUpdated";
import NavbarCustomer from "../../components/NavbarCustomer";

function Login() {
  const customer = JSON.parse(localStorage.getItem("authUser")) || null;
  const customerId = customer?._id || null;
  const [user, setUser] = useState();
  console.log(user);
  const dispatch = useDispatch();
  const location = useLocation();
  const [encryptedMail, setEncryptedMail] = useState("");
  const {
    showSignUp,
    showLogin,
    showReset,
    showSocial,
    resetWorkMail,
    showAdditional,
    showCheckInbox,
    resetMailSend,
    updatePassword,
  } = useSelector((state) => state.modal);

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchLoggedUser();
        setUser(response.vendor);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get("email");

    // If email exists in the query, dispatch toggleReset to open the reset password modal
    if (emailParam) {
      const decryptMail = window.atob(emailParam);
      setEncryptedMail(decryptMail);
      dispatch(toggleReset());
    }
  }, [location, dispatch]);

  const theme = useTheme();
  const [workMail, setWorkMail] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [error, setError] = useState(""); // New state for error message
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const loginData = {
        email: workMail.toLowerCase(),
        password: createPassword,
      };

      // Perform login request
      const response = await login(loginData);
      // Store tokens in localStorage
      const { accessToken, refreshToken, vendor } = response;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("vendorId", vendor?.id);
      localStorage.setItem("payment", vendor?.confirmPayment);
      // Set secure cookie for refresh token
      if (refreshToken) {
        setRefreshTokenCookie(refreshToken);
      }

      // Handle navigation based on vendor role and data
      handleNavigation(vendor);
    } catch (error) {
      handleError(error);
    }
  };

  // Helper function to set refresh token cookie
  const setRefreshTokenCookie = (refreshToken) => {
    const secureOptions = {
      sameSite: "strict",
      httpOnly: true,
      secure: true,
      path: "/",
    };

    document.cookie = `refreshToken=${refreshToken}; ${Object.entries(
      secureOptions
    )
      .map(([key, value]) => `${key}=${value}`)
      .join("; ")}`;
  };

  // Helper function to handle navigation based on vendor data
  const handleNavigation = (vendor) => {
    // Navigate to dashboard for invited members
    if (vendor?.invitedBy) {
      return navigate("/dashboard");
    }

    // Navigate to onboarding if payment is not confirmed
    if (vendor?.confirmPayment === false) {
      return navigate("/onboarding");
    }

    const isAdmin = vendor?.isAdmin === true;
    const hasFilledForms = Boolean(
      vendor?.RestaurantForm?.length || vendor?.venueForms?.length
    );
    const hasHotels = Boolean(vendor?.hotel?.length);

    // Navigate based on admin rights and data availability
    if (isAdmin && hasFilledForms && hasHotels) {
      return navigate("/myVenue");
    }

    // Navigate to myVenue if the vendor is allowed to access the dashboard
    if (vendor?.allowDashboard) {
      return navigate("/myVenue");
    }

    // Default navigation based on payment status
    return vendor?.payment ? navigate("/dashboard") : navigate("/onboarding");
  };

  // Helper function to handle errors
  const handleError = (error) => {
    const errorMessage =
      error.response?.data?.message || "An error occurred. Please try again.";
    setError(errorMessage);
    toast.error(error.message);
  };

  const isFormValid = () => {
    return workMail.trim() !== "" && createPassword.trim() !== "";
  };

  return (
    <>
      {customerId ? (
        <div className="z-[999]">
          <NavbarCustomer />
        </div>
      ) : (
        <div className="z-[9999]">
          <ResponsiveAppBar />
        </div>
      )}
      <div
        style={{
          backgroundImage: `url(${backgroundIcon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          minHeight: "75vh",
        }}
      >
        <Container>
          <Box
            sx={{
              mt: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                mt: { xs: 7, md: 10 },
                fontWeight: "bold",
                fontSize: { xs: "1.6rem" },
              }}
            >
              Log in as{" "}
              <span style={{ color: theme.palette.primary.main }}>
                Venue Owner
              </span>
            </Typography>
            <div
              onClick={() => {
                dispatch(toggleLogin());
              }}
              className="text-dark cursor-pointer mb-0 underline mx-auto"
            >
              Login as Customer
            </div>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ mb: { md: 4 }, color: "grey.text" }}
            >
              Welcome back! Log in and continue your journey.
            </Typography>
          </Box>
        </Container>
        <Container
          maxWidth="sm"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              mt: 7,
              width: {
                xs: "95%",
                md: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <form onSubmit={handleSubmit}>
              <OutlinedLabelledTextField
                id="workMail"
                label="Work Email"
                placeholder="user@workapp.com"
                type="email"
                value={workMail}
                onChange={(e) => setWorkMail(e.target.value)}
                error={!!error} // Set error state for workMail field
                helperText={error && error.includes("Vendor") ? error : ""} // Display error if it contains "Vendor"
              />

              <OutlinedLabelledTextField
                id="createPassword"
                label="Password"
                placeholder="Enter your password"
                type={showPassword ? "text" : "password"}
                value={createPassword}
                onChange={(e) => setCreatePassword(e.target.value)}
                error={!!error} // Set error state for createPassword field
                helperText={error && !error.includes("Vendor") ? error : ""} // Display non-"Vendor" error message\
                eye={true}
                showPassword={showPassword}
                togglefunction={togglePasswordVisibility}
              />

              <Box sx={{ mb: 2, textAlign: "right" }}>
                <Link
                  href="/reset"
                  color="primary"
                  sx={{ textDecoration: "none", fontWeight: "500" }}
                >
                  Forgot Password?
                </Link>
              </Box>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                sx={{
                  mb: { xs: 1, md: 3 },
                  borderRadius: "8px",
                  mt: 2,
                  backgroundColor: isFormValid()
                    ? "primary.main"
                    : "grey.border",
                  color: isFormValid() ? "white" : "text",
                }}
                disabled={!isFormValid()}
              >
                Log In
              </Button>
            </form>

            <Typography
              variant="body2"
              sx={{
                fontSize: "1rem",
                color: "grey.text",
                marginInline: "auto",
              }}
            >
              Don't have an account?{" "}
              <Link
                href="/register"
                color="primary"
                sx={{ textDecoration: "none", fontWeight: "500" }}
              >
                Register
              </Link>
            </Typography>
          </Box>
        </Container>
      </div>

      <Modal
        isOpen={showLogin}
        onClose={() => dispatch(closeModals())}
        label="Log in"
      >
        <LoginModal
          onSignUpClick={() => dispatch(toggleSignUp())}
          onSubmit={() => dispatch(closeModals())}
          onSocialClick={() => dispatch(toggleSocial())}
        />
      </Modal>
      <Modal
        isOpen={showSignUp}
        onClose={() => dispatch(closeModals())}
        label="Sign up"
      >
        <SignUpModal
          setShowSignUp={() => dispatch(toggleSignUp())}
          onLoginClick={() => dispatch(toggleLogin())}
          onSubmit={() => dispatch(toggleCheckInbox())}
          onSocialClick={() => dispatch(toggleSocial())}
        />
      </Modal>
      <Modal
        isOpen={showReset}
        onClose={() => dispatch(closeModals())}
        label="Reset Password"
      >
        <ResetPassword
          onSubmit={() => dispatch(closeModals())}
          mail={encryptedMail}
        />
      </Modal>
      <Modal
        isOpen={updatePassword}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password"
      >
        <PasswordUpdated
          onSubmit={() => dispatch(closeModals())}
          mail={encryptedMail}
        />
      </Modal>

      <Modal
        isOpen={resetMailSend}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password Email Sent"
      >
        <ResetMailSend onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={resetWorkMail}
        onClose={() => dispatch(closeModals())}
        label="Reset Password"
      >
        <ResetWorkMail onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={resetMailSend}
        onClose={() => dispatch(closeModals())}
        // label="Reset Password Email Sent"
      >
        <ResetMailSend onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={showSocial}
        onClose={() => dispatch(closeModals())}
        label=""
      >
        <SocialModal
          onLoginClick={() => dispatch(toggleLogin())}
          onAdditionalClick={() => dispatch(toggleAdditional())}
        />
      </Modal>

      <Modal
        isOpen={showAdditional}
        onClose={() => dispatch(closeModals())}
        label="Additional Details"
      >
        <AdditionalDetails onSubmit={() => dispatch(closeModals())} />
      </Modal>

      <Modal
        isOpen={showCheckInbox}
        onClose={() => dispatch(closeModals())}
        label=""
      >
        <CheckInboxModal />
      </Modal>
    </>
  );
}

export default Login;
