import React from "react";
import restaurantImage from "../../assets/images/restaurants_image.jpg";
import yachtImage from "../../assets/images/yacht_image.jpg";
import ballroomImage from "../../assets/images/ballroom_image.jpg";
import auditoriumImage from "../../assets/images/auditorium_image.jpg";
import museumImage from "../../assets/images/museum_image.jpg";
import Beach from "../../assets/images/Beach.jpg";
import Island from "../../assets/images/island.png";
import Link from "@mui/material/Link";

const ListVenu = () => {
  return (
    <div className="flex justify-center pt-20 pb-10">
      <div className="w-[90%] md:flex-row flex-col-reverse flex justify-between items-center gap-12">
        <div className="md:w-1/2 w-full flex flex-col md:items-start items-center xl:pr-16 pr-8 gap-4">
          <h1 className="xl:text-[#56px] lg:text-[56px] text-3xl md:text-start text-center font-semibold text-[#222222] leading-[78.4px]">
            List your venue and expand your event reach
          </h1>
          <p className="text-secondary md:text-start text-center text-lg mt-2 font-medium">
            We're the fastest growing platform for finding venues in UAE,
            connecting you with new clients
          </p>

          <Link
            href="/list-venues"
            className="flex md:justify-start justify-center w-full sm:my-10 my-6"
          >
            <button className="bg-[#FE4747] text-white rounded-lg w-[120px] py-2">
              Get Started
            </button>
          </Link>
        </div>

        <div className="md:w-1/2 w-full flex gap-3  justify-center">
          <div className="upward-slider-container">
            <div className="upward-slider">
              <img src={restaurantImage} alt="sliderImage 1" />
              <img src={yachtImage} alt="sliderImage 1" />
              <img src={ballroomImage} alt="sliderImage 1" />
              <img src={auditoriumImage} alt="sliderImage 1" />
              <img src={museumImage} alt="sliderImage 1" />
              <img src={restaurantImage} alt="sliderImage 1" />
              <img src={yachtImage} alt="sliderImage 1" />
              <img src={ballroomImage} alt="sliderImage 1" />
              <img src={auditoriumImage} alt="sliderImage 1" />
              <img src={museumImage} alt="sliderImage 1" />
              <img src={Beach} alt="sliderImage 1" />
              <img src={Island} alt="sliderImage 1" />
            </div>
          </div>

          <div className="downward-slider-container">
            <div className="downward-slider">
              <img src={yachtImage} alt="sliderImage 1" />
              <img src={ballroomImage} alt="sliderImage 1" />
              <img src={auditoriumImage} alt="sliderImage 1" />
              <img src={museumImage} alt="sliderImage 1" />
              <img src={Beach} alt="sliderImage 1" />
              <img src={Island} alt="sliderImage 1" />
              <img src={restaurantImage} alt="sliderImage 1" />
              <img src={yachtImage} alt="sliderImage 1" />
              <img src={ballroomImage} alt="sliderImage 1" />
              <img src={auditoriumImage} alt="sliderImage 1" />
              <img src={museumImage} alt="sliderImage 1" />
              <img src={restaurantImage} alt="sliderImage 1" />
            </div>
          </div>

          <div className="upward-slider-container">
            <div className="upward-slider">
              <img src={Island} alt="sliderImage 1" />
              <img src={Beach} alt="sliderImage 1" />
              <img src={museumImage} alt="sliderImage 1" />
              <img src={auditoriumImage} alt="sliderImage 1" />
              <img src={ballroomImage} alt="sliderImage 1" />
              <img src={yachtImage} alt="sliderImage 1" />
              <img src={restaurantImage} alt="sliderImage 1" />
              <img src={museumImage} alt="sliderImage 1" />
              <img src={auditoriumImage} alt="sliderImage 1" />
              <img src={ballroomImage} alt="sliderImage 1" />
              <img src={yachtImage} alt="sliderImage 1" />
              <img src={restaurantImage} alt="sliderImage 1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListVenu;
