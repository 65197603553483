import React, { useContext } from "react";
import SelectableTags from "../../shared/common/selectableTags";
import { VenueContext } from "../../../context/VenueContext";
import { useLocation } from "react-router-dom";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CloseableToolTip from "../CloseableToolTip";
import { Tooltip } from "@mui/material";

const categorizedVenues = {
  "Bars & Dining": [
    "Bar",
    "Beach club",
    "Cafes",
    "Dinner Show",
    "Fine dining restaurant",
    "Karaoke bar",
    "Nightclub",
    "Restaurant",
    "Restaurant/Bar",
    "Restaurant/Lounge & Bar",
    "Rooftops Restaurant",
    "Social clubs and lounges",
    "Supper club",
  ],
  "Wellness Venue": ["Therapy room", "Yoga Studio", "Spa"],
  "Outdoor Venues": [
    "Beach",
    "Desert",
    "Farm",
    "Helipad",
    "Lawn",
    "Park",
    "Boats & Yachts",
    "Rooftops",
    "Terrace",
    "Patio",
    "Garden",
    "Poolside",
    "Courtyard",
  ],
  "Adventure & Activity": [
    "Adventure venue",
    "Escape Rooms",
    "Golf courses",
    "Sports club",
    "Stadium",
    "Theme Park",
    "Venue with onsite activities",
    "Entertainment venue",
    "Sports venue",
    "Zoo",
    "Aquarium",
    "Limousine",
    "Party bus",
  ],
  Corporate: [
    "Conference center",
    // "Ballroom",
    "Co-working space",
    "Exhibitions Halls",
    // "Golf courses",
    "Meeting Center",
    "Meeting room",
    // "Team activity",
    "Training room",
    // "Exhibitions",
  ],
  "Arts & Creativity": [
    "Dance Studio",
    "Photo studio",
    "Recording studio",
    "Streaming studio",
    "TV film studio",
    "Art studio",
    "Art Gallery",
  ],
  "Religious, Cultural & Historical Venue": [
    "Church",
    // "Gallery",
    "Museum",
    "Temple",
  ],
  Halls: [
    "Ballroom",
    "Blank canvas",
    "Auditorium",
    "Cinema",
    "Concert hall",
    "Community center",
    "Warehouse",
    "Amphitheatre",
  ],
  "Kids Venues": ["Kids play area"],
};

// Simplified category names for display
const categoryNames = {
  "Bars & Dining": "Bars & Dining",
  "Kids Venues": "Kids Venues",
  "Wellness Venue": "Wellness Venues",
  "Outdoor Venues": "Outdoor Venues",
  "Adventure & Activity": "Adventure & Activities",
  Corporate: "Corporate Venues",
  "Arts & Creativity": "Arts & Creativity",
  "Religious, Cultural & Historical Venue": "Religious & Cultural",
  Halls: "Halls",
};

const VenueType = () => {
  const { formData, setFormData } = useContext(VenueContext);
  const path = useLocation();

  // Ensure venueType is initialized properly
  const { venueType } = formData;
  const errors = formData?.venueType?.errors || {}; // Ensure error handling if necessary

  // Toggle selected venue type
  const handleToggle = (type) => {
    const updatedTypes = venueType.venueType.includes(type)
      ? venueType.venueType.filter((t) => t !== type)
      : [...venueType.venueType, type];

    // Clear error when a venue type is selected or deselected
    setFormData((prevData) => ({
      ...prevData,
      venueType: {
        ...prevData.venueType,
        venueType: updatedTypes,
        errors: { ...prevData.venueType.errors, venueType: null }, // Clear the error
      },
    }));
  };

  // Change primary venue type
  const handlePrimaryTypeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      venueType: {
        ...prevData.venueType,
        venueRepresent: e.target.value,
        errors: { ...prevData.venueType.errors, venueType: null }, // Clear the error
      },
    }));
  };
  return (
    <div className="p-4">
      <div className="flex items-center gap-4 mb-4">
        <h1 className="text-3xl lg:text-2xl text-darkGray font-semibold  ">
          Venue Type <span className="text-red-500 ml-1">*</span>
        </h1>

        <Tooltip
          title="You can choose from a variety of venue types, but only one will be displayed in the search results. Please select the option that best represents your venue for optimal visibility ."
          arrow
        >
          <img src="/Info.svg" width={24} height={24} alt="info" />
        </Tooltip>
      </div>
      {/* <div className="flex items-center gap-4 mb-4">
        <h1 className="text-3xl lg:text-2xl text-darkGray font-semibold  ">
          Venue Type <span className="text-red-500 ml-1">*</span>
        </h1>
        <CloseableToolTip
          tooltipText={
            "You can choose from a variety of venue types, but only one will be displayed in the search results. Please select the option that best represents your venue for optimal visibility ."
          }
          bgColor="bg-black"
          textColor="text-white"
          borderColor="#4a4a4a"
        >
          <img src="/Info.svg" width={24} height={24} />

        </CloseableToolTip>
      </div> */}

      <div className="mb-4">
        {/* Display categories with simplified names */}
        {Object.keys(categorizedVenues).map((category, index) => {
          // Determine if the pathname contains 'venu-restaurant-Form'
          const isRestaurantForm = path.pathname?.includes(
            "venu-restaurant-Form"
          );

          if (!isRestaurantForm && index === 0) {
            return null; // Skip rendering the first category
          }
          // Render the category and tags
          return (
            <div key={category} className="mb-4">
              <h2 className="text-base font-medium text-darkGray mb-2">
                {categoryNames[category]}
              </h2>
              <SelectableTags
                label=""
                tags={categorizedVenues[category]}
                selectedTags={venueType.venueType}
                onTagClick={handleToggle}
              />
            </div>
          );
        })}
      </div>
      {errors.venueType && (
        <p className="text-red-500 py-1">{errors.venueType}</p>
      )}
      <div className="mb-4 text-secondary pt-4">
        <label className="block mb-2">
          Please select the one that best represents your venue to display on
          the search results: <span className="text-red-500 ml-1">*</span>
        </label>
        <select
          className="w-full p-2 border rounded"
          value={venueType.venueRepresent || ""}
          onChange={handlePrimaryTypeChange}
        >
          <option value="" disabled>
            Select a primary type
          </option>
          {venueType.venueType.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        {errors?.primaryType && (
          <p className="text-red-500 py-1">{errors?.primaryType}</p>
        )}
      </div>
    </div>
  );
};

export default VenueType;
