import React, { useContext } from "react";
import { HotelContext } from "../../context/HotelContext";

const defaultEventTypes = [
  "Company Retreats",
  "Training Programs",
  "Family Reunions",
  "Group Getaways",
  "Educational Camps or Workshops",
  "Corporate Off-sites",
  "Executive Meetings",
  "Destination Weddings",
  "Wellness Retreats",
  "Special Events or Celebrations",
];

const EventType = ({ data }) => {
  return (
    <div className="py-5 lg:w-3/4 w-full flex  flex-wrap justify-between items-center gap- border-t mx-auto">
      <h2 className="text-2xl font-bold mb-4 w-full lg:w-1/5">Event Type</h2>
      <div className="flex flex-wrap gap-4 w-full lg:w-[73%]">
        {data?.selectedEventTypes &&
          data?.selectedEventTypes.map((type, index) => (
            <span
              key={index}
              className="px-4 py-2 border rounded-full border-primary text-darkGray cursor-pointer hover:bg-gray-200"
            >
              {type}
            </span>
          ))}
      </div>
    </div>
  );
};

export default EventType;
