import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleResetWorkMail } from "../../store/features/modalSlice";
import { Tick } from "../../svgs";

const ResetMailSend = () => {
  const { resendEmail } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  return (
    <div className="w-full py-3 text-[#B0B0B0] flex flex-col items-center gap-3">
      <Tick />
      <h1 className="text-dark mt-5  text-center font-bold sm:text-3xl text-2xl">
        Reset Password Email Sent
      </h1>
      <p className="text-lg text-center">
        We just emailed you a reset link to <br />{" "}
        <span className=" text-black">{resendEmail}</span> <br />
        Click on the link to reset your password
      </p>
      <p className="text-center text-sm mt-3">
        Entered wrong email?{" "}
        <button
          onClick={() => dispatch(toggleResetWorkMail())}
          to="#"
          className="text-primary"
        >
          Go Back
        </button>
      </p>
    </div>
  );
};

export default ResetMailSend;
