import React, { useContext, useState } from "react";
import { HotelContext } from "../../context/HotelContext";
import * as FaIcons from "react-icons/fa";
import AirportShuttle from "../../assets/icons/accessibilty/AirportShuttle.png";
import Bus from "../../assets/icons/accessibilty/Bus.png";
import metro from "../../assets/icons/accessibilty/metro.png";
import Taxi from "../../assets/icons/accessibilty/Taxi.png";
import Parkingavailable from "../../assets/icons/accessibilty/Parkingavailable.png";
import ValetParking from "../../assets/icons/accessibilty/ValetParking.png";
const featuresData = [
  {
    category: "Room features",
    key: "roomFeatures",
    items: [
      {
        name: "Calls (Local)",
        icon: <img alt="" src="/room-features/call.svg" />,
        isDefault: true,
      },
      {
        name: "Calls (Toll-free)",
        icon: <img alt="" src="/room-features/toll-free.svg" />,
        isDefault: true,
      },
      {
        name: "Concierge Services",
        icon: <img alt="" src="/room-features/concierge.svg" />,
        isDefault: true,
      },
      {
        name: "Laundry Service",
        icon: <img alt="" src="/room-features/laundry.svg" />,
        isDefault: true,
      },
      {
        name: "Luggage Storage",
        icon: <img alt="" src="/room-features/luggage.svg" />,
        isDefault: true,
      },
      {
        name: "Internet Access",
        icon: <img alt="" src="/room-features/internet.svg" />,
        isDefault: true,
      },
      {
        name: "Room Service",
        icon: <img alt="" src="/room-features/food-tray.svg" />,
        isDefault: true,
      },
      {
        name: "Air conditioning",
        icon: <img alt="" src="/room-features/air-condition.svg" />,
        isDefault: true,
      },
      {
        name: "Kitchen",
        icon: <img alt="" src="/room-features/kitchen.svg" />,
        isDefault: true,
      },
    ],
  },
  {
    category: "Business features",
    key: "businessFeatures",
    items: [
      {
        name: "Business Centre",
        icon: <img alt="" src="/business-features/business.svg" />,
      },
      {
        name: "Video Conference",
        icon: <img alt="" src="/business-features/video-conference.svg" />,
      },
      {
        name: "VIP Services",
        icon: <img alt="" src="/business-features/vip.svg" />,
      },
      {
        name: "AV Equipment",
        icon: <img alt="" src="/business-features/av-equipements.svg" />,
      },
      {
        name: "Onsite AV Staff",
        icon: <img alt="" src="/business-features/support.svg" />,
      },
      {
        name: "High Speed Internet (100 Mbps)",
        icon: <img alt="" src="/business-features/internet.svg" />,
      },
    ],
  },
  {
    category: "Recreational features",
    key: "recreationalFeatures",
    items: [
      {
        name: "Health Club",
        icon: <img alt="" src="/features/medical.svg" />,
      },
      {
        name: "Indoor Pool",
        icon: <img alt="" src="/features/pool.svg" />,
      },
      {
        name: "Outdoor Pool",
        icon: <img alt="" src="/features/outdoor-pool.svg" />,
      },
      {
        name: "Spa",
        icon: <img alt="" src="/features/massage.svg" />,
      },
      {
        name: "Salon",
        icon: <img alt="" src="/features/scissors.svg" />,
      },
      {
        name: "Tennis Courts",
        icon: <img alt="" src="/features/sports.svg" />,
      },
      {
        name: "Whirlpool",
        icon: <img alt="" src="/features/whirlpool.svg" />,
      },
    ],
  },
];

const locationData = [
  {
    category: "Accessibility",
    key: "accessibilityFeatures",
    items: [
      {
        name: "Airport Shuttle",
        icon: (
          <img className="w-8 h-8" alt="Airport Shuttle" src={AirportShuttle} />
        ),
        key: "airportShuttle",
      },
      {
        name: "Bus",
        icon: <img className="w-8 h-8" alt="Bus" src={Bus} />,
        key: "bus",
      },
      {
        name: "Taxi",
        icon: <img className="w-8 h-8" alt="Taxi" src={Taxi} />,
        key: "taxi",
      },
      {
        name: "Metro",
        icon: <img className="w-8 h-8" alt="Metro" src={metro} />,
        key: "metro",
      },
    ],
  },
  {
    category: "Parking",
    key: "parkingFeatures",
    items: [
      {
        name: "Parking available",
        icon: <img className="w-8 h-8" alt="Metro" src={Parkingavailable} />,
        key: "parkingAvailable",
      },
      {
        name: "Valet Parking",
        icon: <img className="w-8 h-8" alt="Metro" src={ValetParking} />,
        key: "valetParking",
      },
    ],
  },
];

const renderIcon = (iconName) => {
  const IconComponent = FaIcons[iconName];
  return IconComponent ? <IconComponent size={32} /> : null;
};

const FeatureItem = ({ feature, available }) => {
  const IconComponent = feature.icon;
  return (
    <div
      className={`flex items-center gap-4 mb-2 ${
        available ? "block" : "hidden"
      }`}
    >
      {feature.icon}
      <span>{feature.name}</span>
    </div>
  );
};

const FeatureItem1 = ({ feature, available }) => {
  return (
    <div
      className={`flex items-center gap-4 mb-2 ${
        available ? "block" : "hidden"
      }`}
    >
      {feature.icon} {/* Directly render the icon */}
      <span>{feature.name}</span>
    </div>
  );
};

const FeaturesSection = () => {
  const { formData } = useContext(HotelContext);

  // Add custom features to the respective categories in featuresData
  Object.keys(formData?.amenities || {}).forEach((categoryKey) => {
    const category = featuresData.find((cat) => cat.key === categoryKey);
    if (category) {
      Object.keys(formData.amenities[categoryKey]).forEach((featureKey) => {
        if (!category.items.find((item) => item.name === featureKey)) {
          const featureValue = formData.amenities[categoryKey][featureKey];
          const IconComponent = FaIcons[featureValue];
          const icon = IconComponent ? (
            <IconComponent className="text-2xl mr-2" />
          ) : null;
          category.items.push({
            name: featureKey,
            icon: icon,
            isDefault: false,
          });
        }
      });
    }
  });
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleShowMore = (categoryKey) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryKey]: !prev[categoryKey], // Toggle the state of the specific category
    }));
  };
  return (
    <div className="py-5 lg:w-3/4 w-full border-t mx-auto">
      <div className="bg-white rounded-lg">
        <h2 className="text-2xl gap-5 font-bold mb-6">
          What this place offers
        </h2>

        {featuresData.map((category, index) => {
          // Filter features where the value is true or some non-falsey value
          const filteredItems = category.items.filter((feature) => {
            if (category.key === "roomFeatures") {
              return formData?.amenities?.roomFeatures?.[feature.name];
            } else if (category.key === "businessFeatures") {
              return formData?.amenities?.businessFeatures?.[feature.name];
            } else if (category.key === "recreationalFeatures") {
              return formData?.amenities?.recreationalFeatures?.[feature.name];
            }
            return false;
          });

          // If all features are false, don't render the category
          if (filteredItems.length === 0) return null;

          // Control the number of visible items based on expanded state
          const isExpanded = expandedCategories[category.key] || false;
          const visibleItems = isExpanded
            ? filteredItems
            : filteredItems.slice(0, 4);

          return (
            <div key={index} className="mb-6 flex gap-5 items-start flex-wrap">
              <h3 className="text-xl font-medium mb-4 lg:w-1/4 w-full">
                {category.category}
              </h3>
              <div className="grid grid-cols-2 lg:w-4/6 justify-between content-between gap-4">
                {visibleItems.map((feature, itemIndex) => {
                  let available = false;
                  if (category.key === "roomFeatures") {
                    available =
                      formData?.amenities?.roomFeatures?.[feature.name] ||
                      false;
                  } else if (category.key === "businessFeatures") {
                    available =
                      formData?.amenities?.businessFeatures?.[feature.name] ||
                      false;
                  } else if (category.key === "recreationalFeatures") {
                    available =
                      formData?.amenities?.recreationalFeatures?.[
                        feature.name
                      ] || false;
                  }
                  return (
                    <FeatureItem
                      key={itemIndex}
                      feature={feature}
                      available={available}
                    />
                  );
                })}
                {/* Show 'Show More/Show Less' only if more than 4 items */}
                {filteredItems.length > 4 && (
                  <button
                    onClick={() => toggleShowMore(category.key)}
                    className="text-red-500 hover:underline mt-2 text-left col-span-2"
                  >
                    {isExpanded ? "Show Less" : "Show More"}
                  </button>
                )}
              </div>
            </div>
          );
        })}

        {locationData.map((category, index) => (
          <div key={index} className="mb-6 flex gap-5 items-start flex-wrap">
            <h3 className="text-xl font-medium  mb-4 lg:w-1/4 w-full">
              {category.category}
            </h3>
            <div className="grid grid-cols-2 lg:w-4/6 gap-4">
              {category.items.map((feature, itemIndex) => {
                let available = false;
                if (category.key === "accessibilityFeatures") {
                  available =
                    formData?.overview?.locationDetails
                      ?.accessibilityFeatures?.[feature.key] || false;
                } else if (category.key === "parkingFeatures") {
                  available =
                    formData?.overview?.locationDetails?.[feature.key] || false;
                }
                return (
                  <FeatureItem1
                    key={itemIndex}
                    feature={feature}
                    available={available}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
