import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { RxCross2 } from "react-icons/rx";
import { getCityCoordinates } from "../map/getCityCoordinates";

const SingleHotelMap = ({ hotel }) => {
  const [coordinates, setCoordinates] = useState({
    lat: hotel?.overview?.locationDetails?.lat || 0,
    lng: hotel?.overview?.locationDetails?.lng || 0,
  });

  const [selectedHotel, setSelectedHotel] = useState(null); // Holds selected hotel for hover details

  useEffect(() => {
    const cityCoordinates = getCityCoordinates(
      hotel?.overview?.locationDetails?.city
    );
    if (cityCoordinates) {
      setCoordinates(cityCoordinates);
    }
  }, [hotel]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC4HfwthUtqr2oD0vq_25Zd9p0fsjOSqLg", // replace with your actual API key
  });

  const handleMarkerHover = () => {
    setSelectedHotel(hotel); // Show hotel details on hover
  };

  const handleMouseOut = () => {
    setSelectedHotel(null); // Hide hotel details when mouse leaves the marker
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className="md:text-2xl text-[20px] md:font-bold font-semibold mb-4">
        Location
      </h2>
      <div className="md:h-[75vh] h-[50vh] w-full relative">
        <GoogleMap
          center={coordinates}
          zoom={14}
          mapContainerStyle={{ height: "100%", width: "100%" }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          {/* Marker */}
          <Marker
            position={coordinates}
            onMouseOver={handleMarkerHover}
            onMouseOut={handleMouseOut}
          />

          {/* Show Venue Details on Hover */}
        </GoogleMap>
        {selectedHotel && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 w-auto p-1 bg-white shadow-lg rounded-lg z-10">
            <div className="relative p-4">
              <button
                onClick={() => setSelectedHotel(null)}
                className="absolute top-2 right-2  rounded-full shadow-md"
              >
                <RxCross2 size={18} />
              </button>

              <h1 className="text-lg font-semibold">
                {selectedHotel?.overview?.basicDetails?.hotelName}
              </h1>
              <p className="text-gray-600">
                {selectedHotel?.overview?.locationDetails?.city},{" "}
                {selectedHotel?.overview?.locationDetails?.area}
              </p>

              {/* Price Information */}
              <div className="text-red-600 font-semibold md:text-lg text-sm">
                AED {selectedHotel?.pricing?.basePrice || "N/A"}
              </div>
              <p className="text-gray-600 text-sm">Min. spend</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleHotelMap;
