"use client";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import chair from "../../../assets2/images/Chair.svg";
import houses from "../../../assets2/images/houses.svg";
import standing from "../../../assets2/images/standing.svg";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import NewFavoriteFolderModal from "../../../components/myFav/NewFavoriteFolderModal";
import img1 from "../../../assets2/images/archdentity.jpg";
import img2 from "../../../assets2/images/playhouse.jpg";
import img3 from "../../../assets2/images/laperli.jpg";
import img4 from "../../../assets2/images/arena.jpg";

const provideData = [
  {
    images: [{ img1: img1 }, { img1: img1 }, { img1: img1 }],
    name: "Archidentity, The Palm",
    loc: "Dubai, Downtown",
    sitting: "200",
    standing: "200",
    feature: "Photoshoot . Indoor & Outdoor",
    perGuest: "105",
    minSpend: "2000",
  },
  {
    images: [{ img1: img2 }, { img1: img2 }, { img1: img2 }],
    name: "Playhouse, Grand Hyatt",
    loc: "Dubai, Downtown",
    sitting: "200",
    standing: "200",
    feature: "Photoshoot . Indoor & Outdoor",
    perGuest: "105",
    minSpend: "2000",
  },
  {
    images: [{ img1: img3 }, { img1: img3 }, { img1: img3 }],
    name: "La Perle, The Luxe",
    loc: "Dubai, Downtown",
    sitting: "200",
    standing: "200",
    feature: "Photoshoot . Indoor & Outdoor",
    perGuest: "105",
    minSpend: "2000",
  },
  {
    images: [{ img1: img4 }, { img1: img4 }, { img1: img4 }],
    name: "Arena Hall, The Royals",
    loc: "Dubai, Downtown",
    sitting: "200",
    standing: "200",
    feature: "Photoshoot . Indoor & Outdoor",
    perGuest: "105",
    minSpend: "2000",
  },
];

const DetailComponent = ({ data }) => {
  console.log("searchData", Array.isArray(data), typeof data, data);
  const [favorites, setFavorites] = useState({});
  const [isMobileView, setIsMobileView] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const toggleFavorite = (itemIndex, imageIndex) => {
    setIsShowModal(true);
    setFavorites((prevState) => ({
      ...prevState,
      [itemIndex]: {
        ...prevState[itemIndex],
        [imageIndex]: !prevState[itemIndex]?.[imageIndex],
      },
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024); // lg breakpoint in Tailwind
    };

    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="absolute right-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowRight className="text-[#000]" />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="absolute left-2 h-fit bg-[#FFFFFF80] rounded-sm p-1 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <SlArrowLeft className="text-[#000] text-xl" />
    </div>
  );

  const imageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const mainCarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1, // Show 1 full slide and a bit of the next
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // lg
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // md
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // sm
        settings: {
          slidesToShow: 1, // Adjusted to show 1 full and a bit of the next
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {isShowModal && <NewFavoriteFolderModal />}
      <div
        className={`w-full h-auto  ${
          isMobileView ? "" : "grid grid-cols-4 gap-6"
        }`}
      >
        {isMobileView ? (
          <Slider {...mainCarouselSettings}>
            {data &&
              Array.isArray(data) &&
              data.map((item, itemIndex) => (
                <div key={itemIndex} className="p-2 ml-0 md:ml-0">
                  <div className="bg-white rounded-lg shadow-lg w-[70vw]">
                    {item.images.length > 1 ? (
                      <Slider {...imageSettings}>
                        {item?.images?.map((e, imageIndex) => (
                          <div key={imageIndex} className="w-full relative">
                            <img
                              src={item.images[imageIndex]}
                              alt=""
                              className="rounded-lg w-full h-[260px]"
                            />
                            <div
                              className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                              onClick={() =>
                                toggleFavorite(itemIndex, imageIndex)
                              }
                            >
                              {favorites[itemIndex]?.[imageIndex] ? (
                                <IoMdHeart className="text-primary" />
                              ) : (
                                <IoMdHeartEmpty className="text-primary" />
                              )}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ) : (
                      <div className="w-full relative">
                        <img
                          src={item.images[0]}
                          alt=""
                          className="rounded-lg w-full h-[260px]"
                        />
                        <div
                          className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                          onClick={() => toggleFavorite(itemIndex, 0)}
                        >
                          <IoMdHeart className="text-primary" />
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col items-start gap-2 p-3">
                      <h1 className="text-darkGray text-[16] font-medium">
                        {item.name}
                      </h1>
                      <p className="text-secondary text-[14px]">{item.loc}</p>
                      <div className="flex items-center w-full justify-between gap-4">
                        <div className="flex items-center gap-1">
                          <img src={chair} alt="" />
                          <p className="text-secondary text-[12px]">
                            {item.sitting}
                          </p>
                        </div>
                        <div className="flex items-center gap-1">
                          <img src={standing} alt="" />
                          <p className="text-secondary text-[12px]">
                            {item.standing}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center gap-1">
                          <img src={houses} alt="" />
                          <p className="text-secondary text-[12px]">
                            {item.activity} . {item.setting}
                          </p>
                        </div>
                      </div>
                      <div className="flex  justify-between items-start w-full">
                        {item.minSpendPricing &&
                        item.venueRental &&
                        !Number.isNaN(item.minSpendPricing) &&
                        !Number.isNaN(item.venueRental) ? (
                          <>
                            <div className="">
                              <p className="text-primary font-medium">
                                AED {item.minSpendPricing}
                              </p>
                              {/* Show one "Min. spend" text */}
                              <p className="text-sm">Min. spend</p>
                            </div>
                            {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
                          </>
                        ) : (
                          <>
                            {/* Case 2: Both minSpendPerPerson and venueRental available */}
                            {item.minSpendPerPerson &&
                            item.venueRental &&
                            !Number.isNaN(item.minSpendPerPerson) &&
                            !Number.isNaN(item.venueRental) ? (
                              <>
                                <div className="">
                                  <p className="text-primary font-medium">
                                    AED {item.venueRental}
                                  </p>
                                  <p className="text-sm">Min. spend</p>
                                </div>
                                <div className="">
                                  <p className="text-primary font-medium">
                                    AED {item.minSpendPerPerson}
                                  </p>
                                  <p className="text-sm">Per guest</p>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* Render each one individually if other cases don't match */}
                                {item.minSpendPerPerson &&
                                  !Number.isNaN(item.minSpendPerPerson) && (
                                    <div className="">
                                      <p className="text-primary font-medium">
                                        AED {item.minSpendPerPerson}
                                      </p>
                                      <p className="text-sm">Per guest</p>
                                    </div>
                                  )}
                                {item.minSpendPricing &&
                                  !Number.isNaN(item.minSpendPricing) && (
                                    <div className="">
                                      <p className="text-primary font-medium">
                                        AED {item.minSpendPricing}
                                      </p>
                                      <p className="text-sm">Min. spend</p>
                                    </div>
                                  )}
                                {item.venueRental &&
                                  !Number.isNaN(item.venueRental) && (
                                    <div className="">
                                      <p className="text-primary font-medium">
                                        AED {item.venueRental}
                                      </p>
                                      <p className="text-sm">Min. spend</p>
                                    </div>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        ) : (
          data &&
          Array.isArray(data) &&
          data.map((item, itemIndex) => (
            <div key={itemIndex} className="bg-white rounded-lg shadow-lg mb-3">
              {item.images.length > 1 ? (
                <Slider {...imageSettings}>
                  {item?.images?.map((e, imageIndex) => (
                    <div key={imageIndex} className="w-full relative">
                      <img
                        src={item.images[imageIndex]}
                        alt=""
                        className="rounded-lg w-full h-[260px]"
                      />
                      <div
                        className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                        onClick={() => toggleFavorite(itemIndex, imageIndex)}
                      >
                        {favorites[itemIndex]?.[imageIndex] ? (
                          <IoMdHeart className="text-primary" />
                        ) : (
                          <IoMdHeartEmpty className="text-primary" />
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="w-full relative">
                  <img
                    src={item.images[0]}
                    alt=""
                    className="rounded-lg w-full h-[260px]"
                  />
                  <div
                    className="bg-white absolute top-3 right-5 p-1.5 rounded-full cursor-pointer"
                    onClick={() => toggleFavorite(itemIndex, 0)}
                  >
                    <IoMdHeart className="text-primary" />
                  </div>
                </div>
              )}
              <div className="flex flex-col items-start gap-2 p-3">
                <h1 className="text-darkGray text-[17px] font-medium">
                  {item.name}
                </h1>
                <p className="text-secondary text-[14px]">{item.loc}</p>
                <div className="flex w-full ">
                  <div className="flex items-center gap-1">
                    <img src={chair} alt="" />
                    <p className="text-secondary text-[14px]">{item.sitting}</p>
                  </div>
                  <div className="flex items-center gap-1 ml-2">
                    <img src={standing} alt="" />
                    <p className="text-secondary text-[14px]">
                      {item.standing}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center gap-1">
                    <img src={houses} alt="" />
                    <p className="text-secondary text-[14px]">
                      {item.activity} . {item.setting}
                    </p>
                  </div>
                </div>
                <div className="flex  justify-between items-start w-full">
                  {item.minSpendPricing &&
                  item.venueRental &&
                  !Number.isNaN(item.minSpendPricing) &&
                  !Number.isNaN(item.venueRental) ? (
                    <>
                      <div className="">
                        <p className="text-primary font-medium">
                          AED {item.minSpendPricing}
                        </p>
                        {/* Show one "Min. spend" text */}
                        <p className="text-sm">Min. spend</p>
                      </div>
                      {/* <div className="">
                  <p className="text-primary font-medium">AED {venueRental}</p>
                </div> */}
                    </>
                  ) : (
                    <>
                      {/* Case 2: Both minSpendPerPerson and venueRental available */}
                      {item.minSpendPerPerson &&
                      item.venueRental &&
                      !Number.isNaN(item.minSpendPerPerson) &&
                      !Number.isNaN(item.venueRental) ? (
                        <>
                          <div className="">
                            <p className="text-primary font-medium">
                              AED {item.venueRental}
                            </p>
                            <p className="text-sm">Min. spend</p>
                          </div>
                          <div className="">
                            <p className="text-primary font-medium">
                              AED {item.minSpendPerPerson}
                            </p>
                            <p className="text-sm">Per guest</p>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Render each one individually if other cases don't match */}
                          {item.minSpendPerPerson &&
                            !Number.isNaN(item.minSpendPerPerson) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {item.minSpendPerPerson}
                                </p>
                                <p className="text-sm">Per guest</p>
                              </div>
                            )}
                          {item.minSpendPricing &&
                            !Number.isNaN(item.minSpendPricing) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {item.minSpendPricing}
                                </p>
                                <p className="text-sm">Min. spend</p>
                              </div>
                            )}
                          {item.venueRental &&
                            !Number.isNaN(item.venueRental) && (
                              <div className="">
                                <p className="text-primary font-medium">
                                  AED {item.venueRental}
                                </p>
                                <p className="text-sm">Min. spend</p>
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default DetailComponent;
