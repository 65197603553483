import React, { useEffect, useState } from "react";
import CustomInput from "../shared/CustomInput";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { base_URL } from "../../config/config";
import { toast } from "react-toastify";
import { setUser } from "../../store/features/authSlice";
import { getUser } from "../../lib/apis";
const NewFavoriteFolderModal = ({ isOpen, onClose, itemId, type, refetch }) => {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const userId = user?._id;
  const dispatch = useDispatch();
  const [wordsLength, setWordsLength] = useState(0);

  const handleCreate = async () => {
    if (name === "") return toast.error("Please enter folder name!");
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${base_URL}/api/favorite/add-favorite`,
        {
          userId,
          name,
          itemId,
          type,
        }
      );

      if (response.data.success) {
        if (refetch) refetch();
        const user = await getUser(userId);
        dispatch(setUser(user));
        toast.success("successfully added to your favorites");
        onClose();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (refetch) refetch();
      console.log(error);
    }
  };
  useEffect(() => {
    setWordsLength(name.length);
  }, [wordsLength, name]);

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg py-4 px-6 w-[464px]  space-y-3 h-72 flex flex-col justify-center items-center  ">
        <div className="flex justify-center items-center relative w-full ">
          <p className="text-center text-[24px] mb-4">
            Create my favorite list
          </p>
          <RxCross2
            size={22}
            className="absolute top-1 right-0 cursor-pointer text-dark"
            onClick={onClose}
          />
        </div>

        <div className=" flex flex-col justify-center items-center gap-3 w-full">
          <div className="w-full">
            <CustomInput
              label={"Name"}
              type={"text"}
              placeholder={"Enter name"}
              value={name}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 30 || newValue.length < name.length) {
                  setName(newValue);
                }
              }}
            />
            <p className="text-[14px] mt-2 text-gray">
              {wordsLength}/30 Characters
            </p>
          </div>

          <button
            onClick={handleCreate}
            className="bg-primary mt-5 text-white rounded-lg w-[160px] py-2 text-sm"
          >
            {isLoading ? "Creating..." : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewFavoriteFolderModal;
