import React, { useState, useEffect, useRef, useCallback } from "react";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const SelectAndSearch = ({ options, onSelect, selectedOption }) => {
  const [query, setQuery] = useState(""); // Search query
  const [filteredOptions, setFilteredOptions] = useState(options); // Filtered options
  const [isOpen, setIsOpen] = useState(false); // Dropdown open state
  const [isSelected, setIsSelected] = useState(false); // Whether an option is selected
  const dropdownRef = useRef(null);

  const searchOptions = useCallback(
    debounce((query) => {
      const filtered = options.filter((option) =>
        option?.label?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredOptions(filtered);
    }, 300),
    [options]
  );

  useEffect(() => {
    if (query === "") {
      setFilteredOptions(options); // Reset to all options if query is empty
    } else {
      searchOptions(query);
    }
  }, [query]);

  const handleSelect = (option) => {
    onSelect(option); // Call the onSelect function passed via props
    setQuery(option.label); // Update input value with the selected option name
    setIsSelected(true); // Mark as selected
    setIsOpen(false); // Close dropdown
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full mx-auto mt-2" ref={dropdownRef}>
      <div className="flex items-center justify-between  border-none rounded-md w-full">
        <input
          type="text"
          value={query || selectedOption}
          onChange={(e) => {
            setQuery(e.target.value);
            setIsSelected(false);
          }} // Update query on input change
          onFocus={() => setIsOpen(true)} // Open dropdown when focused
          placeholder="Start typing..."
          className="w-full border-none font-[400] text-[16px] text-[#B0B0B0] outline-none rounded-md"
        />
        <span
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : ""
          } mr-0`}
          onClick={() => setIsOpen(!isOpen)} // Toggle dropdown on click
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-[#FE4747] font-normal cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </div>

      {isOpen && (
        <ul className="absolute md:z-5 z-[999] w-full top-10 -left-5  bg-white border-none rounded-md shadow-lg max-h-80 overflow-y-auto custom-scrollbar mt-2">
          {(isSelected ? options : filteredOptions).map((option) => (
            <li
              key={option.value}
              onClick={() => handleSelect(option)}
              className="px-4 py-2 cursor-pointer text-black text-[16px] font-[400] hover:bg-blue-100"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectAndSearch;
