import { Textarea } from "@headlessui/react";
import React, { useState } from "react";
import toast from "react-hot-toast";

export default function JustificationModal({
  isVisible,
  onClose,
  onSubmitJustification,
}) {
  const [text, setText] = useState("");

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleContinue = () => {
    // if (text.trim()) {
      onSubmitJustification(text);
    // } 
    // else {
    //   toast.error("Please enter your justification!");
    // }
  };
  if (!isVisible) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg md:w-[600px] ">
        <div className="flex justify-end items-end">
          <img
            onClick={onClose}
            src="/cross.svg"
            alt=""
            className=" cursor-pointer active:opacity-55"
          />
        </div>
        <h2 className="text-[16px] md:text-[22px] lg:text-[32px] text-center font-bold mb-4">
          Specify the justification for the <br />
          alteration
        </h2>
        <div className="md:px-[55px] mt-10 mb-4 text-[10px] lg:text-[16px] flex flex-col gap-y-3">
          <p className=" text-[#5E5E5E] font-normal text-[14px]">
            Reason for modification
          </p>
          <Textarea
            placeholder="Enter your input"
            className="p-3 border border-gray-400 rounded-lg max-h-[72px] active:outline-none active:border-none"
            value={text}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-center items-center w-full">
          <button
            onClick={handleContinue}
            className="bg-[#FF3939] mt-7 md:mt-[40px] w-[90%] mx-auto active:opacity-55 md:w-[400px] text-white px-4 py-2 rounded-xl"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}
