// UserContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { fetchLoggedUser } from "../api/loggedUser";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showVenues, setShowVenues] = useState(false);
  const [editBilling, setEditBilling] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [tileIs, setTileIs] = useState(null);
  const [hotelVenue, setHotelVenue] = useState(null);
  //   States for Rendering different Views for Multiple Flow
  const [restaurantsView, setRestaurantsView] = useState(false);
  const [restaurantsVenueView, setRestaurantVenueView] = useState(false);
  const [restaurantsOtherView, setRestaurantotherView] = useState(false);
  const [venueView, setVenueView] = useState(false);
  const [kidsVenueView, setKidsVenueView] = useState(false);
  // setting alco and non alco fields on back during onbaording
  const [withAlcoholNumber, setWithAlcoholNumber] = useState(0);
  const [withoutAlcoholNumber, setWithoutAlcoholNumber] = useState(0);
  // setting yes/no logic here of venues
  const [items, setItems] = useState([]);
  // setting hotel group name field during onbaording
  const [hotelGroupName, setHotelGroupName] = useState("");
  // setting "Name of Entity/Establishment/Hotel" during onbaording
  const [entityName, setEntityName] = useState("");
  // enquiry details of selected enquiry
  const [selectedEnquiryDetails, setSelectedEnquiryDetails] = useState(null);
  // dashboard login user
  const [dashboardUser, setDashboardUser] = useState(null);
  // funnel reports
  const [funnelReport, setFunnelReport] = useState(false);
  // country reports
  const [countryReport, setCountryReport] = useState(false);
  // Event Type report
  const [eventTypeReport, setEventTypeReport] = useState(false);
  // Event Status report
  const [eventStatusReport, setEventStatusReport] = useState(false);
  // Total Enquiry Report
  const [totalEnquiryReport, setTotalEnquiryReport] = useState(false);
  // Average Budget report
  const [averageBudgetReport, setAverageBudgetReport] = useState(false);
  // Average Response Rate
  const [averageResponseReport, setAverageResponseReport] = useState(false);
  // Saved as favourites Report
  const [saveAsFav, setSaveAsFav] = useState(false);
  // Average Time Spend Report
  const [avgTimeSpendReport, setAvgTimeSpendReport] = useState(false);
  // Main reports active
  const [activeReports, setActiveReports] = useState(false);
  // ENquiry Details active
  const [activeEnquiryDetails, setActiveEnquiryDetails] = useState(false);
  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.warn("Access token not found in localStorage");
        setLoading(false);
        return;
      }

      try {
        const response = await fetchLoggedUser();
        setUser(response.vendor);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const value = {
    user,
    loading,
    error,
    editBilling,
    setEditBilling,
    showVenues,
    setShowVenues,
    editItem,
    setEditItem,
    hotelVenue,
    setHotelVenue,
    tileIs,
    setTileIs,
    restaurantsView,
    setRestaurantsView,
    restaurantsVenueView,
    setRestaurantVenueView,
    restaurantsOtherView,
    setRestaurantotherView,
    venueView,
    setVenueView,
    kidsVenueView,
    setKidsVenueView,
    items,
    setItems,
    withAlcoholNumber,
    setWithAlcoholNumber,
    withoutAlcoholNumber,
    setWithoutAlcoholNumber,
    hotelGroupName,
    setHotelGroupName,
    entityName,
    setEntityName,
    selectedEnquiryDetails,
    setSelectedEnquiryDetails,
    dashboardUser,
    setDashboardUser,
    funnelReport,
    setFunnelReport,
    countryReport,
    setCountryReport,
    eventTypeReport,
    setEventTypeReport,
    eventStatusReport,
    setEventStatusReport,
    totalEnquiryReport,
    setTotalEnquiryReport,
    averageBudgetReport,
    setAverageBudgetReport,
    averageResponseReport,
    setAverageResponseReport,
    saveAsFav,
    setSaveAsFav,
    avgTimeSpendReport,
    setAvgTimeSpendReport,
    activeReports,
    setActiveReports,
    activeEnquiryDetails,
    setActiveEnquiryDetails,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
