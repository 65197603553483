import axios from "axios";
import React, { useState } from "react";
import { base_URL } from "../../config/config";
import { useQuery } from "@tanstack/react-query";

function TotalEnquiryTableReport({ startDate, endDate, venueName }) {
  // Retrieve vendor ID
  const invitedBy = localStorage.getItem("invitedBy");
  const vendorId = invitedBy || localStorage.getItem("vendorId");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Fetch data using React Query
  const { data, isLoading } = useQuery({
    queryKey: [
      "totalEnquiryAnalysisTable",
      currentPage,
      pageSize,
      startDate,
      endDate,
      venueName,
    ],
    queryFn: async () => {
      const response = await axios.get(
        `${base_URL}/api/device-category-enquiries`,
        {
          params: {
            vendorId: vendorId,
            page: currentPage,
            limit: pageSize,
            startDate: startDate
              ? startDate?.toLocaleDateString("en-CA")
              : null, // Converts to YYYY-MM-DD
            endDate: endDate ? endDate?.toLocaleDateString("en-CA") : null,
            venueName,
          },
        }
      );
      return response?.data;
    },
  });

  if (isLoading)
    return (
      <div className="h-[40vh] mt-4 rounded-lg animate-pulse bg-black/20"></div>
    );

  const statsByDeviceCategory = data?.statsByDeviceCategory || {};
  const pagination = data?.pagination || {};

  // Pagination Handlers
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(Number(newPageSize));
    setCurrentPage(1); // Reset to first page when changing page size
  };
  return (
    <div className="rounded-xl inline">
      <div className="overflow-x-auto rounded-xl bg-white mt-4">
        <table className="w-full text-left">
          <thead className="bg-[#F4F4F4]/70">
            <tr>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Device Category
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Total Enquiries Received
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry Accepted by Venue
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Successful Enquiries
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry Declined by Venue
              </th>
              <th className="px-6 py-4 text-[12px] md:text-[15px] font-medium text-[#222222]">
                Enquiry Declined by Client
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {Object.entries(statsByDeviceCategory).map(([category, stats]) => (
              <tr key={category} className="hover:bg-gray-50">
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {category}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {stats.totalEnquiries}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {stats.acceptedByVendor}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {stats.successful}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {stats.declinedByVendor}
                </td>
                <td className="px-6 py-4 font-normal text-[#222222] text-[10px] md:text-[13px]">
                  {stats.declinedByClient}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination (optional) */}
        <div className="flex items-center justify-end gap-x-3 px-6 py-3 border-t">
          <div className="flex items-center gap-2">
            <span className="text-sm text-[#222222]">Display:</span>
            <select
              className="px-2 py-1 border rounded-lg"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
            >
              Previous
            </button>
            <span className="text-sm text-[#222222]">
              Page {pagination?.currentPage} of {pagination?.totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === pagination?.totalPages}
              className="px-3 py-1 text-sm border rounded-md hover:bg-gray-50"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalEnquiryTableReport;
