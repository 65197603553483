/* eslint-disable no-undef */

import axios from "axios";
import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import NavbarCustomer from "../../../components/NavbarCustomer";
import Hero from "./components/Hero";
import ExploreEvents from "./components/ExploreEvents";
import Listing from "../../home/listing/Listing";
import bgImg from "../../../assets/explore-city.png";
import { base_URL } from "../../../config/config";
import { RiArrowRightSLine } from "react-icons/ri";

const addFilters = (name, setFilters) => {
  if (name === "Corporate Events") {
    setFilters({
      eventTypes: {
        "Conference/Seminars": ["Exhibitions"],
        "Corporate Events": [
          "Team Building",
          "Staff Party",
          "PR events",
          "Corporate Retreat",
          "Corporate Gala dinner",
        ],
      },
    });
  } else if (name === "Weddings") {
    setFilters({
      eventTypes: {
        Weddings: ["Wedding Reception", "Outdoor Wedding", "Wedding"],
        Dinning: ["Engagement"],
      },
    });
  } else if (name === "Meetings") {
    setFilters({
      eventTypes: {
        "Conference/Seminars": [
          "Board Meetings",
          "Conference",
          "Training",
          "Networking",
        ],
      },
    });
  } else if (name === "Birthday Parties") {
    setFilters({
      eventTypes: {
        "Birthday Parties": [
          "16th Birthday Party",
          "21st Birthday Party",
          "50th Birthday Party",
        ],
        "Teen/Children": ["Kids Party", "Teen Party"],
      },
    });
  } else if (name === "Proposals") {
    setFilters({
      eventTypes: {
        "Special Big Events": ["Proposals"],
      },
    });
  } else if (name === "Kids Activities") {
    setFilters({
      eventTypes: {
        "Teen/Children": ["Kids Party", "Teen Party"],
      },
    });
  }
};

const getEventTypeCount = async () => {
  try {
    const response = await axios.get(
      `${base_URL}/api/venues/get-count-by-event`
    );
    // console.log(response.data.count);
    return response.data.count;
  } catch (error) {
    console.error(error);
  }
};

const data = {
  "Corporate Events": [
    "Team Building",
    "Staff Party",
    "PR events",
    "Corporate Retreat",
    "Corporate Gala dinner",
    "Exhibitions",
  ],
  Weddings: ["Wedding Reception", "Outdoor Wedding", "Wedding", "Engagement"],
  Meetings: ["Board Meetings", "Conference", "Training", "Networking"],
  "Birthday Parties": [
    "16th Birthday Party",
    "21st Birthday Party",
    "50th Birthday Party",
    "Kids Party",
    "Teen Party",
  ],
  Proposals: ["Proposals"],
  "Kids Activities": ["Kids Party"],
};

const Event = ({ filters, setFilters }) => {
  const { eventType } = useParams();
  console.log(eventType);

  const { data: count } = useQuery({
    queryKey: ["eventTypeCount"],
    queryFn: () => getEventTypeCount(),
  });
  useEffect(() => {
    addFilters(eventType, setFilters);
  }, [eventType]);
  let passingKey;
  if (eventType === "Corporate Events") {
    passingKey = "Exhibitions";
  } else if (eventType === "Weddings") {
    passingKey = "Wedding Reception";
  } else if (eventType === "Meetings") {
    passingKey = "Board Meetings";
  } else if (eventType === "Birthday Parties") {
    passingKey = "16th Birthday Party";
  } else if (eventType === "Proposals") {
    passingKey = "Proposals";
  } else if (eventType === "Kids Activities") {
    passingKey = "Kids Party";
  }
  const cardsData =
    eventType &&
    (count || typeof count === "object") &&
    (data[eventType] || []).map((item) => {
      const isFound = Object.keys(count).find((key) => key === item);
      if (!isFound) {
        return {
          img: bgImg,
          title: item,
          key: item,
          venues: 0,
        };
      }
      return {
        img: bgImg,
        title: item,
        key: item,
        venues: count[item] ? count[item] : 0,
      };
    });
  return (
    <div>
      <NavbarCustomer filters={filters} setFilters={setFilters} />
      <div className="text-[#717171] flex items-center my-4 md:mx-8 mx-1 gap-2">
        <Link to="/">Home</Link>
        <RiArrowRightSLine />
        <p className="text-dark">{eventType}</p>
      </div>
      <Hero
        filters={filters}
        setFilters={setFilters}
        eventTypeTitle={eventType}
        eventTypeName={passingKey}
        cityName={""}
      />
      {eventType !== "Proposals" &&
        eventType !== "Kids Activities" &&
        cardsData && (
          <ExploreEvents
            eventTypeName={eventType}
            setFilters={setFilters}
            filters={filters}
            eventsData={cardsData}
          />
        )}
      <h2
        className={`md:px-8 px-4 text-[#222222] md:text-[32px] text-[18px] font-semibold mb-0 ${
          eventType === "Proposals" || eventType === "Kids Activities"
            ? "mt-9"
            : ""
        }`}
      >
        Search results for all venues in {eventType}
      </h2>
      <Listing filters={filters} setFilters={setFilters} showNav={false} />
    </div>
  );
};

export default Event;
