import { useDispatch } from "react-redux";
import { toggleLogoutModal } from "../store/features/modalSlice";
import { clearUser } from "../store/features/authSlice";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

export default function LogoutModal({ isOpen }) {
  const dispatch = useDispatch();
  const modalRef = useRef(null); // Create a ref for the modal

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked target is outside the modal
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(toggleLogoutModal()); // Close the modal
      }
    };

    // Attach the click event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  if (!isOpen) return null;

  return (
    <div
      ref={modalRef} // Attach the ref to the modal div
      className="absolute top-[60px] right-[75px] bg-white text-black flex flex-col justify-between items-center  shadow-lg rounded-md z-[9999]"
    >
      <Link
        to="/profile"
        className="border-b border-[#717171] border-opacity-15 w-full px-10 py-1 mt-1"
        onClick={() => dispatch(toggleLogoutModal())}
      >
        Profile
      </Link>
      <Link
        className="px-10 py-2 cursor-pointer"
        to={"/"}
        onClick={() => {
          dispatch(clearUser());
          dispatch(toggleLogoutModal());
        }}
      >
        Logout
      </Link>
    </div>
  );
}
