import axios from "axios";
import { base_URL } from "../config/config";

export const getUser = async (userId) => {
  try {
    const response = await axios.get(`${base_URL}/api/auth/get-user/${userId}`);
    if (response.data.success) {
      return response.data.user;
    }
  } catch (error) {
    // console.log(error);
  }
};

const customerId = JSON.parse(localStorage.getItem("authUser"))?._id;

export const getEnquiries = async () => {
  try {
    const response = await axios.post(`${base_URL}/api/enquiry/getEnquiry/`, {
      customerId: customerId,
    });
    const enquiries = response.data.enquiries;
    const venueEnquiries = enquiries.filter(
      (enquiry) => enquiry.enquiryType === "venue"
    );
    const hotelEnquiries = enquiries.filter(
      (enquiry) => enquiry.enquiryType === "hotel"
    );
    return { venueEnquiries, hotelEnquiries };
  } catch (error) {
    // console.log(error);
  }
};
